import React from "react"
import progressBarTransition from "../../../../assets/images/ProgressBarTransition.svg"

const ProgressBar = ({
  progressBarWidth,
  height = "8px",
  progressColor = "#1965cf",
  trackColor = "#f0f2f4",
  showTransition = false,
  showShadow = false,
}) => {
  const width = `${progressBarWidth > 3 ? progressBarWidth : 3}%`
  return (
    <div
      className='v3-offerDetails-progressBar'
      style={{
        background: trackColor,
        boxShadow: showShadow ? "0px 1px 14px -2px #00000036 inset" : "",
      }}
    >
      <div
        className='v3-offerDetails-progress'
        style={{
          width: width,
          height: height,
          transition: `${showTransition ? "width 3s" : ""}`,
          background: progressColor,
        }}
      >
        <img
          src={progressBarTransition}
          alt='shine'
          className='v3-offerDetails-progressbar-shadow'
        />
      </div>
    </div>
  )
}

export default ProgressBar
