import queryString from "query-string"
import { useTranslation } from "react-i18next"
import React, { useEffect, useState } from "react"
import CryptoJs from "react-native-crypto-js"
import { useLocation } from "react-router-dom"
import PublicService from "../../services/PublicService"
import { PwaVersions } from "../../utils/enums"
import CustomLoader from "../core/loader"
import { showToast1 } from "../core/toast"
import Cvp_v2 from "./Cvp_v2"
import View from "../../nativeBaseReplacements/View"
import { useToast } from "../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"

const Cvp = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const [loading, setLoading] = useState(true)
  const [refId, setRefId] = useState()
  const [redirectUrl, setRedirectUrl] = useState()
  const [onboardingUrl, setOnboardingUrl] = useState()
  const [programConfig, setProgramConfig] = useState()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const nativeToast = useToast()

  const toast = (theme, message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }
  async function cvpRedirect(queryParams) {
    if (queryParams.hfRefId && queryParams.channel) {
      let onboardingKey = ""
      let onboardingIv = ""

      if (queryParams.channel === "aubonb") {
        onboardingKey = process.env.REACT_APP_AU_ONBOARDING_KEY
        onboardingIv = process.env.REACT_APP_AU_ONBOARDING_IV
      }

      if (queryParams.channel === "fedonb") {
        onboardingKey = process.env.REACT_APP_FED_ONBOARDING_KEY
        onboardingIv = process.env.REACT_APP_FED_ONBOARDING_IV
      }

      const key = CryptoJs.enc.Utf8.parse(onboardingKey)
      const iv = CryptoJs.enc.Utf8.parse(onboardingIv)
      const encoder = CryptoJs.enc.Utf8

      const data = queryParams.hfRefId.split(" ")?.join("+")

      let decryptedData = null
      try {
        decryptedData = CryptoJs.AES.decrypt(data, key, {
          iv,
        }).toString(encoder)
      } catch (error) {
        console.log(error, "error")
      }

      const response = await PublicService.getProgramConfig(decryptedData)

      if (response.data.success) {
        setProgramConfig(response.data.data)
        if (!response?.data?.data?.featureFlag?.showCvp) {
          window.location.href = queryParams.onboardingUrl
        }
      } else {
        toast(
          {
            fontFamily: "Inter",
            toastColor: "#FFFFFF",
          },
          t("cvp.errorToast"),
        )
        setTimeout(() => {
          window.location.href = queryParams.redirectUrl
        }, 1000)
      }
    } else {
      toast(
        {
          fontFamily: "Inter",
          toastColor: "#FFFFFF",
        },
        t("cvp.errorToast"),
      )
      setTimeout(() => {
        window.location.href = queryParams.onboardingUrl
      }, 1000)
    }
  }

  useEffect(() => {
    ;(async () => {
      setOnboardingUrl(queryParams.onboardingUrl)
      setRefId(queryParams.refId.split(" ").join("+"))
      setRedirectUrl(queryParams.redirectUrl)
      cvpRedirect(queryParams)
      setLoading(false)
    })()
  }, [])

  const getVersioned = item => {
    switch (item) {
      case PwaVersions.V2:
        return (
          <Cvp_v2
            programConfig={programConfig}
            onboardingUrl={onboardingUrl}
            redirectUrl={redirectUrl}
            refId={refId}
          />
        )
      case PwaVersions.V2_1:
        return (
          <Cvp_v2
            programConfig={programConfig}
            onboardingUrl={onboardingUrl}
            redirectUrl={redirectUrl}
            refId={refId}
          />
        )
      default:
        return <></>
    }
  }

  return loading ? (
    <View
      w='100%'
      alignItems='center'
      justifyContent='center'
      height={windowDimensions.height}
    >
      <CustomLoader
        // color={theme.color1}
        color='#A8A8A8'
        width={120}
        height={120}
      />
    </View>
  ) : (
    getVersioned(programConfig?.version || PwaVersions.V2 || PwaVersions.V2_1)
  )
}

export default Cvp
