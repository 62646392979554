import React from "react"

const ArrowRightLarge = ({ color = "#E72B2B" }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='15'
        height='14'
        viewBox='0 0 15 14'
        fill='none'
      >
        <path
          opacity='0.4'
          d='M12.7513 7.58341H2.2513C1.92872 7.58341 1.66797 7.32208 1.66797 7.00008C1.66797 6.67808 1.92872 6.41675 2.2513 6.41675H12.7513C13.0739 6.41675 13.3346 6.67808 13.3346 7.00008C13.3346 7.32208 13.0739 7.58341 12.7513 7.58341Z'
          fill={color}
        />
        <path
          d='M8.66553 11.6667C8.5162 11.6667 8.36684 11.6096 8.25309 11.4959C8.02501 11.2678 8.02501 10.8991 8.25309 10.671L11.924 7.00008L8.25309 3.32918C8.02501 3.1011 8.02501 2.7324 8.25309 2.50431C8.48118 2.27623 8.84988 2.27623 9.07796 2.50431L13.1613 6.58765C13.3894 6.81573 13.3894 7.18443 13.1613 7.41252L9.07796 11.4959C8.96421 11.6096 8.81486 11.6667 8.66553 11.6667Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default ArrowRightLarge
