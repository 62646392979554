import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import paymentSuccessIcon from "../../../assets/images/paymentSuccess.svg"
import { captureEvents, formatAmount } from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import AccountService from "../../../services/AccountService"
import { EventName, PwaVersions } from "../../../utils/enums"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const options = ["@ybi", "@paytm", "@apl", "@axl", "@aubank", "@ilb"]

const PaymentSuccess = () => {
  const { t } = useTranslation()
  const windowDim = useWindowDimensions()
  const [inputId, setInputId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const paymentGateway = useSelector(state => state.paymentGateway)
  const config = useSelector(state => state.config)

  const handleProceed = async () => {
    captureEvents({
      eventName: EventName.PAY_BILL_SUCCESS_CTA,
      metadata: { action: "payment completed", method: "UPI" },
    })
    navigate("/")
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text={
          config?.version == PwaVersions.V3
            ? ""
            : t("PaymentGateway.billPayment")
        }
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      <div className='mainContainer' style={{ color: theme.appTextColor }}>
        <div
          className={`paymentSuccessContentContainer ${config?.version == PwaVersions.V3 ? "v3-paymentSuccess-contentContainer" : ""}`}
        >
          <div className='paymentSuccessIcon'>
            <img src={paymentSuccessIcon} />
          </div>
          {config.version == PwaVersions.V3 ? (
            <>
              <div className='v3-paymentSuccess-description'>
                {t("PaymentGateway.v3paymentSuccessdescriptionpart1")}{" "}
                <span>{formatAmount(paymentGateway.amountPayable)}</span>{" "}
                {t("PaymentGateway.v3paymentSuccessdescriptionpart2")}
              </div>
            </>
          ) : (
            <>
              <div className='paymentSuccessTitle'>
                {t("PaymentGateway.paymentSuccessTitle")}
              </div>

              <div className='paymentSuccessDesc'></div>

              <div className='paymentSuccessAmountPaid'>
                <div className='paymentSuccessAmountPaidText'>
                  {t("PaymentGateway.paymentSuccessAmountPaidText")}
                </div>

                <div className='paymentSuccessAmountPaidAmount'>
                  {formatAmount(paymentGateway.amountPayable)}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className='paymentGatewayBtnContainer'>
        <ColorButton
          text={
            config?.version == PwaVersions.V3
              ? t("PaymentGateway.v3paymentSuccessbtnText")
              : t("PaymentGateway.done")
          }
          onPress={() => handleProceed()}
        />
      </div>
    </div>
  )
}

export default PaymentSuccess
