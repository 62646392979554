const NoInternet = () => {
  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='145'
        height='190'
        viewBox='0 0 145 190'
        fill='none'
      >
        <g clip-path='url(#clip0_4540_78582)'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M96.7831 65.7413L97.1202 3.85751L88.6683 1.49292C88.6683 1.49292 51.4658 4.87847 33.2041 7.91727C14.9472 10.9657 2.44042 13.9997 2.44042 13.9997C2.44042 13.9997 -1.44116 44.094 0.586312 63.3767C2.61379 82.6401 8.52766 98.5372 8.52766 98.5372L16.0693 98.8744C31.8846 94.5931 71.548 83.6514 96.7879 65.7413H96.7831Z'
            fill='#DFDFDF'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M96.7849 65.7413L97.122 3.85751L88.6702 1.49292C88.6702 1.49292 81.3838 2.16232 71.6895 3.15439L30.1094 94.9109C49.185 89.2571 77.2807 79.5772 96.7849 65.7413Z'
            fill='#CCCCCC'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M35.7275 35.1364L36.6858 71.655L69.1447 61.5128L67.9456 28.895C67.9456 28.895 36.0742 34.8041 35.7227 35.1412L35.7275 35.1364Z'
            fill='white'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M41.3047 70.2157L69.15 61.5087L67.9508 28.8909C67.9508 28.8909 64.2282 29.5795 59.2919 30.5042L41.3047 70.2157Z'
            fill='#E6E6E6'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M96.9612 3.82379L88.6683 1.49292C88.6683 1.49292 51.4658 4.87847 33.2041 7.91727C14.9472 10.9657 2.44042 13.9997 2.44042 13.9997C2.44042 13.9997 -1.44116 44.094 0.586312 63.3767C2.61379 82.6401 8.52766 98.5372 8.52766 98.5372L16.0693 98.8744L16.1656 98.8599C15.5107 96.8469 9.85684 79.1004 8.1135 62.6928C6.24494 45.2787 8.96109 17.2119 8.96109 17.2119C8.96109 17.2119 71.1964 4.62323 96.9661 3.82379H96.9612Z'
            fill='#B3B3B3'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M2.55118 13.9849L2.44042 13.9993C2.44042 13.9993 -1.44116 44.0984 0.586312 63.3763C2.61379 82.6397 8.52766 98.5369 8.52766 98.5369L16.0693 98.874L16.1656 98.8595C15.5107 96.8465 9.85684 79.1001 8.1135 62.6924C6.24494 45.2783 8.96109 17.2115 8.96109 17.2115L9.15372 17.1778L2.556 13.9849H2.55118Z'
            fill='#666666'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M45.2969 42.2445L48.1719 41.6039V58.1705L45.2969 58.811V42.2445ZM57.2932 39.4464L60.1682 38.8252V55.3917L57.2932 56.013V39.4464Z'
            fill='#4D4D4D'
          />
          <path
            d='M130.602 92.8637C129.6 94.2989 129.966 96.2685 131.401 97.2558L131.411 97.251C131.449 97.2751 142.613 104.836 135.61 116.216C132.514 121.253 128.064 124.436 124.052 127.292L124.045 127.297C116.69 132.559 110.569 136.937 112.928 148.314C127.668 150.964 123.192 148.583 119.111 147.061C117.589 139.693 122.179 136.404 127.712 132.46L127.736 132.443C132.247 129.211 137.259 125.62 141.009 119.544C151.18 102.996 135.047 92.0884 134.994 92.0498C133.559 91.0626 131.594 91.419 130.602 92.8637Z'
            fill='#262533'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M72.5469 73.8757L87.9865 78.9468V86.344L72.5469 81.2825V73.8757Z'
            fill='#DFDFDF'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M72.5478 73.8079L74.7534 73.1096L89.4563 77.7425L89.62 85.6983L88.0452 86.3725L87.9826 86.3099V78.9513L72.6682 73.9283C72.5911 73.8657 72.543 73.8127 72.543 73.8127L72.5478 73.8079Z'
            fill='#B3B3B3'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M85.9688 63.6748L101.408 68.7363V76.1431L85.9688 71.0816V63.6748Z'
            fill='#DFDFDF'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M85.9688 63.608L88.1889 62.8953L102.892 67.5281L103.055 75.4839L101.471 76.1581L101.408 76.1052V68.7321L86.1084 63.7188C86.0169 63.6417 85.9688 63.6032 85.9688 63.6032V63.608Z'
            fill='#B3B3B3'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M78.8554 78.7825L102.352 63.7378C102.352 63.7378 124.11 70.7256 124.784 72.6038C125.458 74.482 125.458 85.621 125.049 88.7176C124.645 91.7998 116.588 96.8998 116.588 96.8998L101.683 97.3043L80.4687 90.4514C80.4687 90.4514 77.9211 85.6162 78.8602 78.7777L78.8554 78.7825Z'
            fill='#363C4A'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M124.879 73.0134C125.438 75.7295 125.423 85.7995 125.043 88.7179C124.638 91.8 116.582 96.9 116.582 96.9L101.778 97.3045L101.652 87.0034L124.879 73.0085V73.0134Z'
            fill='#262533'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M125.311 82.1977C125.297 84.9235 125.21 87.4759 125.046 88.7184C124.642 91.8005 116.585 96.9005 116.585 96.9005L101.68 97.3051L80.466 90.4521C80.466 90.4521 77.981 85.747 78.8286 79.0337C82.4212 80.5073 96.1079 86.0504 100.635 86.8595C105.874 87.7986 114.87 89.4119 114.87 89.4119L125.311 82.1929V82.1977Z'
            fill='#262533'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M125.308 82.197C125.293 84.9227 125.207 87.4751 125.043 88.7176C124.638 91.7998 116.582 96.8998 116.582 96.8998L101.778 97.3043L101.652 87.0513C106.955 88.0049 114.872 89.4111 114.872 89.4111L125.313 82.1921L125.308 82.197Z'
            fill='#1C1B25'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M99.2775 71.8046C102.413 70.1046 105.606 69.9505 106.434 71.4627C107.257 72.9845 105.394 75.585 102.259 77.285C99.1379 78.9995 95.9402 79.1488 95.1166 77.6269C94.2931 76.1196 96.1569 73.5046 99.2775 71.8046Z'
            fill='#262533'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M115.215 96.606C113.919 94.7904 114.468 89.9601 114.935 88.8814C115.407 87.8026 123.243 81.2434 125.26 81.778C125.641 81.8791 127.1 82.5533 127.875 83.0446C131.213 85.2117 137.199 90.3647 137.098 92.1032C136.958 94.2462 132.985 100.136 130.919 99.8952C129.041 99.6785 116.38 98.2723 115.215 96.6108V96.606Z'
            fill='#262533'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M127.907 83.074C128.658 83.57 129.53 84.2009 130.445 84.9136C128.451 85.4578 126.78 86.2476 125.345 87.2878C123.698 88.4918 122.35 90.0425 121.17 91.9063C120.077 93.6303 119.725 95.9034 119.571 98.2006C118.646 97.9983 117.784 97.7816 117.086 97.5649C117.279 95.0895 117.722 92.6286 119.027 90.5626C120.37 88.4292 121.931 86.657 123.862 85.2459C125.066 84.3694 126.4 83.6471 127.907 83.0788V83.074ZM133.527 87.5142C134.288 88.2125 135.001 88.9108 135.569 89.5561C134.823 89.6958 131.355 90.5097 129.251 94.083C128.273 95.73 127.777 97.6997 127.536 99.4864C126.775 99.3853 125.928 99.2697 125.051 99.1445C125.331 97.0785 125.913 94.7669 127.069 92.8116C128.947 89.6139 131.712 88.1547 133.527 87.5094V87.5142Z'
            fill='#1C1B25'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M42.9336 97.8778V112.114L49.2375 111.035C49.2375 111.035 43.0733 97.7526 42.9336 97.8778Z'
            fill='#FFBC00'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M34.7381 79.4812L18.3594 92.9174L26.2863 96.9387C26.2863 96.9387 34.873 79.8905 34.7333 79.4812H34.7381Z'
            fill='#FFBC00'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M85.2305 47.3977L101.2 25.6396L93.9568 16.2391L127.119 0L110.74 21.0742L116.375 32.0881C116.375 32.0881 85.4953 47.2628 85.2305 47.4025V47.3977Z'
            fill='#FFBC00'
          />
        </g>
        <defs>
          <clipPath id='clip0_4540_78582'>
            <rect width='144.37' height='190' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default NoInternet
