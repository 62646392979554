const Cross = ({ color = "#343434", height, width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width||'21'}
      height={height||'20'}
      viewBox='0 0 21 20'
      fill='none'
    >
      <path
        d='M16.6574 15.1832C16.7154 15.2412 16.7615 15.3102 16.7929 15.386C16.8244 15.4619 16.8405 15.5432 16.8405 15.6253C16.8405 15.7075 16.8244 15.7888 16.7929 15.8647C16.7615 15.9405 16.7154 16.0095 16.6574 16.0675C16.5993 16.1256 16.5304 16.1717 16.4545 16.2031C16.3786 16.2345 16.2973 16.2507 16.2152 16.2507C16.1331 16.2507 16.0518 16.2345 15.9759 16.2031C15.9 16.1717 15.8311 16.1256 15.773 16.0675L10.5902 10.8839L5.40738 16.0675C5.2901 16.1848 5.13104 16.2507 4.96519 16.2507C4.79934 16.2507 4.64028 16.1848 4.523 16.0675C4.40573 15.9503 4.33984 15.7912 4.33984 15.6253C4.33984 15.4595 4.40573 15.3004 4.523 15.1832L9.7066 10.0003L4.523 4.81753C4.40573 4.70026 4.33984 4.5412 4.33984 4.37535C4.33984 4.2095 4.40573 4.05044 4.523 3.93316C4.64028 3.81588 4.79934 3.75 4.96519 3.75C5.13104 3.75 5.2901 3.81588 5.40738 3.93316L10.5902 9.11675L15.773 3.93316C15.8903 3.81588 16.0493 3.75 16.2152 3.75C16.381 3.75 16.5401 3.81588 16.6574 3.93316C16.7747 4.05044 16.8405 4.2095 16.8405 4.37535C16.8405 4.5412 16.7747 4.70026 16.6574 4.81753L11.4738 10.0003L16.6574 15.1832Z'
        fill={color}
      />
    </svg>
  )
}

export default Cross
