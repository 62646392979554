import { forwardRef, useCallback } from "react"
import styled, { css } from "styled-components"
import {
  getNativeBaseBoxShadow,
  nativeBaseColor2rgba,
  getNativeBaseSpaceToPx as spaceToPx,
} from "../utils/functions"

const Input = forwardRef((props, ref) => {
  const {
    _web,
    padding,
    p,
    paddingBottom,
    pb,
    py,
    paddingTop,
    pt,
    paddingLeft,
    pl,
    px,
    paddingRight,
    pr,
    margin,
    m,
    marginBottom,
    mb,
    my,
    marginTop,
    mt,
    marginLeft,
    ml,
    mx,
    marginRight,
    mr,
    maxWidth,
    maxW,
    minWidth,
    minW,
    width,
    w,
    minHeight,
    minH,
    maxHeight,
    maxH,
    height,
    h,
    backgroundColor,
    bgColor,
    background,
    bg,
    className,
    color,
    fontSize,
    shadow,
    style,
    secureTextEntry,
    ...restProps
  } = { ...props, ...props.style }

  const resolvedPadding = spaceToPx(padding, 1) ?? spaceToPx(p) ?? "8px"
  const resolvedPaddingBottom =
    spaceToPx(paddingBottom, 1) ?? spaceToPx(pb ?? py)
  const resolvedPaddingTop = spaceToPx(paddingTop, 1) ?? spaceToPx(pt ?? py)
  const resolvedPaddingLeft = spaceToPx(paddingLeft, 1) ?? spaceToPx(pl ?? px)
  const resolvedPaddingRight = spaceToPx(paddingRight, 1) ?? spaceToPx(pr ?? px)

  const resolvedMargin = spaceToPx(margin, 1) ?? spaceToPx(m) ?? 0
  const resolvedMarginBottom = spaceToPx(marginBottom, 1) ?? spaceToPx(mb ?? my)
  const resolvedMarginTop = spaceToPx(marginTop, 1) ?? spaceToPx(mt ?? my)
  const resolvedMarginLeft = spaceToPx(marginLeft, 1) ?? spaceToPx(ml ?? mx)
  const resolvedMarginRight = spaceToPx(marginRight, 1) ?? spaceToPx(mr ?? mx)

  const resolvedMaxWidth = spaceToPx(maxWidth, 1) ?? spaceToPx(maxW)
  const resolvedMinWidth = spaceToPx(minWidth, 1) ?? spaceToPx(minW)
  const resolvedWidth = spaceToPx(width, 1) ?? spaceToPx(w)

  const resolvedMinHeight = spaceToPx(minHeight, 1) ?? spaceToPx(minH)
  const resolvedMaxHeight = spaceToPx(maxHeight, 1) ?? spaceToPx(maxH)
  const resolvedHeight = spaceToPx(height, 1) ?? spaceToPx(h)

  const resolvedBackgroundColor = nativeBaseColor2rgba(
    backgroundColor ?? bgColor,
  )
  const resolvedBackground = nativeBaseColor2rgba(background ?? bg)
  const resolvedColor = nativeBaseColor2rgba(color)

  const resolvedShadow = getNativeBaseBoxShadow(shadow)

  const resolvedFontSize = !isNaN(+fontSize)
    ? `${fontSize}px`
    : `${fontSize ?? "12px"}`

  const styles = {
    ...restProps,
    ...style,
    ..._web,
  }

  const appliedStyles = {
    padding: resolvedPadding,
    paddingBottom: resolvedPaddingBottom,
    paddingTop: resolvedPaddingTop,
    paddingLeft: resolvedPaddingLeft,
    paddingRight: resolvedPaddingRight,
    margin: resolvedMargin,
    marginBottom: resolvedMarginBottom,
    marginTop: resolvedMarginTop,
    marginLeft: resolvedMarginLeft,
    marginRight: resolvedMarginRight,
    maxWidth: resolvedMaxWidth,
    minWidth: resolvedMinWidth,
    width: resolvedWidth,
    minHeight: resolvedMinHeight,
    maxHeight: resolvedMaxHeight,
    height: resolvedHeight,
    background: resolvedBackground,
    backgroundColor: resolvedBackgroundColor,
    fontSize: resolvedFontSize,
    color: resolvedColor,
    boxShadow: resolvedShadow,
  }

  Object.entries(appliedStyles).forEach(([key, value]) => {
    if (typeof value === "undefined" || value === null) {
      return
    }
    styles[key] = value
  })

  const onChangeHandler = useCallback(
    e => {
      props.onChange?.(e)
      props.onChangeText?.(e.target.value)
    },
    [props.onChange, props.onChangeText],
  )

  const onKeyDownHandler = useCallback(
    e => {
      props.onKeyPress?.({ nativeEvent: e })
    },
    [props.onKeyPress],
  )

  return (
    <NativeBaseStyledInput
      ref={ref}
      nbr='Input'
      {...restProps}
      type={secureTextEntry ? "password" : restProps.type}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      style={styles}
    />
  )
})

export default Input

const NativeBaseStyledInput = styled.input/* css */ `
  -moz-appearance: textfield;
  -webkit-appearance: none;
  background-color: transparent;
  border: 0 solid rgb(229, 229, 229);
  ${props =>
    props.variant !== "underlined"
      ? ""
      : css`
          border-bottom-width: 1px;
        `};
  border-radius: ${props => (props.variant !== "underlined" ? `4px` : 0)};
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
  resize: none;
  line-height: 1.5em;
  outline: none;
  font:
    14px -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
  &::placeholder {
    font:
      14px -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto,
      Helvetica,
      Arial,
      sans-serif;
  }
  &:focus {
    border-color: rgb(34, 211, 238);
  }
`
