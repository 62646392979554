import axios from "axios"
import { publicPaths } from "../config/api"
import store from "../store/Store"
import { expireSession } from "../store/actions/Session"
import { DEFAULT_TENANT } from "../utils/enums"

const ExternalService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/multiwallet",
  validateStatus: function (status) {
    return true
  },
})

ExternalService.interceptors.request.use(
  function (req) {
    const session = store.getState().session

    if (!publicPaths.includes(req.url)) {
      req.headers["Authorization"] = `Bearer ${session.deviceToken}`
      req.headers["tenantId"] =
        localStorage.getItem("tenantId") || DEFAULT_TENANT
    }

    return req
  },
  function (error) {
    return Promise.reject(error)
  },
)

ExternalService.interceptors.response.use(
  function (res) {
    if (res.status === 401) {
      store.dispatch(expireSession())
      return res
    }
    return res
  },
  function (error) {
    return Promise.reject(error)
  },
)
export default ExternalService
