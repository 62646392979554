import { useLocation, useNavigate } from "react-router-dom"
import { Header } from "../../../../core"
import { useTranslation } from "react-i18next"
import mpinBlockedImage from "../../../../../assets/images/v3/mPinBlockedV3.svg"
import { ColorButton } from "../../../../core/buttons"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"
import DividerV3 from "../../../../core/Divider/v3/DividerV3"
import { useEffect } from "react"

const AuthenticationTxnBlocked_v3 = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()
  const location = useLocation()

  const time = location?.state?.time
  const client = location?.state?.client
  const amount = location?.state?.amount
  const failureRedirectUrl = location?.state?.redirectUrlFailure

  useEffect(() => {
    if (failureRedirectUrl) {
      const timer = setTimeout(() => {
        window.location.href = failureRedirectUrl
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [failureRedirectUrl])

  const renderMobileView = () => (
    <div
      className='v3-acs-transactionSection-authentication v3_authentication_txn_blocked'
      style={{ minHeight: window.innerHeight }}
    >
      <div className='v3_mpin_block_container'>
        <div className='v3_mpin_block_image_text_container'>
          <div className='v3_mpin_block_image'>
            <img src={mpinBlockedImage} alt='mpin v3 block image' />
          </div>
          <div className='v3_mpin_block_card_header_text'>
            {t("blockScreenMessages.authenticationFailed")}
          </div>
          <div className='v3_mpin_block_card_description_text'>
            {t("blockScreenMessages.authenticationFailedDescription")}
            <br />
            <div className='v3_authentication_txn_blocked_retry'>
              {t("blockScreenMessages.retryAfter")} {time}
            </div>
          </div>
        </div>

        <div className='v3_authentication_txn_blocked_contact_support-container'>
          <div className='v3_authentication_txn_blocked_contact'>
            {t("mpin.requireAssistanceText")}{" "}
          </div>
          <div
            className='v3_authentication_txn_blocked_contact_support'
            // onClick={handleContactSupportClick}
          >
            {t("mpin.contactSupport")}
          </div>
        </div>
      </div>

      <div className='v3_authentication_txn_blocked_btn_container'>
        <ColorButton
          text={`Go Back`}
          // onPress={handleResetMpinClick}
          isDisable={false}
          width={"100%"}
          margin={"auto"}
        />
      </div>
    </div>
  )

  const renderDesktopView = () => (
    <div className='v3-authenticationTxnSuccess-desktop-container'>
      <div className='v3-acs-loader-desktop-price-container'>
        <div className='v3-acs-loader-desktop-header'>
          {t("PaymentGateway.payingTo")}{" "}
          <span className='v3-acs-loader-desktop-header-span'>{client}:</span>
        </div>
        <div className='v3-acs-loader-desktop-price'>₹{amount}</div>
      </div>
      <DividerV3 />
      <div className='v3-acs-loader-desktop-body-container'>
        <div className='v3_mpin_block_container'>
          <div className='v3_mpin_block_image_text_container'>
            <div className='v3_mpin_block_image'>
              <img src={mpinBlockedImage} alt='mpin v3 block image' />
            </div>
            <div className='v3_mpin_block_card_header_text'>
              {t("blockScreenMessages.authenticationFailed")}
            </div>
            <div className='v3_mpin_block_card_description_text'>
              {t("blockScreenMessages.authenticationFailedDescription")}
              <br />
              <div className='v3_authentication_txn_blocked_retry'>
                {t("blockScreenMessages.retryAfter", { time })}
              </div>
            </div>
          </div>

          <div className='v3_authentication_txn_blocked_contact_support-container'>
            <div className='v3_authentication_txn_blocked_contact'>
              {t("mpin.requireAssistanceText")}{" "}
            </div>
            <div
              className='v3_authentication_txn_blocked_contact_support'
              // onClick={handleContactSupportClick}
            >
              {t("mpin.contactSupport")}
            </div>
          </div>
          <div
            className='v3_authentication_txn_blocked_btn_container'
            style={{ width: "50%" }}
          >
            <ColorButton
              text={`Go Back`}
              // onPress={handleResetMpinClick}
              isDisable={false}
              width={"100%"}
              margin={"auto"}
            />
          </div>
        </div>
      </div>
    </div>
  )
  return width <= 450 ? renderMobileView() : renderDesktopView()
}

export default AuthenticationTxnBlocked_v3
