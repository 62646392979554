import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import DefaultCompanyLogo from "../../../../assets/images/DefaultCompanyLogo.svg"
import arrowRight from "../../../../assets/images/arrowRight.svg"
import CommonService from "../../../../services/CommonService"
import MultiwalletService from "../../../../services/MultiwalletService"
import {
  consoleError,
  formatAmount,
  getCurrentFinancialYear,
  getDate,
} from "../../../../utils/functions"
import WidgetSkeleton from "../../../core/SkeletonLoader/WidgetSkeleton"
import NewUserMultiwalletWidget from "./NewUserMultiwalletWidget"

const IdealMultiWalletWidget = () => {
  const { t } = useTranslation()
  const session = useSelector(state => state.session)
  const user = useSelector(state => state.user)
  const [netAvailableAmount, setNetAvailableAmount] = useState()
  const [netLoadedAmount, setNetLoadedAmount] = useState(0)
  const [lastLoadedAmount, setLastLoadedAmount] = useState()
  const [lastLoadedDate, setLastLoadedDate] = useState()
  const [isTransacted, setIsTransacted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [integerBalance, setIntegerBalance] = useState()
  const [decimalBalance, setDecimalBalance] = useState()
  const [integerLoadBalance, setIntegerLoadBalance] = useState()
  const [decimalLoadBalance, setDecimalLoadBalance] = useState()

  const handleRedirectionClick = async () => {
    try {
      const response = await CommonService.multiwalletPwaRedirection()
      if (response.data?.success) {
        const redirectionUrl = response.data.data.redirectionUrl
        document.cookie =
          "sessionData=" +
          JSON.stringify({
            deviceToken: session.deviceToken,
            accountId: user.account?.id,
            firstName: user?.customer?.firstName,
            nativeAppUrl: session?.redirectUrl,
            tenantId: localStorage.getItem("tenantId"),
          }) +
          ";expires=1d;domain=.hyperface.co;path=/"
        if (redirectionUrl) {
          window.location.href = redirectionUrl
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    ;(async function () {
      try {
        const txnResponse = await MultiwalletService.getTransactions(
          1,
          10,
          [],
          null,
          null,
          user.account?.id,
        )
        if (txnResponse.data.success) {
          if (txnResponse.data.data.count > 0) {
            setIsTransacted(true)
          }
        }

        // load summary api
        const loadSummaryResponse = await MultiwalletService.loadSummary(
          user.account?.id,
        )
        if (loadSummaryResponse.data?.success) {
          setIsLoading(false)
          const responseData = loadSummaryResponse.data?.data
          const netSpent = responseData?.netFYSpentAmount
          const netLoaded = responseData?.netFYLoadedAmount
          const lasLoadedAmount =
            responseData?.lastLoadedData?.lastDailyLoadedAmount
          const lastLoadedDate = responseData?.lastLoadedData?.lastLoadedDate
          setNetLoadedAmount(netLoaded)
          if (netLoaded > 0) {
            setNetLoadedAmount(netLoaded)
          }
          setNetAvailableAmount(
            formatAmount(netLoaded - netSpent < 0 ? 0 : netLoaded - netSpent),
          )
          setLastLoadedAmount(formatAmount(lasLoadedAmount))
          setLastLoadedDate(lastLoadedDate)
        } else {
          consoleError(loadSummaryResponse.data?.errors)
        }
      } catch (error) {
        console.log(error)
      }

      try {
        const response = await MultiwalletService.loadTransactionSummary(
          {
            year: getCurrentFinancialYear().leftBoundFullYear,
          },
          user?.account?.id,
        )
        const txnSummary = response.data?.data?.walletTransactionSummary
        let availableAmount = 0
        txnSummary.map(item => {
          const walletName = Object.keys(item)[0]
          if (item[walletName].netSpent <= item[walletName].netLoaded) {
            availableAmount +=
              item[walletName].netLoaded - item[walletName].netSpent
          }
          return null
        })
        const [int, dec] = formatAmount(availableAmount).split(".")
        setIntegerBalance(int)
        setDecimalBalance(dec)
      } catch (error) {
        console.log(error)
      }
    })()
  }, [])

  useEffect(() => {
    const [loadedInt, loadedDec] = formatAmount(netLoadedAmount).split(".")
    setIntegerLoadBalance(loadedInt)
    setDecimalLoadBalance(loadedDec)
  }, [netLoadedAmount])

  const positiveAvailableIntegerCheck =
    parseInt(integerBalance?.substring(1, integerBalance.length)) > 0
  const isAvailableDecimalGreaterThanZero = parseInt(decimalBalance) > 0

  const positiveLoadedIntegerCheck =
    parseInt(integerLoadBalance?.substring(1, integerLoadBalance.length)) > 0
  const isLoadDecimalGreaterThanZero = parseInt(decimalLoadBalance) > 0
  return (
    <>
      {isLoading ? (
        <WidgetSkeleton />
      ) : !isTransacted && netLoadedAmount === 0 ? (
        <NewUserMultiwalletWidget session={session} user={user} />
      ) : (
        <div className='home-benefits-widget'>
          <div className='benifits-header'>
            <div className='benifits-left-content'>
              <div className='benifits-header-image'>
                <img src={DefaultCompanyLogo} alt='Benifits image' />
              </div>
              <div className='benifits-header-text'>
                <div className='benifits-header-title'>
                  {t("IdealMultiWalletWidget.benefitsHeaderTitle")}
                </div>
                <div className='benifits-header-org-name'>
                  {t("IdealMultiWalletWidget.benefitsHeaderOrgName")}
                </div>
              </div>
            </div>
            <div
              className='benefits-right-chevron'
              onClick={handleRedirectionClick}
            >
              <img src={arrowRight} />
            </div>
          </div>
          <div className='benifits-body'>
            <div className='benifits-availabe-amount-header'>
              {t("IdealMultiWalletWidget.benefitsAvailable")}
            </div>
            <div className='benifits-amount'>
              <div className='benifits-amount-available'>{integerBalance}</div>
              {positiveAvailableIntegerCheck &&
                isAvailableDecimalGreaterThanZero && (
                  <div className='benefits-decimal-amount-available'>
                    {"." + decimalBalance}
                  </div>
                )}
              <div className='benifits-total-amount'>
                <div className='benifits-amount-outof'>/</div>
                <div className='benifits-amount-totalLoadedAmount'>
                  {integerLoadBalance +
                    (positiveLoadedIntegerCheck && isLoadDecimalGreaterThanZero
                      ? decimalLoadBalance
                      : "")}{" "}
                  {t("IdealMultiWalletWidget.loadedText")}
                </div>
              </div>
            </div>
            {
              <div className='benifits-footer'>
                <div className='benifits-last-loaded-text'>
                  {t("IdealMultiWalletWidget.benefitsLastLoadedText")}
                </div>
                <div className='benifits-last-loaded-info'>
                  {getDate(lastLoadedDate)} - {lastLoadedAmount}
                </div>
              </div>
            }
          </div>
        </div>
      )}
    </>
  )
}

export default IdealMultiWalletWidget
