const BadgeCheck = ({ color = "#ffffff" }) => {
  return (
    <svg
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M23.6682 10.085L23.0619 9.47857C22.6756 9.09219 22.4582 8.56694 22.4582 8.01969V7.16581C22.4582 4.88881 20.613 3.04358 18.336 3.04358H17.4822C16.935 3.04358 16.411 2.82633 16.0233 2.43996L15.4169 1.83357C13.8067 0.223445 11.197 0.223445 9.58687 1.83357L8.98044 2.43996C8.59406 2.82633 8.06893 3.04358 7.52168 3.04358H6.66784C4.39084 3.04358 2.54553 4.88881 2.54553 7.16581V8.01969C2.54553 8.56694 2.32833 9.09082 1.94195 9.47857L1.33552 10.085C-0.274602 11.6951 -0.274602 14.3048 1.33552 15.9149L1.94195 16.5213C2.32833 16.9077 2.54553 17.433 2.54553 17.9802V18.8341C2.54553 21.1111 4.39084 22.9563 6.66784 22.9563H7.52168C8.06893 22.9563 8.59269 23.1736 8.98044 23.5599L9.58687 24.1663C11.197 25.7765 13.8067 25.7765 15.4169 24.1663L16.0233 23.5599C16.4097 23.1736 16.935 22.9563 17.4822 22.9563H18.336C20.613 22.9563 22.4582 21.1111 22.4582 18.8341V17.9802C22.4582 17.433 22.6756 16.9091 23.0619 16.5213L23.6682 15.9149C25.2783 14.3048 25.2783 11.6951 23.6682 10.085ZM17.3543 10.9787L11.8543 16.4787C11.6536 16.6794 11.3895 16.7812 11.1255 16.7812C10.8615 16.7812 10.5975 16.6808 10.3967 16.4787L7.64673 13.7287C7.24385 13.3258 7.24385 12.6727 7.64673 12.2698C8.0496 11.8669 8.70278 11.8669 9.10565 12.2698L11.1269 14.2911L15.8981 9.51982C16.3009 9.11694 16.9541 9.11694 17.357 9.51982C17.7571 9.92269 17.7572 10.5772 17.3543 10.9787Z'
        fill={color}
      />
    </svg>
  )
}

export default BadgeCheck
