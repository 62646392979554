import React from "react"
import MakeMyTrip from "../../../../../assets/images/v3/makeMyTrip.svg"
import { useSelector } from "react-redux"
import moment from "moment"
import { formatAmount } from "../../../../../utils/functions"
import Divider from "../../../../core/Divider/v3/Divider"
import customerTxnCatchAll from "../../../../../assets/images/transactionLogos/customerTxnCatchAll.svg"

const EmiDescriptionCard = () => {
  const theme = useSelector(state => state.theme)
  const common = useSelector(state => state.common)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const { txnState } = common
  return (
    <>
      <div className='v3-convertToEMI-emi-description'>
        <div className='v3-convertToEMI-emi-description-left'>
          <div>
            {txnState?.logo ? (
              <img
                src={`data:image/jpg;base64,${txnState?.logo}`}
                height={"40px"}
                width={"40px"}
                alt='txn logo'
              />
            ) : (
              <img src={customerTxnCatchAll} alt='transaction logo' />
            )}
          </div>
          <div>
            <div>{txnState?.description}</div>
            <div>{moment(txnState?.transactionDate).format("DD MMM")}</div>
          </div>
        </div>
        <div className='v3-convertToEMI-emi-description-right'>
          {formatAmount(txnState?.amount)}
        </div>
      </div>
    </>
  )
}

export default EmiDescriptionCard
