import React from "react"

const SuccessCard = ({ color, className }) => {
  return (
    <svg
      width='239'
      height='170'
      viewBox='0 0 239 170'
      fill={color || "#1965CF"}
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g filter='url(#filter0_dd_2207_102019)'>
        <g clipPath='url(#clip0_2207_102019)'>
          <rect
            x='16.0098'
            y='44.708'
            width='193.92'
            height='105.6'
            rx='5.12'
            transform='rotate(-8 16.0098 44.708)'
            fill='#1965CF'
          />
          <path
            opacity='0.2'
            d='M336.049 167.186L184.269 -22.9083L209.487 -46.0824L370.81 159.007L336.049 167.186Z'
            fill='white'
          />
          <path
            d='M116.393 56.8184C117.992 55.0604 120.757 55.0604 122.356 56.8184L125.931 60.7502C126.823 61.7308 128.14 62.2102 129.454 62.0322L134.72 61.3186C137.075 60.9995 139.193 62.7768 139.287 65.1512L139.499 70.4614C139.552 71.7858 140.253 72.9996 141.373 73.7076L145.866 76.546C147.875 77.8152 148.355 80.5382 146.902 82.4179L143.65 86.6219C142.84 87.6704 142.596 89.0507 143 90.3133L144.617 95.3757C145.34 97.6392 143.957 100.034 141.636 100.539L136.443 101.67C135.148 101.952 134.074 102.853 133.571 104.079L131.556 108.997C130.655 111.196 128.057 112.141 125.953 111.036L121.249 108.564C120.075 107.948 118.674 107.948 117.5 108.564L112.796 111.036C110.692 112.141 108.094 111.196 107.193 108.997L105.178 104.079C104.675 102.853 103.601 101.952 102.306 101.67L97.1134 100.539C94.7915 100.034 93.409 97.6392 94.1321 95.3757L95.7494 90.3133C96.1528 89.0507 95.9094 87.6704 95.0985 86.6219L91.8473 82.4179C90.3936 80.5382 90.8738 77.8152 92.8827 76.546L97.3756 73.7076C98.4962 72.9996 99.197 71.7858 99.2498 70.4614L99.4615 65.1512C99.5562 62.7768 101.674 60.9995 104.029 61.3186L109.295 62.0322C110.609 62.2102 111.926 61.7308 112.818 60.7502L116.393 56.8184Z'
            fill='white'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M129.69 79.3381L118.095 90.9332C117.672 91.3565 117.115 91.571 116.559 91.571C116.002 91.571 115.446 91.3594 115.022 90.9332L109.225 85.1357C108.376 84.2863 108.376 82.9094 109.225 82.0601C110.074 81.2107 111.451 81.2107 112.301 82.0601L116.562 86.3213L126.62 76.2625C127.47 75.4132 128.847 75.4132 129.696 76.2625C130.54 77.1118 130.54 78.4917 129.69 79.3381Z'
            fill='#1965CF'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_dd_2207_102019'
          x='-0.630236'
          y='0.439603'
          width='238.729'
          height='168.681'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='3.84' dy='8.32' />
          <feGaussianBlur stdDeviation='5.76' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.709804 0 0 0 0 0.709804 0 0 0 0 0.709804 0 0 0 0.6 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2207_102019'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-3.2' dy='-3.84' />
          <feGaussianBlur stdDeviation='6.72' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.807843 0 0 0 0 0.85098 0 0 0 0 1 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_dropShadow_2207_102019'
            result='effect2_dropShadow_2207_102019'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow_2207_102019'
            result='shape'
          />
        </filter>
        <clipPath id='clip0_2207_102019'>
          <rect
            x='16.0098'
            y='44.708'
            width='193.92'
            height='105.6'
            rx='5.12'
            transform='rotate(-8 16.0098 44.708)'
            fill='white'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SuccessCard
