const Card = ({ color = "#2680EA" }) => {
  return (
    <svg
      width='21'
      height='17'
      viewBox='0 0 21 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.3242 0.75H4.32422C1.90622 0.75 0.574219 2.082 0.574219 4.5V12.5C0.574219 14.918 1.90622 16.25 4.32422 16.25H16.3242C18.7422 16.25 20.0742 14.918 20.0742 12.5V4.5C20.0742 2.082 18.7422 0.75 16.3242 0.75ZM18.5742 12.5C18.5742 14.077 17.9012 14.75 16.3242 14.75H4.32422C2.74722 14.75 2.07422 14.077 2.07422 12.5V7.25H18.5742V12.5ZM18.5742 5.75H2.07422V4.5C2.07422 2.923 2.74722 2.25 4.32422 2.25H16.3242C17.9012 2.25 18.5742 2.923 18.5742 4.5V5.75Z'
        fill={color}
      />
    </svg>
  )
}

export default Card
