import React from "react"

const FAQ_Img1 = ({ color = "#1965CF" }) => {
  return (
    <svg
      width='41'
      height='41'
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='41' height='41' rx='20.5' fill='#F5FAFF' />
      <path
        d='M29.5 16.5V17.75H11.5V16.5C11.5 14.5 12.5 13.5 14.5 13.5H26.5C28.5 13.5 29.5 14.5 29.5 16.5ZM29.5 19.25V24.5C29.5 26.5 28.5 27.5 26.5 27.5H14.5C12.5 27.5 11.5 26.5 11.5 24.5V19.25H29.5ZM19.25 23.5C19.25 23.086 18.914 22.75 18.5 22.75H15.5C15.086 22.75 14.75 23.086 14.75 23.5C14.75 23.914 15.086 24.25 15.5 24.25H18.5C18.914 24.25 19.25 23.914 19.25 23.5Z'
        fill={color}
      />
    </svg>
  )
}

export default FAQ_Img1
