import { getNativeBaseSpaceToPx } from "../utils/functions"

const ChevronDownIcon = ({ color, size }) => {
  return (
    <svg
      viewBox='0 0 24 24'
      stroke=''
      role='img'
      color={color}
      style={{
        height: getNativeBaseSpaceToPx(size, 4),
        width: getNativeBaseSpaceToPx(size, 4),
      }}
    >
      <g>
        <g transform='translate(24) rotate(90)' fill='currentColor' stroke=''>
          <path d='M0,0H24V24H0Z' fill='none'></path>
          <path d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z'></path>
        </g>
      </g>
    </svg>
  )
}

export default ChevronDownIcon
