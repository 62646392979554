import React, { useState } from "react"
import { useSelector } from "react-redux"
import ArrowUp from "../../../../svg/arrowUp"
import ArrowDown from "../../../../svg/arrowDown"
import { hexToRGBA } from "../../../../../utils/functions"

const FaqsAccordian_v3 = props => {
  const [subIsOpen, setSubIsOpen] = useState(false)
  const theme = useSelector(state => state.theme)
  const ans = props.item?.answer.split("#") || []
  return (
    <div className='customerCare-faq-accordian'>
      <div className='customerCare-faq-accordian-container'>
        <div className='customerCare-faq-left'>
          <div className='customerCare-faq-illustration'>
            {props?.item?.imageUrl ? (
              <img src={props?.item?.imageUrl} alt='faq' />
            ) : (
              props?.FAQsDefaultImage
            )}
          </div>
          <div
            className='customerCare-faq-accordian-question'
            style={{ color: theme.v3.cssVars.primaryBase.color1 }}
          >
            {props.item?.question}
          </div>
        </div>
        <div
          onClick={() => {
            setSubIsOpen(!subIsOpen)
          }}
          style={{ cursor: "pointer", width: "7%" }}
        >
          {subIsOpen ? (
            <ArrowUp
              className='aboutProgram-faq-accordian-question-arrowUp'
              size='12px'
              color={theme.v3.rawColors.primaryBase.color1}
            />
          ) : (
            <ArrowDown
              className='aboutProgram-faq-accordian-question-arrowDown'
              size='12px'
              color={theme.v3.rawColors.primaryBase.color1}
            />
          )}
        </div>
      </div>
      {subIsOpen &&
        ans.map(item => (
          <div
            className='customerCare-faq-accordian-item-text'
            style={{ color: theme.v3.cssVars.tertiaryNeutral.color2 }}
          >
            {item}
          </div>
        ))}
    </div>
  )
}

export default FaqsAccordian_v3
