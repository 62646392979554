const ClockBig = ({ color = "#E5B200" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='26'
      viewBox='0 0 25 26'
      fill='none'
    >
      <path
        d='M12.2239 2.04932C6.18537 2.04932 1.27344 6.96125 1.27344 12.9998C1.27344 19.0384 6.18537 23.9503 12.2239 23.9503C18.2625 23.9503 23.1744 19.0384 23.1744 12.9998C23.1744 6.96125 18.2625 2.04932 12.2239 2.04932ZM12.2239 22.4223C7.02779 22.4223 2.80141 18.1959 2.80141 12.9998C2.80141 7.80367 7.02779 3.57729 12.2239 3.57729C17.4201 3.57729 21.6464 7.80367 21.6464 12.9998C21.6464 18.1959 17.4201 22.4223 12.2239 22.4223ZM15.8198 15.5158C16.1183 15.8143 16.1183 16.2982 15.8198 16.5967C15.6711 16.7454 15.4755 16.8207 15.2799 16.8207C15.0843 16.8207 14.8887 16.7464 14.74 16.5967L11.684 13.5407C11.5404 13.3971 11.4599 13.2025 11.4599 13.0008V7.90755C11.4599 7.48583 11.8022 7.14356 12.2239 7.14356C12.6456 7.14356 12.9879 7.48583 12.9879 7.90755V12.684L15.8198 15.5158Z'
        fill={color}
      />
    </svg>
  )
}

export default ClockBig
