import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"

import ApiIds from "../../../../auth/ApiIds"
import Loader from "../../../core/loader"
import CardService from "../../../../services/CardService"
import CommonService from "../../../../services/CommonService"
import {
  setDeviceId,
  setIsMpinSet,
  setVerifiedChallenges,
} from "../../../../store/actions/Auth"

import {
  clearSetMpinState,
  setSetMpinApiToken,
} from "../../../../store/actions/SetMpin"
import {
  setCards,
  setReplacementFee,
  setUser,
} from "../../../../store/actions/User"
import {
  getHeaderText,
  handleApiAuth,
  showCancelButton,
} from "../../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
  MpinErrors,
  ProgramTypes,
} from "../../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
  SetMpinTncActions,
} from "../../../../utils/enums"
import {
  captureEvents,
  consoleError,
  getEnumsFromAU_BlockCodes,
  getEnumsFromFederal_BlockCodes,
  getFlowIdForSetMpin,
  getUnion,
  goToRedirectUrl,
} from "../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../core"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import SetMpinIntro from "./SetMpinIntro"
import SetMpinSuccess from "./SetMpinSuccess"
import styles from "../styles"
import AuthLoader from "../../../core/SkeletonLoader/authLoader"
import { Checkbox } from "../../../../core/Checkbox"
import { toast } from "../../../../core/Toast"
import { showToast1 } from "../../../core/toast"
import AuthService from "../../../../services/AuthService"
import {
  setIssuer,
  setdisabledFunctionsByBlockCode,
} from "../../../../store/actions/Session"
import ForgetMpinIntro from "./../ForgetMpinIntro"
import View from "../../../../nativeBaseReplacements/View"
import Input from "../../../../nativeBaseReplacements/Input"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

// As of now, set mpin screen can be reached from -
// 1. App component
// 2. From middle of an auth by clicking forgot mpin option or mpin gets blocked due to multiple incorrect attempts
// 3. Change mpin option in manage card screen
// Success and failure actions will differ based on the screen the user is coming from and they need to be set in set mpin store
const getProgramTypeFromSession = programType => {
  switch (programType) {
    case "CreditCard":
      return ProgramTypes.CREDIT_CARD
    case "Paylater":
      return ProgramTypes.PAY_LATER
    case "Prepaid":
      return ProgramTypes.PREPAID
    default:
      return ProgramTypes.OTHER
  }
}
const SetMpin = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const nativeToast = useToast()

  const theme = useSelector(state => state.theme)
  const setMpinStore = useSelector(state => state.setMpin)
  const user = useSelector(state => state.user)
  const authStore = useSelector(state => state.auth)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)

  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const setMpinConfig = screen.mpin?.setMpin || {}

  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState("")
  const [showSetMpinSuccess, setShowSetMpinSuccess] = useState(false)
  const [isIntroDone, setIsIntroDone] = useState(false)
  const isMpinEverSet = authStore.afaDetails.config?.isMpinEverSet?.result
  const oldMpinMatchCount =
    config?.auth?.[AuthChallenges.MPIN]?.oldMpinMatchCount
  const [isTncChecked, setIsTncChecked] = useState(
    Boolean(
      isMpinEverSet
        ? setMpinConfig?.tncIsCheckedByDefaultSubsequent
        : setMpinConfig?.tncIsCheckedByDefault,
    ),
  )
  const [pin, setPin] = useState(["", "", "", "", "", "", ""])
  const [confirmPin, setConfirmPin] = useState(["", "", "", "", "", "", ""])
  const [oldMpinMatchedCounter, setOldMpinMatchedCounter] = useState(0)

  const mpin0 = useRef(null)
  const mpin1 = useRef(null)
  const mpin2 = useRef(null)
  const mpin3 = useRef(null)
  const mpin4 = useRef(null)
  const mpin5 = useRef(null)
  const mpin6 = useRef(null)

  const confirmMpin0 = useRef(null)
  const confirmMpin1 = useRef(null)
  const confirmMpin2 = useRef(null)
  const confirmMpin3 = useRef(null)
  const confirmMpin4 = useRef(null)
  const confirmMpin5 = useRef(null)
  const confirmMpin6 = useRef(null)

  // scroll when small screen in use when typing confirm mPIN
  const scrollBy = 150

  // check version
  const isV2 =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1

  const showHeader = isV2
  const headerText = getHeaderText(AuthChallenges.VERIFY_CUSTOMER)
  const mpinSize =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE

  // isLogin is set if coming from app component
  const isLogin = Boolean(location.state?.isLogin)

  // isChangeMpin is set if coming from change mpin option in manage card
  const isChangeMpin = Boolean(location.state?.isChangeMpin)

  const showIntro = isV2 && isLogin && !isIntroDone

  const isMpinSet = authStore.afaDetails.config.isMpinSet.result

  // if tnc action is to activate card, ensure card is not already activated
  const showTnc =
    isV2 &&
    (isMpinEverSet
      ? setMpinConfig.tncEnabledSubsequent
      : setMpinConfig.tncEnabled)

  useEffect(() => {
    captureEvents({ eventName: EventName.SET_MPIN, metadata: {} })
  }, [])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const response = await AuthService.getValidateSession({
        sessionToken: localStorage.getItem("sessionToken"),
      })
      if (
        response.status === 200 &&
        response.data.data.isPromoted &&
        !user.customer?.id
      ) {
        const sessionInfoResponse = await CommonService.getSessionInfo()

        const sessionInfoResult = sessionInfoResponse.data
        if (sessionInfoResponse.status === 200) {
          //API for Block Codes
          let blockCodes = []

          let blockCodeInfo = sessionInfoResult?.data?.blockCodeInfo

          let accountblockCodes = blockCodeInfo?.account?.blockCodes

          let cardBlockCodesArray = blockCodeInfo.cards

          let cardBlockCodes = []

          for (let ind = 0; ind < cardBlockCodesArray?.length; ind++) {
            cardBlockCodes.push(cardBlockCodesArray[ind].blockCode)
          }

          let disabledFunctions = []
          if (sessionInfoResult?.data?.issuerName === Issuers.AU_BANK) {
            let union = getUnion(accountblockCodes, cardBlockCodes)
            blockCodes = union
            disabledFunctions = getEnumsFromAU_BlockCodes(blockCodes)
          }

          if (sessionInfoResult?.data?.issuerName === Issuers.FEDERAL_BANK) {
            let accountStatus = sessionInfoResult.data.account.status
            if (accountStatus === "ACTIVE" || accountStatus === "DORMANT") {
              let union = getUnion(accountblockCodes, cardBlockCodes)
              blockCodes = union
              disabledFunctions = getEnumsFromFederal_BlockCodes(blockCodes)
            } else {
              navigate("/blocked")
            }
          }

          const blockedAccountStatuses = [
            "CLOSED",
            "SUSPENDED",
            "FORCED_SUSPENDED",
            "PENDING_CLOSURE",
            "CHARGE_OFF",
          ]
          if (
            sessionInfoResult?.data?.issuerName !== Issuers.FEDERAL_BANK &&
            sessionInfoResult?.data?.issuerName !== Issuers.AU_BANK
          ) {
            if (
              blockedAccountStatuses.includes(
                sessionInfoResult.data.account.status,
              )
            ) {
              navigate("/blocked")
            }
          }

          if (
            disabledFunctions.includes(AU_BlockCodes.LOGIN_DISABLE) ||
            disabledFunctions.includes(AU_BlockCodes.REGISTRATION_DISABLE) ||
            disabledFunctions.includes(
              AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
            ) ||
            disabledFunctions.includes(AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE)
          ) {
            navigate("/blocked")
          }

          if (
            disabledFunctions.includes(
              Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
            ) ||
            disabledFunctions.includes(
              Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
            )
          ) {
            navigate("/blocked")
          }

          if (sessionInfoResult?.success) {
            dispatch(
              setIssuer({
                issuer: sessionInfoResult.data?.issuerName,
              }),
            )
            dispatch(
              setdisabledFunctionsByBlockCode({
                disabledFunctionsByBlockCode: disabledFunctions,
              }),
            )

            // get session info
            // we cannot rely on summary api to get customer and account as summary api may have auth and auth may
            // require to set mpin which in turn requires customer

            // set user in store
            dispatch(
              setUser({
                customer: sessionInfoResult.data?.customer,
                cards: sessionInfoResult.data?.cards,
                account: sessionInfoResult.data?.account,
                programType: getProgramTypeFromSession(
                  sessionInfoResult.data?.programType,
                ),
                enablePciWidget: sessionInfoResult.data?.enablePciWidget,
              }),
            )
            dispatch(
              setReplacementFee({
                cardReplacementFee: sessionInfoResult?.data?.cards
                  ? sessionInfoResult?.data?.cards[0]?.replacementFee
                  : 0,
              }),
            )
            setLoading(false)
          } else {
            consoleError(sessionInfoResult?.errors)
            setError(true)
            setLoading(false)
            // don't proceed further
            return
          }
          setLoading(false)
        } else {
          consoleError("Error fetching session Info")
          navigate("/error")
          setLoading(false)
        }
      }
      setLoading(false)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!isV2 || (isV2 && !isLogin) || (isV2 && isLogin && isIntroDone)) {
        // api auth for set mpin is to be done before showing the set mpin screen
        // if api token is already set it means api auth is done
        if (setMpinStore.apiToken) return

        if (
          isV2 &&
          session?.disabledFunctionsByBlockCode?.includes(
            Federal_BlockCodes.RESET_MPIN_DISABLE,
          )
        ) {
          navigate("/")
          toast(`Access has been blocked to change ${mpinWord}`)
          return
        }
        setLoading(true)

        await handleApiAuth({
          apiId: ApiIds.SET_MPIN,
          flowId: getFlowIdForSetMpin(oldMpinMatchCount, oldMpinMatchedCounter),
          onAuthSuccess: onSetMpinAuthSuccess,
          onAuthFailure: onSetMpinAuthFailure,
          onAuthCancel: onSetMpinAuthCancel,
          otpReason: "to set mpin",
          mpinReason: "to set mpin",
          toast,
          navigate,
        })

        setLoading(false)
      }
    })()
  }, [isIntroDone, oldMpinMatchedCounter])

  const setMpin = async () => {
    if (pin.join("").length < mpinSize) {
      setError(true)
      toast(`The ${mpinWord} entered is incorrect`)
      return
    }

    if (pin.join("") !== confirmPin.join("")) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast(
        config?.version === PwaVersions.V2_1
          ? "mPINs don't match"
          : "MPINs don't match",
      )
      setSubmitLoading(false)
      return
    }

    const finalPin = pin.join("")
    let sameValidation = false
    let consecutiveValidation = false
    const maxSameAllowed = mpinSize
    const maxConsecutiveAllowed = mpinSize
    let sameCount = 1
    let consecutiveCount = 1

    for (let ind = 1; ind < finalPin.length; ind++) {
      if (finalPin.charAt(ind) === finalPin.charAt(ind - 1)) sameCount++
      else sameCount = 1
      if (finalPin.charAt(ind) == +finalPin.charAt(ind - 1) + 1)
        consecutiveCount++
      else consecutiveCount = 1
      if (sameCount >= maxSameAllowed) sameValidation = true
      if (consecutiveCount >= maxConsecutiveAllowed)
        consecutiveValidation = true
    }

    const continuousValidation =
      setMpinStore?.mpinMetadata?.hasNonContiguousValidation
    const nonRepeatValidation =
      setMpinStore?.mpinMetadata?.hasNonRepetitiveValidation

    if (sameValidation && nonRepeatValidation) {
      toast(`${mpinWord} cannot have ${maxSameAllowed} \nrepetitive digits`)
      return
    }
    if (consecutiveValidation && continuousValidation) {
      toast(
        `${mpinWord} cannot have ${maxConsecutiveAllowed} \nconsecutive digits`,
      )
      return
    }

    setSubmitLoading(true)

    try {
      const mpin = pin.join("")

      const response = await CommonService.setMpin(setMpinStore.apiToken, {
        customerId: user.customer.id,
        programId: user.account.programId,
        mpin,
      })
      const result = response.data

      if (result?.success) {
        // update isMpinSet flag
        dispatch(setIsMpinSet({ isMpinSet: { result: true } }))
        captureEvents({ eventName: EventName.MPIN_UPDATED, metadata: {} })

        // if coming from change mpin then get an mpin verification id since user is not prompted to enter the new mpin
        if (isChangeMpin) {
          const verifyChallengeMpinResponse =
            await CommonService.verifyChallengeMpin({
              customerId: user.customer?.id,
              programId: user.account?.programId,
              mpin,
            })
          const verifyChallengeMpinResult = verifyChallengeMpinResponse.data

          if (verifyChallengeMpinResult?.success) {
            const verifiedChallenges = {
              ...authStore.verifiedChallenges,
              mpinRefId: verifyChallengeMpinResult.data.mpinRefId,
            }

            dispatch(setVerifiedChallenges({ verifiedChallenges }))
          }
        }

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        if (isV2) {
          setShowSetMpinSuccess(true)
        } else {
          await onCompletion()
        }
      } else {
        if (result.errors == MpinErrors.OLD_MPIN_MATCH) {
          // on old mpin delete mpin ref id and prev  mpin token if available, so that get api token can be triggered.
          let verifiedChallenges = { ...authStore.verifiedChallenges }
          // verifiedChallenges.mpinRefId = null
          dispatch(setVerifiedChallenges({ verifiedChallenges }))
          dispatch(setSetMpinApiToken({ apiToken: null }))
          setOldMpinMatchedCounter(oldMpinMatchedCounter + 1)

          toast(
            `Your new ${mpinWord} should be different than the previous ${oldMpinMatchCount} ${mpinWord}`,
          )
          setSubmitLoading(false)
          return
        } else {
          await setMpinStore.onSetMpinFailure(result?.errors)
          consoleError(result?.errors)
        }
        dispatch(setSetMpinApiToken({ apiToken: null }))
      }
    } catch (error) {
      consoleError(error)
      dispatch(setSetMpinApiToken({ apiToken: null }))
      await setMpinStore.onSetMpinFailure()
    }

    setSubmitLoading(false)
  }

  const onSetMpinAuthSuccess = async apiToken => {
    // set api token to be used in set mpin api
    dispatch(setSetMpinApiToken({ apiToken }))
    // navigate back to set mpin screen after auth
    navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin } })
  }

  const onSetMpinAuthFailure = async (error, message) => {
    // first call failure method from store
    await setMpinStore.onSetMpinFailure(error, message)
    // then clear the store
    dispatch(clearSetMpinState())
  }

  const onSetMpinAuthCancel = async message => {
    await setMpinStore.onSetMpinCancel(message)
    dispatch(clearSetMpinState())
  }

  const onCompletion = async () => {
    await setMpinStore.onSetMpinSuccess()
    dispatch(clearSetMpinState())
  }

  const onCancel = async () => {
    await setMpinStore.onSetMpinCancel()
    dispatch(clearSetMpinState())
  }

  const onSetMpinSuccessSubmit = async () => {
    if (isTncChecked) {
      if (
        isMpinEverSet
          ? setMpinConfig.tncActionSubsequent ===
            SetMpinTncActions.ENABLE_DOMESTIC_ONLINE
          : setMpinConfig.tncAction === SetMpinTncActions.ENABLE_DOMESTIC_ONLINE
      ) {
        await handleApiAuth({
          apiId: ApiIds.UPDATE_CARD_CONTROLS,
          onAuthSuccess: onEnableDomesticOnlineSuccess,
          onAuthFailure: onCompletion,
          onAuthCancel: onCompletion,
          otpReason: "to enable domestic online transactions",
          mpinReason: "to enable domestic online transactions",
          toast,
          navigate,
        })
      } else if (
        isMpinEverSet
          ? setMpinConfig.tncActionSubsequent ===
            SetMpinTncActions.ACTIVATE_CARD
          : setMpinConfig.tncAction === SetMpinTncActions.ACTIVATE_CARD
      ) {
        await handleApiAuth({
          apiId: ApiIds.ACTIVATE_CARD,
          onAuthSuccess: onActivateCardSuccess,
          onAuthFailure: onCompletion,
          onAuthCancel: onCompletion,
          otpReason: "to activate card",
          mpinReason: "to activate card",
          toast,
          navigate,
        })
      } else {
        await onCompletion()
      }
    } else {
      await onCompletion()
    }
  }

  const onEnableDomesticOnlineSuccess = async apiToken => {
    try {
      const response = await CardService.updateCardControls(
        apiToken,
        user.cards[0]?.id,
        {
          updateCardControls: [
            {
              txnTags: {
                location: "DOMESTIC",
                txnType: "RETAIL",
                txnChannel: "ECOMMERCE",
              },
              isEnabled: true,
            },
          ],
        },
      )
      const result = response?.data

      if (result?.success) {
        dispatch(setCards({ cards: [result.data] }))
      } else {
        consoleError(
          "Failed to enable domestic online transaction after set mpin. Error: " +
            result.errors,
        )
      }
    } catch (error) {
      consoleError(
        "An exception occurred while enabling domestic online transaction after set mpin. Error: " +
          error,
      )
    }

    await onCompletion()
  }

  const onActivateCardSuccess = async apiToken => {
    try {
      // when activating card from tnc action, as of now activation code is not needed
      const response = await CardService.activateCard(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...user.cards?.[0] }
        tempCard.isPhysicalCardActivated = true
        dispatch(setCards({ cards: [tempCard] }))
      } else {
        consoleError(
          "Failed to activate card after set mpin. Error: " + result.errors,
        )
      }
    } catch (error) {
      consoleError(
        "An exception occurred while activating card after set mpin. Error: " +
          error,
      )
    }

    await onCompletion()
  }

  return loading ? (
    <View h={windowDimensions.height} backgroundColor={theme.backgroundColor}>
      <AuthLoader />
    </View>
  ) : showIntro ? (
    isMpinEverSet ? (
      <ForgetMpinIntro
        setMpinConfig={setMpinConfig}
        onSubmit={() => {
          captureEvents({ eventName: EventName.WELCOME_PAGE_CTA, metadata: {} })
          setIsIntroDone(true)
        }}
      />
    ) : (
      <SetMpinIntro
        setMpinConfig={setMpinConfig}
        onSubmit={() => {
          captureEvents({ eventName: EventName.WELCOME_PAGE_CTA, metadata: {} })
          setIsIntroDone(true)
        }}
      />
    )
  ) : showSetMpinSuccess ? (
    <SetMpinSuccess
      successImageUrl={setMpinConfig.successImageUrl}
      onSubmit={onSetMpinSuccessSubmit}
    />
  ) : (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      {showHeader ? (
        <Header
          text={
            config?.version === PwaVersions.V2_1
              ? `Setup ${mpinWord}`
              : headerText
          }
          onBack={async () => {
            showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
          }}
        />
      ) : (
        <div className='header-disabled'></div>
      )}
      <div className='setMpin'>
        <div
          className='setMpin-widget'
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.widgetBackgroundColor
                : "#FFFFFF",
          }}
        >
          <div>
            {config?.version !== PwaVersions.V2_1 && (
              <>
                <div
                  className='setMpin-widget-heading'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  {`Set ${mpinWord}`}
                </div>
                {config?.version === PwaVersions.V2_1 && (
                  <div
                    className='setMpin-widget-sub-heading'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    {`Set a ${mpinSize} digit ${mpinWord} for secured access`}
                  </div>
                )}
              </>
            )}
            <div
              className='setMpin-widget-subHeading'
              style={{
                fontFamily: theme.fontFamily,
                color: theme.appTextColor,
                marginTop: config?.version === PwaVersions.V2_1 ? "0px" : "",
              }}
            >
              {`Enter ${mpinWord}`}
            </div>
          </div>
          <div className='setMpin-widget-pin-container'>
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin0}
              value={pin[0]}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([text, pin[1], pin[2], pin[3], pin[4], pin[5], pin[6]])
                if (text !== "") {
                  mpin1.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin1}
              value={pin[1]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && pin[1] === "") {
                  mpin0.current.focus()
                  setPin(["", "", pin[2], pin[3], pin[4], pin[5], pin[6]])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([pin[0], text, pin[2], pin[3], pin[4], pin[5], pin[6]])
                if (text !== "") {
                  mpin2.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin2}
              value={pin[2]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && pin[2] === "") {
                  mpin1.current.focus()
                  setPin([pin[0], "", "", pin[3], pin[4], pin[5], pin[6]])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([pin[0], pin[1], text, pin[3], pin[4], pin[5], pin[6]])
                if (text !== "") {
                  mpin3.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin3}
              value={pin[3]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && pin[3] === "") {
                  mpin2.current.focus()
                  setPin([pin[0], pin[1], "", "", pin[4], pin[5], pin[6]])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([pin[0], pin[1], pin[2], text, pin[4], pin[5], pin[6]])
                if (text !== "") {
                  if (mpinSize === 4) {
                    window.scrollBy(0, scrollBy)
                    confirmMpin0.current.focus()
                  } else {
                    mpin4.current.focus()
                  }
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            {mpinSize > 4 && (
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin4}
                value={pin[4]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[4] === "") {
                    mpin3.current.focus()
                    setPin([pin[0], pin[1], pin[2], "", "", pin[5], pin[6]])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([pin[0], pin[1], pin[2], pin[3], text, pin[5], pin[6]])
                  if (text !== "") {
                    if (mpinSize === 5) {
                      window.scrollBy(0, scrollBy)
                      confirmMpin0.current.focus()
                    } else {
                      mpin5.current.focus()
                    }
                  }
                }}
                type='tel'
                inputMode='numeric'
              />
            )}
            {mpinSize > 5 && (
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin5}
                value={pin[5]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[5] === "") {
                    mpin4.current.focus()
                    setPin([pin[0], pin[1], pin[2], pin[3], "", "", pin[6]])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([pin[0], pin[1], pin[2], pin[3], pin[4], text, pin[6]])
                  if (text !== "") {
                    if (mpinSize === 6) {
                      window.scrollBy(0, scrollBy)
                      confirmMpin0.current.focus()
                    } else {
                      mpin6.current.focus()
                    }
                  }
                }}
                type='tel'
                inputMode='numeric'
              />
            )}
            {mpinSize > 6 && (
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={mpin6}
                value={pin[6]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && pin[6] === "") {
                    mpin5.current.focus()
                    setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setPin([pin[0], pin[1], pin[2], pin[3], pin[4], pin[5], text])
                  if (text !== "") {
                    window.scrollBy(0, scrollBy)
                    confirmMpin0.current.focus()
                  }
                }}
                type='tel'
                inputMode='numeric'
              />
            )}
          </div>
          {/* <View mt="12px" display={error ? "flex" : "None"}>
              <Text
                fontFamily={theme.fontFamily}
                font-weight="700"
                font-size="14px"
                line-height="18px"
                letter-spacing="0.02em"
                color="#C2181B"
              >
                {error}
              </Text>
            </View> */}
          <div
            className='setMpin-widget-subHeading'
            style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
          >
            {`Confirm ${mpinWord}`}
          </div>
          <div className='setMpin-widget-pin-container'>
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={confirmMpin0}
              value={confirmPin[0]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && confirmPin[0] === "") {
                  if (mpinSize === 4) {
                    window.scrollBy(0, -scrollBy)
                    mpin3.current.focus()
                    setPin([pin[0], pin[1], pin[2], "", "", "", ""])
                  } else if (mpinSize === 5) {
                    window.scrollBy(0, -scrollBy)
                    mpin4.current.focus()
                    setPin([pin[0], pin[1], pin[2], pin[3], "", "", ""])
                  } else if (mpinSize === 6) {
                    window.scrollBy(0, -scrollBy)
                    mpin5.current.focus()
                    setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                  } else if (mpinSize === 7) {
                    window.scrollBy(0, -scrollBy)
                    mpin6.current.focus()
                    setPin([pin[0], pin[1], pin[2], pin[3], pin[4], pin[5], ""])
                  }

                  setConfirmPin([
                    "",
                    confirmPin[1],
                    confirmPin[2],
                    confirmPin[3],
                    confirmPin[4],
                    confirmPin[5],
                    confirmPin[6],
                  ])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setConfirmPin([
                  text,
                  confirmPin[1],
                  confirmPin[2],
                  confirmPin[3],
                  confirmPin[4],
                  confirmPin[5],
                  confirmPin[6],
                ])
                if (text !== "") {
                  confirmMpin1.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={confirmMpin1}
              value={confirmPin[1]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && confirmPin[1] === "") {
                  confirmMpin0.current.focus()
                  setConfirmPin([
                    "",
                    "",
                    confirmPin[2],
                    confirmPin[3],
                    confirmPin[4],
                    confirmPin[5],
                    confirmPin[6],
                  ])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setConfirmPin([
                  confirmPin[0],
                  text,
                  confirmPin[2],
                  confirmPin[3],
                  confirmPin[4],
                  confirmPin[5],
                  confirmPin[6],
                ])
                if (text !== "") {
                  confirmMpin2.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={confirmMpin2}
              value={confirmPin[2]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && confirmPin[2] === "") {
                  confirmMpin1.current.focus()
                  setConfirmPin([
                    confirmPin[0],
                    "",
                    "",
                    confirmPin[3],
                    confirmPin[4],
                    confirmPin[5],
                    confirmPin[6],
                  ])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setConfirmPin([
                  confirmPin[0],
                  confirmPin[1],
                  text,
                  confirmPin[3],
                  confirmPin[4],
                  confirmPin[5],
                  confirmPin[6],
                ])
                if (text !== "") {
                  confirmMpin3.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={confirmMpin3}
              value={confirmPin[3]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && confirmPin[3] === "") {
                  confirmMpin2.current.focus()
                  setConfirmPin([
                    confirmPin[0],
                    confirmPin[1],
                    "",
                    "",
                    confirmPin[4],
                    confirmPin[5],
                    confirmPin[6],
                  ])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setConfirmPin([
                  confirmPin[0],
                  confirmPin[1],
                  confirmPin[2],
                  text,
                  confirmPin[4],
                  confirmPin[5],
                  confirmPin[6],
                ])
                if (text !== "") {
                  mpinSize === 4
                    ? confirmMpin3.current.blur()
                    : confirmMpin4.current.focus()
                }
              }}
              type='tel'
              inputMode='numeric'
            />
            {mpinSize > 4 && (
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={confirmMpin4}
                value={confirmPin[4]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && confirmPin[4] === "") {
                    confirmMpin3.current.focus()
                    setConfirmPin([
                      confirmPin[0],
                      confirmPin[1],
                      confirmPin[2],
                      "",
                      "",
                      confirmPin[5],
                      confirmPin[6],
                    ])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setConfirmPin([
                    confirmPin[0],
                    confirmPin[1],
                    confirmPin[2],
                    confirmPin[3],
                    text,
                    confirmPin[5],
                    confirmPin[6],
                  ])
                  if (text !== "") {
                    mpinSize === 5
                      ? confirmMpin4.current.blur()
                      : confirmMpin5.current.focus()
                  }
                }}
                type='tel'
                inputMode='numeric'
              />
            )}
            {mpinSize > 5 && (
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={confirmMpin5}
                value={confirmPin[5]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && confirmPin[5] === "") {
                    confirmMpin4.current.focus()
                    setConfirmPin([
                      confirmPin[0],
                      confirmPin[1],
                      confirmPin[2],
                      confirmPin[3],
                      "",
                      "",
                      confirmPin[6],
                    ])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setConfirmPin([
                    confirmPin[0],
                    confirmPin[1],
                    confirmPin[2],
                    confirmPin[3],
                    confirmPin[4],
                    text,
                    confirmPin[6],
                  ])
                  if (text !== "") {
                    mpinSize === 6
                      ? confirmMpin5.current.blur()
                      : confirmMpin6.current.focus()
                  }
                }}
                type='tel'
                inputMode='numeric'
              />
            )}
            {mpinSize > 6 && (
              <Input
                {...styles.textInput}
                borderColor={error ? "#C2181B" : "fff"}
                ref={confirmMpin6}
                value={confirmPin[6]}
                onKeyPress={({ nativeEvent }) => {
                  if (nativeEvent.key === "Backspace" && confirmPin[6] === "") {
                    confirmMpin5.current.focus()
                    setConfirmPin([
                      confirmPin[0],
                      confirmPin[1],
                      confirmPin[2],
                      confirmPin[3],
                      confirmPin[4],
                      "",
                      "",
                    ])
                  }
                }}
                onChangeText={text => {
                  if ((text < "0" || text > "9") && text !== "") return false
                  setConfirmPin([
                    confirmPin[0],
                    confirmPin[1],
                    confirmPin[2],
                    confirmPin[3],
                    confirmPin[4],
                    confirmPin[5],
                    text,
                  ])
                  if (text !== "") {
                    confirmMpin6.current.blur()
                  }
                }}
                type='tel'
                inputMode='numeric'
              />
            )}
          </div>
        </div>
        <div className='setMpin-cta-container'>
          <div
            className='setMpin-cta'
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
          >
            {showTnc && (
              <div
                className='setMpin-tnc'
                style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
              >
                <div
                  className='setMpin-tnc-container'
                  style={{
                    backgroundColor:
                      config?.version === PwaVersions.V2 ||
                      config?.version === PwaVersions.V2_1
                        ? theme.widgetBackgroundColor
                        : "#FFFFFF",
                  }}
                >
                  <div
                    onClick={() => setIsTncChecked(!isTncChecked)}
                    className='setMpin-tnc-checkbox'
                  >
                    <Checkbox
                      marginLeft='-3px'
                      {...styles.tncCheckBox}
                      className={
                        isTncChecked
                          ? "setMpin-tnc-checkBox-checked"
                          : "setMpin-tnc-checkBox-notchecked"
                      }
                      checkmarkClassName={
                        isTncChecked
                          ? "setMpin-tnc-checkMark-checked"
                          : "setMpin-tnc-checkMark-notchecked"
                      }
                      isChecked={isTncChecked}
                      style={{
                        backgroundColor: isTncChecked ? theme.color1 : null,
                      }}
                    />
                  </div>
                  <div
                    className='setMpin-tnc-text'
                    style={{ color: theme.appTextColor }}
                  >
                    <EmbeddedLink
                      text={
                        isMpinEverSet
                          ? setMpinConfig.tncTextSubsequent
                          : setMpinConfig.tncText
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            <ColorButton
              text={"Submit"}
              isDisable={
                (showTnc &&
                  (isMpinEverSet
                    ? setMpinConfig.tncIsMandatorySubsequent
                    : setMpinConfig.tncIsMandatory) &&
                  !isTncChecked) ||
                pin.join("").length !== mpinSize ||
                confirmPin.join("").length !== mpinSize
              }
              onPress={setMpin}
              isLoading={submitLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetMpin
