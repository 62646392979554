import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../auth/ApiIds"
import CardService from "../../services/CardService"
import { handleApiAuth } from "../../utils/auth"
import { DisplayConfig, ProgramTypes } from "../../utils/constants"
import { PwaVersions } from "../../utils/enums"
import { consoleError } from "../../utils/functions"
import { showToast1 } from "../core/toast"
import Home_v2 from "./screens/Home/home_v2"
import { useToast } from "../../nativeBaseReplacements/useToast"

const ManageCard = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const nativeToast = useToast()
  const navigate = useNavigate()

  const [isResetPinLoading, setIsResetPinLoading] = useState(false)

  const account = user.account
  const card = user.cards?.[0]

  const isAccountActive = account.status === "ACTIVE"

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const alertToast = () => {
    toast(t("activation.activateCardtoEnableOption"))
  }

  const toast1 = message => {
    showToast1({ toast, theme, message })
  }

  const handleResetPinClick = async () => {
    if (!isResetPinLoading) {
      setIsResetPinLoading(true)
      if (!user.enablePciWidget) {
        navigate("/ResetPin")
      } else {
        await handleApiAuth({
          apiId: ApiIds.PCI_SET_PIN_URL,
          onAuthSuccess: onAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("activation.resetPinReason"),
          mpinReason: t("activation.resetPinReason"),
          toast,
          navigate,
        })
      }
      setIsResetPinLoading(false)
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getResetPinWidget(
        apiToken,
        user.cards[0]?.id,
        {
          callbackUrl: process.env.REACT_APP_URL + "/PinReset",
        },
      )
      const result = response?.data
      if (result?.success) {
        window.location.href = result.data
      } else {
        consoleError(result.errors)
        toast1(t("mpin.errorSettingPin"))
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast1(t("mpin.errorSettingPin"))
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast1(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast1(message)
    navigate("/", { replace: true })
  }

  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
        maxWidth: DisplayConfig.MAX_WIDTH,
        width: "100%",
      }}
    >
      {config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1 ||
      config?.version === PwaVersions.V3 ? (
        <Home_v2
          isAccountActive={isAccountActive}
          theme={theme}
          card={card}
          user={user}
          ProgramTypes={ProgramTypes}
          isResetPinLoading={isResetPinLoading}
          alertToast={alertToast}
          handleResetPinClick={handleResetPinClick}
          session={session}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default ManageCard
