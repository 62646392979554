import moment from "moment"
import { useTranslation } from "react-i18next"
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import DatePicker from "react-date-picker"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CommonService from "../../../services/CommonService"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import {
  AuthChallenges,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
} from "../../../utils/constants"
import {
  AuthenticationType,
  EventName,
  PwaVersions,
} from "../../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  isWebKitBrowser,
  setInputValue,
  truncateString,
  handleWebKitTextInputFocus,
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import styles from "./styles"
import ActivationAuthHeader_v3 from "../../core/ActivationAuthHeader_v3"
import { BottomSheet } from "react-spring-bottom-sheet"
import crossIcon from "../../../assets/images/v3/closeBtn.svg"
import Divider from "../../core/Divider/v3/Divider"
import { useBottomSheet } from "../BottomSheetContext"
import Error_v3 from "../../core/Error_v3"
import FormControl, {
  FormControlErrorMessage,
} from "../../../nativeBaseReplacements/FormControl"
import Input from "../../../nativeBaseReplacements/Input"
import WarningOutlineIcon from "../../../nativeBaseReplacements/WarningOutlineIcon"
import Text from "../../../nativeBaseReplacements/Text"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import DividerV3 from "../../core/Divider/v3/DividerV3"

export const ErrorMsgToIgnore = Object.freeze({
  MSG_1: "switchAccountNumber is not found",
  MSG_2: "Customer data is not found for the account",
  MSG_3: "Unable to find",
})

const CustomerAuthBottomSheet = forwardRef(({ isOpen, onClose }, ref) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const theme = useSelector(state => state.theme)
  const authStore = useSelector(state => state.auth)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errCount, setErrCount] = useState(0)
  const [errorCount, setErrorCount] = useState(0)
  const [pan, setPan] = useState("")
  const [dob, setDob] = useState("")
  const [panInvalid, setPanInvalid] = useState(false)
  const [dateInvalid, setDateInvalid] = useState(false)
  const [enableVerify, setEnableVerify] = useState(false)
  const [changeFocus, setChangeFocus] = useState(false)
  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.VERIFY_CUSTOMER)
  const [errMsg, setErrMsg] = useState("")

  const panInput = useRef(null)
  const dobInput = useRef(null)
  const bottomSheetContext = useBottomSheet()

  const toast = (message, hasExclamationMark = false) => {
    showToast1({ nativeToast, theme, message, hasExclamationMark })
  }

  useEffect(() => {
    captureEvents({
      eventName: EventName.AUTH_CUSTOMER,
      metadata: {},
    })
  }, [])

  useEffect(() => {
    checkIsPanInvalid()
    checkIsDateValid()
  }, [pan, dob])

  useEffect(() => {
    const isValidDOB = dob.length === 10 && moment(dob, "DD/MM/YYYY").isValid()
    const isValidPan = pan.length === 10
    const isValidDate = !panInvalid && !dateInvalid

    if (isValidDOB && isValidPan && isValidDate) {
      if (!enableVerify) {
        setEnableVerify(true)
      }
    } else {
      if (enableVerify) {
        setEnableVerify(false)
      }
    }
  }, [panInvalid, dateInvalid, pan, dob])

  const checkAge = () => {
    let today = new Date()
    let birthDate = moment(dob)
    let age = today.getFullYear() - birthDate.year()
    let m = today.getMonth() - birthDate.month()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.date())) {
      age--
    }
    return age
  }

  const checkIsDateValid = () => {
    setDateInvalid(false)
    return true
    if (!!dob) return false
    let dateFor = moment(dob)
    let yearInput = document.querySelector('[aria-label="year-label"]')
    let year = dateFor.year()
    let today = moment()
    let age = checkAge()
    let isValid = checkInvalidDate()
    if ((year + "").length >= 4 && !isValid) {
      setErrMsg(t("customerAuth.invalidDateMsg"))
      setDateInvalid(true)
      return false
    } else if (
      (year + "").length >= 4 &&
      (year < 1900 || dateFor > today || (age >= 0 && age < 18))
    ) {
      if (dateFor > today) {
        setErrMsg(t("customerAuth.futureDateErrorMsg"))
      } else if (year < 1900) {
        setErrMsg(t("customerAuth.dobErrorMsg", { date: "01-01-1900" }))
      } else if (age >= 0 && age < 18) {
        setErrMsg(t("customerAuth.ageErrorMsg", { noYears: "18" }))
      }
      setDateInvalid(true)
      return false
    } else {
      year.toString().length === 4 ? yearInput.blur() : console.log()
    }
    setDateInvalid(false)
  }

  function checkInvalidDate() {
    let dayInput = document.querySelector('[aria-label="day-label"]')
    let monthInput = document.querySelector('[aria-label="month-label"]')
    let yearInput = document.querySelector('[aria-label="year-label"]')
    let enteredDate =
      dayInput.value + "/" + monthInput.value + "/" + yearInput.value
    let momentDate = moment(enteredDate, "DD/MM/YYYY")
    let isValid = momentDate.isValid()
    if (yearInput.length < 4) return false
    return isValid
  }

  function onBackClick(event) {
    let dayInput = document.querySelector('[aria-label="day-label"]')
    let monthInput = document.querySelector('[aria-label="month-label"]')
    checkIsDateValid()

    let label = event?.target?.ariaLabel
    let value = event?.target?.value
    if (value == "") {
      if (changeFocus) {
        if (label === "year-label") {
          monthInput?.focus()
        } else if (label === "month-label") {
          dayInput?.focus()
        }
        setChangeFocus(false)
      }
      setChangeFocus(true)
    } else {
      setChangeFocus(false)
    }
  }

  const checkIsPanInvalid = () => {
    if (pan.length < 10) {
      setPanInvalid(false)
      return true
    }
    for (let ind = 0; ind < pan.length; ind++) {
      let newPan = pan
      let currChar = newPan.charAt(ind)
      if ((ind >= 0 && ind <= 4) || ind === 9) {
        if (currChar < "A" || currChar > "Z") {
          setPanInvalid(true)
          return true
        }
      } else if (ind >= 5 && ind <= 8) {
        if (currChar < "0" || currChar > "9") {
          setPanInvalid(true)
          return true
        }
      }
    }
    setPanInvalid(false)
    return false
  }

  const setDate = useCallback(
    date => {
      if (
        (date.length > dob.length && date[date.length - 1] === "/") ||
        date.length > 10
      ) {
        return
      }
      date = date.replace(/[^0-9/]/g, "")
      ;(date.length === 2 || date.length === 5) && date.length > dob.length
        ? setDob(`${date}/`)
        : setDob(date)
    },
    [dob],
  )
  const verifyCustomer = async () => {
    if (pan?.length !== 10) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast(t("customerAuth.panErrorMsg", { noDigits: "10" }), true)
      return
    }
    if (!dob) {
      setError(true)
      setDateInvalid(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast(t("customerAuth.dobInputErrorMsg"), true)
      return
    }

    if (panInvalid || dateInvalid) {
      return false
    }
    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeCustomer({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        accountId: user.account.id,
        applicationId: !user.account.id
          ? localStorage.getItem("applicationId")
          : null,
        pan,
        dob: moment(dob, "DD/MM/YYYY").format("YYYYMMDD"),
      })
      const result = response.data

      if (result?.success) {
        setError(false)

        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          customerRefId: result.data.verificationRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))
        await onFactorCompletion(
          navigate,
          config?.version,
          null,
          bottomSheetContext,
        )
      } else {
        setError(true)
        setErrCount(result?.errors?.attemptsLeft)
        let msg = ""
        let blockedUntilFromApi = result?.errors?.blockedUntil
        if (result?.errors.attemptsLeft == 0) {
          bottomSheetContext.closeBottomSheet()
          bottomSheetContext.closeBottomSheet2()
          navigate("/AttemptExhausted", {
            state: {
              type: AuthenticationType.CUSTOMER_VERIFY,
              time: blockedUntilFromApi,
            },
          })
        }
        setErrorCount(errorCount + 1)
        //add condition here
        if (
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_1) ||
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_2) ||
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_3)
        ) {
          msg = `${t("customerAuth.genericErrorMsg")}`
        } else if (result?.errors.message === "Verification failed") {
          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: {},
          })
          msg = `${t("customerAuth.invalidDetailsErrorMsg")} \nYou have ${
            errorCount === 0
              ? "two attempts remaining"
              : errorCount === 1
                ? "one attempt remaining"
                : errorCount === 2
                  ? "Last attempt"
                  : ""
          }`
        } else if (result?.errors.message.length > 45) {
          msg = `${t("customerAuth.genericErrorMsg")}`
        } else {
          msg = truncateString(result?.errors.message, 45)
        }
        captureEvents({
          eventName: EventName.INCORRECT_INPUT,
          metadata: {},
        })
      }
    } catch (error) {
      bottomSheetContext.closeBottomSheet()
      await authStore.onAuthFailure(error, t("customerAuth.genericErrorMsg"))
    }

    setSubmitLoading(false)
  }

  useEffect(() => {
    document.addEventListener("focusin", handleWebKitTextInputFocus)
    return () => {
      document.removeEventListener("focusin", handleWebKitTextInputFocus)
    }
  }, [])

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className='authCustomer'>
        <div className='authCustomer-container'>
          <div
            className={
              config?.version === PwaVersions.V2
                ? "authCustomer-container-content-v2"
                : "authCustomer-container-content"
            }
            // className='authSmsOtp-widget'
            style={{
              backgroundColor:
                config?.version === PwaVersions.V2
                  ? theme.widgetBackgroundColor
                  : null,
            }}
          >
            <div className='bottom-sheet'>
              <div
                style={{ marginLeft: "-15px", marginBottom: "0px" }}
                className='bottom-sheet-header'
              >
                <div className='v3-verify-otp-title v3-verify-otp-title-customer'>
                  <p className='v3-customer-auth-text'>
                    {t("customerAuth.verifyIdentityToProceed")}
                  </p>
                  <div
                    className='v3-verify-otp-close-btn-customer'
                    onClick={onClose}
                  >
                    <img src={crossIcon} />
                  </div>
                </div>
              </div>
            </div>
            <DividerV3
              style={{ marginLeft: "-18px", width: "120%", marginBottom: 0 }}
            />
            <div
              className='authCustomer-input'
              style={{
                marginTop: "28px",
              }}
            >
              <div
                style={{
                  marginLeft: "7px",
                }}
                className='v3-authCustomer-input-label v3-customer-auth-btm-sheet-pan-text '
              >
                {t("customerAuth.panNo")}
              </div>
              <FormControl isInvalid={panInvalid}>
                <Input
                  width='100%'
                  fontSize={14}
                  backgroundColor={theme.v3.rawColors.leadingWhite}
                  placeholderTextColor={
                    theme.v3.rawColors.tertiaryNeutral.color2
                  }
                  borderWidth='0px'
                  borderBottomWidth={"1px"}
                  py={3}
                  my={2}
                  focusOutlineColor={theme.v3.rawColors.secondary.color1}
                  // borderColor={error ? "#C2181B" : "#ECECEC"}
                  placeholder='Enter PAN here'
                  value={pan}
                  ref={panInput}
                  onChange={e => {
                    const finalValue = e.target.value
                      ?.replace(/\W/g, "")
                      ?.toUpperCase()
                    setInputValue(e, finalValue)

                    let dayInput = document.querySelector(
                      '[aria-label="day-label"]',
                    )
                    if (finalValue.length <= 10) {
                      setPan(finalValue)
                    }
                    if (finalValue.length == 10) {
                      dobInput.current.focus()
                    }
                  }}
                />
                <FormControlErrorMessage
                  leftIcon={<WarningOutlineIcon size='xs' />}
                >
                  {t("customerAuth.invalidPan")}
                </FormControlErrorMessage>
              </FormControl>
              <div className='authCustomer-input-container'>
                <div
                  className='v3-authCustomer-input-label v3-customer-auth-btm-sheet-pan-text'
                  style={{
                    marginLeft: "7px",
                  }}
                >
                  {t("customerAuth.dob")}
                </div>
                <style>{styles.v3datePickerStyle}</style>
                <FormControl isInvalid={dateInvalid}>
                  <Input
                    onFocus={() => {
                      setChangeFocus(false)
                    }}
                    value={dob}
                    ref={dobInput}
                    onChange={e => {
                      setDate(e.target.value)
                    }}
                    width='100%'
                    fontSize={14}
                    backgroundColor={theme.v3.rawColors.leadingWhite}
                    placeholderTextColor={
                      theme.v3.rawColors.tertiaryNeutral.color2
                    }
                    borderWidth='0px'
                    borderBottomWidth={"1px"}
                    py={3}
                    my={2}
                    focusOutlineColor={theme.v3.rawColors.secondary.color1}
                    // borderColor={error ? "#C2181B" : "#ECECEC"}
                    placeholder='DD/MM/YYYY'
                  />
                  <FormControlErrorMessage
                    leftIcon={<WarningOutlineIcon size='xs' />}
                  >
                    {errMsg}
                  </FormControlErrorMessage>
                </FormControl>
                <div>
                  <Text
                    font-weight='400'
                    font-size='12px'
                    line-height='18px'
                    color={`${
                      config?.version === PwaVersions.V2 ||
                      config?.version === PwaVersions.V2_1
                        ? theme.appTextColor
                        : "#303030"
                    }:alpha.80`}
                  ></Text>
                </div>
              </div>
            </div>
            <div className='v3-error-msg-container'>
              {error && (
                <>
                  {error && (
                    <Error_v3
                      type={AuthenticationType.CUSTOMER_VERIFY}
                      attempts={errCount}
                      customClassName={"customerAuth"}
                    />
                  )}
                </>
              )}
            </div>
            <div className='authCustomer-button-container'>
              <div
                className='authCustomer-button'
                style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
              >
                <DividerV3 />
                <ColorButton
                  text={"Verify"}
                  isDisable={!enableVerify}
                  onPress={verifyCustomer}
                  isLoading={submitLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BottomSheet>
  )
})

export default CustomerAuthBottomSheet
