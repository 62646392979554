import React, { useEffect, useRef } from "react"
import RewardTransaction_v3 from "./RewardTransaction_v3"
import { useSelector } from "react-redux"

const RewardTransactionList = ({
  transactionList,
  onTransactionClick,
  isExpiring = false,
  isReward = false,
  isCashback = false,
}) => {
  const rewardsListRef = useRef(null)
  const { isRewardsLoaded } = useSelector(state => state.common)

  const handleTransactionClick = transaction => {
    if (onTransactionClick) {
      onTransactionClick(transaction)
    }
  }

  useEffect(() => {
    if (isRewardsLoaded) {
      rewardsListRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })

      const adjustScrollPosition = () => {
        const yOffset = -150
        const y =
          rewardsListRef.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset
        window.scrollTo({ top: y, behavior: "smooth" })
      }

      setTimeout(adjustScrollPosition, 100)
    }
  }, [isRewardsLoaded])

  return (
    <div ref={rewardsListRef}>
      {transactionList?.map(transaction => (
        <RewardTransaction_v3
          transaction={transaction}
          isReward={isReward}
          isCashback={isCashback}
          isLast={
            isExpiring &&
            transactionList[transactionList.length - 1] === transaction
          }
          isExpiring={isExpiring}
          onTransactionClick={() => handleTransactionClick(transaction)}
          key={transaction?.id}
        />
      ))}
    </div>
  )
}

export default RewardTransactionList
