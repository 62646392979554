import {
  SET_CAMPAIGN_BENEFITS,
  SET_CVP_BENEFITS,
  SET_MERCHANT_BENEFITS,
  SET_THIRD_PARTY_BENEFITS,
  SET_CODE_REVEALED,
} from "../ActionTypes"

export const setCvpBenefits = data => dispatch => {
  dispatch({
    type: SET_CVP_BENEFITS,
    payload: data,
  })
}

export const setCampaignBenefits = data => dispatch => {
  dispatch({
    type: SET_CAMPAIGN_BENEFITS,
    payload: data,
  })
}

export const setMerchantBenefits = data => dispatch => {
  dispatch({
    type: SET_MERCHANT_BENEFITS,
    payload: data,
  })
}

export const setThirdPartyBenefits = data => dispatch => {
  dispatch({ type: SET_THIRD_PARTY_BENEFITS, payload: data })
}
