import View from "./View"

const Link = ({ _text, onPress, isUnderlined, _web, ...restProps }) => {
  const { color } = _text
  return (
    <View
      role='link'
      {...restProps}
      onClick={onPress}
      {..._web}
      style={{ ...restProps.style, flexDirection: "row", color }}
    />
  )
}

export default Link
