import React from "react"

const TriangleExclamationSvg = ({ color = "#E72B2B", ...props }) => (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.604 10.0054L8.74996 2.79762C8.00329 1.40112 5.99721 1.40112 5.24996 2.79762L1.39592 10.0054C0.711084 11.2864 1.64094 12.8333 3.09636 12.8333H10.9036C12.3584 12.8333 13.2888 11.2858 12.604 10.0054ZM6.56246 5.83334C6.56246 5.59184 6.75846 5.39584 6.99996 5.39584C7.24146 5.39584 7.43746 5.59184 7.43746 5.83334V8.16668C7.43746 8.40818 7.24146 8.60418 6.99996 8.60418C6.75846 8.60418 6.56246 8.40818 6.56246 8.16668V5.83334ZM7.01164 10.5C6.68964 10.5 6.42532 10.2387 6.42532 9.91668C6.42532 9.59468 6.6838 9.33334 7.0058 9.33334H7.01164C7.33422 9.33334 7.59497 9.59468 7.59497 9.91668C7.59497 10.2387 7.33364 10.5 7.01164 10.5Z'
      fill={color}
    />
  </svg>
)

export default TriangleExclamationSvg
