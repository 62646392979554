import React from "react"
import { useSelector } from "react-redux"

const PosIcon = ({ size, color, className = "", styles }) => {
  const theme = useSelector(state => state.theme)
  return (
    <div style={{ ...styles, width: size }}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 260 260'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M26 205V227.245C26 231.276 29.7066 234.555 34.2428 234.555H113.108C117.645 234.555 121.351 231.276 121.351 227.245L121.352 205H26ZM67.691 225.465H43.8283C42.7088 225.465 41.803 224.662 41.803 223.673C41.803 222.684 42.7088 221.881 43.8283 221.881H67.691C68.8105 221.881 69.7164 222.684 69.7164 223.673C69.7164 224.662 68.8105 225.465 67.691 225.465ZM87.8057 217.667H43.8286C42.7091 217.667 41.8033 216.864 41.8033 215.875C41.8033 214.885 42.7091 214.083 43.8286 214.083H87.8057C88.9251 214.083 89.831 214.885 89.831 215.875C89.831 216.864 88.9251 217.667 87.8057 217.667Z'
          fill={theme?.appTextColor}
        />
        <path
          d='M148.396 61.4316L148.397 38.8643C148.397 33.0669 142.91 28.3489 136.166 28.3489L124.14 28.3471V11.1339C124.14 7.74626 120.945 5 117.005 5H31.3922C27.4519 5 24.2577 7.74621 24.2577 11.1339V28.3471H12.2308C5.48765 28.3471 0 33.0651 0 38.8625V194.485C0 200.282 5.48765 205 12.2308 205H136.167C142.911 205 148.398 200.282 148.398 194.485V169.385C148.398 169.385 148.396 163.378 148.396 157.116V73.695C148.396 67.4314 148.396 61.4316 148.396 61.4316ZM105.591 161.996V149.728H119.86V161.996H105.591ZM119.86 173.773V186.041H105.591V173.773H119.86ZM105.591 137.951V125.683H119.86V137.951H105.591ZM28.5362 109.274V72.4715H119.858V109.274H28.5362ZM67.0625 161.995V149.727H81.3316V161.995H67.0625ZM81.3316 173.772V186.04H67.0625V173.772H81.3316ZM67.0625 137.95V125.682H81.3316V137.95H67.0625ZM28.5362 161.995V149.727H42.8053V161.995H28.5362ZM42.8053 173.772V186.04H28.5362V173.772H42.8053ZM28.5362 137.95V125.682H42.8053V137.95H28.5362ZM38.5247 17.2642H109.87V44.253H122.712V51.6135H25.6817V44.253H38.5237L38.5247 17.2642Z'
          fill={theme?.appTextColor}
        />
      </svg>
    </div>
  )
}

export default PosIcon
