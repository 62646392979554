import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import DatePicker from "react-date-picker"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CommonService from "../../../services/CommonService"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import { AuthChallenges, DisplayConfig } from "../../../utils/constants"
import { EventName, PwaVersions } from "../../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  setInputValue,
  truncateString,
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import styles from "./styles"
import FormControl, {
  FormControlErrorMessage,
} from "../../../nativeBaseReplacements/FormControl"
import View from "../../../nativeBaseReplacements/View"
import Text from "../../../nativeBaseReplacements/Text"
import WarningOutlineIcon from "../../../nativeBaseReplacements/WarningOutlineIcon"
import Input from "../../../nativeBaseReplacements/Input"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

export const ErrorMsgToIgnore = Object.freeze({
  MSG_1: "switchAccountNumber is not found",
  MSG_2: "Customer data is not found for the account",
  MSG_3: "Unable to find",
})

const Customer = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const [theme, authStore, user, config, session, screen] = useSelector(
    state => [
      state.theme,
      state.auth,
      state.user,
      state.config,
      state.session,
      state.screen,
    ],
  )
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pan, setPan] = useState("")
  const [dob, setDob] = useState("")
  const [panInvalid, setPanInvalid] = useState(false)
  const [dateInvalid, setDateInvalid] = useState(false)
  const [enableVerify, setEnableVerify] = useState(false)
  const [changeFocus, setChangeFocus] = useState(false)
  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.VERIFY_CUSTOMER)
  const [errMsg, setErrMsg] = useState("")

  const panInput = useRef(null)
  const dobInput = useRef(null)

  const toast = (message, hasExclamationMark = false) => {
    showToast1({ nativeToast, theme, message, hasExclamationMark })
  }

  useEffect(() => {
    captureEvents({
      eventName: EventName.AUTH_CUSTOMER,
      metadata: {},
    })
  }, [])

  useEffect(() => {
    checkIsPanInvalid()
    checkIsDateValid()
  }, [pan, dob])

  useEffect(() => {
    let year = moment(dob).year()
    if (
      (year + "").length < 4 ||
      pan.length !== 10 ||
      !dob ||
      panInvalid ||
      dateInvalid
    ) {
      setEnableVerify(false)
    } else {
      setEnableVerify(true)
    }
  }, [panInvalid, dateInvalid, pan, dob])

  const checkAge = () => {
    let today = new Date()
    let birthDate = moment(dob)
    let age = today.getFullYear() - birthDate.year()
    let m = today.getMonth() - birthDate.month()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.date())) {
      age--
    }
    return age
  }

  const checkIsDateValid = () => {
    let dateFor = moment(dob)
    let yearInput = document.querySelector('[aria-label="year-label"]')
    let year = dateFor.year()
    let today = moment()
    let age = checkAge()
    let isValid = checkInvalidDate()
    if ((year + "").length >= 4 && !isValid) {
      setErrMsg(t("customerAuth.invalidDateMsg"))
      setDateInvalid(true)
      return false
    } else if (
      (year + "").length >= 4 &&
      (year < 1900 || dateFor > today || (age >= 0 && age < 18))
    ) {
      if (dateFor > today) {
        setErrMsg(t("customerAuth.futureDateErrorMsg"))
      } else if (year < 1900) {
        setErrMsg(t("customerAuth.dobErrorMsg", { date: "01-01-1900" }))
      } else if (age >= 0 && age < 18) {
        setErrMsg(t("customerAuth.ageErrorMsg", { noYears: "18" }))
      }
      setDateInvalid(true)
      return false
    } else {
      year.toString().length === 4 ? yearInput.blur() : console.log()
    }
    setDateInvalid(false)
  }

  function checkInvalidDate() {
    let dayInput = document.querySelector('[aria-label="day-label"]')
    let monthInput = document.querySelector('[aria-label="month-label"]')
    let yearInput = document.querySelector('[aria-label="year-label"]')
    let enteredDate =
      dayInput.value + "/" + monthInput.value + "/" + yearInput.value
    let momentDate = moment(enteredDate, "DD/MM/YYYY")
    let isValid = momentDate.isValid()
    if (yearInput.length < 4) return false
    return isValid
  }

  function onBackClick(event) {
    let dayInput = document.querySelector('[aria-label="day-label"]')
    let monthInput = document.querySelector('[aria-label="month-label"]')
    checkIsDateValid()

    let label = event?.target?.ariaLabel
    let value = event?.target?.value
    if (value == "") {
      if (changeFocus) {
        if (label === "year-label") {
          monthInput?.focus()
        } else if (label === "month-label") {
          dayInput?.focus()
        }
        setChangeFocus(false)
      }
      setChangeFocus(true)
    } else {
      setChangeFocus(false)
    }
  }

  const checkIsPanInvalid = () => {
    if (pan.length < 10) {
      setPanInvalid(false)
      return true
    }
    for (let ind = 0; ind < pan.length; ind++) {
      let newPan = pan
      let currChar = newPan.charAt(ind)
      if ((ind >= 0 && ind <= 4) || ind === 9) {
        if (currChar < "A" || currChar > "Z") {
          setPanInvalid(true)
          return true
        }
      } else if (ind >= 5 && ind <= 8) {
        if (currChar < "0" || currChar > "9") {
          setPanInvalid(true)
          return true
        }
      }
    }
    setPanInvalid(false)
    return false
  }

  const verifyCustomer = async () => {
    if (pan.length !== 10) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast(t("customerAuth.panErrorMsg", { noDigits: "10" }), true)
      return
    }
    if (!dob) {
      setError(true)
      setDateInvalid(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast(t("customerAuth.dobInputErrorMsg"), true)
      return
    }

    if (panInvalid || dateInvalid) {
      return false
    }
    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeCustomer({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        accountId: user.account.id,
        applicationId: !user.account.id
          ? localStorage.getItem("applicationId")
          : null,
        pan,
        dob: moment(dob).format("YYYYMMDD"),
      })
      const result = response.data

      if (result?.success) {
        setError(false)

        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          customerRefId: result.data.verificationRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        await onFactorCompletion(navigate)
      } else {
        let msg = ""
        let errors = result.errors

        let attemptsLeftFromApi = errors?.attemptsLeft
        let blockedUntilFromApi = errors?.blockedUntil

        if (config?.version === PwaVersions.V2_1 && blockedUntilFromApi) {
          navigate("/BlockedAttemptExhausted", {
            state: {
              time: blockedUntilFromApi,
              from: "Customer",
              title: "Alert",
            },
            replace: true,
          })
        }
        //add condition here
        if (
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_1) ||
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_2) ||
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_3)
        ) {
          msg = `${t("customerAuth.genericErrorMsg")}`
        } else if (
          result?.errors.message === "Verification failed" &&
          attemptsLeftFromApi > 0
        ) {
          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: {},
          })
          msg = `${t("customerAuth.invalidDetailsErrorMsg")} \nYou have ${
            attemptsLeftFromApi == 3
              ? "three attempts remaining"
              : attemptsLeftFromApi == 2
                ? "two attempts remaining"
                : attemptsLeftFromApi === 1
                  ? "one attempt remaining"
                  : ""
          }`
        } else if (result?.errors.message.length > 45) {
          msg = `${t("customerAuth.genericErrorMsg")}`
        } else {
          msg = truncateString(result?.errors.message, 45)
        }
        captureEvents({
          eventName: EventName.INCORRECT_INPUT,
          metadata: {},
        })
        toast(msg, true)
      }
    } catch (error) {
      await authStore.onAuthFailure(error, t("customerAuth.genericErrorMsg"))
    }

    setSubmitLoading(false)
  }

  return (
    <div className='authCustomer'>
      <div
        className='authCustomer-container'
        style={{
          backgroundColor:
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? theme.backgroundColor
              : null,
          minHeight: windowDimensions.height,
        }}
      >
        {showHeader ? (
          <Header
            text={
              headerText == `Reset ${mpinWord}` ||
              headerText == `Change ${mpinWord}`
                ? `Setup ${mpinWord}`
                : headerText
            }
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <div className='header-disabled'></div>
        )}
        <div
          className={
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? "authCustomer-container-content-v2"
              : "authCustomer-container-content"
          }
          // className='authSmsOtp-widget'
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.widgetBackgroundColor
                : null,
          }}
        >
          <div>
            <div
              className='authCustomer-heading'
              style={{
                fontFamily: theme.fontFamily,
                color:
                  config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                    ? theme.appTextColor
                    : "#303030",
              }}
            >
              {t("customerAuth.enterPanAndDob")}
            </div>
            <div
              className='authCustomer-subHeading'
              style={{
                color:
                  config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                    ? theme.appTextColor
                    : "#303030",
              }}
            >
              {config?.version === PwaVersions.V2
                ? t("customerAuth.needToVerifyIdentityText")
                : t("customerAuth.enterUserAuthDetails")}
            </div>
          </div>
          <div className='authCustomer-input'>
            <div
              style={{
                color:
                  config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                    ? theme.appTextColor
                    : "#303030",
              }}
            >
              {t("customerAuth.pan")}
            </div>
            <FormControl isInvalid={panInvalid}>
              <Input
                {...styles.textInput}
                // borderColor={error ? "#C2181B" : "#ECECEC"}
                placeholder='ABCDE1234X'
                placeholderTextColor={
                  config?.version == PwaVersions.V2_1 ? "#CDCFD4" : ""
                }
                value={pan}
                ref={panInput}
                onChange={e => {
                  const finalValue = e.target.value
                    ?.replace(/\W/g, "")
                    ?.toUpperCase()
                  setInputValue(e, finalValue)

                  let dayInput = document.querySelector(
                    '[aria-label="day-label"]',
                  )
                  if (finalValue.length <= 10) {
                    setPan(finalValue)
                  }
                  if (finalValue.length == 10) {
                    dayInput.focus()
                  }
                }}
              />
              <FormControlErrorMessage
                leftIcon={<WarningOutlineIcon size='xs' />}
              >
                {t("customerAuth.invalidPan")}
              </FormControlErrorMessage>
            </FormControl>
            <div className='authCustomer-input-container'>
              <div
                className='authCustomer-input-label'
                style={{
                  color:
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.appTextColor
                      : "#303030",
                }}
              >
                {t("customerAuth.dob")}
              </div>
              <style>{styles.datePickerStyle}</style>
              <FormControl isInvalid={dateInvalid}>
                <DatePicker
                  ref={dobInput}
                  maxDate={new Date("12-31-9999")}
                  className='verify-customer-date-picker'
                  onFocus={() => {
                    setChangeFocus(false)
                  }}
                  onKeyUp={e => onBackClick(e)}
                  onChange={date => {
                    setDob(date)
                  }}
                  value={dob}
                  disableCalendar={true}
                  format='dd/MM/y'
                  monthPlaceholder='MM'
                  yearPlaceholder='YYYY'
                  dayPlaceholder='DD'
                  nativeInputAriaLabel='Date'
                  yearAriaLabel='year-label'
                  dayAriaLabel='day-label'
                  monthAriaLabel='month-label'
                />
                <FormControlErrorMessage
                  leftIcon={<WarningOutlineIcon size='xs' />}
                >
                  {errMsg}
                </FormControlErrorMessage>
              </FormControl>
              <div>
                <Text
                  font-weight='400'
                  font-size='12px'
                  line-height='18px'
                  color={`${
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.appTextColor
                      : "#303030"
                  }:alpha.80`}
                ></Text>
              </div>
            </div>
          </div>
          <View mt='12px' display={error ? "flex" : "none"}>
            <Text
              fontFamily={theme.fontFamily}
              font-weight='700'
              font-size='14px'
              line-height='18px'
              letter-spacing='0.02em'
              color='#C2181B'
            >
              {error}
            </Text>
          </View>
          <div className='authCustomer-button-container'>
            <div
              className='authCustomer-button'
              style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            >
              <ColorButton
                text={"Verify Details"}
                isDisable={!enableVerify}
                onPress={verifyCustomer}
                isLoading={submitLoading}
              />
              {showCancelButton() &&
                !(
                  config?.version == PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                ) && (
                  <WhiteButton
                    text='Cancel'
                    onPress={async () => {
                      await authStore.onAuthCancel()
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customer
