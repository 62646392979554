const TokenisationUnpauseMerchant = ({ color = "" }) => {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='play-circle'>
        <path
          id='play-circle_2'
          d='M10 2.41663C5.39752 2.41663 1.66669 6.14746 1.66669 10.75C1.66669 15.3525 5.39752 19.0833 10 19.0833C14.6025 19.0833 18.3334 15.3525 18.3334 10.75C18.3334 6.14746 14.6025 2.41663 10 2.41663ZM12.8833 11.6342L9.15173 13.9175C8.40923 14.3716 7.45414 13.8392 7.45414 12.97V8.52991C7.45414 7.66074 8.40923 7.12827 9.15173 7.58244L12.8833 9.86576C13.545 10.2708 13.545 11.2292 12.8833 11.6342Z'
          fill={color || "#1965CF"}
        />
      </g>
    </svg>
  )
}

export default TokenisationUnpauseMerchant
