import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "../../nativeBaseReplacements"

const CustomerCareEmail = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 32 32' size='32px'>
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 225.000000 225.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          className='customerCare-email-icon-svg-path'
          transform='translate(0.000000,225.000000) scale(0.100000,-0.100000)'
          fill={theme.appTextColor}
          stroke='none'
        >
          <path
            d='M90 1140 l0 -600 560 0 560 0 10 26 c6 14 10 30 10 35 0 5 -199 9
-507 9 l-508 0 239 239 239 240 106 -81 c58 -45 111 -81 117 -81 6 0 56 34
112 76 55 43 105 77 109 77 5 0 53 -43 107 -96 l99 -96 26 26 26 26 -98 98
-98 98 233 177 233 176 5 -135 5 -135 36 38 36 38 -5 223 -5 222 -823 0 -824
0 0 -600z m1577 485 l3 -40 -342 -260 c-187 -143 -357 -272 -377 -287 l-37
-27 -374 287 c-363 278 -375 289 -378 324 -3 31 0 37 20 41 13 2 352 4 753 3
l730 -1 2 -40z m-1279 -304 c112 -86 213 -163 225 -172 20 -17 16 -21 -208
-248 -126 -127 -232 -231 -237 -231 -4 0 -8 185 -8 411 0 325 3 409 13 403 6
-4 103 -78 215 -163z'
          />
          <path
            d='M1653 1088 l-292 -293 -37 -138 c-20 -75 -35 -139 -33 -141 2 -3 67
13 143 34 l139 38 293 293 294 294 -102 102 c-57 57 -105 103 -108 103 -3 0
-136 -132 -297 -292z m394 94 c3 -7 -6 -26 -21 -40 l-26 -26 -55 54 -55 54 27
28 26 27 50 -41 c27 -23 51 -48 54 -56z m-150 -64 l53 -53 -182 -182 c-101
-101 -188 -183 -193 -183 -6 0 -33 22 -60 50 l-49 50 184 185 c101 102 187
185 189 185 3 0 29 -24 58 -52z m-430 -420 c18 -18 33 -37 33 -44 0 -6 -20
-17 -44 -23 -57 -14 -66 -14 -61 2 2 6 9 31 15 55 6 23 14 42 17 42 4 0 22
-14 40 -32z'
          />
        </g>
      </svg>
    </Icon>
  )
}
export default CustomerCareEmail
