import { Label } from "./Label"

const Toggle = ({
  className = "",
  style,
  label,
  value,
  isDisabled,
  isChecked,
  onChange,
  ...props
}) => {
  return (
    <div className='hf-toggle-container'>
      {label &&
        (typeof label === "string" ? (
          <Label className='hf-toggle-label'>{label}</Label>
        ) : (
          <div className='hf-toggle-label'>{label}</div>
        ))}
      <div className='hf-toggle-parent'>
        <input
          type='checkbox'
          className={`hf-toggle ${className}`}
          style={style}
          disabled={isDisabled}
          checked={isChecked}
          onChange={onChange}
          {...props}
        />
        <div className='hf-toggle-switch'></div>
      </div>
    </div>
  )
}

export { Toggle }
