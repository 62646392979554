import React, { useState } from "react"
import { useSelector } from "react-redux"
import { ColorButton } from "../../../core/buttons"
import SuccessIcon from "../../../../assets/images/Success1.svg"
import { getHeaderText, showCancelButton } from "../../../../utils/auth"
import { captureEvents, goToRedirectUrl } from "../../../../utils/functions"
import { AuthChallenges } from "../../../../utils/constants"
import { Header } from "../../../core"
import { EventName, PwaVersions } from "../../../../utils/enums"
import { useNavigate } from "react-router-dom"
import { ScrollView } from "../../../../nativeBaseReplacements"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const SetMpinSuccess = ({ onSubmit }) => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()

  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)

  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const [submitLoading, setSubmitLoading] = useState(false)
  const successImageUrl = screen?.mpin?.setMpin?.successImageUrl

  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.MPIN)

  const handleSubmitClick = async () => {
    if (config?.version === PwaVersions.V3) {
      navigate("/")
      return
    }
    captureEvents({
      eventName: EventName.MPIN_SUCCESS_CTA,
      metadata: {},
    })
    setSubmitLoading(true)
    await onSubmit()
    setSubmitLoading(false)
  }

  return (
    <div
      className='mpinSuccess'
      style={{
        minHeight:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1 ||
          config?.version === PwaVersions.V3
            ? windowDimensions.height
            : null,
        backgroundColor:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1 ||
          config?.version === PwaVersions.V3
            ? theme.backgroundColor
            : null,
      }}
    >
      {showHeader ? (
        <Header
          text={
            config?.version === PwaVersions.V2_1
              ? `Setup ${mpinWord}`
              : headerText
          }
          onBack={async () => {
            showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
          }}
        />
      ) : (
        <div className='mpinSuccess-noHeader'></div>
      )}
      <div
        style={{
          backgroundColor:
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? theme.backgroundColor
              : null,
        }}
      >
        <div className='mpinSuccess-content-container'>
          {successImageUrl ? (
            <img className='mpinSuccess-content-img' src={successImageUrl} />
          ) : (
            <div
              className='mpinSuccess-content-icon-container'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <img className='mpinSuccess-content-img' src={SuccessIcon} />
            </div>
          )}
          <div className='mpinSuccess-content-text'>
            <div
              className='mpinSuccess-content-text-1'
              style={{
                fontFamily: theme.fontFamily,
                color: theme.appTextColor,
              }}
            >
              {`${mpinWord} updated successfully!`}
            </div>
            <div
              className='mpinSuccess-content-text-2'
              style={{
                fontFamily: theme.fontFamily,
                color: theme.appTextColor,
              }}
            >
              {`Use this ${mpinWord} to securely access your credit card`}
            </div>
          </div>
        </div>
        <div className='mpinSuccess-cta-container'>
          <div className='mpinSuccess-cta' style={{ position: "absolute" }}>
            <ColorButton
              text='Proceed'
              isLoading={submitLoading}
              onPress={handleSubmitClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SetMpinSuccess
