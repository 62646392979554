import React from "react"
import ConcentricCircles from "../../../svg/v3/ConcentricCircles"
import { useSelector } from "react-redux"
import { hexToRGBA } from "../../../../utils/functions"

const InfoCard = ({
  details,
  onCardClick,
  defaultImage,
  cardBackgroundColor,
}) => {
  const theme = useSelector(state => state.theme)
  const handleCardClick = () => {
    if (onCardClick) {
      onCardClick({ ...details, defaultImage })
    }
  }
  return (
    <div
      className='v3-customerSupport-infoCard'
      style={{
        background:
          details?.color ||
          details?.backgroundColor ||
          cardBackgroundColor ||
          theme.v3.cssVars.tertiaryNegative.color3,
      }}
      onClick={handleCardClick}
    >
      <div
        className='v3-customerSupport-infoCard-title'
        style={{ color: theme.v3.cssVars.primaryBase.color1 }}
      >
        {details?.title || details?.question}
      </div>
      {details?.desc && (
        <div
          className='v3-customerSupport-infoCard-desc'
          style={{ color: theme.v3.cssVars.primaryBase.color2 }}
        >
          {details?.desc}
        </div>
      )}
      {details?.imageUrl ? (
        <img
          src={details?.imageUrl}
          className='v3-customerSupport-infoCard-image'
          alt=''
        />
      ) : (
        <div className='v3-customerSupport-infoCard-image'>{defaultImage}</div>
      )}
      <div className='v3-customerSupport-infoCard-backgroundPattern'>
        <ConcentricCircles color={"rgba(128,128,128,0.4)"} />
      </div>
    </div>
  )
}

export default InfoCard
