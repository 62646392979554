const RewardPointsBgPattern = ({ color = "#F0E0B5" }) => {
  return (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='81'
        height='35'
        viewBox='0 0 81 35'
        fill='none'
      >
        <path
          d='M2.87157 4.68961L59.1916 -51.6304C58.6516 -51.8204 58.1016 -51.9904 57.5516 -52.1504L2.35156 3.04959C2.51156 3.59959 2.69157 4.1496 2.87157 4.68961Z'
          fill={color}
        />
        <path
          d='M5.31938 10.4596L64.9694 -49.1904C64.4894 -49.4404 63.9994 -49.6704 63.5094 -49.9004L4.60938 8.9996C4.83938 9.4996 5.06938 9.97963 5.31938 10.4596Z'
          fill={color}
        />
        <path
          d='M8.40125 15.5995L70.1112 -46.1105C69.6812 -46.4105 69.2512 -46.7005 68.8112 -46.9805L7.53125 14.2995C7.81125 14.7495 8.10125 15.1796 8.40125 15.5995Z'
          fill={color}
        />
        <path
          d='M12.0412 20.1803L74.6812 -42.4597C74.3012 -42.7997 73.9212 -43.1397 73.5312 -43.4697L11.0312 19.0303C11.3613 19.4203 11.6912 19.8003 12.0412 20.1803Z'
          fill={color}
        />
        <path
          d='M16.2225 24.2096L78.7125 -38.2804C78.3825 -38.6704 78.0425 -39.0604 77.7025 -39.4404L15.0625 23.1996C15.4525 23.5496 15.8325 23.8796 16.2225 24.2096Z'
          fill={color}
        />
        <path
          d='M20.9484 27.7104L82.2084 -33.5496C81.9284 -33.9896 81.6384 -34.4196 81.3384 -34.8496L19.6484 26.8404C20.0784 27.1304 20.5084 27.4204 20.9484 27.7104Z'
          fill={color}
        />
        <path
          d='M26.2591 30.6098L85.1091 -28.2402C84.8791 -28.7302 84.6491 -29.2202 84.3991 -29.7002L24.7891 29.9098C25.2791 30.1498 25.7691 30.3898 26.2591 30.6098Z'
          fill={color}
        />
        <path
          d='M32.2298 32.86L87.3698 -22.2799C87.2098 -22.8299 87.0299 -23.3799 86.8499 -23.9199L30.5898 32.3401C31.1198 32.5301 31.6698 32.7 32.2298 32.86Z'
          fill={color}
        />
        <path
          d='M38.9972 34.3001L88.7972 -15.4999C88.7172 -16.1299 88.6172 -16.7599 88.5072 -17.3799L37.1172 34.0101C37.7472 34.1301 38.3672 34.2201 38.9972 34.3001Z'
          fill={color}
        />
        <path
          d='M44.8419 34.6897C45.5319 34.6897 46.2119 34.6697 46.8819 34.6397L89.1319 -7.61029C89.1619 -8.29029 89.1819 -8.97027 89.1819 -9.65027C89.1819 -9.71027 89.1819 -9.76031 89.1819 -9.82031L44.6719 34.6897C44.7319 34.6897 44.7919 34.6897 44.8419 34.6897Z'
          fill={color}
        />
        <path
          d='M56.6508 33.0999L87.6008 2.14984C87.8608 1.20984 88.0908 0.259805 88.2908 -0.700195L53.8008 33.7899C54.7608 33.5899 55.7108 33.3499 56.6508 33.0999Z'
          fill={color}
        />
        <path
          d='M83.2081 12.5898L67.0781 28.7198C73.7581 24.8298 79.3281 19.2598 83.2081 12.5898Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default RewardPointsBgPattern
