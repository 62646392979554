import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { BottomSheet } from "react-spring-bottom-sheet"
import Cross from "../../../../svg/v3/Cross"
import telephone from "../../../../../assets/images/v3/telephone.svg"
import mail from "../../../../../assets/images/v3/mail.svg"
import CreditCardTimes from "../../../../svg/v3/creditCardTimes"
import { ColorButton } from "../../../../core/buttons"

const EMIConversionFailedBottomSheet = forwardRef(
  ({ isOpen, onClose }, ref) => {
    const { t } = useTranslation()

    const theme = useSelector(state => state.theme)
    const screen = useSelector(state => state.screen)
    const config = useSelector(state => state.config)
    return (
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div className='v3-customerSupport-bottomSheet'>
          <div className='v3-transactionSection-convertionFailed-bottomSheet'>
            <CreditCardTimes />
          </div>
          <div className='v3-transactionSection-convertionFailed-bottomSheet-text'>
            {t("TransactionSection.conversionFailedBottomSheetText")}
          </div>
          <div className='v3-customerSupport-bottomSheet-contacts'>
            <img
              className='v3-customerSupport-bottomSheet-contacts-image'
              alt='telephone'
              src={telephone}
            />
            <div className='v3-customerSupport-bottomSheet-contacts-text'>
              <div>{"Our 24x7 Customer Service"}</div>
              <div>{"1800 277 142"}</div>
            </div>
          </div>
          <div className='v3-customerSupport-bottomSheet-contacts-divider'>
            {"Or"}
          </div>
          <div className='v3-customerSupport-bottomSheet-contacts'>
            <img
              className='v3-customerSupport-bottomSheet-contacts-image'
              alt='mail'
              src={mail}
            />
            <div className='v3-customerSupport-bottomSheet-contacts-text'>
              <div>{"Write to us at"}</div>
              <div>{"customers@wise.com"}</div>
            </div>
          </div>
          <div className='v3-transactionSection-convertionFailed-bottomSheet-button'>
            <ColorButton
              text='Ok, Got it'
              width='100%'
              onPress={() => onClose()}
            />
          </div>
        </div>
      </BottomSheet>
    )
  },
)

export default EMIConversionFailedBottomSheet
