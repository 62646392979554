import { useSelector } from "react-redux"
import { useTranslation, Trans } from "react-i18next"
import { AuthenticationType } from "../../utils/enums"
import InfoCircle from "../svg/v3/infoCircle"
import { t } from "i18next"

const Error_v3 = ({ type, attempts, errorMessage, customClassName }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  return (
    <div className={`v3-error-msg ${customClassName}`}>
      <InfoCircle color={theme.v3.rawColors.tertiaryNegative.color1} />
      {attempts && (
        <div>
          {type === "CUSTOMER_VERIFY" ? (
            t("customerAuth.panAndDobMismatchMessage")
          ) : (
            <Trans
              i18nKey='customerAuth.incorrectMessage'
              values={{ type: type === "OTP" ? "OTP" : "mPIN" }}
            />
          )}{" "}
          {attempts == "1" ? (
            <Trans
              i18nKey='customerAuth.attemptLeft'
              values={{ count: attempts }}
              components={{ strong: <span style={{ fontWeight: "600" }} /> }}
            />
          ) : (
            <Trans
              i18nKey='customerAuth.attemptsLeft'
              values={{ count: attempts }}
              components={{ strong: <span style={{ fontWeight: "600" }} /> }}
            />
          )}
        </div>
      )}
      {errorMessage && <div>{errorMessage}</div>}
    </div>
  )
}

export default Error_v3
