/* eslint-disable */
import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Paths from "../../../routes/Paths"
import AccountService from "../../../services/AccountService"
import { NudgeContentType, ProgramTypes } from "../../../utils/constants"
import {
  EventName,
  NudgeType,
  nudgeVariants,
  PwaVersions,
} from "../../../utils/enums"

import CardInHand from "../../svg/cardInHand"
import CrossFilled from "../../svg/crossFilled"
import {
  captureEvents,
  consoleError,
  hexToRGBA,
  truncateString,
} from "../../../utils/functions"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import ArrowRight from "../../svg/v3/arrowRight"
import BannerNudges_v3 from "./v3/BannerNudges"
import { setCommonState } from "../../../store/actions/Common"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const getProgramType = user => {
  if (user.programType === ProgramTypes.CREDIT_CARD) return " credit "
  else if (user.programType === ProgramTypes.PAY_LATER) return " pay later "
  else return " "
}
const SmartNudges = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [nudges, setNudges] = useState([])
  const [topNudges, setTopNudges] = useState([])

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const featureFlags = useSelector(state => state.featureFlags)
  const screen = useSelector(state => state.screen)

  const windowDim = useWindowDimensions()

  const allNudges = props.nudgesData
  const isNudgeSectionEnabled =
    screen?.home?.homePageLayout?.isNudgeSectionEnabled || false

  // incrementing impression count of first nudge on load
  useEffect(() => {
    if (allNudges) {
      let id = allNudges[0].id
      let nudgesDelivery =
        JSON.parse(localStorage.getItem("nudgesDelivery")) || {}
      nudgesDelivery.lastImpressionId = id
      localStorage.setItem(
        "nudgesDelivery",
        JSON.stringify({
          ...nudgesDelivery,
          [accountId]: {
            ...(nudgesDelivery?.[accountId] || {}),
            [id]: {
              ...(nudgesDelivery?.[accountId]?.[id] || {}),
              sessions: (nudgesDelivery?.[accountId]?.[id]?.sessions || 0) + 1,
            },
          },
        }),
      )
    }
  }, [])

  useEffect(() => {
    allNudges.sort(
      (a, b) =>
        a?.displayOptions?.displayOrder - b?.displayOptions?.displayOrder,
    )

    if (config?.version === PwaVersions.V3) {
      let filteredPillNudges = allNudges?.filter(nudge => {
        const nudgeDetails = JSON.parse(
          nudge?.displayOptions?.nudgeDetails || "{}",
        )
        return nudgeDetails?.variant === nudgeVariants.PILL
      })
      if (filteredPillNudges && filteredPillNudges.length > 0) {
        const topNudges = filteredPillNudges.slice(0, 4)
        setNudges(topNudges)
      }
      setTopNudges(filteredPillNudges)
    } else {
      setNudges(allNudges)
    }
    // dispatch(setCommonState({ pillNudges: filteredPillNudges }))
  }, [props.nudgesData])

  const onClickNavigation = () => {
    if (props?.to) {
      return props.to
    } else if (
      (config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1) &&
      screen?.home?.isQrCodeRequiredForActivation
    ) {
      return "/ActivateCard/Scanner"
    } else {
      return Paths.HOME_WITH_ACTIVATE
    }
  }

  const getBgColor = () => {
    if (
      config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1
    ) {
      return theme.widgetBackgroundColor
    } else if (user.programType === ProgramTypes.PREPAID) {
      return `${theme.color1}:alpha.10`
    } else {
      return theme.color3
    }
  }

  const handleNudgeDisable = async item => {
    try {
      let data = {
        nudgeDfnId: item.id,
        channel: "PWA",
      }
      const response = await AccountService.disableNudge(
        user?.account?.id,
        data,
      )
      const result = response.data

      if (result?.success) {
        // card state will be updated by summary api later
        let updatedArray = removeNudgeFromState(item.id)
        setNudges(updatedArray)
      } else {
        consoleError(result?.errors)
      }
    } catch (error) {
      consoleError(error)
    }
  }
  const accountId = user?.account?.id

  function removeNudgeFromState(id) {
    let arr = nudges
    return arr.filter(obj => obj.id !== id)
  }

  const handleNudgeTouch = id => {
    let nudgesDelivery =
      JSON.parse(localStorage.getItem("nudgesDelivery")) || {}
    let lastNudgeId = nudgesDelivery?.lastImpressionId || ""
    if (id == lastNudgeId) {
      return false
    } else {
      nudgesDelivery.lastImpressionId = id
    }
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId]: {
          ...(nudgesDelivery?.[accountId] || {}),
          [id]: {
            ...(nudgesDelivery?.[accountId]?.[id] || {}),
            sessions: (nudgesDelivery?.[accountId]?.[id]?.sessions || 0) + 1,
          },
        },
      }),
    )
  }

  const handleNudgeClick = id => {
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery"))
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId]: {
          ...(nudgesDelivery?.[accountId] || {}),
          [id]: {
            ...(nudgesDelivery?.[accountId]?.[id] || {}),
            clickCount:
              (nudgesDelivery?.[accountId]?.[id]?.clickCount || 0) + 1,
          },
        },
      }),
    )
  }

  const getColorsV3 = (color, comp) => {
    if (color && color !== "") {
      return color
    } else {
      switch (comp) {
        case NudgeContentType.SUBTITLE:
          return theme.v3.rawColors.primaryBase.color2
        case NudgeContentType.BACKGROUND:
          return theme.v3.rawColors.primary.color5
        default:
          return theme.v3.rawColors.primary.color1
      }
    }
  }

  const getVersionedSmartNudges = () => {
    switch (config?.version) {
      case PwaVersions.V3:
        return (
          isNudgeSectionEnabled && (
            <div
              style={
                nudges?.length === 0
                  ? { height: "0px" }
                  : { marginBottom: "18px" }
              }
              className='v3-nudge-smartnudges'
            >
              <Swiper
                spaceBetween={2}
                centeredSlides={true}
                slidesPerView={1.15}
              >
                {nudges &&
                  nudges?.map((item, index) => {
                    const originalDescription = JSON.parse(
                      item?.displayOptions?.nudgeDetails,
                    )?.description
                    const description =
                      originalDescription.length >=
                      (windowDim.width < 360
                        ? 70
                        : windowDim.width < 370
                          ? 80
                          : 90)
                        ? truncateString(
                            originalDescription,
                            windowDim.width < 360
                              ? 70
                              : windowDim.width < 370
                                ? 80
                                : 90,
                          )
                        : originalDescription
                    return (
                      <SwiperSlide>
                        {" "}
                        <div
                          onTouchEnd={e => handleNudgeTouch(e?.target?.id)}
                          id={item.id}
                          style={{
                            backgroundColor: getColorsV3(
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.backgroundColor,
                              NudgeContentType.BACKGROUND,
                            ),
                            borderColor: hexToRGBA(
                              getColorsV3(
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.titleColor,
                                NudgeContentType.BORDER,
                              ),
                              0.18,
                            ),
                          }}
                          className='v3-nudge-smartnudges-nudge-container'
                          onClick={e => {
                            captureEvents({
                              eventName: EventName.NUDGE_CLICK,
                              metadata: {
                                nudgeDetails: JSON.parse(
                                  item?.displayOptions?.nudgeDetails,
                                ),
                                position: index + 1,
                              },
                            })
                            JSON.parse(
                              item?.displayOptions?.nudgeDetails,
                            )?.redirectionUrl.startsWith("https")
                              ? (console.log(""),
                                (window.location.href = JSON.parse(
                                  item?.displayOptions?.nudgeDetails,
                                )?.redirectionUrl))
                              : console.log(""),
                              navigate(
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.redirectionUrl,
                              )
                            setActiveIndex(index)
                            handleNudgeClick(item.id)
                            e.stopPropagation()
                          }}
                        >
                          <div
                            id={item.id}
                            className='v3-nudge-smartnudges-nudge-icon'
                          >
                            <img
                              id={item.id}
                              src={
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.illustrationUrl
                              }
                            />
                          </div>
                          <div
                            id={item.id}
                            className='nudge-smartnudges-nudge-nudgeTitleDescriptionContainer'
                          >
                            <div
                              id={item.id}
                              style={{
                                color: getColorsV3(
                                  JSON.parse(item?.displayOptions?.nudgeDetails)
                                    ?.titleColor,
                                  NudgeContentType.TITLE,
                                ),
                              }}
                              className='nudge-smartnudges-nudge-nudgeTitle'
                            >
                              {
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.title
                              }
                            </div>
                            <div
                              id={item.id}
                              style={{
                                color: getColorsV3(
                                  JSON.parse(item?.displayOptions?.nudgeDetails)
                                    ?.subtitleColor,
                                  NudgeContentType.SUBTITLE,
                                ),
                              }}
                              className='nudge-smartnudges-nudge-nudgeDescription'
                            >
                              {description}
                            </div>
                          </div>
                          <div
                            className='v3-nudge-smartnudges-nudge-arrow'
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "auto",
                            }}
                          >
                            <ArrowRight
                              color={getColorsV3(
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.titleColor,
                                NudgeContentType.ARROW,
                              )}
                            />
                          </div>
                        </div>
                      </SwiperSlide>
                    )
                  })}
                {topNudges.length > 4 && (
                  <SwiperSlide>
                    <div
                      className='v3-nudge-smartnudges-nudge-container-view-all'
                      onClick={() => navigate("/allPillNudges")}
                    >
                      <span className='v3-nudge-smartnudges-pill-view'>{`+${topNudges?.length - nudges?.length} more`}</span>
                    </div>
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          )
        )
      default:
        return (
          <div
            style={nudges?.length === 0 ? { height: "0px" } : {}}
            className='nudge-smartnudges'
          >
            <CarouselProvider
              naturalSlideWidth={200}
              naturalSlideHeight={200}
              isIntrinsicHeight={true}
              totalSlides={nudges?.length}
              visibleSlides={nudges?.length === 1 ? 1 : 1.1}
              currentSlide={activeIndex}
            >
              <Slider>
                {nudges &&
                  nudges?.map((item, index) => (
                    <Slide index={index}>
                      <div
                        onTouchEnd={e => handleNudgeTouch(e?.target?.id)}
                        id={item.id}
                        style={{
                          backgroundColor: JSON.parse(
                            item?.displayOptions?.nudgeDetails,
                          )?.backgroundColor,
                          marginLeft: index === 0 ? "16px" : "16px",
                          marginRight:
                            index === nudges.length - 1 ? "16px" : "16px",
                        }}
                        className='nudge-smartnudges-nudge-container'
                        onClick={e => {
                          captureEvents({
                            eventName: EventName.NUDGE_CLICK,
                            metadata: {
                              nudgeDetails: JSON.parse(
                                item?.displayOptions?.nudgeDetails,
                              ),
                              position: index + 1,
                            },
                          })
                          JSON.parse(
                            item?.displayOptions?.nudgeDetails,
                          )?.redirectionUrl.startsWith("https")
                            ? (console.log(""),
                              (window.location.href = JSON.parse(
                                item?.displayOptions?.nudgeDetails,
                              )?.redirectionUrl))
                            : console.log(""),
                            navigate(
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.redirectionUrl,
                            )
                          setActiveIndex(index)
                          handleNudgeClick(item.id)
                          e.stopPropagation()
                        }}
                      >
                        <div
                          id={item.id}
                          className='nudge-smartnudges-nudge-icon'
                        >
                          {JSON.parse(item?.displayOptions?.nudgeDetails)
                            ?.illustrationUrl ? (
                            <img
                              id={item.id}
                              src={
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.illustrationUrl
                              }
                              width={"60px"}
                              height={"60px"}
                            ></img>
                          ) : (
                            item.name !== "Activate Physical Card" &&
                            item.name !== "Request New Card" && (
                              <CardInHand type={"new"} color={theme.color1} />
                            )
                          )}
                        </div>

                        <div
                          id={item.id}
                          className='nudge-smartnudges-nudge-nudgeTitleDescriptionContainer'
                        >
                          <div
                            id={item.id}
                            style={{
                              color: JSON.parse(
                                item?.displayOptions?.nudgeDetails,
                              )?.titleColor,
                            }}
                            className='nudge-smartnudges-nudge-nudgeTitle'
                          >
                            {
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.title
                            }
                          </div>
                          <div
                            id={item.id}
                            style={{
                              color: JSON.parse(
                                item?.displayOptions?.nudgeDetails,
                              )?.subtitleColor,
                            }}
                            className='nudge-smartnudges-nudge-nudgeDescription'
                          >
                            {truncateString(
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.description,
                              75,
                            )}
                          </div>
                        </div>

                        {item?.displayOptions?.requireType ===
                        NudgeType.OPTIONAL ? (
                          <div
                            onClick={e => {
                              e.stopPropagation()
                              handleNudgeDisable(item)
                            }}
                            className='nudge-smartnudges-nudge-crossImage'
                          >
                            {" "}
                            <CrossFilled
                              fillColor={
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.backgroundColor
                              }
                              crossColor={
                                JSON.parse(item?.displayOptions?.nudgeDetails)
                                  ?.titleColor
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Slide>
                  ))}
              </Slider>
            </CarouselProvider>
          </div>
        )
    }
  }

  return <>{getVersionedSmartNudges()}</>
}

export default SmartNudges
