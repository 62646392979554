const Cashback = ({
  circleColor = "#1965CF",
  backgroundColor = "#ACC8EE",
  borderColor = "#70A0E1",
  shadowColor = "#0E0E0E",
  rupeeColor = "#fff",
  customClassname = "",
  height,
  width,
}) => {
  return (
    <div className={customClassname}>
      <svg
        width={width || "35"}
        height={height || "35"}
        viewBox='0 0 35 35'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M3.62061 28.4884C7.85377 30.3729 11.9817 32.3771 16.0007 34.4912C20.6405 25.6904 26.0907 17.0118 32.3587 8.57887C28.3631 6.10069 24.2495 3.72573 20.0236 1.46094C13.7306 10.2759 8.26475 19.326 3.62061 28.4884Z'
          fill={circleColor}
        />
        <path
          d='M2.74365 28.55C6.94109 30.506 11.0365 32.5781 15.0213 34.7578C19.8117 26.0326 25.4123 17.4416 31.8277 9.1116C27.8917 6.56193 23.8375 4.11217 19.672 1.76855C13.2041 10.486 7.5623 19.4546 2.74365 28.55Z'
          fill={shadowColor}
        />
        <path
          d='M31.8442 7.76143C25.4087 16.1217 19.7871 24.7416 14.9787 33.4976C10.9742 31.3343 6.85993 29.2797 2.6416 27.3408C7.47252 18.2163 13.1271 9.21653 19.6106 0.469727C23.8036 2.79592 27.882 5.22844 31.8442 7.76143Z'
          fill={borderColor}
        />
        <path
          d='M13.4661 31.9545C10.6522 30.4654 7.78621 29.0282 4.86913 27.6474C5.25405 26.9303 4.98351 26.0671 4.25488 25.7245C8.51462 17.8934 13.3862 10.1618 18.8721 2.61166C19.598 3.01197 20.5905 2.7799 21.102 2.09082C24.0003 3.72743 26.8439 5.41739 29.63 7.15556C29.1219 7.82393 29.2789 8.71464 29.9688 9.1474C24.5184 16.3964 19.6746 23.8312 15.4331 31.3728C14.7369 30.9947 13.8514 31.2559 13.4674 31.9537L13.4661 31.9545Z'
          fill={backgroundColor}
        />
        <path
          d='M14.064 32.2632C14.2863 32.3812 14.5074 32.4999 14.7284 32.6186C14.8505 32.3974 14.9719 32.1782 15.096 31.9575C14.7295 31.7601 14.2666 31.8972 14.0652 32.2625L14.064 32.2632Z'
          fill={shadowColor}
        />
        <path
          d='M3.92739 26.3359C3.80446 26.5645 3.68152 26.7931 3.55859 27.0217C3.79029 27.1292 4.02198 27.2368 4.25313 27.3463C4.45575 26.9683 4.31067 26.5151 3.92739 26.3359Z'
          fill={shadowColor}
        />
        <path
          d='M30.2069 7.53546C30.427 7.67342 30.6459 7.81212 30.864 7.94953C30.7037 8.16034 30.5421 8.37189 30.3831 8.58197C30.0208 8.35331 29.9405 7.88523 30.2061 7.53418L30.2069 7.53546Z'
          fill={shadowColor}
        />
        <path
          d='M19.2925 2.02791C19.453 1.80848 19.6148 1.58832 19.7766 1.36816C20.0068 1.49696 20.2363 1.62447 20.4665 1.75327C20.1975 2.11641 19.6736 2.23896 19.2925 2.02791Z'
          fill={shadowColor}
        />
        <path
          d='M19.5532 12.782C21.9642 14.144 22.58 17.1503 21.0705 19.4669C19.5563 21.7845 16.4812 22.6014 14.0618 21.3259C11.6469 20.0495 10.8098 17.0967 12.3323 14.6926C13.8503 12.2894 17.1475 11.4204 19.5539 12.7833L19.5532 12.782Z'
          fill={circleColor}
        />
        <path
          d='M15.1302 30.5955C14.4742 30.4752 13.7853 30.6784 13.2973 31.1378C10.7972 29.8166 8.25586 28.5393 5.67514 27.3033C5.79596 26.6453 5.58778 25.9745 5.11133 25.5133C9.217 18.0289 13.8832 10.6377 19.1145 3.41023C19.8205 3.54955 20.5888 3.36903 21.179 2.92773C23.744 4.3804 26.2656 5.87322 28.743 7.40491C28.5221 8.04865 28.6447 8.73941 29.0653 9.25471C23.8646 16.223 19.2212 23.3594 15.1322 30.596L15.1302 30.5955Z'
          stroke={circleColor}
          stroke-width='0.315466'
          stroke-miterlimit='10'
        />
        <path
          d='M12.196 24.453C12.7584 24.746 12.949 25.4336 12.6284 25.9863C12.3085 26.5402 11.5983 26.7539 11.0361 26.4643C10.4734 26.1767 10.2711 25.4923 10.5921 24.9343C10.9131 24.3764 11.6337 24.1601 12.196 24.453Z'
          fill={circleColor}
        />
        <path
          d='M23.1101 7.70084C23.669 8.02641 23.8048 8.72835 23.4191 9.26546C23.0341 9.80384 22.2741 9.97795 21.7141 9.65642C21.1541 9.33488 21.0066 8.63624 21.3926 8.09382C21.7787 7.5514 22.5504 7.37399 23.1093 7.69956L23.1101 7.70084Z'
          fill={circleColor}
        />
        <path
          d='M15.3524 15.5968C15.5092 15.3536 15.6669 15.1099 15.8259 14.8665C15.7504 14.7012 15.6758 14.5354 15.6003 14.3701C15.02 15.2567 14.4512 16.1457 13.8964 17.0378C13.9744 17.2017 14.0521 17.3669 14.1301 17.5308C14.2253 17.3777 14.3208 17.2234 14.4169 17.0698C14.5821 16.8061 14.8116 16.5762 15.0905 16.419C15.213 16.349 15.3484 16.2918 15.483 16.2664C15.2268 16.6673 14.9734 17.0688 14.7212 17.4708C14.7984 17.6352 14.876 17.8004 14.9532 17.9647C15.2855 17.4306 15.6224 16.8984 15.9632 16.3673C16.2306 16.5428 16.339 16.7992 16.2449 17.2677C16.1534 17.7213 15.7049 18.3621 15.7049 18.3621C15.8361 18.4328 15.966 18.5031 16.0972 18.5738C16.8014 18.4156 17.5063 18.2625 18.2111 18.1151C18.7328 18.0062 19.1923 17.6964 19.4818 17.2597C18.4931 17.4674 17.5054 17.6846 16.5184 17.9127C16.514 17.913 17.2806 16.4424 16.3399 15.7832C16.4418 15.6263 16.5446 15.4689 16.6474 15.3115C16.5728 15.1457 16.499 14.9794 16.4244 14.8135C16.2549 15.0719 16.0862 15.3297 15.9189 15.5879C15.712 15.5358 15.5209 15.5486 15.3519 15.596L15.3524 15.5968Z'
          fill={rupeeColor}
        />
      </svg>
    </div>
  )
}

export default Cashback
