import styled, { keyframes } from "styled-components"
import { getNativeBaseSpaceToPx, hexToRGBA } from "../utils/functions"

const rotation = keyframes`
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}`

const Spinner = styled.span`
  width: ${props => getNativeBaseSpaceToPx(props.width ?? 20, 1)};
  height: ${props => getNativeBaseSpaceToPx(props.height ?? 20, 1)};
  border: ${props =>
      +`${getNativeBaseSpaceToPx(props.height ?? 20, 1)}`.replace("px", "") *
      0.15}px
    solid ${props => hexToRGBA(props.color, 0.2)};
  border-bottom-color: ${props => props.color};
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`

export default Spinner
