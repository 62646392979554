import { useSelector } from "react-redux"

const WarningIcon = ({ customClassname = "" }) => {
  const theme = useSelector(state => state.theme)
  const {
    tertiaryNegative: { color1: negativeFeedback1 },
    leadingWhite,
  } = theme?.v3?.rawColors

  return (
    <div className={`v3-warning-background ${customClassname}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='65'
        height='65'
        viewBox='0 0 85 85'
        fill='none'
      >
        <path
          d='M76.307 60.7311L53.198 17.5129C48.7209 9.13947 36.6924 9.13947 32.2119 17.5129L9.10286 60.7311C4.99658 68.412 10.572 77.6875 19.2988 77.6875H66.1111C74.8343 77.6875 80.4133 68.4085 76.307 60.7311Z'
          fill={negativeFeedback1}
        />
        <path
          d='M42.6956 52.3287C41.2476 52.3287 40.0723 51.1535 40.0723 49.7055V35.7147C40.0723 34.2667 41.2476 33.0914 42.6956 33.0914C44.1436 33.0914 45.3189 34.2667 45.3189 35.7147V49.7055C45.3189 51.1535 44.1436 52.3287 42.6956 52.3287ZM46.2633 60.1985C46.2633 58.2678 44.6998 56.7008 42.7656 56.7008H42.7306C40.7999 56.7008 39.25 58.2678 39.25 60.1985C39.25 62.1292 40.8349 63.6962 42.7656 63.6962C44.6963 63.6962 46.2633 62.1292 46.2633 60.1985Z'
          fill={leadingWhite}
        />
      </svg>
    </div>
  )
}

export default WarningIcon
