import {
    CLEAR_SET_MPIN_STATE,
    SET_MPIN_METADATA,
    SET_SET_MPIN_API_TOKEN,
    SET_SET_MPIN_STATE,
} from "../ActionTypes"

const initialState = {
  apiToken: null,
  onSetMpinSuccess: null,
  onSetMpinFailure: null,
  onSetMpinCancel: null,
}

const SetMpinReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SET_MPIN_STATE:
      return {
        ...state,
        onSetMpinSuccess: action.payload.onSetMpinSuccess,
        onSetMpinFailure: action.payload.onSetMpinFailure,
        onSetMpinCancel: action.payload.onSetMpinCancel,
      }
    case CLEAR_SET_MPIN_STATE:
      return {
        mpinMetadata: state?.mpinMetadata,
      }
    case SET_SET_MPIN_API_TOKEN:
      return {
        ...state,
        apiToken: action.payload.apiToken,
      }
    case SET_MPIN_METADATA:
      return {
        ...state,
        mpinMetadata: action.payload.mpinMetadata,
      }
    default:
      return state
  }
}

export default SetMpinReducer
