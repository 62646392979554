import { CardOrientation, TransactionDateTypes } from "../../utils/constants"
import { getV3Theme, getV3ThemeTokens } from "../../utils/theme"
import { SET_DESIGN, SET_FONT } from "../ActionTypes"

const initialState = {
  fontFamily: "Inter",
  color1: "#0D286C",
  color2: "#53B7ED",
  color3: "#F8F8F8",
  toastColor: "#FFFFFF",
  toastBgColor: "#303030",
  cardDesignUrl: null,
  cardOrientation: CardOrientation.VERTICAL,
  virtualCardDetailsImage: null,
  title: "",
  titleColor: "#FFFFFF",
  headerBackgroundColor: "#000000",
  backgroundColor: "#FFFFFF",
  widgetBackgroundColor: "#FFFFFF",
  appTextColor: "#303030",
  deskTopBackgroundColor: "#FFFFFF",
  transactionDateType: TransactionDateTypes.TRANSACTION_DATE,
  iconOverlay: "NONE",
  iconOverlayImageUrl: "",
  virtualCardAnimation: "NONE",
  transactionViewType: "ALL",
  v3: {
    cssVars: getV3Theme(),
    rawColors: getV3ThemeTokens(),
  },
}

const ThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FONT:
      return {
        ...state,
        fontFamily: action.payload.fontFamily,
      }
    case SET_DESIGN:
      return {
        ...state,
        color1: action.payload.color1,
        color2: action.payload.color2,
        color3: action.payload.color3,
        cardPrimaryColor: action.payload.cardPrimaryColor,
        cardDesignUrl: action.payload.cardDesignUrl,
        cardOrientation: action.payload.cardOrientation,
        title: action.payload.title,
        titleColor: action.payload.titleColor,
        widgetBackgroundColor: action.payload.widgetBackgroundColor,
        appTextColor: action.payload.appTextColor,
        headerBackgroundColor: action.payload.headerBackgroundColor,
        backgroundColor: action.payload.backgroundColor,
        transactionDateType: action.payload.transactionDateType,
        toastColor: action.payload.toastColor,
        toastBgColor: action.payload.toastBgColor,
        iconOverlay: action.payload.iconOverlay,
        iconOverlayImageUrl: action.payload.iconOverlayImageUrl,
        virtualCardAnimation: action.payload.virtualCardAnimation,
        virtualCardDetailsImage: action.payload.virtualCardDetailsImage,
        transactionViewType: action.payload.transactionViewType,
        v3: {
          cssVars: getV3Theme(),
          rawColors: getV3ThemeTokens(action.payload),
        },
      }
    default:
      return state
  }
}

export default ThemeReducer
