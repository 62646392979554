import React from "react"
import CopyToClipboard from "../../../svg/v3/copyToClipboard"
import { copyToClipboard, hexToRGBA } from "../../../../utils/functions"
import { useSelector } from "react-redux"
import CouponBackgroundSmall from "../../../svg/v3/coupon"

const HighlightedMerchantOfferCard = ({ offer, isActive }) => {
  const theme = useSelector(state => state.theme)

  const handleCopyButtonClick = couponCode => {
    copyToClipboard(couponCode)
  }
  return (
    <div
      className='v3-allMerchantOffers-highlightedOffers-offerContainer'
      style={{ transform: !isActive ? "scale(0.9)" : "" }}
    >
      <div className='v3-allMerchantOffers-highlightedOffers-offerContainer-textContainer'>
        <div>
          <img src={offer?.logoUrl} alt='brand-logo' />
          <div style={{ color: theme.v3.cssVars.tertiaryNeutral.color2 }}>
            {offer?.displayTitle}
          </div>
        </div>
        <div style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}>
          {offer?.displayDescription || offer?.description}
        </div>
        {JSON.parse(offer?.offerDetails)?.couponCode && (
          <div className='v3-allMerchantOffers-highlightedOffers-offerContainer-couponContainer'>
            <div className='v3-allMerchantOffers-highlightedOffers-offerContainer-couponContainer-couponBg'>
              <CouponBackgroundSmall
                color={
                  offer?.displayColor
                    ? hexToRGBA(offer.displayColor, 0.3)
                    : theme.v3.rawColors.primary.color4
                }
              />
            </div>
            <div className='v3-allMerchantOffers-highlightedOffers-offerContainer-couponTextContainer'>
              <div
                style={{
                  color: offer?.displayColor || theme.v3.cssVars.primary.color1,
                }}
              >
                {JSON.parse(offer?.offerDetails)?.couponCode}
              </div>
              <div
                style={{ transform: "scale(1.3)", cursor: "pointer" }}
                onClick={() =>
                  handleCopyButtonClick(
                    JSON.parse(offer?.offerDetails)?.couponCode,
                  )
                }
              >
                <CopyToClipboard
                  color={offer?.displayColor || theme.v3.cssVars.primary.color1}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='v3-allMerchantOffers-highlightedOffers-offerContainer-imageContainer'>
        <img src={offer?.illustrationUrl} alt='illustration' />
      </div>
    </div>
  )
}

export default HighlightedMerchantOfferCard
