import React from "react"

const ContactlessIcon = ({ color1, color2 }) => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' width='32' height='32' rx='16' fill={color2 || "#F5FAFF"} />
      <path
        d='M14.4997 22.6663H18.4997C21.833 22.6663 23.1663 21.333 23.1663 17.9997V13.9997C23.1663 10.6663 21.833 9.33301 18.4997 9.33301H14.4997C11.1663 9.33301 9.83301 10.6663 9.83301 13.9997V17.9997C9.83301 21.333 11.1663 22.6663 14.4997 22.6663Z'
        fill={color1 || "#2680EA"}
        stroke={color1 || "#2680EA"}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.5 14.405C14.92 12.5317 18.08 12.5317 20.5 14.405'
        stroke='#FAFAFA'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M13.5664 16.4646C15.3464 15.0912 17.6597 15.0912 19.4397 16.4646'
        stroke='#FAFAFA'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.0332 18.5248C15.9199 17.8381 17.0799 17.8381 17.9665 18.5248'
        stroke='#FAFAFA'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default ContactlessIcon
