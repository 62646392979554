import React, { createContext, useState } from "react"
export const ErrorContext = createContext()

export const ErrorScreenProvider = ({ children }) => {
  const [error, setError] = useState({
    statusCode: null,
    callback: null,
  })

  const setErrorState = (statusCode, callback) => {
    let tryAgainCount =
      JSON.parse(localStorage.getItem("errorTryAgainCount")) || 0

    const updatedCallback = () => {
      tryAgainCount++
      callback()
      localStorage.setItem("errorTryAgainCount", tryAgainCount)
    }

    setError({
      statusCode,
      callback: updatedCallback,
    })
  }

  const clearErrorState = () => {
    setError({ statusCode: null, callback: null })
  }

  return (
    <ErrorContext.Provider
      value={{ globalError: error, setErrorState, clearErrorState }}
    >
      {children}
    </ErrorContext.Provider>
  )
}
