import React from "react"

const CS_blockCard = ({ color = "#1965CF" }) => {
  return (
    <svg
      width='100%'
      height='64'
      viewBox='0 0 72 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M59.0625 53.8125H3.9375C1.76269 53.8125 0 52.0498 0 49.875V13.125C0 10.9502 1.76269 9.1875 3.9375 9.1875H59.0625C61.2373 9.1875 63 10.9502 63 13.125V49.875C63 52.0498 61.2373 53.8125 59.0625 53.8125Z'
        fill={color}
      />
      <path
        d='M7.875 45.9375C8.59987 45.9375 9.1875 45.3499 9.1875 44.625C9.1875 43.9001 8.59987 43.3125 7.875 43.3125C7.15013 43.3125 6.5625 43.9001 6.5625 44.625C6.5625 45.3499 7.15013 45.9375 7.875 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M11.8125 45.9375C12.5374 45.9375 13.125 45.3499 13.125 44.625C13.125 43.9001 12.5374 43.3125 11.8125 43.3125C11.0876 43.3125 10.5 43.9001 10.5 44.625C10.5 45.3499 11.0876 45.9375 11.8125 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M15.75 45.9375C16.4749 45.9375 17.0625 45.3499 17.0625 44.625C17.0625 43.9001 16.4749 43.3125 15.75 43.3125C15.0251 43.3125 14.4375 43.9001 14.4375 44.625C14.4375 45.3499 15.0251 45.9375 15.75 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M21 45.9375C21.7249 45.9375 22.3125 45.3499 22.3125 44.625C22.3125 43.9001 21.7249 43.3125 21 43.3125C20.2751 43.3125 19.6875 43.9001 19.6875 44.625C19.6875 45.3499 20.2751 45.9375 21 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M24.9375 45.9375C25.6624 45.9375 26.25 45.3499 26.25 44.625C26.25 43.9001 25.6624 43.3125 24.9375 43.3125C24.2126 43.3125 23.625 43.9001 23.625 44.625C23.625 45.3499 24.2126 45.9375 24.9375 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M28.875 45.9375C29.5999 45.9375 30.1875 45.3499 30.1875 44.625C30.1875 43.9001 29.5999 43.3125 28.875 43.3125C28.1501 43.3125 27.5625 43.9001 27.5625 44.625C27.5625 45.3499 28.1501 45.9375 28.875 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M34.125 45.9375C34.8499 45.9375 35.4375 45.3499 35.4375 44.625C35.4375 43.9001 34.8499 43.3125 34.125 43.3125C33.4001 43.3125 32.8125 43.9001 32.8125 44.625C32.8125 45.3499 33.4001 45.9375 34.125 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M38.0625 45.9375C38.7874 45.9375 39.375 45.3499 39.375 44.625C39.375 43.9001 38.7874 43.3125 38.0625 43.3125C37.3376 43.3125 36.75 43.9001 36.75 44.625C36.75 45.3499 37.3376 45.9375 38.0625 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M42 45.9375C42.7249 45.9375 43.3125 45.3499 43.3125 44.625C43.3125 43.9001 42.7249 43.3125 42 43.3125C41.2751 43.3125 40.6875 43.9001 40.6875 44.625C40.6875 45.3499 41.2751 45.9375 42 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M47.25 45.9375C47.9749 45.9375 48.5625 45.3499 48.5625 44.625C48.5625 43.9001 47.9749 43.3125 47.25 43.3125C46.5251 43.3125 45.9375 43.9001 45.9375 44.625C45.9375 45.3499 46.5251 45.9375 47.25 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M51.1875 45.9375C51.9124 45.9375 52.5 45.3499 52.5 44.625C52.5 43.9001 51.9124 43.3125 51.1875 43.3125C50.4626 43.3125 49.875 43.9001 49.875 44.625C49.875 45.3499 50.4626 45.9375 51.1875 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M55.125 45.9375C55.8499 45.9375 56.4375 45.3499 56.4375 44.625C56.4375 43.9001 55.8499 43.3125 55.125 43.3125C54.4001 43.3125 53.8125 43.9001 53.8125 44.625C53.8125 45.3499 54.4001 45.9375 55.125 45.9375Z'
        fill='#70A0E1'
      />
      <path
        d='M14.4375 24.9375V30.1875H10.5V24.9375H14.4375ZM10.5 34.125H14.4375V31.5H10.5V34.125ZM15.75 27.5625H18.375C18.375 26.1122 17.2003 24.9375 15.75 24.9375V27.5625ZM15.75 28.875V34.125C17.2003 34.125 18.375 32.9503 18.375 31.5V28.875H15.75ZM9.1875 28.875H6.5625V31.5C6.5625 32.9503 7.73719 34.125 9.1875 34.125V28.875ZM9.1875 24.9375C7.73719 24.9375 6.5625 26.1122 6.5625 27.5625H9.1875V24.9375Z'
        fill='#D6E3F6'
      />
      <path
        opacity='0.35'
        d='M54.25 61.1042C61.982 61.1042 68.25 54.7709 68.25 46.9583C68.25 39.1458 61.982 32.8125 54.25 32.8125C46.518 32.8125 40.25 39.1458 40.25 46.9583C40.25 54.7709 46.518 61.1042 54.25 61.1042Z'
        fill='#0E0E0E'
      />
      <path
        d='M54.25 59.9374C61.982 59.9374 68.25 53.6041 68.25 45.7916C68.25 37.9791 61.982 31.6458 54.25 31.6458C46.518 31.6458 40.25 37.9791 40.25 45.7916C40.25 53.6041 46.518 59.9374 54.25 59.9374Z'
        fill='#E72B2B'
      />
      <path
        d='M54.25 59.9374C61.982 59.9374 68.25 53.6041 68.25 45.7916C68.25 37.9791 61.982 31.6458 54.25 31.6458C46.518 31.6458 40.25 37.9791 40.25 45.7916C40.25 53.6041 46.518 59.9374 54.25 59.9374Z'
        fill='#E72B2B'
      />
      <g opacity='0.35'>
        <path
          d='M60.4855 38.3938C58.7417 37.0866 56.5854 36.3125 54.25 36.3125C48.4511 36.3125 43.75 41.0789 43.75 46.9583C43.75 49.2733 44.4812 51.4138 45.7188 53.1606L60.4855 38.3938Z'
          fill='#0E0E0E'
        />
        <path
          d='M62.9016 40.9275L48.1841 55.645C49.8976 56.8773 51.9895 57.6041 54.2502 57.6041C60.0491 57.6041 64.7502 52.8377 64.7502 46.9583C64.7502 44.7189 64.0665 42.6419 62.9016 40.9275Z'
          fill='#0E0E0E'
        />
      </g>
      <path
        d='M60.4855 37.2271C58.7417 35.9198 56.5854 35.1458 54.25 35.1458C48.4511 35.1458 43.75 39.9122 43.75 45.7916C43.75 48.1065 44.4812 50.2471 45.7188 51.9939L60.4855 37.2271Z'
        fill='#F6B3B3'
      />
      <path
        d='M62.9016 39.7607L48.1841 54.4782C49.8976 55.7105 51.9895 56.4374 54.2502 56.4374C60.0491 56.4374 64.7502 51.6709 64.7502 45.7915C64.7502 43.5521 64.0665 41.4752 62.9016 39.7607Z'
        fill='#F6B3B3'
      />
    </svg>
  )
}

export default CS_blockCard
