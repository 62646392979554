import React from "react"

const ViewBillIcon = ({
  backgroundColor = "#FEF2F2",
  iconColor = "#E72B2B",
}) => (
  <svg
    width='42'
    height='43'
    viewBox='0 0 42 43'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='0.570923' width='42' height='42' rx='21' fill={backgroundColor} />
    <path
      d='M17.3437 14.4155V13.4155C17.3437 12.4155 17.8437 11.9155 18.8437 11.9155H23.8437C24.8437 11.9155 25.3437 12.4155 25.3437 13.4155V14.4155C25.3437 15.4155 24.8437 15.9155 23.8437 15.9155H18.8437C17.8437 15.9155 17.3437 15.4155 17.3437 14.4155ZM29.3437 16.9155V26.9155C29.3437 28.9155 28.3437 29.9155 26.3437 29.9155H16.3437C14.3437 29.9155 13.3438 28.9155 13.3438 26.9155V16.9155C13.3438 15.1735 14.0956 14.1925 15.6086 13.9725C15.7296 13.9545 15.8437 14.0545 15.8437 14.1765V14.4146C15.8437 16.2346 17.0237 17.4146 18.8437 17.4146H23.8437C25.6637 17.4146 26.8437 16.2346 26.8437 14.4146V14.1765C26.8437 14.0545 26.9589 13.9545 27.0789 13.9725C28.5919 14.1925 29.3437 15.1735 29.3437 16.9155ZM24.3738 20.0515C24.0808 19.7585 23.6057 19.7585 23.3127 20.0515L20.5098 22.8546L19.3728 21.7185C19.0798 21.4255 18.6048 21.4255 18.3118 21.7185C18.0188 22.0115 18.0188 22.4865 18.3118 22.7795L19.9788 24.4465C20.1248 24.5925 20.3168 24.6665 20.5088 24.6665C20.7008 24.6665 20.8928 24.5935 21.0388 24.4465L24.3718 21.1135C24.6668 20.8195 24.6668 20.3445 24.3738 20.0515Z'
      fill={iconColor}
    />
  </svg>
)

export default ViewBillIcon
