import React from "react";

const CashIcon = ({ color1, color2 }) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="32" height="32" rx="16" fill={color2 || "#F5FAFF"} />
      <path
        d="M22.3271 21.9267C22.3671 22.2 22.1738 22.4533 21.9005 22.4933C21.8738 22.4933 21.8538 22.4933 21.8271 22.4933C21.5805 22.4933 21.3671 22.3133 21.3338 22.0667L20.8271 18.5C20.7405 17.9 20.4538 17.3267 20.0271 16.9L17.5538 14.4267C17.3405 14.2133 16.9938 14.2133 16.7738 14.4267C16.5938 14.6067 16.5605 14.8867 16.6938 15.1067L19.5871 19.7333C19.7338 19.9667 19.6605 20.2733 19.4271 20.42C19.1938 20.5667 18.8871 20.4933 18.7405 20.26L18.3271 19.6067C18.1071 19.74 17.8138 19.8333 17.4005 19.8333H14.0405C14.6071 20.74 14.9738 21.1133 15.5138 21.6467C15.7071 21.84 15.7071 22.16 15.5138 22.3533C15.4138 22.4533 15.2871 22.5 15.1605 22.5C15.0338 22.5 14.9071 22.4533 14.8071 22.3533C14.1738 21.72 13.7005 21.24 12.8738 19.8267C11.8671 19.8067 11.3271 19.26 11.3271 18.24V11.0933C11.3271 10.0533 11.8805 9.5 12.9205 9.5H17.4005C18.4405 9.5 18.9938 10.0533 18.9938 11.0933V14.46L20.7271 16.1933C21.3071 16.7733 21.6938 17.5467 21.8138 18.36L22.3205 21.9267H22.3271Z"
        fill={color1 || "#2680EA"}
      />
    </svg>
  );
};

export default CashIcon;
