import moment from "moment"
import React from "react"
import { useTranslation } from "react-i18next"

const DateFilterHeader = ({ dateObject }) => {
  const { t } = useTranslation()
  return (
    <div className='v3-spend-analytics-category-split-filter-selected-container'>
      {t("spendAnalytics.dateFilter.dateFilterHeader")}{" "}
      <span className='v3-spend-analytics-category-split-filter-selected-date'>
        {moment(dateObject?.startDate).format("DD MMM'YY") +
          " - " +
          moment(dateObject?.endDate).format("DD MMM'YY")}
      </span>
    </div>
  )
}

export default DateFilterHeader
