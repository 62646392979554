import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setCurrentOffer } from "../../../../../store/actions/Session"
import { truncateString } from "../../../../../utils/functions"
import RewardIcon from "../../../../svg/rewards"

const OffersCard_v2 = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  return (
    <div className='offersCard'>
      <div
        className='offersCard-main-div'
        style={{ backgroundColor: props?.data?.displayColor }}
      >
        <div className='offersCard-rewardIcon'>
          {props.data.benefitType === "MILESTONE" ? (
            <RewardIcon
              percent={1 - props.data.currentValue / props.data.threshold}
              color={props?.data?.displayColor || "#B9E9FC"}
              size='50px'
            />
          ) : (
            <img
              src={props.data.illustrationUrl}
              height='50px'
              width={"50px"}
              alt=''
            />
          )}
        </div>
        <div
          className='offersCard-description-div'
          onClick={() => {
            navigate("/Offers/Details", { state: { id: props.index } })
            dispatch(setCurrentOffer({ currentOffer: props.data }))
          }}
        >
          <div className='offersCard-description-text'>
            {truncateString(
              props.data.displayDescription || props.data.description,
              60,
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OffersCard_v2
