import React from "react"
import { Route, Routes } from "react-router-dom"

import ShowTransactions from "../components/AllTransactions/index"
import ViewTransactions from "../components/AllTransactions/v2/viewTransactions"
import CardDetailsWidget from "../components/Home/Components/CardDetailsWidget/index"
import EMIPlans from "../components/Home/Components/ConvertToEMI/index"
import PaymentExpired from "../components/Home/PaymentGateway/paymentExpired"
import PaymentFailed from "../components/Home/PaymentGateway/paymentFailed"
import PaymentGateway from "../components/Home/PaymentGateway/paymentGateway"
import PaymentOptions from "../components/Home/PaymentGateway/paymentOptions"
import PaymentRequest from "../components/Home/PaymentGateway/paymentRequest"
import PaymentSuccess from "../components/Home/PaymentGateway/paymentSuccess"
import PaymentUpiVerify from "../components/Home/PaymentGateway/paymentUpiVerify"
import Home from "../components/Home/home"
import ManageCard from "../components/ManageCard/manageCard"
import AboutProgram from "../components/ManageCard/screens/AboutProgram"
import AnalyseSpend from "../components/ManageCard/screens/AnalyseSpend/spendAnalysis"
import SpendCategory from "../components/ManageCard/screens/AnalyseSpend/spendCategory"
import BlockCard from "../components/ManageCard/screens/BlockCard/blockCard/index"
import BlockCardReason from "../components/ManageCard/screens/BlockCard/blockCardReason/index"
import CardBlocked from "../components/ManageCard/screens/BlockCard/cardBlocked/index"
import NewCardRequest from "../components/ManageCard/screens/BlockCard/newCardRequest/index"
import NewCardSuccess from "../components/ManageCard/screens/BlockCard/newCardSuccess/newCardSuccess"
import CardSettings from "../components/ManageCard/screens/CardSettings/index"
import CustomerCare from "../components/ManageCard/screens/CustomerCare"
import Emi from "../components/ManageCard/screens/EMI/Emi"
import ForeClose from "../components/ManageCard/screens/EMI/ForeClose"
import ResetPin from "../components/MockSwitch/ResetPin/index"
import OfferDetails from "../components/OffersList/OfferDetails"
import OfferList from "../components/OffersList/OfferList"
import Preloader_v2 from "../components/PreLoader/preloader_v2"
import Rewards from "../components/Rewards"
import StatementFetchAllNew from "../components/Statement/fetchAll/Main"
import AuthCustomer from "../components/auth/customer/index"
import EmailOtp from "../components/auth/emailOtp"
import AuthMpin from "../components/auth/mpin/mpinPage/Mpin"
import MpinBlocked from "../components/auth/mpin/mpinPage/MpinBlocked"
import AuthSetMpin from "../components/auth/mpin/setMpin/index"
import AuthOtp from "../components/auth/otp/index"
import ActivationLimits from "../components/core/ActivationLimits"
import QRscanner from "../components/core/QRscanner/QRscanner"
import Payment from "../components/core/payment"
import Blocked from "../components/messages/Blocked"
import BlockedMultipleAttempts from "../components/messages/BlockedMultipleAttempts"
import ErrorScreenv2_1 from "../components/messages/errorScreenv2_1"
import EMIOverview from "../components/Home/Components/ConvertToEMI/EMIOverview"
import AllCvpOffers from "../components/OffersList/v3/AllCvpOffers"
import CvpOfferDetails from "../components/OffersList/v3/CvpOfferDetails"
import AllCampaignOffers from "../components/OffersList/v3/AllCampaignOffers"
import CampaignOfferDetails from "../components/OffersList/v3/CampaignOfferDetails"
import AllMerchantOffers from "../components/OffersList/v3/AllMerchantOffers"
import MerchantOfferDetails from "../components/OffersList/v3/MerchantOfferDetails"
import MpinComp from "../components/auth/mpin/mpinPage/index"
import RewardsError from "../components/messages/RewardsError"
import BlockCardReason_v3 from "../components/ManageCard/screens/BlockCard/blockCardBottomSheets/blockCardReason_v3"
import SessionExpired from "../components/messages/SessionExpired"
import ErrorScreen from "../components/messages/errorScreen"
import Paths from "./Paths"
import BlockedState_v3 from "../components/messages/BlockedState_v3"
import SavedCards from "../components/ManageCard/screens/Tokensation/savedCards"
import Cashback_V3 from "../components/Cashback/Cashback_V3"
import AllNudges from "../components/core/Nudge/v3/AllNudges"
import AddCardToMerchant from "../components/ManageCard/screens/Tokensation/addCardToMerchant"
import SavedCardsEmpty from "../components/ManageCard/screens/Tokensation/savedCardsEmpty"
import MainBlockedScreen from "../components/messages/index"
import SpendAnalytics from "../components/SpendAnalytics"
import CategoryInsight from "../components/SpendAnalytics/categoryInsights"
import AuthenticationTxn_v3 from "../components/Home/Components/TransactionSection/v3/AuthenticationTxn_v3"
import AuthenticationTxnSuccess_v3 from "../components/Home/Components/TransactionSection/v3/AuthenticationTxnSuccess_v3"
import AuthenticationTxnBlocked_v3 from "../components/Home/Components/TransactionSection/v3/AuthenticationTxnBlocked_v3"
import PayLaterAboutProgram from "../components/AllTransactions/v3/webView/PayLaterAboutProgram"

const CustomRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.HOME} element={<Home />} />
      <Route path={Paths.HOME_WITH_ACTIVATE} element={<Home />} />
      <Route path='/loader' element={<Preloader_v2 />} />
      <Route path='/Transactions' element={<ShowTransactions />} />
      <Route path='/ViewTransactions' element={<ViewTransactions />} />
      <Route path='/rewards' element={<Rewards />} />
      <Route path='/cashback' element={<Cashback_V3 />} />
      <Route path='/Statement' element={<StatementFetchAllNew />} />
      <Route path='/CardDetailsWidget' element={<CardDetailsWidget />} />
      <Route path='/PaymentGateway' element={<PaymentGateway />} />
      <Route path='/PaymentGateway/options' element={<PaymentOptions />} />
      <Route
        path='/PaymentGateway/options/verifyUpi'
        element={<PaymentUpiVerify />}
      />
      <Route
        path='/PaymentGateway/paymentRequest'
        element={<PaymentRequest />}
      />
      <Route path='/PaymentGateway/Success' element={<PaymentSuccess />} />
      <Route path='/PaymentGateway/Failed' element={<PaymentFailed />} />
      <Route path='/PaymentGateway/Expired' element={<PaymentExpired />} />

      <Route
        path='/CardDetailsError'
        element={<ErrorScreen showButtons={false} />}
      />
      <Route path='/payment' element={<Payment />} />
      <Route path='/ResetPin' element={<ResetPin />} />
      <Route path='/Offers' element={<OfferList />} />
      <Route path='/ManageCard' element={<ManageCard />} />
      <Route
        path='/ManageCard/Cards/Saved/Empty'
        element={<SavedCardsEmpty />}
      />
      <Route path='/ConvertToEMI' element={<EMIPlans />} />
      <Route path='/EMIOverview' element={<EMIOverview />} />
      <Route path='/Offers/Details' element={<OfferDetails />} />
      <Route path='/ManageCard/CardSettings' element={<CardSettings />} />
      <Route path='/ManageCard/BlockCard' element={<BlockCard />} />
      <Route path='/ManageCard/NewCardRequest' element={<NewCardRequest />} />
      <Route path='/ManageCard/NewCardSuccess' element={<NewCardSuccess />} />
      <Route path='/ManageCard/ListEMI' element={<Emi />} />
      <Route path='/ManageCard/AboutProgram' element={<AboutProgram />} />
      <Route path='/ManageCard/AnalyseSpend' element={<AnalyseSpend />} />
      <Route path='/ManageCard/CustomerCare' element={<CustomerCare />} />
      <Route path='/ManageCard/ForecloseEmi' element={<ForeClose />} />
      <Route
        path='/ManageCard/BlockCardReason'
        element={<BlockCardReason_v3 />}
      />
      <Route path='/ActivateCard/Scanner' element={<QRscanner />} />
      <Route
        path={Paths.ACTIVATION_CARD_CONTROLS}
        element={<ActivationLimits />}
      />
      <Route path='/Auth/Otp' element={<AuthOtp />} />
      <Route path='/Auth/EmailOtp' element={<EmailOtp />} />
      <Route path='/Auth/Mpin' element={<MpinComp />} />
      <Route path='/Auth/SetMpin' element={<AuthSetMpin />} />
      <Route path='/Auth/MpinBlocked' element={<MpinBlocked />} />
      <Route path='/Auth/Customer' element={<AuthCustomer />} />
      <Route
        path='/ManageCard/AnalyseSpend/Category'
        element={<SpendCategory />}
      />
      <Route
        path='/ManageCard/BlockCard/Reason'
        element={<BlockCardReason />}
      />
      <Route
        path='/ManageCard/BlockCard/CardBlocked'
        element={<CardBlocked />}
      />
      <Route path='/SessionExpired' element={<SessionExpired />} />
      <Route path='/Error' element={<ErrorScreen />} />
      <Route path='/ErrorOccured' element={<ErrorScreenv2_1 />} />
      <Route path='/Rewards/Error' element={<RewardsError />} />
      <Route path='/Blocked' element={<MainBlockedScreen />} />
      <Route
        path='/BlockedAttemptExhausted'
        element={<BlockedMultipleAttempts />}
      />
      <Route path='/AttemptExhausted' element={<BlockedState_v3 />} />
      <Route path='/CvpOffers' element={<AllCvpOffers />} />
      <Route path='/CvpOffers/:BenefitId' element={<CvpOfferDetails />} />
      <Route path='/CampaignOffers' element={<AllCampaignOffers />} />
      <Route
        path='/CampaignOffers/:BenefitId'
        element={<CampaignOfferDetails />}
      />
      <Route path='/MerchantOffers' element={<AllMerchantOffers />} />
      <Route path='/allPillNudges' element={<AllNudges />} />
      <Route
        path='/MerchantOffers/:BenefitId'
        element={<MerchantOfferDetails />}
      />
      <Route path='/ManageCard/Cards/Saved' element={<SavedCards />} />
      <Route path='/ManageCard/Cards/Add' element={<AddCardToMerchant />} />
      <Route
        path='/Authenticate/Transaction'
        element={<AuthenticationTxn_v3 />}
      />
      <Route
        path='/Authenticate/Transaction/Success'
        element={<AuthenticationTxnSuccess_v3 />}
      />
      <Route
        path='/Authenticate/Transaction/Blocked'
        element={<AuthenticationTxnBlocked_v3 />}
      />
      <Route path='*' element={<Home />} />
      <Route path='/spendAnalytics' element={<SpendAnalytics />} />
      <Route path='/spendAnalytics/category' element={<CategoryInsight />} />
      <Route path='/programDetails' element={<PayLaterAboutProgram />} />
    </Routes>
  )
}

export default CustomRoutes
