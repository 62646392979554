const ConcentricCircles = ({ color = "#000000" }) => {
  return (
    <div>
      <svg
        width='100%'
        height='65'
        viewBox='0 0 87 65'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g id='Group 4' opacity='0.3'>
          <circle
            id='Ellipse 410'
            cx='87.6255'
            cy='86.8715'
            r='24.2544'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 412'
            cx='87.6225'
            cy='86.8764'
            r='31.3256'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 413'
            cx='87.6257'
            cy='86.8757'
            r='39.407'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 414'
            cx='87.629'
            cy='86.879'
            r='47.4884'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 415'
            cx='87.6245'
            cy='86.8745'
            r='55.5698'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 417'
            cx='87.6277'
            cy='86.8777'
            r='63.6512'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 418'
            cx='87.624'
            cy='86.874'
            r='73.7529'
            stroke={color}
            stroke-width='2'
          />
          <circle
            id='Ellipse 419'
            cx='87.625'
            cy='86.875'
            r='85.875'
            stroke={color}
            stroke-width='2'
          />
        </g>
      </svg>
    </div>
  )
}

export default ConcentricCircles
