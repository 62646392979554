import React from "react"
import { getV3Theme } from "../../../utils/theme"

const WithdrawalIcon = ({
  color1 = getV3Theme().tertiaryNeutral.color2,
  color2 = getV3Theme.tertiaryNeutral.color5,
}) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='32' height='32' rx='16' fill={color2} />
      <path
        d='M22.5 11V14.9667C22.5 15.9067 21.94 16.4667 21 16.4667H20.8667C20.756 16.4667 20.6667 16.3773 20.6667 16.2667V11.8667C20.6667 11.756 20.5773 11.6667 20.4667 11.6667H11.5333C11.4227 11.6667 11.3333 11.756 11.3333 11.8667V16.2667C11.3333 16.3773 11.244 16.4667 11.1333 16.4667H11C10.06 16.4667 9.5 15.9067 9.5 14.9667V11C9.5 10.06 10.06 9.5 11 9.5H21C21.94 9.5 22.5 10.06 22.5 11ZM19.6667 12.8667V20C19.6667 21.1047 18.7713 22 17.6667 22H14.3333C13.2287 22 12.3333 21.1047 12.3333 20V12.8667C12.3333 12.756 12.4227 12.6667 12.5333 12.6667H19.4667C19.5773 12.6667 19.6667 12.756 19.6667 12.8667ZM16.6667 19.67C16.6667 19.302 16.368 19.0033 16 19.0033C15.632 19.0033 15.3333 19.302 15.3333 19.67C15.3333 20.038 15.632 20.3367 16 20.3367C16.368 20.3367 16.6667 20.0387 16.6667 19.67ZM17.5 16.1667C17.5 15.338 16.8287 14.6667 16 14.6667C15.1713 14.6667 14.5 15.338 14.5 16.1667C14.5 16.9953 15.1713 17.6667 16 17.6667C16.8287 17.6667 17.5 16.9953 17.5 16.1667Z'
        fill={color1}
      />
    </svg>
  )
}

export default WithdrawalIcon
