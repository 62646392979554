import React from "react"
import Transaction_v3 from "./Transaction_v3"
import { EventName } from "../../utils/enums"

const AllTransactions_v3 = ({ transactions }) => {
  return (
    <div className='v3-all-transactions-section'>
      {transactions?.map((transaction, index) => {
        return (
          <Transaction_v3
            screenName={EventName.SHOW_TRANSACTION}
            transaction={transaction}
          />
        )
      })}
    </div>
  )
}

export default AllTransactions_v3
