const TokenisationProcessing = ({ color = "" }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='clock'>
        <path
          id='clock_2'
          d='M7.00118 0.729248C3.54318 0.729248 0.730347 3.54208 0.730347 7.00008C0.730347 10.4581 3.54318 13.2709 7.00118 13.2709C10.4592 13.2709 13.272 10.4581 13.272 7.00008C13.272 3.54208 10.4592 0.729248 7.00118 0.729248ZM7.00118 12.3959C4.0256 12.3959 1.60535 9.97566 1.60535 7.00008C1.60535 4.0245 4.0256 1.60425 7.00118 1.60425C9.97676 1.60425 12.397 4.0245 12.397 7.00008C12.397 9.97566 9.97676 12.3959 7.00118 12.3959ZM9.06036 8.4409C9.23128 8.61181 9.23128 8.88892 9.06036 9.05983C8.9752 9.145 8.86318 9.18815 8.75118 9.18815C8.63918 9.18815 8.52716 9.14558 8.442 9.05983L6.692 7.30983C6.60975 7.22758 6.56368 7.11615 6.56368 7.00065V4.08398C6.56368 3.84248 6.75968 3.64648 7.00118 3.64648C7.24268 3.64648 7.43868 3.84248 7.43868 4.08398V6.81921L9.06036 8.4409Z'
          fill={color || "#E5B200"}
        />
      </g>
    </svg>
  )
}

export default TokenisationProcessing
