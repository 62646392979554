import { useSelector } from "react-redux"
import InfoCircle from "../svg/v3/infoCircle"

const InfoPromptV3 = ({ info, customClassName }) => {
  const theme = useSelector(state => state.theme)
  return (
    <div className={`v3-info-prompt ${customClassName}`}>
      <InfoCircle color={theme.v3.rawColors.tertiaryNeutral.color1} />
      <div className='v3-info-propmt-text'>{info}</div>
    </div>
  )
}

export default InfoPromptV3
