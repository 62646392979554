import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import { DisplayConfig, EmiStatus } from "../../../../utils/constants"
import { AU_BlockCodes, EventName, PwaVersions } from "../../../../utils/enums"
import { captureEvents, consoleError } from "../../../../utils/functions"
import { Header } from "../../../core"
import EmiListLoader from "../../../core/SkeletonLoader/EmiListLoader"
import EmiListLoaderV3 from "../../../core/SkeletonLoader/EmiListLoaderV3"
import { ColorButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import BillEmi from "./Components/BillEmi"
import TransactionEmi from "./Components/TransactionEmi"
import TransactionEmiV3 from "./Components/TransactionEmiV3"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import ChevronLeftIcon from "../../../../nativeBaseReplacements/ChevronLeftIcon"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const Emi = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const user = useSelector(state => state.user)
  const theme = useSelector(state => state.theme)
  const common = useSelector(state => state.common)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)

  const [selected, setSelected] = useState(user.enableBalanceEMI ? 0 : 1)
  const [error /* , setError */] = useState(false)
  const [billEmiData, setBillEmiData] = useState([])
  const [transactionEmiData, setTransactionEmiData] = useState([])
  const [disabledByBlockCode, setDisabledByBlockCode] = useState(false)
  const [loading, setLoading] = useState(true)

  const { isListEmiSet, listEmiData } = common

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    console.log(isListEmiSet, listEmiData)
    captureEvents({ eventName: EventName.EMI, metadata: {} })
  }, [])

  useEffect(() => {
    if (user.account.status !== "ACTIVE") {
      navigate("/")
    }
  }, [user.account.status, navigate])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      )
    ) {
      setDisabledByBlockCode(true)
      toast(t("emi.accessDenied"))
      navigate("/")
      return false
    }

    ;(async () => {
      if (!isListEmiSet) {
        dispatch(clearCommonState())

        await handleApiAuth({
          apiId: ApiIds.LIST_EMI,
          onAuthSuccess: onListEmiAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("EMI.getEmiListReasons"),
          mpinReason: t("EMI.getEmiListReasons"),
          toast,
          navigate,
        })
      } else {
        const activeEmis = listEmiData.emiDetails.filter(
          emi => emi.status === EmiStatus.ACTIVE,
        )

        const bills = activeEmis.filter(item => {
          return item.emiType === "BALANCE"
        })

        const transaction = activeEmis.filter(item => {
          return item.emiType === "TRANSACTION"
        })

        captureEvents({
          eventName: EventName.EMI_DETAILS,
          metadata: { billEmi: bills, transactionEmi: transaction },
        })
        setBillEmiData(bills)
        setTransactionEmiData(transaction)
      }

      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListEmiSet])

  const onListEmiAuthSuccess = async apiToken => {
    try {
      const response = await AccountService.listEmi(apiToken, user.account.id)
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isListEmiSet: true,
            listEmiData: result.data,
          }),
        )
        if (!disabledByBlockCode)
          navigate("/ManageCard/ListEMI", { replace: true })
      } else {
        consoleError(t("emi.fetchEmiListError", { errMessage: result?.errors }))
        toast(t("emi.errorOccurred"))
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(t("emi.fetchEmiExceptionMessage", { errMessage: error }))
      toast(t("emi.errorOccurred"))
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }
  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  const getTextColor = number => {
    if (getVersionedDisplay()) {
      if (selected === number) {
        return theme.color1
      } else {
        return theme.appTextColor
      }
    }
    return null
  }

  return loading ? (
    config.version === PwaVersions.V3 ? (
      <div
        style={{
          height: windowDimensions.height,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <EmiListLoaderV3 />{" "}
      </div>
    ) : config.version === PwaVersions.V3 ? (
      <div
        style={{
          height: windowDimensions.height,
          backgroundColor: theme.backgroundColor,
        }}
      >
        <EmiListLoaderV3 />{" "}
      </div>
    ) : (
      <div
        className='emi-loader-v2'
        style={{
          height: windowDimensions.height,
          backgroundColor: `${
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? theme.backgroundColor
              : "white"
          }`,
        }}
      >
        <EmiListLoader />
      </div>
    )
  ) : error ? (
    <ErrorScreen />
  ) : (
    <div className='emi'>
      <div
        className='emi-container'
        style={{
          maxWidth: DisplayConfig.MAX_WIDTH,
          height: windowDimensions.height,
        }}
      >
        {config?.version == PwaVersions.V3 ||
        config?.version == PwaVersions.V2 ||
        config?.version == PwaVersions.V2_1 ? (
          <Header
            marginBottom='1px'
            text={config?.version == PwaVersions.V3 ? "Your EMIs" : "My EMIs"}
            onBack={async () => {
              window.history.go(-1)
            }}
          />
        ) : (
          <div
            className='emi-header'
            style={{
              backgroundColor: getVersionedDisplay()
                ? theme.headerBackgroundColor
                : null,
            }}
          >
            <div onClick={() => navigate(-1)}>
              <ChevronLeftIcon
                size='24px'
                color={getVersionedDisplay() ? theme.titleColor : null}
              />
            </div>
            <div
              className='emi-header-text'
              style={{ color: getVersionedDisplay() ? theme.titleColor : null }}
            >
              {t("emi.myEmiHeader")}
            </div>
          </div>
        )}
        {config?.version !== PwaVersions.V3 && (
          <div
            className='emi-content'
            style={{
              backgroundColor: getVersionedDisplay()
                ? theme.backgroundColor
                : null,
            }}
          >
            {user.enableBalanceEMI && (
              <div
                className='emi-content-toggleHeader'
                style={{
                  width:
                    user.enableTransactionEMI && user.enableBalanceEMI
                      ? "50%"
                      : "100%",
                  borderBottomColor: theme.color1,
                  borderBottomWidth: selected === 0 ? "2px" : "0px",
                }}
                onClick={() => setSelected(0)}
              >
                <div
                  className='emi-content-toggleHeader-text'
                  style={{ color: getTextColor(0) }}
                >
                  {t("emi.errorOccurred")}
                </div>
              </div>
            )}
            {user.enableTransactionEMI && (
              <div
                className='emi-content-toggleHeader'
                style={{
                  width:
                    user.enableTransactionEMI && user.enableBalanceEMI
                      ? "50%"
                      : "100%",
                  borderBottomColor: theme.color1,
                  borderBottomWidth: selected === 1 ? "2px" : "0px",
                }}
                onClick={() => setSelected(1)}
              >
                <div
                  className='emi-content-toggleHeader-text'
                  style={{ color: getTextColor(1) }}
                >
                  {t("emi.transactionEmiHeader")}
                </div>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            minHeight: getVersionedDisplay() ? windowDimensions.height : null,
            backgroundColor: getVersionedDisplay()
              ? theme.backgroundColor
              : null,
          }}
        >
          {config?.version == PwaVersions.V3 ? (
            <TransactionEmiV3 data={transactionEmiData} />
          ) : selected === 0 ? (
            <>
              <BillEmi data={billEmiData} />
            </>
          ) : (
            <>
              <TransactionEmi data={transactionEmiData} />
            </>
          )}
        </div>
      </div>
      {config?.version === PwaVersions.V3 && (
        <div className='v3-emi-cta-container'>
          <ColorButton text={"Create New EMI"} width={"94%"} />
        </div>
      )}
    </div>
  )
}

export default Emi
