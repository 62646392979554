import React from "react"
import { useSelector } from "react-redux"
import spendAnalyticsZeroStateImg from "../../assets/images/v3/spendAnalyticsZeroState.svg"
import { useTranslation } from "react-i18next"

const NewUserSpends = () => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const userName = user?.customer?.firstName
  return (
    <div className='v3-spend-analytics-empty-container'>
      <div className='v3-spend-analytics-empty-section'>
        <div className='v3-spend-analytics-empty-header'>
          {t("spendAnalytics.newUserSpends.userNameText", {
            userName: userName,
          })}
        </div>
        <div className='v3-spend-analytics-empty-body'>
          <div className='v3-spend-analytics-empty-body-illustration'>
            <img src={spendAnalyticsZeroStateImg} alt='illustration' />
          </div>
          <div className='v3-spend-analytics-empty-body-info-container'>
            <div className='v3-spend-analytics-empty-body-info-header'>
              {t("spendAnalytics.newUserSpends.headerText")}
            </div>
            <div className='v3-spend-analytics-empty-body-info-description'>
              {t("spendAnalytics.newUserSpends.descriptionText")}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewUserSpends
