import moment from "moment"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import cashbackDefaultIcon from "../../../assets/images/cashbackDefaultIcon.svg"
import rewardsTransactionLogo from "../../../assets/images/rewardsTransactionLogo.svg"
import { EventName, PwaVersions } from "../../../utils/enums"
import {
  captureEvents,
  formatAmount,
  formatString,
  truncateString,
} from "../../../utils/functions"
import Text from "../../../nativeBaseReplacements/Text"
import View from "../../../nativeBaseReplacements/View"
import ChevronUpIcon from "../../../nativeBaseReplacements/ChevronUpIcon"
import ChevronDownIcon from "../../../nativeBaseReplacements/ChevronDownIcon"

const TRANSACTION_STATUS = {
  VERIFIED_FOR_POSTING: "VERIFIED_FOR_POSTING",
  PARTIALLY_VERIFIED: "PARTIALLY_VERIFIED",
  REVERSED: "REVERSED",
  POSTED: "POSTED",
}

const isShowPendingText = (transactionType, status) => {
  if (!status) return false
  if (transactionType == "HF_REWARDS" || transactionType == "HF_CASHBACK") {
    return (
      status == TRANSACTION_STATUS.VERIFIED_FOR_POSTING ||
      status == TRANSACTION_STATUS.PARTIALLY_VERIFIED
    )
  } else {
    return status != TRANSACTION_STATUS.POSTED
  }
}

const Transactions = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [showDesc, setShowDesc] = useState(false)
  const config = useSelector(state => state.config)

  const getLogo = (type, merchantLogo, enrichmentLogo) => {
    if (merchantLogo || enrichmentLogo) {
      if (type == "HF_CASHBACK" || type == "HF_REWARDS") {
        return "data:image/png;base64," + (merchantLogo || enrichmentLogo)
      } else {
        return merchantLogo || enrichmentLogo
      }
    } else {
      if (type == "HF_CASHBACK") {
        return cashbackDefaultIcon
      } else {
        return rewardsTransactionLogo
      }
    }
  }

  return (
    <View py='12px' borderBottomWidth={"1px"} borderColor='#ECECEC'>
      <View
        flexDir={"row"}
        justifyContent='center'
        alignItems={"center"}
        w='100%'
      >
        {!config?.jsonConfig?.rewards?.disableRewardsTransactionLogo && (
          <View
            style={
              props.fromRewards
                ? { marginLeft: "-10px", marginRight: "0px" }
                : {}
            }
            w={"20%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <img
              alt='logo'
              src={getLogo(
                props?.type,
                props.transaction?.merchantLogo,
                props.transaction?.enrichmentLogo,
              )}
              style={{ height: "42px", width: "42px" }}
            />
          </View>
        )}
        <View
          w={
            !config?.jsonConfig?.rewards?.disableRewardsTransactionLogo
              ? "80%"
              : "100%"
          }
          flex={"1"}
        >
          <View
            onClick={() => setShowDesc(!showDesc)}
            display={"flex"}
            flexDir={"row"}
            alignItems={"start"}
            justifyContent={"space-between"}
            w={"100%"}
          >
            <View w='50%'>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {showDesc
                  ? config.version === PwaVersions.V2_1
                    ? formatString(props.transaction.merchantName) ||
                      formatString(props.transaction.txnNarration)
                    : formatString(props.transaction.description)
                  : truncateString(
                      config.version === PwaVersions.V2_1
                        ? formatString(props.transaction.merchantName) ||
                            formatString(props.transaction.txnNarration)
                        : formatString(props.transaction.description),
                      16,
                    )}
              </Text>
            </View>
            <View w='42%'>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
                textAlign={"end"}
              >
                {props.transaction.status === TRANSACTION_STATUS.REVERSED
                  ? "- "
                  : "+ "}
                {`${props?.type === "HF_CASHBACK" ? "₹" : ""} ${
                  Number(props.transaction.outcomeValue) ||
                  Number(props.transaction.value) ||
                  0
                } `}
                {props?.type !== "HF_CASHBACK" ? "Points" : ""}
              </Text>
            </View>
            {
              <View
                onClick={() => {
                  captureEvents({
                    eventName: EventName.REWARDS_HISTORY_CTA,
                    metadata: { transaction: props?.transaction },
                  })
                  setIsOpen(!isOpen)
                }}
              >
                {isOpen ? (
                  <ChevronUpIcon color={props.theme.color1} size='20px' />
                ) : (
                  <ChevronDownIcon color={props.theme.color1} size='20px' />
                )}
              </View>
            }
          </View>
          <View
            w='100%'
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={`${props.theme.appTextColor}:alpha.70`}
              >
                {moment(
                  props?.type === "HF_CASHBACK"
                    ? props.transaction?.originalTransactionDate
                    : props.transaction?.outcomePostingDate ||
                        props.transaction?.postingDate,
                ).format("DD MMM YYYY")}
              </Text>
            </View>
            <View w={"35%"} display={"flex"} justifyContent={"flex-end"}>
              {isShowPendingText(props?.type, props.transaction.status) && (
                <View p='4px' borderRadius={"4px"}>
                  <Text
                    fontFamily={props.theme.fontFamily}
                    fontWeight='400'
                    fontSize='12px'
                    lineHeight='12px'
                    letterSpacing='0.02em'
                    color={props.theme.appTextColor}
                    textAlign={"right"}
                  >
                    Pending
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
      {isOpen && (
        <View
          borderRadius={"8px"}
          background={props.theme.backgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          mt='16px'
          p='12px'
        >
          {(props.transaction.metadata?.txnAmount ||
            props?.transaction?.originalTransactionAmount) && (
            <View
              alignItems={"center"}
              justifyContent='space-between'
              flexDir={"row"}
              mt='5px'
            >
              <View>
                <Text
                  fontFamily={props.theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='12px'
                  letterSpacing='0.02em'
                  color={props.theme.appTextColor}
                >
                  Transaction Amount
                </Text>
              </View>
              <View>
                <Text
                  fontFamily={props.theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='12px'
                  letterSpacing='0.02em'
                  color={props.theme.appTextColor}
                >
                  {formatAmount(
                    Number(
                      props?.type === "HF_REWARDS" ||
                        props?.type === "HF_CASHBACK"
                        ? props?.transaction?.originalTransactionAmount
                        : props.transaction.metadata?.txnAmount,
                    ),
                  )}
                </Text>
              </View>
            </View>
          )}

          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDir={"row"}
            mt='12px'
          >
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {props?.type === "HF_CASHBACK"
                  ? "Posting Date"
                  : "Transaction Date"}
              </Text>
            </View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {moment(
                  props?.type === "HF_REWARDS"
                    ? props?.transaction?.originalTransactionDate
                    : props?.type === "HF_CASHBACK"
                      ? props?.transaction?.outcomePostingDate
                      : props.transaction.metadata?.txnDate,
                ).format("DD MMM YYYY")}
              </Text>
            </View>
          </View>
          {props?.type === "HF_CASHBACK" &&
            !isShowPendingText(props?.type, props.transaction.status) && (
              <>
                <hr></hr>
                <View
                  fontWeight={"500"}
                  fontSize={"12px"}
                  textAlign={"center"}
                  marginTop={"-2px"}
                  marginBottom={"5px"}
                  opacity={"0.5"}
                >
                  {props?.transaction?.description}
                </View>
              </>
            )}

          {/* {
            <View
              alignItems={"center"}
              justifyContent='space-between'
              flexDir={"row"}
              mt='12px'
            >
              <View w='60%'>
                <Text
                  fontFamily={props.theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='12px'
                  letterSpacing='0.02em'
                  color={props.theme.appTextColor}
                >
                  Benefit Applied
                </Text>
              </View>
              <View w='40%'>
                <Text
                  textAlign={"right"}
                  fontFamily={props.theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='12px'
                  letterSpacing='0.02em'
                  color={props.theme.appTextColor}
                >
                  {formatString(
                    truncateString(
                      config.version === PwaVersions.V2_1
                        ? props.transaction.description
                        : props.transaction.merchantName,
                      14,
                    ),
                  )}
                </Text>
              </View>
            </View>
          } */}
        </View>
      )}
    </View>
  )
}

export default Transactions
