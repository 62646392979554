const CouponBackground = ({ color = "#EAF4FF" }) => {
  return (
    <svg
      width='100%'
      height='73'
      viewBox='0 0 205 73'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M0.136719 61.2621V12.9753C0.342031 12.9834 0.548371 12.9874 0.755664 12.9874C8.2393 12.9874 14.4817 7.67314 15.915 0.612488H204.324V72.3875H15.6099C13.7367 65.9524 7.79534 61.2499 0.755664 61.2499C0.548371 61.2499 0.342031 61.254 0.136719 61.2621Z'
        fill={color}
      />
    </svg>
  )
}

export default CouponBackground
