import React from "react"
import { useSelector } from "react-redux"
import { CustomerCareContactTypes } from "../../../../../utils/enums"
import Telephone from "../../../../svg/v3/Telephone"
import CaretRight from "../../../../svg/v3/CaretRight"

const CustomerServiceCard = ({ handleOnClick, contactType }) => {
  const [theme, screen] = useSelector(state => [state.theme, state.screen])

  const {
    primary: { color1: primaryColor1 },
  } = theme.v3.rawColors

  const { customerCare } = screen

  return (
    <div
      className='v3-customerSupport-bottomSheet-contacts'
      onClick={() =>
        handleOnClick({
          contact:
            contactType === CustomerCareContactTypes.EMAIL
              ? CustomerCareContactTypes.EMAIL
              : CustomerCareContactTypes.CALL,
        })
      }
    >
      <div>
        <div className='v3-customerSupport-bottomSheet-contacts-image'>
          <Telephone color={primaryColor1} />
        </div>
        <div className='v3-customerSupport-bottomSheet-contacts-text'>
          <div>
            {contactType === CustomerCareContactTypes.EMAIL
              ? customerCare?.emailTitle
              : customerCare?.moblieNoTitle}
          </div>
          <div>
            {contactType === CustomerCareContactTypes.EMAIL
              ? customerCare?.email
              : customerCare?.moblieNo}
          </div>
        </div>
      </div>
      <div>
        <CaretRight color={primaryColor1} />
      </div>
    </div>
  )
}

export default CustomerServiceCard
