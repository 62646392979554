import React from "react"

const MagnifyingGlass = ({ color = "#0E0E0E" }) => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.66264 0.5C2.99086 0.5 0 3.49086 0 7.16264C0 10.8344 2.99086 13.8253 6.66264 13.8253C8.25935 13.8253 9.72572 13.2583 10.8749 12.3171L14.853 16.2951C14.9144 16.3591 14.9879 16.4101 15.0693 16.4453C15.1507 16.4805 15.2382 16.4991 15.3269 16.5C15.4155 16.5009 15.5035 16.4841 15.5855 16.4506C15.6676 16.4171 15.7422 16.3675 15.8048 16.3048C15.8675 16.2422 15.9171 16.1676 15.9506 16.0855C15.9841 16.0035 16.0009 15.9155 16 15.8269C15.9991 15.7382 15.9805 15.6507 15.9453 15.5693C15.9101 15.4879 15.8591 15.4144 15.7951 15.353L11.8171 11.3749C12.7583 10.2257 13.3253 8.75935 13.3253 7.16264C13.3253 3.49086 10.3344 0.5 6.66264 0.5ZM6.66264 1.83253C9.61428 1.83253 11.9928 4.21101 11.9928 7.16264C11.9928 10.1143 9.61428 12.4928 6.66264 12.4928C3.71101 12.4928 1.33253 10.1143 1.33253 7.16264C1.33253 4.21101 3.71101 1.83253 6.66264 1.83253Z'
        fill={color}
      />
    </svg>
  )
}

export default MagnifyingGlass
