import React, { useEffect, useState } from "react"
import lockedOffer from "../../../../assets/images/v3/lockedOffer.svg"
import ProgressBar from "./progressBar"
import { formatAmount, hexToRGBA } from "../../../../utils/functions"
import DescreteProgressBar from "./descreteProgressBar"
import { useSelector } from "react-redux"
import { offerType } from "../../../../utils/constants"
import { milestoneStyle } from "../../../../utils/enums"

const PendingMilestoneCard = ({ details, offer }) => {
  const theme = useSelector(state => state.theme)
  const [progressBarWidth, setProgressBardWidth] = useState(0)

  const pendingText = details?.pendingText?.replace(
    "{pendingValue}",
    `${details?.milestoneStyle !== "descrete" ? formatAmount(offer?.threshold - offer?.currentValue) : offer?.threshold - offer?.currentValue}`,
  )

  useEffect(() => {
    setProgressBardWidth((offer?.currentValue / offer?.threshold) * 100)
  }, [offer])

  return (
    <div
      className='v3-offerDetails-PendingMilestoneCard'
      style={
        offer?.offerType === offerType.PROGRAM_OFFERS
          ? {
              borderRadius: "12px",
              padding: "16px",
              borderColor: theme.v3.cssVars.primary.color2,
              color: theme?.v3.cssVars.tertiaryNeutral.color1,
            }
          : {
              borderRadius: "18px",
              padding: "14px 18px 14px 14px",
              // paddingRight:"16px",
              borderColor: theme.v3.cssVars.primary.color2,
              color: theme?.v3.cssVars.tertiaryNeutral.color1,
            }
      }
    >
      {details?.milestoneStyle !== "descrete" && (
        <img
          src={lockedOffer}
          alt='lock'
          className='v3-offerDetails-pendingMilestoneCard-image'
        />
      )}
      <div
        style={{
          gap: offer?.offerType === offerType.PROGRAM_OFFERS ? "7px" : "9px",
        }}
      >
        {offer?.offerType === offerType.PROGRAM_OFFERS && (
          <div className='v3-offerDetails-pendingMilestoneCard-text'>
            {pendingText}
          </div>
        )}
        <div>
          {details?.milestoneStyle === "descrete" ? (
            <DescreteProgressBar
              milestoneValue={offer?.currentValue}
              thresholdValue={offer?.threshold}
              height={
                offer?.offerType === offerType.PROGRAM_OFFERS ? "8px" : "16px"
              }
              progressColor={
                offer?.displayColor || theme.v3.rawColors.secondary.color1
              }
              showShadow={offer?.offerType !== offerType.PROGRAM_OFFERS}
            />
          ) : (
            <ProgressBar
              progressBarWidth={progressBarWidth}
              height={
                offer?.offerType === offerType.PROGRAM_OFFERS ? "8px" : "16px"
              }
              progressColor={
                offer?.displayColor || theme.v3.rawColors.secondary.color1
              }
              showTransition
              showShadow={offer?.offerType !== offerType.PROGRAM_OFFERS}
            />
          )}
        </div>
        {offer?.offerType !== offerType.PROGRAM_OFFERS && (
          <div className='v3-offerDetails-pendingMilestoneCard-text'>
            {pendingText}
          </div>
        )}
        {offer?.offerType === offerType.PROGRAM_OFFERS &&
          details?.milestoneStyle !== milestoneStyle.DESCRETE && (
            <div
              className='v3-offerDetails-pendingMilestoneCard-amountContainer'
              style={{ color: theme.v3.cssVars.primaryBase.color2 }}
            >
              <div>{formatAmount(offer?.currentValue).split(".")[0]}</div>
              <div>{formatAmount(offer?.threshold).split(".")[0]}</div>
            </div>
          )}
      </div>
    </div>
  )
}

export default PendingMilestoneCard
