import { SET_SCREEN_STATE } from "../ActionTypes"

const initialState = {}

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SCREEN_STATE:
      return {
        ...state,
        ...action.payload,
        // to unset a var it can be set as undefined or null
      }
    default:
      return state
  }
}

export default CommonReducer
