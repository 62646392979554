import React from "react"
import sessionExpiredWeb from "../../../../../assets/images/v3/sessionExpiredWeb.svg"
import { Header } from "../../../../core"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const SessionExpiredWeb = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const screen = useSelector(state => state.screen)
  const headerConfig = screen?.pwaHeader

  const handleLoginClick = () => {
    navigate("/")
  }
  return (
    <>
      {headerConfig?.primaryLogoUrl && <Header />}
      <div className='v3-session-expired-web-container'>
        <div className='v3-session-expired-web-header-container'>
          <div className='v3-session-expired-web-header-image'>
            <img src={sessionExpiredWeb} />
          </div>
          <div className='v3-session-expired-web-header-text-container'>
            <div className='v3-session-expired-web-primary-header-text'>
              {t("messages.sessionExpiredText")}!
            </div>
            <div className='v3-session-expired-web-secondary-header-text'>
              {t("messages.loginAgainMessage")}
            </div>
          </div>
        </div>
        <div
          className='v3-session-expired-web-cta-container'
          onClick={handleLoginClick}
        >
          <div className='v3-session-expired-web-cta-text'>
            {t("messages.logInText")}
          </div>
        </div>
      </div>
    </>
  )
}

export default SessionExpiredWeb
