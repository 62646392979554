import { getNativeBaseSpaceToPx } from "../utils/functions"

const ChevronRightIcon = ({ color, size }) => {
  return (
    <svg
      color={color}
      viewBox={`0 0 24 24`}
      stroke=''
      role='img'
      style={{
        marginBottom: "2px",
        height: getNativeBaseSpaceToPx(size, 4),
        width: getNativeBaseSpaceToPx(size, 4),
      }}
    >
      <g>
        <path
          fill='currentColor'
          d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'
          stroke=''
        ></path>
      </g>
    </svg>
  )
}

export default ChevronRightIcon
