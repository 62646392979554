import React from "react"

const CreditCardSvg = ({ color = "#0D2950", secondaryColor = "#25314C" }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='credit-card-plus'>
        <path
          id='Vector'
          d='M13.3333 4.66663V5.99996H1.33331V4.66663C1.33331 3.33329 1.99998 2.66663 3.33331 2.66663H11.3333C12.6666 2.66663 13.3333 3.33329 13.3333 4.66663Z'
          fill={color}
        />
        <path
          id='Vector_2'
          opacity='0.4'
          d='M13.3333 6V7.63334C13.3333 7.74667 13.24 7.83333 13.1266 7.83333C13.1 7.83333 13.0733 7.82665 13.0467 7.81999C12.7133 7.71999 12.3666 7.66667 12 7.66667C9.97998 7.66667 8.33331 9.31333 8.33331 11.3333C8.33331 11.4867 8.33999 11.64 8.35999 11.7933C8.35999 11.9067 8.26665 12 8.15332 12H3.33331C1.99998 12 1.33331 11.3333 1.33331 10V6H13.3333Z'
          fill={color}
        />
        <path
          id='Vector_3'
          d='M6 9.83337H4C3.724 9.83337 3.5 9.60937 3.5 9.33337C3.5 9.05737 3.724 8.83337 4 8.83337H6C6.276 8.83337 6.5 9.05737 6.5 9.33337C6.5 9.60937 6.276 9.83337 6 9.83337Z'
          fill={color}
        />
        <path
          id='Vector_4'
          d='M12 8.66663C10.5266 8.66663 9.33331 9.85996 9.33331 11.3333C9.33331 12.8066 10.5266 14 12 14C13.4733 14 14.6666 12.8066 14.6666 11.3333C14.6666 9.85996 13.4733 8.66663 12 8.66663ZM12.8333 11.6666H12.3333V12.1666C12.3333 12.3533 12.1866 12.5 12 12.5C11.8133 12.5 11.6666 12.3533 11.6666 12.1666V11.6666H11.1666C10.98 11.6666 10.8333 11.52 10.8333 11.3333C10.8333 11.1466 10.98 11 11.1666 11H11.6666V10.5C11.6666 10.3133 11.8133 10.1666 12 10.1666C12.1866 10.1666 12.3333 10.3133 12.3333 10.5V11H12.8333C13.02 11 13.1666 11.1466 13.1666 11.3333C13.1666 11.52 13.02 11.6666 12.8333 11.6666Z'
          fill={secondaryColor}
        />
      </g>
    </svg>
  )
}

export default CreditCardSvg
