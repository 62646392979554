import React from "react"

const AnalyseSpends = ({ color = "#1965CF" }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='29'
    height='30'
    viewBox='0 0 29 30'
    fill='none'
  >
    <path
      d='M26.0515 12.2148C25.402 7.3774 21.7049 3.52605 17.0609 2.85068C16.4689 2.7633 15.8661 2.95181 15.4095 3.36378C14.9493 3.78073 14.6856 4.38123 14.6856 5.01415V12.5144C14.6856 13.7129 15.6216 14.689 16.7733 14.689H23.9747C24.5811 14.689 25.1587 14.4143 25.5578 13.9362C25.9545 13.4606 26.1342 12.834 26.0515 12.2148ZM24.2012 12.7054C24.1677 12.7466 24.0921 12.8152 23.9747 12.8152H16.7745C16.6139 12.8152 16.4845 12.6805 16.4845 12.5132V5.01293C16.4845 4.88934 16.5504 4.81077 16.5899 4.77581C16.6223 4.74585 16.6858 4.70084 16.7745 4.70084C16.7865 4.70084 16.7997 4.70203 16.8117 4.70328C16.8128 4.70328 16.8128 4.70328 16.8128 4.70328C20.6646 5.2638 23.7314 8.45962 24.2707 12.4731C24.2863 12.5855 24.2348 12.6667 24.2012 12.7054ZM21.6306 15.8201H14.4987C14.0037 15.8201 13.5999 15.4006 13.5999 14.8838V7.45481C13.5999 6.8119 13.3254 6.20885 12.8472 5.80063C12.3787 5.4024 11.7734 5.24136 11.1838 5.36495C8.44898 5.9367 5.99338 7.66566 4.4462 10.1062C2.88224 12.5743 2.3465 15.5604 2.93732 18.5128C3.77024 22.6761 7.01562 26.0567 11.0136 26.9256C11.7662 27.0891 12.5212 27.1703 13.2678 27.1703C15.3351 27.1703 17.3426 26.551 19.0839 25.3538C21.4268 23.7422 23.0866 21.1855 23.6355 18.3368C23.7542 17.7251 23.6032 17.0922 23.2185 16.6041C22.8266 16.106 22.2478 15.8201 21.6306 15.8201ZM18.094 23.7921C16.1274 25.1454 13.7412 25.6085 11.3815 25.0929C8.07265 24.3739 5.38692 21.5764 4.69782 18.1309C4.20526 15.6703 4.64867 13.1873 5.94658 11.1374C7.2313 9.11009 9.26869 7.67577 11.5373 7.20139C11.5517 7.19889 11.5637 7.19758 11.5757 7.19758C11.6332 7.19758 11.6775 7.22379 11.7063 7.25C11.7351 7.27372 11.801 7.34246 11.801 7.45481V14.8838C11.801 16.433 13.0114 17.6926 14.4974 17.6926H21.6294C21.7372 17.6926 21.8031 17.7612 21.8259 17.7912C21.8546 17.8287 21.887 17.8899 21.8714 17.9698C21.4172 20.3317 20.0402 22.4539 18.094 23.7921Z'
      fill={color}
    />
  </svg>
)

export default AnalyseSpends
