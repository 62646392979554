import React, { useEffect, useState } from "react"

const ClipboardToast = ({ svg, text }) => {
  return (
    <div className='v3-clipboard-toast'>
      <div className='v3-clipboard-toast-content'>
        <div className='v3-clipboard-toast-icon'>{svg}</div>
        <div className='v3-clipboard-toast-text'>{text}</div>
      </div>
    </div>
  )
}

export default ClipboardToast
