import React from "react"

const ArrowDown = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.30781 3.93246L2.68281 9.55746C2.6247 9.61551 2.5786 9.68444 2.54715 9.76031C2.5157 9.83619 2.49951 9.91752 2.49951 9.99965C2.49951 10.0818 2.5157 10.1631 2.54715 10.239C2.5786 10.3149 2.6247 10.3838 2.68281 10.4418L8.30781 16.0668C8.42509 16.1841 8.58415 16.25 8.75 16.25C8.91585 16.25 9.07491 16.1841 9.19219 16.0668C9.30946 15.9496 9.37535 15.7905 9.37535 15.6247C9.37535 15.4588 9.30946 15.2997 9.19219 15.1825L4.63359 10.6247H16.875C17.0408 10.6247 17.1997 10.5588 17.3169 10.4416C17.4342 10.3244 17.5 10.1654 17.5 9.99965C17.5 9.83389 17.4342 9.67492 17.3169 9.55771C17.1997 9.4405 17.0408 9.37465 16.875 9.37465H4.63359L9.19219 4.81684C9.30946 4.69956 9.37535 4.5405 9.37535 4.37465C9.37535 4.2088 9.30946 4.04974 9.19219 3.93246C9.07491 3.81519 8.91585 3.7493 8.75 3.7493C8.58415 3.7493 8.42509 3.81519 8.30781 3.93246Z'
        fill='#0E0E0E'
      />
    </svg>
  )
}

export default ArrowDown
