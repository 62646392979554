import { SET_OTP_STATE } from "../ActionTypes"

const initialState = {
  onSuccess: null,
  onError: null,
  onCancel: null,
  reason: "",
}

const OtpReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OTP_STATE:
      return {
        ...state,
        onSuccess: action.payload.onSuccess,
        onError: action.payload.onError,
        onCancel: action.payload.onCancel,
        reason: action.payload.reason,
      }
    default:
      return state
  }
}

export default OtpReducer
