import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import OfferDetails_v2 from "./v2/OfferDetails_v2"

const OfferDetails = () => {
  const config = useSelector(state => state.config)
  return config?.version === PwaVersions.V2 ||
    config?.version === PwaVersions.V2_1 ? (
    <OfferDetails_v2 />
  ) : (
    <div></div>
  )
}

export default OfferDetails
