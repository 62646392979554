import React from "react"
import { useSelector } from "react-redux"
import { SwiperSlide, Swiper } from "swiper/react"
import { useNavigate } from "react-router-dom"
import BannerCvpCard from "./bannerCvpCard"
import { useTranslation } from "react-i18next"

const CvpOffer = ({ cvpOffers }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const handleViewAllClick = path => {
    navigate(path)
  }
  return (
    cvpOffers &&
    cvpOffers.length > 0 && (
      <div className='v3-offers-section-2'>
        <div className='v3-offers-section-header'>
          <div className='v3-offers-section-header-title'>
            {" "}
            {t("offers.dealsForYou")}
          </div>

          <div
            className='v3-offers-section-header-cta'
            onClick={() => handleViewAllClick("/CvpOffers")}
          >
            {t("offers.ViewAll")}
          </div>
        </div>
        <div className='offer-carouselProvider'>
          <Swiper
            spaceBetween={10}
            slidesPerView={2}
            breakpoints={{
              300: { slidesPerView: 2.5 },
              340: { slidesPerView: 2.8 },
              385: { slidesPerView: 3.1 },
              420: { slidesPerView: 3.4 },
            }}
            style={{ padding: "0px 15px", overflow: "visible" }}
          >
            {cvpOffers
              ?.slice(
                0,
                screen?.home?.homeMiscellaneous?.cvpOffersListLength || 3,
              )
              ?.map((benefit, index) => {
                return (
                  <SwiperSlide style={{ marginRight: "2px", width: "117px" }}>
                    <BannerCvpCard benefit={benefit} />
                  </SwiperSlide>
                )
              })}
          </Swiper>
        </div>
      </div>
    )
  )
}

export default CvpOffer
