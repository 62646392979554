import React from "react"
import { useTranslation } from "react-i18next"
import DefaultCompanyLogo from "../../../../assets/images/DefaultCompanyLogo.svg"
import Star from "../../../../assets/images/Star.svg"
import arrowRight from "../../../../assets/images/arrowRight.svg"
import CommonService from "../../../../services/CommonService"
import DividerV3 from "../../../core/Divider/v3/DividerV3"

const NewUserMultiwalletWidgetV3 = ({ session, user, config, theme }) => {
  const date = new Date()
  const { t } = useTranslation()
  const month = date.toLocaleString("en-US", { month: "long" })
  const year = date.getFullYear().toString().substring(2)
  const handleRedirectionClick = async () => {
    try {
      const response = await CommonService.multiwalletPwaRedirection()
      if (response.data?.success) {
        document.cookie =
          "sessionData=" +
          JSON.stringify({
            deviceToken: session.deviceToken,
            accountId: user.account?.id,
            firstName: user?.customer?.firstName,
            nativeAppUrl: session?.redirectUrl,
            tenantId: localStorage.getItem("tenantId"),
          }) +
          ";expires=1d;domain=.hyperface.co;path=/"
        const redirectionUrl = response.data.data.redirectionUrl
        if (redirectionUrl) {
          window.location.href = redirectionUrl
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='v3-home-benefits-widget'>
      <div className='v3-benefits-top-section'>
        <div className='v3-benifits-header'>
          <div className='v3-benifits-left-content'>
            <div className='v3-benifits-header-image'>
              {config?.jsonConfig?.multiWalletData?.companyInformation
                ?.companyLogo ? (
                <img
                  src={config?.companyInformation?.companyLogo}
                  width={"30px"}
                  height={"30px"}
                  alt='Company logo'
                />
              ) : (
                <img
                  src={DefaultCompanyLogo}
                  width={"30px"}
                  height={"30px"}
                  alt='Benifits image'
                />
              )}
            </div>
            <div className='v3-benifits-header-text'>
              <div className='v3-benifits-header-title'>
                {config?.jsonConfig?.multiWalletData?.companyInformation
                  ?.companyTitleText || "Flexi-Benefits"}
              </div>
              <div className='v3-benifits-header-org-name'>
                {config?.jsonConfig?.multiWalletData?.companyInformation
                  ?.companyName || "Cognizant"}
              </div>
            </div>
          </div>
          <div
            className='v3-benefits-right-chevron'
            onClick={handleRedirectionClick}
          >
            <img src={arrowRight} />
          </div>
        </div>
        <DividerV3 color={theme.v3.rawColors.primaryBase.color5} />
        <div className='v3-benifits-body'>
          <div className='v3-benefits-body-header'>
            <div className='v3-benefits-body-text-section'>
              <div
                className='v3-benefits-body-header-text'
                style={{
                  color:
                    config?.jsonConfig?.multiWalletData?.designConfig
                      ?.globalTextColor,
                }}
              >
                <span className='v3-benefits-body-header-icon'>
                  <img src={Star} alt='star' />
                </span>
                Welcome, {user?.customer?.firstName} !
              </div>
              <div
                className='v3-benefits-body-text'
                style={{
                  color:
                    config?.jsonConfig?.multiWalletData?.designConfig
                      ?.globalTextColor,
                }}
              >
                {t("NewUserMultiwalletWidgetV3.benefitsBodyTextProcessedDate", {
                  month: month,
                  year: year,
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewUserMultiwalletWidgetV3
