import { useEffect } from "react"
import React, { useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { clearCommonState } from "../../../../store/actions/Common"
import { setSetMpinState } from "../../../../store/actions/SetMpin"
import { setSpendGroup } from "../../../../store/actions/User"
import { ProgramTypes } from "../../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import { captureEvents } from "../../../../utils/functions"
import { Header } from "../../../core"
import CustomLoader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import AboutProgram from "../../../svg/aboutProgram"
import AnalyseSpends from "../../../svg/analyseSpends"
import BlockReplace from "../../../svg/blockReplace"
import CardControl from "../../../svg/cardControl"
import CustomerCare_v2 from "../../../svg/customerCare_v2"
import ManageEmi from "../../../svg/manageEmi"
import MpinReset from "../../../svg/mpinReset"
import ResetPin from "../../../svg/resetPin"
import ViewStatement from "../../../svg/viewStatement"
import { BlockOptions } from "../BlockCard/constants"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const Home_v2 = props => {
  const screen = useSelector(state => state.screen)
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const user = useSelector(state => state.user)
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const nativeToast = useToast()

  const manageCardContainerRef = useRef(null)

  const isPhysicalCardActivated = user?.cards[0]?.isPhysicalCardActivated
  const isVirtualCardActivated = user?.cards[0]?.isVirtualCardActivated
  const notPhysicalAndVirtual =
    !isVirtualCardActivated && !isPhysicalCardActivated

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    captureEvents({ eventName: EventName.MANAGE_CARD, metadata: {} })
    dispatch(clearCommonState())
    dispatch(
      setSpendGroup({
        spendGroup: null,
      }),
    )
    const element = manageCardContainerRef.current
    setTimeout(() => {
      element.classList.add("active")
    }, 3)
    return () => {
      element.classList.remove("active")
    }
  }, [])
  const onChangeMpinClick = async () => {
    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {
          toast(`Your ${mpinWord} has been changed successfully!`)
          // navigate back
          navigate("/ManageCard", { replace: true })
        },

        onSetMpinFailure: async () => {
          toast(`Failed to set ${mpinWord}`)
          navigate("/", { replace: true })
        },

        onSetMpinCancel: async () => {
          navigate("/", { replace: true })
        },
      }),
    )
    // navigate to set mpin screen
    navigate("/Auth/SetMpin", { replace: true, state: { isChangeMpin: true } })
  }

  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }
  const checkV2_1 = () => {
    return config?.version === PwaVersions.V2_1
  }
  const getBlockMsg = () => {
    if (replaceOrBlock() === BlockOptions.ONLY_REPLACE) return "Replace Card"
    else if (replaceOrBlock() === BlockOptions.ONLY_BLOCK) return "Block Card"
    else return "Block/Replace Card"
  }
  return (
    <div
      style={{
        padding: "0px",
        paddingBottom: "10px",
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
      ref={manageCardContainerRef}
      className='manageCard-container'
    >
      <Header
        text={checkV2_1() ? "Settings" : "Manage Card"}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div style={{ padding: "0px 20px" }}>
        <Link
          style={{ textDecoration: "None" }}
          to={
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.UNBLOCK_CARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session?.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.MANAGE_LIMIT_DISABLE,
            ) ||
            !props.isAccountActive ||
            !props.card ||
            props.card.isHotlisted ||
            notPhysicalAndVirtual
              ? "#"
              : "/ManageCard/CardSettings"
          }
        >
          <div
            className='manageCard-widget'
            onClick={() => {
              ;(session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
              ) ||
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.UNBLOCK_CARD_DISABLE,
                ) ||
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                ) ||
                session?.disabledFunctionsByBlockCode?.includes(
                  AU_BlockCodes.MANAGE_LIMIT_DISABLE,
                ) ||
                !props.isAccountActive ||
                !props.card ||
                props.card.isHotlisted ||
                notPhysicalAndVirtual) &&
              props?.user?.programType === ProgramTypes.PREPAID
                ? toast("Request a replacement card to \n enable this option")
                : console.log()
            }}
            style={{
              backgroundColor: theme.widgetBackgroundColor,
              opacity:
                session.disabledFunctionsByBlockCode.includes(
                  Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
                ) ||
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.UNBLOCK_CARD_DISABLE,
                ) ||
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                ) ||
                session?.disabledFunctionsByBlockCode?.includes(
                  AU_BlockCodes.MANAGE_LIMIT_DISABLE,
                ) ||
                !props.isAccountActive ||
                !props.card ||
                props.card.isHotlisted ||
                notPhysicalAndVirtual
                  ? "0.6"
                  : "1",
            }}
          >
            <div className='manageCard-widget-icon'>
              <CardControl />
            </div>
            <div
              className='manageCard-widget-text'
              style={{
                fontFamily: props.theme.fontFamily,
                color: theme.appTextColor,
              }}
            >
              {"Card Controls"}
            </div>
          </div>
        </Link>
        {props.user.programType !== props.ProgramTypes.PREPAID ? (
          props.isAccountActive &&
          props.user.enableManageEMI && (
            <Link
              style={{ textDecoration: "None" }}
              to={
                session.disabledFunctionsByBlockCode.includes(
                  AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                ) ? (
                  <></>
                ) : (
                  "/ManageCard/ListEMI"
                )
              }
            >
              <div
                className='manageCard-widget'
                style={{
                  backgroundColor: theme.widgetBackgroundColor,
                  opacity: session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                  )
                    ? "0.6"
                    : "1",
                }}
              >
                <div className='manageCard-widget-icon'>
                  <ManageEmi />
                </div>
                <div
                  className='manageCard-widget-text'
                  style={{
                    fontFamily: props.theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  My Active EMIs
                </div>
              </div>
            </Link>
          )
        ) : (
          <div
            onClick={() => {
              window.location.href = props.session.repaymentUrl
            }}
          >
            <div
              className='manageCard-widget'
              style={{
                backgroundColor: theme.widgetBackgroundColor,
              }}
            >
              <div className='manageCard-widget-icon'>
                <ManageEmi />
              </div>
              <div
                className='manageCard-widget-text'
                style={{
                  fontFamily: props.theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                Manage Balance
              </div>
            </div>
          </div>
        )}
        <div
          className='manageCard-widget'
          style={{
            backgroundColor: theme.widgetBackgroundColor,
            opacity:
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.CC_PIN_DISABLE,
              ) ||
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.CARD_DASHBOARD_DISABLE,
              ) ||
              session?.disabledFunctionsByBlockCode?.includes(
                AU_BlockCodes.RESET_PIN_DISABLE,
              ) ||
              !props.isAccountActive ||
              !props.card ||
              props.card.isHotlisted ||
              props.card.isLocked ||
              (props.user.programType === ProgramTypes.PREPAID
                ? props.card?.cardType === "Virtual"
                  ? props.card?.isVirtualCardActivated
                  : !props.card?.isPhysicalCardActivated
                : !props.card?.isPhysicalCardActivated)
                ? "0.6"
                : "1",
          }}
          onClick={() =>
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.CC_PIN_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session?.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.RESET_PIN_DISABLE,
            ) ? (
              <></>
            ) : !props.isAccountActive ||
              !props.card ||
              props.card.isHotlisted ||
              props.card.isLocked ||
              (props.user.programType === ProgramTypes.PREPAID
                ? props.card?.cardType === "Virtual"
                  ? props.card?.isVirtualCardActivated
                  : !props.card?.isPhysicalCardActivated
                : !props.card?.isPhysicalCardActivated) ? (
              config?.version !== PwaVersions.V2_1 && props.alertToast()
            ) : (
              props.handleResetPinClick()
            )
          }
        >
          <div className='manageCard-widget-icon'>
            {props.isResetPinLoading ? (
              <div
                style={{
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: `${
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.backgroundColor
                      : "white"
                  }`,
                }}
              >
                <CustomLoader
                  color={props.theme.color1}
                  width={40}
                  height={40}
                />
              </div>
            ) : (
              <ResetPin />
            )}
          </div>
          <div
            className='manageCard-widget-text'
            style={{
              fontFamily: props.theme.fontFamily,
              color: theme.appTextColor,
            }}
          >
            {"Reset Card PIN"}
          </div>
        </div>
        <Link
          style={{
            textDecoration: "None",
            opacity:
              replaceOrBlock() === BlockOptions.GRAY_OUT ||
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.CARD_DASHBOARD_DISABLE,
              ) ||
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.REPLACE_CARD_DISABLE,
              )
                ? "0.6"
                : !props.isAccountActive || !props.card
                  ? "0.6"
                  : "1",
          }}
          to={
            replaceOrBlock() === BlockOptions.GRAY_OUT ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode.includes(
              Federal_BlockCodes.REPLACE_CARD_DISABLE,
            ) ||
            !props.isAccountActive ||
            !props.card
              ? ""
              : (!props.card.isHotlisted ||
                    replaceOrBlock() === BlockOptions.ONLY_BLOCK) &&
                  replaceOrBlock() !== BlockOptions.ONLY_REPLACE
                ? "/ManageCard/BlockCard"
                : "/ManageCard/NewCardRequest"
          }
        >
          <div
            className='manageCard-widget'
            style={{
              backgroundColor: theme.widgetBackgroundColor,
            }}
          >
            <div className='manageCard-widget-icon'>
              <BlockReplace />
            </div>
            <div
              className='manageCard-widget-text'
              style={{
                fontFamily: props.theme.fontFamily,
                color: theme.appTextColor,
              }}
            >
              {getBlockMsg()}
            </div>
          </div>
        </Link>

        {config?.version === PwaVersions.v2
          ? props.user.programType !== props.ProgramTypes.PREPAID &&
            theme?.transactionViewType !== "UNBILLED" && (
              <Link
                style={{
                  textDecoration: "None",
                }}
                to={
                  session.disabledFunctionsByBlockCode.includes(
                    Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.VIEW_STATEMENT_DISABLE,
                  ) ||
                  !props.isAccountActive ||
                  !props.card
                    ? "#"
                    : "/Statement"
                }
                bgColor={theme.widgetBackgroundColor}
              >
                <div
                  className='manageCard-widget'
                  style={{
                    backgroundColor: theme.widgetBackgroundColor,
                    opacity:
                      session.disabledFunctionsByBlockCode.includes(
                        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
                      ) ||
                      session.disabledFunctionsByBlockCode.includes(
                        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                      ) ||
                      session.disabledFunctionsByBlockCode.includes(
                        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
                      ) ||
                      !props.isAccountActive ||
                      !props.card
                        ? "0.6"
                        : "1",
                  }}
                >
                  <div className='manageCard-widget-icon'>
                    <ViewStatement />
                  </div>
                  <div
                    className='manageCard-widget-text'
                    style={{
                      fontFamily: props.theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    View Statement
                  </div>
                </div>
              </Link>
            )
          : props.user.programType !== props.ProgramTypes.PREPAID && (
              <Link
                style={{
                  textDecoration: "None",
                }}
                to={
                  session.disabledFunctionsByBlockCode.includes(
                    Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                  ) ||
                  session.disabledFunctionsByBlockCode.includes(
                    AU_BlockCodes.VIEW_STATEMENT_DISABLE,
                  ) ||
                  !props.isAccountActive ||
                  !props.card
                    ? "#"
                    : "/Statement"
                }
                bgColor={theme.widgetBackgroundColor}
              >
                <div
                  className='manageCard-widget'
                  style={{
                    backgroundColor: theme.widgetBackgroundColor,
                    opacity:
                      session.disabledFunctionsByBlockCode.includes(
                        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
                      ) ||
                      session.disabledFunctionsByBlockCode.includes(
                        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
                      ) ||
                      session.disabledFunctionsByBlockCode.includes(
                        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
                      ) ||
                      !props.isAccountActive ||
                      !props.card
                        ? "0.6"
                        : "1",
                  }}
                >
                  <div className='manageCard-widget-icon'>
                    <ViewStatement />
                  </div>
                  <div
                    className='manageCard-widget-text'
                    style={{
                      fontFamily: props.theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    View Statement
                  </div>
                </div>
              </Link>
            )}

        {screen?.analyzeSpends?.isEnabled && (
          <Link
            style={{
              textDecoration: "None",
            }}
            to={
              session.disabledFunctionsByBlockCode.includes(
                AU_BlockCodes.CARD_DASHBOARD_DISABLE,
              ) ? (
                <></>
              ) : (
                "/ManageCard/AnalyseSpend"
              )
            }
          >
            <div
              className='manageCard-widget'
              style={{
                backgroundColor: theme.widgetBackgroundColor,
              }}
            >
              <div className='manageCard-widget-icon'>
                <AnalyseSpends />
              </div>
              <div
                className='manageCard-widget-text'
                style={{
                  fontFamily: props.theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                Analyse Spends
              </div>
            </div>
          </Link>
        )}

        {screen?.mpin?.resetMpin?.isEnabled && (
          <div
            className='manageCard-widget'
            style={{
              backgroundColor: theme.widgetBackgroundColor,
              opacity: session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.RESET_MPIN_DISABLE,
              )
                ? "0.6"
                : "1",
            }}
            onClick={
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.RESET_MPIN_DISABLE,
              )
                ? () => {}
                : onChangeMpinClick
            }
          >
            <div className='manageCard-widget-icon'>
              <MpinReset />
            </div>
            <div
              className='manageCard-widget-text'
              style={{
                fontFamily: props.theme.fontFamily,
                color: theme.appTextColor,
              }}
            >
              {config?.version === PwaVersions.V2_1
                ? `Setup ${mpinWord}`
                : `Reset ${mpinWord}`}
            </div>
          </div>
        )}
        {screen?.aboutProgram?.isEnabled && (
          <Link
            style={{
              textDecoration: "None",
            }}
            to={"/ManageCard/AboutProgram"}
          >
            <div
              className='manageCard-widget'
              style={{
                backgroundColor: theme.widgetBackgroundColor,
              }}
            >
              <div className='manageCard-widget-icon'>
                <AboutProgram />
              </div>
              <div
                className='manageCard-widget-text'
                style={{
                  fontFamily: props.theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                About Card Program
              </div>
            </div>
          </Link>
        )}
        {screen?.customerCare?.isEnabled && (
          <Link
            style={{
              textDecoration: "None",
            }}
            to={"/ManageCard/CustomerCare"}
          >
            <div
              className='manageCard-widget'
              style={{
                backgroundColor: theme.widgetBackgroundColor,
              }}
            >
              <div className='manageCard-widget-icon'>
                <CustomerCare_v2 />
              </div>
              <div
                className='manageCard-widget-text'
                style={{
                  fontFamily: props.theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                Customer Care
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  )
}

export default Home_v2
