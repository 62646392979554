import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { ColorButton } from "../../../../core/buttons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { EventName, PwaVersions } from "../../../../../utils/enums"
import { captureEvents } from "../../../../../utils/functions"
import { Header } from "../../../../core"
import { ScrollView } from "../../../../../nativeBaseReplacements"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"

const NewCardSuccess = ({ successImageUrl, onSubmit }) => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()

  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)

  useEffect(() => {
    captureEvents({
      eventName: EventName.NEW_CARD_REQUEST_SUCCESS,
      metadata: {},
    })
  }, [])

  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1

  const navigate = useNavigate()

  return (
    <div
      className='mpinSuccess'
      style={{
        minHeight:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? windowDimensions.height
            : null,
        backgroundColor:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? theme.backgroundColor
            : null,
      }}
    >
      <ScrollView w='100%'>
        {showHeader ? (
          <Header
            text={"Block/Replace Card"}
            onBack={async () => {
              navigate("/ManageCard")
            }}
          />
        ) : (
          <div className='mpinSuccess-noHeader'></div>
        )}
        <div
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : null,
          }}
        >
          <div className='mpinSuccess-content-container'>
            {successImageUrl ? (
              <img className='mpinSuccess-content-img' src={successImageUrl} />
            ) : (
              <div
                className='mpinSuccess-content-icon-container'
                style={{ backgroundColor: theme.widgetBackgroundColor }}
              >
                <svg
                  width='96'
                  height='96'
                  viewBox='0 0 96 96'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M94.0796 21.5998H1.92051C0.858994 21.5998 0.000803563 20.7408 0.000803563 19.6801L0 1.91971C0 0.85819 0.859013 0 1.91971 0H94.0787C95.1403 0 95.9984 0.859013 95.9984 1.91971V19.6792C95.9992 20.7408 95.141 21.5998 94.0796 21.5998V21.5998ZM3.8398 17.7595H92.1582V3.83941H3.8398V17.7595Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M94.0795 95.9971H1.92044C0.858923 95.9971 0.000732422 95.1381 0.000732422 94.0774V19.6775C0.000732422 18.616 0.859745 17.7578 1.92044 17.7578H94.0795C95.141 17.7578 95.9992 18.6168 95.9992 19.6775L96 94.0774C96 95.1389 95.141 95.9971 94.0795 95.9971ZM3.84179 92.1577H92.1602V21.5984H3.84179V92.1577Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M83.8988 12.7202H27.7393C26.6778 12.7202 25.8196 11.8612 25.8196 10.8005C25.8196 9.73897 26.6787 8.88078 27.7393 8.88078H83.8988C84.9603 8.88078 85.8185 9.73979 85.8185 10.8005C85.8185 11.8612 84.9603 12.7202 83.8988 12.7202Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M12.0888 12.7087C11.5898 12.7087 11.0892 12.5158 10.7445 12.1518C10.5532 11.979 10.4182 11.7677 10.3226 11.5371C10.227 11.3065 10.1691 11.0574 10.1691 10.8074C10.1691 10.2899 10.3813 9.78933 10.7445 9.44459C11.4548 8.73425 12.7413 8.73425 13.4517 9.44459C13.8173 9.78933 14.027 10.2899 14.027 10.8074C14.027 11.0565 13.9692 11.3065 13.8735 11.5371C13.7779 11.7677 13.6429 11.9798 13.4517 12.1518C13.086 12.5158 12.6063 12.7087 12.0888 12.7087H12.0888Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M19.7703 12.7087C19.2713 12.7087 18.7707 12.5159 18.426 12.1519C18.2347 11.9791 18.0997 11.7678 18.0041 11.5371C17.9085 11.3065 17.8691 11.0574 17.8691 10.8075C17.8691 10.5391 17.9085 10.29 18.0041 10.0594C18.0997 9.82876 18.2347 9.61663 18.426 9.44466C18.5031 9.34903 18.5987 9.27189 18.7144 9.19556C18.81 9.1377 18.9241 9.07904 19.0407 9.02279C19.1548 8.98342 19.2898 8.94565 19.4047 8.90628C19.6562 8.86851 19.9053 8.86851 20.1544 8.90628C20.2685 8.94565 20.4035 8.98342 20.5184 9.02279C20.6349 9.07904 20.749 9.1369 20.8446 9.19556C20.9419 9.2727 21.0568 9.34904 21.1331 9.44466C21.4987 9.78939 21.7085 10.29 21.7085 10.8075C21.7085 11.3065 21.4987 11.7862 21.1331 12.1519C20.7683 12.5159 20.2877 12.7087 19.7703 12.7087H19.7703Z'
                    fill={theme.color1}
                  />
                  <path
                    fill-rule='evenodd'
                    clip-rule='evenodd'
                    d='M67.8147 48.4752C68.5948 47.6326 69.0195 46.5181 68.9993 45.3666C68.9791 44.215 68.5156 43.1163 67.7064 42.3019C66.8973 41.4875 65.8056 41.021 64.6614 41.0007C63.5173 40.9804 62.4099 41.4078 61.5727 42.193L42.6114 61.2767L35.4273 54.0462C34.5901 53.2611 33.4827 52.8336 32.3386 52.8539C31.1944 52.8743 30.1027 53.3408 29.2936 54.1552C28.4844 54.9695 28.0209 56.0683 28.0007 57.2198C27.9805 58.3714 28.4052 59.4858 29.1853 60.3285L39.4904 70.7C40.3185 71.5324 41.441 72 42.6114 72C43.7817 72 44.9043 71.5324 45.7323 70.7L67.8147 48.4752Z'
                    fill={theme.color1}
                    stroke='#F8F8F8'
                    stroke-width='5'
                  />
                </svg>
              </div>
            )}
            <div className='mpinSuccess-content-text'>
              <div
                className='mpinSuccess-content-text-1'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                {t("activation.activateCardSuccess")}
              </div>
              <div
                className='mpinSuccess-content-text-2'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                {t("activation.virtualCardActivated")}
              </div>
            </div>
          </div>
          <div className='mpinSuccess-cta-container'>
            <div className='mpinSuccess-cta'>
              <ColorButton
                text='Return to Home Screen'
                onPress={() => {
                  captureEvents({
                    eventName: EventName.REPLACE_CARD_SUCCESS_CTA,
                    metadata: { cta: "return to home screen" },
                  })
                  navigate("/")
                }}
              />
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  )
}

export default NewCardSuccess
