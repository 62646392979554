const CaretRight = ({ color = "#1965CF" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
    >
      <path
        d='M11.354 8.85372L6.35403 13.8537C6.30757 13.9002 6.25242 13.937 6.19173 13.9622C6.13103 13.9873 6.06598 14.0003 6.00028 14.0003C5.93458 14.0003 5.86953 13.9873 5.80883 13.9622C5.74813 13.937 5.69298 13.9002 5.64653 13.8537C5.60007 13.8073 5.56322 13.7521 5.53808 13.6914C5.51294 13.6307 5.5 13.5657 5.5 13.5C5.5 13.4343 5.51294 13.3692 5.53808 13.3085C5.56322 13.2478 5.60007 13.1927 5.64653 13.1462L10.2934 8.49997L5.64653 3.85372C5.55271 3.7599 5.5 3.63265 5.5 3.49997C5.5 3.36729 5.55271 3.24004 5.64653 3.14622C5.74035 3.0524 5.8676 2.99969 6.00028 2.99969C6.13296 2.99969 6.26021 3.0524 6.35403 3.14622L11.354 8.14622C11.4005 8.19266 11.4374 8.2478 11.4626 8.3085C11.4877 8.3692 11.5007 8.43427 11.5007 8.49997C11.5007 8.56568 11.4877 8.63074 11.4626 8.69144C11.4374 8.75214 11.4005 8.80729 11.354 8.85372Z'
        fill={color}
      />
    </svg>
  )
}

export default CaretRight
