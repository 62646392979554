import React from "react"
import { useSelector } from "react-redux"
import { DisplayConfig } from "../../../../utils/constants"
import styles from "./styles"
import View from "../../../../nativeBaseReplacements/View"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const IFrame = () => {
  const windowDimensions = useWindowDimensions()

  const session = useSelector(state => state.session)

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View {...styles.mainView}>
          {/* <View {...styles.header}>
            <CloseIcon
              {...styles.closeIcon}
              style={{ float: "right" }}
              onClick={() => window.location.href = session.redirectUrl}
            />
          </View> */}
          <View {...styles.widget}>
            <iframe
              title='Onboarding IFrame'
              height={windowDimensions.height - 55}
              allow='clipboard-write'
              src={session.onboardingUrl}
            />
          </View>
        </View>
      </View>
    </View>
  )
}

export default IFrame
