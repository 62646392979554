import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import cardImgHorizontal from "../../../../../assets/images/Card_Illustration_horizontal.png"
import cardImgVertical from "../../../../../assets/images/Card_Illustration_vertical.png"
import { toast } from "../../../../../core/Toast"
import { CardOrientation, DisplayConfig } from "../../../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../../utils/enums"
import { Header } from "../../../../core"
import { ColorButton, WhiteButton } from "../../../../core/buttons"

import { captureEvents } from "../../../../../utils/functions"
import { BlockOptions } from "../constants"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"

const BlockCard_v2 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)

  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }

  useEffect(() => {
    captureEvents({ eventName: EventName.BLOCK_CARD, metadata: {} })
  }, [])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      replaceOrBlock() === BlockOptions.ONLY_REPLACE ||
      replaceOrBlock() === BlockOptions.GRAY_OUT
    ) {
      toast(t("manageCard.accessBlockedText"))
      navigate("/")
      return false
    }

    if (
      replaceOrBlock() === BlockOptions.ONLY_BLOCK &&
      user.cards[0].isHotlisted
    ) {
      toast(t("manageCard.cardBlockedText"))
      navigate("/")
      return
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
    ) {
      toast(t("manageCard.accessBlockedToast"))
      navigate("/")
      return
    }

    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards])

  const proceedBlockCard = () => {
    captureEvents({ eventName: EventName.BLOCK_REPLACE_CTA, metadata: {} })
    navigate("/ManageCard/BlockCard/Reason")
  }
  const cancel = () => {
    navigate("/ManageCard")
  }

  return (
    <div className='blockCard' style={{ maxWidth: DisplayConfig.MAX_WIDTH }}>
      <div
        style={{
          minHeight: windowDim.height,
          backgroundColor: theme.backgroundColor,
        }}
      >
        {/* body content start */}

        <Header
          text='Block/Replace Card'
          onBack={async () => {
            window.history.go(-1)
          }}
        />

        {/* body title end */}

        {/* content box start */}
        <div
          className='blockCard-content'
          style={{ backgroundColor: theme.widgetBackgroundColor }}
        >
          {theme.cardOrientation === CardOrientation.HORIZONTAL ? (
            <img
              className='blockCard-content-img-horizontal'
              src={
                config?.version === PwaVersions.V2_1
                  ? theme.cardDesignUrl
                  : cardImgHorizontal
              }
              alt='Alternate Text '
            />
          ) : (
            <img
              className='blockCard-content-img-vertical'
              src={
                config?.version === PwaVersions.V2_1
                  ? theme.cardDesignUrl
                  : cardImgVertical
              }
              alt='Alternate Text '
            />
          )}
          {/* content box Image End */}
          <div
            className='blockCard-content-text'
            style={{
              fontFamily: theme.fontFamily,
              color: `${theme.appTextColor}90`,
            }}
          >
            {t("manageCard.preventFutureTrxnsText")}
          </div>
          <div
            className='blockCard-content-text'
            style={{
              fontFamily: theme.fontFamily,
              color: `${theme.appTextColor}90`,
            }}
          >
            {t("manageCard.requestNewCardText")}
          </div>
        </div>
        {/* content box Text end */}

        {/* content box end */}

        {/* body content end */}

        {/*    CTA Section */}
        <div
          style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
          className='blockCard-ctaSection'
        >
          <ColorButton
            text={
              config?.version === PwaVersions.V2_1
                ? t("manageCard.proceed")
                : t("manageCard.proceedBlockingText")
            }
            isDisable={false}
            onPress={proceedBlockCard}
          />
          {!(
            config?.version == PwaVersions.V2 ||
            config?.version == PwaVersions.V2_1
          ) ? (
            <WhiteButton text='Cancel' onPress={cancel} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default BlockCard_v2
