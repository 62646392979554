const styles = {
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  mainView: {
    width: "100%",
    px: "12px",
    py: 10,
  },
  containerHeader: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#303030",
  },
  cardCotnrolText: {
    width: "100%",
    fontSize: 18,
    fontFamily: "Inter",
    fontWeight: 500,
    textAlign: "center",
  },
  cardStatus: {
    backgroundColor: "#FFF",
    px: 5,
    py: 4,
    borderRadius: "6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 3,
  },
  cardStatusText: {
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#303030",
  },
  cardControlSwitch: {
    size: "sm",
    onThumbColor: "whitesmoke",
    offTrackColor: "grey",
    onTrackColor: "#00D39B",
  },
  onlineTransaction: {
    py: "8px",
    my: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  offlineTransaction: {
    px: 5,
    py: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  magneticStripe: {
    px: 5,
    py: 4,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  imageStyle: {
    resizeMode: "stretch",
    width: "95%",
    height: 220,
    marginTop: 15,
    marginLeft: "2.5%",
  },
  contactless: {
    backgroundColor: "#FFF",
    px: 5,
    pt: 4,
    pb: 5,
    borderRadius: 7,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  commonStyles: {
    borderWidth: 0,
    width: "100%",
  },
  inputField: {
    height: "38px",
    textAlign: "left",
    borderColor: "#e3e3e3",
    backgroundColor: "#fff",
  },
  overallCardSettingContainer: {
    backgroundColor: "#FFF",
    padding: "12px",
    borderRadius: "6px",
  },
  cardSettingContainer: {
    mb: "12px",
    borderRadius: "6px",
    px: "16px",
    pt: "12px",
    pb: "18px",
    // boxShadow: "0px 0px 2px 0px #000000",
  },
  limitText: {
    ml: "12px",
    width: "65%",
    fontSize: "12px",
    fontWeight: "500",
    color: "#7B7B7B",
    letterSpacing: "0.02em",
  },
  limitContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  button: {
    pt: "8px",
    alignItems: "center",
    justifyContent: "center",
    mt: "24px",
    width: "100%",
  },
  saveChangesButton: {
    mb: 3,
    backgroundColor: "#6180FF",
    _text: {
      fontFamily: "Inter",
      fontWeight: "500",
    },
  },
  cancelButton: {
    backgroundColor: "#FFF",
    borderWidth: 1,
    borderColor: "#6180FF",
    _text: {
      color: "#6180FF",
      fontFamily: "Inter",
      fontWeight: "500",
    },
  },
  sovereigntyHeading: {
    my: "8px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  expand: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },

  domIntlSelected: {
    borderBottomWidth: "3px",
  },
  notesStrip: {
    position: "absolute",
    top: 0,
    right: "17px",
    borderBottomLeftRadius: "7px",
    borderBottomRightRadius: "7px",
  },
}

export default styles
