import { TextWrapperSkeleton } from "./styledComponents"

const TransactionLoader = () => {
  return (
    <div className='v3-transaction-skeleton-loader'>
      <TextWrapperSkeleton width='62px' height='62px' br='100%' />
      <TextWrapperSkeleton
        margin='5px auto'
        height='62px'
        br='18px'
        flexGrow={2}
      />
    </div>
  )
}

const TransactionListLoader = ({ transactionListLength = 3 }) => {
  const items = Array.from({ length: transactionListLength }, index => (
    <TransactionLoader key={index} />
  ))
  return <>{items}</>
}

export default TransactionListLoader
