import React from "react"
import { formatAmount } from "../../../utils/functions"
import ArrowRightSmall from "../../svg/v3/arrowRightSmall"
import carrotImage from "../../../assets/images/v3/carrotImage.svg"
import rewardIcon from "../../../assets/images/v3/rewardPointIcon.svg"
import { useTranslation } from "react-i18next"

const SingleCategoryCard = ({ spendGroups }) => {
  const { t } = useTranslation()
  const merchants = spendGroups?.data?.spendGroups[0]?.merchantList
  const spendGroupData = spendGroups?.data?.spendGroups[0]
  return (
    <div className='v3-spend-analytics-category-split-chart-2-single-category'>
      <div className='v3-spend-analytics-category-split-chart-2-single-category-header'>
        <div className='v3-spend-analytics-category-split-chart-2-single-category-header-text'>
          {t("spendAnalytics.singleCategoryCard.totalSpends")}
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-single-category-header-value'>
          {formatAmount(spendGroupData?.totalSpend)}
        </div>
      </div>
      <div className='v3-spend-analytics-category-split-chart-2-single-category-body'>
        <div className='v3-spend-analytics-category-split-chart-2-single-category-body-left'>
          <div className='v3-spend-analytics-category-split-chart-2-single-category-body-merchant-header'>
            {t("spendAnalytics.singleCategoryCard.spentOnText")}
          </div>
          <div className='v3-spend-analytics-category-split-chart-2-single-category-body-merchant-name'>
            {spendGroupData?.groupName}
          </div>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-txn-redirection'>
            {spendGroupData?.countOfTxns}{" "}
            {t("spendAnalytics.singleCategoryCard.transactionsCount")}{" "}
            <span>
              <ArrowRightSmall />
            </span>
          </div>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants'>
            <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-logos'>
              {merchants?.map((item, index) => {
                const customClass = index === 0 ? "first" : "second"
                return (
                  <img
                    src={`data:image/png;base64, ${item.merchantLogo}`}
                    className={`v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-${customClass}-logo`}
                    alt='merchant logo'
                    width={19}
                    height={19}
                    style={{ borderRadius: "100%" }}
                  />
                )
              })}
            </div>
            <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-count'>
              {spendGroupData?.numberOfMerchants}{" "}
              {t("spendAnalytics.singleCategoryCard.merchantsCount")}
            </div>
          </div>
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-single-category-body-right'>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-right'>
            <img
              src={carrotImage}
              width={"133"}
              height={"130"}
              alt='categcoory icon'
            />
          </div>
        </div>
      </div>
      <div className='v3-spend-analytics-category-split-chart-2-single-category-footer'>
        <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-text'>
          {t("spendAnalytics.singleCategoryCard.youHaveEarned")}{" "}
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-count'>
          <img
            src={rewardIcon}
            style={{ paddingBottom: "2px" }}
            alt='reward icon'
            width={12}
            height={12}
          />{" "}
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-count-value'>
            {spendGroupData?.rewardPoints}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SingleCategoryCard
