const styles = {
  mainView: {
    backgroundColor: "#FFFFFF",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },
  precloseBody: {
    px: "16px",
    py: "20px",
    borderBottomWidth: "1px",
    borderBottomColor: "#ECECEC",
  },
  loader: { w: "100%", alignItems: "center", justifyContent: "center" },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    p: "12px",
    borderBottomWidth: "1px",
    borderBottomColor: "#ECECEC",
  },
  toggleHeaderText: {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: "500",
    lineHeight: "20px",
  },
  toggleHeader: {
    width: "46%",
    mx: "2%",
    p: "16px",
    alignItems: "center",
    justifyContent: "center",
  },
  manageCard: {
    width: "100%",
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: "500",
    lineHeight: "24px",
    ml: "16px",
  },
  precloseBodyText: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "20px",
  },
  precloselargeText: {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: "500",
    lineHeight: "24px",
  },
  precloseDescriptionText: {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: "16px",
  },
  precloseBodyContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    my: "20px",
    flexDirection: "row",
  },
  precloseDropContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    my: "12px",
    flexDirection: "row",
  },
  precloseDropdownContainer: {
    p: "16px",
    alignItems: "center",
    my: "12px",
    bgColor: "#F8F8F8",
    borderRadius: "8px",
  },
  CTAsection: {
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    bottom: "16px",
  },
  paid: {
    px: "2px",
    py: "1px",
    borderColor: "#00A271",
    borderWidth: "1px",
    w: "30%",
    alignItems: "center",
    mt: "1px",
    borderRadius: "2px",
  },
}

export default styles
