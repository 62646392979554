import React from "react"
import { useSelector } from "react-redux"
import CloseButton from "../svg/v3/closeButton"
import { ColorButton } from "../core/buttons"
import CheckIcon from "../svg/v3/checkIcon"
import { useTranslation } from "react-i18next"

const CategoryFilterBottomSheet = ({
  handleCategoryFilterClick,
  spendGroups,
  handleSelectCategory,
  group,
  handleCategoryCta,
  configuredGroups,
}) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const noneCategory = {
    groupName: "None",
  }

  return (
    <div className='v3-spend-analytics-category-bottomsheet-container'>
      <div className='v3-spend-analytics-category-bottomsheet-header'>
        <div className='v3-spend-analytics-category-bottomsheet-header-top'>
          <div className='v3-spend-analytics-category-bottomsheet-primary-header'>
            {t("spendAnalytics.categoryBottomSheet.categoryTrend")}
          </div>
          <div onClick={handleCategoryFilterClick}>
            <CloseButton
              color={theme.v3.rawColors.tertiaryNeutral.color1}
              background={theme.v3.rawColors.tertiaryNeutral.color3}
            />
          </div>
        </div>
        <div className='v3-spend-analytics-category-bottomsheet-secondary-header'>
          {t("spendAnalytics.categoryBottomSheet.selectCategoryText")}
        </div>
      </div>
      <div className='v3-spend-analytics-category-bottomsheet-body-container'>
        <div
          className='v3-spend-analytics-category-bottomsheet-filter'
          style={{
            borderRadius: "8px",
            backgroundColor:
              group?.groupName === noneCategory.groupName &&
              theme.v3.cssVars.secondary.color4,
          }}
          onClick={() => handleSelectCategory(noneCategory)}
        >
          <div
            style={{
              color:
                group?.groupName === noneCategory.groupName &&
                theme.v3.cssVars.secondary.color1,
            }}
          >
            None
          </div>
          {group?.groupName === noneCategory.groupName && (
            <div className=''>
              <CheckIcon
                width={24}
                height={24}
                color={theme.v3.rawColors.secondary.color1}
              />
            </div>
          )}
        </div>
        {spendGroups?.data?.spendGroups?.map((item, index) => {
          const spendGroupImage = configuredGroups?.find(
            data => data?.groupingField === item?.groupId,
          )?.iconUrl

          return (
            <>
              <div
                className='v3-spend-analytics-category-bottomsheet-filter'
                style={{
                  borderRadius: "8px",
                  backgroundColor:
                    group?.groupName === item?.groupName &&
                    theme.v3.cssVars.secondary.color4,
                }}
                onClick={() => handleSelectCategory(item)}
              >
                <div
                  style={{
                    color:
                      group?.groupName === item?.groupName &&
                      theme.v3.cssVars.secondary.color1,
                  }}
                >
                  <span>
                    <img src={spendGroupImage} width={11} height={11} />
                  </span>{" "}
                  {item?.groupName}
                </div>
                {group?.groupName === item?.groupName && (
                  <div className=''>
                    <CheckIcon
                      width={24}
                      height={24}
                      color={theme.v3.rawColors.secondary.color1}
                    />
                  </div>
                )}
              </div>
            </>
          )
        })}
      </div>
      <div className='v3-spend-analytics-category-bottomsheet-cta-container'>
        <ColorButton
          text={t("spendAnalytics.bottomSheet.confirmText")}
          width={"100%"}
          onPress={handleCategoryCta}
        />
      </div>
    </div>
  )
}

export default CategoryFilterBottomSheet
