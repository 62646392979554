const Cycles = ({ width, height, color }) => {
  return (
    <svg
      width={width || "25"}
      height={height || "24"}
      viewBox='0 0 25 24'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.75 4V9C22.75 9.552 22.303 10 21.75 10H16.75C16.197 10 15.75 9.552 15.75 9C15.75 8.448 16.197 8 16.75 8H19.6599C18.2529 5.565 15.637 4 12.75 4C8.661 4 5.24496 7.05591 4.80396 11.1079C4.74796 11.6199 4.31504 12 3.81104 12C3.77504 12 3.7389 11.9979 3.7019 11.9939C3.1529 11.9339 2.75692 11.4411 2.81592 10.8911C3.36892 5.82211 7.63898 1.99902 12.751 1.99902C15.954 1.99902 18.898 3.53408 20.751 6.00708V4C20.751 3.448 21.198 3 21.751 3C22.304 3 22.75 3.448 22.75 4ZM21.7991 12.0061C21.2381 11.9471 20.756 12.3431 20.696 12.8921C20.255 16.9451 16.839 20 12.75 20C9.863 20 7.24709 18.435 5.84009 16H8.75C9.303 16 9.75 15.552 9.75 15C9.75 14.448 9.303 14 8.75 14H3.75C3.197 14 2.75 14.448 2.75 15V20C2.75 20.552 3.197 21 3.75 21C4.303 21 4.75 20.552 4.75 20V17.9919C6.603 20.4649 9.547 22 12.75 22C17.861 22 22.1321 18.1769 22.6851 13.1079C22.7441 12.5589 22.3481 12.0661 21.7991 12.0061Z'
        fill={color}
      />
    </svg>
  )
}

export default Cycles
