const ArrowBack = ({ color = "#A8A8A8" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
    >
      <path
        d='M10.8759 6.75001C10.8759 8.19751 9.69841 9.37501 8.25091 9.37501H3.00091C2.79391 9.37501 2.62591 9.20701 2.62591 9.00001C2.62591 8.79301 2.79391 8.62501 3.00091 8.62501H8.25091C9.28441 8.62501 10.1259 7.78401 10.1259 6.75001C10.1259 5.71601 9.28441 4.87501 8.25091 4.87501H2.40643L3.26642 5.735C3.41292 5.8815 3.41292 6.11901 3.26642 6.26551C3.19342 6.33851 3.0974 6.3755 3.0014 6.3755C2.9054 6.3755 2.80939 6.33901 2.73639 6.26551L1.23639 4.76551C1.20189 4.73101 1.17445 4.68957 1.15545 4.64357C1.11745 4.55207 1.11745 4.44857 1.15545 4.35707C1.17445 4.31107 1.20189 4.2695 1.23639 4.235L2.73639 2.735C2.88289 2.5885 3.1204 2.5885 3.2669 2.735C3.4134 2.8815 3.4134 3.11901 3.2669 3.26551L2.40692 4.1255H8.2514C9.6984 4.125 10.8759 5.30251 10.8759 6.75001Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowBack
