import React from "react"

function CheckIcon({ color, width, height }) {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M8.9998 17.75C8.9988 17.75 8.99779 17.75 8.99679 17.75C8.79679 17.749 8.60579 17.669 8.4648 17.526L4.46479 13.464C4.17379 13.169 4.1778 12.694 4.4728 12.403C4.7678 12.113 5.2438 12.116 5.5338 12.411L9.0028 15.935L18.4688 6.46902C18.7618 6.17602 19.2368 6.17602 19.5298 6.46902C19.8228 6.76202 19.8228 7.23705 19.5298 7.53005L9.5298 17.5301C9.3898 17.6711 9.1988 17.75 8.9998 17.75Z'
          fill={color}
          stroke={color}
        />
      </svg>
    </div>
  )
}

export default CheckIcon
