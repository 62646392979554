import styled from "styled-components"
import View from "./View"

const Slider = props => {
  const sliderFraction =
    ((props.value ?? props.defaultValue ?? 50) - (props.minValue ?? 0)) /
    ((props.maxValue ?? 100) - (props.minValue ?? 0))

  return (
    <View style={{ width: "100%", minHeight: "16px" }}>
      <RangeInput
        {...props}
        sliderFraction={sliderFraction}
        min={props.minValue}
        max={props.maxValue}
        onChange={e => props.onChange?.(e.target.value)}
        type='range'
      />
    </View>
  )
}

export default Slider

const RangeInput = styled.input/* css */ `
  -webkit-appearance: none;
  cursor: pointer;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background: linear-gradient(
    to right,
    ${props => props.filledTrackColor} ${props => props.sliderFraction * 100}%,
    ${props => props.trackColor} ${props => props.sliderFraction * 100}%
  );

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 4px;
    border-radius: 4px;
    background: linear-gradient(
      to right,
      ${props => props.filledTrackColor} ${props => props.sliderFraction * 100}%,
      ${props => props.trackColor} ${props => props.sliderFraction * 100}%
    );
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    transform: translateY(-6px);
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${props => props.thumbColor || "transparent"} !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
  }

  &::-moz-range-track {
    height: 4px;
    border-radius: 4px;
    background-color: linear-gradient(
      to right,
      ${props => props.filledTrackColor} ${props => props.sliderFraction * 100}%,
      ${props => props.trackColor} ${props => props.sliderFraction * 100}%
    );
  }

  &::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${props => props.thumbColor || "transparent"} !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
  }
`
