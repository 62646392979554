import React from "react"

const ArrowDown = ({ color, size, className="" }) => {
  return (
    <div style={{ width: size }}>
      <svg
        className={className}
        style={{ fill: color }}
        viewBox='0 0 1000 1100'
        class='icon'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        stroke={color}
        stroke-width='80'
      >
        <path
          d='M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z'
          style={{ fill: color }}
          stroke={color}
          stroke-width='80'
        />
      </svg>
    </div>
  )
}
export default ArrowDown