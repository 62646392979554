import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import ExpiredImage from "../../assets/images/sessionExpired.svg"
import { EventName, PwaVersions } from "../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  isIosDevice,
  postMessageToIos,
} from "../../utils/functions"
import { Header } from "../core"
import { ColorButton } from "../core/buttons"
import styles from "./styles"

import { DisplayConfig } from "../../utils/constants"
import Text from "../../nativeBaseReplacements/Text"
import View from "../../nativeBaseReplacements/View"
import { useToast } from "../../nativeBaseReplacements/useToast"
import { Image, ScrollView } from "../../nativeBaseReplacements"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"

const SessionExpired = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()

  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)

  useEffect(() => {
    captureEvents({ eventName: EventName.SESSION_EXPIRED, metadata: {} })

    if (theme?.widgetBackgroundColor) {
      localStorage.setItem(
        "widgetBackgroundColor",
        theme?.widgetBackgroundColor,
      )
    }
    if (theme?.backgroundColor) {
      localStorage.setItem("backgroundColor", theme?.backgroundColor)
    }
    if (theme?.color1) {
      localStorage.setItem("color1", theme?.color1)
    }
    if (theme?.appTextColor) {
      localStorage.setItem("appTextColor", theme?.appTextColor)
    }
    if (theme?.fontFamily) {
      localStorage.setItem("fontFamily", theme?.fontFamily)
    }
  }, [])

  const backHome = () => {
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  const getVersionedDisplay = () => {
    return (
      localStorage.getItem("version") === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1
    )
  }

  return (
    <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
      <View>
        <ScrollView>
          <Header
            marginBottom='0px'
            text={t("messages.sessionExpiredText")}
            onBack={async () => {
              goToRedirectUrl()
            }}
          />
          <View
            {...styles.innerView}
            h={windowDimensions.height - 70}
            minH={windowDimensions.height - 70}
            bgColor={
              getVersionedDisplay()
                ? localStorage.getItem("backgroundColor")
                : null
            }
          >
            <View {...styles.section1}>
              <View
                {...styles.imageContainer}
                bgColor={
                  getVersionedDisplay()
                    ? localStorage.getItem("widgetBackgroundColor")
                    : null
                }
              >
                {config?.version === PwaVersions.V2_1 ? (
                  <svg
                    width='100'
                    height='100'
                    viewBox='0 0 100 100'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M30 0C13.5078 0 0 13.5078 0 30C0 46.4922 13.5078 60 30 60C31.5938 60 33.2578 59.7656 34.6875 59.5625C35.4531 59.5391 36.1328 59.0781 36.4453 58.375C36.7578 57.6719 36.6406 56.8594 36.1484 56.2734C35.6484 55.6875 34.8672 55.4375 34.125 55.625C32.75 55.8203 31.2109 56 30 56C15.6875 56 4 44.3125 4 30C4 15.6875 15.6875 4 30 4C41.7109 4 51.7266 11.8125 54.875 22.5625C55.1875 23.6328 56.3047 24.25 57.375 23.9375C58.4453 23.625 59.0625 22.5078 58.75 21.4375C55.1016 8.99219 43.4922 0 30 0ZM29.8125 9.9375C29.7266 9.95313 29.6406 9.97656 29.5625 10C28.6328 10.2109 27.9766 11.0469 28 12V27C27.0391 27.6484 26.375 28.7578 26.375 30C26.375 30.2109 26.4688 30.4297 26.5 30.625L19.1875 38C18.5938 38.4844 18.3203 39.2578 18.4922 40.0078C18.6641 40.75 19.25 41.3359 19.9922 41.5078C20.7422 41.6797 21.5156 41.4062 22 40.8125L29.375 33.5C29.5703 33.5312 29.7891 33.625 30 33.625C31.9844 33.625 33.625 31.9844 33.625 30C33.625 28.7578 32.9609 27.6484 32 27V12C32.0234 11.4219 31.7891 10.8672 31.375 10.4766C30.9531 10.0781 30.3828 9.88281 29.8125 9.9375ZM58.6875 28.375C58.0781 28.4688 57.5469 28.8359 57.25 29.375L18.25 97C17.8906 97.625 17.8906 98.3906 18.2578 99.0078C18.6172 99.625 19.2812 100.008 20 100H98C98.7188 100.008 99.3828 99.625 99.7422 99.0078C100.109 98.3906 100.109 97.625 99.75 97L60.75 29.375C60.3672 28.7109 59.6406 28.3203 58.875 28.375C58.8125 28.375 58.75 28.375 58.6875 28.375ZM59 34.4375L94.5 96H23.5L59 34.4375ZM57 54C56.6016 54 56.375 54.2266 56.375 54.625V77.375C56.375 77.7734 56.6016 78 57 78H60.8125C61.2109 78 61.375 77.7734 61.375 77.375V54.625C61.375 54.2266 61.2109 54 60.8125 54H57ZM57 82.625C56.6016 82.625 56.375 82.7891 56.375 83.1875V87.375C56.375 87.7734 56.6016 88 57 88H61C61.3984 88 61.625 87.7734 61.625 87.375V83.1875C61.625 82.7891 61.3984 82.625 61 82.625H57Z'
                      fill={localStorage.getItem("color1")}
                    />
                  </svg>
                ) : (
                  <Image {...styles.image} source={{ uri: ExpiredImage }} />
                )}
              </View>
              <View {...styles.text1Container1}>
                <View {...styles.text1Container2}>
                  <Text
                    {...styles.text1}
                    fontFamily={localStorage.getItem("fontFamily")}
                    color={
                      getVersionedDisplay()
                        ? localStorage.getItem("appTextColor")
                        : null
                    }
                  >
                    {t("messages.sessionExpiredText")}
                  </Text>
                </View>
                <View {...styles.text2Container}>
                  <Text
                    {...styles.text2}
                    fontFamily={localStorage.getItem("fontFamily")}
                    color={
                      getVersionedDisplay()
                        ? localStorage.getItem("appTextColor")
                        : null
                    }
                  >
                    {t("messages.accessTemporarilyRestricted")}
                  </Text>
                </View>
              </View>
            </View>
            {!config?.version == PwaVersions.V2 ? (
              <View {...styles.button} mt='20%'>
                <ColorButton text={t("messages.goBack")} onPress={backHome} />
              </View>
            ) : null}
            <View {...styles.button} position='fixed' bottom={"20px"} mt='25%'>
              <View
                {...styles.errorScreenV2_1_ctaDiv}
                style={{ borderColor: localStorage.getItem("color1") }}
                onClick={async () => goToRedirectUrl()}
              >
                <Text
                  {...styles.errorScreenV2_1_ctaText}
                  style={{ color: localStorage.getItem("color1") }}
                >
                  {t("messages.returnHome")}
                </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default SessionExpired
