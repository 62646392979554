const CreditCardTimes = ({color="#E00000"}) => {
  return (
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
        <path d="M13.9688 21.875H6.46875C4.3075 21.875 3.34375 20.9112 3.34375 18.75V11.875H24.5938V12.5C24.5938 12.845 24.8738 13.125 25.2188 13.125C25.5637 13.125 25.8438 12.845 25.8438 12.5V8.75C25.8438 5.8875 24.3312 4.375 21.4688 4.375H6.46875C3.60625 4.375 2.09375 5.8875 2.09375 8.75V18.75C2.09375 21.6125 3.60625 23.125 6.46875 23.125H13.9688C14.3138 23.125 14.5938 22.845 14.5938 22.5C14.5938 22.155 14.3138 21.875 13.9688 21.875ZM6.46875 5.625H21.4688C23.63 5.625 24.5938 6.58875 24.5938 8.75V10.625H3.34375V8.75C3.34375 6.58875 4.3075 5.625 6.46875 5.625ZM11.4688 16.875C11.8138 16.875 12.0938 17.155 12.0938 17.5C12.0938 17.845 11.8138 18.125 11.4688 18.125H7.71875C7.37375 18.125 7.09375 17.845 7.09375 17.5C7.09375 17.155 7.37375 16.875 7.71875 16.875H11.4688ZM22.7188 15.625C19.6175 15.625 17.0938 18.1488 17.0938 21.25C17.0938 24.3512 19.6175 26.875 22.7188 26.875C25.82 26.875 28.3438 24.3512 28.3438 21.25C28.3438 18.1488 25.82 15.625 22.7188 15.625ZM22.7188 25.625C20.3062 25.625 18.3438 23.6625 18.3438 21.25C18.3438 18.8375 20.3062 16.875 22.7188 16.875C25.1313 16.875 27.0938 18.8375 27.0938 21.25C27.0938 23.6625 25.1313 25.625 22.7188 25.625ZM24.4113 20.4425L23.6037 21.25L24.4113 22.0575C24.655 22.3012 24.655 22.6975 24.4113 22.9413C24.2888 23.0638 24.1287 23.1238 23.9688 23.1238C23.8088 23.1238 23.6487 23.0625 23.5262 22.9413L22.7188 22.1337L21.9113 22.9413C21.7888 23.0638 21.6287 23.1238 21.4688 23.1238C21.3088 23.1238 21.1487 23.0625 21.0262 22.9413C20.7825 22.6975 20.7825 22.3012 21.0262 22.0575L21.8338 21.25L21.0262 20.4425C20.7825 20.1988 20.7825 19.8025 21.0262 19.5587C21.27 19.315 21.6662 19.315 21.91 19.5587L22.7175 20.3663L23.525 19.5587C23.7688 19.315 24.165 19.315 24.4088 19.5587C24.6525 19.8025 24.655 20.1975 24.4113 20.4425Z" fill={color}/>
        </svg>
    </div>
  )
}

export default CreditCardTimes