const styles = {
  mpinVerificationText: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 20,
  },
  saveSettingText: {
    mt: 2,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    color: "#7B7B7B",
  },
  pinMainView: {
    px: 10,
    mt: 3,
    borderRadius: 5,
  },
  pinMainView1: {
    width: "104%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft:"-8px"
  },
  textInput: {
    width: "18%",
    py: 3,
    m: 1.5,
    backgroundColor: "#FFF",
    borderColor: "#303030",
    borderWidth: "1px",
    textAlign: "center",
    secureTextEntry: "true",
    maxLength: 1,
    keyboardType: "numeric",
  },
  forgotMpin: {
    mt: 4,
    fontFamily: "Inter",
    fontSize: 13,
    fontWeight: 400,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  cancelButton: {
    backgroundColor: "#FFF",
    borderColor: "#6180FF",
    borderWidth: 1,
  },
  forgotMpinContainer: {
    display: "flex",
    flexDirection: "row",
    mt: 3,
  },
  forgotMpinText: {
    fontSize: 12,
    fontWeight: 400,
    color: "#7B7B7B",
  },
  resetNowText: {
    fontSize: 12,
    fontWeight: 600,
    color: "#3A5FB6",
    ml: 2,
  },
  otpVerificationText: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600,
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8F8F8",
    padding: 3,
    w: "90%",
    mb: "20px",
  },
  messageText: {
    fontSize: 12,
    color: "#7B7B7B",
  },
  secureMessageText: {
    fontWeight: 500,
    fontSize: 12,
    color: "#7B7B7B",
    mt: "5px",
  },
  infoSvgContainer: {
    width: "20px",
    my: "auto",
  },
  lockSvgContainer: {
    width: "18px",
    mt: "10px",
  },
  secureMessageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tncContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    w: "100%",
    mb: "20px",
  },
  tncCheckBox: {
    borderColor: "grey",
    _checked: { borderColor: "grey" },
    mt: "0px",
    ml: "0px",
    w: "10%",
  },
  tncTextContainer: {
    w: "90%",
    ml: "10px",
  },
  expand: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
}

export default styles
