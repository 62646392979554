import React from "react"
import { useTranslation } from "react-i18next"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents"

const WidgetSkeleton = () => {
  const { t } = useTranslation()
  return (
    <div className='skeleton-home-benefits-widget'>
      <div className='skeleton-benifits-header'>
        <div className='skeleton-benifits-left-content'>
          <div className='skeleton-benifits-header-image'>
            <CardSkeleton br='100%' />
          </div>
          <div className='skeleton-benifits-header-text'>
            <div className='skeleton-benifits-header-title'>
              <TextWrapperSkeleton />
            </div>
            <div className='skeleton-benifits-header-org-name'>
              <TextWrapperSkeleton />
            </div>
          </div>
        </div>
      </div>
      <div className='skeleton-benifits-body'>
        <div className='skeleton-benifits-availabe-amount-header'>
          {t("qrscanner.benefitsHeader")}
        </div>
        <div className='skeleton-benifits-amount'>
          <TextWrapperSkeleton />
          <div className='skeleton-benifits-amount-outof'>/</div>
          <TextWrapperSkeleton />
        </div>
        <div className='skeleton-benifits-footer'>
          <div className='skeleton-benifits-last-loaded-text'>
            {t("qrscanner.benefitsLoadedText")}
          </div>
          <div className='skeleton-benifits-last-loaded-info'>
            <TextWrapperSkeleton />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WidgetSkeleton
