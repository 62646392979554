import React from "react"
import { ColorButton } from "../core/buttons"
import { useSelector } from "react-redux"
import CloseButton from "../svg/v3/closeButton"
import CheckIcon from "../svg/v3/checkIcon"
import { useTranslation } from "react-i18next"

const MerchantFilterBottmSheet = ({
  merchants,
  onClose,
  handleSelectMerchant,
  setMerchantSelect,
  merchantSelect,
}) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  return (
    <div className='v3-spend-analytics-category-bottomsheet-container'>
      <div className='v3-spend-analytics-category-bottomsheet-header'>
        <div className='v3-spend-analytics-category-bottomsheet-header-top'>
          <div className='v3-spend-analytics-category-bottomsheet-primary-header'>
            {t("spendAnalytics.merchantFilterBottomSheet.filterByMerchants")}
          </div>
          <div onClick={onClose}>
            <CloseButton
              color={theme.v3.rawColors.tertiaryNeutral.color1}
              background={theme.v3.rawColors.tertiaryNeutral.color3}
            />
          </div>
        </div>
        <div className='v3-spend-analytics-category-bottomsheet-secondary-header'>
          {t("spendAnalytics.merchantFilterBottomSheet.filterDescription")}
        </div>
      </div>
      <div className='v3-spend-analytics-category-bottomsheet-body-container'>
        {merchants.map((merchant, index) => {
          return (
            <div
              className='v3-spend-analytics-category-insight-bottomsheet-filter'
              onClick={() => setMerchantSelect(merchant)}
              style={{
                backgroundColor:
                  merchantSelect === merchant &&
                  theme.v3.cssVars.secondary.color4,
              }}
            >
              <div className='v3-spend-analytics-category-insight-left-container'>
                <div className='v3-spend-analytics-category-insight-merchant-logo'>
                  <img
                    src={`data:image/png;base64, ${merchant.merchantLogo}`}
                    width={28}
                    height={28}
                  />
                </div>
                <div className='v3-spend-analytics-category-insight-merchant-name'>
                  {merchant.merchantName}
                </div>
              </div>
              {merchantSelect === merchant && (
                <div className='v3-spend-analytics-category-insight-merchant-check'>
                  <CheckIcon
                    width={24}
                    height={24}
                    color={theme.v3.rawColors.secondary.color1}
                  />
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className='v3-spend-analytics-category-bottomsheet-cta-container'>
        <ColorButton
          text={t("spendAnalytics.bottomSheet.confirmText")}
          width={"100%"}
          onPress={() => handleSelectMerchant(merchantSelect)}
        />
      </div>
    </div>
  )
}

export default MerchantFilterBottmSheet
