import ExternalService from "./ExternalService"

const MultiwalletService = {
  loadSummary: async accountId => {
    return ExternalService.post("/wallets/load-summary", {
      accountId: accountId,
    })
  },
  getWallets: accountId => {
    return ExternalService.post("/wallets/fetch-wallets", {
      accountId: accountId,
      getFYLoadedAmount: true,
    })
  },
  loadTransactionSummary: (data, accountId) => {
    return ExternalService.post("/wallets/transaction-summary", {
      accountId: accountId,
      ...data,
    })
  },
  getTransactions: (start, limit, labels, from, to, accountId) => {
    return ExternalService.post("/transactions/fetch-transactions", {
      page: start,
      count: limit,
      from: from,
      to: to,
      accountId: accountId,
      labels: labels,
      fetchRrnAndLogo: true,
      fetchPartialSetoffs: true,
    })
  },
}

export default MultiwalletService
