import moment from "moment"
import { useTranslation } from "react-i18next"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import homeEmptyTransaction from "../../../../assets/images/homeEmptyTransaction.svg"
import AccountService from "../../../../services/AccountService"
import { ProgramTypes } from "../../../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import ViewAllTransactionsLoader from "../../../core/SkeletonLoader/ViewAllTransactionLoader"
import Transaction from "../../../core/Transaction"
import { ColorButton } from "../../../core/buttons"
import CustomLoader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import ArrowDown from "../../../svg/arrowDown"
import FlatList from "../../../../nativeBaseReplacements/FlatList"
import { useToast } from "../../../../nativeBaseReplacements/useToast"
import ChevronDownIcon from "../../../../nativeBaseReplacements/ChevronDownIcon"
import ChevronRightIcon from "../../../../nativeBaseReplacements/ChevronRightIcon"
import ChevronUpIcon from "../../../../nativeBaseReplacements/ChevronUpIcon"

const options = {
  ALL: "ALL",
  UNBILLED: "UNBILLED",
}

const initTransactions = []

const initOffset = 0
const transactionCount = 10

const TransactionSection_v2 = props => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const [isOpen, setIsOpen] = useState(true)
  const [loading, setLoading] = useState(true)
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)
  const [transactions, setTransactions] = useState(initTransactions)
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const [apiError, setApiError] = useState(0)
  const offset = useRef(initOffset)
  const isTransactionsLoadingRef = useRef(false)

  const nativeToast = useToast()
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const fetchUnbilledTransactions = async () => {
    try {
      const response = await AccountService.getUnbilledTransactions(
        user.account.id,
        {
          count: transactionCount,
          offset: offset.current,
        },
      )
      const result = response.data

      if (result?.success) {
        if (result.data.totalCount !== 0) {
          props.setUnbilledTransactions(result?.data?.totalCount)
          setTransactions(transactions => [
            ...transactions,
            ...result.data.transactions,
          ])
        } else {
          props.setUnbilledTransactions(0)
        }
        if (result.data.hasMore) {
          offset.current += transactionCount
        } else if (result.data.totalCount !== 0 && !result.data.hasMore) {
          setTransactions(transactions => [...transactions, { type: "EMPTY" }])
          offset.current = -1
        }
      } else {
        props.setUnbilledTransactions(0)
        setTransactions(transactions => [...transactions, { type: "EMPTY" }])
        offset.current = -1
        setApiError(apiError + 1)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
      offset.current = -1
      setApiError(apiError + 1)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)
    if (
      offset.current !== -1 &&
      !isTransactionsLoadingRef.current &&
      apiError < 3
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns

      await fetchUnbilledTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    }
  }
  useEffect(() => {
    fetchUnbilledTransactions()
  }, [theme?.transactionViewType])

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
  }

  const loadMoreComponent = () => {
    return offset.current === -1 ? (
      <></>
    ) : (
      <div
        onClick={loadMoreTransaction}
        className='home-transactionSection-loadmore-text-v2_1'
      >
        <span
          style={{ color: theme.color1 }}
          className='home-transactionSection-loadmore-text-v2_1-viewHere'
        >
          {" "}
          {t("TransactionSection.loadMoreText")}{" "}
          <span style={{ display: "inline-block" }}>
            {" "}
            <ArrowDown color={theme.color1} size={"9px"} />{" "}
          </span>
        </span>
      </div>
    )
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <>
            <div className='home-transactionSection-render-title'>
              <div
                className='home-transactionSection-render-title-text'
                style={{ fontFamily: theme.fontFamily }}
              >
                {t("TransactionSection.transactionsTitle")}
              </div>
              <div></div>
            </div>
          </>
        )

      case "DATE":
        return (
          <div
            className='home-transactionSection-render-transactionDate'
            style={{ color: theme.appTextColor }}
          >
            {moment(item.date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Today"
              : moment(item.date).format("Do MMM YY")}
          </div>
        )

      case "EMPTY":
        return (
          <>
            {config?.version === PwaVersions.V2_1 ? (
              <></>
            ) : (
              <div className='home-transactionSection-render-emptyItem'>
                <ColorButton
                  opacity={!viewStatementByBlockCode ? "0.4" : "1"}
                  text={"View Statement Summary"}
                  onPress={!viewStatementByBlockCode ? null : ViewTransaction}
                />
              </div>
            )}
          </>
        )

      default:
        return (
          <div className='home-transactionSection-container' key={index}>
            <Transaction item={item} />
          </div>
        )
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <div className='home-transactionSection-renderLoader'>
        <CustomLoader color={theme.color1} width={20} height={20} />
      </div>
    ) : null
  }
  const isAll = theme?.transactionViewType === options.ALL
  const ViewTransaction = () => {
    if (user.programType === ProgramTypes.PREPAID) navigate("/Transactions")
    else if (isAll) navigate("/ViewTransactions")
    else navigate("/Statement")
  }

  useEffect(() => {
    if (apiError >= 3) {
      toast("Error fetching Transactions")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      if (user.programType !== ProgramTypes.PREPAID) {
        setViewStatementByBlockCode(false)
      }
    }
  })
  const getMsg = () => {
    if (user.programType === ProgramTypes.PREPAID)
      return "View All Transactions"
    else if (isAll) return null
    else return "View Statement Summary"
  }

  return (
    <>
      <div
        className='home-transactionSection'
        style={{ backgroundColor: theme.widgetBackgroundColor }}
      >
        <div
          className={
            isOpen
              ? "home-transactionSection-open"
              : "home-transactionSection-close"
          }
        >
          <div
            className='home-transactionSection-heading'
            style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
          >
            {theme?.transactionViewType === options.UNBILLED
              ? "Unbilled Transactions"
              : "Recent Transactions"}
          </div>
          <div onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }}>
            {isOpen ? (
              <ChevronUpIcon size='6' color={theme.appTextColor} />
            ) : (
              <ChevronDownIcon size='6' color={theme.appTextColor} />
            )}
          </div>
        </div>

        {user.programType === ProgramTypes.PREPAID || isAll ? (
          loading ? (
            <div className='home-loader'>
              {/* <CustomLoader color={theme.color1} width={120} height={120} /> */}
              <ViewAllTransactionsLoader />
            </div>
          ) : props.recentTransactions.length > 0 ? (
            isOpen &&
            props.recentTransactions.map((transaction, index) => (
              <div className='home-transactionSection-container' key={index}>
                <Transaction item={transaction} />
              </div>
            ))
          ) : (
            isOpen && (
              <div className='home-transactionSection-Emptytransaction'>
                <img
                  className='home-transactionSection-Emptytransaction-icon'
                  src={
                    screen?.home?.transaction?.emptyImageUrl ||
                    homeEmptyTransaction
                  }
                  alt='Alternate Text '
                />

                <div
                  className='home-transactionSection-Emptytransaction-text'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  {t("TransactionSection.emptyTransactionsText")}
                </div>
              </div>
            )
          )
        ) : null}
        {user.programType !== ProgramTypes.PREPAID &&
        theme?.transactionViewType === options.UNBILLED &&
        isOpen ? (
          loading ? (
            <div className='home-loader'>
              {/* <CustomLoader color={theme.color1} width={120} height={120} /> */}
              <ViewAllTransactionsLoader />
            </div>
          ) : transactions.length > 0 ? (
            <FlatList
              data={transactions}
              renderItem={renderItem}
              keyExtractor={(_transaction, index) => index}
              onEndReached={
                config.version === PwaVersions.V2_1
                  ? console.log()
                  : loadMoreTransaction
              }
              ListFooterComponent={
                config.version === PwaVersions.V2_1
                  ? loadMoreComponent
                  : renderLoader
              }
              onEndReachedThreshold={0.2}
              refreshing={false}
              onRefresh={handleRefresh}
            />
          ) : (
            <div className='home-transactionSection-Emptytransaction'>
              <img
                className='home-transactionSection-Emptytransaction-icon'
                src={
                  screen?.home?.transaction?.emptyImageUrl ||
                  homeEmptyTransaction
                }
                alt='Alternate Text '
              />
              <div
                className='home-transactionSection-Emptytransaction-text'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                {t("TransactionSection.emptyUnbilledTrxns")}
              </div>

              {config.version !== PwaVersions.V2_1 && getMsg() && (
                <div
                  className='home-transactionSection-Emptytransaction-msg'
                  onClick={() => ViewTransaction()}
                >
                  <div
                    className='home-transactionSection-Emptytransaction-msg-text'
                    style={{ color: theme.color1 }}
                  >
                    {getMsg()}
                    <ChevronRightIcon mb='1px' color={theme.color1} size={4} />
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}

        {
          <div>
            {user.programType === ProgramTypes.PREPAID || isAll
              ? props.recentTransactions.length > 0
                ? isOpen && (
                    <div className='home-transactionSection-button'>
                      <ColorButton
                        opacity={!viewStatementByBlockCode ? "0.4" : "1"}
                        text={getMsg() || "View All Transactions"}
                        onPress={
                          !viewStatementByBlockCode ? null : ViewTransaction
                        }
                      />
                    </div>
                  )
                : isOpen &&
                  (user.summary.lastStatement.toDate ||
                    props.recentTransactions.length === 0) &&
                  getMsg() && (
                    <div
                      className='home-transactionSection-cta'
                      onClick={() => ViewTransaction()}
                    >
                      <div
                        className='home-transactionSection-cta-text'
                        style={{ color: theme.color1 }}
                      >
                        {getMsg()}
                        <ChevronRightIcon
                          mb='1px'
                          color={theme.color1}
                          size={4}
                        />
                      </div>
                    </div>
                  )
              : null}
          </div>
        }
      </div>
    </>
  )
}

export default TransactionSection_v2
