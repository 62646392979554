import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter } from "react-router-dom"
import store from "../src/store/Store"
import "../src/translations/i18n.js"
import App from "./App"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import { Envs } from "./utils/enums.js"
import { BottomSheetProvider } from "./components/auth/BottomSheetContext.js"
import { ErrorScreenProvider } from "./components/auth/ErrorScreenContext.js"

const env = process.env.REACT_APP_ENV

const originalConsoleLog = console.log

console.log = data => {
  if (env === Envs.DEV || env === Envs.UAT) originalConsoleLog(data)
}

Sentry.setUser({ sessionToken: localStorage.getItem("sessionToken") })

env !== Envs.DEV &&
  Sentry.init({
    dsn: "https://664f5d64a9ec43e3ae12fdefbad2ecdb@o4505131282006016.ingest.sentry.io/4505131337711616",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: env,
  })

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorScreenProvider>
          <BottomSheetProvider>
            <App />
          </BottomSheetProvider>
        </ErrorScreenProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
)

reportWebVitals()
