import React, { forwardRef } from "react"
import {
  getNativeBaseBoxShadow,
  nativeBaseColor2rgba,
  getNativeBaseSpaceToPx as spaceToPx,
} from "../utils/functions"

const View = forwardRef((props, ref) => {
  const {
    padding,
    p,
    paddingBottom,
    pb,
    py,
    paddingTop,
    pt,
    paddingLeft,
    pl,
    px,
    paddingRight,
    pr,
    margin,
    m,
    marginBottom,
    mb,
    my,
    marginTop,
    mt,
    marginLeft,
    ml,
    mx,
    marginRight,
    mr,
    maxWidth,
    maxW,
    minWidth,
    minW,
    width,
    w,
    minHeight,
    minH,
    maxHeight,
    maxH,
    height,
    h,
    backgroundColor,
    bgColor,
    background,
    bg,
    className,
    flexDir,
    flexDirection,
    style,
    _web,
    shadow,
    borderColor,
    borderWidth,
    ...restProps
  } = { ...props, ...props.style }

  const resolvedPadding = spaceToPx(padding, 1) ?? spaceToPx(p)
  const resolvedPaddingBottom =
    spaceToPx(paddingBottom, 1) ?? spaceToPx(pb ?? py)
  const resolvedPaddingTop = spaceToPx(paddingTop, 1) ?? spaceToPx(pt ?? py)
  const resolvedPaddingLeft = spaceToPx(paddingLeft, 1) ?? spaceToPx(pl ?? px)
  const resolvedPaddingRight = spaceToPx(paddingRight, 1) ?? spaceToPx(pr ?? px)

  const resolvedMargin = spaceToPx(margin, 1) ?? spaceToPx(m)
  const resolvedMarginBottom = spaceToPx(marginBottom, 1) ?? spaceToPx(mb ?? my)
  const resolvedMarginTop = spaceToPx(marginTop, 1) ?? spaceToPx(mt ?? my)
  const resolvedMarginLeft = spaceToPx(marginLeft, 1) ?? spaceToPx(ml ?? mx)
  const resolvedMarginRight = spaceToPx(marginRight, 1) ?? spaceToPx(mr ?? mx)

  const resolvedMaxWidth = spaceToPx(maxWidth, 1) ?? spaceToPx(maxW)
  const resolvedMinWidth = spaceToPx(minWidth, 1) ?? spaceToPx(minW)
  const resolvedWidth = spaceToPx(width, 1) ?? spaceToPx(w)

  const resolvedMinHeight = spaceToPx(minHeight, 1) ?? spaceToPx(minH)
  const resolvedMaxHeight = spaceToPx(maxHeight, 1) ?? spaceToPx(maxH)
  const resolvedHeight = spaceToPx(height, 1) ?? spaceToPx(h)
  const fixedFlexDirection = flexDir ?? flexDirection

  const resolvedBackgroundColor = nativeBaseColor2rgba(
    backgroundColor ?? bgColor,
  )
  const resolvedBackground = nativeBaseColor2rgba(background ?? bg)

  const resolvedShadow = shadow ? getNativeBaseBoxShadow(shadow) : undefined

  const resolvedBorderColor = nativeBaseColor2rgba(borderColor)

  const combinedStyle = {
    alignItems: "stretch",
    border: "0 solid black",
    boxSizing: "border-box",
    display: "flex",
    flexBasis: "auto",
    flexDirection: fixedFlexDirection || "column",
    flexShrink: 0,
    listStyle: "none",
    margin: 0,
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    position: "relative",
    textDecoration: "none",
    zIndex: 0,
    ...restProps,
    ...style,
    ..._web,
  }

  const appliedStyles = {
    padding: resolvedPadding,
    paddingBottom: resolvedPaddingBottom,
    paddingTop: resolvedPaddingTop,
    paddingLeft: resolvedPaddingLeft,
    paddingRight: resolvedPaddingRight,
    margin: resolvedMargin,
    marginBottom: resolvedMarginBottom,
    marginTop: resolvedMarginTop,
    marginLeft: resolvedMarginLeft,
    marginRight: resolvedMarginRight,
    maxWidth: resolvedMaxWidth,
    minWidth: resolvedMinWidth,
    width: resolvedWidth,
    minHeight: resolvedMinHeight,
    maxHeight: resolvedMaxHeight,
    height: resolvedHeight,
    background: resolvedBackground,
    backgroundColor: resolvedBackgroundColor || "transparent",
    boxShadow: resolvedShadow,
    borderColor: resolvedBorderColor,
    borderWidth: borderWidth,
  }

  Object.entries(appliedStyles).forEach(([key, value]) => {
    if (typeof value === "undefined" || value === null) {
      return
    }
    combinedStyle[key] = value
  })

  return (
    <div
      nbr='View'
      ref={ref}
      {...restProps}
      className={className}
      style={combinedStyle}
    />
  )
})

export default View
