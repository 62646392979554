import React, { useState } from "react"
import "../../../src/styles.css" // Import the CSS file

const InputDatePicker = ({
  setDate,
  date,
  validate,
  minDate,
  maxDate,
  format,
  dateType,
  isDisabled,
  setError,
  optionalIndex = null,
}) => {
  const handleInput = val => {
    setDate(val)
  }

  const handleValidate = val => {
    if (validate) {
      let errorMessage = validate(val)
      if (errorMessage) {
        setError(errorMessage)
      } else {
        setError("")
      }
    }
  }

  return (
    <div>
      <input
        datepicker
        min={minDate}
        max={maxDate}
        type={dateType}
        className='custom-input'
        placeholder='Select date'
        datepicker-format={format || "dd/mm/yyyy"}
        value={date}
        onBlur={e => handleValidate(e.target.value)}
        onChange={e => handleInput(e.target.value)}
        disabled={isDisabled}
        onKeyDown={e => e.preventDefault()}
        onClick={e => e.target.showPicker && e.target.showPicker()}
      />
    </div>
  )
}

export default InputDatePicker
