import React from "react"
import { CardSkeleton } from "./styledComponents.js"

function BlockedPageLoader({}) {
  return (
    <>
      <CardSkeleton height='140px' margin='auto' width='135px' />
      <CardSkeleton height='90px' margin='20px 0px 0px 0px' width='80%' />
    </>
  )
}
export default BlockedPageLoader 
 