const ArrowRightSolidSmall = ({ color = "#1965CF" }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
      >
        <path
          d='M10.353 6.35414L8.35352 8.35366C8.25602 8.45116 8.128 8.50014 8 8.50014C7.872 8.50014 7.74399 8.45116 7.64649 8.35366C7.45098 8.15816 7.45098 7.84212 7.64649 7.64663L8.79297 6.50014H2C1.7235 6.50014 1.5 6.27614 1.5 6.00014C1.5 5.72414 1.7235 5.50014 2 5.50014H8.79297L7.64649 4.35366C7.45098 4.15816 7.45098 3.84212 7.64649 3.64662C7.84199 3.45112 8.15802 3.45112 8.35352 3.64662L10.353 5.64614C10.3995 5.69264 10.436 5.7476 10.4615 5.8091C10.512 5.9311 10.512 6.06918 10.4615 6.19118C10.436 6.25268 10.3995 6.30764 10.353 6.35414Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default ArrowRightSolidSmall
