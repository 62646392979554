import React from "react"
import webZeroTxn from "../../../../assets/images/v3/webZeroTxn.svg"
import moment from "moment"
import { useTranslation } from "react-i18next"

const ZeroTransactions = ({ currentPage, dateRange }) => {
  const { t } = useTranslation()
  return (
    <div className='v3-recent-transaction-table-container'>
      <div className='v3-recent-transaction-table-section'>
        <table className='v3-recent-transaction-table-body'>
          <tr className='v3-recent-transaction-table-header'>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.zeroTransactions.transactionType")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.zeroTransactions.orderId")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.zeroTransactions.dateText")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.zeroTransactions.amountText")}
            </th>
            <th className='v3-recent-transaction-table-header-text'>
              {t("payLaterWebComponents.zeroTransactions.statusText")}
            </th>
          </tr>
        </table>
        <div className='v3-web-zero-transaction-container'>
          <div className='v3-web-zero-transaction-illustration'>
            <img src={webZeroTxn} alt='zero txn image' />
          </div>
          <div className='v3-web-zero-transaction-description-container'>
            <div className='v3-web-zero-transaction-description-header'>
              {t("payLaterWebComponents.zeroTransactions.noTransactionsFound")}
            </div>
            {currentPage === "ALL_TRANSACTIONS" && (
              <div className='v3-web-zero-transaction-description-date-range'>
                {t("payLaterWebComponents.zeroTransactions.fromText")}{" "}
                <span className='v3-web-zero-transaction-date-range'>
                  {moment(dateRange?.from).format("DD MMM")}
                </span>{" "}
                {t("payLaterWebComponents.zeroTransactions.toText")}{" "}
                <span className='v3-web-zero-transaction-date-range'>
                  {moment(dateRange?.to).format("DD MMM")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ZeroTransactions
