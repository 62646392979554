import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../../utils/enums"
import BlockCardReason_v2 from "./blockCardReason_v2"
import BlockCardReason_v3 from "../blockCardBottomSheets/blockCardReason_v3"

const Index = () => {
  const config = useSelector(state => state.config)
  const getVersioned = version => {
    switch (version) {
      case PwaVersions.V2:
        return <BlockCardReason_v2 />
      case PwaVersions.V2_1:
        return <BlockCardReason_v2 />
      case PwaVersions.V3:
        return <BlockCardReason_v3 />
      default:
        return <BlockCardReason_v2 />
    }
  }
  return getVersioned(config?.version)
}

export default Index
