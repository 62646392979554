import React from "react"
import { AuthBlocked_v3 } from "../core/AuthBlocked_v3"
import { useLocation } from "react-router-dom"

const BlockedState_v3 = () => {
  const { state } = useLocation()
  const type = state.type
  const time = state.time
  return <AuthBlocked_v3 type={type} timeUntilBlocked={time} />
}

export default BlockedState_v3
