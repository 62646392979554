import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents"

function PwaRedirectionImageLoader() {
  return (
    <CardSkeleton width='123.5px' height='123.5px' display='block'>
      <TextWrapperSkeleton width='123.5px' height='123.5px' />
    </CardSkeleton>
  )
}

export default PwaRedirectionImageLoader
