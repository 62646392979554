const Stroke = ({ color = "#F0F2F4", width = "245", height = "2" }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill='none'
  >
    <path d={`M0 0.570923H${width}`} stroke={color} />
  </svg>
)

export default Stroke
