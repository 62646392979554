import React from "react"

const CS_privacy = ({ color = "#23951C" }) => {
  return (
    <svg
      width='100%'
      height='56'
      viewBox='0 0 57 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.5002 4.66675C22.0835 4.66675 16.8335 9.91675 16.8335 16.3334V21.0001H21.5002V16.3334C21.5002 12.4834 24.6502 9.33342 28.5002 9.33342C32.3502 9.33342 35.5002 12.4834 35.5002 16.3334V21.0001H40.1668V16.3334C40.1668 9.91675 34.9168 4.66675 28.5002 4.66675Z'
        fill='#0E0E0E'
      />
      <path
        d='M42.5002 51.3333H14.5002C11.9335 51.3333 9.8335 49.2333 9.8335 46.6667V25.6667C9.8335 23.1 11.9335 21 14.5002 21H42.5002C45.0668 21 47.1668 23.1 47.1668 25.6667V46.6667C47.1668 49.2333 45.0668 51.3333 42.5002 51.3333Z'
        fill='#68B670'
      />
      <path
        d='M28.5 29.1667C26.6435 29.1667 24.863 29.9042 23.5503 31.217C22.2375 32.5298 21.5 34.3102 21.5 36.1667C21.5 38.0233 22.2375 39.8037 23.5503 41.1165C24.863 42.4293 26.6435 43.1667 28.5 43.1667C30.3565 43.1667 32.137 42.4293 33.4497 41.1165C34.7625 39.8037 35.5 38.0233 35.5 36.1667C35.5 34.3102 34.7625 32.5298 33.4497 31.217C32.137 29.9042 30.3565 29.1667 28.5 29.1667Z'
        fill='#EFEBE9'
      />
      <path
        d='M28.5 32.6667C27.5717 32.6667 26.6815 33.0355 26.0251 33.6919C25.3687 34.3483 25 35.2385 25 36.1667C25 37.095 25.3687 37.9852 26.0251 38.6416C26.6815 39.298 27.5717 39.6667 28.5 39.6667C29.4283 39.6667 30.3185 39.298 30.9749 38.6416C31.6313 37.9852 32 37.095 32 36.1667C32 35.2385 31.6313 34.3483 30.9749 33.6919C30.3185 33.0355 29.4283 32.6667 28.5 32.6667Z'
        fill={color}
      />
      <path
        d='M30.8332 32.6667C30.5238 32.6667 30.227 32.7897 30.0082 33.0085C29.7894 33.2272 29.6665 33.524 29.6665 33.8334C29.6665 34.1428 29.7894 34.4396 30.0082 34.6584C30.227 34.8772 30.5238 35.0001 30.8332 35.0001C31.1426 35.0001 31.4393 34.8772 31.6581 34.6584C31.8769 34.4396 31.9998 34.1428 31.9998 33.8334C31.9998 33.524 31.8769 33.2272 31.6581 33.0085C31.4393 32.7897 31.1426 32.6667 30.8332 32.6667Z'
        fill='white'
      />
    </svg>
  )
}

export default CS_privacy
