const InfoCircle = ({ color, size }) => {
  return (
    <div style={{ width: size, display: "flex", alignItems: "center" }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
      >
        <path
          d='M8.49998 15.1663C4.54798 15.1663 1.33331 11.9517 1.33331 7.99967C1.33331 4.04767 4.54798 0.833008 8.49998 0.833008C12.452 0.833008 15.6666 4.04767 15.6666 7.99967C15.6666 11.9517 12.452 15.1663 8.49998 15.1663ZM8.49998 1.83301C5.09931 1.83301 2.33331 4.59901 2.33331 7.99967C2.33331 11.4003 5.09931 14.1663 8.49998 14.1663C11.9006 14.1663 14.6666 11.4003 14.6666 7.99967C14.6666 4.59901 11.9006 1.83301 8.49998 1.83301ZM8.99998 10.9997V7.95231C8.99998 7.67631 8.77598 7.45231 8.49998 7.45231C8.22398 7.45231 7.99998 7.67631 7.99998 7.95231V10.9997C7.99998 11.2757 8.22398 11.4997 8.49998 11.4997C8.77598 11.4997 8.99998 11.2757 8.99998 10.9997ZM9.17999 5.66634C9.17999 5.29834 8.88199 4.99967 8.51333 4.99967H8.50665C8.13865 4.99967 7.84324 5.29834 7.84324 5.66634C7.84324 6.03434 8.14533 6.33301 8.51333 6.33301C8.88133 6.33301 9.17999 6.03434 9.17999 5.66634Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default InfoCircle
