import { hex2hsl, hsl2hex } from "./functions"

const getBrandColorPalette = color => {
  const { h, s, l } = hex2hsl(color)
  let sN = [s]
  let lN = [l]
  const lPercentages = [36, 62, 82, 94]
  for (let i = 0; i < 4; i++) {
    let newSN = sN[i]
    if (lN[i] < 50) {
      newSN = newSN - (2 * (50 - lN[i]) * newSN) / 100
    }
    const newLN = l + (100 - l) * (lPercentages[i] / 100)
    sN.push(newSN)
    lN.push(newLN)
  }
  const output = {}
  for (let i = 1; i <= 5; i++) {
    output[`color${i}`] = hsl2hex(h, sN[i - 1], lN[i - 1])
  }
  return output
}

const getSystemColorPalette = color => {
  const { h, s, l } = hex2hsl(color)
  let sN = [s]
  let lN = [l]
  const lPercentages = [62, 94]
  for (let i = 0; i < 2; i++) {
    let newSN = sN[i]
    if (lN[i] < 50) {
      newSN = newSN - (2 * (50 - lN[i]) * newSN) / 100
    }
    const newLN = l + (100 - l) * (lPercentages[i] / 100)
    sN.push(newSN)
    lN.push(newLN)
  }
  const output = {}
  for (let i = 1; i <= 3; i++) {
    output[`color${i}`] = hsl2hex(h, sN[i - 1], lN[i - 1])
  }
  return output
}

export const getV3ThemeTokens = config => {
  const defaultV3ThemeConfig = {
    primaryColor: "#1965CF",
    iconsColor: "#0D2950",
    secondaryColor: "#ED5B27",
    screenConfig: {
      colorStyle: {
        neutralTertiaryColor: "#0E0E0E",
        positiveTertiaryColor: "#23951C",
        negativeTertiaryColor: "#E72B2B",
        cautionTertiaryColor: "#E5B200",
        feedbackTertiaryColor: "#A432EA",
        leadingWhiteTertiaryColor: "#FFFFFF",
      },
    },
  }

  const primary = config?.primaryColor || defaultV3ThemeConfig.primaryColor
  const primaryBase = config?.iconsColor || defaultV3ThemeConfig.iconsColor
  const secondaryColor =
    config?.secondaryColor || defaultV3ThemeConfig.secondaryColor
  const leadingWhite =
    config?.screenConfig?.colorStyle?.leadingWhiteTertiaryColor ||
    defaultV3ThemeConfig.screenConfig.colorStyle.leadingWhiteTertiaryColor
  const tertiaryPositive =
    config?.screenConfig?.colorStyle?.positiveTertiaryColor ||
    defaultV3ThemeConfig.screenConfig.colorStyle.positiveTertiaryColor
  const tertiaryNegative =
    config?.screenConfig?.colorStyle?.negativeTertiaryColor ||
    defaultV3ThemeConfig.screenConfig.colorStyle.negativeTertiaryColor
  const tertiaryCaution =
    config?.screenConfig?.colorStyle?.cautionTertiaryColor ||
    defaultV3ThemeConfig.screenConfig.colorStyle.cautionTertiaryColor
  const tertiaryAltFeedback =
    config?.screenConfig?.colorStyle?.feedbackTertiaryColor ||
    defaultV3ThemeConfig.screenConfig.colorStyle.feedbackTertiaryColor
  const tertiaryNeutral =
    config?.screenConfig?.colorStyle?.neutralTertiaryColor ||
    defaultV3ThemeConfig.screenConfig.colorStyle.neutralTertiaryColor
  const primaryCtaBgColor =
    config?.screenConfig?.colorStyle?.primaryButton?.backgroundColor ||
    secondaryColor
  const primaryCtaTextColor =
    config?.screenConfig?.colorStyle?.primaryButton?.textColor || leadingWhite
  const primaryCtaStrokeColor =
    config?.screenConfig?.colorStyle?.primaryButton?.strokeColor || leadingWhite
  const secondaryCtaBgColor =
    config?.screenConfig?.colorStyle?.secondaryButton?.backgroundColor ||
    leadingWhite
  const secondaryCtaTextColor =
    config?.screenConfig?.colorStyle?.secondaryButton?.textColor ||
    secondaryColor
  const secondaryCtaStrokeColor =
    config?.screenConfig?.colorStyle?.secondaryButton?.strokeColor ||
    secondaryColor
  const tertiaryCtaTextColor =
    config?.screenConfig?.colorStyle?.tertiaryButtonColor || secondaryColor

  return {
    primary: getBrandColorPalette(primary),
    primaryBase: getBrandColorPalette(primaryBase),
    secondary: getBrandColorPalette(secondaryColor),
    leadingWhite,
    tertiaryNeutral: getSystemColorPalette(tertiaryNeutral),
    tertiaryPositive: getSystemColorPalette(tertiaryPositive),
    tertiaryNegative: getSystemColorPalette(tertiaryNegative),
    tertiaryCaution: getSystemColorPalette(tertiaryCaution),
    tertiaryAltFeedback: getSystemColorPalette(tertiaryAltFeedback),
    primaryCta: {
      backgroundColor: primaryCtaBgColor,
      textColor: primaryCtaTextColor,
      strokeColor: primaryCtaStrokeColor,
    },
    secondaryCta: {
      backgroundColor: secondaryCtaBgColor,
      textColor: secondaryCtaTextColor,
      strokeColor: secondaryCtaStrokeColor,
    },
    tertiaryCta: {
      textColor: tertiaryCtaTextColor,
    },
  }
}

export const getV3Theme = () => {
  const getBrandColorPalette = (baseToken = "primary") => {
    const output = {}
    for (let i = 1; i <= 5; i++) {
      output[`color${i}`] = `var(--${baseToken}-color-${i})`
    }
    return output
  }

  const getSystemColorPalette = (baseToken = "primary") => {
    const output = {}
    for (let i = 1; i <= 3; i++) {
      output[`color${i}`] = `var(--${baseToken}-color-${i})`
    }
    return output
  }

  const getCtaColorPalette = (baseToken = "primary-cta") => {
    return {
      backgroundColor: `var(--${baseToken}-background-color)`,
      textColor: `var(--${baseToken}-text-color)`,
      strokeColor: `var(--${baseToken}-stroke-color)`,
    }
  }

  return {
    primary: getBrandColorPalette("primary"),
    primaryBase: getBrandColorPalette("primary-base"),
    secondary: getBrandColorPalette("secondary"),
    leadingWhite: `var(--leading-white)`,
    tertiaryNeutral: getSystemColorPalette("tertiary-neutral"),
    tertiaryPositive: getSystemColorPalette("tertiary-positive"),
    tertiaryNegative: getSystemColorPalette("tertiary-negative"),
    tertiaryCaution: getSystemColorPalette("tertiary-caution"),
    tertiaryAltFeedback: getSystemColorPalette("tertiary-alt-feedback"),
    primaryCta: getCtaColorPalette("primary-cta"),
    secondaryCta: getCtaColorPalette("secondary-cta"),
    tertiaryCta: {
      textColor: `var(--tertiary-cta-text-color)`,
    },
  }
}

export const getV3ThemeCSS = config => {
  const v3Theme = getV3ThemeTokens(config)
  return `
    :root {
      --primary-color-1: ${v3Theme.primary.color1};
      --primary-color-2: ${v3Theme.primary.color2};
      --primary-color-3: ${v3Theme.primary.color3};
      --primary-color-4: ${v3Theme.primary.color4};
      --primary-color-5: ${v3Theme.primary.color5};

      --primary-base-color-1: ${v3Theme.primaryBase.color1};
      --primary-base-color-2: ${v3Theme.primaryBase.color2};
      --primary-base-color-3: ${v3Theme.primaryBase.color3};
      --primary-base-color-4: ${v3Theme.primaryBase.color4};
      --primary-base-color-5: ${v3Theme.primaryBase.color5};

      --secondary-color-1: ${v3Theme.secondary.color1};
      --secondary-color-2: ${v3Theme.secondary.color2};
      --secondary-color-3: ${v3Theme.secondary.color3};
      --secondary-color-4: ${v3Theme.secondary.color4};
      --secondary-color-5: ${v3Theme.secondary.color5};

      --leading-white: ${v3Theme.leadingWhite};
      
      --tertiary-neutral-color-1: ${v3Theme.tertiaryNeutral.color1};
      --tertiary-neutral-color-2: ${v3Theme.tertiaryNeutral.color2};
      --tertiary-neutral-color-3: ${v3Theme.tertiaryNeutral.color3};

      --tertiary-positive-color-1: ${v3Theme.tertiaryPositive.color1};
      --tertiary-positive-color-2: ${v3Theme.tertiaryPositive.color2};
      --tertiary-positive-color-3: ${v3Theme.tertiaryPositive.color3};

      --tertiary-negative-color-1: ${v3Theme.tertiaryNegative.color1};
      --tertiary-negative-color-2: ${v3Theme.tertiaryNegative.color2};
      --tertiary-negative-color-3: ${v3Theme.tertiaryNegative.color3};

      --tertiary-caution-color-1: ${v3Theme.tertiaryCaution.color1};
      --tertiary-caution-color-2: ${v3Theme.tertiaryCaution.color2};
      --tertiary-caution-color-3: ${v3Theme.tertiaryCaution.color3};

      --tertiary-alt-feedback-color-1: ${v3Theme.tertiaryAltFeedback.color1};
      --tertiary-alt-feedback-color-2: ${v3Theme.tertiaryAltFeedback.color2};
      --tertiary-alt-feedback-color-3: ${v3Theme.tertiaryAltFeedback.color3};

      --primary-cta-background-color: ${v3Theme.primaryCta.backgroundColor};
      --primary-cta-text-color: ${v3Theme.primaryCta.textColor};
      --primary-cta-stroke-color: ${v3Theme.primaryCta.strokeColor};

      --secondary-cta-background-color: ${v3Theme.secondaryCta.backgroundColor};
      --secondary-cta-text-color: ${v3Theme.secondaryCta.textColor};
      --secondary-cta-stroke-color: ${v3Theme.secondaryCta.strokeColor};

      --tertiary-cta-text-color: ${v3Theme.tertiaryCta.textColor};
    }
  `
}
