const Sparkle = ({ color, size, className = "" }) => {
  return (
    <div style={{ width: size }}>
      <svg
        className={className}
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
      >
        <path
          d='M12.33 6.71906L10.7393 6.26463C9.27629 5.84638 8.15279 4.72346 7.73454 3.25988L7.28012 1.66913C7.20837 1.41888 6.7907 1.41888 6.71895 1.66913L6.26454 3.25988C5.84629 4.72346 4.72279 5.84696 3.25979 6.26463L1.66903 6.71906C1.54361 6.75464 1.45728 6.86955 1.45728 6.99963C1.45728 7.12972 1.54361 7.24404 1.66903 7.28021L3.25979 7.73464C4.72279 8.15289 5.84629 9.27581 6.26454 10.7394L6.71895 12.3301C6.75512 12.4556 6.86945 12.5419 6.99953 12.5419C7.12961 12.5419 7.24396 12.4556 7.28012 12.3301L7.73454 10.7394C8.15279 9.27581 9.27629 8.15231 10.7393 7.73464L12.33 7.28021C12.4554 7.24463 12.5418 7.12972 12.5418 6.99963C12.5418 6.86955 12.4554 6.75522 12.33 6.71906Z'
          fill={color || "#2680EA"}
        />
      </svg>
    </div>
  )
}

export default Sparkle
