import React from "react"
import { useSelector } from "react-redux"
import CopyToClipboard from "../../../svg/v3/copyToClipboard"
import couponLarge from "../../../../assets/images/v3/couponLarge.svg"
import couponDivider from "../../../../assets/images/v3/couponDivider.svg"
import couponText from "../../../../assets/images/v3/couponText.svg"
import { copyToClipboard, hexToRGBA } from "../../../../utils/functions"
import CouponBackground from "../../../svg/v3/couponBackground"
import defaultLogo from "../../../../assets/images/v3/Vector.svg"
import { customToast } from "../../../../core"
import { ToastType } from "../../../../utils/enums"

const MerchantOfferCoupon = ({
  offer,
  offerDetails,
  showCouponCode,
  handleCopyTextClick,
  showCopyToClipboardButton = true,
}) => {
  const theme = useSelector(state => state.theme)
  const handleCopyButtonClick = couponCode => {
    copyToClipboard(couponCode)
    customToast(ToastType.COPY, `Coupon Code Copied!`, {
      toastTextColor: "var(--tertiary-neutral-color-1)",
      toastBackgroundColor: "var(--tertiary-neutral-color-1)",
    })
  }
  return (
    <div
      className={`v3-merchantOfferDetails-coupon ${!showCopyToClipboardButton ? "v3-merchantOfferDetails-coupon-animation" : ""}`}
    >
      <img src={couponLarge} alt='coupon' />
      <div className='v3-merchantOfferDetails-coupon-merchantLogo'>
        {offer?.logoUrl ? (
          <img src={offer?.logoUrl} alt='merchantLogo' />
        ) : (
          <img src={defaultLogo} />
        )}
      </div>
      <div className='v3-merchantOfferDetails-coupon-divider'>
        <img src={couponDivider} alt='couponDivider' />
      </div>
      <div className='v3-merchantOfferDetails-coupon-text'>
        {showCouponCode ? (
          <div className='v3-merchantOfferDetails-coupon-couponCode'>
            <div className='v3-merchantOfferDetails-coupon-couponCode-background'>
              <CouponBackground
                color={
                  offer?.displayColor
                    ? hexToRGBA(offer.displayColor, 0.3)
                    : theme.v3.rawColors.primary.color4
                }
              />
            </div>
            <div
              className='v3-merchantOfferDetails-coupon-couponCode-text'
              style={{
                color: offer?.displayColor || theme.v3.cssVars.primary.color1,
              }}
            >
              {offerDetails?.couponCode}{" "}
              {showCopyToClipboardButton && (
                <div
                  className='v3-merchantOfferDetails-coupon-copyToClipboard'
                  onClick={() =>
                    handleCopyButtonClick(offerDetails?.couponCode)
                  }
                >
                  <CopyToClipboard
                    color={
                      offer?.displayColor || theme.v3.rawColors.primary.color1
                    }
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <img
            src={couponText}
            alt='couponText'
            onClick={() => handleCopyTextClick()}
            style={{ cursor: "pointer" }}
          />
        )}
      </div>
    </div>
  )
}

export default MerchantOfferCoupon
