import React from "react"

const SparklesLogo = ({ color = "white" }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='15'
    height='13'
    viewBox='0 0 15 13'
    fill='none'
  >
    <path
      d='M6.72222 0.388916L7.73417 5.59919L12.9444 6.61114L7.73417 7.62309L6.72222 12.8334L5.71027 7.62309L0.5 6.61114L5.71027 5.59919L6.72222 0.388916Z'
      fill={color}
    />
    <path
      d='M12.1693 0.166748L12.5158 2.1536L14.5026 2.50008L12.5158 2.84656L12.1693 4.83341L11.8228 2.84656L9.83594 2.50008L11.8228 2.1536L12.1693 0.166748Z'
      fill={color}
    />
  </svg>
)

export default SparklesLogo
