import React from "react"
import ArrowLeft from "../../../svg/arrowLeft"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import ArrowBack from "../../../svg/v3/arrowBack"
import LeftArrow from "../../../svg/v3/leftArrow"

const WebViewHeader = ({ headerText }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const navigate = useNavigate()

  return (
    <>
      <div className='v3-web-header-container'>
        <div className='v3-web-header-container-wrapper'>
          <div className='v3-web-header-left-section'>
            <div
              className='v3-web-header-back-icon'
              onClick={() => navigate(-1)}
            >
              <LeftArrow
                isBorderAllowed={false}
                color={theme.v3.cssVars.primaryBase.color1}
              />
            </div>
            <div className='v3-web-header-text'>{headerText}</div>
          </div>
          <div className='v3-web-header-right-section'>
            {t("payLaterWebComponents.header.welcomeText")}{" "}
            {user?.customer?.firstName}
          </div>
        </div>
      </div>
      <DividerV3
        width={"100%"}
        marginBottom={"0px"}
        color={theme.v3.rawColors.primaryBase.color5}
      />
    </>
  )
}

export default WebViewHeader
