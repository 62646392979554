import React from "react"

const Ellipse = ({ color1 = "#FFF4F7", className }) => {
  return (
    <svg
      width='376'
      height='114'
      viewBox='0 0 376 114'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <ellipse cx='188.5' cy='-17.2266' rx='231' ry='131' fill={color1} />
    </svg>
  )
}

export default Ellipse
