const Calender = ({ color = "#0E0E0E", customClassname, height, width }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className={customClassname}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width || "16"}
        height={height || "16"}
        viewBox='0 0 16 16'
        fill='none'
      >
        <path
          d='M12 2.5H11.1667V2C11.1667 1.724 10.9427 1.5 10.6667 1.5C10.3907 1.5 10.1667 1.724 10.1667 2V2.5H5.83333V2C5.83333 1.724 5.60933 1.5 5.33333 1.5C5.05733 1.5 4.83333 1.724 4.83333 2V2.5H4C2.388 2.5 1.5 3.388 1.5 5V12C1.5 13.612 2.388 14.5 4 14.5H12C13.612 14.5 14.5 13.612 14.5 12V5C14.5 3.388 13.612 2.5 12 2.5ZM4 3.5H4.83333V4C4.83333 4.276 5.05733 4.5 5.33333 4.5C5.60933 4.5 5.83333 4.276 5.83333 4V3.5H10.1667V4C10.1667 4.276 10.3907 4.5 10.6667 4.5C10.9427 4.5 11.1667 4.276 11.1667 4V3.5H12C13.0513 3.5 13.5 3.94867 13.5 5V5.5H2.5V5C2.5 3.94867 2.94867 3.5 4 3.5ZM12 13.5H4C2.94867 13.5 2.5 13.0513 2.5 12V6.5H13.5V12C13.5 13.0513 13.0513 13.5 12 13.5Z'
          fill={color}
          stroke={color}
          stroke-width='0.4'
        />
      </svg>
    </div>
  )
}

export default Calender
