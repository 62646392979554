import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import AccountService from "../../../services/AccountService"
import { handleApiAuth } from "../../../utils/auth"
import { DisplayConfig } from "../../../utils/constants"
import { EventName } from "../../../utils/enums"
import { captureEvents } from "../../../utils/functions"
import { Header } from "../../core"
import ViewAllTransactionsLoader from "../../core/SkeletonLoader/ViewAllTransactionLoader"
import { showToast1 } from "../../core/toast"
import Empty from "./empty"
import Transactions from "./transactions"
import FlatList from "../../../nativeBaseReplacements/FlatList"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const initStartDate = moment().subtract(12, "months").format("YYYY-MM-DD")
const initCurrentDate = moment().format("YYYY-MM-DD")
const initTransactions = [
  { type: "TITLE", date: moment().format("YYYY-MM-DD") },
]

const initOffset = 0
const transactionCount = 10

const ShowTransactions_v1 = () => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)

  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const isTransactionsLoadingRef = useRef(false)
  const [apiError, setApiError] = useState(0)

  const preferences = useSelector(state => state.preferences)
  const windowDim = useWindowDimensions()
  const navigate = useNavigate()

  // transactions array contains -
  // 1. Title
  // 2. transactions
  // 3. Dates
  const [transactions, setTransactions] = useState(initTransactions)
  const offset = useRef(initOffset)
  // date till which txns should be loaded
  const startDate = useRef(initStartDate)
  // pointer to current month being fetched
  const currentMonth = useRef(initCurrentDate)
  const location = useLocation()

  useEffect(() => {
    captureEvents({ eventName: EventName.VIEW_TRANSACTION, metadata: {} })
  }, [])

  const nativeToast = useToast()
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  useEffect(() => {
    if (apiError >= 3) {
      toast("Error fetching Transactions")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  const getTransactions = async () => {
    try {
      await handleApiAuth({
        apiId: ApiIds.FETCH_TRANSACTIONS,
        onAuthSuccess: onGetTransactionsAuthSuccess,
        onAuthFailure: getTransactionsAuthFailed,
        onAuthCancel: getTransactionsAuthFailed,
        otpReason: "to fetch billed transaction",
        mpinReason: "to fetch billed transaction",
        toast,
        navigate,
      })
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

  const getTransactionsAuthFailed = async () => {
    setApiError(apiError + 1)
  }
  const onGetTransactionsAuthSuccess = async apiToken => {
    try {
      // get txns for current month with the set offset
      const response = await AccountService.getTransactions(
        user.account.id,
        {
          count: transactionCount,
          offset: offset.current,
          from: moment(currentMonth.current)
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment(currentMonth.current).endOf("month").format("YYYY-MM-DD"),
          checkEmiEligibility: true,
        },
        apiToken,
      )

      const result = response.data

      // if api call successful
      if (result?.success) {
        if (result.data.totalCount !== 0) {
          setTransactions(transactions => [
            ...transactions,
            ...result.data.transactions,
          ])
        }
        // if current month has more txns pending, stay in this month
        if (result.data.hasMore) {
          // increment offset, next call happens for current month with this offset
          offset.current += transactionCount
        } else {
          // else move current month pointer to next month (chronologically previous month)

          // get next month's value
          const nextMonthToFetch = moment(currentMonth.current)
            .subtract(1, "month")
            .format("YYYY-MM-DD")

          // move pointer to next month
          currentMonth.current = nextMonthToFetch
          // reset offset
          offset.current = 0
        }
        navigate("/viewTransactions", { replace: true })
      } else {
        setApiError(apiError + 1)
      }
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)
    if (
      moment(currentMonth.current).format("YYYY-MM") >=
        moment(startDate.current).format("YYYY-MM") &&
      !isTransactionsLoadingRef.current &&
      apiError < 3
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns
      await getTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    } else if (
      moment(currentMonth.current).format("YYYY-MM") <
        moment(startDate.current).format("YYYY-MM") &&
      transactions.length === 1
    ) {
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <div>
        <ViewAllTransactionsLoader />
      </div>
    ) : null
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <Header
            text='Transactions'
            onBack={async () => {
              window.history.go(-1)
            }}
          />
        )

      case "DATE":
        return (
          <div
            className='viewTransaction-transactionDate'
            style={{ color: theme.appTextColor }}
          >
            {moment(item.date).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
              ? "Today"
              : moment(item.date).format("Do MMM YY")}
          </div>
        )

      case "EMPTY":
        return <Empty />

      default:
        return (
          <div style={{ marginTop: index === 1 ? "-16px" : "0px" }}>
            <Transactions
              transaction={item}
              fontFamily={theme.fontFamily}
              dateFormat={preferences.dateFormat}
              timeFormat={preferences.timeFormat}
              isLast={index === transactions.length - 1}
            />
          </div>
        )
    }
  }

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
    startDate.current = initStartDate
    currentMonth.current = initCurrentDate
  }

  return (
    <div
      style={{
        maxWidth: DisplayConfig.MAX_WIDTH,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <div style={{ minHeight: windowDim.height }}>
        {
          <FlatList
            data={transactions}
            renderItem={renderItem}
            keyExtractor={(_transaction, index) => index}
            onEndReached={loadMoreTransaction}
            ListFooterComponent={renderLoader}
            onEndReachedThreshold={0.2}
            refreshing={false}
            onRefresh={handleRefresh}
          />
        }
      </div>
    </div>
  )
}

export default ShowTransactions_v1
