import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "../../../nativeBaseReplacements"

const CopyCardNumber = ({ color }) => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 15 15' size='15px'>
      <svg
        width='15'
        height='15'
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M14.75 6.21875V12.7812C14.75 14.0938 14.0938 14.75 12.7812 14.75H6.21875C4.90625 14.75 4.25 14.0938 4.25 12.7812V6.21875C4.25 4.90625 4.90625 4.25 6.21875 4.25H12.7812C14.0938 4.25 14.75 4.90625 14.75 6.21875ZM10.8125 1.25C10.8125 0.9395 10.5605 0.6875 10.25 0.6875H3.21875C1.586 0.6875 0.6875 1.586 0.6875 3.21875V10.25C0.6875 10.5605 0.9395 10.8125 1.25 10.8125C1.5605 10.8125 1.8125 10.5605 1.8125 10.25V3.21875C1.8125 2.21975 2.21975 1.8125 3.21875 1.8125H10.25C10.5605 1.8125 10.8125 1.5605 10.8125 1.25Z'
          fill={color || "#A6B2CD"}
        />
      </svg>
    </Icon>
  )
}

export default CopyCardNumber
