import React from "react"
import noTransactionImage from "../../assets/images/v3/noTransactions.svg"

const ZeroStateTransactionsV3 = ({ text, subText }) => {
  return (
    <div className='v3-no-transaction-section'>
      <img src={noTransactionImage} alt='no transactions image' />
      <div>
        <div className='v3-no-transaction-text'>{text}</div>
        {subText && <div className='v3-no-transaction-sub-text'>{subText}</div>}
      </div>
    </div>
  )
}

export default ZeroStateTransactionsV3
