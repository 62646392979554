import React from "react"
import { useSelector } from "react-redux"
import { EMI_PLAN_VIEW, PwaVersions } from "../../../../utils/enums"
import EMIPlans_v1 from "./EMIPlans_v1"
import EMIPlans_v2 from "./EMIPlans_v2"
import EMIPlans_v3 from "./EMIPlans_v3"

const EMIPlans = () => {
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const isListEmiView = screen?.emi?.emiPlanView === EMI_PLAN_VIEW.LIST
  return config?.version === PwaVersions.V3 ? (
    <EMIPlans_v3 />
  ) : isListEmiView ? (
    <EMIPlans_v1 />
  ) : (
    <EMIPlans_v2 />
  )
}

export default EMIPlans
