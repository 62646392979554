import React from "react";
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js";

function AuthLoader() {
  return (
    <>
      <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        br='0'
        height="56px"
      >
        <TextWrapperSkeleton height='12px' width='200px' />
      </CardSkeleton>
      <CardSkeleton height='220px' margin='20px auto' width='88%' padding='15px'>
        <TextWrapperSkeleton width='60px' />
        <TextWrapperSkeleton margin='20px  0px 60px 0px' width='200px' />
        <CardSkeleton width="100%" display="flex" justifyContent="space-around">
            <TextWrapperSkeleton width="65px" height="40x"/>
            <TextWrapperSkeleton width="65px" height="40x"/>
            <TextWrapperSkeleton width="65px" height="40x"/>
            <TextWrapperSkeleton width="65px" height="40x"/>
        </CardSkeleton>
        <TextWrapperSkeleton width="150px" margin="25px 0px"/>
      </CardSkeleton>
      <CardSkeleton height="40px" margin="auto auto 10px auto" width="85%" display="flex" justifyContent="center" alignItems="center">
        <TextWrapperSkeleton width="100px"/>
      </CardSkeleton>
    </>
  )
}

export default AuthLoader;
