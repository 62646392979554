const CrossButton = ({ backgroundColor = "#F1F1F1", color = "#0E0E0E" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
    >
      <circle cx='14' cy='14' r='14' fill={backgroundColor} />
      <path
        d='M17.2311 11.3076L10.7695 17.7692'
        stroke={color}
        stroke-width='1.41423'
        stroke-linecap='round'
      />
      <path
        d='M17.2311 17.7693L10.7695 11.3077'
        stroke={color}
        stroke-width='1.41423'
        stroke-linecap='round'
      />
    </svg>
  )
}

export default CrossButton
