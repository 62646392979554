import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import { Button, Text } from "../../nativeBaseReplacements"

const btnStyles = {
  alignItems: "center",
  justifyContent: "center",
  borderWidth: "2px",
  py: "14px",
  borderRadius: "6px",
}

const ColorButton = props => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)

  const backgroundColor = (() => {
    if (!props.isDisable) {
      return (
        props?.primaryColor ||
        (config?.version === PwaVersions.V3
          ? theme.v3.rawColors.primaryCta.backgroundColor
          : theme.color1)
      )
    }
    if (config?.version === PwaVersions.V2_1) {
      return "#A7A7A7"
    }
    if (config?.version === PwaVersions.V3) {
      return theme.v3.rawColors.tertiaryNeutral.color2
    }
    return `${theme.color1}:alpha.70` || `${props.primaryColor}:alpha.70`
  })()

  const borderColor = (() => {
    if (props.isDisable && config?.version === PwaVersions.V2_1) {
      return "#A7A7A7"
    }
    return (
      props.primaryColor ||
      (config?.version === PwaVersions.V3
        ? theme.v3.rawColors.primaryCta.strokeColor
        : theme.color1)
    )
  })()

  const color = (() => {
    if (props.color) {
      return props.color
    }
    if (
      config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1
    ) {
      return props.secondaryColor || theme.color2
    }
    if (config?.version === PwaVersions.V3) {
      return props.secondaryColor || theme.v3.rawColors.primaryCta.textColor
    }
    return "#FFF"
  })()

  return (
    <Button
      borderColor={borderColor}
      {...props}
      {...btnStyles}
      width={props.width || "90%"}
      isDisabled={props.isDisable}
      borderWidth={
        config?.version === PwaVersions.V2 || config?.version === PwaVersions.V3
          ? "0px"
          : "2px"
      }
      color={color}
      backgroundColor={backgroundColor}
      opacity={1}
      borderRadius={config?.version === PwaVersions.V3 ? "12px" : "6px"}
      height={config?.version === PwaVersions.V3 ? "42px" : "auto"}
      onPress={() => {
        if (!props.isDisable) return props.onPress()
      }}
      spinnerColor={"#FFFFFF"}
      position='relative'
    >
      <Text
        color={color}
        fontFamily={theme.fontFamily}
        fontSize={props.fontSize || "16px"}
        fontWeight='600'
      >
        {props.text}
      </Text>
      {props?.redirectIcon && (
        <div className='v3-color-button-redirection-icon'>
          <img src={props?.redirectIcon} />
        </div>
      )}
    </Button>
  )
}

const WhiteButton = props => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const color =
    props?.isDisable && config?.version === PwaVersions.V3
      ? "rgba(116, 116, 116, 0.5)"
      : props.primaryColor || config?.version === PwaVersions.V3
        ? theme.v3.rawColors.secondaryCta.textColor
        : theme.color1
  return (
    <Button
      onPress={() => props.onPress()}
      mt='8px'
      bgColor={
        config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1
          ? theme.color2
          : config?.version === PwaVersions.V3
            ? theme.v3.rawColors.secondaryCta?.backgroundColor
            : "#fff"
      }
      borderColor={
        props.isDisable && config?.version === PwaVersions.V2_1
          ? "#A7A7A7"
          : props.isDisable && config?.version === PwaVersions.V3
            ? "rgba(116, 116, 116, 0.5)"
            : props.primaryColor || config?.version === PwaVersions.V3
              ? theme.v3.rawColors.secondaryCta?.strokeColor
              : theme.color1
      }
      {...btnStyles}
      borderWidth={
        config?.version === PwaVersions.V2
          ? "0px"
          : config?.version === PwaVersions.V3
            ? "1px"
            : "2px"
      }
      width={props.width || "90%"}
      borderRadius={config?.version === PwaVersions.V3 ? "12px" : "6px"}
      height={config?.version === PwaVersions.V3 ? "42px" : "auto"}
      spinnerColor={color}
    >
      <Text
        color={color}
        fontFamily={theme.fontFamily}
        fontSize='16px'
        fontWeight={config?.version === PwaVersions.V3 ? "500" : "600"}
      >
        {props.text}
      </Text>
    </Button>
  )
}

export { ColorButton, WhiteButton }
