import React from "react"

const CardDiscard = ({ color = "#E72B2B", bgColor = "#FEF2F2" }) => (
  <svg
    width='100%'
    height='100%'
    viewBox='0 0 40 41'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <rect y='0.924927' width='40' height='40' rx='20' fill={bgColor} />
    <path
      d='M25.0003 22.5083C23.1587 22.5083 21.667 24 21.667 25.8416C21.667 27.6833 23.1587 29.175 25.0003 29.175C26.842 29.175 28.3337 27.6833 28.3337 25.8416C28.3337 24 26.842 22.5083 25.0003 22.5083ZM26.1253 26.3833C26.292 26.5417 26.292 26.8083 26.1253 26.9666C26.0503 27.0499 25.942 27.0916 25.8337 27.0916C25.7253 27.0916 25.617 27.0499 25.542 26.9666L25.0003 26.425L24.4587 26.9666C24.3837 27.0499 24.2753 27.0916 24.167 27.0916C24.0587 27.0916 23.9503 27.0499 23.8753 26.9666C23.7087 26.8083 23.7087 26.5417 23.8753 26.3833L24.417 25.8416L23.8753 25.2999C23.7087 25.1416 23.7087 24.875 23.8753 24.7167C24.0337 24.55 24.3003 24.55 24.4587 24.7167L25.0003 25.2583L25.542 24.7167C25.7003 24.55 25.967 24.55 26.1253 24.7167C26.292 24.875 26.292 25.1416 26.1253 25.2999L25.5837 25.8416L26.1253 26.3833ZM11.667 19.8V24.175C11.667 25.8416 12.5003 26.675 14.167 26.675H20.192C20.3337 26.675 20.4503 26.5583 20.4503 26.4166C20.4253 26.225 20.417 26.0333 20.417 25.8416C20.417 23.3166 22.4753 21.2583 25.0003 21.2583C25.4587 21.2583 25.892 21.325 26.3087 21.45C26.342 21.4583 26.3753 21.4666 26.4087 21.4666C26.5503 21.4666 26.667 21.3583 26.667 21.2166V19.8H11.667ZM17.5003 23.9666H15.0003C14.6587 23.9666 14.3753 23.6833 14.3753 23.3416C14.3753 23 14.6587 22.7166 15.0003 22.7166H17.5003C17.842 22.7166 18.1253 23 18.1253 23.3416C18.1253 23.6833 17.842 23.9666 17.5003 23.9666ZM24.167 15.0083H14.167C12.5003 15.0083 11.667 15.8416 11.667 17.5083V18.55H26.667V17.5083C26.667 15.8416 25.8337 15.0083 24.167 15.0083Z'
      fill={color}
    />
  </svg>
)

export default CardDiscard
