import { Player } from "@lottiefiles/react-lottie-player"
import React, { useState } from "react"
import lottie from "../../assets/images/Hourglass.json"
import HyperfaceLogo from "../../assets/images/HyperfaceLogo.svg"
import bankDefaultIcon from "../../assets/images/bankDefaultIcon.svg"
import banner from "../../assets/images/preLoaderTransparent.gif"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"

const centeringStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "transparent",
}

const preloaderImageFromLocalStorage = localStorage.getItem("preLoaderBanner")
const Preloader_v2 = () => {
  const windowDim = useWindowDimensions()
  const [imgLoaded, setImgLoaded] = useState(false)
  const handleImageLoaded = () => {
    setImgLoaded(true)
  }

  return (
    <div
      className='preloader'
      style={{
        ...centeringStyle,
        minHeight: windowDim.height,
        backgroundColor: localStorage.getItem("backgroundColor")
          ? localStorage.getItem("backgroundColor")
          : "#FFFFFF",
      }}
    >
      <div
        className='preloader-banner'
        style={{
          ...centeringStyle,
          marginTop: "10%",
        }}
      >
        {preloaderImageFromLocalStorage ? (
          <>
            {localStorage.getItem("version") === "v2_1" ||
            preloaderImageFromLocalStorage?.split(".")?.pop() == "json" ||
            preloaderImageFromLocalStorage?.split(".")?.pop() == "lottie" ? (
              <Player
                src={lottie}
                className='player'
                loop
                autoplay
                style={{ height: "300px", width: "300px" }}
              />
            ) : (
              <img
                className='preloader-banner-img'
                style={{
                  width: "300px",
                  height: "300px",
                  display: imgLoaded ? "block" : "none",
                }}
                src={preloaderImageFromLocalStorage}
                onLoad={handleImageLoaded}
              />
            )}
          </>
        ) : (
          <img
            className='preloader-default-banner-img'
            style={{
              width: "200px",
              height: "200px",
              marginBottom: "30%",
            }}
            src={banner}
          />
        )}
      </div>
      <div
        className='preloader-logos'
        style={{ ...centeringStyle, marginTop: "32px" }}
      >
        {localStorage.getItem("issuerImage") !== null && (
          <div className='preloader-issuer' style={{ ...centeringStyle }}>
            <div
              className='preloader-issuer-name'
              style={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#4F4F4F",
                width: "60%",
              }}
            >
              {`Hold on! Establishing a secure connection with ${
                localStorage.getItem("issuerBankName")
                  ? localStorage.getItem("issuerBankName")
                  : "the bank"
              }`}
            </div>
            <div
              className='preloader-issuer-logo-container'
              style={{
                marginTop: "12px",
              }}
            >
              {localStorage.getItem("issuerImage") !== null ? (
                <img
                  className='preloader-issuer-logo'
                  style={{
                    width: "100px",
                    height: "43px",
                  }}
                  src={localStorage.getItem("issuerImage")}
                />
              ) : (
                <img
                  className='preloader-issuer-default-logo'
                  style={{
                    width: "36px",
                    height: "36px",
                  }}
                  src={bankDefaultIcon}
                />
              )}
            </div>
          </div>
        )}
        {localStorage.getItem("hfStatus") !== null && (
          <div className='preloader-hyperfaceLogo-container'>
            <div
              className='preloader-hyperfaceLogo-text'
              style={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "12px",
                lineHeight: "16px",
                color: "#4F4F4F",
              }}
            >
              {localStorage.getItem("issuerImage") !== null
                ? `Powered by`
                : `Establishing a secure link Powered by`}
            </div>
            <img
              className='preloader-hyperfaceLogo-img'
              style={{
                width: "100px",
                height: "28px",
                marginTop: "12px",
              }}
              src={HyperfaceLogo}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Preloader_v2
