import { useSelector } from "react-redux"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import ZeroStateTransactionsV3 from "../../../core/ZeroStateTransactionsV3"
import TokenisationLock from "../../../svg/v3/tokenisationLock"
import { useNavigate } from "react-router-dom"
import hfLogoColored from "../../../../assets/images/v3/active/hfLogoColored.svg"
import { useTranslation } from "react-i18next"

const SavedCardsEmpty = () => {
  const [theme, screen] = useSelector(state => [
    state.theme,
    state.screen,
    state.config,
  ])
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div>
      <Header
        text='Manage Saved Cards'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='v3-saved-cards-empty-container'>
        <div className='v3-saved-cards-empty-img-container'>
          <ZeroStateTransactionsV3 />
          <div className='v3-saved-cards-empty-text'>
            Card not saved with any merchant
          </div>
        </div>

        <div className='v3-tokenisation-hf-branding-container v3-tokenisation-empty-hf-branding-container '>
          <div className='v3-tokenization-hf-branding'>
            <div className='v3-home-hf-branding-text v3-tokenization-hf-branding-text  '>
              {t("loader.poweredBy")}
            </div>
            <div className='v3-home-hf-branding-logo'>
              <img src={hfLogoColored} />
            </div>
          </div>
        </div>

        <div className='v3-saved-cards-empty-divider'>
          <div className='v3-saved-cards-empty-line-container'>
            <div>
              <TokenisationLock />
            </div>

            <div className='v3-saved-cards-empty-line'>
              Save your card at your favourite merchant and enjoy seamless
              checkout experience
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", flexDirection: "column" }}
          className='v3-customerSupport-CTA'
        >
          <ColorButton
            text={"Add Card to Merchant App"}
            margin='auto'
            onPress={() => navigate("/ManageCard/Cards/Add")}
            primaryColor={theme.v3.rawColors.secondary.color1}
            color={theme.v3.rawColors.leadingWhite}
            width='100%'
          />
        </div>
      </div>
    </div>
  )
}

export default SavedCardsEmpty
