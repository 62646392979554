import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { ColorButton } from "../../../core/buttons"
import {
  CardOrientation,
  HEADER_HEIGHT_IN_PX,
} from "../../../../utils/constants"
import { Header } from "../../../core"
import { captureEvents, goToRedirectUrl } from "../../../../utils/functions"
import { showCancelButton } from "../../../../utils/auth"
import { EventName, PwaVersions } from "../../../../utils/enums"
import { ScrollView } from "../../../../nativeBaseReplacements"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const SetMpinIntro = ({ setMpinConfig, onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const isV2 =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1

  useEffect(() => {
    captureEvents({ eventName: EventName.WELCOME_PAGE, metadata: {} })
  }, [])

  return (
    <div
      style={{
        minHeight: isV2 ? windowDimensions.height : null,
        backgroundColor: isV2 ? theme.backgroundColor : null,
      }}
    >
      <ScrollView>
        <Header
          text={`Setup ${mpinWord}`}
          onBack={async () => {
            showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
          }}
        />

        <div
          className='mpinIntro'
          style={{
            minHeight:
              windowDimensions.height - (isV2 ? HEADER_HEIGHT_IN_PX : 0) - 20,
          }}
        >
          <div className='mpinIntro-container'>
            {setMpinConfig.introTitle1 && (
              <div
                className='mpinIntro-title-1'
                style={{ color: isV2 ? theme.appTextColor : null }}
              >
                {setMpinConfig.introTitle1}
              </div>
            )}
            {setMpinConfig.introTitle2 && (
              <div
                className='mpinIntro-title-2'
                style={{ color: isV2 ? theme.appTextColor : null }}
              >
                {setMpinConfig.introTitle2}
              </div>
            )}
            {setMpinConfig.introImageUrl &&
              (theme.cardOrientation === CardOrientation.VERTICAL ? (
                <img
                  className='mpinIntro-img-vertical'
                  src={setMpinConfig.introImageUrl}
                  alt='Intro image'
                />
              ) : (
                <img
                  className='mpinIntro-img-horizontal'
                  src={setMpinConfig.introImageUrl}
                  alt='Intro image'
                />
              ))}
            {setMpinConfig.introParagraph1 && (
              <div
                className='mpinIntro-introPara-1'
                style={{ color: isV2 ? theme.appTextColor : null }}
              >
                {setMpinConfig.introParagraph1}
              </div>
            )}
            {setMpinConfig.introParagraph2 && (
              <div
                className='mpinIntro-introPara-2'
                style={{ color: isV2 ? theme.appTextColor : null }}
              >
                {setMpinConfig.introParagraph2}
              </div>
            )}
            {setMpinConfig.introSteps && (
              <div className='mpinIntro-introSteps-container'>
                {setMpinConfig.introSteps?.split("\r\n").map((point, index) => (
                  <div
                    className='mpinIntro-introStep'
                    style={{ color: isV2 ? theme.appTextColor : null }}
                  >
                    {index + 1}. {point}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* <View display='flex' flex='1' flexDirection='column'></View> */}
          <div className='mpinIntro-cta-container'>
            <div className='mpinIntro-cta'>
              <ColorButton
                mx='auto'
                text='Get Started'
                onPress={async () => onSubmit()}
              />
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  )
}

export default SetMpinIntro
