const Hourglass_1 = ({ color = "#E72B2B" }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='15'
        height='14'
        viewBox='0 0 15 14'
        fill='none'
      >
        <path
          d='M11.875 3.65273V2.62492C11.875 1.9815 11.3517 1.45825 10.7083 1.45825H4.29167C3.64825 1.45825 3.125 1.9815 3.125 2.62492V3.64931C3.125 4.77865 3.51704 5.05922 4.15637 5.42381L6.92364 6.99992L4.15637 8.57603C3.51704 8.94061 3.125 9.22061 3.125 10.3505V11.3749C3.125 12.0183 3.64825 12.5416 4.29167 12.5416H10.7083C11.3517 12.5416 11.875 12.0183 11.875 11.3749V10.3471C11.875 9.22127 11.4853 8.94061 10.8489 8.57603L8.09914 6.99992L10.8489 5.42381C11.4853 5.05922 11.875 4.77798 11.875 3.65273ZM10.5589 9.08246C11.0769 9.37937 11.2917 9.50244 11.2917 10.3471V11.3749C11.2917 11.6963 11.0303 11.9583 10.7083 11.9583H4.29167C3.96967 11.9583 3.70833 11.6963 3.70833 11.3749V10.3505C3.70833 9.50294 3.92356 9.37993 4.44448 9.0836L7.51282 7.33588L10.5589 9.08246ZM7.51225 6.66396L4.44448 4.91624C3.92298 4.61932 3.70776 4.4969 3.70776 3.64931V2.62492C3.70776 2.3035 3.9691 2.04159 4.2911 2.04159H10.7078C11.0298 2.04159 11.2911 2.3035 11.2911 2.62492V3.65273C11.2911 4.4974 11.0764 4.62045 10.5578 4.91795L7.51225 6.66396Z'
          fill={color}
          stroke={color}
        />
      </svg>
    </div>
  )
}

export default Hourglass_1
