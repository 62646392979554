import React from "react"
import Calender from "../../svg/v3/Calender"
import ArrowDown from "../../svg/arrowDown"
import { useTranslation } from "react-i18next"

const CategorySplitHeader = ({ setIsDateBottomSheetOpen }) => {
  const { t } = useTranslation()
  return (
    <div className='v3-spend-analytics-category-split-header'>
      <div className='v3-spend-analytics-category-split-header-text'>
        <div className='v3-spend-analytics-category-split-primary-header-text'>
          {t("spendAnalytics.categorySplit.categorySplitText")}
        </div>
        <div className='v3-spend-analytics-category-split-secondary-header-text'>
          {t("spendAnalytics.categorySplit.categoryHeader")}
        </div>
      </div>
      <div
        className='v3-spend-analytics-category-split-filter-tab'
        onClick={() => setIsDateBottomSheetOpen(prev => !prev)}
      >
        <div className='v3-spend-analytics-category-split-filter-tab-icon-and-text'>
          <div className='v3-spend-analytics-category-split-filter-icon'>
            <Calender />
          </div>
          <div className='v3-spend-analytics-category-split-filter-text'>
            {t("spendAnalytics.categorySplit.dateFilterLabel")}
          </div>
        </div>
        <div className='v3-spend-analytics-category-split-filter-dropdown'>
          <ArrowDown size={"12px"} />
        </div>
      </div>
    </div>
  )
}

export default CategorySplitHeader
