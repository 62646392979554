const styles = {
  mainContainer: {
    width: "100%",
    background: "#515151",
  },
  back: {
    cursor: "pointer",
    size: "28px",
    color: "#fff",
  },
  header: {
    mt: "16px",
    ml: "16px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  QrReader: {
    mx: "10%",
    mt: "40%",
    background: "#fff",
    borderRadius: "8px",
    borderWidth: "6px",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },

  infoText: {
    mt: "40px",
    width: "75%",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#fff",
    textAlign: "center",
  },
}

export default styles
