import Service from "../services/Service"
import { Envs, LogLevels } from "./enums"

const env = process.env.REACT_APP_ENV

const isConsoleLoggingEnabled = [
  Envs.DEV,
  Envs.DEV_MOBILE,
  Envs.UAT,
  Envs.SANDBOX,
].includes(env)
const isServerLoggingEnabled = [Envs.UAT, Envs.SANDBOX, Envs.PROD].includes(env)

const log = (level, initLog) => {
  let finalLog =
    typeof initLog !== "object"
      ? initLog
      : {
          ...initLog,
          env: env.toLowerCase(),
          service: "pwa",
          timestamp: new Date().toISOString(),
          level,
        }

  if (isConsoleLoggingEnabled) {
    switch (level) {
      case LogLevels.INFO:
        console.info(finalLog)
        break
      case LogLevels.ERROR:
        console.error(finalLog)
        break
      default:
        break
    }
  }

  if (isServerLoggingEnabled) {
    try {
      Service.post(`/log`, { level, log: finalLog })
    } catch (error) {
      if (isConsoleLoggingEnabled) {
        console.error(
          `An exception occurred while sending log of level ${level} and content ${finalLog} to server. Error: \n`,
          error,
        )
      }
    }
  }
}

const logger = {
  info: initLog => {
    log(LogLevels.INFO, initLog)
  },
  error: initLog => {
    log(LogLevels.ERROR, initLog)
  },
}

export default logger
