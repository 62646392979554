const RedeemedRewardIcon = ({ customClassnames, color = "#1965CF" }) => {
  return (
    <div className={`v3-reward-redeemed-icon ${customClassnames}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M3.00146 11.8C2.97446 11.8 2.94651 11.799 2.91851 11.796C2.50651 11.751 2.20951 11.38 2.25451 10.969C2.61751 7.65199 4.61949 4.79503 7.60949 3.32703C7.84249 3.21303 8.11748 3.22701 8.33648 3.36401C8.55648 3.50101 8.69048 3.74198 8.69048 4.00098V7.05103C8.69048 7.46503 8.35448 7.80103 7.94048 7.80103C7.52648 7.80103 7.19048 7.46503 7.19048 7.05103V5.31006C5.26748 6.64806 4.00645 8.74896 3.74645 11.132C3.70445 11.515 3.37846 11.8 3.00146 11.8ZM16.3915 20.673C19.3815 19.206 21.3835 16.349 21.7455 13.031C21.7915 12.619 21.4935 12.249 21.0815 12.204C20.6735 12.16 20.3005 12.456 20.2555 12.868C19.9945 15.251 18.7345 17.3519 16.8105 18.6899V16.949C16.8105 16.535 16.4745 16.199 16.0605 16.199C15.6465 16.199 15.3105 16.535 15.3105 16.949V19.999C15.3105 20.258 15.4445 20.499 15.6645 20.636C15.7855 20.711 15.9225 20.749 16.0605 20.749C16.1735 20.75 16.2865 20.725 16.3915 20.673ZM12.0005 7C12.0005 9.209 13.7915 11 16.0005 11C18.2095 11 20.0005 9.209 20.0005 7C20.0005 4.791 18.2095 3 16.0005 3C13.7915 3 12.0005 4.791 12.0005 7ZM4.00048 17C4.00048 19.209 5.79148 21 8.00048 21C10.2095 21 12.0005 19.209 12.0005 17C12.0005 14.791 10.2095 13 8.00048 13C5.79148 13 4.00048 14.791 4.00048 17Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default RedeemedRewardIcon
