import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import Blocked from "./Blocked"
import BlockedScreen from "./v3/BlockedScreen"

const Main = () => {
  const config = useSelector(state => state.config)
  if (config.version !== PwaVersions.V3) return <Blocked />
  return <BlockedScreen />
}

export default Main
