const ArrowRight = ({ color = "#1965CF", customClassname = "" }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "fit-content",
      }}
      className={customClassname}
    >
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          opacity='0.4'
          d='M20 13H4C3.447 13 3 12.552 3 12C3 11.448 3.447 11 4 11H20C20.553 11 21 11.448 21 12C21 12.552 20.553 13 20 13Z'
          fill={color}
        />
        <path
          d='M16 17C15.744 17 15.488 16.9021 15.293 16.7071C14.902 16.3161 14.902 15.684 15.293 15.293L18.586 12L15.293 8.70707C14.902 8.31607 14.902 7.68401 15.293 7.29301C15.684 6.90201 16.3161 6.90201 16.7071 7.29301L20.7071 11.293C21.0981 11.684 21.0981 12.3161 20.7071 12.7071L16.7071 16.7071C16.5121 16.9021 16.256 17 16 17Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default ArrowRight
