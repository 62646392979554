import React from "react"

const AlarmClock = ({ color = "#1965CF", backgroundColor = "#F1F6FC" }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 40 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        y='0.924927'
        width='40'
        height='40'
        rx='20'
        fill={backgroundColor}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6082 14.6166L12.9415 16.2833C12.8198 16.405 12.6598 16.4666 12.4998 16.4666C12.3398 16.4666 12.1798 16.4058 12.0581 16.2833C11.814 16.0391 11.814 15.6433 12.0581 15.3991L13.7248 13.7324C13.969 13.4883 14.3648 13.4883 14.609 13.7324C14.8532 13.9766 14.8523 14.3725 14.6082 14.6166ZM27.9372 15.3949L26.2706 13.7616C26.0239 13.52 25.6289 13.5241 25.3856 13.7708C25.1447 14.0175 25.1491 14.4133 25.3949 14.655L27.0616 16.2883C27.1832 16.4075 27.3415 16.4666 27.499 16.4666C27.6607 16.4666 27.8231 16.4041 27.9456 16.2791C28.1872 16.0325 28.1831 15.6366 27.9372 15.3949ZM23.8357 27.1225L25.4431 28.7341C25.6865 28.9782 25.6865 29.3741 25.4415 29.6183C25.3198 29.7399 25.1598 29.8008 25.0006 29.8008C24.8406 29.8008 24.6798 29.7391 24.5581 29.6166L22.7098 27.7632C21.8814 28.1324 20.9664 28.3416 20.0014 28.3416C19.0364 28.3416 18.1214 28.1324 17.2931 27.7632L15.4439 29.6166C15.3223 29.7383 15.1614 29.7999 15.0014 29.7999C14.8423 29.7999 14.6822 29.7391 14.5606 29.6175C14.3156 29.3741 14.3156 28.9774 14.5589 28.7333L16.1664 27.1215C14.4556 25.9149 13.3356 23.9275 13.3356 21.6741C13.3356 17.9925 16.3206 15.0075 20.0023 15.0075C23.6839 15.0075 26.6689 17.9925 26.6689 21.6741C26.6664 23.9275 25.5465 25.9159 23.8357 27.1225ZM22.5248 21.0392C22.769 20.795 22.769 20.3991 22.5248 20.155C22.2807 19.9108 21.8848 19.9108 21.6406 20.155L19.3048 22.4908L18.3574 21.5441C18.1132 21.3 17.7173 21.3 17.4732 21.5441C17.229 21.7883 17.229 22.1842 17.4732 22.4283L18.8623 23.8175C18.984 23.9392 19.144 24.0008 19.304 24.0008C19.464 24.0008 19.624 23.94 19.7457 23.8175L22.5248 21.0392Z'
        fill={color}
      />
    </svg>
  )
}

export default AlarmClock
