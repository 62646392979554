const Bank = ({size,color}) => {
  return (
    <div style={{width:size}}>
       <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
       <path d="M27.094 25.5769H26.7815V24.0144C26.7815 22.6644 25.9427 21.8269 24.594 21.8269H24.2815V14.9519H24.594C25.9427 14.9519 26.7815 14.1144 26.7815 12.7644V9.56555C26.7815 8.7318 26.3177 7.98188 25.5715 7.60938L17.1015 3.37445C16.3127 2.98195 15.3728 2.98195 14.5865 3.37445L6.11652 7.60938C5.37027 7.98188 4.90649 8.7318 4.90649 9.56555V12.7644C4.90649 14.1144 5.74524 14.9519 7.09399 14.9519H7.40649V21.8269H7.09399C5.74524 21.8269 4.90649 22.6644 4.90649 24.0144V25.5769H4.59399C4.07649 25.5769 3.65649 25.9969 3.65649 26.5144C3.65649 27.0319 4.07649 27.4519 4.59399 27.4519H27.094C27.6115 27.4519 28.0315 27.0319 28.0315 26.5144C28.0315 25.9969 27.6115 25.5769 27.094 25.5769ZM22.4065 21.8269H19.2815V14.9519H22.4065V21.8269ZM14.2815 21.8269V14.9519H17.4065V21.8269H14.2815ZM6.82407 13.043C6.82282 13.043 6.82282 13.043 6.82407 13.043C6.81782 13.033 6.78149 12.9594 6.78149 12.7644V9.56555C6.78149 9.4468 6.84767 9.33946 6.95392 9.28571L15.4239 5.05078C15.6902 4.91828 15.9978 4.91828 16.2641 5.05078L24.7341 9.28571C24.8403 9.33821 24.9065 9.44555 24.9065 9.56555V12.7644C24.9065 12.9594 24.8703 13.032 24.8728 13.0357C24.8628 13.0407 24.789 13.0769 24.594 13.0769H7.09399C6.90524 13.0769 6.83032 13.043 6.82407 13.043ZM9.28149 14.9519H12.4065V21.8269H9.28149V14.9519ZM6.78149 24.0144C6.78149 23.8194 6.81772 23.7469 6.81522 23.7431C6.82522 23.7381 6.89899 23.7019 7.09399 23.7019H24.594C24.8215 23.7019 24.8665 23.7358 24.8653 23.7358C24.8703 23.747 24.9065 23.8194 24.9065 24.0144V25.5769H6.78149V24.0144ZM14.2815 9.01441C14.2815 8.15191 14.9815 7.45191 15.844 7.45191C16.7065 7.45191 17.4065 8.15191 17.4065 9.01441C17.4065 9.87691 16.7065 10.5769 15.844 10.5769C14.9815 10.5769 14.2815 9.87691 14.2815 9.01441Z" fill="#2680EA"/>
       </svg>
    </div>
  )
}

export default Bank