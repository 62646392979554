
const Repeat = ({color="#323232",size}) => {
  return (
    <div style={{width:size}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path d="M2.58832 3.01074H10.6617C11.63 3.01074 12.4117 3.79241 12.4117 4.76074V6.69741" stroke={color} stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.43165 1.16699L2.58832 3.01031L4.43165 4.85366" stroke={color} stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.4117 10.9904H4.33832C3.36998 10.9904 2.58832 10.2087 2.58832 9.24038V7.30371" stroke={color} stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.5683 12.8341L12.4117 10.9908L10.5683 9.14746" stroke={color} stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
  )
}

export default Repeat