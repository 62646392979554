const Categories = ({ color = "#0E0E0E" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
    >
      <path
        opacity='0.4'
        d='M6 4.5H2.25C1.836 4.5 1.5 4.164 1.5 3.75C1.5 3.336 1.836 3 2.25 3H6C6.414 3 6.75 3.336 6.75 3.75C6.75 4.164 6.414 4.5 6 4.5Z'
        fill={color}
      />
      <path
        d='M6 6C5.586 6 5.25 5.664 5.25 5.25V2.25C5.25 1.836 5.586 1.5 6 1.5C6.414 1.5 6.75 1.836 6.75 2.25V5.25C6.75 5.664 6.414 6 6 6Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M15.75 4.5H9C8.586 4.5 8.25 4.164 8.25 3.75C8.25 3.336 8.586 3 9 3H15.75C16.164 3 16.5 3.336 16.5 3.75C16.5 4.164 16.164 4.5 15.75 4.5Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M9 9.75H2.25C1.836 9.75 1.5 9.414 1.5 9C1.5 8.586 1.836 8.25 2.25 8.25H9C9.414 8.25 9.75 8.586 9.75 9C9.75 9.414 9.414 9.75 9 9.75Z'
        fill={color}
      />
      <path
        d='M12 11.25C11.586 11.25 11.25 10.914 11.25 10.5V7.5C11.25 7.086 11.586 6.75 12 6.75C12.414 6.75 12.75 7.086 12.75 7.5V10.5C12.75 10.914 12.414 11.25 12 11.25Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M15.75 9.75H12C11.586 9.75 11.25 9.414 11.25 9C11.25 8.586 11.586 8.25 12 8.25H15.75C16.164 8.25 16.5 8.586 16.5 9C16.5 9.414 16.164 9.75 15.75 9.75Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M6 15H2.25C1.836 15 1.5 14.664 1.5 14.25C1.5 13.836 1.836 13.5 2.25 13.5H6C6.414 13.5 6.75 13.836 6.75 14.25C6.75 14.664 6.414 15 6 15Z'
        fill={color}
      />
      <path
        d='M6 16.5C5.586 16.5 5.25 16.164 5.25 15.75V12.75C5.25 12.336 5.586 12 6 12C6.414 12 6.75 12.336 6.75 12.75V15.75C6.75 16.164 6.414 16.5 6 16.5Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M15.75 15H9C8.586 15 8.25 14.664 8.25 14.25C8.25 13.836 8.586 13.5 9 13.5H15.75C16.164 13.5 16.5 13.836 16.5 14.25C16.5 14.664 16.164 15 15.75 15Z'
        fill={color}
      />
    </svg>
  )
}

export default Categories
