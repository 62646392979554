import React from "react"

import emptyTransaction from "../../../assets/images/emptyTransaction.svg"

const Empty = props => {
  return (
    <div className="emptyTransaction"
    >
      <div className="emptyTransaction-content" >
        <div className="emptyTransaction-content-img"
          style={{backgroundColor: props.color3}}
        >
          <img src={emptyTransaction}/>
        </div>
        <div className="emptyTransaction-content-text-container">
          <div className="emptyTransaction-content-text-1"
            style={{fontFamily: props.fontFamily}}
            
          >
            No transactions found.
          </div>
          <div className="emptyTransaction-content-text-2" style={{fontFamily: props.fontFamily}}
          >
            Start using your card and keep a track of your transactions here.
          </div>
        </div>
      </div>
    </div>
  )
}

export default Empty
