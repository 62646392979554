// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import styled from "styled-components"

const WarningOutlineIcon = ({ color, size = "md" }) => {
  const sizeConversion = {
    xs: 16,
    sm: 24,
    md: 32,
    lg: 40,
    xl: 48,
    "2xl": 56,
    "3xl": 64,
  }
  return (
    <svg
      viewBox={`0 0 16 16`}
      stroke=''
      role='img'
      color={color}
      style={{
        height: `${sizeConversion[size]}px`,
        width: `${sizeConversion[size]}px`,
      }}
    >
      <g>
        <path
          fill='currentColor'
          d='M8 16C6.41775 16 4.87103 15.5308 3.55544 14.6518C2.23985 13.7727 1.21447 12.5233 0.608967 11.0615C0.00346627 9.59966 -0.15496 7.99113 0.153721 6.43928C0.462403 4.88743 1.22433 3.46197 2.34315 2.34315C3.46197 1.22433 4.88743 0.462403 6.43928 0.153721C7.99113 -0.15496 9.59966 0.00346627 11.0615 0.608967C12.5233 1.21447 13.7727 2.23985 14.6518 3.55544C15.5308 4.87103 16 6.41775 16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16ZM8 14.4C9.2658 14.4 10.5032 14.0246 11.5556 13.3214C12.6081 12.6182 13.4284 11.6186 13.9128 10.4492C14.3972 9.27973 14.524 7.9929 14.277 6.75142C14.0301 5.50995 13.4205 4.36958 12.5255 3.47452C11.6304 2.57946 10.4901 1.96992 9.24858 1.72298C8.0071 1.47603 6.72028 1.60277 5.55083 2.08717C4.38138 2.57158 3.38184 3.39188 2.6786 4.44435C1.97536 5.49683 1.6 6.7342 1.6 8C1.6 9.69739 2.27429 11.3253 3.47452 12.5255C4.67475 13.7257 6.30262 14.4 8 14.4ZM7.2 10.4H8.8V12H7.2V10.4ZM7.2 4H8.8V8.8H7.2V4Z'
          stroke=''
        ></path>
      </g>
    </svg>
  )
}

// const WarningOutlineIconContainer = styled(View)``

export default WarningOutlineIcon
