import React from "react"

const RewardsIcon = ({ backgroundColor = "#1965CF", color = "#fff" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
    >
      <g filter='url(#filter0_i_6216_20815)'>
        <circle cx='20' cy='20' r='20' fill={backgroundColor} />
      </g>
      <g filter='url(#filter1_d_6216_20815)'>
        <path
          d='M14.5917 21.3705L21.8844 8.44259C21.9363 8.35066 22.0769 8.40081 22.0589 8.50481L20.5978 16.947C20.5679 17.1194 20.7007 17.2771 20.8757 17.2771H25.0872C25.3752 17.2771 25.5563 17.5876 25.4145 17.8383L18.0846 30.8001C18.0325 30.8924 17.8913 30.8413 17.9103 30.737L19.4509 22.2638C19.4824 22.0906 19.3494 21.9313 19.1734 21.9313H14.9193C14.6315 21.9313 14.4503 21.6212 14.5917 21.3705Z'
          fill={color}
        />
      </g>
      <defs>
        <filter
          id='filter0_i_6216_20815'
          x='0'
          y='0'
          width='40'
          height='40'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='-3.57391' dy='-3.25217' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='shape'
            result='effect1_innerShadow_6216_20815'
          />
        </filter>
        <filter
          id='filter1_d_6216_20815'
          x='14.543'
          y='8.12092'
          width='10.9199'
          height='22.7273'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-0.273485' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_6216_20815'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_6216_20815'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}

export default RewardsIcon
