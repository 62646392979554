const OfferLock = ({ fill = "#1965CF" }) => {
  return (
    <div>
      <svg
        width='42'
        height='42'
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z'
          fill={fill}
        />
        <path
          d='M26.1407 13.8371L26.1773 13.8737C26.1644 13.8607 26.1558 13.8457 26.1429 13.8328V13.8371H26.1407Z'
          fill='black'
          fill-opacity='0.4'
        />
        <path
          d='M26.9559 10.0549C27.6111 10.9222 28.0017 11.0258 28.0017 12.1934C28.0017 12.773 27.5313 13.2434 26.9517 13.2434C26.6808 13.2434 26.4351 13.1384 26.2482 12.9683L28.5771 16.273C29.7321 16.4368 30.6204 17.4259 30.6204 18.625V30.5761C30.6204 31.8886 29.5557 32.9554 28.2411 32.9554H13.7574C13.3395 32.9554 12.9447 32.8462 12.6045 32.6572L21.9285 41.9812C31.8237 41.5507 39.9444 34.2721 41.6643 24.7654L26.9559 10.057V10.0549Z'
          fill='black'
          fill-opacity='0.4'
        />
        <path
          d='M21 0C9.4017 0 0 9.4017 0 21C0 32.5983 9.4017 42 21 42C32.5983 42 42 32.5983 42 21C42 9.4017 32.5983 0 21 0ZM21 39.9C10.5777 39.9 2.1 31.4223 2.1 21C2.1 10.5777 10.5777 2.1 21 2.1C31.4223 2.1 39.9 10.5777 39.9 21C39.9 31.4223 31.4223 39.9 21 39.9Z'
          fill='black'
          fill-opacity='0.4'
        />
        <path
          d='M28.2412 16.2455H16.099V13.1669C16.099 11.4722 17.4766 10.0946 19.1713 10.0946H22.8295C24.5242 10.0946 25.9018 11.4722 25.9018 12.1911C25.9018 12.7707 26.3722 13.2411 26.9518 13.2411C27.5314 13.2411 28.0018 12.7707 28.0018 12.1911C28.0018 10.3151 25.6813 7.99463 22.8295 7.99463H19.1713C16.3195 7.99463 13.999 10.3151 13.999 13.1669V16.2455H13.7575C12.445 16.2455 11.3782 17.3102 11.3782 18.6248V30.5759C11.3782 31.8884 12.4429 32.9552 13.7575 32.9552H28.2412C29.5537 32.9552 30.6205 31.8905 30.6205 30.5759V18.6248C30.6205 17.3123 29.5558 16.2455 28.2412 16.2455ZM22.168 26.8862L22.4368 29.4104H19.5661L19.8349 26.8862C19.186 26.4914 18.7492 25.7795 18.7492 24.9626C18.7492 23.7194 19.7572 22.7114 21.0004 22.7114C22.2436 22.7114 23.2516 23.7194 23.2516 24.9626C23.2516 25.7795 22.8169 26.4914 22.1659 26.8862H22.168Z'
          fill='white'
        />
      </svg>
    </div>
  )
}

export default OfferLock
