import { Fragment, useCallback, useEffect, useRef, useState } from "react"
import View from "./View"
import { Virtuoso } from "react-virtuoso"

const FlatList = ({
  data,
  renderItem,
  keyExtractor,
  ListFooterComponent = Fragment,
  onEndReached,
  ariaLabel,
}) => {
  /** @type {import('react').MutableRefObject<HTMLDivElement | null>} */
  const containerRef = useRef()
  const [listHeight, setListHeight] = useState()
  const [isVirtualized, setIsVirtualized] = useState(false)

  useEffect(() => {
    const height =
      containerRef.current?.parentElement?.getBoundingClientRect().height
    setListHeight(height)

    setIsVirtualized(!!height)
  }, [])

  const refFunction = useCallback(
    div => {
      if (!div) {
        return
      }
      onEndReached?.()
    },
    [onEndReached, containerRef],
  )

  const itemContent = useCallback(
    index => {
      const item = data[index]
      if (index === data.length) {
        // if list height is 0 then it can't be virtualized
        // so call onEndReached when raeching `ListFooterComponent`
        return (
          <View
            style={{ minHeight: isVirtualized ? "1px" : 0 }}
            ref={refFunction}
            onLoad={onEndReached}
            key={data.length}
          >
            <ListFooterComponent />
          </View>
        )
      }
      return (
        <View
          key={keyExtractor?.(item, index) || index}
          style={ariaLabel === "llllll" ? { zIndex: index === 0 ? 1 : 0 } : {}}
        >
          {renderItem({ item, index })}
        </View>
      )
    },
    [data, onEndReached, listHeight, refFunction, isVirtualized],
  )

  return (
    <div
      ref={containerRef}
      nbr={"FlatList"}
      style={{
        width: "100%",
        height: "100%",
      }}
      aria-label={ariaLabel}
    >
      {isVirtualized && (
        <Virtuoso
          endReached={onEndReached}
          totalCount={data.length + 1}
          style={{ height: `${listHeight || 0}px` }}
          itemContent={itemContent}
        />
      )}
      {!isVirtualized &&
        Array(data.length + 1)
          .fill(null)
          .map((_, index) => itemContent(index))}
    </div>
  )
}

export default FlatList
