const styles = {
  heading: {
    fontFamily: "Inter",
    fontWeight: 600,
    fontSize: 20,
  },
  heading2: {
    mt: 2,
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    color: "#272D3B",
  },
  inputSection: {
    mt: "30px",
  },
  inputContainer: {
    mt: "15px",
  },
  label: {
    width: "100%",
    color: "#7B7B7B",
  },
  textInput: {
    width: "100%",
    fontSize: 14,
    backgroundColor: "#FFF",
    placeholderTextColor: "#7B7B7B",
    borderWidth: "1px",
    py: 3,
    my: 2,
  },
  expand: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  cancelButton: {
    backgroundColor: "#FFF",
    borderColor: "#6180FF",
    borderWidth: 1,
  },
  otpVerificationText: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600,
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8F8F8",
    padding: 3,
    w: "90%",
    mb: "20px",
  },
  messageText: {
    fontSize: 12,
    color: "#7B7B7B",
  },
  secureMessageText: {
    fontWeight: 500,
    fontSize: 12,
    color: "#7B7B7B",
    mt: "5px",
  },
  infoSvgContainer: {
    width: "20px",
    mt: "3px",
  },
  lockSvgContainer: {
    width: "18px",
    mt: "10px",
  },
  secureMessageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  datePickerStyle: `
    .verify-customer-date-picker {
      width: 100%;
      font-size: 14px;
      background-color: #FFF;
      color: #303030;
      border: solid 1px rgb(236, 236, 236);
      border-radius: 4px;
      padding: 3px 8px;
      margin: 2px 0px;
      height: 50px;
    }

    .verify-customer-date-picker > .react-date-picker__wrapper {
      border: none;
    }
  `,
  v3datePickerStyle: `
    .v3-verify-customer-date-picker {
      width: 100% !important;
      font-size: 14px;
      display: flex;
      background-color: #FFF;
      color: #303030;
      border-bottom: solid 1px #DEDEDE;
      padding: 3px 8px;
      height: 50px;
    }

    .v3-verify-customer-date-picker > .react-date-picker__wrapper {
      border: none;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .v3-verify-customer-date-picker > react-date-picker__wrapper > .react-date-picker__inputGroup {
      width: 90%;
    }
    .v3-verify-customer-date-calendar {
      border: none;
      padding: 0;
      height: 16px;

    }
  `,
}

export default styles
