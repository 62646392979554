import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import greyCircle from "../../assets/images/greyCircle.svg"
import { EventName, PwaVersions } from "../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  isIosDevice,
  postMessageToIos,
} from "../../utils/functions"
import { Header } from "../core"
import { ColorButton } from "../core/buttons"
import AttentionIcon from "../svg/attention"
import styles from "./styles"
import { AuthChallenges, PWA_MAX_WIDTH_IN_PX } from "../../utils/constants"
import moment from "moment"
import { showCancelButton } from "../../utils/auth"
import BlockedPageLoader from "../core/SkeletonLoader/BlockedPageLoader"
import View from "../../nativeBaseReplacements/View"
import Text from "../../nativeBaseReplacements/Text"
import { ScrollView } from "../../nativeBaseReplacements"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"

const BlockedMutipleAttempts = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()

  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const [pageTitle, setPageTitle] = useState("")
  const [timeLeftText, setTimeLeftText] = useState("")

  const location = useLocation()
  const headerTitle = location?.state?.headerTitle
  const timeUntillBlock = location?.state?.time

  const otpSmsAuth = config?.auth?.[AuthChallenges.OTP_SMS]
  const maxResendCount = otpSmsAuth?.maxResendCount
  const blockDurationInMin = otpSmsAuth?.blockDurationInMin

  const padNumber = (number, digits) => {
    return String(number).padStart(digits, "0")
  }

  useEffect(() => {
    let initialTimeLeftInSeconds = moment(timeUntillBlock).diff(
      moment(),
      "seconds",
    )
    let timeLeftInSeconds = initialTimeLeftInSeconds
    const timer = setInterval(() => {
      let textToDisplay = ""
      if (timeLeftInSeconds <= 0) {
        showCancelButton() ? navigate("/") : goToRedirectUrl()
      } else if (timeLeftInSeconds < 60) {
        textToDisplay = `00:${padNumber(timeLeftInSeconds, 2)} seconds`
      } else if (timeLeftInSeconds < 3600) {
        let min = padNumber(Math.floor(timeLeftInSeconds / 60), 2)
        let seconds = padNumber(timeLeftInSeconds % 60, 2)
        textToDisplay = `${min}:${seconds} minutes`
      } else {
        let hour = padNumber(Math.floor(timeLeftInSeconds / 3600), 2)
        let min = padNumber(
          Math.floor((timeLeftInSeconds - hour * 3600) / 60),
          2,
        )
        textToDisplay = `${hour}:${min} hours`
      }
      setTimeLeftText(textToDisplay)
      timeLeftInSeconds--
    }, 1000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  useEffect(() => {
    let reason =
      location?.state?.from === "Customer"
        ? "PAN and DOB"
        : location?.state?.from
    captureEvents({
      eventName: EventName.BLOCKED_MULTPLE_ATTEMPTS,
      reason: reason,
      metadata: {},
    })
  }, [])

  useEffect(() => {
    let title = t("messages.accessRestrictedTemporarily")

    if (!title) {
      navigate("/")
    }
    setPageTitle(title)
  }, [])

  const description = screen.accountClosed
  const isMobileNumPresent = () => {
    if (description?.moblieNo) return "Or"
    else return null
  }
  const backHome = () => {
    captureEvents({ eventName: EventName.ACCESS_BLOCK_CTA, metadata: {} })
    showCancelButton() ? navigate("/") : goToRedirectUrl()
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  //width will be minimum of (PWA_MAX_WIDTH_IN_PX, windowWidth)
  let windowWidth = PWA_MAX_WIDTH_IN_PX
  if (windowDimensions.width < windowWidth) {
    windowWidth = windowDimensions.width
  }

  return (
    <View
      bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
      {...styles.mainView}
      minH={windowDimensions.height}
    >
      <ScrollView>
        <Header
          marginBottom='0px'
          text={headerTitle || t("messages.alert")}
          onBack={async () => {
            showCancelButton() ? navigate("/") : goToRedirectUrl()
          }}
        />
        <View {...styles.innerView} minW={windowWidth}>
          <View {...styles.section1}>
            {!timeLeftText ? (
              <BlockedPageLoader />
            ) : (
              <>
                <View mt='30px'>
                  <img
                    style={{
                      position: "absolute",
                      zIndex: -1,
                      top: "-25px",
                      height: "150px",
                      left: "-25px",
                      width: "150px",
                    }}
                    src={greyCircle}
                  />
                  <AttentionIcon color='#CA5454' />
                  {/* <Image {...styles.image} source={{ uri: ErrorImage }} /> */}
                </View>
                <View {...styles.text1Container1}>
                  <View {...styles.text1Container2}>
                    <Text
                      {...styles.text1}
                      fontFamily={theme.fontFamily}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {pageTitle}
                    </Text>
                  </View>
                  <View>
                    <Text
                      {...styles.textSubTitle}
                      fontFamily={theme.fontFamily}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      {t("messages.yourAccessTemporarilyRestricted", {
                        timeLeftText: timeLeftText || t("messages.sometime"),
                      })}
                    </Text>
                  </View>
                </View>
              </>
            )}
          </View>

          <View {...styles.button} position='fixed' bottom={"20px"} mt='25%'>
            <ColorButton text={t("messages.goToHomepage")} onPress={backHome} />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default BlockedMutipleAttempts
