import React from "react"
import { getV3Theme } from "../../../utils/theme"

const YourCardV3 = ({ color = getV3Theme().tertiaryNeutral.color2 }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M18.5 4.25H6.5C4.082 4.25 2.75 5.582 2.75 8V16C2.75 18.418 4.082 19.75 6.5 19.75H18.5C20.918 19.75 22.25 18.418 22.25 16V8C22.25 5.582 20.918 4.25 18.5 4.25ZM20.75 16C20.75 17.577 20.077 18.25 18.5 18.25H6.5C4.923 18.25 4.25 17.577 4.25 16V10.75H20.75V16ZM20.75 9.25H4.25V8C4.25 6.423 4.923 5.75 6.5 5.75H18.5C20.077 5.75 20.75 6.423 20.75 8V9.25ZM6.75 15C6.75 14.586 7.086 14.25 7.5 14.25H10.5C10.914 14.25 11.25 14.586 11.25 15C11.25 15.414 10.914 15.75 10.5 15.75H7.5C7.086 15.75 6.75 15.414 6.75 15Z'
        fill={color}
      />
    </svg>
  )
}

export default YourCardV3
