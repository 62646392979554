import { useTranslation } from "react-i18next"
import crossIcon from "../../../../assets/images/v3/closeBtn.svg"
import { BottomSheet } from "react-spring-bottom-sheet"
import frozenCard from "../../../../assets/images/v3/FrozenCardImage.svg"
import AlarmClock from "../../../svg/AlarmClock"
import CardDiscard from "../../../svg/CardDiscard"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import { ColorButton } from "../../../core/buttons"
import freezeCardBg from "../../../../assets/images/v3/freezeCardBG.svg"

const FreezeCardDescription = ({ isOpen, onClose, setHandleFreezecard }) => {
  const { t } = useTranslation()
  return (
    <>
      <div style={{ maxWidth: "500px" }}>
        <BottomSheet open={isOpen} onDismiss={onClose}>
          <div className='v3-freezeCardDescription-bg'>
            <img
              src={freezeCardBg}
              style={{ borderRadius: "5% 5% 0 0", width: "100%" }}
            />
          </div>
          <div
            className='v3-bottomDrawer-nudges-container'
            style={{
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "24px 26px 26px 24px",
            }}
          >
            <div className='v3-bottomDrawer-nudges-header'>
              <div className='v3-summary-bottomSheet-img'>
                <img src={frozenCard} width={"100%"} />
              </div>
              <span
                className='v3-bottomDrawer-nudges-close-btn'
                onClick={() => onClose()}
              >
                <img src={crossIcon} />
              </span>
            </div>
            <div className='v3-freezeCardDescription-title'>
              {t("CardDetailsWidgetV3.freezeYourCard")}
            </div>
            <div className='v3-freezeCardDescription-subtitle-container'>
              <div className='v3-freezeCardDescription-subtitle'>
                <div className='v3-freezeCardDescription-subtitle-icon'>
                  <AlarmClock />
                </div>
                <div className='v3-freezeCardDescription-subtitle-text'>
                  {t("CardDetailsWidgetV3.freezeCardDescription1")}
                </div>
              </div>
              <div className='v3-freezeCardDescription-subtitle'>
                <div className='v3-freezeCardDescription-subtitle-icon'>
                  <CardDiscard />
                </div>
                <div className='v3-freezeCardDescription-subtitle-text'>
                  {t("CardDetailsWidgetV3.freezeCardDescription2")}
                </div>
              </div>
            </div>
            <DividerV3 />
            <div
              className='v3-verify-otp-bottomSheet-cta'
              style={{ padding: "0px", width: "100%" }}
            >
              <ColorButton
                onPress={() => setHandleFreezecard(true)}
                text='Yes, Freeze my card'
                width='100%'
              />
            </div>
          </div>
        </BottomSheet>
      </div>
    </>
  )
}

export default FreezeCardDescription
