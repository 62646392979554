import React, { forwardRef, useRef, useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import calculator from "../../assets/images/v3/calculator.svg"
import check from "../svg/v3/check-circle.svg"
import crossIcon from "../svg/v3/crossIcon.svg"

import { useDispatch, useSelector } from "react-redux"

const LoadingBottomSheet = forwardRef(({ isOpen, onClose, title }, ref) => {
  const dispatch = useDispatch()

  const sheetRef = useRef()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const authStore = useSelector(state => state.auth)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)

  return (
    <div style={{ maxWidth: "450px" }}>
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div ref={sheetRef} className='bottom-sheet'>
          <div className='v3-success-bottomSheet-icon-container'>
            <img className='v3-success-bottomSheet-icon' src={calculator} />
          </div>
          <div className='bottom-sheet-header' style={{ marginBottom: "40px" }}>
            <div className='v3-success-bottomSheet-title'>{title}</div>
          </div>
        </div>
      </BottomSheet>
    </div>
  )
})

export default LoadingBottomSheet
