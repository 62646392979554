import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../utils/enums"
import Customer from "./Customer"
import Customer_v3 from "./Customer_v3"
const Index = () => {
  const config = useSelector(state => state.config)
  const getVersionedOtp = version => {
    switch (version) {
      case PwaVersions.V3:
        return <Customer_v3 />
      default:
        return <Customer />
    }
  }
  return getVersionedOtp(config?.version)
}

export default Index
