import React, { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { BottomSheet } from "react-spring-bottom-sheet"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import Cross from "../../../svg/v3/Cross"
import { useNavigate } from "react-router-dom"

const CancelPaymentBottomSheet = forwardRef(({ isOpen, onClose, ref }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className='v3-paymentRequest-cancelPaymentBottomSheet'>
        <div className='v3-paymentRequest-cancelPaymentBottomSheet-header'>
          <div>{t("PaymentGateway.areYouSureText")}</div>
          <div onClick={() => onClose()}>
            <Cross />
          </div>
        </div>
        <div className='v3-paymentRequest-cancelPaymentBottomSheet-description'>
          {t("PaymentGateway.cancellationDescriptionText")}
        </div>
        <ColorButton
          text={t("PaymentGateway.cancelText")}
          width='100%'
          onPress={() => navigate("/")}
        />
        <WhiteButton
          text={t("PaymentGateway.stayText")}
          width='100%'
          marginTop='15px'
          onPress={onClose}
        />
      </div>
    </BottomSheet>
  )
})

export default CancelPaymentBottomSheet
