const ArrowRightSmall = ({ color = "#1965CF" }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='5'
        height='8'
        viewBox='0 0 5 8'
        fill='none'
      >
        <path
          d='M0.833489 8C0.620176 8 0.406837 7.92163 0.244352 7.76563C-0.0814507 7.45284 -0.0814507 6.9472 0.244352 6.63441L2.98824 4.00011L0.244352 1.36581C-0.0814507 1.05302 -0.0814507 0.547385 0.244352 0.234593C0.570155 -0.0781978 1.09682 -0.0781978 1.42263 0.234593L4.75565 3.4345C5.08145 3.74729 5.08145 4.25293 4.75565 4.56572L1.42263 7.76563C1.26014 7.92163 1.0468 8 0.833489 8Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default ArrowRightSmall
