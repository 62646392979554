import React, { useState } from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../../utils/enums"
import ArrowDown from "../../../../svg/arrowDown"
import ArrowUp from "../../../../svg/arrowUp"
import Pointer from "../../../../svg/pointer"

const FaqAccordian = props => {
  const [subIsOpen, setSubIsOpen] = useState(props.index === 0)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const ans = props.item?.answer.split("#") || []
  return (
    <div className='aboutProgram-faq-accordian'>
      <div className='aboutProgram-faq-accordian-container'>
        <div
          className='aboutProgram-faq-accordian-question'
          style={{ color: theme.appTextColor }}
        >
          {props.item?.question}
        </div>
        <div
          onClick={() => {
            setSubIsOpen(!subIsOpen)
          }}
          style={{ cursor: "pointer", width: "7%" }}
        >
          {subIsOpen ? (
            <ArrowUp
              className='aboutProgram-faq-accordian-question-arrowUp'
              size='12px'
              color={theme.appTextColor}
            />
          ) : (
            <ArrowDown
              className='aboutProgram-faq-accordian-question-arrowDown'
              size='12px'
              color={theme.appTextColor}
            />
          )}
        </div>
      </div>
      {subIsOpen &&
        ans.map(item => (
          <div className='aboutProgram-faq-accordian-item'>
            {config?.version !== PwaVersions.V2_1 && (
              <div style={{ width: "7%" }}>
                <Pointer />
              </div>
            )}
            <div
              className='aboutProgram-faq-accordian-item-text'
              style={{ color: theme.appTextColor }}
              color={theme.appTextColor}
            >
              {item}
            </div>
          </div>
        ))}
    </div>
  )
}

export default FaqAccordian
