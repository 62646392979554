import styled from "styled-components"

const Radio = ({ isChecked, size = 20, color }) => {
  return (
    <RadioContainer
      style={{
        width: `${size}px`,
        height: `${size}px`,
        border: `2px solid ${isChecked ? "black" : "#D4D4D4"}`,
      }}
    >
      {isChecked && (
        <svg
          style={{
            color,
            height: `${0.6 * size}px`,
            width: `${0.6 * size}px`,
            opacity: 1,
            cursor: "pointer",
          }}
          viewBox='0 0 24 24'
          stroke=''
          role='img'
        >
          <g>
            <path
              fill='currentColor'
              d='M 12, 12 m -9, 0 a 9,9 0 1,0 18,0 a 9,9 0 1,0 -18,0'
              stroke=''
            ></path>
          </g>
        </svg>
      )}
    </RadioContainer>
  )
}

export default Radio

const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
