import { toast } from "react-toastify"
import ClipboardToast from "../components/core/Clipboard"
import "react-toastify/dist/ReactToastify.minimal.css"
import { ToastType } from "../utils/enums"
import SuccessTick from "../components/svg/v3/SuccessTick"
import Info from "../components/svg/info"

const coreToast = (content, options) => {
  const finalOptions = {
    // position: toast.POSITION.BOTTOM_CENTER,
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    className: "hf-toast",
    bodyClassName: "hf-toast-body",
    ...options,
  }

  toast(content, finalOptions)
}

const coreToast2 = (content, options) => {
  coreToast(content, {
    className: "hf-toast-2",
    bodyClassName: "hf-toast-2-body",
    ...options,
  })
}

const customToast = (type, content, options) => {
  let iconToShow
  switch (type) {
    case ToastType.COPY: {
      iconToShow = <SuccessTick color={"var(--leading-white)"} />
      break
    }
    case ToastType.INFO: {
      iconToShow = <Info />
      break
    }
    default: {
    }
  }
  const finalOptions = {
    position: "bottom-center",
    autoClose: 1500,
    hideProgressBar: true,
    closeButton: false,
    defaultHeight: "40px",
    className: "hf-toast-3",
    bodyClassName: "hf-toast-3-body",
    ...options,
  }
  toast(<ClipboardToast text={content} svg={iconToShow} />, finalOptions)
}

export { coreToast as toast, coreToast2 as toastify, customToast }
