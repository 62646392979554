import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../../auth/ApiIds"
import CardService from "../../../../../services/CardService"
import { setCards } from "../../../../../store/actions/User"
import { handleApiAuth } from "../../../../../utils/auth"
import {
  AU_BlockCodes,
  BlockReplaceTncActions,
  BottomSheetType,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "../../../../../utils/enums"
import {
  captureEvents,
  consoleError,
  hideGenericError,
  resetErrorTryAgainCount,
} from "../../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../../core"
import { ColorButton } from "../../../../core/buttons"
import ErrorScreen from "../../../../messages/errorScreen"
import Info from "../../../../svg/info"
import cardEnvelope from "../../../../../assets/images/v3/requestNewCard.svg"

import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import { DisplayConfig, ErrorType } from "../../../../../utils/constants"
import { Checkbox } from "../../../../../core/Checkbox"
import { showToast1 } from "../../../../core/toast"
import Divider from "../../../../core/Divider/v3/Divider"
import { useBottomSheet } from "../../../../auth/BottomSheetContext"
import { useToast } from "../../../../../nativeBaseReplacements/useToast"
import { ErrorContext } from "../../../../auth/ErrorScreenContext"

const NewCardRequest_v3 = () => {
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const nativeToast = useToast()
  const [address, setAddress] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [failureErrorMessage, setFailureErrorMessage] = useState(
    t("CardDetailsWidgetV3.tryAfterSomeTime"),
  )
  const bottomSheetContext = useBottomSheet()

  const [isTncChecked, setIsTncChecked] = useState(
    Boolean(screen?.blockReplaceCard?.tncIsCheckedByDefault),
  )
  let currentCard = user?.cards[0]
  const cardReplacementFeeText =
    screen?.blockReplaceCard?.cardReplacementFeeText

  useEffect(() => {
    captureEvents({ eventName: EventName.NEW_CARD_REQUEST, metadata: {} })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!user.cards[0].isHotlisted) {
        toast(t("CardDetailsWidgetV3.deniedRequestCardActivation"))
        navigate("/")
        return
      }
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.CARD_DASHBOARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        toast(t("CardDetailsWidgetV3.accessBlockedToRequestCard"))
        navigate("/")
        return
      }

      if (
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        toast(t("CardDetailsWidgetV3.accessBlockedText"))
        navigate("/")
        return
      }
      if (
        user.account.status !== "ACTIVE" ||
        !user.cards[0] ||
        !user.cards[0].isHotlisted
      ) {
        navigate("/")
        return
      }

      const currentAddress = user.customer?.currentAddress

      if (currentAddress) {
        const line1 = currentAddress.line1 ? currentAddress.line1 : ""
        const line2 = currentAddress.line2 ? ", " + currentAddress.line2 : ""
        const line3 = currentAddress.line3 ? ", " + currentAddress.line3 : ""
        const city = currentAddress.city ? ", " + currentAddress.city : ""
        const pincode = currentAddress.pincode
          ? " - " + currentAddress.pincode
          : ""
        const state = currentAddress.state ? ", " + currentAddress.state : ""

        setAddress(`${line1}${line2}${line3}${city}${pincode}${state}`)
      } else {
        setError(true)
      }
    })()
  }, [])

  const onEnableDomesticOnlineSuccess = async apiToken => {
    try {
      const response = await CardService.updateCardControls(
        apiToken,
        currentCard?.id,
        {
          updateCardControls: [
            {
              txnTags: {
                location: "DOMESTIC",
                txnType: "RETAIL",
                txnChannel: "ECOMMERCE",
              },
              isEnabled: true,
            },
          ],
        },
      )
      const result = response?.data

      if (result?.success) {
        dispatch(setCards({ cards: [result.data] }))
        toast(t("CardDetailsWidgetV3.blockedCardReplacedText"))
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("CardDetailsWidgetV3.cardRequestSent"),
          t("CardDetailsWidgetV3.blockedCardReplacedText"),
          "/",
        )
        resetErrorTryAgainCount()
      } else {
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status, () => handleSubmitClick())
        } else {
          handleFailureState()
        }

        consoleError(
          t("CardDetailsWidgetV3.domesticTrxnFailedErrorMessage", {
            errorMessage: result.errors,
          }),
        )
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => handleSubmitClick())
      } else {
        navigate("/", { replace: true })
        handleFailureState()
        setFailureErrorMessage(
          t("CardDetailsWidgetV3.domesticTrxnFailedExceptionMessage"),
        )
        consoleError(
          t("CardDetailsWidgetV3.domesticTrxnFailedError", {
            errorMessage: error,
          }),
        )
      }
    }
  }

  const onActivateCardSuccess = async apiToken => {
    try {
      // when activating card from tnc action, as of now activation code is not needed
      const response = await CardService.activateCard(apiToken, currentCard?.id)
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...user.cards?.[0] }
        tempCard.isActivated = true
        dispatch(setCards({ cards: [tempCard] }))
        resetErrorTryAgainCount()
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("CardDetailsWidgetV3.cardRequestSent"),
          t("CardDetailsWidgetV3.blockedCardReplacedText"),
          "/",
        )
      } else {
        setErrorState(response?.status)

        consoleError(
          t("activation.activationFailedError", { errMessage: result.errors }),
        )
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR)
      } else {
        consoleError(
          t("activation.activationFailedException", { errMessage: error }),
        )
        setErrorState(ErrorType.NO_INTERNET_ERROR)
      }
    }
  }

  const handleSubmitClick = async () => {
    if (screen?.blockReplaceCard?.isTncEnabled && !isTncChecked) {
      return false
    }
    setSubmitLoading(true)
    handleApiAuth({
      apiId: ApiIds.REPLACE_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: t("CardDetailsWidgetV3.requestNewCardReason"),
      mpinReason: t("CardDetailsWidgetV3.requestNewCardReason"),
      version: config?.version,
      bottomSheetContext: bottomSheetContext,
    })

    setSubmitLoading(false)
  }

  const updateBlockCodeAction = () => {
    let disabledFunctions = session?.disabledFunctionsByBlockCode
    if (session?.issuer === Issuers.AU_BANK) {
      let index = disabledFunctions.indexOf(AU_BlockCodes.TEMP_CARD_DISABLE)
      if (index > -1) {
        disabledFunctions.splice(index, 1)
      }
      let indexOfLocked = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
      if (indexOfLocked > -1) {
        disabledFunctions.splice(indexOfLocked, 1)
      }

      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
    if (session?.issuer === Issuers.FEDERAL_BANK) {
      let index = disabledFunctions.indexOf(Federal_BlockCodes.CARD_BLOCKED)
      if (index > -1) {
        disabledFunctions.splice(index, 1)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
  }

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("CardDetailsWidgetV3.BottomSheetFailureMsg"),
      failureErrorMessage || t("CardDetailsWidgetV3.requestNewCardFailed"),
      null,
      null,
      () => handleSubmitClick(),
    )
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.replace(apiToken, user.cards[0]?.id, {
        cardExpiry: user.cards[0]?.cardExpiry?.replace(/\//g, ""), // remove forward slash from expiry
      })
      const result = response?.data
      if (result?.success) {
        captureEvents({ eventName: EventName.CARD_REPLACED, metadata: {} })
        const tempCard = { ...result.data }
        currentCard = tempCard
        updateBlockCodeAction()

        dispatch(setCards({ cards: [tempCard] }))
        if (
          screen?.blockReplaceCard?.tncAction ===
          BlockReplaceTncActions.ENABLE_DOMESTIC_ONLINE
        ) {
          await handleApiAuth({
            apiId: ApiIds.UPDATE_CARD_CONTROLS,
            onAuthSuccess: onEnableDomesticOnlineSuccess,
            flowId: "5",
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: t("mpin.enableDomesticTrxnsReason"),
            mpinReason: t("mpin.enableDomesticTrxnsReason"),
            version: config?.version,
            bottomSheetContext: bottomSheetContext,
          })
        } else if (
          screen?.blockReplaceCard?.tncAction ===
          BlockReplaceTncActions.ACTIVATE_CARD
        ) {
          await handleApiAuth({
            apiId: ApiIds.ACTIVATE_CARD,
            onAuthSuccess: onActivateCardSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: t("mpin.activateCard"),
            mpinReason: t("mpin.activateCard"),
            version: config?.version,
            bottomSheetContext: bottomSheetContext,
          })
        } else {
          bottomSheetContext.openBottomSheet(
            BottomSheetType.SUCCESS,
            t("CardDetailsWidgetV3.cardRequestSent"),
            t("CardDetailsWidgetV3.blockedCardReplacedText"),
            "/",
          )
        }
        resetErrorTryAgainCount()
      } else {
        consoleError(result.errors)
        if (hideGenericError(response?.status)) {
          setErrorState(response?.status, () => handleSubmitClick())
        } else {
          handleFailureState()
        }
        setFailureErrorMessage(
          t("CardDetailsWidgetV3.requestNewCardFailedMessage"),
        )
        // navigate("/", { replace: true })
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => handleSubmitClick())
      } else {
        consoleError(error)
        handleFailureState()
      }
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return error ? (
    <div
      className='newCardRequest-error-container'
      style={{ height: window.innerHeight }}
    >
      <ErrorScreen />
    </div>
  ) : (
    <div
      style={{
        height: window.innerHeight,
      }}
      className='v3-newCardRequest'
    >
      <Header
        text='Block/Replace Card'
        onBack={async () => {
          navigate(-1)
        }}
      />

      {/* card image */}
      <div className='v3-newCardRequest-top-section'>
        <div className='v3-newCardRequest-image'>
          <img src={cardEnvelope} />
        </div>

        {/* text content */}
        <div className='v3-newCardRequest-content'>
          <div className='v3-newCardRequest-content-header-wrapper'>
            <div className='v3-newCardRequest-content-header'>
              Request a new credit card
            </div>
            {cardReplacementFeeText && (
              <div
                className='v3-newCardRequest-replacement-fee-text'
                style={{
                  color: theme.v3.cssVars.primaryBase.color3,
                  fontFamily: theme.fontFamily,
                }}
              >
                {cardReplacementFeeText}
              </div>
            )}
          </div>
          <div
            className='v3-newCardRequest-content-address-header'
            style={{
              fontFamily: theme.fontFamily,
            }}
          >
            {t("CardDetailsWidgetV3.newCardDelivery")}
          </div>

          <div
            className='v3-newCardRequest-content-address'
            style={{
              fontFamily: theme.fontFamily,
            }}
          >
            {address}
          </div>

          <div className='v3-newCardRequest-content-help'>
            <div className='v3-newCardRequest-needhelp-text'>
              {t("mpin.requireAssistanceText")}{" "}
            </div>
            <div
              className='v3-newCardRequest-request-support-text'
              onClick={() => navigate("/ManageCard/CustomerCare")}
            >
              {t("cvp.requestSupport")}
            </div>
          </div>
        </div>
      </div>

      {/*    CTA Section */}
      <div className='v3-newCardRequest-ctaSection'>
        {screen?.blockReplaceCard?.isTncEnabled && (
          <div className='v3-newCardRequest-tnc'>
            <div
              onClick={() => setIsTncChecked(!isTncChecked)}
              className='newCardRequest-tnc-checkBox'
            >
              <Checkbox
                isChecked={isTncChecked}
                backgroundColor={
                  isTncChecked ? theme.v3.rawColors.secondary.color1 : null
                }
              />
            </div>
            <div className='v3-newCardRequest-tnc-text'>
              {t("cvp.readAndAgreeText")}{" "}
              <span
                onClick={() =>
                  window.location.href(screen?.blockReplaceCard?.tncUrl)
                }
                style={{ fontWeight: "500", color: "#000" }}
              >
                {t("cvp.terms&Conditions")}
              </span>{" "}
              {t("cvp.consentToActivateCard")}
            </div>
          </div>
        )}
        <ColorButton
          isDisable={screen?.blockReplaceCard?.isTncEnabled && !isTncChecked}
          text='Confirm'
          onPress={handleSubmitClick}
          isLoading={submitLoading}
          width={"100%"}
          margin={"auto"}
        />
      </div>
    </div>
  )
}

export default NewCardRequest_v3
