import { getNativeBaseSpaceToPx } from "../utils/functions"

const ChevronUpIcon = ({ color, size }) => {
  return (
    <svg
      color={color}
      style={{
        height: getNativeBaseSpaceToPx(size, 4),
        width: getNativeBaseSpaceToPx(size, 4),
      }}
      viewBox='0 0 24 24'
      stroke=''
      role='img'
    >
      <g>
        <path
          fill='currentColor'
          d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z'
          stroke=''
        ></path>
      </g>
    </svg>
  )
}

export default ChevronUpIcon
