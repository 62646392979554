import React from "react"

const CloseCross = () => {
  return (
    <svg
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='14' cy='14' r='14' fill='black' />
      <path
        d='M17.2311 11.3076L10.7695 17.7692'
        stroke='white'
        strokeWidth='1.41423'
        strokeLinecap='round'
      />
      <path
        d='M17.2311 17.7693L10.7695 11.3077'
        stroke='white'
        strokeWidth='1.41423'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default CloseCross
