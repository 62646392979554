import React from "react"

const DescreteProgressBar = ({
  milestoneValue,
  thresholdValue,
  height = "8px",
  progressColor = "#1965cf",
  trackColor = "#f0f2f4",
  showShadow = false,
}) => {
  const divs = Array.from({ length: thresholdValue }, (v, i) => i)

  return (
    <div
      className='v3-offerDetails-descreteProgressBar'
      style={{
        height: height,
      }}
    >
      {divs.map((element, index) => {
        return (
          <div
            className={`v3-offerDetails-descreteProgressBar${milestoneValue >= element + 1 ? "-active" : ""}-bar`}
            style={{
              boxShadow:
                showShadow && milestoneValue < element + 1
                  ? "0px 1px 14px -2px #00000036 inset"
                  : "",
            }}
          ></div>
        )
      }, [])}
    </div>
  )
}

export default DescreteProgressBar
