import { useNavigate } from "react-router-dom"
import { Header } from "../../../../core/headers"
import OtpInput from "../../../../auth/otp/OtpInput_v3"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Error_v3 from "../../../../core/Error_v3"
import {
  AuthChallenges,
  DEFAULT_OTP_SMS_SIZE,
  OtpErrors,
} from "../../../../../utils/constants"
import { AuthenticationType } from "../../../../../utils/enums"
import DividerV3 from "../../../../core/Divider/v3/DividerV3"
import { ColorButton } from "../../../../core/buttons"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"
import authImage from "../../../../../assets/images/v3/authImage.svg"
import AccountService from "../../../../../services/AccountService"
import AuthenticationTxnSuccess_v3 from "./AuthenticationTxnSuccess_v3"

const AuthenticationTxn_v3 = props => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)
  const [attempts, setAttempts] = useState(0)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [resendOtpEnabled, setResendOtpEnabled] = useState(true)
  const [timeLeft, setTimeLeft] = useState(10 * 60)
  const [otp, setOtp] = useState()
  const { t } = useTranslation()
  const otpId = useRef("")
  const { width } = useWindowDimensions()
  const [refId, setRefId] = useState("")
  const [loader, setLoader] = useState(false)
  const [isResendClicked, setIsResendClicked] = useState(false)
  const [seconds, setCountDown] = useState(0)
  const [paymentData, setPaymentData] = useState({
    redirectUrlFailure: "",
    redirectUrlSuccess: "",
    amount: 0,
    client: "",
    mobileNumber: "",
  })
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const session = useSelector(state => state.session)
  const otpLength =
    config?.auth?.[AuthChallenges.OTP_SMS]?.length || DEFAULT_OTP_SMS_SIZE
  const autoOtpSubmit = config?.auth?.[AuthChallenges.OTP_SMS]?.autoSubmit

  const uniqueIdentifier = session?.uniqueId

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => setCountDown(seconds - 1), 1000)
      return () => clearTimeout(timerId)
    }
  }, [seconds])

  const generateOtp = async () => {
    try {
      const response = await AccountService.generateAcsChallengeOTP({
        accountId: user?.account?.id,
        authorizationId: uniqueIdentifier,
      })

      const result = response.data
      const payload = result?.data?.payload

      if (result?.success && payload?.status === "SUCCESS") {
        otpId.current = payload.mobileVerificationRefId

        const coolOffPeriod = payload.coolOffPeriodInSec || 30
        const resendsLeft = payload.resendsLeft || 0
        setRefId(payload.refId)

        setResendOtpEnabled(resendsLeft > 0)
        setCountDown(coolOffPeriod)
      } else {
        handleOtpError(result?.data?.apiResult?.errors || "Unknown error")
      }
    } catch (error) {
      handleOtpError({ reason: t("emailOtp.otpGenerationErrMsg") })
    }
  }
  const verifyOtp = async () => {
    setSubmitLoading(true)
    setLoader(true)

    try {
      const response = await AccountService.verifyAcsChallengeOTP({
        accountId: user?.account?.id,
        refId: refId,
        otp: otp,
      })
      const data = response?.data?.data
      const verificationStatus = data?.verificationStatus
      const failureReason = data?.failureReason
      const attemptsLeft = data?.attemptsLeft
      const blockedUntil = data?.blockedUntil
      const mobileVerificationRefId = data?.mobileVerificationRefId
      const createdOn = data?.createdOn

      if (response?.status === 200) {
        switch (verificationStatus) {
          case "VERIFIED":
            navigate("/Authenticate/Transaction/Success", {
              state: {
                ispaymentCompleted: true,
                redirectUrlSuccess: paymentData.redirectUrlSuccess,
                createdOn: createdOn,
                amount: paymentData.amount,
                client: paymentData.client,
              },
            })
            break

          case "FAILED":
            setCountDown(0)
            if (attemptsLeft > 0) {
              setResendOtpEnabled(true)
            }
            if (failureReason === OtpErrors.INVALID_OTP) {
              setAttempts(attemptsLeft || 0)
              handleOtpError(OtpErrors.INVALID_OTP)
            } else if (failureReason === OtpErrors.OTP_EXPIRED) {
              handleOtpError(OtpErrors.OTP_EXPIRED)
            } else if (failureReason === OtpErrors.TEMPORARILY_BLOCK) {
              let blockedDuration

              if (blockedUntil) {
                const currentTime = new Date()
                const unblockTime = new Date(blockedUntil)
                const timeDiff = Math.max(
                  0,
                  Math.floor((unblockTime - currentTime) / 1000),
                )

                const minutes = Math.floor(timeDiff / 60)
                const seconds = timeDiff % 60

                blockedDuration = `Your Account is temporarily blocked for ${minutes} min and ${seconds} sec`
              } else {
                blockedDuration = "Account is temporarily blocked."
              }

              handleOtpError(OtpErrors.TEMPORARILY_BLOCK, blockedDuration)
            } else if (failureReason === OtpErrors.INVALID_REF_ID) {
              handleOtpError(OtpErrors.INVALID_REF_ID)
            } else {
              handleOtpError("UNKNOWN_ERROR")
            }
            break

          default:
            handleOtpError("UNKNOWN_STATUS")
            break
        }
      } else {
        handleOtpError(response?.data?.errors || "UNKNOWN_ERROR")
      }
      setLoader(false)
    } catch (error) {
      setError(t("emailOtp.otpVerificationErrMsg"))
    } finally {
      setSubmitLoading(false)
    }
  }

  const handlePaymentApproval = async () => {
    try {
      const response = await AccountService.completePaymentApproval({
        id: uniqueIdentifier,
        type: "AUTHORIZATION",
      })

      const {
        redirectUrlFailure,
        redirectUrlSuccess,
        amount,
        client,
        mobileNumber,
      } = response?.data?.data?.payload?.metadata || {}

      setPaymentData(prevData => ({
        ...prevData,
        redirectUrlFailure,
        redirectUrlSuccess,
        amount,
        client,
        mobileNumber,
      }))
    } catch (error) {
      console.error("Error approving payment:", error)
    }
  }

  useEffect(() => {
    handlePaymentApproval()
    generateOtp()
  }, [])

  const handleOtpError = (errors, blockedDuration) => {
    setOtp(null)
    if (errors === OtpErrors.RETRIES_EXCEEDED) {
      setError(OtpErrors.RETRIES_EXCEEDED)
    } else if (errors === OtpErrors.INVALID_OTP) {
      setError(OtpErrors.INVALID_OTP)
    } else if (errors === OtpErrors.TEMPORARILY_BLOCK) {
      navigate("/Authenticate/Transaction/Blocked", {
        state: {
          time: blockedDuration,
          client: paymentData.client,
          amount: paymentData.amount,
          redirectUrlFailure: paymentData.redirectUrlFailure,
        },
      })
    } else if (errors === OtpErrors.OTP_EXPIRED) {
      setError(OtpErrors.OTP_EXPIRED)
    } else if (errors === OtpErrors.INVALID_REF_ID) {
      setError(OtpErrors.INVALID_REF_ID)
    } else {
      setError(errors?.reason || t("emailOtp.genericError"))
    }
  }

  const handleOTPChange = otp => {
    setError()
    setOtp(otp)
  }

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [timeLeft])

  const minutes = String(Math.floor(timeLeft / 60)).padStart(2, "0")
  const second = String(timeLeft % 60).padStart(2, "0")
  const formattedTime = `${minutes} min : ${second} sec`

  const renderMobileView = () => (
    <div className='v3-acs-transactionSection-authentication'>
      <div className='v3-acs-transactionSection-authentication-header'>
        <div className='v3-acs-transactionSection-authentication-header-text'>
          {t("PaymentGateway.payingTo")}{" "}
          <span className='v3-acs-transactionSection-authentication-header-span'>
            {paymentData.client}
          </span>
        </div>
        <div className='v3-acs-transactionSection-authentication-header-span'>
          ₹{paymentData.amount}
        </div>
      </div>
      <div className='v3-acs-transactionSection-authentication-body'>
        <div className='v3-acs-transactionSection-authentication-body-text'>
          <div className='v3-acs-transactionSection-authentication-body-title'>
            {t("emailOtp.authenticateTransaction")}
          </div>
          <div className='v3-acs-transactionSection-authentication-body-desc'>
            {t("emailOtp.otpSentTextmobile")} {paymentData.mobileNumber}
          </div>
        </div>
        <div className='v3-otp-input-sheet-txn'>
          <OtpInput
            isValid={!error}
            isResendClicked={isResendClicked}
            length={otpLength}
            onChange={e => handleOTPChange(e)}
            type={"login"}
            maxInputSize={otpLength}
          />
          {error && (
            <>
              {error === OtpErrors.INVALID_OTP && (
                <Error_v3 type={AuthenticationType.OTP} attempts={attempts} />
              )}
              {error === OtpErrors.RETRIES_EXCEEDED && (
                <Error_v3
                  errorMessage={t("emailOtp.otpRetryExhaustedTextMobile")}
                />
              )}
              {error === OtpErrors.OTP_EXPIRED && (
                <Error_v3
                  errorMessage={t("emailOtp.otpExpired")}
                  customClassName={"customerAuthOTP"}
                />
              )}
            </>
          )}

          {seconds > 0 && (
            <div className='v3-verify-otp-resend-otp-text-login'>
              <div
                className='authSmsOtp-widget-resendOtp-timer'
                style={{
                  fontWeight: "normal",
                  display: seconds <= 0 ? "none" : "inline",
                }}
              >
                {t("emailOtp.requestOtpTxt")}
                <span
                  style={{
                    fontWeight: "600",
                    color: theme.v3.cssVars.tertiaryNeutral.color1,
                  }}
                >
                  {seconds >= 10 ? seconds : `0${seconds}`}s
                </span>
              </div>
            </div>
          )}

          {resendOtpEnabled && (
            <div className='v3-verify-otp-resend-otp-text-login'>
              {seconds <= 0 && <div>{t("emailOtp.otpNotRecvdCta")}</div>}
              <div
                style={{ display: "inline" }}
                onClick={() => {
                  setIsResendClicked(true)
                  if (seconds < 1) {
                    generateOtp()
                  }
                }}
              >
                {resendOtpEnabled && (
                  <div
                    style={{
                      color:
                        seconds <= 0
                          ? theme.v3.cssVars.secondary.color1
                          : theme.v3.cssVars.tertiaryNeutral.color2,
                      fontWeight: seconds <= 0 ? "600" : null,
                      textDecoration: seconds <= 0 ? "underline" : null,
                      display: seconds > 0 ? "none" : "inline",
                    }}
                  >
                    {t("emailOtp.resendOtpText")}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {!autoOtpSubmit && (
          <div className='v3-login-otp-cta'>
            <div className='v3-acs-authentication-note-text'>
              <span className='v3-acs-authentication-note-text-span'>
                {t("emailOtp.pageExpirationText")} {formattedTime}
              </span>
              <span className='v3-acs-authentication-note-text-span'>
                {t("emailOtp.pleaseDoNotGoBack")}
              </span>
            </div>
            <DividerV3 />
            <div className='v3-verify-otp-btn'>
              <ColorButton
                text='Verify OTP'
                margin={"auto"}
                onPress={verifyOtp}
                isLoading={submitLoading}
                isDisable={otpLength !== otp?.length}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )

  const renderDesktopView = () => (
    <div className='v3-authenticationTxnSuccess-desktop-container'>
      <div className='v3-acs-loader-desktop-price-container'>
        <div className='v3-acs-loader-desktop-header'>
          {t("PaymentGateway.payingTo")}{" "}
          <span className='v3-acs-loader-desktop-header-span'>
            {paymentData.client}:
          </span>
        </div>
        <div className='v3-acs-loader-desktop-price'>₹{paymentData.amount}</div>
      </div>
      <DividerV3 />
      <div className='v3-acs-loader-desktop-body-container v3-acs-web-authentication'>
        <div className='v3-acs-authentication-desktop-content'>
          <div className='v3-acs-authentication-desktop-header'>
            {t("emailOtp.authenticateTransaction")}
          </div>
          <div className='v3-acs-authentication-desktop-desc'>
            {t("emailOtp.otpSentTextmobile")} {paymentData.mobileNumber}
          </div>
          <div className='v3-otp-input-sheet-txn'>
            <OtpInput
              isValid={!error}
              isResendClicked={isResendClicked}
              length={otpLength}
              onChange={e => handleOTPChange(e)}
              type={"login"}
              maxInputSize={otpLength}
            />
            {error && (
              <>
                {error === OtpErrors.INVALID_OTP && (
                  <Error_v3
                    type={AuthenticationType.OTP}
                    attempts={attempts}
                    customClassName={"acsAuthOTP"}
                  />
                )}
                {error === OtpErrors.RETRIES_EXCEEDED && (
                  <Error_v3
                    errorMessage={t("emailOtp.otpRetryExhaustedTextMobile")}
                  />
                )}
                {error === OtpErrors.OTP_EXPIRED && (
                  <Error_v3
                    errorMessage={t("emailOtp.otpExpired")}
                    customClassName={"customerAuthOTP"}
                  />
                )}
                {error === OtpErrors.INVALID_REF_ID && (
                  <Error_v3 errorMessage={"Invalid Reference ID"} />
                )}
              </>
            )}

            {resendOtpEnabled && (
              <div className='v3-verify-otp-resend-otp-text-login'>
                {seconds <= 0 && <div>{t("emailOtp.otpNotRecvdCta")}</div>}
                <div
                  style={{ display: "inline" }}
                  onClick={() => {
                    setIsResendClicked(true)
                    if (seconds < 1) {
                      generateOtp()
                    }
                  }}
                >
                  <div
                    className='authSmsOtp-widget-resendOtp-timer'
                    style={{
                      fontWeight: "normal",
                      display: seconds > 0 ? "inline" : "none",
                    }}
                  >
                    00:{seconds >= 10 ? seconds : `0${seconds}`}
                  </div>
                  <div
                    style={{
                      color:
                        seconds <= 0
                          ? theme.v3.cssVars.secondary.color1
                          : theme.v3.cssVars.tertiaryNeutral.color2,
                      fontWeight: seconds <= 0 ? "600" : null,
                      textDecoration: seconds <= 0 ? "underline" : null,
                    }}
                  >
                    {t("emailOtp.resendOtpText")}
                  </div>
                </div>
              </div>
            )}
          </div>
          {!autoOtpSubmit && (
            <div className='v3-acs-authentication-desktop-btn'>
              <div className='v3-verify-otp-btn'>
                <ColorButton
                  text='Verify OTP'
                  onPress={verifyOtp}
                  margin={"auto"}
                  width={"343px"}
                  isLoading={submitLoading}
                  isDisable={otpLength !== otp?.length}
                />
              </div>
            </div>
          )}
          <div className='v3-acs-authentication-desktop-note'>
            <div className='v3-acs-authentication-desktop-note-head-text'>
              {t("emailOtp.pleaseNoteTxt")}
            </div>
            <div className='v3-acs-authentication-desktop-note-text'>
              {t("emailOtp.pageExpirationText")} {formattedTime}
              <br /> {t("emailOtp.pleaseDoNotGoBack")}
            </div>
          </div>
        </div>
        <div className='v3-acs-authentication-desktop-image'>
          <img src={authImage} alt='authImage' />
        </div>
      </div>
    </div>
  )

  return (
    <>
      {loader ? (
        <AuthenticationTxnSuccess_v3
          amountprice={paymentData.amount}
          clientName={paymentData.client}
        />
      ) : width <= 450 ? (
        renderMobileView()
      ) : (
        renderDesktopView()
      )}
    </>
  )
}

export default AuthenticationTxn_v3
