const ArrowDown_1 = ({ color = "#ED5B27" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
    >
      <path
        d='M6.00014 8.50002C5.87214 8.50002 5.74413 8.45103 5.64663 8.35353L2.14662 4.85353C1.95112 4.65803 1.95112 4.342 2.14662 4.1465C2.34212 3.951 2.65816 3.951 2.85366 4.1465L6.00014 7.29299L9.14663 4.1465C9.34212 3.951 9.65816 3.951 9.85366 4.1465C10.0492 4.342 10.0492 4.65803 9.85366 4.85353L6.35366 8.35353C6.25616 8.45103 6.12814 8.50002 6.00014 8.50002Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowDown_1
