import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { useNavigate } from "react-router-dom"
import {
  getErrorDescription,
  getErrorImage,
  getErrorText,
  getPrimaryCtaText,
  resetErrorTryAgainCount,
  showSecondaryCta,
} from "../../../utils/functions"
import { ErrorContext } from "../../auth/ErrorScreenContext"
import {
  ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT,
  ErrorType,
} from "../../../utils/constants"

const ErrorScreen_v3 = () => {
  const { globalError, clearErrorState } = useContext(ErrorContext)
  const theme = useSelector(state => state.theme)

  const navigate = useNavigate()

  if (!globalError.statusCode) return null

  const { leadingWhite } = theme?.v3?.rawColors

  const handlePrimaryCtaClick = async () => {
    let tryAgainCount =
      JSON.parse(localStorage.getItem("errorTryAgainCount")) || 0
    if (
      globalError?.statusCode >= 500 &&
      globalError?.statusCode < 600 &&
      globalError?.callback &&
      tryAgainCount < ERROR_SCREEN_TRY_AGAIN_RETRY_COUNT
    ) {
      await globalError.callback()
      clearErrorState()
    } else if (globalError?.statusCode === ErrorType.NO_INTERNET_ERROR) {
      window.location.reload()
    } else {
      clearErrorState()
      navigate("/")
    }
  }

  const handleSecondaryCtaClick = () => {
    clearErrorState()
    resetErrorTryAgainCount()
    navigate("/")
  }

  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: leadingWhite,
      }}
      className='v3-error-screen-container'
    >
      <div>
        <Header
          onBack={async () => {
            window.history.go(-1)
          }}
        />
      </div>

      <div className='v3-error-screen-content'>
        {getErrorImage(globalError?.statusCode)}
        <div className='v3-error-screen-text'>
          <div>
            {globalError?.statusCode !== 400 &&
              globalError?.statusCode !== ErrorType.NO_INTERNET_ERROR && (
                <div>Oops! </div>
              )}
            <div>{getErrorText(globalError?.statusCode)}</div>
          </div>
          <div>{getErrorDescription(globalError?.statusCode)}</div>
        </div>
      </div>
      <div className='v3-error-screen-cta-container'>
        <ColorButton
          text={getPrimaryCtaText(globalError)}
          width='100%'
          customClassname='v3-error-screen-color-button'
          onPress={handlePrimaryCtaClick}
        />
        {showSecondaryCta(globalError?.statusCode) && (
          <WhiteButton
            text='Back to Home'
            width='100%'
            className='v3-error-screen-white-button'
            onPress={handleSecondaryCtaClick}
          />
        )}
      </div>
    </div>
  )
}

export default ErrorScreen_v3
