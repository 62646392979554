import React from "react"

const CreditImage = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8334 18.5834L4.16671 6.91675'
        stroke='#23951C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.8334 10.0251V18.5835H7.27504'
        stroke='#23951C'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default CreditImage
