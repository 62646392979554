import React from "react"
import { formatAmount, formatToWholeCurrency } from "../../../utils/functions"
import { useTranslation } from "react-i18next"

const AllCategoriesInfoHeader = ({ spendGroups }) => {
  const { t } = useTranslation()
  const spendGroupData = spendGroups?.data
  return (
    <div className='v3-spend-analytics-category-split-chart-2-header'>
      <div className='v3-spend-analytics-category-split-chart-2-left-section'>
        <div className='v3-spend-analytics-category-split-chart-2-categories-count'>
          {t("spendAnalytics.allCategoryHeader.categoriesSpentOn")}
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-categories-count-value'>
          {spendGroupData?.spendGroups?.length}
        </div>
      </div>
      <div className='v3-spend-analytics-category-split-chart-2-right-section'>
        <div className='v3-spend-analytics-category-split-chart-2-total-spent'>
          {t("spendAnalytics.allCategoryHeader.totalSpends")}
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-total-spent-value'>
          {formatToWholeCurrency(spendGroupData?.totalSpend || 0)}
        </div>
      </div>
    </div>
  )
}

export default AllCategoriesInfoHeader
