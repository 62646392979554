const MoreActionIcon = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      style={{ width: "-webkit-fill-available" }}
    >
      <path
        d='M4.1886 13.5C3.3596 13.5 2.68359 12.829 2.68359 12C2.68359 11.171 3.35059 10.5 4.17859 10.5H4.1886C5.0176 10.5 5.6886 11.171 5.6886 12C5.6886 12.829 5.0176 13.5 4.1886 13.5ZM12.1886 13.5C11.3596 13.5 10.6836 12.829 10.6836 12C10.6836 11.171 11.3506 10.5 12.1786 10.5H12.1886C13.0176 10.5 13.6886 11.171 13.6886 12C13.6886 12.829 13.0176 13.5 12.1886 13.5ZM20.1886 13.5C19.3596 13.5 18.6836 12.829 18.6836 12C18.6836 11.171 19.3506 10.5 20.1786 10.5H20.1886C21.0176 10.5 21.6886 11.171 21.6886 12C21.6886 12.829 21.0176 13.5 20.1886 13.5Z'
        fill={color || "#2680EA"}
      />
    </svg>
  )
}

export default MoreActionIcon
