import { useSelector } from "react-redux"
import {
  MoneyMovementIndicator,
  RewardsRecordType,
  RewardsRedirectionType,
  benefitType,
} from "../../../utils/constants"
import { formatToWholeCurrency } from "../../../utils/functions"
import CloseButton from "../../svg/v3/closeButton"
import ClockBig from "../../svg/v3/ClockBig"
import ArrowBackBig from "../../svg/v3/ArrowBackBig"
import RedeemedRewardIcon from "../../svg/v3/RedeemedRewardIcon"
import Rewards from "../../svg/v3/Rewards"
import Cashback from "../../svg/v3/Cashback"
import MilestoneRewardIcon from "../../svg/v3/MilestoneRewardIcon"

const TransactionBottomSheetHeader = ({
  transaction,
  isReward = false,
  isCashback = false,
  onCrossBtnClick,
}) => {
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const rewardRedirectionType = screen?.home?.reward?.redirectionTypesV3

  const {
    primary: {
      color1: primaryColor1,
      color2: primaryColor2,
      color3: primaryColor3,
    },
    tertiaryNeutral: {
      color1: tertiaryNeutralColor1,
      color2: tertiaryNeutralColor2,
    },
    leadingWhite,
  } = theme?.v3?.rawColors

  const getTextStyle = () => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      if (
        transaction?.moneyMovementIndicator === MoneyMovementIndicator.CREDIT
      ) {
        return { color: "var(--tertiary-positive-color-1)" }
      } else {
        return {
          color: "var(--tertiary-neutral-color-2)",
          textDecorationLine: "line-through",
        }
      }
    } else {
      switch (transaction?.recordType || transaction?.status) {
        case RewardsRecordType.POSTED:
          return { color: "var(--tertiary-positive-color-1)" }
        case RewardsRecordType.EARNED:
          return { color: "var(--tertiary-positive-color-1)" }
        case RewardsRecordType.REVERSED:
          return {
            color: "var(--tertiary-neutral-color-2)",
            textDecorationLine: "line-through",
          }
        case RewardsRecordType.EXPIRED:
          return {
            color: "var(--tertiary-neutral-color-2)",
            textDecorationLine: "line-through",
          }
        case RewardsRecordType.PENDING:
          return { color: "var(--tertiary-caution-color-1)" }
        default:
          return { color: "var(--tertiary-neutral-color-1)" }
      }
    }
  }

  const getTransactionLogo = () => {
    if (transaction?.recordType === RewardsRecordType.REDEEMED) {
      return (
        <RedeemedRewardIcon
          color={primaryColor1}
          customClassnames='v3-rewards-cashback-header-redeemed-icon'
        />
      )
    } else if (transaction?.recordType === RewardsRecordType.EXPIRED) {
      return (
        <Rewards
          circleColor={tertiaryNeutralColor2}
          powerColor={leadingWhite}
          customClassname='v3-rewards-cashback-default-icon'
        />
      )
    } else if (
      transaction?.benefitDetails?.benefitType === benefitType.MILESTONE
    ) {
      return (
        <MilestoneRewardIcon
          color={primaryColor1}
          customClassnames='v3-rewards-cashback-milestone-icon'
        />
      )
    } else if (transaction?.transactionDetails?.identifiedMerchantLogo) {
      return (
        <img
          src={`data:image/jpg;base64,${transaction?.transactionDetails?.identifiedMerchantLogo}`}
          alt='rewards-icon'
        />
      )
    } else {
      if (isReward) {
        return (
          <Rewards
            circleColor={primaryColor1}
            powerColor={leadingWhite}
            customClassname='v3-rewards-cashback-default-icon'
          />
        )
      } else {
        return (
          <Cashback
            customClassname={"v3-reward-cashback-btmSheet-cashback-icon"}
            circleColor={primaryColor1}
            backgroundColor={primaryColor3}
            borderColor={primaryColor2}
            shadowColor={tertiaryNeutralColor1}
            rupeeColor={leadingWhite}
          />
        )
      }
    }
  }

  const getHeaderText = () => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      return transaction?.merchantName
    } else if (transaction?.recordType === RewardsRecordType.REDEEMED) {
      return "Redeemed Reward Points"
    } else {
      return transaction?.narration
    }
  }

  const getPointsText = () => {
    if (isReward) {
      return `${Number(transaction?.points)} pts`
    } else if (isCashback) {
      return `${formatToWholeCurrency(transaction?.cashbackAmount)}`
    }
  }

  return (
    <div className='v3-rewards-cashback-bottom-sheet-header'>
      <div className='v3-rewards-cashback-bottom-sheet-header-content'>
        <div className='v3-rewards-cashback-bottom-sheet-header-merchant-logo'>
          {getTransactionLogo()}
        </div>
        <div className='v3-rewards-cashback-bottom-sheet-header-text'>
          <div>{getHeaderText()}</div>
          <div>
            {(transaction?.recordType === RewardsRecordType.PENDING ||
              transaction?.status === RewardsRecordType.PENDING) && (
              <ClockBig color={theme.v3.rawColors.tertiaryCaution.color1} />
            )}
            {(transaction?.recordType === RewardsRecordType.REVERSED ||
              transaction?.status === RewardsRecordType.REVERSED) && (
              <ArrowBackBig color={theme.v3.rawColors.tertiaryNeutral.color2} />
            )}
            <div style={getTextStyle()}>
              {(transaction?.recordType === RewardsRecordType.EARNED ||
                transaction?.status === RewardsRecordType.POSTED ||
                transaction?.moneyMovementIndicator ===
                  MoneyMovementIndicator.CREDIT) &&
                "+"}
              {(transaction?.recordType === RewardsRecordType.REDEEMED ||
                transaction?.moneyMovementIndicator ===
                  MoneyMovementIndicator.DEBIT) &&
                "-"}
              {isCashback && " "}
              {getPointsText()}
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => onCrossBtnClick()}>
        <CloseButton
          color={theme.v3.rawColors.tertiaryNeutral.color1}
          background={theme.v3.rawColors.tertiaryNeutral.color3}
        />
      </div>
    </div>
  )
}

export default TransactionBottomSheetHeader
