import React from "react"

const BlackCheckCIrcle = () => {
  return (
    <svg
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 0.125C2.536 0.125 0.125 2.5365 0.125 5.5C0.125 8.4635 2.536 10.875 5.5 10.875C8.464 10.875 10.875 8.4635 10.875 5.5C10.875 2.5365 8.464 0.125 5.5 0.125ZM5.5 10.125C2.9495 10.125 0.875 8.0505 0.875 5.5C0.875 2.9495 2.9495 0.875 5.5 0.875C8.0505 0.875 10.125 2.9495 10.125 5.5C10.125 8.0505 8.0505 10.125 5.5 10.125ZM7.515 4.06799C7.6615 4.21449 7.6615 4.45201 7.515 4.59851L5.1815 6.93201C5.1085 7.00501 5.0125 7.04199 4.9165 7.04199C4.8205 7.04199 4.7245 7.00551 4.6515 6.93201L3.485 5.7655C3.3385 5.619 3.3385 5.38149 3.485 5.23499C3.6315 5.08849 3.869 5.08849 4.0155 5.23499L4.91701 6.13651L6.985 4.06851C7.1315 3.92201 7.3685 3.92199 7.515 4.06799Z'
        fill='#A8A8A8'
      />
    </svg>
  )
}

export default BlackCheckCIrcle
