import React from "react"

const SuccessTick = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M7.9987 1.33337C4.3187 1.33337 1.33203 4.32004 1.33203 8.00004C1.33203 11.68 4.3187 14.6667 7.9987 14.6667C11.6787 14.6667 14.6654 11.68 14.6654 8.00004C14.6654 4.32004 11.6787 1.33337 7.9987 1.33337ZM10.6854 6.80005L7.57202 9.9067C7.47869 10.0067 7.35202 10.0534 7.21869 10.0534C7.09202 10.0534 6.96536 10.0067 6.86536 9.9067L5.31203 8.35339C5.1187 8.16006 5.1187 7.84002 5.31203 7.64669C5.50537 7.45335 5.82536 7.45335 6.0187 7.64669L7.21869 8.84672L9.9787 6.09338C10.172 5.89338 10.492 5.89338 10.6854 6.09338C10.8787 6.28672 10.8787 6.60005 10.6854 6.80005Z'
        fill={color}
      />
    </svg>
  )
}

export default SuccessTick
