import React from "react"
import moment from "moment"
import { useSelector } from "react-redux"
import {
  MoneyMovementIndicator,
  RewardsRecordType,
  RewardsRedirectionType,
  benefitType,
} from "../../../utils/constants"
import { formatToWholeCurrency } from "../../../utils/functions"
import RedeemedRewardIcon from "../../svg/v3/RedeemedRewardIcon"
import Cashback from "../../svg/v3/Cashback"
import MilestoneRewardIcon from "../../svg/v3/MilestoneRewardIcon"
import RewardsIcon from "../../svg/v3/RewardsIcon"
import Clock from "../../svg/v3/clock"
import ArrowBack from "../../svg/v3/arrowBack"

const RewardTransaction_v3 = ({
  transaction,
  isReward = false,
  isCashback = false,
  isExpiring = false,
  onTransactionClick,
  isLast = false,
}) => {
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)

  const rewardRedirectionType = screen?.home?.reward?.redirectionTypesV3

  const {
    primary: {
      color1: primaryColor1,
      color2: primaryColor2,
      color3: primaryColor3,
    },
    tertiaryNeutral: {
      color1: tertiaryNeutralColor1,
      color2: tertiaryNeutralColor2,
    },
    tertiaryCaution: { color1: tertiaryCautionColor1 },
    leadingWhite,
  } = theme?.v3?.rawColors

  const getTextStyle = () => {
    if (isExpiring) {
      return { color: "var(--primary-base-color-2)" }
    } else if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      if (
        transaction?.moneyMovementIndicator === MoneyMovementIndicator.CREDIT
      ) {
        return { color: "var(--tertiary-positive-color-1)" }
      } else {
        return {
          color: "var(--tertiary-neutral-color-2)",
          textDecorationLine: "line-through",
        }
      }
    } else {
      switch (transaction?.recordType || transaction?.status) {
        case RewardsRecordType.POSTED:
          return { color: "var(--tertiary-positive-color-1)" }
        case RewardsRecordType.EARNED:
          return { color: "var(--tertiary-positive-color-1)" }
        case RewardsRecordType.REVERSED:
          return {
            color: "var(--tertiary-neutral-color-2)",
            textDecorationLine: "line-through",
          }
        case RewardsRecordType.EXPIRED:
          return {
            color: "var(--tertiary-neutral-color-2)",
            textDecorationLine: "line-through",
          }
        case RewardsRecordType.PENDING:
          return { color: "var(--tertiary-caution-color-1)" }
        default:
          return { color: "var(--tertiary-neutral-color-1)" }
      }
    }
  }

  const getActivityDateTimeText = () => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      return moment(transaction?.metadata?.txnDate).format("DD MMM, hh:mm A")
    }
    if (isExpiring) {
      return `expiring on ${moment(transaction?.activityDateTime).format("DD MMM")}`
    } else if (
      (transaction?.recordType === RewardsRecordType.PENDING ||
        transaction?.status === RewardsRecordType.PENDING) &&
      (transaction?.postingDateTime || transaction?.postedOn)
    ) {
      return `to be credited on ${moment(transaction?.postingDateTime || transaction?.postedOn).format("DD MMM")}`
    } else if (transaction?.activityDateTime) {
      return moment(transaction?.activityDateTime).format("DD MMM, hh:mm A")
    }
  }

  const getTransactionLogo = () => {
    if (transaction?.recordType === RewardsRecordType.REDEEMED) {
      return <RedeemedRewardIcon color={primaryColor1} />
    } else if (transaction?.recordType === RewardsRecordType.EXPIRED) {
      return (
        <RewardsIcon
          backgroundColor={tertiaryNeutralColor2}
          color={leadingWhite}
        />
      )
    } else if (
      transaction?.benefitDetails?.benefitType === benefitType?.MILESTONE
    ) {
      return <MilestoneRewardIcon color={primaryColor1} />
    } else if (transaction?.transactionDetails?.identifiedMerchantLogo) {
      return (
        <img
          src={`data:image/jpg;base64,${transaction?.transactionDetails?.identifiedMerchantLogo}`}
          alt='rewards-icon'
        />
      )
    } else {
      if (isReward) {
        return (
          <RewardsIcon backgroundColor={primaryColor1} color={leadingWhite} />
        )
      } else {
        return (
          <Cashback
            customClassname={"v3-reward-cashback-transaction-cashback-icon"}
            circleColor={primaryColor1}
            backgroundColor={primaryColor3}
            borderColor={primaryColor2}
            shadowColor={tertiaryNeutralColor1}
            rupeeColor={leadingWhite}
          />
        )
      }
    }
  }

  const getRewardText = () => {
    if (
      rewardRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS)
    ) {
      return transaction?.merchantName
    } else if (transaction?.recordType === RewardsRecordType.REDEEMED) {
      return "Redeemed Reward Points"
    } else {
      return transaction?.narration
    }
  }

  const getPointsText = () => {
    if (isReward) {
      return `${Number(transaction?.points)} pts`
    } else if (isCashback) {
      return `${formatToWholeCurrency(transaction?.cashbackAmount)}`
    }
  }

  return (
    <div
      className={`v3-rewards-cashback-transaction ${isExpiring ? "v3-rewards-cashback-transaction-expiring" : ""} ${isLast ? "v3-rewards-cashback-transaction-expiring-last" : ""}`}
      onClick={onTransactionClick}
    >
      <div className='v3-rewards-cashback-transaction-logo'>
        {getTransactionLogo()}
      </div>
      <div
        className={`v3-rewards-cashback-transactions-details ${isExpiring ? "v3-rewards-cashback-transaction-details-expiring" : ""}`}
      >
        <div className='v3-rewards-cashback-transactions-details-text'>
          <div>{getRewardText()}</div>
          <div>{getActivityDateTimeText()}</div>
        </div>
        <div className='v3-rewards-cashback-transactions-points'>
          {(transaction?.recordType === RewardsRecordType.PENDING ||
            transaction?.status === RewardsRecordType.PENDING) &&
            !isExpiring && <Clock color={tertiaryCautionColor1} />}
          {(transaction?.recordType === RewardsRecordType.REVERSED ||
            transaction?.status === RewardsRecordType.REVERSED) &&
            !isExpiring && <ArrowBack color={tertiaryNeutralColor2} />}
          <div style={getTextStyle()}>
            {(transaction?.recordType === RewardsRecordType.EARNED ||
              transaction?.status === RewardsRecordType.POSTED ||
              transaction?.moneyMovementIndicator ===
                MoneyMovementIndicator.CREDIT) &&
              !isExpiring &&
              "+"}
            {(transaction?.recordType === RewardsRecordType.REDEEMED ||
              transaction?.moneyMovementIndicator ===
                MoneyMovementIndicator.DEBIT) &&
              !isExpiring &&
              "-"}
            {getPointsText()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RewardTransaction_v3
