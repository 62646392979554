import { CLEAR_COMMON_STATE, SET_COMMON_STATE } from "../ActionTypes"

export const setCommonState = data => dispatch => {
  dispatch({
    type: SET_COMMON_STATE,
    payload: data,
  })
}

export const clearCommonState = () => dispatch => {
  dispatch({
    type: CLEAR_COMMON_STATE,
  })
}
