import { createPortal } from "react-dom"
import { DisplayConfig, PWA_MAX_WIDTH_IN_PX } from "../utils/constants"
import View from "./View"
import useWindowDimensions from "../hooks/useWindowDimensionsWeb"
import { Fragment, useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose } from "@fortawesome/free-solid-svg-icons"

/**
 * @param {{ isOpen: boolean; onClose: () => void, children: import('react').PropsWithChildren<{}>['children'] }} props
 */
const Modal = ({ isOpen, onClose, children, header, ...restProps }) => {
  const { width } = useWindowDimensions()

  if (!isOpen) {
    return <Fragment />
  }
  return createPortal(
    <ModalOverlay windowWidth={width}>
      <ModalContainer {...restProps}>
        <StyledFontAwesomeIcon size='16px' onClick={onClose} icon={faClose} />
        {!!header && <ModalHeaderContainer>{header}</ModalHeaderContainer>}
        <ModalBodyContainer {...restProps}>{children}</ModalBodyContainer>
      </ModalContainer>
    </ModalOverlay>,
    document.body,
  )
}

export default Modal

const ModalOverlay = styled.div`
  width: ${DisplayConfig.MAX_WIDTH};
  height: 100vh;
  position: absolute;
  top: 0;
  left: ${({ windowWidth }) => (windowWidth - PWA_MAX_WIDTH_IN_PX) / 2}px;
  margin: 0 auto;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ModalContainer = styled.div`
  width: 75%;
  max-width: 380px;
  max-height: 604px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: rgb(250, 250, 250);
  position: relative;
  overflow: auto;
`

const ModalHeaderContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(212, 212, 212, 1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 600 !important;
  font:
    14px -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 1rem;
`

const ModalBodyContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid rgba(212, 212, 212, 1);
  display: flex;
  flex-direction: column;
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 11;
  height: 20px;
  width: 20px;
`
