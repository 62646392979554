import React from "react"

const AlertIconRounded = ({
  width = 40,
  height = 40,
  fillColor = "#0D2950",
  backgroundColor = "#D3D8DF",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='40' height='40' rx='20' fill={backgroundColor} />
    <path
      d='M28.0057 24.2935L22.4999 13.9966C21.4333 12.0016 18.5674 12.0016 17.4999 13.9966L11.9942 24.2935C11.0158 26.1235 12.3442 28.3334 14.4234 28.3334H25.5765C27.6549 28.3334 28.9841 26.1226 28.0057 24.2935ZM19.3749 18.3334C19.3749 17.9884 19.6549 17.7084 19.9999 17.7084C20.3449 17.7084 20.6249 17.9884 20.6249 18.3334V21.6667C20.6249 22.0117 20.3449 22.2917 19.9999 22.2917C19.6549 22.2917 19.3749 22.0117 19.3749 21.6667V18.3334ZM20.0166 25C19.5566 25 19.179 24.6267 19.179 24.1667C19.179 23.7067 19.5483 23.3334 20.0083 23.3334H20.0166C20.4775 23.3334 20.85 23.7067 20.85 24.1667C20.85 24.6267 20.4766 25 20.0166 25Z'
      fill={fillColor}
    />
  </svg>
)

export default AlertIconRounded
