import React from "react"
import { useSelector } from "react-redux"
import { RewardsBottomSheetVariant } from "../../../utils/constants"
import Rewards from "../../svg/v3/Rewards"
import Hourglass_2 from "../../svg/v3/Hourglass_2"
import CloseButton from "../../svg/v3/closeButton"

const BottomSheetHeader = ({ text, points, variant, onCloseBtnClick }) => {
  const theme = useSelector(state => state.theme)
  const {
    primary: { color1: primaryColor1 },
    secondary: { color4: secondaryColor4 },
    tertiaryNegative: { color1: tertiaryNegativeColor1 },
    leadingWhite,
  } = theme?.v3?.rawColors

  return (
    <div className='v3-rewards-bottomm-sheet-header'>
      <div>
        {variant === RewardsBottomSheetVariant.EXPIRING_SOON ? (
          <div className='v3-rewards-bottom-sheet-hourglass'>
            <Hourglass_2
              color={tertiaryNegativeColor1}
              background={secondaryColor4}
            />
          </div>
        ) : (
          <div className='v3-rewards-bottom-sheet-reward-icon-container'>
            <Rewards
              circleColor={primaryColor1}
              powerColor={leadingWhite}
              customClassname='v3-rewards-bottom-sheet-reward-icon'
            />
          </div>
        )}

        <div>
          {variant === RewardsBottomSheetVariant.LIFETIME_POINTS && (
            <div
              className='v3-rewards-bottom-sheet-header-text'
              style={{ marginBottom: "-4px" }}
            >
              {text}
            </div>
          )}
          <div
            className='v3-rewards-bottom-sheet-header-points'
            style={{
              color:
                variant === RewardsBottomSheetVariant.LIFETIME_POINTS
                  ? "var(--tertiary-neutral-color-1)"
                  : "var(--tertiary-negative-color-1)",
            }}
          >
            {points} Pts
          </div>
          {variant === RewardsBottomSheetVariant.EXPIRING_SOON && (
            <div
              className='v3-rewards-bottom-sheet-header-text'
              style={{ marginTop: "-4px" }}
            >
              {text}
            </div>
          )}
        </div>
      </div>
      <div onClick={() => onCloseBtnClick()}>
        <CloseButton />
      </div>
    </div>
  )
}

export default BottomSheetHeader
