import React from "react"
import PwaRedirectionImageLoader from "../../../../core/SkeletonLoader/PwaRedirectionImageLoader"
import Lottie from "lottie-react"
import { replaceColor } from "lottie-colorify"
import pwaLoader from "../../../../svg/v3/pwaLoader.json"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import hfLogo from "../../../../../assets/images/v3/active/hfLogo.svg"

const PwaWebRedirectionLoader = ({ loaderAnimation }) => {
  const { t } = useTranslation()
  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  return (
    <div className='v3-web-redirection-loader-container'>
      <div className='v3-web-redirection-loader-top-section'>
        {session?.isCssLoaded ? (
          <div className='v3-web-redirection-loader-animation-container'>
            <Lottie
              loop={true}
              animationData={replaceColor(
                "#005aff",
                theme.v3.rawColors.primary.color1,
                pwaLoader,
              )}
            />
          </div>
        ) : (
          <div>
            <PwaRedirectionImageLoader />
          </div>
        )}
        <div className='v3-web-redirection-loader-text-container'>
          <div className='v3-web-redirection-loader-text-header'>
            {t("loader.redirectionLoaderText")}
          </div>
          <div className='v3-web-redirection-loader-text-description'>
            {t("loader.redirectionLoaderDescription")}
          </div>
        </div>
      </div>
      <div className='v3-web-redirection-loader-bottom-section'>
        <div className='v3-web-redirection-loader-hf-branding-text'>
          {t("loader.poweredBy")}
        </div>
        <div className='v3-web-redirection-loader-hf-branding-logo'>
          <img src={hfLogo} alt='hf logo' />
        </div>
      </div>
    </div>
  )
}

export default PwaWebRedirectionLoader
