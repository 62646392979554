import React from "react"

const ViewArrow = ({ color = "#1965CF" }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='12'
    height='13'
    viewBox='0 0 12 13'
    fill='none'
  >
    <path
      d='M4.50014 10.5C4.37214 10.5 4.24412 10.451 4.14662 10.3535C3.95112 10.158 3.95112 9.842 4.14662 9.6465L7.29311 6.50002L4.14662 3.35353C3.95112 3.15803 3.95112 2.842 4.14662 2.6465C4.34212 2.451 4.65816 2.451 4.85366 2.6465L8.35366 6.1465C8.54916 6.342 8.54916 6.65803 8.35366 6.85353L4.85366 10.3535C4.75616 10.451 4.62814 10.5 4.50014 10.5Z'
      fill={color}
    />
  </svg>
)

export default ViewArrow
