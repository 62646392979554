const MilestoneRewardIcon = ({ customClassnames, color = "#1965CF" }) => {
  return (
    <div className={`v3-reward-redeemed-icon ${customClassnames}`}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M15 8.5L19 14H5.75V20.96C5.75 21.37 5.41 21.71 5 21.71C4.59 21.71 4.25 21.37 4.25 20.96V14V6C4.25 4 5.25 3 7.25 3H19L15 8.5Z'
          fill={color}
        />
      </svg>
    </div>
  )
}

export default MilestoneRewardIcon
