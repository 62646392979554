import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { RewardsRedirectionType } from "../../../../../../utils/constants"
import { formatToWholeCurrency } from "../../../../../../utils/functions"
import CommonService from "../../../../../../services/CommonService"
import Rewards from "../../../../../svg/v3/Rewards"
import Cashback from "../../../../../svg/v3/Cashback"
import ArrowRightSmall from "../../../../../svg/v3/arrowRightSmall"
import ArrowRightSolidSmall from "../../../../../svg/v3/arrowRightSolidSmall"

const RewardsAndCashbacks = () => {
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const navigate = useNavigate()

  const [isRedirectionLoading, setRedirectionLoading] = useState(false)

  const {
    primary: {
      color1: primaryColor1,
      color2: primaryColor2,
      color3: primaryColor3,
      color4: primaryColor4,
    },
    tertiaryNeutral: { color1: tertiaryColor1 },
    leadingWhite,
  } = theme?.v3?.rawColors

  const rewardsRedirectionType = screen?.home?.reward?.redirectionTypesV3

  const handleViewClick = path => {
    if (path === "/rewards") {
      ;(async () => {
        setRedirectionLoading(true)
        if (rewardsRedirectionType.includes(RewardsRedirectionType.SSO)) {
          const response = await CommonService.getLrSsoUrl()
          if (response.data.success) {
            window.location.href = response.data.data.ssoUrl
          } else {
            navigate("/rewards")
            setRedirectionLoading(false)
          }
        } else if (
          rewardsRedirectionType.includes(RewardsRedirectionType.URL)
        ) {
          window.location.href = screen?.home?.reward?.lrUrl
        } else {
          navigate("/rewards")
        }
        setRedirectionLoading(false)
      })()
    } else {
      navigate(path)
    }
  }

  const rewards =
    user?.rewardSummary?.currentValue || user?.aggregate?.available || 0

  const cashback = user?.hfCashback?.earned || 0

  const isRewardsURL =
    rewardsRedirectionType?.includes(RewardsRedirectionType.SSO) ||
    rewardsRedirectionType?.includes(RewardsRedirectionType.URL)

  const showRewards =
    rewardsRedirectionType?.includes(RewardsRedirectionType.LR_TRANSACTIONS) ||
    rewardsRedirectionType?.includes(RewardsRedirectionType.HF_REWARDS) ||
    isRewardsURL

  const showCashback = rewardsRedirectionType?.includes(
    RewardsRedirectionType.HF_CASHBACK,
  )

  const showBoth = showRewards && showCashback

  return (
    <>
      {(showRewards || showCashback) && (
        <div className='v3-rewards-cashbacks-container'>
          {showRewards && (
            <div
              className={`v3-rewards-cashback-wrapper ${!showBoth ? "v3-rewards-cashback-wrapper-width" : ""}`}
            >
              <div className='v3-rewards-cashback-left-container'>
                <Rewards
                  backgroundColor={primaryColor4}
                  circleColor={primaryColor1}
                  powerColor={leadingWhite}
                />
                <div
                  className={`v3-rewards-cashback-text-container ${showBoth ? "v3-rewards-cashback-text-container-small" : ""}`}
                >
                  {!isRewardsURL && (
                    <div className='v3-rewards-cashback-text-number'>
                      {Number(rewards)}
                    </div>
                  )}
                  <div
                    className={`v3-rewards-cashback-text ${showBoth ? "v3-rewards-cashback-text-small" : ""}`}
                    onClick={() => handleViewClick("/rewards")}
                  >
                    {isRewardsURL
                      ? "Check your Reward Points"
                      : showBoth
                        ? "Points available"
                        : "Reward points available"}
                    {showBoth && (
                      <ArrowRightSmall
                        color={theme.v3.cssVars.primary.color1}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!showBoth && (
                <div
                  className='v3-rewards-cashback-view-btn'
                  onClick={() => handleViewClick("/rewards")}
                >
                  <div className='v3-rewards-cashback-view-btn-text'>
                    View now
                  </div>
                  <ArrowRightSolidSmall color={primaryColor1} />
                </div>
              )}
            </div>
          )}

          {showBoth && <div className='v3-rewards-cashback-divider'></div>}

          {showCashback && (
            <div
              className={`v3-rewards-cashback-wrapper ${!showBoth ? "v3-rewards-cashback-wrapper-width" : ""}`}
            >
              <div className='v3-rewards-cashback-left-container'>
                <Cashback
                  circleColor={primaryColor1}
                  backgroundColor={primaryColor3}
                  borderColor={primaryColor2}
                  shadowColor={tertiaryColor1}
                  rupeeColor={leadingWhite}
                />
                <div
                  className={`v3-rewards-cashback-text-container ${showBoth ? "v3-rewards-cashback-text-container-small" : ""}`}
                >
                  <div className='v3-rewards-cashback-text-number'>
                    {formatToWholeCurrency(cashback)}
                  </div>
                  <div
                    className={`v3-rewards-cashback-text ${showBoth ? "v3-rewards-cashback-text-small" : ""}`}
                    onClick={() => handleViewClick("/cashback")}
                  >
                    Cashbacks earned
                    {showBoth && <ArrowRightSmall color={primaryColor1} />}
                  </div>
                </div>
              </div>
              {!showBoth && (
                <div
                  className='v3-rewards-cashback-view-btn'
                  onClick={() => handleViewClick("/cashback")}
                >
                  <div className='v3-rewards-cashback-view-btn-text'>
                    View now
                  </div>
                  <ArrowRightSolidSmall color={primaryColor1} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default RewardsAndCashbacks
