import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import ToastExclamation from "../svg/toastExclamation"
import ToastTick from "../svg/toastTick"
import View from "../../nativeBaseReplacements/View"
import Text from "../../nativeBaseReplacements/Text"

const Toast = props => {
  const theme = useSelector(state => state.theme)
  let scrollFromTopPercentage =
    (parseFloat(window.scrollY) * 100) / parseFloat(window.innerHeight)
  // let topmargin = 77
  // let topmarginInVH = topmargin + scrollFromTopPercentage + "dvh"

  return (
    <View
      bgColor={theme.toastBgColor}
      flexDirection='row'
      // px='12px'
      // py='12px'
      m={"2px"}
      alignItems='center'
      justifyContent='center'
      borderRadius='32px'
      // mt={topmarginInVH}
    >
      {props.children}
    </View>
  )
}

export const showToast1 = ({
  nativeToast,
  theme,
  config,
  message,
  hasTick = false,
  hasExclamationMark = false,
  topmarginVH,
}) => {
  let newMessage = message.replace(/\n/g, "")
  nativeToast.show({
    duration: 3000,
    render: () => {
      return (
        <Toast topmarginVH={topmarginVH}>
          {hasTick && (
            <View w='24px' h='24px' display='flex'>
              <ToastTick />
            </View>
          )}
          {hasExclamationMark && (
            <View w='24px' h='24px' display='flex'>
              <ToastExclamation />
            </View>
          )}
          <View
            ml={(hasTick || hasExclamationMark) && "8px"}
            width={"100%"}
            maxWidth={"380px"}
          >
            <Text
              color={theme.toastColor}
              fontFamily={theme.fontFamily}
              fontWeight='600'
              fontSize='16px'
              lineHeight='19px'
              letterSpacing='0.01em'
              textAlign={"center"}
              flexWrap={"wrap"}
            >
              {newMessage}
            </Text>
          </View>
        </Toast>
      )
    },
    placement:
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
        ? "top"
        : "top",
  })
}

export default Toast
