import React from "react"
import TransactionSection_v3 from "../TransactionSection/v3/TransactionSection_v3"

const RecentTransactions = () => {
  return (
    <div className='v3-activity-section-transactions'>
      <TransactionSection_v3 />
    </div>
  )
}

export default RecentTransactions
