import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import Paths from "../../../routes/Paths"
import CardService from "../../../services/CardService"
import { handleApiAuth } from "../../../utils/auth"
import { DisplayConfig } from "../../../utils/constants"
import { EventName, PwaVersions } from "../../../utils/enums"
import { captureEvents, consoleError } from "../../../utils/functions"
import { showToast1 } from "../../core/toast"
import ResetPin_v2 from "./ResetPin_v2"
import View from "../../../nativeBaseReplacements/View"
import { useToast } from "../../../nativeBaseReplacements/useToast"

const ResetPin = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const featureFlags = useSelector(state => state.featureFlags)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()
  const nativeToast = useToast()
  const location = useLocation()

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pin, setPin] = useState(["", "", "", ""])
  const [confirmPin, setConfirmPin] = useState(["", "", "", ""])

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/")
    }
  }, [user.account.status, user.cards, navigate])

  const isActivationFlow = location.state?.from === "Activation"

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const Cancel = () => {
    navigate(-1)
  }

  const handleSubmit = async () => {
    captureEvents({ eventName: EventName.RESET_PIN_CTA, metadata: {} })
    if (pin.join("") === confirmPin.join("")) {
      setSubmitLoading(true)

      await handleApiAuth({
        apiId: ApiIds.SET_CARD_PIN,
        flowId: isActivationFlow ? "4" : "3",
        onAuthSuccess: onAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: "to reset card pin",
        mpinReason: "to reset card pin",
        toast,
        navigate,
      })

      setSubmitLoading(false)
    } else {
      setError(true)
      captureEvents({
        eventName: EventName.RESET_PIN_ERROR,
        metadata: { msg: t("messages.pinMismatchError") },
      })
      toast(
        config?.version === PwaVersions.V2_1
          ? t("messages.bothPinsDoNotMatch")
          : t("messages.pinMismatchError"),
      )
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.resetPin(apiToken, user.cards[0]?.id, {
        cardPin: pin.join(""),
      })
      const result = response?.data
      if (result?.success) {
        captureEvents({ eventName: EventName.RESET_PIN_SUCCESS, metadata: {} })
        if (isActivationFlow) {
          if (
            (config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1) &&
            screen?.home?.isCardControlsInActivationFlow
          ) {
            navigate(Paths.ACTIVATION_CARD_CONTROLS, { replace: true })
          } else {
            navigate(Paths.HOME_WITH_ACTIVATE, { replace: true })
          }
        } else {
          navigate("/ManageCard", { replace: true })
        }

        // TODO: in activation flow, calling toast before navigate prevents toast from disappearing. Find why.
        toast(t("messages.cardPinUpdated"))
      } else {
        consoleError(result.errors)
        captureEvents({
          eventName: EventName.RESET_PIN_ERROR,
          metadata: { msg: t("messages.resetPinErrorMessage") },
        })
        toast(t("messages.resetPinErrorMessage"))
        navigate("/ManageCard", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      captureEvents({
        eventName: EventName.RESET_PIN_ERROR,
        metadata: { msg: t("messages.resetPinErrorMessage") },
      })
      toast(t("messages.errorResettingPin"))
      navigate("/ManageCard", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    captureEvents({
      eventName: EventName.RESET_PIN_ERROR,
      metadata: { msg: message },
    })
    message && toast(message)
    navigate("/ManageCard", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    captureEvents({
      eventName: EventName.RESET_PIN_ERROR,
      metadata: { msg: message },
    })
    navigate("/ManageCard", { replace: true })
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        {config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1 ? (
          <ResetPin_v2
            confirmPin={confirmPin}
            setConfirmPin={setConfirmPin}
            pin={pin}
            setPin={setPin}
            error={error}
            submitLoading={submitLoading}
            handleSubmit={handleSubmit}
            Cancel={Cancel}
            theme={theme}
          />
        ) : (
          <></>
        )}
      </View>
    </View>
  )
}

export default ResetPin
