import React from "react"

const RightArrow = () => {
  return (
    <svg
      width='48'
      height='36'
      viewBox='0 0 48 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='48' height='36' rx='12' fill='white' fill-opacity='0.2' />
      <path
        opacity='0.4'
        d='M31.4998 18.8333H16.4998C16.039 18.8333 15.6665 18.46 15.6665 18C15.6665 17.54 16.039 17.1667 16.4998 17.1667H31.4998C31.9607 17.1667 32.3332 17.54 32.3332 18C32.3332 18.46 31.9607 18.8333 31.4998 18.8333Z'
        fill='white'
      />
      <path
        d='M25.6666 24.6667C25.4532 24.6667 25.2399 24.5851 25.0774 24.4226C24.7515 24.0967 24.7515 23.57 25.0774 23.2442L30.3215 18L25.0774 12.7559C24.7515 12.4301 24.7515 11.9033 25.0774 11.5775C25.4032 11.2517 25.9299 11.2517 26.2558 11.5775L32.0891 17.4108C32.4149 17.7367 32.4149 18.2634 32.0891 18.5892L26.2558 24.4226C26.0933 24.5851 25.8799 24.6667 25.6666 24.6667Z'
        fill='white'
      />
    </svg>
  )
}

export default RightArrow
