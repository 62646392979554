import React from "react"

const OfflineIcon = ({ color1, color2 }) => {
  return (
    <svg
      width='33'
      height='32'
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' width='32' height='32' rx='16' fill={color2 || '#F5FAFF'} />
      <path
        d='M16.1663 21.373V16.4997H9.99967C9.90634 16.4997 9.83301 16.573 9.83301 16.6663C9.83301 17.9997 10.4997 18.6663 11.833 18.6663H14.4997V20.833H12.833C12.5597 20.833 12.333 21.0597 12.333 21.333C12.333 21.6063 12.5597 21.833 12.833 21.833H16.1997C16.1797 21.6863 16.1663 21.533 16.1663 21.373ZM19.833 9.33301H11.833C10.4997 9.33301 9.83301 9.99967 9.83301 11.333V15.333C9.83301 15.4263 9.90634 15.4997 9.99967 15.4997H16.1997C16.373 14.3597 17.1996 13.6663 18.453 13.6663C18.453 13.6663 21.453 13.6663 21.633 13.6663C21.773 13.6663 21.833 13.5463 21.833 13.4663C21.833 13.3863 21.833 11.333 21.833 11.333C21.833 9.99967 21.1663 9.33301 19.833 9.33301ZM21.8804 14.6663H18.4517C17.5943 14.6663 17.1657 15.0976 17.1657 15.9609V21.3717C17.1657 22.2351 17.5943 22.6663 18.4517 22.6663H21.8804C22.7377 22.6663 23.1663 22.2351 23.1663 21.3717V15.9609C23.1663 15.0976 22.7377 14.6663 21.8804 14.6663ZM20.1663 21.6663C19.7983 21.6663 19.4997 21.3677 19.4997 20.9997C19.4997 20.6317 19.7983 20.333 20.1663 20.333C20.5343 20.333 20.833 20.6317 20.833 20.9997C20.833 21.3677 20.5343 21.6663 20.1663 21.6663Z'
        fill={color1 ||'#2680EA'}
      />
    </svg>
  )
}
export default OfflineIcon
