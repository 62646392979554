import React from "react"

const CalendarSvg = ({ color = "#0D2950", secondaryColor = "#25314C" }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='calendar-plus'>
        <path
          id='Vector'
          opacity='0.4'
          d='M2 6V12C2 13.3333 2.66667 14 4 14H12C13.3333 14 14 13.3333 14 12V6H2Z'
          fill={color}
        />
        <path
          id='Vector_2'
          d='M12 3H11.1667V2C11.1667 1.724 10.9427 1.5 10.6667 1.5C10.3907 1.5 10.1667 1.724 10.1667 2V3H5.83333V2C5.83333 1.724 5.60933 1.5 5.33333 1.5C5.05733 1.5 4.83333 1.724 4.83333 2V3H4C2.66667 3 2 3.66667 2 5V6H14V5C14 3.66667 13.3333 3 12 3Z'
          fill={color}
        />
        <path
          id='Vector_3'
          d='M9.66665 9.50004H8.49998V8.33337C8.49998 8.05737 8.27598 7.83337 7.99998 7.83337C7.72398 7.83337 7.49998 8.05737 7.49998 8.33337V9.50004H6.33331C6.05731 9.50004 5.83331 9.72404 5.83331 10C5.83331 10.276 6.05731 10.5 6.33331 10.5H7.49998V11.6667C7.49998 11.9427 7.72398 12.1667 7.99998 12.1667C8.27598 12.1667 8.49998 11.9427 8.49998 11.6667V10.5H9.66665C9.94265 10.5 10.1666 10.276 10.1666 10C10.1666 9.72404 9.94265 9.50004 9.66665 9.50004Z'
          fill={secondaryColor}
        />
      </g>
    </svg>
  )
}

export default CalendarSvg
