import React from "react"
import customerTxnCatchAll from "../../assets/images/transactionLogos/customerTxnCatchAll.svg"

import { useSelector } from "react-redux"
import {
  APPAREL,
  DINING,
  ELECTRONICS,
  FUEL,
  GROCERY,
  RENT,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
  TRAVEL,
  UTILITY,
} from "../../utils/constants"

const TransactionLogo_v2_1 = props => {
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const urlFolder = config?.jsonConfig.haveCustomTransactionType
    ? user.account.programId
    : screen.home.transaction.transactionLogoType.toLowerCase()
  let s3_url = process.env.REACT_APP_S3_URL
  const mccCheck = code => {
    let MCC_Code = parseInt(code)
    if (FUEL.includes(MCC_Code)) {
      //TO:DO here s3_url variable contains only folder name
      return `${s3_url}/${urlFolder}/Fuel.svg`
    } else if (UTILITY.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/Utilities.svg`
    } else if (DINING.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/FoodGrocery.svg`
    } else if (GROCERY.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/FoodGrocery.svg`
    } else if (ELECTRONICS.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/Electronics.svg`
    } else if (TRAVEL.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/Transport.svg`
    } else if (RENT.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/Utilities.svg`
    } else if (APPAREL.includes(MCC_Code)) {
      return `${s3_url}/${urlFolder}/Apparel.svg`
    } else {
      return customerTxnCatchAll
    }
  }
  const image = type => {
    switch (type) {
      case "REFUND":
        return `${s3_url}/${urlFolder}/Refund.svg`
      case "REFUND_REVERSAL":
        return `${s3_url}/${urlFolder}/RefundReversal.svg`
      case "PAYMENT":
        return mccCheck(props.mcc)
      case "PURCHASE":
        return mccCheck(props.mcc)
      case "PAYMENT_REVERSAL":
        return `${s3_url}/${urlFolder}/PaymentReversal.svg`
      case "CASHBACK":
        return `${s3_url}/${urlFolder}/Cashback.svg`
      case "CASHBACK_REVERSAL":
        return `${s3_url}/${urlFolder}/CashbackReversal.svg`
      case "REPAYMENT":
        return `${s3_url}/${urlFolder}/Repayment.svg`
      case "REPAYMENT_REVERSAL":
        return `${s3_url}/${urlFolder}/RepaymentReversal.svg`
      case "FEE":
        return `${s3_url}/${urlFolder}/Fee.svg`
      case "FEE_REVERSAL":
        return `${s3_url}/${urlFolder}/FeeReversal.svg`
      case "TAX":
        return `${s3_url}/${urlFolder}/Tax.svg`
      case "TAX_REVERSAL":
        return `${s3_url}/${urlFolder}/TaxReversal.svg`
      case "INTEREST":
        return `${s3_url}/${urlFolder}/Interest-1.svg`
      case "INTEREST_REVERSAL":
        return `${s3_url}/${urlFolder}/InterestReversal-1.svg`
      case "SURCHARGE":
        return `${s3_url}/${urlFolder}/Surcharge.svg`
      case "SURCHARGE_REVERSAL":
        return `${s3_url}/${urlFolder}/SurchargeReversal.svg`
      case "EMI":
        return `${s3_url}/${urlFolder}/Emi.svg`
      case "CASH_WITHDRAWAL_REVERSAL":
        return `${s3_url}/${urlFolder}/CashWithdrawalReversal.svg`
      case "CASH_WITHDRAWAL":
        return `${s3_url}/${urlFolder}/CashWithdrawal.svg`
      case "SETTLEMENT_DEBIT_CASH":
        return `${s3_url}/${urlFolder}/SettlementDebit.svg`
      case "SETTLEMENT_CREDIT_CASH":
        return `${s3_url}/${urlFolder}/SettlementCredit.svg`
      case "ADD_FUND":
        return `${s3_url}/${urlFolder}/AddFund.svg`
      default:
        return customerTxnCatchAll
    }
  }

  return (
    <div>
      <img
        width={TRANSACTION_LOGO_WIDTH + "px"}
        height={TRANSACTION_LOGO_HEIGHT + "px"}
        src={image(props.transactionType)}
        alt='transaction logo'
      />
    </div>
  )
}

export default TransactionLogo_v2_1
