import React, { forwardRef, useRef, useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import check from "../svg/v3/check-circle.svg"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import successTick from "../../assets/images/v3/success.svg"

import { useDispatch, useSelector } from "react-redux"
import { ColorButton, WhiteButton } from "../core/buttons"
import scanCircle from "../../assets/images/v3/scanCircle.svg"
import DividerV3 from "../core/Divider/v3/DividerV3"

const numToString = {
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
}

const SuccessBottomSheet = forwardRef(
  (
    {
      isOpen,
      onClose,
      title,
      subtitle,
      showSuccessSecondaryButton,
      showSuccessPrimaryButton,
      primarySuccessButtonText,
      secondarySuccessButtonText,
      onSuccessPrimaryCtaClick,
      onSuccessSecondaryClick,
    },
    ref,
  ) => {
    const dispatch = useDispatch()
    const sheetRef = useRef()

    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)

    const theme = useSelector(state => state.theme)
    const user = useSelector(state => state.user)
    const authStore = useSelector(state => state.auth)
    const config = useSelector(state => state.config)
    const session = useSelector(state => state.session)

    return (
      <div style={{ maxWidth: "450px" }}>
        <BottomSheet open={isOpen} onDismiss={onClose}>
          <span
            className='v3-verify-otp-close-btn'
            style={{ float: "right", marginRight: "20px", marginTop: "20px" }}
            onClick={onClose}
          >
            <img src={crossIcon} />
          </span>
          <div>
            <div ref={sheetRef} className='bottom-sheet'>
              <div className='v3-success-bottomSheet-icon-container'>
                <img
                  className='v3-success-bottomSheet-icon'
                  src={successTick}
                />
              </div>

              <div className='bottom-sheet-header'>
                <div className='v3-success-bottomSheet-title'>{title}</div>

                <div className='v3-success-bottomSheet-subtitle'>
                  {subtitle}
                </div>
              </div>
              {showSuccessPrimaryButton && (
                <>
                  <DividerV3 />
                  <div className='v3-success-bottomSheet-textOver-cta'>
                    <img src={scanCircle} alt='scan circle' />
                    <div className='v3-success-bottomSheet-textOver-cta-text'>
                      Unfreeze card anytime you want!
                    </div>
                  </div>
                  <div className='v3-success-bottomSheet-cta'>
                    <div className='v3-success-bottomSheet-cta-primary'>
                      <ColorButton
                        text={primarySuccessButtonText}
                        onPress={onSuccessPrimaryCtaClick}
                      />
                    </div>
                    {showSuccessSecondaryButton && (
                      <div className='v3-success-bottomSheet-cta-secondary'>
                        <WhiteButton
                          text={secondarySuccessButtonText}
                          onPress={onSuccessSecondaryClick}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
              {/* <div className='v3-opt-input-cta'>
            <ColorButton
              isLoading={submitLoading}
              onPress={() => console.log()}
              text='Verify'
            />
          </div> */}
            </div>
          </div>
        </BottomSheet>
      </div>
    )
  },
)

export default SuccessBottomSheet
