const styles = {
  pinMainView: {
    px: "16px",
    py: "24px",
    mt: 8,
    borderRadius: 5,
  },
  pinMainView1: {
    width: "104%",
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    marginLeft:"-8px"
  },
  textInput: {
    width: "18%",
    py: 3,
    m: 2,
    backgroundColor: "#FFF",
    borderColor: "#303030",
    borderWidth: "1px",
    textAlign: "center",
    secureTextEntry: "true",
    maxLength: 1,
    keyboardType: "numeric",
  },
  mainHeading: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "26px",
    color: "#303030",
  },
  descriptionText: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#7b7b7b",
  },
  buttonSection: {
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    bottom: "24px",
  },
  pinSectionHeading: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#303030",
  },
  widgetErrorText: {
    textAlign: "center",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "16px",
    color: "#303030",
  },
  widgetHeading: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#7B7B7B",
  },
  widgetDetailText: {
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#303030",
  },
}

export default styles
