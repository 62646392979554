import React from "react"

const CaretRightSvg = ({ color = "#0E0E0E", ...props }) => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M11.3538 8.35378L6.35375 13.3538C6.3073 13.4002 6.25215 13.4371 6.19145 13.4622C6.13076 13.4874 6.0657 13.5003 6 13.5003C5.93431 13.5003 5.86925 13.4874 5.80856 13.4622C5.74786 13.4371 5.69271 13.4002 5.64625 13.3538C5.5998 13.3073 5.56295 13.2522 5.53781 13.1915C5.51267 13.1308 5.49973 13.0657 5.49973 13C5.49973 12.9343 5.51267 12.8693 5.53781 12.8086C5.56295 12.7479 5.5998 12.6927 5.64625 12.6463L10.2931 8.00003L5.64625 3.35378C5.55243 3.25996 5.49973 3.13272 5.49973 3.00003C5.49973 2.86735 5.55243 2.7401 5.64625 2.64628C5.74007 2.55246 5.86732 2.49976 6 2.49976C6.13269 2.49976 6.25993 2.55246 6.35375 2.64628L11.3538 7.64628C11.4002 7.69272 11.4371 7.74786 11.4623 7.80856C11.4874 7.86926 11.5004 7.93433 11.5004 8.00003C11.5004 8.06574 11.4874 8.13081 11.4623 8.1915C11.4371 8.2522 11.4002 8.30735 11.3538 8.35378Z'
      fill={color}
    />
  </svg>
)

export default CaretRightSvg
