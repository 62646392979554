import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import {
  addClassname,
  captureEvents,
  copyToClipboard,
  formatCardNumber,
  removeClassname,
} from "../../../utils/functions"
import { EventName, ToastType, AU_BlockCodes } from "../../../utils/enums"
import Copy from "../../svg/copy"
import EyeHide from "../../svg/eyeHide"
import EyeShow from "../../svg/eyeShow"
import cardClose from "../../../assets/images/cardClose.png"
import blueCard from "../../../assets/images/v3/horizontalCard.svg"
import vericalCard from "../../../assets/images/v3/VerticalCard.svg"
import { CardOrientation } from "../../../utils/constants"
import { customToast, toastify } from "../../../core"
import CopyCardNumber from "../../svg/v3/copyCardNumber"
import SuccessTick from "../../svg/v3/SuccessTick"
import { useNavigate } from "react-router-dom"

const CardDetails_v3 = ({
  cardDetails,
  setSucess,
  setClose,
  setIsAnimating,
  tapToViewRef,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isCvvHidden, setIsCvvHidden] = useState(true)
  const user = useSelector(state => state.user)
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const iconOverlayImageUrl = theme?.virtualCardDetailsImage
  const cardOrientation = theme?.cardOrientation
  const ShowCardCopyButton = screen.home.isShowCardCopyButton
  const backHorizontalCardRef = useRef()
  const backVerticalCardRef = useRef()
  const tapToviewTextRef = useRef(tapToViewRef.current)
  const session = useSelector(state => state.session)

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIRTUAL_CARD_DISABLE,
      )
    ) {
      navigate("/")
    }
  }, [])

  const handleCardClick = useCallback(
    element => {
      setIsAnimating(true)
      removeClassname(element, "v3-virtual-card-flip-second")
      addClassname(element, "v3-virtual-card-flip-first")
      removeClassname(tapToviewTextRef.current, "v3-virtual-card-flip-second")
      addClassname(tapToviewTextRef.current, "v3-virtual-card-flip-first")
      setTimeout(() => {
        setSucess(false)
        setClose(true)
      }, 500)
      setTimeout(() => {
        setIsAnimating(false)
      }, 1300)
    },
    [backHorizontalCardRef, backVerticalCardRef, tapToviewTextRef],
  )

  const copyCardNumber = () => {
    captureEvents({
      eventName: EventName.VIRTUAL_CARD_CTA,
      metadata: { action: "copy card number" },
    })
    copyToClipboard(cardDetails?.unmaskedCardNumber || "")

    customToast(ToastType.COPY, `Card Number Copied!`, {
      toastTextColor: "var(--tertiary-neutral-color-1)",
      toastBackgroundColor: "var(--tertiary-neutral-color-1)",
    })
  }

  const toggleCvvVisibility = () => {
    captureEvents({
      eventName: EventName.VIRTUAL_CARD_CTA,
      metadata: { action: "view/hide cvv" },
    })
    setIsCvvHidden(prevState => !prevState)
  }

  return (
    <>
      {cardOrientation === CardOrientation.VERTICAL ? (
        <div
          ref={backVerticalCardRef}
          className='v3-container v3-virtual-card-flip-second'
          style={{
            backgroundImage: `url(${iconOverlayImageUrl ? iconOverlayImageUrl : vericalCard})`,
            color: theme.v3.cssVars.leadingWhite,
          }}
        >
          <img
            src={cardClose}
            alt='Close'
            onClick={() => handleCardClick(backVerticalCardRef.current)}
            className='v3-vertical-close-icon'
          />
          <div className='v3-card-vertical-number'>
            <div className='v3-card-vertical-number-section'>
              <p className='v3-card-vertical-number-label'>
                {t("messages.cardNumber")}
              </p>
              <p className='v3-vertical-card-number'>
                {!isCvvHidden
                  ? `**** **** **** ${cardDetails.unmaskedCardNumber.slice(-4)}`
                  : formatCardNumber(cardDetails.unmaskedCardNumber)}
              </p>
            </div>
            {isCvvHidden ? (
              ShowCardCopyButton && (
                <div className='v3-copy-icon' onClick={copyCardNumber}>
                  <CopyCardNumber color={"#A6B2CD"} />
                </div>
              )
            ) : (
              <div className='v3-eye-icon-1' onClick={toggleCvvVisibility}>
                <EyeShow color={"#FFFFFF"} />
              </div>
            )}
          </div>
          <div className='v3-expcvv-container'>
            <div className='v3-cvv-expiry'>
              <p className='v3-card-exipry'>Exp Date</p>
              <p className='v3-card-expiry-number'>{cardDetails.cardExpiry}</p>
            </div>
            <div className='v3-cvv-expiry'>
              <p className='v3-cvv-label'>CVV</p>
              <div className='v3-expiry-cvv'>
                <p className='v3-vertical-cvv'>
                  {isCvvHidden ? "***" : cardDetails.cvv}
                </p>
                <div
                  className='v3-cvv-icon-container'
                  onClick={toggleCvvVisibility}
                >
                  {isCvvHidden ? <EyeShow color={"#FFFFFF"} /> : <></>}
                </div>
              </div>
            </div>
          </div>
          <p className='v3-card-name'>{user.cards[0].cardDisplayName}</p>
        </div>
      ) : (
        <div
          className='v3-horizontal-container v3-virtual-card-flip-second'
          ref={backHorizontalCardRef}
          style={{
            backgroundImage: `url(${iconOverlayImageUrl ? iconOverlayImageUrl : blueCard})`,
            color: theme.v3.cssVars.leadingWhite,
          }}
        >
          <img
            src={cardClose}
            alt='Close'
            onClick={() => handleCardClick(backHorizontalCardRef.current)}
            className='v3-close-icon'
          />
          <div className='v3-card-number'>
            <div className='v3-card-number-horizontal-label'>Card Number</div>
            <div className='v3-card-number-horizontal-number'>
              <p className='v3-horizontal-card-number'>
                {!isCvvHidden
                  ? `**** **** **** ${cardDetails.unmaskedCardNumber.slice(-4)}`
                  : formatCardNumber(cardDetails.unmaskedCardNumber)}
              </p>
              {isCvvHidden ? (
                ShowCardCopyButton && (
                  <div
                    className='v3-copy-icon-horizontal'
                    onClick={copyCardNumber}
                  >
                    <CopyCardNumber color={"#A6B2CD"} />
                  </div>
                )
              ) : (
                <div
                  className='v3-eye-icon-horizontal'
                  onClick={toggleCvvVisibility}
                >
                  <EyeShow color={"#FFFFFF"} />
                </div>
              )}
            </div>
          </div>
          <div className='v3-horizontal-bottom-card'>
            <div className='v3-cvv-expiry'>
              <p className='v3-card-exipry'>Exp Date</p>
              <p className='v3-horizontal-expiry'>{cardDetails.cardExpiry}</p>
            </div>
            <div className='v3-horizontal-cvv-expiry'>
              <p className='v3-horizontal-cvv-label'>{t("messages.cvv")}</p>
              <div className='v3-horizontal-expiry-cvv'>
                <p className='v3-horizontal-cvv'>
                  {isCvvHidden ? "***" : cardDetails.cvv}
                </p>
                <div
                  className='v3-horizontal-cvv-icon-container'
                  onClick={toggleCvvVisibility}
                >
                  {isCvvHidden ? <EyeShow color={"#FFFFFF"} /> : <></>}
                </div>
              </div>
            </div>
          </div>
          <p className='v3-horizontal-card-name'>
            {user.cards[0].cardDisplayName}
          </p>
        </div>
      )}
    </>
  )
}

export default CardDetails_v3
