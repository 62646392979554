import React from "react"
import { SwiperSlide, Swiper } from "swiper/react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Pagination } from "swiper/modules"
import { useTranslation } from "react-i18next"

const CrossSellOffer = ({ crossSellOffers }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const handleViewAllClick = path => {
    navigate(path)
  }

  return (
    crossSellOffers &&
    crossSellOffers.length > 0 && (
      <div className='v3-offers-section-2'>
        <div className='v3-offers-section-header'>
          <div className='v3-offers-section-header-title'>
            {t("offers.upgrades")}
          </div>
          <div className='v3-offers-section-header-cta'>
            {t("offers.ViewAll")}
          </div>
        </div>
        <div>
          <div className='offer-carouselProvider'>
            <Swiper
              spaceBetween={10}
              slidesPerView={1}
              centeredSlides={true}
              modules={[Pagination]}
              pagination={{ clickable: true }}
            >
              {crossSellOffers.map((benefit, index) => {
                return (
                  <SwiperSlide
                    key={index}
                    style={{ padding: "0px 10px", paddingBottom: "40px" }}
                  >
                    <img src={benefit?.illustrationUrl} width={"100%"} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
      </div>
    )
  )
}

export default CrossSellOffer
