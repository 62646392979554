import {
  SET_CAMPAIGN_BENEFITS,
  SET_CVP_BENEFITS,
  SET_MERCHANT_BENEFITS,
  SET_THIRD_PARTY_BENEFITS,
  SET_CODE_REVEALED,
} from "../ActionTypes"

const initialState = {
  cvpBenefits: [],
  campaignBenefits: [],
  merchantBenefits: [],
  isCvpBenefitsFetched: false,
  isCampaignBenefitsFetched: false,
  isMerchantBenefitsFetched: false,
  isThirdPartyBenefitsFetched: false,
}

const Benefits = (state = initialState, action) => {
  switch (action.type) {
    case SET_CVP_BENEFITS:
      return {
        ...state,
        cvpBenefits: action.payload,
        isCvpBenefitsFetched: true,
      }
    case SET_CAMPAIGN_BENEFITS:
      return {
        ...state,
        campaignBenefits: [...state.campaignBenefits, ...action.payload],
        isCampaignBenefitsFetched: true,
      }
    case SET_THIRD_PARTY_BENEFITS:
      return {
        ...state,
        campaignBenefits: [...state.campaignBenefits, ...action.payload],
        isThirdPartyBenefitsFetched: true,
      }
    case SET_MERCHANT_BENEFITS:
      return {
        ...state,
        merchantBenefits: action.payload,
        isMerchantBenefitsFetched: true,
      }
    default:
      return state
  }
}

export default Benefits
