import React from "react"
import mPinV3 from "../../../assets/images/mPinV3.svg"
import CardImg from "../../../assets/images/v3/card.png"
import mPinV3bg from "../../../assets/images/v3/mPinV3bg.svg"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

const Header = ({
  username,
  cardImage,
  centerFloatingImage,
  backgroundImage,
  type,
}) => {
  const { t } = useTranslation()
  const session = useSelector(state => state.session)
  const user = useSelector(state => state.user)
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const cardName = screen.preLoader.cardName
  return (
    <div
      style={{
        overflow: "hidden",
        height: "30vh",
        background:
          "linear-gradient(0deg, #BDD8F8 22.45%, rgba(38, 128, 234, 0) 68.26%)",
      }}
    >
      <img
        src={backgroundImage ? backgroundImage : mPinV3bg}
        className='v3-auth-header-bg'
        alt='auth-header-background'
      />
      <div className='v3_mpin_header'>
        {t("mpin.mpinHeaderWelcomeText", {
          customerName: user.customer.firstName,
        })}{" "}
        <br></br>
        <span
          style={{
            color: theme.v3.cssVars.primary.color1,
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {cardName}
        </span>
      </div>
      <div
        className='v3_mpin_card_image'
        style={{
          marginTop: "4px",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          opacity: "0.75",
        }}
      >
        <img
          src={theme?.cardDesignUrl || CardImg}
          style={{
            width: theme.cardOrientation === "VERTICAL" ? "220px" : "288px",
            marginTop: "45px",
          }}
          alt='card'
        />
      </div>
    </div>
  )
}

export default Header
