const Icon = ({ children, size, ...props }) => {
  return (
    <svg height={size} width={size} {...props}>
      <g>{children}</g>
    </svg>
  )
}

export const G = ({ ...props }) => <g {...props} />

export const Path = ({ ...props }) => <path {...props} />

export default Icon
