import React from "react"

const DebitImage = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.8334 6.91667L4.16671 18.5833'
        stroke='#E72B2B'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M15.8334 15.475V6.91667H7.27504'
        stroke='#E72B2B'
        stroke-width='1.5'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default DebitImage
