import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../../auth/ApiIds"
import CardService from "../../../../../services/CardService"
import { setCards } from "../../../../../store/actions/User"
import { handleApiAuth } from "../../../../../utils/auth"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "../../../../../utils/enums"
import { captureEvents, consoleError } from "../../../../../utils/functions"
import { ColorButton, WhiteButton } from "../../../../core/buttons"

import { Radio } from "../../../../../core/Radio"
import { toast } from "../../../../../core/Toast"
import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import { DisplayConfig } from "../../../../../utils/constants"
import { Header } from "../../../../core"
import { BlockOptions } from "../constants"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"

const defaultReasons = [
  { value: "CARDSTOLEN", text: "My Card Is Stolen" },
  { value: "CARDLOST", text: "Lost My Card" },
  { value: "DAMAGED", text: "My Card Is Damaged" },
  { value: "FRAUD", text: "Fraud" },
]

const BlockCardReason_v2 = () => {
  const [selectedValue, setSelectedValue] = useState(null)
  const [submitLoading, setSubmitLoading] = useState(false)
  const initCard = useRef(null)

  const [theme, user, session, featureFlags, config, screen] = useSelector(
    state => [
      state.theme,
      state.user,
      state.session,
      state.featureFlags,
      state.config,
      state.screen,
    ],
  )

  const reasons =
    screen?.blockReplaceCard?.blockCardOptions?.length > 4
      ? JSON.parse(screen?.blockReplaceCard?.blockCardOptions)
      : defaultReasons

  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }

  const dispatch = useDispatch()
  const windowDim = useWindowDimensions()
  const navigate = useNavigate()

  useEffect(() => {
    captureEvents({ eventName: EventName.BLOCK_CARD_REASON, metadata: {} })
  }, [])

  useEffect(() => {
    // store and check init card state as this screen changes hotlist status
    initCard.current = initCard.current || user.cards?.[0]

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      replaceOrBlock() === BlockOptions.ONLY_REPLACE ||
      replaceOrBlock() === BlockOptions.GRAY_OUT
    ) {
      toast("Access has been blocked to \nblock card page")
      navigate("/")
      return false
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
    ) {
      toast("Access has been blocked")
      navigate("/")
      return
    }

    if (
      user.account.status !== "ACTIVE" ||
      !initCard.current ||
      initCard.current.isHotlisted
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards])

  const handleSubmitClick = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.HOTLIST_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to block card",
      mpinReason: "to block card",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const updateBlockCodeAction = () => {
    let disabledFunctions = session?.disabledFunctionsByBlockCode
    if (session?.issuer === Issuers.AU_BANK) {
      let index = disabledFunctions.indexOf(AU_BlockCodes.TEMP_CARD_DISABLE)
      if (index === -1) {
        disabledFunctions.push(AU_BlockCodes.TEMP_CARD_DISABLE)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
    if (session?.issuer === Issuers.FEDERAL_BANK) {
      let index = disabledFunctions.indexOf(Federal_BlockCodes.CARD_BLOCKED)
      if (index === -1) {
        disabledFunctions.push(Federal_BlockCodes.CARD_BLOCKED)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.hotlist(apiToken, user.cards[0]?.id, {
        reason: selectedValue,
      })
      const result = response?.data
      if (result?.success) {
        const tempCard = { ...user.cards[0] }
        tempCard.isHotlisted = true

        updateBlockCodeAction()
        captureEvents({
          eventName: EventName.CARD_BLOCKED_REASON,
          metadata: { reason: selectedValue },
        })

        dispatch(setCards({ cards: [tempCard] }))
        navigate("/ManageCard/BlockCard/CardBlocked", {
          state: { from: "blockCard" },
          replace: true,
        })
      } else {
        consoleError(result?.errors)
        toast("Sorry an error occurred")
        // navigate on failure also as success callback may be called from challenge screen
        // this action can be made more specific by checking current path in url and acting accordingly
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return (
    <div
      className='blockCardReason'
      style={{
        maxWidth: DisplayConfig.MAX_WIDTH,

        backgroundColor: theme.backgroundColor,
      }}
    >
      <div style={{ width: "100%", minHeight: windowDim.height }}>
        <Header
          text='Block/Replace Card'
          onBack={async () => {
            window.history.go(-1)
          }}
        />
        <div
          style={{
            backgroundColor: theme.backgroundColor,
          }}
        >
          <div
            className='blockCardReason-content'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            {/* body content start */}
            {config?.version !== PwaVersions.V2_1 && (
              <div
                className='blockCardReason-content-header'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                Block Card
              </div>
            )}
            <div
              className='blockCardReason-content-subHeader'
              style={{
                fontFamily: theme.fontFamily,
                color: `${theme.appTextColor}90`,
              }}
            >
              {config?.version === PwaVersions.V2_1
                ? "Why do you want to block this card? Please select a reason:"
                : "Please state your reason to block the card"}
            </div>
            {reasons.map(item => (
              <div
                onClick={() => {
                  setSelectedValue(item.value)
                }}
                className={
                  selectedValue !== item.value
                    ? "blockCardReason-content-box"
                    : "blockCardReason-content-box-selected"
                }
                key={item.value}
                style={{
                  borderColor:
                    selectedValue === item.value
                      ? theme.color1
                      : theme.appTextColor,
                }}
              >
                <Radio
                  style={
                    selectedValue === item.value
                      ? { color: theme.color1 }
                      : { color: theme.appTextColor }
                  }
                  labelStyle={
                    selectedValue === item.value
                      ? { color: theme.color1 }
                      : { color: theme.appTextColor }
                  }
                  labelClassName={
                    item.value === selectedValue
                      ? "blockCardReasonRadio-selectedState-label"
                      : "blockCardReasonRadio-normalState-label"
                  }
                  className={
                    item.value === selectedValue
                      ? "blockCardReasonRadio-selectedState"
                      : "blockCardReasonRadio-normalState"
                  }
                  value={item.value}
                  isChecked={selectedValue === item.value}
                  label={item.text}
                ></Radio>
              </div>
            ))}
          </div>

          {/*    CTA Section */}
          <div
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            className='blockCardReason-ctaSection'
          >
            <ColorButton
              text='Block Card'
              isDisable={!selectedValue}
              onPress={handleSubmitClick}
              isLoading={submitLoading}
            />
            {!(
              config?.version == PwaVersions.V2 ||
              config?.version == PwaVersions.V2_1
            ) ? (
              <WhiteButton
                text='Cancel'
                onPress={() => navigate("/ManageCard")}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlockCardReason_v2
