import React from "react"
import RewardsCashbackZeroState from "../../../assets/images/v3/Rewards&CashbackZeroState.svg"

const RewardsZeroStateTransaction = ({ isRewards }) => {
  return (
    <div className='v3-Rewards-zero-state-transaction'>
      <img
        src={RewardsCashbackZeroState}
        alt='empty state rewards & cahsback'
        className='v3-Rewards-zero-state-transaction-image'
      />
      <div className='v3-Rewards-zero-state-transaction-text'>
        <div>
          {isRewards ? "No reward point history" : "No cashback history"}
        </div>
        <div>
          {isRewards
            ? "Reward point transactions will show here as soon as you earn your first reward point"
            : "Cashback transactions will show here as soon as you earn your first cahback"}
        </div>
      </div>
    </div>
  )
}

export default RewardsZeroStateTransaction
