import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import CurrencyFormat from "react-currency-format"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Radio } from "../../../core/Radio"
import AccountService from "../../../services/AccountService"
import { setPgAmount, setPgConfig } from "../../../store/actions/PaymentGateway"
import { EventName } from "../../../utils/enums"
import { captureEvents, formatAmount } from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const PaymentGateway = () => {
  const windowDim = useWindowDimensions()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isOtherAmountSelected, setIsOtherAmountSelected] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [inputValue, setInputValue] = useState()
  const [payableAmount, setPayableAmount] = useState()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const [isLastStatementSelected, setIsLastStatementSelected] = useState(true)
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }
  const location = useLocation()

  useEffect(() => {
    captureEvents({
      eventName: EventName.PAY_BILL_AMOUNT_SELECTION_PAGE,
      metadata: {},
    })
  }, [])
  useEffect(() => {
    const lastStatementFromDate = user?.summary?.lastStatement?.fromDate
    const selectedStatementFromDate = location?.state?.statement?.fromDate
    if (!selectedStatementFromDate) return

    if (lastStatementFromDate != selectedStatementFromDate) {
      setIsLastStatementSelected(false)
      setSelectedValue("OTHERAMOUNT")
      setIsOtherAmountSelected(true)
    }
  }, [])

  const totalAmountDue = user?.summary?.lastStatement?.totalAmountDue || 0
  const minimumAmountDue = user?.summary?.lastStatement?.minimumAmountDue || 0
  const dueDate =
    user?.summary?.lastStatement?.dueDate || "2023-06-15T23:59:59+05:30"

  const amountDue = [
    {
      value: "TOTALAMOUNT",
      toShow: isLastStatementSelected,
      label: (
        <div className='label'>
          <div className='labelText'>{t("PaymentGateway.totalAmountDue")}</div>
          <div className='labelAmount'>{formatAmount(totalAmountDue)}</div>
        </div>
      ),
    },
    {
      value: "MINAMOUNT",
      toShow: isLastStatementSelected,
      label: (
        <div className='label'>
          <div className='labelText'>
            {t("PaymentGateway.minimumAmountDue")}
          </div>
          <div className='labelAmount'>{formatAmount(minimumAmountDue)}</div>
        </div>
      ),
    },
    {
      value: "OTHERAMOUNT",
      toShow: true,
      label: (
        <div className='label'>
          <div className='labelText'>{t("PaymentGateway.otherAmount")}</div>
        </div>
      ),
    },
  ]

  const handleProceed = async () => {
    let amountPayable
    if (selectedValue === "TOTALAMOUNT") amountPayable = totalAmountDue
    else if (selectedValue === "MINAMOUNT") amountPayable = minimumAmountDue
    else amountPayable = inputValue

    if (!amountPayable || amountPayable <= 0) {
      toast(
        t("PaymentGateway.toastAmtNotPayable", { currency: "₹", amt: "0.00" }),
      )
      return false
    }
    dispatch(
      setPgAmount({
        amountPayable: amountPayable,
      }),
    )

    try {
      const response = await AccountService.getPGConfig()
      let redirectUrl
      if (response.status == 200) {
        redirectUrl = response?.data?.data[0]?.flows[1]?.metadata?.redirectUrl
        dispatch(
          setPgConfig({
            pgconfig: response?.data?.data,
          }),
        )
        let modeSelected
        if (selectedValue === "TOTALAMOUNT") modeSelected = "TAD"
        else if (selectedValue === "MINAMOUNT") modeSelected = "MAD"
        else modeSelected = "Custom"
        captureEvents({
          eventName: EventName.PROCEED_TO_PAY,
          metadata: { amount: modeSelected },
        })
      }

      if (amountPayable > 100000) {
        window.open(redirectUrl)
      } else {
        navigate("/paymentGateway/options")
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text={t("PaymentGateway.billPayment")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='mainContainer' style={{ color: theme.appTextColor }}>
        <div
          className='widget'
          style={{ backgroundColor: theme.widgetBackgroundColor }}
        >
          <div className='headingContainer'>
            <div className='headingText'>
              {t("PaymentGateway.payableAmount")}
            </div>
            <div className='headingBox'>
              {t("PaymentGateway.dueOn")}{" "}
              {moment(dueDate).format("DD MMM YYYY")}{" "}
            </div>
          </div>
          <div className='subHeading'>{t("PaymentGateway.confirmAmount")}</div>
          <div className='RadioContainer'>
            {amountDue.map(item => (
              <>
                {item?.toShow && (
                  <div
                    onClick={() => {
                      setSelectedValue(item.value)
                      setIsOtherAmountSelected(item.value === "OTHERAMOUNT")
                    }}
                    className='radioButton'
                    key={item.value}
                    style={{
                      border: "none",
                    }}
                  >
                    <Radio
                      style={
                        selectedValue === item.value
                          ? { color: theme.color1 }
                          : { color: theme.appTextColor }
                      }
                      labelStyle={
                        selectedValue === item.value
                          ? { color: theme.color1 }
                          : { color: theme.appTextColor }
                      }
                      value={item.value}
                      isChecked={selectedValue === item.value}
                      label={item.label}
                    ></Radio>
                  </div>
                )}
              </>
            ))}
            {isOtherAmountSelected && (
              <div className='inputContainer'>
                <span className='currencyInput'>₹</span>

                <CurrencyFormat
                  onKeyDown={evt =>
                    ["e", "E", "+", "-"].includes(evt.key) &&
                    evt.preventDefault()
                  }
                  placeholder='0.00'
                  thousandSpacing='2s'
                  thousandSeparator={true}
                  decimalScale={2}
                  value={inputValue}
                  onValueChange={values => {
                    let amount = values?.value
                    console.log(values)
                    //Restricting entering negative amount
                    if ((amount + "").charAt(0) === "-") {
                      amount = amount * -1
                    }
                    setInputValue(amount)
                  }}
                  className='otherAmount'
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='paymentGatewayBtnContainer'>
        <ColorButton
          text={t("PaymentGateway.proceedToPay")}
          onPress={() => handleProceed()}
        />
      </div>
    </div>
  )
}

export default PaymentGateway
