import styled from "styled-components"
import View from "./View"
import Spinner from "./Spinner"

const Button = ({
  onPress,
  isDisabled,
  children,
  isLoading,
  spinnerColor = "#FFFFFF",
  ...restProps
}) => {
  return (
    <ButtonContainer
      nbr={"Button"}
      {...restProps}
      onClick={!isDisabled ? onPress : () => {}}
      style={{
        cursor: "pointer",
        opacity: isDisabled || isLoading ? 0.5 : 1,
        ...restProps.style,
      }}
    >
      {isLoading && <Spinner color={spinnerColor} />}
      {children}
    </ButtonContainer>
  )
}

export default Button

const ButtonContainer = styled(View)`
  gap: 0.375rem;
  flex-direction: row !important;
`
