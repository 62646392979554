const ArrowBackBig = ({ color = "#A8A8A8" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
    >
      <path
        d='M22.1584 14.0278C22.1584 16.9768 19.7595 19.3757 16.8105 19.3757H6.11469C5.69297 19.3757 5.3507 19.0334 5.3507 18.6117C5.3507 18.19 5.69297 17.8477 6.11469 17.8477H16.8105C18.9161 17.8477 20.6305 16.1344 20.6305 14.0278C20.6305 11.9212 18.9161 10.2078 16.8105 10.2078H4.90355L6.6556 11.9599C6.95407 12.2584 6.95407 12.7423 6.6556 13.0407C6.50688 13.1894 6.31127 13.2648 6.11569 13.2648C5.92011 13.2648 5.7245 13.1905 5.57577 13.0407L2.51982 9.98477C2.44954 9.91448 2.39365 9.83004 2.35494 9.73632C2.27752 9.54991 2.27752 9.33905 2.35494 9.15264C2.39365 9.05892 2.44954 8.97423 2.51982 8.90394L5.57577 5.84799C5.87424 5.54953 6.35813 5.54953 6.6566 5.84799C6.95506 6.14646 6.95506 6.63035 6.6566 6.92882L4.90455 8.68087H16.8115C19.7595 8.67985 22.1584 11.0788 22.1584 14.0278Z'
        fill={color}
      />
    </svg>
  )
}

export default ArrowBackBig
