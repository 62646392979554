import React, { useEffect, useState /* , useRef */ } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import PublicService from "../../../services/PublicService"
import { consoleError } from "../../../utils/functions"
import Loader from "../../core/loader"
import styles from "./styles"
import View from "../../../nativeBaseReplacements/View"
import Text from "../../../nativeBaseReplacements/Text"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const CardActive = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const theme = useSelector(state => state.theme)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    ;(async () => {
      const cardId = localStorage.getItem("cardId")
      localStorage.removeItem("cardId")

      if (!cardId) {
        setError(true)
        consoleError(t("activation.cardIdNotFoundConsoleError"))
      } else {
        try {
          const response = await PublicService.activateCard({ cardId })
          const result = response.data

          if (!result?.success) {
            setError(true)
            consoleError(result?.errors)
          }
        } catch (error) {
          setError(true)
          consoleError(error)
        }
      }
      setLoading(false)
    })()
  }, [])

  return loading ? (
    <View
      height={windowDimensions.height}
      alignItems='center'
      justifyContent='center'
    >
      <Loader color={theme.color1} width={120} height={120} />
    </View>
  ) : error ? (
    <View {...styles.mainView}>
      <Text {...styles.clientText} fontFamily={theme.fontFamily}>
        {t("activation.cardActivationError")}
      </Text>
    </View>
  ) : (
    <View {...styles.mainView}>
      <View mb='5'>
        <Text {...styles.clientText} fontFamily={theme.fontFamily}>
          {t("activation.physicalCardActivatedText")}
        </Text>
      </View>
      {/* <View alignItems="center" justifyContent="center">
          <View my="12px">
            {orientation === "Vertical" ? (
              <Image
                source={{ uri: VerticalCard }}
                height={"343px"}
                width={"216px"}
              />
            ) : (
              <Image
                source={{ uri: HorizontalCard }}
                height={"216px"}
                width={"343px"}
              />
            )}
          </View>
        </View> */}
      <View>
        <Text {...styles.physicalpayLatertext} fontFamily={theme.fontFamily}>
          {t("activation.logInToAccessCardText")}
        </Text>
      </View>
      {/* <View mt="112px" alignItems="center" justifyConten="center">
          <Text {...styles.physicalpayLatertext}>
            This page will close in {count} seconds...
          </Text>
        </View> */}
    </View>
  )
}

export default CardActive
