import React, { forwardRef, useEffect, useRef, useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import { useTranslation } from "react-i18next"
import "react-spring-bottom-sheet/dist/style.css"
import { ColorButton } from "../../core/buttons"
import CommonService from "../../../services/CommonService"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import crossIcon from "../../../assets/images/v3/closeBtn.svg"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  MpinErrors,
} from "../../../utils/constants"
import * as analytics from "../../../utils/analytics"
import {
  captureEvents,
  getAnalyticsProgramType,
  isWebKitBrowser,
  handleWebKitTextInputFocus,
} from "../../../utils/functions"
import {
  AuthenticationType,
  EventName,
  PwaVersions,
} from "../../../utils/enums"
import {
  restoreStateBeforeSetMpin,
  saveStateBeforeSetMpin,
  setDeviceId,
  setIsMpinSet,
} from "../../../store/actions/Auth"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import OtpInput from "../otp/OtpInput_v3"
import { toast } from "react-toastify"
import { useBottomSheet } from "../BottomSheetContext"
import Error_v3 from "../../core/Error_v3"
import Divider from "../../core/Divider/v3/Divider"
import DividerV3 from "../../core/Divider/v3/DividerV3"

const Mpin_v3 = forwardRef(({ isOpen, onClose }, ref) => {
  const { t } = useTranslation()
  const sheetRef = useRef()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [pin, setPin] = useState()
  const [error, setError] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [showMpinBlocked, setShowMpinBlocked] = useState(false)
  const bottomSheetContext = useBottomSheet()

  const [theme, authStore, user, config, screen, session] = useSelector(
    state => [
      state.theme,
      state.auth,
      state.user,
      state.config,
      state.screen,
      state.session,
    ],
  )

  const mpinLength =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE
  useEffect(() => {
    captureEvents({
      eventName: EventName.MPIN,
      metadata: {},
    })
  }, [])
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  const handleMPINVerify = async () => {
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - Click on Verify ${mpinWord}`,
    )

    if (pin?.length < mpinLength) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      // resetStateOnWrongMPIN()

      return
    }

    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeMpin({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        customerId: user.customer.id,
        programId: user.account.programId,
        mpin: pin,
      })
      const result = response.data
      if (result?.success) {
        setError(false)
        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          mpinRefId: result.data.mpinRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        await onFactorCompletion(
          navigate,
          config?.version,
          null,
          bottomSheetContext,
        )
      } else {
        if (result?.errors?.status === MpinErrors.INCORRECT_MPIN) {
          const attempts =
            result?.errors?.attemptsLeft === 2
              ? `.\n${t("mpin.attemptsRemainingText", { AttemptNo: 2 })}`
              : result?.errors?.attemptsLeft === 1
                ? `. \n${t("mpin.lastAttemptRemainingText", { AttemptNo: 1 })}`
                : ""
          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: {},
          })
          setError(
            `${t("mpin.incorrectMpinErrorText", { attempts: attempts })}`,
          )
          setPin()
          // resetStateOnWrongMPIN()
        } else if (result?.errors?.status === MpinErrors.BLOCKED) {
          setError(result?.errors?.message)
          setPin()
          // update isMpinSet flag
          dispatch(setIsMpinSet({ isMpinSet: { result: false } }))
          // show mpin blocked screen
          setShowMpinBlocked(true)
          bottomSheetContext.closeBottomSheet()
          bottomSheetContext.closeBottomSheet2()
          navigate("/AttemptExhausted", {
            state: {
              type: AuthenticationType.MPIN,
            },
          })
        } else {
          bottomSheetContext.closeBottomSheet()
          await authStore.onAuthFailure(
            result?.errors?.reason,
            `${t("mpin.verifyMpinErrorText")}`,
          )
          setError(result?.errors?.message)
          setPin()
        }
      }
    } catch (error) {
      bottomSheetContext.closeBottomSheet()
      await authStore.onAuthFailure(error, t("mpin.genericErrorText"))
    }
    setSubmitLoading(false)
  }
  const handleOTPChange = otp => {
    setError()
    setPin(otp)
  }

  const resetStateOnWrongMPIN = () => {
    setPin()
  }

  useEffect(() => {
    document.addEventListener("focusin", handleWebKitTextInputFocus)
    return () => {
      document.removeEventListener("focusin", handleWebKitTextInputFocus)
    }
  }, [])

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div ref={sheetRef} className='bottom-sheet-mpin'>
        <div className='v3-verify-mpin-bottomsheet-header'>
          <div className='v3-verify-mpin-title-BottomSheet'>
            {t("mpin.verifyMpinText")}
          </div>
          <div className='v3-verify-mpin-cross-btn' onClick={onClose}>
            <img src={crossIcon} />
          </div>
        </div>
        <Divider />
        <div className='v3-otp-input-bottom-sheet-content-mpin'>
          <OtpInput
            isValid={!error}
            length={mpinLength}
            onChange={e => handleOTPChange(e)}
            isHashed
          />
          {error && (
            <Error_v3 errorMessage={error} customClassName={"customerAuth"} />
          )}
        </div>
        <div className='v3-opt-input-cta'>
          <DividerV3 />
          <ColorButton
            isLoading={submitLoading}
            onPress={() => handleMPINVerify()}
            text='Verify'
            isDisable={mpinLength !== pin?.length}
          />
        </div>
      </div>
    </BottomSheet>
  )
})

export default Mpin_v3
