import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function ListWrapper() {
  return (
    <CardSkeleton
      margin='12px auto auto auto'
      br='0px 0px 0px 0px'
      width='100%'
      height='78px'
      padding='16px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <CardSkeleton display='flex' alignItems='center' width='50%'>
        <TextWrapperSkeleton width='55px' height='55px' br='60%' />
        <div
          style={{
            margin: "auto auto auto 8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <TextWrapperSkeleton width='50px' />
          <TextWrapperSkeleton width='80px' />
        </div>
      </CardSkeleton>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "40%",
          alignItems: "flex-end",
          height: "100%",
        }}
      >
        <TextWrapperSkeleton width='50px' />
        <TextWrapperSkeleton width='80px' />
      </div>
    </CardSkeleton>
  )
}

function HeaderWrapper() {
  return (
    <CardSkeleton
      br='0px 0px 0px 0px'
      width='100%'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      height='48px'
    >
      <TextWrapperSkeleton
        height='10px'
        width='130px'
        margin='auto auto auto 16px'
      />
    </CardSkeleton>
  )
}

function SubHeaderWrapper() {
  return (
    <CardSkeleton
      br='0px 0px 0px 0px'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      height='48px'
      margin='auto auto 15px auto'
    >
      <TextWrapperSkeleton
        height='10px'
        width='140px'
        margin='auto auto auto 16px'
      />
    </CardSkeleton>
  )
}

function ButtonWrapper() {
  return (
    <CardSkeleton
      br='0px 0px 0px 0px'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='flex-start'
      height='84px'
    >
      <TextWrapperSkeleton
        height='42px'
        width='90%'
        margin='auto'
        br='12px 12px 12px 12px'
      />
    </CardSkeleton>
  )
}

function EmiListLoaderV3() {
  return (
    <div className='v3-emiList-skeleton-loader-wrapper'>
      <div>
        <HeaderWrapper />
        <SubHeaderWrapper />
        <ListWrapper />
        <ListWrapper />
        <ListWrapper />
        <ListWrapper />
      </div>
      <ButtonWrapper />
    </div>
  )
}

export default EmiListLoaderV3
