import { getNativeBaseSpaceToPx } from "../utils/functions"

const ChevronLeftIcon = ({ color, size }) => {
  return (
    <svg
      color={color}
      viewBox='0 0 24 24'
      stroke=''
      role='img'
      style={{
        marginBottom: "2px",
        height: getNativeBaseSpaceToPx(size, 4),
        width: getNativeBaseSpaceToPx(size, 4),
      }}
    >
      <g>
        <path
          fill='currentColor'
          d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'
          stroke=''
        ></path>
      </g>
    </svg>
  )
}

export default ChevronLeftIcon
