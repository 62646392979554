const styles = {
  otpVerificationText: {
    fontFamily: "Inter",
    fontSize: 20,
    fontWeight: 600,
  },
  saveSettingText: {
    mt: 2,
    fontSize: 14,
    fontFamily: "Inter",
    fontWeight: 400,
    color: "#808080",
  },
  pinMainView: {
    px: 10,
    mt: 3,
    borderRadius: 5,
  },
  pinMainView1: {
    width: "104%",
    display: "flex",
    flexDirection: "row",
    justifyContent:"space-between",
    marginLeft:"-8px"
  },
  textInput: {
    width: "18%",
    py: 3,
    m: 2,
    backgroundColor: "#FFF",
    borderColor: "#303030",
    borderWidth: "1px",
    textAlign: "center",
    secureTextEntry: "true",
    maxLength: 1,
    keyboardType: "numeric",
  },
  resendOtp: {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 12,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  cancelButton: {
    backgroundColor: "#FFF",
    borderColor: "#6180FF",
    borderWidth: 1,
  },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8F8F8",
    padding: 3,
    w: "90%",
    mb: "20px",
  },
  messageText: {
    fontSize: 12,
    color: "#7B7B7B",
  },
  secureMessageText: {
    fontWeight: 500,
    fontSize: 12,
    color: "#7B7B7B",
    mt: "5px",
  },
  infoSvgContainer: {
    width: "20px",
    mt: "3px",
  },
  lockSvgContainer: {
    width: "18px",
    mt: "10px",
  },
  secureMessageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  expand: {
    display: "flex",
    flex: "1",
    flexDirection: "column",
  },
}

export default styles
