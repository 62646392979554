import React, { forwardRef } from "react"
import {
  getNativeBaseBoxShadow,
  nativeBaseColor2rgba,
  getNativeBaseSpaceToPx as spaceToPx,
} from "../utils/functions"

const Text = forwardRef((props, ref) => {
  const {
    hasTextAncestor,
    _web,
    padding,
    p,
    paddingBottom,
    pb,
    py,
    paddingTop,
    pt,
    paddingLeft,
    pl,
    px,
    paddingRight,
    pr,
    margin,
    m,
    marginBottom,
    mb,
    my,
    marginTop,
    mt,
    marginLeft,
    ml,
    mx,
    marginRight,
    mr,
    maxWidth,
    maxW,
    minWidth,
    minW,
    width,
    w,
    minHeight,
    minH,
    maxHeight,
    maxH,
    height,
    h,
    backgroundColor,
    bgColor,
    background,
    bg,
    className,
    color,
    fontSize,
    shadow,
    ...restProps
  } = { ...props, ...props.style }

  const resolvedPadding = spaceToPx(padding, 1) ?? spaceToPx(p)
  const resolvedPaddingBottom =
    spaceToPx(paddingBottom, 1) ?? spaceToPx(pb ?? py)
  const resolvedPaddingTop = spaceToPx(paddingTop, 1) ?? spaceToPx(pt ?? py)
  const resolvedPaddingLeft = spaceToPx(paddingLeft, 1) ?? spaceToPx(pl ?? px)
  const resolvedPaddingRight = spaceToPx(paddingRight, 1) ?? spaceToPx(pr ?? px)

  const resolvedMargin = spaceToPx(margin, 1) ?? spaceToPx(m)
  const resolvedMarginBottom = spaceToPx(marginBottom, 1) ?? spaceToPx(mb ?? my)
  const resolvedMarginTop = spaceToPx(marginTop, 1) ?? spaceToPx(mt ?? my)
  const resolvedMarginLeft = spaceToPx(marginLeft, 1) ?? spaceToPx(ml ?? mx)
  const resolvedMarginRight = spaceToPx(marginRight, 1) ?? spaceToPx(mr ?? mx)

  const resolvedMaxWidth = spaceToPx(maxWidth, 1) ?? spaceToPx(maxW)
  const resolvedMinWidth = spaceToPx(minWidth, 1) ?? spaceToPx(minW)
  const resolvedWidth = spaceToPx(width, 1) ?? spaceToPx(w)

  const resolvedMinHeight = spaceToPx(minHeight, 1) ?? spaceToPx(minH)
  const resolvedMaxHeight = spaceToPx(maxHeight, 1) ?? spaceToPx(maxH)
  const resolvedHeight = spaceToPx(height, 1) ?? spaceToPx(h)

  const resolvedBackgroundColor = nativeBaseColor2rgba(
    backgroundColor ?? bgColor,
  )
  const resolvedBackground = nativeBaseColor2rgba(background ?? bg)
  const resolvedColor = nativeBaseColor2rgba(color)

  const resolvedShadow = getNativeBaseBoxShadow(shadow)

  const resolvedFontSize = !isNaN(fontSize) ? `${fontSize}px` : fontSize

  const defaultTextStyle = {
    backgroundColor: "transparent",
    border: "0 solid black",
    boxSizing: "border-box",
    color: "black",
    display: "inline",
    listStyle: "none",
    margin: 0,
    padding: 0,
    position: "relative",
    textAlign: "start",
    textDecoration: "none",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  }

  const defaultTextWithAncestorStyle = {
    ...defaultTextStyle,
    color: "inherit",
    font: "inherit",
    textAlign: "inherit",
    whiteSpace: "inherit",
  }

  const appliedStyles = {
    padding: resolvedPadding,
    paddingBottom: resolvedPaddingBottom,
    paddingTop: resolvedPaddingTop,
    paddingLeft: resolvedPaddingLeft,
    paddingRight: resolvedPaddingRight,
    margin: resolvedMargin,
    marginBottom: resolvedMarginBottom,
    marginTop: resolvedMarginTop,
    marginLeft: resolvedMarginLeft,
    marginRight: resolvedMarginRight,
    maxWidth: resolvedMaxWidth,
    minWidth: resolvedMinWidth,
    width: resolvedWidth,
    minHeight: resolvedMinHeight,
    maxHeight: resolvedMaxHeight,
    height: resolvedHeight,
    background: resolvedBackground,
    backgroundColor: resolvedBackgroundColor,
    fontSize: resolvedFontSize,
    color: resolvedColor,
    boxShadow: resolvedShadow,
  }

  const textStyles = {
    ...defaultTextStyle,
    ...restProps,
    ..._web,
  }

  const textWithAncestorStyles = {
    ...defaultTextWithAncestorStyle,
    ...restProps,
    ..._web,
  }

  Object.entries(appliedStyles).forEach(([key, value]) => {
    if (typeof value === "undefined" || value === null) {
      return
    }
    textStyles[key] = value
    textWithAncestorStyles[key] = value
  })

  if (hasTextAncestor) {
    return (
      <span
        ref={ref}
        className={className}
        {...restProps}
        style={textWithAncestorStyles}
      />
    )
  }
  return (
    <div
      nbr='Text'
      ref={ref}
      className={className}
      {...restProps}
      style={textStyles}
    />
  )
})

export default Text
