import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import cardImgHorizontal from "../../../../../assets/images/Card_Illustration_horizontal.png"
import cardImgVertical from "../../../../../assets/images/Card_Illustration_vertical.png"

import moment from "moment"
import { Header } from "../../../../core"
import { ColorButton, WhiteButton } from "../../../../core/buttons"

import { CardOrientation, DisplayConfig } from "../../../../../utils/constants"
import { EventName, PwaVersions } from "../../../../../utils/enums"
import { captureEvents } from "../../../../../utils/functions"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"

const CardBlocked_v2 = () => {
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const location = useLocation()

  const date = moment().format("DD-MM-YYYY")

  useEffect(() => {
    captureEvents({ eventName: EventName.CARD_BLOCKED, metadata: {} })
  }, [])

  useEffect(() => {
    // this screen comes after block card reason screen

    // if account is inactive or card was not issued, user wouldn't have reached the block card reason screen
    // if card is not hotlisted then user didn't successfully submit the block card reason
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      !user.cards[0].isHotlisted
    ) {
      navigate("/")
    }

    // reaching this point means card hotlisted, but this screen should only come after the block card reason screen.
    if (location.state?.from !== "blockCard") {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards, location.state?.from])

  const requestNewCard = () => {
    captureEvents({
      eventName: EventName.BLOCK_CARD_SUCCESS_CTA,
      metadata: { cta: "request new card" },
    })
    navigate("/ManageCard/NewCardRequest")
  }
  const returnHome = () => {
    captureEvents({
      eventName: EventName.BLOCK_CARD_SUCCESS_CTA,
      metadata: { cta: "return home" },
    })
    navigate("/")
  }

  return (
    <div
      style={{
        minHeight: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <div>
        <div
          style={{
            minHeight: windowDim.height,
            backgroundColor: theme.backgroundColor,
          }}
        >
          {/* body content start */}
          <Header
            text='Block/Replace Card'
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          {/* body title end */}

          {/* content box start */}
          <div
            className='cardBlocked-content'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            {theme.cardOrientation === CardOrientation.HORIZONTAL ? (
              <img
                className='blockCard-content-img-horizontal'
                src={
                  config?.version === PwaVersions.V2_1
                    ? theme.cardDesignUrl
                    : cardImgHorizontal
                }
                alt='Alternate Text '
              />
            ) : (
              <img
                className='blockCard-content-img-vertical'
                src={
                  config?.version === PwaVersions.V2_1
                    ? theme.cardDesignUrl
                    : cardImgVertical
                }
                alt='Alternate Text '
              />
            )}
            {/* content box Image End */}
            <div
              className='cardBlocked-content-text'
              style={{
                color: `${theme.appTextColor}90`,
                fontFamily: theme.fontFamily,
              }}
            >
              Your Card is blocked effective {date}
            </div>
            {/* content box Text end */}
          </div>
          {/* content box start */}

          {/* body content end */}
          {/*    CTA Section */}
          <div
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            className='cardBlocked-ctaSection'
          >
            <ColorButton
              text='Request a New Card'
              isDisable={false}
              onPress={requestNewCard}
            />
            <WhiteButton text='Return to Home Screen' onPress={returnHome} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardBlocked_v2
