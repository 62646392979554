import React from "react"
import { EmiStatus } from "../../../utils/constants"
import Transaction from "../../core/Transaction"

export const getEmiLabel = emiStatus => {
  if (emiStatus === EmiStatus.NOT_CREATED) {
    return null
  } else if (emiStatus === EmiStatus.PENDING) {
    return "EMI requested"
  } else if (emiStatus === EmiStatus.ACTIVE) {
    return "EMI converted"
  } else if (emiStatus === EmiStatus.CANCELLED) {
    return "EMI cancelled"
  } else if (emiStatus === EmiStatus.FORECLOSED) {
    return "EMI foreclosed"
  } else if (emiStatus === EmiStatus.CLOSED) {
    return "EMI closed"
  } else {
    return null
  }
}

export const getEmiLabelColor = emiStatus => {
  if (emiStatus === EmiStatus.NOT_CREATED) {
    return null
  } else if (emiStatus === EmiStatus.PENDING) {
    return "#fcf55f"
  } else if (emiStatus === EmiStatus.ACTIVE) {
    return "#d5f0aa"
  } else if (emiStatus === EmiStatus.CANCELLED) {
    return "#ff7575"
  } else if (emiStatus === EmiStatus.FORECLOSED) {
    return "#ff7575"
  } else if (emiStatus === EmiStatus.CLOSED) {
    return "#ff7575"
  } else {
    return null
  }
}

const Transactions = props => {
  return (
    <>
      {/* Transactions Section */}

      <div
        className='transaction'
        style={{ borderBottomWidth: props.isLast ? null : "1px" }}
      >
        <Transaction item={props.transaction} />
      </div>
    </>
  )
}

export default Transactions
