const styles = {
  periodText: {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    width: "65%",
    borderWidth: 0,
    mb:"10px"
  },
  dateText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
  },
  spendContainer: {
    flexDirection: "row",
    mb: "12px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: "0.5px",
    borderBottomColor: "#F0F0F0",
  },
  spendCategory: {
    color: "#303030",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  },
  spendAmountContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    alignSelf: "flex-end",
    mb: "16px",
  },
  spendAmount: {
    color: "#303030",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
  },
  spendPercentage: {
    mt: "-2px",
    color: "#7B7B7B",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
  },
  transactionLabel:{
    backgroundColor: "#F0EFF5",
    borderRadius: "3px",
    width:"100px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  transactionContent:{
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: " 12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",
    color: "#7B7B7B"
  },
  categoryText:{
    marginTop:"5px",
    fontFamily: 'Inter',
fontStyle: 'normal',
fontWeight: "600",
fontSize: "20px",
lineHeight: "24px",
letterSpacing: "0.02em",
color: "#303030",

  }
}

export default styles
