import React from "react"

const Hourglass_2 = ({ color = "#E72B2B", background = "#FCE1D8" }) => {
  return (
    <svg
      width='55'
      height='56'
      viewBox='0 0 55 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.907227' width='55' height='55' rx='27.5' fill={background} />
      <path
        d='M37.2476 20.9498V18.6598C37.2476 17.2263 36.0818 16.0605 34.6483 16.0605H20.3522C18.9187 16.0605 17.7529 17.2263 17.7529 18.6598V20.9421C17.7529 23.4582 18.6264 24.0834 20.0508 24.8956L26.2162 28.4071L20.0508 31.9187C18.6264 32.7309 17.7529 33.3547 17.7529 35.8722V38.1545C17.7529 39.588 18.9187 40.7537 20.3522 40.7537H34.6483C36.0818 40.7537 37.2476 39.588 37.2476 38.1545V35.8645C37.2476 33.3562 36.3794 32.7309 34.9614 31.9187L28.8351 28.4071L34.9614 24.8956C36.3794 24.0834 37.2476 23.4568 37.2476 20.9498ZM34.3154 33.047C35.4695 33.7085 35.9479 33.9827 35.9479 35.8645V38.1545C35.9479 38.8706 35.3657 39.4541 34.6483 39.4541H20.3522C19.6348 39.4541 19.0526 38.8706 19.0526 38.1545V35.8722C19.0526 33.9838 19.5321 33.7097 20.6927 33.0495L27.5288 29.1556L34.3154 33.047ZM27.5275 27.6586L20.6927 23.7648C19.5308 23.1033 19.0513 22.8305 19.0513 20.9421V18.6598C19.0513 17.9437 19.6335 17.3602 20.3509 17.3602H34.647C35.3644 17.3602 35.9466 17.9437 35.9466 18.6598V20.9498C35.9466 22.8316 35.4683 23.1058 34.3129 23.7686L27.5275 27.6586Z'
        fill={color}
      />
      <rect
        x='18.8828'
        y='33.7412'
        width='17.2147'
        height='6.47759'
        fill={color}
      />
      <path
        d='M23.5273 25.439H32.0069L29.4076 28.0649H26.1266L23.5273 25.439Z'
        fill={color}
      />
    </svg>
  )
}

export default Hourglass_2
