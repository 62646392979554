import styled from "styled-components"

const Divider = styled.div`
  height: 1px;
  background-color: rgba(212, 212, 212, 1);
  width: 100%;
  align-items: stretch;
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0;
  min-height: 0;
  min-width: 0;
  padding: 0;
  z-index: 0;
  position: relative;
`

export default Divider
