import React, { useState } from "react"
import { useSelector } from "react-redux"
import { formatAmount, formatToWholeCurrency } from "../../../utils/functions"

const AllCategoriesVisualization = ({
  spendGroups,
  configuredGroups,
  activeCategoryIndex,
  totalCategoryAmount,
}) => {
  const theme = useSelector(state => state.theme)

  return (
    <div className='v3-spend-analytics-category-split-chart-2-widget'>
      {spendGroups?.data?.spendGroups?.map((item, index) => {
        const spendGroupImage = configuredGroups?.find(
          group => group.groupingField === item?.groupId,
        )?.iconUrl
        return (
          <div className='v3-spend-analytics-category-split-chart-2-category-container'>
            <div className='v3-spend-analytics-category-split-chart-2-category-label-bar'>
              <div
                className='v3-spend-analytics-category-split-chart-2-category-label'
                style={{
                  backgroundColor:
                    index === activeCategoryIndex &&
                    theme.v3.rawColors.primary.color5,
                }}
              >
                <div className='v3-spend-analytics-category-split-chart-2-category-label-text'>
                  {item?.groupName}
                </div>
                <div className='v3-spend-analytics-category-split-chart-2-category-label-icon'>
                  <img src={spendGroupImage} width={11} height={11} />
                </div>
              </div>
              <div
                className='v3-spend-analytics-category-split-chart-2-category-bar'
                style={{
                  width: `${(item?.totalSpend / totalCategoryAmount) * 100}%`,
                  backgroundColor:
                    index === activeCategoryIndex &&
                    theme.v3.rawColors.primary.color1,
                }}
              ></div>
            </div>
            <div className='v3-spend-analytics-category-split-chart-2-bar-value'>
              {formatToWholeCurrency(item?.totalSpend)}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AllCategoriesVisualization
