import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import RadioButton from "../../../../../assets/images/v3/radioButton.svg"
import SelectedRadioButton from "../../../../../assets/images/v3/radioButtonSelected.svg"
import Divider from "../../../../core/Divider/v3/Divider"
import { formatAmount } from "../../../../../utils/functions"
import { useSelector } from "react-redux"

const EmiElement = ({ emi, isSelected }) => {
  const { t } = useTranslation()
  const common = useSelector(state => state.common)
  const { txnState } = common
  const interestAmount = emi?.emi * emi?.tenureInMonths - txnState?.amount || 0
  const totalPayable = txnState?.amount + interestAmount + emi?.processingFees

  return (
    <div
      className={`v3-convertToEmi-selectEmi-card ${
        isSelected ? "v3-convertToEmi-selectEmi-selectedCard" : ""
      }`}
    >
      <div
        className={
          isSelected ? "v3-convertToEmi-selectEmi-card-selected-top" : ""
        }
      >
        <div className='v3-convertToEmi-selectEmi-card-tenure'>
          <div>
            <img src={isSelected ? SelectedRadioButton : RadioButton} />
          </div>
          <div
            className={
              isSelected ? "v3-convertToEmi-selectEmi-card-selectedTenure" : ""
            }
          >
            {emi?.tenureInMonths} {t("EmiElement.months")}
          </div>
        </div>
        <div
          className={`v3-convertToEmi-selectEmi-card-emiAmount ${
            isSelected ? "v3-convertToEmi-selectEmi-card-selectedEmiAmount" : ""
          }`}
        >
          <div>
            {formatAmount(emi?.emi)} / {t("EmiElement.mon")}
          </div>
          <div>
            {emi?.interestRateBps
              ? `@${(emi?.interestRateBps / 100).toFixed(2)}% p.a.`
              : "@No Cost"}
          </div>
        </div>
      </div>
      {isSelected && (
        <>
          <Divider />
          <div className='v3-convertToEmi-selectEmi-element-details'>
            <div>{t("EmiElement.interestAndGST", { per: "18%" })}</div>
            <div>{formatAmount(interestAmount)}</div>
          </div>
          <div className='v3-convertToEmi-selectEmi-element-details v3-convertToEmi-selectEmi-element-details-total'>
            <div>{t("EmiElement.totalAmountPayable")}</div>
            <div>{formatAmount(totalPayable)}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default EmiElement
