import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ArrowRightSmall from "../../svg/v3/arrowRightSmall"
import carrotImage from "../../../assets/images/v3/carrotImage.svg"
import rewardIcon from "../../../assets/images/v3/rewardPointIcon.svg"
import { useTranslation } from "react-i18next"

const CategoryCarouselCard = ({
  item,
  activeCategoryIndex,
  index,
  spendGroups,
  totalCategoryAmount,
}) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const navigate = useNavigate()
  const screen = useSelector(state => state.screen)
  const configuredGroups = screen.analyzeSpends.spendCategory
  const spendGroupImage = configuredGroups?.find(
    data => data?.groupingField === item?.groupId,
  )?.iconUrl
  return (
    <div
      className='v3-spend-analytics-category-split-chart-2-widget-category-slide'
      style={{
        background:
          index !== activeCategoryIndex && theme.v3.rawColors.primary.color5,
      }}
      onClick={() =>
        navigate("/spendAnalytics/category", {
          state: {
            category: item,
          },
        })
      }
    >
      <div
        className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part'
        style={{
          background:
            index !== activeCategoryIndex && theme.v3.rawColors.primary.color5,
        }}
      >
        <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left'>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-count'>
            <span className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-count-nr'>
              {index + 1}
            </span>
            /{spendGroups?.data?.spendGroups?.length}
          </div>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category'>
            <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-name'>
              {item?.groupName}
            </div>
            <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-value'>
              {((item?.totalSpend / totalCategoryAmount) * 100).toFixed(0)}%
            </div>
          </div>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-txn-redirection'>
            {item?.countOfTxns}{" "}
            {t("spendAnalytics.singleCategoryCard.transactionsCount")}{" "}
            <span>
              <ArrowRightSmall />
            </span>
          </div>
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants'>
            <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-logos'>
              {item?.merchantList?.map((item, index) => {
                const customClass = index === 0 ? "first" : "second"
                return (
                  <img
                    src={`data:image/png;base64, ${item.merchantLogo}`}
                    className={`v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-${customClass}-logo`}
                    alt='merchant logo'
                    width={19}
                    height={19}
                    style={{ borderRadius: "100%" }}
                  />
                )
              })}
            </div>
            <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-count'>
              {item?.merchantList?.length}{" "}
              {t("spendAnalytics.singleCategoryCard.merchantsCount")}
            </div>
          </div>
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-right'>
          <img
            src={spendGroupImage}
            width={130}
            height={100}
            alt='category icon'
          />
        </div>
      </div>
      <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part'>
        <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-text'>
          {t("spendAnalytics.singleCategoryCard.youHaveEarned")}{" "}
        </div>
        <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-count'>
          <img
            src={rewardIcon}
            style={{ paddingBottom: "2px" }}
            alt='reward icon'
            width={12}
            height={12}
          />{" "}
          <div className='v3-spend-analytics-category-split-chart-2-widget-category-slide-bottom-part-reward-count-value'>
            {item?.rewardPoints}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CategoryCarouselCard
