import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Header } from "../../core"
import { useParams } from "react-router-dom"
import { ColorButton } from "../../core/buttons"
import Divider from "../../core/Divider/v3/Divider"
import OfferDetailsAccordian from "./Components/offerDetailsAccordian"
import moment from "moment"
import Lottie from "lottie-react"
import Rays from "../../../components/svg/v3/Rays.json"
import MerchantOfferCoupon from "./Components/merchantOfferCoupon"
import {
  consoleError,
  getDifferenceInDays,
  hexToRGBA,
} from "../../../utils/functions"
import Hourglass from "../../svg/v3/hourGlassIcon"
import { useDispatch } from "react-redux"
import defaultBanner from "../../../assets/images/v3/BrandBanner.svg"
import defaultLogo from "../../../assets/images/v3/Vector.svg"
import SubscribeRedirectIcon from "../../../assets/images/v3/subscribeRedirect.svg"
import AccountService from "../../../services/AccountService"
import { ErrorContext } from "../../auth/ErrorScreenContext"
import { ErrorType, offerType, offerVisibility } from "../../../utils/constants"
import { useBottomSheet } from "../../auth/BottomSheetContext"
import { BottomSheetType } from "../../../utils/enums"
import { setMerchantBenefits } from "../../../store/actions/Benefits"
import { useSubscribeOffer } from "./subscribeOffer"

const MerchantOfferDetails = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)
  const user = useSelector(state => state.user)

  const { setErrorState } = useContext(ErrorContext)
  const bottomSheet = useBottomSheet()
  const { subscribeOffer } = useSubscribeOffer()

  const [showAnimation, setShowAnimation] = useState(false)
  const [offerExpiry, setOfferExpiry] = useState(false)
  const params = useParams()
  const { BenefitId } = params
  const offer = benefits?.merchantBenefits.filter(offer => {
    return offer?.benefitId === BenefitId
  })?.[0]
  let offerDetails
  if (offer) {
    offerDetails = JSON.parse(offer?.offerDetails)
  }

  const [isOptedIn, setIsOptedIn] = useState(offer?.isAccountOptedIn)

  const isOptInOffer = offer?.visibility === offerVisibility.OPT_IN

  useEffect(() => {
    const couponRevealedOffers = localStorage.getItem("revealedOffers")
    if (!couponRevealedOffers) {
      localStorage.setItem("revealedOffers", JSON.stringify([]))
    }
  }, [])

  const initialRevealedCouponOffers = JSON.parse(
    localStorage.getItem("revealedOffers"),
  )
  const [showCouponCode, setShowCouponCode] = useState(
    initialRevealedCouponOffers?.includes(offer.benefitId) ? true : false,
  )

  const handleCtaClick = () => {
    if (offerDetails?.ctaLink) {
      window.location.href = offerDetails?.ctaLink
    }
  }

  const handleCouponTextClick = () => {
    if (!showCouponCode) {
      setShowCouponCode(true)
      setShowAnimation(true)
      let currentRevealedOffers = JSON.parse(
        localStorage.getItem("revealedOffers"),
      )
      currentRevealedOffers.push(offer.benefitId)
      localStorage.setItem(
        "revealedOffers",
        JSON.stringify(currentRevealedOffers),
      )
    }
  }

  useEffect(() => {
    setOfferExpiry(getDifferenceInDays(offer?.activeTo))
  }, [offer])

  useEffect(() => {
    if (showAnimation) {
      setTimeout(() => {
        setShowAnimation(false)
      }, 3000)
    }
  }, [showAnimation])

  return (
    <>
      <div
        style={{
          height: window.innerHeight,
          backgroundColor: theme.v3.cssVars.leadingWhite,
          overflowY: "auto",
        }}
        className='v3-merchantOfferDetails'
      >
        {showCouponCode && showAnimation && (
          <div
            className='v3-merchantOfferDetails-lottieFile'
            style={{
              height: window.innerHeight,
            }}
            onClick={() => setShowAnimation(false)}
          >
            <Lottie
              loop={false}
              animationData={Rays}
              style={{ width: "100%" }}
            />
            <div style={{ position: "absolute" }}>
              <MerchantOfferCoupon
                offer={offer}
                offerDetails={offerDetails}
                showCouponCode={true}
                showCopyToClipboardButton={false}
              />
            </div>
          </div>
        )}
        <Header
          text={`${t("offers.brandOfferHeader")} - ${offer?.displayTitle}`}
          onBack={async () => {
            window.history.go(-1)
          }}
        />
        <div className='v3-merchantOfferDetails-image-container'>
          <div className='v3-merchantOfferDetails-illustrationImage'>
            {offer?.illustrationUrl ? (
              <img src={offer?.illustrationUrl} alt='illustration' />
            ) : (
              <img src={defaultBanner} />
            )}
          </div>
        </div>
        {offerDetails?.couponCode ? (
          <MerchantOfferCoupon
            offer={offer}
            offerDetails={offerDetails}
            showCouponCode={showCouponCode}
            handleCopyTextClick={handleCouponTextClick}
          />
        ) : offer?.logoUrl ? (
          <div className='v3-merchantOfferDetails-logo'>
            <img src={offer?.logoUrl} alt='logo' />
          </div>
        ) : (
          <div className='v3-merchantOfferDetails-logo'>
            <img src={defaultLogo} alt='logo' />
          </div>
        )}

        <div className='v3-merchantOfferDetails-contentContainer'>
          <div
            className='v3-merchantOfferDetails-description'
            style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
          >
            {offer?.displayDescription || offer?.description}
          </div>
          <div
            className='v3-merchantOfferDetails-offerValidity'
            style={{
              color:
                offerExpiry <= 7
                  ? theme.v3.cssVars.secondary.color1
                  : theme.v3.cssVars.tertiaryNeutral.color2,
            }}
          >
            <Hourglass
              color={
                offerExpiry <= 7
                  ? theme.v3.rawColors.secondary.color1
                  : theme.v3.rawColors.tertiaryNeutral.color2
              }
            />{" "}
            {t("offers.validityText", {
              date: moment(offer?.activeTo).format("DD/MM/YY"),
            })}
          </div>

          {offerDetails?.content && (
            <div
              className='v3-cvpOfferDetails-text-accordian-container'
              style={{ background: theme.v3.cssVars.leadingWhite }}
            >
              {offerDetails?.content?.map((element, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <Divider
                        height='1px'
                        color={theme.v3.rawColors.tertiaryNeutral.color3}
                      />
                    )}
                    <OfferDetailsAccordian
                      header={element?.title}
                      points={element?.points}
                      open={index === 0}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
        <div
          className='v3-cvpOfferDetails-cta'
          style={{ background: theme.v3.cssVars.leadingWhite }}
        >
          <ColorButton
            text={
              isOptInOffer && !isOptedIn
                ? t("offers.optInNowText")
                : offerDetails?.ctaText
            }
            width={"100%"}
            onPress={
              isOptInOffer && !isOptedIn
                ? () =>
                    subscribeOffer(
                      offer?.benefitId,
                      setIsOptedIn,
                      offerType.MERCHANT_OFFERS,
                    )
                : handleCtaClick
            }
            redirectIcon={
              isOptInOffer && !isOptedIn ? SubscribeRedirectIcon : null
            }
          />
        </div>
      </div>
    </>
  )
}

export default MerchantOfferDetails
