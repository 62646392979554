const TokenisationCaretRight = ({ color = "" }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='CaretRight'>
        <path
          id='Vector'
          d='M11.3537 8.35378L6.35372 13.3538C6.30727 13.4002 6.25212 13.4371 6.19142 13.4622C6.13072 13.4874 6.06567 13.5003 5.99997 13.5003C5.93428 13.5003 5.86922 13.4874 5.80852 13.4622C5.74783 13.4371 5.69268 13.4002 5.64622 13.3538C5.59977 13.3073 5.56292 13.2522 5.53778 13.1915C5.51263 13.1308 5.49969 13.0657 5.49969 13C5.49969 12.9343 5.51263 12.8693 5.53778 12.8086C5.56292 12.7479 5.59977 12.6927 5.64622 12.6463L10.2931 8.00003L5.64622 3.35378C5.5524 3.25996 5.49969 3.13272 5.49969 3.00003C5.49969 2.86735 5.5524 2.7401 5.64622 2.64628C5.74004 2.55246 5.86729 2.49976 5.99997 2.49976C6.13265 2.49976 6.2599 2.55246 6.35372 2.64628L11.3537 7.64628C11.4002 7.69272 11.4371 7.74786 11.4623 7.80856C11.4874 7.86926 11.5004 7.93433 11.5004 8.00003C11.5004 8.06574 11.4874 8.13081 11.4623 8.1915C11.4371 8.2522 11.4002 8.30735 11.3537 8.35378Z'
          fill={color || "#1965CF"}
        />
      </g>
    </svg>
  )
}

export default TokenisationCaretRight
