import React from "react"

const Divider = ({
  showCenterMarker = false,
  color = "#dedede",
  height = "1px",
}) => {
  return (
    <div
      className='v3-contentSeperator'
      style={{ background: color, height: height }}
    >
      {showCenterMarker && (
        <div className='v3-contentSeperator-centerMarker'></div>
      )}
    </div>
  )
}

export default Divider
