import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import aboutProgramReward2 from "../../../../../assets/images/aboutProgramReward2.svg"
import { EventName, PwaVersions } from "../../../../../utils/enums"
import { captureEvents } from "../../../../../utils/functions"
import { Header } from "../../../../core"
import ArrowRight from "../../../../svg/arrowRight"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"

const AboutProgram_v2 = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)
  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    captureEvents({ eventName: EventName.ABOUT_PROGRAM, metadata: {} })
  }, [])

  const [isOpen, setIsOpen] = useState(true)
  return (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='About Card Program'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      {config?.version !== PwaVersions.V2_1 &&
        screen?.aboutProgram?.description && (
          <div
            className='aboutProgram-description'
            style={{
              backgroundColor: theme.widgetBackgroundColor,
              color: theme.appTextColor,
            }}
          >
            {screen?.aboutProgram?.description}
          </div>
        )}

      <div
        className='aboutProgram-cvp'
        style={{ backgroundColor: theme.widgetBackgroundColor }}
      >
        {config?.version === PwaVersions.V2_1 && (
          <div className='aboutProgram-description-container_v2'>
            <div
              className='aboutProgram-description_v2'
              style={{
                backgroundColor: theme.widgetBackgroundColor,
                color: theme.appTextColor,
              }}
            >
              {screen?.aboutProgram?.description}
            </div>
          </div>
        )}
        {screen?.aboutProgram?.cvp.map(item => (
          <div className='aboutProgram-cvp-container'>
            <div className='aboutProgram-cvp-border'>
              <div className='aboutProgram-cvp-img-container'>
                {item?.imageUrl && (
                  <img
                    className='aboutProgram-cvp-img'
                    src={item?.imageUrl || aboutProgramReward2}
                    alt='Alternate Text '
                  />
                )}
              </div>
              <div
                style={{
                  width: "78%",
                  backgroundColor: theme.widgetBackgroundColor,
                }}
              >
                <div
                  className='aboutProgram-cvp-title'
                  style={{ color: theme.appTextColor }}
                >
                  {item?.title}
                </div>
                <div
                  className='aboutProgram-cvp-description'
                  style={{ color: `${theme.appTextColor}90` }}
                >
                  {item?.desc}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {screen?.aboutProgram?.knowMoreUrl && (
        <div
          className='aboutProgram-knowMore-container'
          onClick={() =>
            (window.location.href =
              screen?.aboutProgram?.knowMoreUrl || "https://www.google.com")
          }
        >
          <div className='aboutProgram-knowMore-text'>
            <div style={{ color: theme.appTextColor }}>
              {`${screen?.aboutProgram?.knowMoreText} ` ||
                "Want to know more? "}
            </div>
            <div
              style={{
                color: theme.color1,
                marginLeft: "4px",
                marginRight: "4px",
              }}
            >
              {t("manageCard.clickHereText")}
            </div>
          </div>
          <div className='aboutProgram-knowMoreUrl'>
            <ArrowRight
              className='aboutProgram-knowMoreUrl-arrowRight'
              size='10px'
              color={theme.color1}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AboutProgram_v2
