import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../../auth/ApiIds"
import CardService from "../../../../../services/CardService"
import { setCards } from "../../../../../store/actions/User"
import { handleApiAuth } from "../../../../../utils/auth"
import {
  AU_BlockCodes,
  BlockReplaceTncActions,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "../../../../../utils/enums"
import { captureEvents, consoleError } from "../../../../../utils/functions"
import { EmbeddedLink, Header } from "../../../../core"
import { ColorButton } from "../../../../core/buttons"
import ErrorScreen from "../../../../messages/errorScreen"
import Info from "../../../../svg/info"

import { Checkbox } from "../../../../../core/Checkbox"
import { setdisabledFunctionsByBlockCode } from "../../../../../store/actions/Session"
import { DisplayConfig } from "../../../../../utils/constants"
import { showToast1 } from "../../../../core/toast"
import CardReplaceFee from "../../../../svg/cardReplaceFee"
import { useToast } from "../../../../../nativeBaseReplacements/useToast"
import useWindowDimensions from "../../../../../hooks/useWindowDimensionsWeb"

const NewCardRequest_v2 = () => {
  const windowDimension = useWindowDimensions()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const { blockReplaceCard } = screen ?? {}
  const cardReplacementFeeInfo = blockReplaceCard?.cardReplacementFeeInfo

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const nativeToast = useToast()
  const [address, setAddress] = useState("")
  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [isTncChecked, setIsTncChecked] = useState(
    Boolean(blockReplaceCard?.tncIsCheckedByDefault),
  )
  let currentCard = user?.cards[0]
  console.log(user)
  const replacementFee = user?.cardReplacementFee

  const isShowInfotipEnabled = blockReplaceCard?.isShowInfotipEnabled
  const infotipContent = blockReplaceCard?.infotipContent

  useEffect(() => {
    captureEvents({ eventName: EventName.NEW_CARD_REQUEST, metadata: {} })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!user.cards[0].isHotlisted) {
        toast("Cannot request a new card, \nas card is already active")
        navigate("/")
        return
      }
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.CARD_DASHBOARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        toast("Access has been blocked to \nrequest a new card")
        navigate("/")
        return
      }

      if (
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        toast("Access has been blocked")
        navigate("/")
        return
      }
      if (
        user.account.status !== "ACTIVE" ||
        !user.cards[0] ||
        !user.cards[0].isHotlisted
      ) {
        navigate("/")
        return
      }

      const currentAddress = user.customer?.currentAddress

      if (currentAddress) {
        const line1 = currentAddress.line1 ? currentAddress.line1 : ""
        const line2 = currentAddress.line2 ? ", " + currentAddress.line2 : ""
        const line3 = currentAddress.line3 ? ", " + currentAddress.line3 : ""
        const city = currentAddress.city ? ", " + currentAddress.city : ""
        const pincode = currentAddress.pincode
          ? " - " + currentAddress.pincode
          : ""
        const state = currentAddress.state ? ", " + currentAddress.state : ""

        setAddress(`${line1}${line2}${line3}${city}${pincode}${state}`)
      } else {
        setError(true)
      }
    })()
  }, [])

  const onEnableDomesticOnlineSuccess = async apiToken => {
    try {
      const response = await CardService.updateCardControls(
        apiToken,
        currentCard?.id,
        {
          updateCardControls: [
            {
              txnTags: {
                location: "DOMESTIC",
                txnType: "RETAIL",
                txnChannel: "ECOMMERCE",
              },
              isEnabled: true,
            },
          ],
        },
      )
      const result = response?.data

      if (result?.success) {
        dispatch(setCards({ cards: [result.data] }))
        toast(
          "Blocked Card has been replaced and \ndomestic e-commerce channel is \nenabled",
        )
        navigate("/", { replace: true })
      } else {
        navigate("/", { replace: true })
        toast("Blocked Card has been replaced")
        consoleError(
          "Failed to enable domestic online transaction after Replacing Card. Error: " +
            result.errors,
        )
      }
    } catch (error) {
      navigate("/", { replace: true })
      consoleError(
        "An exception occurred while enabling domestic online transaction after Replacing Card. Error: " +
          error,
      )
    }
  }

  const onActivateCardSuccess = async apiToken => {
    try {
      // when activating card from tnc action, as of now activation code is not needed
      const response = await CardService.activateCard(apiToken, currentCard?.id)
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...user.cards?.[0] }
        tempCard.isActivated = true
        dispatch(setCards({ cards: [tempCard] }))
        toast("Blocked Card has been replaced and \n activated successfully")
        navigate("/", { replace: true })
      } else {
        navigate("/", { replace: true })
        toast("Blocked Card has been replaced")
        consoleError(
          "Failed to activate card after set mpin. Error: " + result.errors,
        )
      }
    } catch (error) {
      navigate("/", { replace: true })
      toast("Blocked Card has been replaced")
      consoleError(
        "An exception occurred while activating card after set mpin. Error: " +
          error,
      )
    }
  }

  const handleSubmitClick = async () => {
    if (blockReplaceCard?.isTncEnabled && !isTncChecked) {
      return false
    }
    setSubmitLoading(true)
    handleApiAuth({
      apiId: ApiIds.REPLACE_CARD,
      onAuthSuccess: onAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to request a new card",
      mpinReason: "to request a new card",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const updateBlockCodeAction = () => {
    let disabledFunctions = session?.disabledFunctionsByBlockCode
    if (session?.issuer === Issuers.AU_BANK) {
      let index = disabledFunctions.indexOf(AU_BlockCodes.TEMP_CARD_DISABLE)
      if (index > -1) {
        disabledFunctions.splice(index, 1)
      }
      let indexOfLocked = disabledFunctions.indexOf(AU_BlockCodes.CARD_LOCKED)
      if (indexOfLocked > -1) {
        disabledFunctions.splice(indexOfLocked, 1)
      }

      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
    if (session?.issuer === Issuers.FEDERAL_BANK) {
      let index = disabledFunctions.indexOf(Federal_BlockCodes.CARD_BLOCKED)
      if (index > -1) {
        disabledFunctions.splice(index, 1)
      }
      dispatch(
        setdisabledFunctionsByBlockCode({
          disabledFunctionsByBlockCode: disabledFunctions,
        }),
      )
    }
  }

  const onAuthSuccess = async apiToken => {
    try {
      const response = await CardService.replace(apiToken, user.cards[0]?.id, {
        cardExpiry: user.cards[0]?.cardExpiry?.replace(/\//g, ""), // remove forward slash from expiry
      })
      const result = response?.data
      if (result?.success) {
        captureEvents({ eventName: EventName.CARD_REPLACED, metadata: {} })
        const tempCard = { ...result.data }
        currentCard = tempCard

        updateBlockCodeAction()

        dispatch(setCards({ cards: [tempCard] }))
        if (
          blockReplaceCard?.tncAction ===
          BlockReplaceTncActions.ENABLE_DOMESTIC_ONLINE
        ) {
          await handleApiAuth({
            apiId: ApiIds.UPDATE_CARD_CONTROLS,
            onAuthSuccess: onEnableDomesticOnlineSuccess,
            flowId: "5",
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to enable domestic online transactions",
            mpinReason: "to enable domestic online transactions",
            toast,
            navigate,
          })
        } else if (
          blockReplaceCard?.tncAction === BlockReplaceTncActions.ACTIVATE_CARD
        ) {
          await handleApiAuth({
            apiId: ApiIds.ACTIVATE_CARD,
            onAuthSuccess: onActivateCardSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to activate card",
            mpinReason: "to activate card",
            toast,
            navigate,
          })
        } else {
          toast("Blocked Card has been replaced")
          navigate("/", { replace: true })
        }
      } else {
        consoleError(result.errors)
        toast("New card request failed")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return error ? (
    <div
      className='newCardRequest-error-container'
      style={{ height: windowDimension.height }}
    >
      <ErrorScreen />
    </div>
  ) : (
    <div
      className='newCardRequest'
      style={{
        maxWidth: DisplayConfig.MAX_WIDTH,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <div>
        <Header
          text='Block/Replace Card'
          onBack={async () => {
            navigate("/ManageCard")
          }}
        />
        <div
          style={{
            backgroundColor: theme.backgroundColor,
            minHeight: windowDimension.height,
          }}
        >
          <div
            className='newCardRequest-content'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            {/* body content start */}
            <div
              className='newCardRequest-content-header'
              style={{
                fontFamily: theme.fontFamily,
                color: theme.appTextColor,
              }}
            >
              Request a New Card
            </div>

            {cardReplacementFeeInfo && (
              <div
                className='newCardRequest-content-address newCardRequest-content-card-replacement-fee  '
                style={{ backgroundColor: `${theme.appTextColor}20` }}
              >
                <CardReplaceFee
                  color1={theme.appTextColor}
                  classname={"newCardRequest-content-replace-fee-rupee"}
                />
                <div
                  className='newCardRequest-content-address-text newCardRequest-content-replacement-fee-text '
                  style={{
                    color: theme.appTextColor,
                    fontFamily: theme.fontFamily,
                  }}
                >
                  {cardReplacementFeeInfo}
                </div>
              </div>
            )}
            <div
              className='newCardRequest-content-subHeader'
              style={{
                fontFamily: theme.fontFamily,
                color: `${theme.appTextColor}90`,
              }}
            >
              Your card will be delivered to your current billing address as
              below
            </div>
            {/* body title end */}

            {/* content box start */}
            <div
              className='newCardRequest-content-address'
              style={{ backgroundColor: `${theme.appTextColor}20` }}
            >
              <div
                className='newCardRequest-content-address-text'
                style={{
                  color: theme.appTextColor,
                  fontFamily: theme.fontFamily,
                }}
              >
                {address}
              </div>
            </div>
            {/* body content end */}
          </div>
          {isShowInfotipEnabled && (
            <div className='newCardRequest-message'>
              <div
                className='newCardRequest-message-container'
                style={
                  config?.version === PwaVersions.V2_1
                    ? { backgroundColor: theme.widgetBackgroundColor }
                    : {}
                }
              >
                <div className='newCardRequest-message-icon'>
                  <Info
                    iconProps={{
                      size: "40px",
                      mt: "-1px",
                      fill: `${theme.appTextColor}`,
                    }}
                  />
                </div>
                <div
                  className='newCardRequest-message-text'
                  style={{ color: theme.appTextColor }}
                >
                  {infotipContent}
                </div>
              </div>
            </div>
          )}

          {/*    CTA Section */}
          <div
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            className='newCardRequest-ctaSection'
          >
            {blockReplaceCard?.isTncEnabled && (
              <div
                className='newCardRequest-tnc'
                style={{ maxW: DisplayConfig.MAX_WIDTH }}
              >
                <div
                  className='newCardRequest-tnc-message'
                  style={{ backgroundColor: theme.widgetBackgroundColor }}
                >
                  <div
                    onClick={() => setIsTncChecked(!isTncChecked)}
                    className='newCardRequest-tnc-checkBox'
                  >
                    <Checkbox
                      isChecked={isTncChecked}
                      className={
                        isTncChecked
                          ? "newCardRequest-tnc-checkBox-checked"
                          : "newCardRequest-tnc-checkBox-notchecked"
                      }
                      checkmarkClassName={
                        isTncChecked
                          ? "newCardRequest-tnc-checkMark-checked"
                          : "newCardRequest-tnc-checkMark-notchecked"
                      }
                      style={{
                        backgroundColor: isTncChecked ? theme.color1 : null,
                      }}
                    />
                  </div>
                  <div
                    className='newCardRequest-tnc-link'
                    style={{ color: theme.appTextColor }}
                  >
                    <EmbeddedLink text={blockReplaceCard?.tncUrl} />
                  </div>
                </div>
              </div>
            )}
            <ColorButton
              isDisable={blockReplaceCard?.isTncEnabled && !isTncChecked}
              text='Confirm'
              onPress={handleSubmitClick}
              isLoading={submitLoading}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewCardRequest_v2
