import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import greyCircle from "../../assets/images/greyCircle.svg"
import { EventName, PwaVersions } from "../../utils/enums"
import {
  captureEvents,
  isIosDevice,
  postMessageToIos,
} from "../../utils/functions"
import { Header } from "../core"
import rewardsErrorIcon from "../svg/rewardsError.svg"
import styles from "./styles"
import View from "../../nativeBaseReplacements/View"
import Text from "../../nativeBaseReplacements/Text"
import { ScrollView } from "../../nativeBaseReplacements"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"

const RewardsError = () => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()

  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const user = useSelector(state => state.user)
  const [pageTitle, setPageTitle] = useState("")

  const location = useLocation()
  const headerTitle = location?.state?.headerTitle
  const timeLeft = location?.state?.time

  useEffect(() => {
    captureEvents({ eventName: EventName.ERROR_SCREEN_V2_1, metadata: {} })
  }, [])
  useEffect(() => {
    let title = t("messages.accessBlocked")

    if (!title) {
      navigate("/")
    }
    setPageTitle(title)
  }, [])

  const description = screen.accountClosed
  const isMobileNumPresent = () => {
    if (description?.moblieNo) return "Or"
    else return null
  }
  const backHome = () => {
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  console.log(location)
  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  //width will be minimum of (412, windowWidth)
  let windowWidth = 412
  if (windowDimensions.width < windowWidth) {
    windowWidth = windowDimensions.width
  }

  return (
    <View
      {...styles.mainView}
      minH={windowDimensions.height}
      background={theme.backgroundColor}
    >
      <ScrollView width={"100%"}>
        <Header
          marginBottom='0px'
          text={headerTitle || t("messages.rewards")}
          onBack={async () => {
            navigate("/")
          }}
        />
        <View
          {...styles.innerView}
          bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
          minW={windowWidth}
        >
          <View {...styles.section1}>
            <View mt='30px'>
              <img
                style={{
                  position: "absolute",
                  zIndex: -1,
                  top: "-25px",
                  height: "150px",
                  left: "-25px",
                  width: "150px",
                }}
                src={greyCircle}
              />
              <img src={rewardsErrorIcon} />
              {/* <Image {...styles.image} source={{ uri: ErrorImage }} /> */}
            </View>
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}></View>
              <View {...styles.text2Container}>
                <Text
                  {...styles.errorScreenV2_1_text2}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {location?.state?.text1 || t("messages.unableToFetchRewards")}
                </Text>

                <Text
                  {...styles.errorScreenV2_1_text2}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {location?.state?.text2 || t("messages.tryAgainLater")}
                </Text>
              </View>
            </View>
          </View>

          <View {...styles.button} position='fixed' bottom={"20px"} mt='25%'>
            <View
              {...styles.errorScreenV2_1_ctaDiv}
              style={{ borderColor: theme.color1 }}
              onClick={async () => {
                navigate("/")
              }}
            >
              <Text
                {...styles.errorScreenV2_1_ctaText}
                style={{ color: theme.color1 }}
              >
                {t("messages.returnHome")}
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default RewardsError
