const DocumentList = ({ color = "#1965CF" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
    >
      <path
        d='M20.25 9.25H18.5V6C18.5 3.582 17.168 2.25 14.75 2.25H6.75C4.332 2.25 3 3.582 3 6V18C3 20.418 4.332 21.75 6.75 21.75H19.75C21.267 21.75 22.5 20.517 22.5 19V11.5C22.5 10.259 21.49 9.25 20.25 9.25ZM6.75 20.25C5.173 20.25 4.5 19.577 4.5 18V6C4.5 4.423 5.173 3.75 6.75 3.75H14.75C16.327 3.75 17 4.423 17 6V19C17 19.45 17.108 19.875 17.301 20.25H6.75ZM21 19C21 19.689 20.439 20.25 19.75 20.25C19.061 20.25 18.5 19.689 18.5 19V10.75H20.25C20.663 10.75 21 11.086 21 11.5V19ZM15 8C15 8.414 14.664 8.75 14.25 8.75H9.75C9.336 8.75 9 8.414 9 8C9 7.586 9.336 7.25 9.75 7.25H14.25C14.664 7.25 15 7.586 15 8ZM15 12C15 12.414 14.664 12.75 14.25 12.75H9.75C9.336 12.75 9 12.414 9 12C9 11.586 9.336 11.25 9.75 11.25H14.25C14.664 11.25 15 11.586 15 12ZM15 16C15 16.414 14.664 16.75 14.25 16.75H9.75C9.336 16.75 9 16.414 9 16C9 15.586 9.336 15.25 9.75 15.25H14.25C14.664 15.25 15 15.586 15 16ZM8 8C8 8.414 7.664 8.75 7.25 8.75C6.836 8.75 6.5 8.414 6.5 8C6.5 7.586 6.836 7.25 7.25 7.25C7.664 7.25 8 7.586 8 8ZM8 12C8 12.414 7.664 12.75 7.25 12.75C6.836 12.75 6.5 12.414 6.5 12C6.5 11.586 6.836 11.25 7.25 11.25C7.664 11.25 8 11.586 8 12ZM8 16C8 16.414 7.664 16.75 7.25 16.75C6.836 16.75 6.5 16.414 6.5 16C6.5 15.586 6.836 15.25 7.25 15.25C7.664 15.25 8 15.586 8 16Z'
        fill={color}
      />
    </svg>
  )
}

export default DocumentList
