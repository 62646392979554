import React from "react"

const Filter = ({ color = "#25314C" }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M8 6H3C2.448 6 2 5.552 2 5C2 4.448 2.448 4 3 4H8C8.552 4 9 4.448 9 5C9 5.552 8.552 6 8 6Z'
        fill={color}
      />
      <path
        d='M8 8C7.448 8 7 7.552 7 7V3C7 2.448 7.448 2 8 2C8.552 2 9 2.448 9 3V7C9 7.552 8.552 8 8 8Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M21 6H12C11.448 6 11 5.552 11 5C11 4.448 11.448 4 12 4H21C21.552 4 22 4.448 22 5C22 5.552 21.552 6 21 6Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M12 13H3C2.448 13 2 12.552 2 12C2 11.448 2.448 11 3 11H12C12.552 11 13 11.448 13 12C13 12.552 12.552 13 12 13Z'
        fill={color}
      />
      <path
        d='M16 15C15.448 15 15 14.552 15 14V10C15 9.448 15.448 9 16 9C16.552 9 17 9.448 17 10V14C17 14.552 16.552 15 16 15Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M21 13H16C15.448 13 15 12.552 15 12C15 11.448 15.448 11 16 11H21C21.552 11 22 11.448 22 12C22 12.552 21.552 13 21 13Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M8 20H3C2.448 20 2 19.552 2 19C2 18.448 2.448 18 3 18H8C8.552 18 9 18.448 9 19C9 19.552 8.552 20 8 20Z'
        fill={color}
      />
      <path
        d='M8 22C7.448 22 7 21.552 7 21V17C7 16.448 7.448 16 8 16C8.552 16 9 16.448 9 17V21C9 21.552 8.552 22 8 22Z'
        fill={color}
      />
      <path
        opacity='0.4'
        d='M21 20H12C11.448 20 11 19.552 11 19C11 18.448 11.448 18 12 18H21C21.552 18 22 18.448 22 19C22 19.552 21.552 20 21 20Z'
        fill={color}
      />
    </svg>
  )
}

export default Filter
