import React from "react";
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js";

function RewardWrapper(){
    return(
        <CardSkeleton margin="20px auto 0px auto" padding="12px" height='150px' alignItems="center" justifyContent="space-between" width='90%' display='flex'>
            <TextWrapperSkeleton height="120px" width='25%' />
            <CardSkeleton display="flex" flexDirection="column" alignItems="center" justifyContent="center" height='70px' width="60%">
                <TextWrapperSkeleton width="200px"/>
                <TextWrapperSkeleton margin="10px 0px" width="200px"/>
                <TextWrapperSkeleton width="200px"/>
            </CardSkeleton>
      </CardSkeleton>
    )
}

function RewardLoader() {
  return (
    <>
      <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        br='0'
        height="56px"
      >
        <TextWrapperSkeleton height='12px' width='200px' />
      </CardSkeleton>
      <RewardWrapper/>
      <RewardWrapper/>
      <RewardWrapper/>
      <RewardWrapper/>
    </>
  )
}

export default RewardLoader;
