import { AgCharts } from "ag-charts-react"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import exchangeCircle from "../../../assets/images/v3/exhangeCircle.svg"
import dotIcon from "../../../assets/images/v3/dotIcon.svg"
import ArrowDown from "../../svg/arrowDown"
import InfoCircleGrey from "../../../assets/images/v3/infoCircleGreyBg.svg"
import moment from "moment"
import {
  formatAmount,
  getMonthForIndex,
  getMonthlyYearlyDateRanges,
} from "../../../utils/functions"
import AccountService from "../../../services/AccountService"
import { useTranslation } from "react-i18next"
import { DateFilterTypes } from "../../../utils/constants"

const SpendAnalyticsChart = ({
  handleCategoryFilter,
  selectedGroup,
  spendGroups,
}) => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const theme = useSelector(state => state.theme)

  const [chartOptions, setChartOptions] = useState()
  const [chartData, setChartData] = useState([])
  const [clickedMonth, setClickedMonth] = useState()
  const [isChartInit, setIsChartInit] = useState(false)
  const [dateInterval, setDateInterval] = useState(DateFilterTypes.MONTH)
  const [chartWidth, setChartWidth] = useState(400) //set to max screen width

  const handleBarClick = e => {
    const month = e.datum.xKey
    setClickedMonth(month)
  }

  const getSpendTrend = async spendGroupIds => {
    let startDate, endDate
    if (dateInterval === DateFilterTypes.MONTH) {
      startDate = moment().startOf("year").format("YYYY-MM-DD")
      endDate = moment(new Date()).format("YYYY-MM-DD")
    } else {
      startDate = moment()
        .startOf("year")
        .subtract(5, "years")
        .format("YYYY-MM-DD")
      endDate = moment(new Date()).endOf("year").format("YYYY-MM-DD")
    }
    try {
      const response = await AccountService.getSpendTrend(user?.account?.id, {
        spendGroupIds: spendGroupIds,
        dateRanges: getMonthlyYearlyDateRanges(
          startDate,
          endDate,
          dateInterval,
        ),
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!spendGroups?.data?.spendGroups) return
    ;(async function () {
      setIsChartInit(false)
      setChartData()
      try {
        let idsOfAllGroups = spendGroups.data.spendGroups.map(
          group => group.groupId,
        )
        const response = await getSpendTrend(idsOfAllGroups)

        if (response.data.success) {
          let updatedTrendData
          if (dateInterval === DateFilterTypes.MONTH) {
            const trendData = response.data.data.payload.data
            updatedTrendData = trendData.map(data => ({
              xKey: getMonthForIndex(moment(data.fromDate).month()),
              totalSpend: data?.totalSpend || 0,
            }))
          } else {
            const trendData = response.data.data.payload.data
            updatedTrendData = trendData.map(data => ({
              xKey: moment(data.fromDate).year(),
              totalSpend: data?.totalSpend || 0,
              groupSpend: null,
            }))
          }
          setChartData(updatedTrendData)
          setIsChartInit(true)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [spendGroups, dateInterval, selectedGroup])

  useEffect(() => {
    if (
      !selectedGroup ||
      !isChartInit ||
      (selectedGroup && selectedGroup?.groupName === "none")
    )
      return
    ;(async function () {
      try {
        const response = await getSpendTrend([selectedGroup.groupId])

        if (response.data.success) {
          let updatedTrendData
          if (dateInterval === DateFilterTypes.MONTH) {
            const trendData = response.data.data.payload.data
            updatedTrendData = chartData.map(chartItem => {
              const data = trendData.find(
                d =>
                  chartItem.xKey ===
                  getMonthForIndex(moment(d.fromDate).month()),
              )
              return {
                ...chartItem,
                groupSpend: data?.totalSpend || 0,
              }
            })
          } else {
            const trendData = response.data.data.payload.data
            updatedTrendData = chartData.map(chartItem => {
              const data = trendData.find(
                d => chartItem.xKey === moment(d.fromDate).year(),
              )
              return {
                ...chartItem,
                groupSpend: data?.totalSpend || 0,
              }
            })
          }
          setChartData(updatedTrendData)
        }
      } catch (error) {
        console.log(error)
      }
    })()
  }, [selectedGroup, isChartInit])

  useEffect(() => {
    if (!isChartInit || !chartData.some(data => data.totalSpend)) return
    const barWidth = 70
    const totalBars = chartData.length
    const calculatedWidth = Math.max(totalBars * barWidth, 450)
    setChartWidth(calculatedWidth)
    setChartOptions({
      data: chartData,
      series: [
        {
          type: "bar",
          xKey: "xKey",
          yKey: "totalSpend",
          cornerRadius: 8,
          fill: theme.v3.rawColors.primary.color4,
          itemStyler: ({ datum, fill }) => ({
            fill:
              datum.xKey === clickedMonth
                ? theme.v3.rawColors.primary.color1
                : fill,
            stroke:
              datum.xKey === clickedMonth
                ? theme.v3.rawColors.primary.color1
                : fill,
          }),
          label: {
            enabled: true,
            placement: "outside",
            fontSize: "0.625rem",
            fontWeight: 700,
            color: theme.v3.rawColors.primary.color1,
            formatter: params => {
              const amount = formatAmount(params?.value).slice(0, -3)
              return params.datum.xKey === clickedMonth ? `${amount}` : ""
            },
          },
          tooltip: { enabled: false },
          listeners: { nodeClick: e => handleBarClick(e) },
          zIndex: 1,
        },
        {
          type: "line",
          xKey: "xKey",
          yKey: "groupSpend",
          stroke: theme.v3.rawColors.primaryBase.color1,
          strokeWidth: 1,
          marker: {
            enabled: true,
            fill: theme.v3.rawColors.primaryBase.color1,
            stroke: theme.v3.rawColors.primaryBase.color1,
            size: 4,
          },
          label: {
            enabled: true,
            placement: "outside",
            fontWeight: 600,
            fontSize: "0.625rem",
            color: theme.v3.leadingWhite,
            formatter: params => {
              const amount = formatAmount(params?.value).slice(0, -3)
              return params.datum.xKey === clickedMonth ? `${amount}` : ""
            },
          },
          tooltip: { enabled: false },
          zIndex: 999,
          highlightStyle: { series: { enabled: false } },
        },
      ],
      legend: { enabled: false },
      axes: [
        {
          type: "category",
          position: "bottom",
          label: { color: theme.v3.rawColors.tertiaryNeutral.color2 },
        },
        {
          type: "number",
          position: "left",
          label: {
            color: theme.v3.rawColors.tertiaryNeutral.color2,
            formatter: ({ value }) => `₹${(value / 1000).toFixed(0)}K`,
          },
        },
      ],
    })
  }, [clickedMonth, chartData, isChartInit])

  const handleToggleDateType = () => {
    setDateInterval(prev =>
      prev === DateFilterTypes.MONTH
        ? DateFilterTypes.YEAR
        : DateFilterTypes.MONTH,
    )
  }

  return (
    <div className='v3-spend-analytics-chart-1-container'>
      <div className='v3-spend-analytics-chart-1-header'>
        {t("spendAnalytics.spendAnalyticsChart.chartHeader")}
        <br /> {user?.customer?.firstName}!
      </div>
      <div className='v3-spend-analytics-chart-1-filter-container'>
        <div className='v3-spend-analytics-chart-1-overview-period'>
          <div className='v3-spend-analytics-chart-1-overview-period-header'>
            {t("spendAnalytics.spendAnalyticsChart.overViewPeriod")}
          </div>
          <div
            className='v3-spend-analytics-chart-1-overview-period-filter-tab'
            onClick={handleToggleDateType}
          >
            <div className='v3-spend-analytics-chart-1-overview-period-value'>
              {dateInterval === DateFilterTypes.MONTH ? "Year" : "Month"}
            </div>
            <div className='v3-spend-analytics-chart-1-overview-period-icon'>
              <img src={exchangeCircle} alt='exchange icon' />
            </div>
          </div>
        </div>
        <div className='v3-spend-analytics-chart-1-category-trend'>
          <div className='v3-spend-analytics-chart-1-category-trend-header'>
            {t("spendAnalytics.spendAnalyticsChart.categoryTrend")}
          </div>
          <div
            className='v3-spend-analytics-chart-1-category-trend-fitler-tab'
            onClick={handleCategoryFilter}
          >
            <div className='v3-spend-analytics-chart-1-category-trend-filter-tab-left'>
              <div className='v3-spend-analytics-chart-1-category-trend-circle-icon'>
                <img src={dotIcon} alt='image' />
              </div>
              <div className='v3-spend-analytics-chart-1-category-trend-value'>
                <span>
                  <img src={selectedGroup?.iconUrl} width={10} height={10} />
                </span>{" "}
                {selectedGroup?.groupName}
              </div>
            </div>
            <div className='v3-spend-analytics-chart-1-category-trend-tab-icon'>
              <ArrowDown
                color={theme.v3.cssVars.primaryBase.color1}
                size={"12px"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='v3-spend-analytics-chart-1-widget-container'>
        <div
          className='v3-spend-analytics-chart-1-widget'
          style={{ width: `${chartWidth}px` }}
        >
          <AgCharts options={chartOptions} />
        </div>
      </div>
      <div className='v3-spend-analytics-chart-1-info-container'>
        <img src={InfoCircleGrey} alt='info circle image' />
        <div className='v3-spend-analytics-chart-1-info-text'>
          {t("spendAnalytics.spendAnalyticsChart.chartInfoText")}
        </div>
      </div>
    </div>
  )
}

export default SpendAnalyticsChart
