import React, { useState } from "react"
import { useSelector } from "react-redux"
import ArrowDown from "../../svg/arrowDown"
import ArrowUp from "../../svg/arrowUp"

const RewardsAccordian = ({ header, content, isOpen = false }) => {
  const theme = useSelector(state => state.theme)
  const [isAccordianOpen, setIsAccordianOpen] = useState(isOpen)

  const {
    tertiaryNeutral: { color1: tertiaryNeutralColor1 },
  } = theme?.v3?.rawColors

  return (
    <div className='v3-rewards-cashback-accordian'>
      <div
        className='v3-offerDetails-accordian-header'
        style={{ color: theme?.v3.cssVars.tertiaryNeutral.color1 }}
      >
        {header}{" "}
        <div onClick={() => setIsAccordianOpen(!isAccordianOpen)}>
          {!isAccordianOpen ? (
            <ArrowDown size='12px' color={tertiaryNeutralColor1} />
          ) : (
            <ArrowUp color={tertiaryNeutralColor1} size='12px' />
          )}
        </div>
      </div>
      {isAccordianOpen && (
        <div className='v3-rewards-cashback-accordian-content'>{content}</div>
      )}
    </div>
  )
}

export default RewardsAccordian
