import ViewNowArrow from "../../../svg/v3/viewAllArrow"
import { Swiper, SwiperSlide } from "swiper/react"
import { useEffect, useState } from "react"

const BannerNudges_v3 = ({ nudges }) => {
  const [bannerNudges, setBannerNudges] = useState([])
  const [bannerLength, setBannerLength] = useState(0)

  useEffect(() => {
    // Filter banner nudges
    const filteredBannerNudges = nudges?.filter(nudge => {
      const nudgeDetails = JSON.parse(
        nudge?.displayOptions?.nudgeDetails || "{}",
      )
      return nudgeDetails?.variant === "BANNER"
    })
    setBannerNudges(filteredBannerNudges)
    setBannerLength(filteredBannerNudges?.length)
  }, [nudges])

  return (
    <Swiper
      spaceBetween={1}
      slidesPerView={"auto"}
      className='v3-bannerNudges-swiper'
    >
      {bannerNudges?.map((obj, index) => (
        <SwiperSlide
          key={index}
          style={{ width: bannerLength !== 1 ? "fit-content" : "" }}
        >
          <BannerNudge obj={obj} bannerLength={bannerLength} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

const BannerNudge = ({ obj, bannerLength }) => {
  const single = bannerLength === 1

  const nudgeDetails = JSON.parse(obj?.displayOptions?.nudgeDetails || "{}")
  const handleBtn = () => {
    window.open(nudgeDetails.redirectionUrl, "_blank")
  }

  if (nudgeDetails.illustrationUrl) {
    return (
      <div className='v3-bannerNudges-container'>
        <div className='v3-bannerNudges-inner-container'>
          <div className='v3-bannerNudges-content'>
            <div className='v3-bannerNudges-title'>{nudgeDetails.title}</div>
            <div className='v3-bannerNudges-description'>
              {nudgeDetails.description}
            </div>
            <div className='v3-bannerNudges-btn' onClick={handleBtn}>
              <ViewNowArrow />
            </div>
          </div>
          <div className='v3-bannerNudges-img'>
            <img src={nudgeDetails.illustrationUrl} style={{ width: "90%" }} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='v3-bannerNudges-container'>
        <div
          className={`v3-bannerNudges-inner-container ${bannerLength === 1 ? "v3-bannerNudges-2" : "v3-bannerNudges-3"}`}
        >
          <div className='v3-bannerNudges-content bannerNudges-content-2'>
            <div className='v3-bannerNudges-title bannerNudges-title-2'>
              {nudgeDetails.title}
            </div>
            <div className='v3-bannerNudges-description bannerNudges-description-2'>
              {nudgeDetails.description}
            </div>
            {bannerLength > 1 && (
              <div onClick={handleBtn}>
                <ViewNowArrow />
              </div>
            )}
          </div>
          {bannerLength === 1 && (
            <div className='v3-bannerNudges-btn2' onClick={handleBtn}>
              <ViewNowArrow />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default BannerNudges_v3
