export const Envs = Object.freeze({
  DEV: "dev",
  DEV_MOBILE: "dev-mobile",
  UAT: "uat",
  SANDBOX: "sandbox",
  PROD: "prod",
  AU_SANDBOX: "au-sandbox",
  AU_PROD: "au-prod",
  FED_PROD: "fed-prod",
})

export const SetMpinTncActions = Object.freeze({
  NONE: "NONE",
  ENABLE_DOMESTIC_ONLINE: "ENABLE_DOMESTIC_ONLINE",
  ACTIVATE_CARD: "ACTIVATE_CARD",
})

export const PwaVersions = Object.freeze({
  V1: "v1",
  V2: "v2",
  V2_1: "v2_1",
  V3: "v3",
})

export const AU_BlockCodes = Object.freeze({
  ENTIRE_PWA_ACCOUNT_DISABLE: "AU_ENTIRE_PWA_ACCOUNT_DISABLE",
  ENTIRE_PWA_ACCOUNT_CARD_DISABLE: "AU_ENTIRE_PWA_ACCOUNT_CARD_DISABLE",
  REGISTRATION_DISABLE: "AU_REGISTRATION_DISABLE",
  LOGIN_DISABLE: "AU_LOGIN_DISABLE",
  CARD_DASHBOARD_DISABLE: "AU_CARD_DASHBOARD_DISABLE",
  TEMP_CARD_DISABLE: "AU_TEMP_CARD_DISABLE",
  PERMANENT_CARD_DISABLE: "AU_PERMANENT_CARD_DISABLE",
  UNBLOCK_CARD_DISABLE: "AU_UNBLOCK_CARD_DISABLE",
  REPLACE_CARD_DISABLE: "AU_REPLACE_CARD_DISABLE",
  RESET_PIN_DISABLE: "AU_RESET_PIN_DISABLE",
  VIEW_STATEMENT_DISABLE: "AU_VIEW_STATEMENT_DISABLE",
  PAY_BILL_DISABLE: "AU_PAY_BILL_DISABLE",
  MANAGE_LIMIT_DISABLE: "AU_MANAGE_LIMIT_DISABLE",
  REWARDS_SSO_DISABLE: "AU_REWARDS_SSO_DISABLE",
  EMI_CONVERSION_DISABLE: "AU_EMI_CONVERSION_DISABLE",
  VIRTUAL_CARD_DISABLE: "AU_VIRTUAL_CARD_DISABLE",
  CARD_LOCKED: "AU_CARD_LOCKED",
})
export const LogLevels = Object.freeze({
  INFO: "INFO",
  ERROR: "ERROR",
})

let Issuers

switch (process.env.REACT_APP_ENV) {
  case Envs.SANDBOX:
    Issuers = {
      AU_BANK: "AU Small Finance Bank",
      FEDERAL_BANK: "Federal Bank",
    }
    break
  case Envs.AU_SANDBOX:
    Issuers = {
      AU_BANK: "AU Small Finance Bank",
      FEDERAL_BANK: "Federal Bank",
    }
    break
  case Envs.AU_PROD:
    Issuers = {
      AU_BANK: "AU Small Finance Bank",
      FEDERAL_BANK: "Federal Bank",
    }
    break
  case Envs.PROD:
    Issuers = {
      AU_BANK: "AU Small Finance Bank",
      FEDERAL_BANK: "Federal Bank",
    }
  case Envs.FED_PROD:
    Issuers = {
      AU_BANK: "AU Small Finance Bank",
      FEDERAL_BANK: "Federal Bank",
    }
    break
  default:
    Issuers = {
      AU_BANK: "AU Bank",
      TRANSCORPP: "Transcorpp",
      TRANSCORP: "Transcorp ",
      AMERICAN_EXPRESS: "American Express",
      PUNJAB_BANK: "Punjab Bank",
      FEDERAL_BANK: "Federal Bank",
      BANK_OF_MAHARASHTRA: "Bank of Maharashtra",
      ROCKETERY_BANK: "Rocketery Bank",
      HYPERFACE_BANK: "Hyperface Bank",
    }
}
export { Issuers }

export const KycStatus = Object.freeze({
  SMALL_KYC: "smallKYC",
  FULL_KYC: "COMPLETED",
})

export const CardPrimaryPeriodType = Object.freeze({
  DAILY: "DAILY",
  MONTHLY: "MONTHLY",
  TRANSACTIONAL: "TRANSACTIONAL",
})

export const Federal_BlockCodes = Object.freeze({
  ENTIRE_PWA_ACCOUNT_DISABLE: "FED_ENTIRE_PWA_ACCOUNT_DISABLE",
  ENTIRE_PWA_ACCOUNT_CARD_DISABLE: "FED_ENTIRE_PWA_ACCOUNT_CARD_DISABLE",
  TEMP_CARD_DISABLE: "FED_TEMP_CARD_DISABLE",
  PERMANENT_CARD_DISABLE: "FED_PERMANENT_CARD_DISABLE",
  UNBLOCK_CARD_DISABLE: "FED_UNBLOCK_CARD_DISABLE",
  REPLACE_CARD_DISABLE: "FED_REPLACE_CARD_DISABLE",
  RESET_MPIN_DISABLE: "FED_RESET_MPIN_DISABLE",
  VIEW_STATEMENT_DISABLE: "FED_VIEW_STATEMENT_DISABLE",
  PAY_BILL_DISABLE: "FED_PAY_BILL_DISABLE",
  REWARDS_SSO_DISABLE: "FED_REWARDS_SSO_DISABLE",
  EMI_CONVERSION_DISABLE: "FED_EMI_CONVERSION_DISABLE",
  VIRTUAL_CARD_DISABLE: "FED_VIRTUAL_CARD_DISABLE",
  CARD_BLOCKED: "FED_CARD_BLOCKED",
  CC_PIN_DISABLE: "FED_CC_PIN_DISABLE",
  CARD_CONTROL_DISABLE: "FED_CARD_CONTROL_DISABLE",
})

export const BlockReplaceTncActions = Object.freeze({
  NONE: "NONE",
  ENABLE_DOMESTIC_ONLINE: "ENABLE_DOMESTIC_ONLINE",
  ACTIVATE_CARD: "ACTIVATE_CARD",
})

export const NudgeType = Object.freeze({
  MANDATORY: "MANDATORY",
  OPTIONAL: "OPTIONAL",
})

export const EMI_PLAN_VIEW = Object.freeze({
  LIST: "list",
  TABLE: "table",
})

export const PAY_BILL_BUTTON_TEXT = Object.freeze({
  PAY_BILL: "Pay Bill",
  PAY_OUTSTANDING: "Pay Outstanding",
  PAY_MORE: "Pay More",
  NO_BUTTON: "",
  PAY_NOW: "Pay Now",
})

export const EventName = Object.freeze({
  HOME: "HOME",
  SHOW_TRANSACTION: "SHOW_TRANSACTION",
  VIEW_TRANSACTION: "VIEW_TRANSACTION",
  AUTH_CUSTOMER: "AUTH_CUSTOMER",
  EMAIL_OTP: "EMAIL_OTP",
  MPIN: "MPIN",
  MPIN_BLOCKED: "MPIN_BLOCKED",
  SET_MPIN: "SET_MPIN",
  OTP: "OTP",
  QR_SCANNER: "QR_SCANNER",
  CARD_DETAILS: "CARD_DETAILS",
  CONVERT_TO_EMI: "CONVERT_TO_EMI",
  ABOUT_PROGRAM: "ABOUT_PROGRAM",
  ANALYSE_SPENDS: "ANALYSE_SPENDS",
  SPEND_CATEGORY: "SPEND_CATEGORY",
  BLOCK_CARD: "BLOCK_CARD",
  BLOCK_CARD_REASON: "BLOCK_CARD_REASON",
  CARD_BLOCKED: "CARD_BLOCKED",
  NEW_CARD_REQUEST: "NEW_CARD_REQUEST",
  NEW_CARD_REQUEST_SUCCESS: "NEW_CARD_REQUEST_SUCCESS",
  CARD_SETTINGS: "CARD_SETTINGS",
  CUSTOMER_CARE: "CUSTOMER_CARE",
  EMI: "EMI",
  FORECLOSE_EMI: "FORECLOSE_EMI",
  MANAGE_CARD: "MANAGE_CARD",
  BLOCKED: "BLOCKED",
  BLOCKED_MULTPLE_ATTEMPTS: "BLOCKED_MULTPLE_ATTEMPTS",
  ERROR_SCREEN: "ERROR_SCREEN",
  ERROR_SCREEN_V2_1: "ERROR_SCREEN_V2_1",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  RESET_PIN: "RESET_PIN",
  OFFER_DETAILS: "OFFER_DETAILS",
  ALL_OFFERS: "ALL_OFFERS",
  REWARDS: "REWARDS",
  STATEMENT: "STATEMENT",
  STATEMENT_V2_1: "STATEMENT",
  MPIN_UPDATED: "MPIN_UPDATED",
  VIEW_STATEMENT_SUMMARY: "VIEW_STATEMENT_SUMMARY",
  PAY_BILL: "PAY_BILL",
  VIEW_BILLED_TRANSACTIONS: "VIEW_BILLED_TRANSACTIONS",
  RESET_PIN_SUCCESS: "RESET_PIN_SUCCESS",
  CONVERT_TO_EMI_REQUESTED: "CONVERT_TO_EMI_REQUESTED",
  CARD_BLOCKED_REASON: "CARD_BLOCKED_REASON",
  CARD_REPLACED: "CARD_REPLACED",
  TEMP_LOCK: "TEMP_LOCK",
  TEMP_UNLOCK: "TEMP_UNLOCK",
  UPDATE_CARD_CONTROLS: "UPDATE_CARD_CONTROLS",
  PROCEED_TO_PAY: "PROCEED_TO_PAY",
  PAYMENT_SUCCESS: "PAYMENT_SUCCESS",
  PAYMENT_FAILURE: "PAYMENT_FAILURE",
  INCORRECT_INPUT: "INCORRECT_INPUT",
  REWARDS_REDEEM_CTA: "REWARDS_REDEEM_CTA",
  OFFER_GENERATED: "OFFER_GENERATED",

  WELCOME_PAGE: "WELCOME_PAGE",
  WELCOME_PAGE_CTA: "WELCOME_PAGE_CTA",
  MPIN_SUCCESS_CTA: "MPIN_SUCCESS_CTA",
  RESEND_OTP: "RESEND_OTP",
  VERIFY_OTP_CTA: "VERIFY_OTP_CTA",
  ACCESS_BLOCK_CTA: "ACCESS_BLOCK_CTA",
  VIRTUAL_CARD_CLICKED: "VIRTUAL_CARD_CLICKED",
  VIRTUAL_CARD_CLICK_ERROR: "VIRTUAL_CARD_CLICK_ERROR",
  VIRTUAL_CARD_CTA: "VIRTUAL_CARD_CTA",
  REWARDS_ERROR: "REWARDS_ERROR",
  CARD_CONTROLS_ERROR: "CARD_CONTROLS_ERROR",
  RESET_PIN_CTA: "RESET_PIN_CTA",
  RESET_PIN_ERROR: "RESET_PIN_ERROR",
  PAY_BILL_AMOUNT_SELECTION_PAGE: "PAY_BILL_AMOUNT_SELECTION_PAGE",
  PAY_BILL_MODE_SELECTION_PAGE: "PAY_BILL_MODE_SELECTION_PAGE",
  PAY_BILL_MODE_SELECTION_CTA: "PAY_BILL_MODE_SELECTION_CTA",
  PAY_BILL_UPI_ID_PAGE: "PAY_BILL_UPI_ID_PAGE",
  PAY_BILL_UPI_VERIFY_BTN: "PAY_BILL_UPI_VERIFY_BTN",
  PAY_BILL_UPI_ID_CTA: "PAY_BILL_UPI_ID_CTA",
  PAY_BILL_FAILURE_CTA: "PAY_BILL_FAILURE_CTA",
  PAY_BILL_SUCCESS_CTA: "PAY_BILL_SUCCESS_CTA",
  PAY_BILL_PAYMENT_REQ_PAGE: "PAY_BILL_PAYMENT_REQ_PAGE",
  BLOCK_REPLACE_CTA: "BLOCK_REPLACE_CTA",
  BLOCK_REPLACE_REASON_CTA: "BLOCK_REPLACE_REASON_CTA",
  BLOCK_CARD_SUCCESS_CTA: "BLOCK_CARD_SUCCESS_CTA",
  REPLACE_CARD_SUCCESS_CTA: "REPLACE_CARD_SUCCESS_CTA",

  EMI_DETAILS: "EMI_DETAILS",
  EMI_DETAILS_PRECLOSE_CTA: "EMI_DETAILS_PRECLOSE_CTA",
  EMI_PRECLOSE_SCREEN: "EMI_PRECLOSE_SCREEN",
  EMI_PRECLOSE_CTA: "EMI_PRECLOSE_CTA",
  FORCLOSE_EMI_CTA: "FORCLOSE_EMI_CTA",
  FORECLOSE_EMI_SUCCESS: "FORECLOSE_EMI_SUCCESS",
  FORECLOSE_EMI_FAILURE: "FORECLOSE_EMI_FAILURE",
  OFFER_DETAILS_COPY_CTA: "",
  OFFER_SCREEN_ERROR: "OFFER_SCREEN_ERROR",
  REWARDS_HISTORY: "REWARDS_HISTORY",
  REWARDS_HISTORY_CTA: "REWARDS_HISTORY_CTA",
  STATEMENT_SUMMARY_SCREEN_CTA: "STATEMENT_SUMMARY_SCREEN_CTA",
  BACK_BUTTON_CTA: "BACK_BUTTON_CTA",
  CONVERT_TO_EMI_SUCCESS: "CONVERT_TO_EMI_SUCCESS",
  CONVERT_TO_EMI_ERROR: "CONVERT_TO_EMI_ERROR",
  NUDGE_CLICK: "NUDGE_CLICK",
  TRANSACTION_ID_COPY_CTA: "TRANSACTION_ID_COPY_CTA",
})

export const eventConfig = {
  HOME: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Card Homepage",
  },
  SHOW_TRANSACTION: {
    allowed: false,
    requiredParams: [],
  },
  VIEW_TRANSACTION: {
    allowed: false,
    requiredParams: [],
  },
  AUTH_CUSTOMER: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Verify PAN DOB",
    flowRequired: true,
  },
  EMAIL_OTP: {
    allowed: false,
    requiredParams: [],
  },
  MPIN: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Enter Mpin",
    flowRequired: true,
  },
  MPIN_BLOCKED: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC MPIN Access Blocked",
  },
  SET_MPIN: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Set Mpin",
  },
  OTP: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Verify OTP",
    flowRequired: true,
  },
  QR_SCANNER: {
    allowed: false,
    requiredParams: [],
  },
  CARD_DETAILS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC View Virtual Card",
  },
  CONVERT_TO_EMI: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Convert to EMI initiated",
  },
  ABOUT_PROGRAM: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC About Card Program",
  },
  ANALYSE_SPENDS: {
    allowed: false,
    requiredParams: [],
  },
  SPEND_CATEGORY: {
    allowed: false,
    requiredParams: [],
  },
  BLOCK_CARD: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Block Replace Card",
  },
  BLOCK_CARD_REASON: {
    allowed: false,
    requiredParams: [],
  },
  CARD_BLOCKED: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Card Blocked",
  },
  NEW_CARD_REQUEST: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Replace Card",
  },
  NEW_CARD_REQUEST_SUCCESS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Replace Card",
  },
  CARD_SETTINGS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Card Controls",
  },
  CUSTOMER_CARE: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Customer Care",
  },
  EMI: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Convert to EMI initiated",
  },
  FORECLOSE_EMI: {
    allowed: false,
    requiredParams: [],
  },
  MANAGE_CARD: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Settings",
  },
  BLOCKED: {
    allowed: false,
    requiredParams: [],
  },
  BLOCKED_MULTPLE_ATTEMPTS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC User Account Blocked",
  },
  ERROR_SCREEN: {
    allowed: false,
    requiredParams: [],
  },
  ERROR_SCREEN_V2_1: {
    allowed: false,
    requiredParams: [],
  },
  SESSION_EXPIRED: {
    allowed: false,
    requiredParams: [],
  },
  RESET_PIN: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Reset PIN Initiated",
  },
  OFFER_DETAILS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Offer Detail",
  },
  ALL_OFFERS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Offers Section",
  },
  REWARDS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC View Reward Points",
  },
  STATEMENT: {
    allowed: false,
    requiredParams: [],
    eventName: "Bill Summary",
  },
  STATEMENT_V2_1: {
    allowed: false,
    requiredParams: [],
  },
  MPIN_UPDATED: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC MPIN updated",
  },
  VIEW_STATEMENT_SUMMARY: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC View Bill Summary",
  },
  PAY_BILL: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Initiate Pay Bill",
  },
  VIEW_BILLED_TRANSACTIONS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC View Billed Transactions",
  },
  RESET_PIN_SUCCESS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Reset PIN Success",
  },
  CONVERT_TO_EMI_REQUESTED: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Convert to EMI requested",
  },
  CARD_BLOCKED_REASON: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC card block reason",
  },
  CARD_REPLACED: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Card Replaced",
  },
  TEMP_LOCK: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Temp Block",
  },
  TEMP_UNLOCK: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Temp UnBlock",
  },
  UPDATE_CARD_CONTROLS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Update Card Limits",
  },
  PROCEED_TO_PAY: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Proceed to Pay Bill",
  },
  PAYMENT_SUCCESS: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Pay Bill Success",
  },
  PAYMENT_FAILURE: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Pay Bill Failure",
  },
  INCORRECT_INPUT: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Incorrect Input",
    flowRequired: true,
  },
  REWARDS_REDEEM_CTA: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Redeem Reward Points",
  },
  OFFER_GENERATED: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Offer Generated",
  },
  WELCOME_PAGE: {
    allowed: false,
    requiredParams: [],
  },
  WELCOME_PAGE_CTA: {
    allowed: false,
    requiredParams: [],
  },
  MPIN_SUCCESS_CTA: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC MPIN Updated",
  },
  RESEND_OTP: {
    allowed: false,
    requiredParams: [],
  },
  VERIFY_OTP_CTA: {
    allowed: false,
    requiredParams: [],
  },
  ACCESS_BLOCK_CTA: {
    allowed: false,
    requiredParams: [],
  },
  VIRTUAL_CARD_CLICKED: {
    allowed: false,
    requiredParams: [],
  },
  VIRTUAL_CARD_CLICK_ERROR: {
    allowed: false,
    requiredParams: [],
  },
  VIRTUAL_CARD_CTA: {
    allowed: false,
    requiredParams: [],
  },
  REWARDS_ERROR: {
    allowed: false,
    requiredParams: [],
  },
  CARD_CONTROLS_ERROR: {
    allowed: false,
    requiredParams: [],
  },
  RESET_PIN_CTA: {
    allowed: false,
    requiredParams: [],
  },
  RESET_PIN_ERROR: {
    allowed: false,
    requiredParams: [],
  },
  PAY_BILL_AMOUNT_SELECTION_PAGE: {
    allowed: false,
    requiredParams: [],
    eventName: "CBCC Initiate Pay Bill",
  },
  PAY_BILL_MODE_SELECTION_PAGE: {
    allowed: false,
    requiredParams: [],
  },
  PAY_BILL_MODE_SELECTION_CTA: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Payment Selection",
  },
  PAY_BILL_UPI_ID_PAGE: {
    allowed: false,
    requiredParams: [],
  },
  PAY_BILL_UPI_VERIFY_BTN: {
    allowed: false,
    requiredParams: [],
  },
  PAY_BILL_UPI_ID_CTA: {
    allowed: false,
    requiredParams: [],
    eventName: "CBCC Proceed to Pay Bill",
  },
  PAY_BILL_FAILURE_CTA: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Pay Bill Failure",
  },
  PAY_BILL_SUCCESS_CTA: {
    allowed: true,
    requiredParams: [],
    eventName: "CBCC Pay Bill Success",
  },
  PAY_BILL_PAYMENT_REQ_PAGE: {
    allowed: false,
    requiredParams: [],
  },
  BLOCK_REPLACE_CTA: {
    allowed: false,
    requiredParams: [],
  },
  BLOCK_REPLACE_REASON_CTA: {
    allowed: false,
    requiredParams: [],
  },
  BLOCK_CARD_SUCCESS_CTA: {
    allowed: false,
    requiredParams: [],
  },
  REPLACE_CARD_SUCCESS_CTA: {
    allowed: false,
    requiredParams: [],
  },

  EMI_DETAILS: {
    allowed: false,
    requiredParams: [],
  },
  EMI_DETAILS_PRECLOSE_CTA: {
    allowed: false,
    requiredParams: [],
  },
  EMI_PRECLOSE_SCREEN: {
    allowed: false,
    requiredParams: [],
  },
  EMI_PRECLOSE_CTA: {
    allowed: false,
    requiredParams: [],
  },
  FORCLOSE_EMI_CTA: {
    allowed: false,
    requiredParams: [],
  },
  FORECLOSE_EMI_SUCCESS: {
    allowed: false,
    requiredParams: [],
  },
  FORECLOSE_EMI_FAILURE: {
    allowed: false,
    requiredParams: [],
  },
  OFFER_DETAILS_COPY_CT: {
    allowed: false,
    requiredParams: [],
  },
  OFFER_SCREEN_ERROR: {
    allowed: false,
    requiredParams: [],
  },
  REWARDS_HISTORY: {
    allowed: false,
    requiredParams: [],
  },
  REWARDS_HISTORY_CTA: {
    allowed: false,
    requiredParams: [],
  },
  STATEMENT_SUMMARY_SCREEN_CTA: {
    allowed: false,
    requiredParams: [],
  },
  BACK_BUTTON_CTA: {
    allowed: false,
    requiredParams: [],
  },
  CONVERT_TO_EMI_SUCCESS: {
    allowed: false,
    requiredParams: [],
  },
  CONVERT_TO_EMI_ERROR: {
    allowed: false,
    requiredParams: [],
  },
  NUDGE_CLICK: {
    allowed: false,
    requiredParams: [],
  },
}

export const AuthenticationType = Object.freeze({
  OTP: "OTP",
  MPIN: "MPIN",
  CUSTOMER_VERIFY: "CUSTOMER_VERIFY",
  MPIN_EXPIRED: "MPIN_EXPIRED",
})

export const BottomSheetType = Object.freeze({
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  INFO: "INFO",
  NULL: "NULL",
  SET_MPIN: "SET_MPIN",
  RESET_PIN: "RESET_PIN",
  FREEZE_INSTEAD_SCREEN: "FREEZE_INSTEAD_SCREEN",
  PROCEED_TO_BLOCK: "PROCEED_TO_BLOCK",
})

export const downloadStatementStates = Object.freeze({
  INIT: "INIT",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
})

export const transactionTypes = Object.freeze({
  CASHBACK: "CASHBACK",
  REFUND: "REFUND",
  PURCHASE: "PURCHASE",
  REPAYMENT: "REPAYMENT",
})

export const transactionNature = Object.freeze({
  CREDIT: "CREDIT",
  DEBIT: "DEBIT",
})

export const HomeTabs = Object.freeze({
  CARD: "CARD",
  PERKS: "PERKS",
})

export const RepaymentTypes = Object.freeze({
  S2S_PG: "S2S_PG",
  PG: "PG",
})

export const BrandingOnHeaders = Object.freeze({
  SINGLE_LOGO: "SINGLE_LOGO",
  TWO_LOGO: "TWO_LOGO",
  TEXT: "TEXT",
})

export const HeaderContentAlignment = Object.freeze({
  LEFT: "LEFT",
  CENTER: "CENTER",
})

export const AmountTypes = Object.freeze({
  TAD: "TAD",
  MAD: "MAD",
  CUSTOM_AMOUNT: "CUSTOM_AMOUNT",
})

export const TransactionDateTypes = Object.freeze({
  POSTING_DATE: "POSTING_DATE",
  TRANSACTION_DATE: "TRANSACTION_DATE",
})

export const milestoneStyle = Object.freeze({
  DESCRETE: "descrete",
})

export const rewardRedirectionType = Object.freeze({
  LR_TRANSACTIONS: "LR_TRANSACTIONS",
  HF_CASHBACK: "HF_CASHBACK",
  HF_REWARDS: "HF_REWARDS",
  SSO: "SSO",
  URL: "URL",
})

export const CustomerCareContactTypes = Object.freeze({
  CALL: "CALL",
  EMAIL: "EMAIL",
})

export const FaqTypes = Object.freeze({
  CARD: "card",
  LIST: "list",
})

export const commonStoreKeys = Object.freeze([
  "SelectedHomePageTab",
  "firstTimeInactive",
  "isLoggedIn",
])

export const ToastType = Object.freeze({
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  INFO: "INFO",
  COPY: "COPY",
  WARNING: "WARNING",
})

export const cardDisplayMode = Object.freeze({
  FULL_CARD_VIEW: "FULL_CARD_VIEW",
  HALF_CARD_VIEW: "HALF_CARD_VIEW",
})

export const scrollAnimationStates = Object.freeze({
  SHOW: "SHOW",
  HIDE: "HIDE",
})

export const SelectedHomePageTab = "SelectedHomePageTab"

export const DEFAULT_TENANT = "DEFAULT"

export const nudgeVariants = Object.freeze({
  MANDATORY: "MANDATORY",
  OPTIONAL: "OPTIONAL",
  MODAL: "MODAL",
  DRAWER: "DRAWER",
  PILL: "PILL",
})
export const MERCHANT_STATUS = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  PROCESSING: "PROCESSING",
  FAILED: "FAILED",
  NULL: "NULL",
  INACTIVE: "INACTIVE",
  DEACTIVATED: "DEACTIVATED",
}
export const MERCHANT_TYPE_LABELS = {
  ACTIVE: "Active",
  SUSPENDED: "Paused",
  REQUESTED: "Requested",
}

export const TOKENISATION_LIFECYCLE_TYPE = {
  RESUME: "RESUME",
  SUSPEND: "SUSPEND",
  DELETE: "DELETE",
}

export const TokenisationOnusOffusIndicator = {
  ONUS: "ONUS",
  OFFUS: "OFFUS",
}

export const customDateRangeFilterFields = Object.freeze({
  FROM: "FROM",
  TO: "TO",
  BOTH: "BOTH",
})

export const cardSettingsOptions = Object.freeze({
  RESET_PIN: "resetPin",
  RESET_MPIN: "resetMpin",
  BLOCK_REPLACE_CARD: "blockReplace",
})

export const AccountStatus = Object.freeze({
  PENDING_CLOSURE: "PENDING_CLOSURE",
  CLOSED: "CLOSED",
  CHARGE_OFF: "CHARGE_OFF",
  SUSPENDED: "SUSPENDED",
  FORCED_SUSPENDED: "FORCED_SUSPENDED",
  ACTIVE: "ACTIVE",
  DORMANT: "DORMANT",
})
