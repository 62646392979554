const HourGlass = ({ color = "#ED5B27" }) => {
  return (
    <svg
      width='12'
      height='13'
      viewBox='0 0 12 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.77968 2.375H9.2203C9.29466 2.37512 9.3673 2.39735 9.429 2.43886C9.4907 2.48037 9.53866 2.53928 9.5668 2.60811C9.59493 2.67694 9.60197 2.75258 9.58701 2.82542C9.57206 2.89826 9.53578 2.96501 9.4828 3.01719L5.99999 6.5L2.51718 3.01719C2.4642 2.96501 2.42792 2.89826 2.41297 2.82542C2.39801 2.75258 2.40505 2.67694 2.43318 2.60811C2.46132 2.53928 2.50928 2.48037 2.57098 2.43886C2.63267 2.39735 2.70532 2.37512 2.77968 2.375V2.375Z'
        stroke={color}
        stroke-width='0.75'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.77968 10.625H9.2203C9.29466 10.6249 9.3673 10.6026 9.429 10.5611C9.4907 10.5196 9.53866 10.4607 9.5668 10.3919C9.59493 10.3231 9.60197 10.2474 9.58701 10.1746C9.57206 10.1017 9.53578 10.035 9.4828 9.98281L5.99999 6.5L2.51718 9.98281C2.4642 10.035 2.42792 10.1017 2.41297 10.1746C2.39801 10.2474 2.40505 10.3231 2.43318 10.3919C2.46132 10.4607 2.50928 10.5196 2.57098 10.5611C2.63267 10.6026 2.70532 10.6249 2.77968 10.625Z'
        stroke={color}
        stroke-width='0.75'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M8.25 4.25H3.75'
        stroke={color}
        stroke-width='0.75'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6 6.5V8.375'
        stroke={color}
        stroke-width='0.75'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default HourGlass
