import React from "react"

const CardReplaceFee = ({ color1, color2, classname }) => {
  return (
    <svg
      className={classname}
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='25' height='25' rx='12.5' fill='white' />
      <rect x='0.5' y='0.5' width='25' height='25' rx='12.5' stroke={color1} />
      <path
        d='M16.179 10.7244L15.8409 11.8778H9.39773L9.7358 10.7244H16.179ZM13.1562 18.5L9.51705 14.0455L9.49716 13.2102H11.3068C11.7775 13.2102 12.1785 13.1439 12.5099 13.0114C12.8414 12.8755 13.0949 12.6733 13.2706 12.4048C13.4463 12.133 13.5341 11.7917 13.5341 11.3807C13.5341 10.7741 13.3518 10.2985 12.9872 9.95383C12.6226 9.60582 12.0625 9.43182 11.3068 9.43182H9.39773L9.7358 8.31818H11.3068C12.1155 8.31818 12.7784 8.45241 13.2955 8.72088C13.8158 8.98603 14.2003 9.35062 14.4489 9.81463C14.7008 10.2753 14.8267 10.7973 14.8267 11.3807C14.8267 11.9077 14.7107 12.3932 14.4787 12.8374C14.25 13.2782 13.8821 13.6328 13.375 13.9013C12.8712 14.1697 12.205 14.304 11.3764 14.304H11.3466L14.7074 18.4205V18.5H13.1562ZM16.179 8.31818L15.8409 9.47159L10.7699 9.43182L11.108 8.31818H16.179Z'
        fill={color1}
      />
    </svg>
  )
}

export default CardReplaceFee
