import React from "react"

const CS_creditCard = () => {
  return (
    <svg
      width='100%'
      height='51'
      viewBox='0 0 52 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.6 41.7562C3.6671 41.7562 2.09375 40.1834 2.09375 38.2499V12.7499C2.09375 10.8164 3.6671 9.24365 5.6 9.24365H46.4C48.3329 9.24365 49.9062 10.8164 49.9062 12.7499V38.2499C49.9062 40.1834 48.3329 41.7562 46.4 41.7562H5.6Z'
        fill='#F78F8F'
      />
      <path
        d='M46.3999 9.56255C48.1575 9.56255 49.5874 10.9925 49.5874 12.75V38.2501C49.5874 40.0076 48.1575 41.4375 46.3999 41.4375H5.5999C3.84231 41.4375 2.4124 40.0076 2.4124 38.2501V12.75C2.4124 10.9925 3.84231 9.56255 5.5999 9.56255H46.3999ZM46.3999 8.92505H5.5999C3.48723 8.92505 1.7749 10.6374 1.7749 12.75V38.2501C1.7749 40.3627 3.48723 42.0751 5.5999 42.0751H46.3999C48.5126 42.0751 50.2249 40.3627 50.2249 38.2501V12.75C50.2249 10.6374 48.5126 8.92505 46.3999 8.92505Z'
        fill='#DB5D5D'
      />
      <path d='M1.7749 15.3H50.2249V20.4H1.7749V15.3Z' fill='#0E0E0E' />
      <path d='M6.875 22.95H22.175V25.5H6.875V22.95Z' fill='white' />
    </svg>
  )
}

export default CS_creditCard
