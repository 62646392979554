import React from "react"
import MagnifyingGlass from "../../../svg/v3/magnifyingGlass"
import { useSelector } from "react-redux"
import { hexToRGBA } from "../../../../utils/functions"

const SearchInput = ({
  placeholder = "Search",
  onInputChange,
  focused,
  setFocused,
  value,
  opacity = "1",
}) => {
  const theme = useSelector(state => state.theme)
  return (
    <div
      className='v3-allMerchantOffers-searchInput'
      style={{
        borderColor: focused
          ? theme.v3.cssVars.primary.color2
          : theme.v3.cssVars.tertiaryNeutral.color3,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <MagnifyingGlass
          color={
            focused
              ? theme.v3.cssVars.primary.color1
              : theme.v3.cssVars.tertiaryNeutral.color1
          }
        />
      </div>
      <input
        value={value}
        placeholder={placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onChange={e => {
          onInputChange(e.target.value)
        }}
        style={{
          caretColor: theme.v3.cssVars.tertiaryNeutral.color2,
          color: theme.v3.cssVars.tertiaryNeutral.color1,
          opacity: opacity,
        }}
      />
    </div>
  )
}

export default SearchInput
