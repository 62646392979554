const FilledInfoCircle = ({ color = "#2680EA", size = "15px" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 0C2.2385 0 0 2.2385 0 5C0 7.7615 2.2385 10 5 10C7.7615 10 10 7.7615 10 5C10 2.2385 7.7615 0 5 0ZM5.375 7.25C5.375 7.457 5.207 7.625 5 7.625C4.793 7.625 4.625 7.457 4.625 7.25V4.96448C4.625 4.75748 4.793 4.58948 5 4.58948C5.207 4.58948 5.375 4.75748 5.375 4.96448V7.25ZM5.01001 3.75C4.73401 3.75 4.50745 3.526 4.50745 3.25C4.50745 2.974 4.729 2.75 5.005 2.75H5.01001C5.28651 2.75 5.51001 2.974 5.51001 3.25C5.51001 3.526 5.28601 3.75 5.01001 3.75Z'
        fill={color}
      />
    </svg>
  )
}

export default FilledInfoCircle
