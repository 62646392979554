import {
    CLEAR_SET_MPIN_STATE,
    SET_MPIN_METADATA,
    SET_SET_MPIN_API_TOKEN,
    SET_SET_MPIN_STATE,
} from "../ActionTypes"

export const setSetMpinState = data => dispatch => {
  dispatch({
    type: SET_SET_MPIN_STATE,
    payload: data,
  })
}

export const clearSetMpinState = () => dispatch => {
  dispatch({
    type: CLEAR_SET_MPIN_STATE,
  })
}

export const setSetMpinApiToken = data => dispatch => {
  dispatch({
    type: SET_SET_MPIN_API_TOKEN,
    payload: data,
  })
}

export const setMpinMetaData = data => dispatch => {
  dispatch({
    type: SET_MPIN_METADATA,
    payload: data,
  })
}
