export const AU_BlockCodesMapping = Object.freeze({
  ENTIRE_PWA_ACCOUNT_DISABLE: ["C"],
  ENTIRE_PWA_ACCOUNT_CARD_DISABLE: [
    "D",
    "F",
    "N",
    "R",
    "X",
    "Z",
    "P",
    "S",
    "U",
  ],

  REGISTRATION_DISABLE: [
    "C",
    "D",
    "F",
    "N",
    "R",
    "X",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  LOGIN_DISABLE: [
    "C",
    "D",
    "F",
    "N",
    "R",
    "X",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  CARD_DASHBOARD_DISABLE: [
    "C",
    "D",
    "F",
    "N",
    "R",
    "X",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  TEMP_CARD_DISABLE: [
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "J",
    "L",
    "N",
    "Q",
    "R",
    "Y",
    "L",
    "Q",
    "T",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  PERMANENT_CARD_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "J",
    "L",
    "N",
    "Q",
    "R",
    "Y",
    "L",
    "Q",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  UNBLOCK_CARD_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "G",
    "J",
    "L",
    "N",
    "Q",
    "R",
    "T",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  REPLACE_CARD_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "J",
    "N",
    "R",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  RESET_PIN_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "G",
    "J",
    "L",
    "N",
    "Q",
    "R",
    "T",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  VIEW_STATEMENT_DISABLE: [
    "C",
    "D",
    "F",
    "N",
    "R",
    "X",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  PAY_BILL_DISABLE: [
    "C",
    "D",
    "F",
    "N",
    "R",
    "X",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  MANAGE_LIMIT_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "J",
    "N",
    "R",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  REWARDS_SSO_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "J",
    "N",
    "R",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ], // skip for now
  EMI_CONVERSION_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "G",
    "H",
    "I",
    "J",
    "N",
    "R",
    "X",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  VIRTUAL_CARD_DISABLE: [
    "A",
    "B",
    "C",
    "D",
    "F",
    "G",
    "J",
    "L",
    "N",
    "Q",
    "R",
    "T",
    "X",
    "Y",
    "Z",
    "K",
    "M",
    "O",
    "P",
    "S",
    "U",
    "W",
  ],
  CARD_LOCKED: ["A"],
})

export const Federal_BlockCodesMapping = Object.freeze({
  ENTIRE_PWA_ACCOUNT_DISABLE: ["B", "C", "D", "U"],
  ENTIRE_PWA_ACCOUNT_CARD_DISABLE: ["M", "N", "Z"],
  RESET_MPIN_DISABLE: ["B", "C", "D", "M", "N", "U", "Z"],
  CARD_BLOCKED: ["F", "G", "L", "S"],
  EMI_CONVERSION_DISABLE: ["B", "C", "D", "M", "N", "U", "Z"],
  REPLACE_CARD_DISABLE: ["B", "C", "D", "G", "M", "N", "U", "Z"],
  VIEW_STATEMENT_DISABLE: ["B", "C", "D", "M", "N", "U", "Z"],
  PAY_BILL_DISABLE: ["B", "C", "D", "M", "N", "U", "Z"],
  TEMP_CARD_DISABLE: ["B", "C", "D", "F", "G", "L", "M", "N", "S", "U", "Z"],
  PERMANENT_CARD_DISABLE: [
    "B",
    "C",
    "D",
    "F",
    "G",
    "L",
    "M",
    "N",
    "S",
    "U",
    "Z",
  ],
  UNBLOCK_CARD_DISABLE: ["B", "C", "D", "F", "G", "L", "M", "N", "S", "U", "Z"],
  CC_PIN_DISABLE: ["B", "C", "D", "F", "G", "L", "M", "N", "S", "U", "Z"],
  CARD_CONTROL_DISABLE: ["B", "C", "D", "F", "G", "L", "M", "N", "S", "U", "Z"],
  VIRTUAL_CARD_DISABLE: ["B", "C", "D", "F", "G", "L", "M", "N", "S", "U", "Z"],
  REWARDS_SSO_DISABLE: ["B", "C", "D", "M", "N", "U", "Z"], // not to be done now
})
