import React from "react"

const FAQ_Img2 = ({ color = "#1965CF" }) => {
  return (
    <svg
      width='41'
      height='41'
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='41' height='41' rx='20.5' fill='#F5FAFF' />
      <path
        d='M11.5011 20.3C11.4741 20.3 11.4462 20.299 11.4192 20.296C11.0072 20.251 10.7092 19.88 10.7552 19.469C11.1172 16.151 13.1192 13.295 16.1102 11.827C16.3432 11.713 16.6171 11.727 16.8371 11.864C17.0571 12.001 17.1911 12.242 17.1911 12.501V15.551C17.1911 15.965 16.8551 16.301 16.4411 16.301C16.0271 16.301 15.6911 15.965 15.6911 15.551V13.8101C13.7671 15.1481 12.5061 17.2501 12.2461 19.6331C12.2031 20.0151 11.8791 20.3 11.5011 20.3ZM24.8902 29.173C27.8812 27.706 29.8832 24.849 30.2452 21.531C30.2912 21.119 29.9932 20.749 29.5812 20.704C29.1732 20.66 28.8002 20.956 28.7552 21.368C28.4942 23.751 27.2342 25.852 25.3102 27.191V25.45C25.3102 25.036 24.9742 24.7 24.5602 24.7C24.1462 24.7 23.8102 25.036 23.8102 25.45V28.5C23.8102 28.759 23.9442 29 24.1642 29.137C24.2852 29.212 24.4232 29.25 24.5602 29.25C24.6722 29.25 24.7852 29.225 24.8902 29.173ZM20.5002 15.5C20.5002 17.709 22.2912 19.5 24.5002 19.5C26.7092 19.5 28.5002 17.709 28.5002 15.5C28.5002 13.291 26.7092 11.5 24.5002 11.5C22.2912 11.5 20.5002 13.291 20.5002 15.5ZM20.0002 22.5H14.0002C13.0002 22.5 12.5002 23 12.5002 24V24.25H21.5002V24C21.5002 23 21.0002 22.5 20.0002 22.5ZM12.5002 25.75V28C12.5002 29 13.0002 29.5 14.0002 29.5H20.0002C21.0002 29.5 21.5002 29 21.5002 28V25.75H12.5002ZM16.0002 28H14.5002C14.2202 28 14.0002 27.78 14.0002 27.5C14.0002 27.22 14.2202 27 14.5002 27H16.0002C16.2802 27 16.5002 27.22 16.5002 27.5C16.5002 27.78 16.2802 28 16.0002 28Z'
        fill={color}
      />
    </svg>
  )
}

export default FAQ_Img2
