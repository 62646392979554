import { Label } from "./Label"

const Radio = ({
  className = "",
  style,
  label,
  labelStyle,
  labelClassName,
  value,
  isChecked,
  onChange,
  ...props
}) => {
  return (
    <div className='hf-radio-container'>
      <input
        type='radio'
        className={`hf-radio ${className}`}
        style={style}
        checked={isChecked}
        value={value}
      />
      {label &&
        (typeof label === "string" ? (
          <Label  style={labelStyle} className={`hf-radio-label ${labelClassName}`} >{label}</Label>
        ) : (
          <div style={labelStyle}  className={`hf-radio-label ${labelClassName}`}>{label}</div>
        ))}
    </div>
  )
}

export { Radio }
