import moment from "moment"
import { useSelector } from "react-redux"

import { PAY_BILL_BUTTON_TEXT, PwaVersions } from "../utils/enums"

const usePayButtonText = () => {
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)

  const { summary } = user || {}
  const { account } = summary || {}
  const { approvedCreditLimit = 0, availableCreditLimit = 0 } = account || {}

  const totalOutstanding =
    Number(approvedCreditLimit) - Number(availableCreditLimit)

  const { lastStatement = {} } = summary

  const { balanceAmountDue } = lastStatement

  const { transaction = {} } = screen?.home || {}
  let { enableOutstandingPayment = false, enablePayMore = false } = transaction

  // always true for PWA V3
  if (config?.version === PwaVersions.V3) {
    enableOutstandingPayment = true
  }

  const { jsonConfig = {} } = config || {}
  const { hideOverDueState = false } = jsonConfig

  const islastStatementPresent = Object.keys(lastStatement).length > 0

  const isV3 = config.version === PwaVersions.V3

  if (enablePayMore) {
    if (!islastStatementPresent) {
      if (totalOutstanding <= 0) {
        // Condition 1
        return PAY_BILL_BUTTON_TEXT.PAY_MORE
      }
    } else {
      if (moment() <= moment(lastStatement.dueDate)) {
        if (balanceAmountDue <= 0 && totalOutstanding <= 0) {
          // Condition 4
          return PAY_BILL_BUTTON_TEXT.PAY_MORE
        }
      } else {
        if (balanceAmountDue <= 0 && totalOutstanding <= 0) {
          // Condition 7
          return PAY_BILL_BUTTON_TEXT.PAY_MORE
        }
      }
    }
  }

  if (enableOutstandingPayment) {
    if (!islastStatementPresent) {
      if (totalOutstanding > 0) {
        // Condition 2
        if (isV3) return PAY_BILL_BUTTON_TEXT.PAY_NOW
        return PAY_BILL_BUTTON_TEXT.PAY_OUTSTANDING
      }
    } else {
      if (moment() <= moment(lastStatement.dueDate)) {
        if (balanceAmountDue <= 0 && totalOutstanding > 0) {
          // Condition 5
          if (isV3) return PAY_BILL_BUTTON_TEXT.PAY_NOW
          return PAY_BILL_BUTTON_TEXT.PAY_OUTSTANDING
        }
      } else {
        if (balanceAmountDue <= 0 && totalOutstanding > 0) {
          // Condition 8
          if (isV3) return PAY_BILL_BUTTON_TEXT.PAY_NOW
          return PAY_BILL_BUTTON_TEXT.PAY_OUTSTANDING
        }
      }
    }
  }

  if (islastStatementPresent) {
    if (moment() <= moment(lastStatement.dueDate)) {
      if (balanceAmountDue > 0) {
        // Condition 3
        return PAY_BILL_BUTTON_TEXT.PAY_BILL
      }
    } else {
      if (balanceAmountDue > 0) {
        // Condition 6
        if (hideOverDueState) {
          if (enableOutstandingPayment) {
            if (isV3) return PAY_BILL_BUTTON_TEXT.PAY_MORE
            return PAY_BILL_BUTTON_TEXT.PAY_OUTSTANDING
          } else {
            return PAY_BILL_BUTTON_TEXT.NO_BUTTON
          }
        } else {
          return PAY_BILL_BUTTON_TEXT.PAY_BILL
        }
      }
    }
  }
  return PAY_BILL_BUTTON_TEXT.NO_BUTTON
}

export default usePayButtonText
