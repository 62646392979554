import React from "react"
import { useNavigate } from "react-router-dom"
import { EventName } from "../../../../utils/enums"

const QuickActionsItem = ({ actionConfig }) => {
  const navigate = useNavigate()

  return (
    <div className='v3-activity-section-actions-body-item'>
      <div
        className='v3-activity-section-actions-body-item-icon'
        onClick={() =>
          navigate(actionConfig?.redirectTo, {
            state: {
              previousScreen:
                actionConfig?.redirectTo === "/transactions" && EventName.HOME,
            },
          })
        }
      >
        <div className='v3-quick-action-svg'>{actionConfig?.icon}</div>
      </div>
      <div className='v3-activity-section-actions-body-item-text'>
        <div>{actionConfig?.text1}</div>
        <div>{actionConfig?.text2}</div>
      </div>
    </div>
  )
}

export default QuickActionsItem
