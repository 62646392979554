import React from "react"

const FreezeCardIcon = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      style={{ width: "-webkit-fill-available" }}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.5 1.25C12.9142 1.25 13.25 1.58579 13.25 2V4.18934L14.9697 2.46967C15.2626 2.17678 15.7374 2.17678 16.0303 2.46967C16.3232 2.76256 16.3232 3.23744 16.0303 3.53033L13.25 6.31066V17.6893L16.0303 20.4697C16.3232 20.7626 16.3232 21.2374 16.0303 21.5303C15.7374 21.8232 15.2626 21.8232 14.9697 21.5303L13.25 19.8107V22C13.25 22.4142 12.9142 22.75 12.5 22.75C12.0858 22.75 11.75 22.4142 11.75 22V19.8107L10.0303 21.5303C9.73744 21.8232 9.26256 21.8232 8.96967 21.5303C8.67678 21.2374 8.67678 20.7626 8.96967 20.4697L11.75 17.6893V6.31066L8.96967 3.53033C8.67678 3.23744 8.67678 2.76256 8.96967 2.46967C9.26256 2.17678 9.73744 2.17678 10.0303 2.46967L11.75 4.18934V2C11.75 1.58579 12.0858 1.25 12.5 1.25Z'
        fill={color || "#2680EA"}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.01135 4.17809C6.41145 4.07088 6.8227 4.30832 6.9299 4.70842L7.94758 8.50642L12.4996 11.1345L17.0516 8.50642L18.0693 4.70842C18.1765 4.30832 18.5877 4.07088 18.9878 4.17809C19.3879 4.28529 19.6254 4.69654 19.5182 5.09664L18.8887 7.44576L20.7847 6.35109C21.1435 6.14398 21.6022 6.26689 21.8093 6.62561C22.0164 6.98433 21.8935 7.44302 21.5347 7.65013L19.6387 8.7448L21.9878 9.37424C22.3879 9.48144 22.6254 9.8927 22.5182 10.2928C22.411 10.6929 21.9997 10.9303 21.5996 10.8231L17.8016 9.80546L13.9996 12.0005L17.8018 14.1958L21.5998 13.1781C21.9999 13.0709 22.4112 13.3083 22.5184 13.7084C22.6256 14.1085 22.3881 14.5198 21.988 14.627L19.6389 15.2564L21.5349 16.3511C21.8937 16.5582 22.0166 17.0169 21.8095 17.3756C21.6024 17.7343 21.1437 17.8572 20.7849 17.6501L18.8889 16.5555L19.5184 18.9046C19.6256 19.3047 19.3881 19.7159 18.988 19.8231C18.5879 19.9303 18.1767 19.6929 18.0695 19.2928L17.0518 15.4948L12.4996 12.8666L7.94738 15.4948L6.92971 19.2928C6.8225 19.6929 6.41125 19.9303 6.01115 19.8231C5.61105 19.7159 5.37361 19.3047 5.48082 18.9046L6.11026 16.5555L4.21424 17.6501C3.85552 17.8572 3.39683 17.7343 3.18972 17.3756C2.98261 17.0169 3.10552 16.5582 3.46424 16.3511L5.36026 15.2564L3.01115 14.627C2.61105 14.5198 2.37361 14.1085 2.48082 13.7084C2.58803 13.3083 2.99928 13.0709 3.39938 13.1781L7.19738 14.1958L10.9996 12.0005L7.19758 9.80546L3.39957 10.8231C2.99948 10.9303 2.58822 10.6929 2.48102 10.2928C2.37381 9.8927 2.61125 9.48144 3.01135 9.37424L5.36046 8.7448L3.46443 7.65013C3.10572 7.44302 2.98281 6.98433 3.18992 6.62561C3.39702 6.26689 3.85572 6.14398 4.21443 6.35109L6.11046 7.44576L5.48102 5.09664C5.37381 4.69654 5.61125 4.28529 6.01135 4.17809Z'
        fill={color || "#2680EA"}
      />
    </svg>
  )
}

export default FreezeCardIcon
