import React from "react"
import ArrowRightSmall from "../../../svg/v3/arrowRightSmall"
import { useSelector } from "react-redux"
import { EventName } from "../../../../utils/enums"
import { useNavigate } from "react-router-dom"

const QuickActionsItemWithDescription = ({ actionConfig, isOnly = false }) => {
  const [theme] = useSelector(state => [state.theme])
  const navigate = useNavigate()
  const {
    primary: { color1: primaryColor1 },
  } = theme?.v3?.rawColors

  return (
    <div
      className='v3-activity-section-single-action'
      onClick={() =>
        navigate(actionConfig?.redirectTo, {
          state: {
            previousScreen:
              actionConfig?.redirectTo === "/transactions" && EventName.HOME,
          },
        })
      }
    >
      <div style={{ width: "75%" }}>
        <div className='v3-quick-action-svg'>{actionConfig?.icon}</div>
        <div style={{ width: "50%" }}>
          <div className='v3-quick-action-text'>
            {actionConfig?.text1} {actionConfig?.text2}
          </div>
          {isOnly && (
            <div className='v3-quick-action-description'>
              {actionConfig?.description}
            </div>
          )}
        </div>
      </div>
      <div className='v3-quick-action-view'>
        {isOnly && <div>View</div>}
        {isOnly && <ArrowRightSmall color={primaryColor1} />}
      </div>
    </div>
  )
}

export default QuickActionsItemWithDescription
