import { getApiConfig } from "../utils/functions"
import Service from "./Service"

const AccountService = {
  getSummary: (apiToken, accountId, version) =>
    Service.get(`/accounts/${accountId || "temp_id"}/summary`, {
      params: {
        version: "v1",
      },
      ...getApiConfig(apiToken),
    }),
  getSummaryWithoutNudges: (apiToken, accountId, version) =>
    Service.get(`/accounts/${accountId || "temp_id"}/v2/summary`, {
      params: {
        version: "v1",
      },
      ...getApiConfig(apiToken),
    }),

  getOffersList: (accountId, programId) =>
    Service.get(`/accounts/get-offers-list`, {
      params: {
        programId: programId,
        accountId: accountId,
      },
    }),

  getBenefitsForAccount: data =>
    Service.post(`/accounts/getAccountBenefits`, data),

  subscribeBenefit: data => Service.post("/accounts/subscribeBenefit", data),

  getTransactions: (accountId, data, apiToken) =>
    Service.post(
      `/accounts/${accountId}/transactions`,
      data,
      getApiConfig(apiToken),
    ),

  getRewards: (accountId, data) =>
    Service.post(`/accounts/${accountId}/rewards`, data),

  getSsoRewards: data =>
    Service.post(`/accounts/grimlock/aggregate/statement`, data),

  getAggregateSummary: data =>
    Service.post(`/accounts/grimlock/aggregate/summary`, data),

  getCashbacks: (accountId, data) =>
    Service.post(`/accounts/${accountId}/cashbacks`, data),

  getUnbilledTransactions: (accountId, data) =>
    Service.post(`/accounts/${accountId}/unbilled`, data),

  getStatements: (accountId, data) =>
    Service.post(`/accounts/${accountId}/statements`, data),

  getBatchStatements: data => Service.post("/accounts/get-statements", data),

  getBillingCycles: data =>
    Service.post(`/accounts/get-statement-cycles`, data),

  downloadStatement: (accountId, statementId, fileName) =>
    Service.get(`/accounts/${accountId}/download-statement/${statementId}`, {
      responseType: "blob",
    }),

  getEMIConfig: (apiToken, accountId, amount, txnRefId) =>
    Service.get(`/accounts/${accountId}/emi`, {
      params: { amount: amount, txnRefId: txnRefId },
      ...getApiConfig(apiToken),
    }),

  createEMI: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/emi/create`,
      data,
      getApiConfig(apiToken),
    ),

  listEmi: (apiToken, accountId) =>
    Service.get(`/accounts/${accountId}/list-emi`, getApiConfig(apiToken)),

  getForeclosureDetails: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/get-foreclosure-details`,
      data,
      getApiConfig(apiToken),
    ),

  forecloseEmi: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/foreclose-emi`,
      data,
      getApiConfig(apiToken),
    ),

  getRewardPoints: data => Service.post(`/accounts/aggregates`, data),
  getHfRewardPoints: data => Service.post(`/accounts/rewards/summary`, data),
  getHfCashback: data => Service.post(`/accounts/cashback-summary`, data),

  getSpendGroups: (accountId, data) =>
    Service.post(`/accounts/${accountId}/spendGroups`, data),

  getSpendCategory: (accountId, spendGroupId, data) =>
    Service.post(
      `/accounts/${accountId}/spendGroups/${spendGroupId}/transactions`,
      data,
    ),
  getSpendTrend: (accountId, data) =>
    Service.post(`/accounts/${accountId}/spendTrend`, data),

  getAllNudges: accountId =>
    Service.get(`/accounts/nudges/account/${accountId}`),

  getPGConfig: () => Service.get(`/accounts/grimlock/pg/config`),

  initiatePGTransaction: data =>
    Service.post(`/accounts/grimlock/pg/transaction/initiate`, data),

  getPGTransactionStatus: data =>
    Service.post(`/accounts/grimlock/pg/transaction/status`, data),

  verifyVpa: data => Service.post(`/accounts/grimlock/pg/vpa/verify`, data),
  disableNudge: (accountId, data) =>
    Service.post(`/accounts/nudges/account/${accountId}/channel/disable`, data),

  getHfRewardSummary: data =>
    Service.post(`/accounts/rewards/v2/summary`, data),

  getHfCashbackSummary: data =>
    Service.post(`/accounts/cashback/summary`, data),

  getExpiringSoonRewardTransactions: data =>
    Service.post(`/accounts/rewards/transactions/expiringSoon`, data),

  getHfRewardTransactions: data =>
    Service.post(`/accounts/rewards-transactions`, data),

  getHfCashbackTransactions: data =>
    Service.post(`/accounts/cashback-transactions`, data),

  getBulkBenefits: data => Service.post(`/accounts/benefits/bulk`, data),

  getBulkTransactions: data =>
    Service.post(`/accounts/transactions/bulk`, data),

  generateAcsChallengeOTP: data =>
    Service.post(`/accounts/generate-acs-otp`, data),

  verifyAcsChallengeOTP: data => Service.post(`/accounts/verify-acs-otp`, data),

  completePaymentApproval: data =>
    Service.post(`/accounts/paymentProcessing/fetchTxnInfo`, data),
}

export default AccountService
