import React from "react"

const QuestionIcon = ({ color1, color2, className }) => {
  return (
    <svg
      width='96'
      height='96'
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M47.999 95.998C74.2946 95.998 95.6113 74.6813 95.6113 48.3857C95.6113 22.0902 74.2946 0.773438 47.999 0.773438C21.7035 0.773438 0.386719 22.0902 0.386719 48.3857C0.386719 74.6813 21.7035 95.998 47.999 95.998Z'
        fill={color1}
      />
      <path
        d='M47.999 86.2812C68.9281 86.2812 85.8945 69.3149 85.8945 48.3857C85.8945 27.4566 68.9281 10.4902 47.999 10.4902C27.0699 10.4902 10.1035 27.4566 10.1035 48.3857C10.1035 69.3149 27.0699 86.2812 47.999 86.2812Z'
        fill='white'
      />
      <path
        d='M40.5826 40.322C40.0611 40.3231 39.5476 40.1941 39.0884 39.9469C38.6293 39.6997 38.2389 39.342 37.9527 38.9061C37.6664 38.4702 37.4933 37.9698 37.4489 37.4502C37.4045 36.9307 37.4903 36.4082 37.6984 35.9301C38.2409 34.718 39.0239 33.6289 40.0001 32.7287C42.1312 30.7292 44.9384 29.7295 48.4217 29.7295C50.6642 29.7295 52.5728 30.143 54.1474 30.9701C55.7145 31.7903 57.0235 33.0291 57.9287 34.5486C58.8505 36.0226 59.3422 37.7248 59.3482 39.4632C59.3477 40.4041 59.1946 41.3386 58.895 42.2305C58.5491 43.2226 58.0674 44.162 57.4635 45.0218C57.0818 45.5787 56.1673 46.5171 54.72 47.837C53.3363 49.0937 52.4695 49.9804 52.1195 50.497C51.7173 51.1335 51.4194 51.8302 51.2368 52.5607C51.0247 53.4017 50.8926 54.2608 50.8421 55.1266C50.8207 55.4022 50.6961 55.6595 50.4933 55.8472C50.2904 56.035 50.0242 56.1392 49.7478 56.1391H45.3921C45.1051 56.1391 44.8296 56.0268 44.6244 55.8261C44.4192 55.6255 44.3008 55.3525 44.2944 55.0656C44.267 53.0681 44.6656 51.0877 45.4634 49.2563C46.2425 47.5148 47.5069 45.8806 49.2567 44.3537C50.9422 42.8746 51.9601 41.8567 52.3104 41.3C52.6689 40.7116 52.8511 40.0326 52.8352 39.3437C52.8352 38.3261 52.4098 37.4632 51.5589 36.7552C50.7078 36.0476 49.5666 35.6937 48.1354 35.6935C46.6719 35.6935 45.4711 36.1247 44.533 36.9871C44.0774 37.3991 43.7153 37.904 43.4712 38.4677C43.2228 39.0247 42.8171 39.4969 42.3039 39.8264C41.7907 40.1559 41.1924 40.3281 40.5826 40.322ZM47.6583 58.859C48.208 58.8491 48.7541 58.9496 49.2642 59.1544C49.7744 59.3593 50.2383 59.6645 50.6285 60.0519C51.0231 60.4208 51.3363 60.8682 51.5479 61.3654C51.7594 61.8625 51.8648 62.3983 51.8571 62.9386C51.8646 63.4794 51.7604 64.016 51.5511 64.5148C51.3417 65.0135 51.0317 65.4637 50.6404 65.8372C49.8612 66.609 48.8087 67.042 47.7119 67.042C46.6152 67.042 45.5627 66.609 44.7835 65.8372C44.2088 65.268 43.8162 64.5408 43.6557 63.748C43.4953 62.9552 43.5741 62.1326 43.8822 61.3847C44.1903 60.6367 44.7138 59.9973 45.3861 59.5476C46.0585 59.0979 46.8494 58.8582 47.6583 58.859Z'
        fill={color1}
      />
      <path
        d='M60.6931 94.286C56.5581 95.4255 52.2882 96.0014 47.999 95.9981C21.7035 95.9981 0.386743 74.6813 0.386743 48.3857C0.379618 41.3645 1.92879 34.4289 4.9228 28.0779C7.91681 21.727 12.2812 16.1186 17.7021 11.6562C17.6885 12.3034 17.682 12.9512 17.6826 13.5996C17.6826 47.212 34.7512 76.8404 60.6931 94.286Z'
        fill={color1}
      />
      <path
        d='M50.2844 86.2121C49.5285 86.2588 48.7667 86.2821 47.999 86.2821C27.104 86.2821 10.1035 69.2816 10.1035 48.3866C10.0952 39.8327 12.9946 31.53 18.3259 24.8408C21.0671 48.5325 32.4453 70.3825 50.2844 86.2121Z'
        fill='#F0F0F0'
      />
    </svg>
  )
}
export default QuestionIcon
