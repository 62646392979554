import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "../../nativeBaseReplacements"

const Pointer = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 24 24' size='24px'>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.9998 3.66675C7.40486 3.66675 3.6665 7.40511 3.6665 12.0001C3.6665 16.5951 7.40486 20.3334 11.9998 20.3334C16.5948 20.3334 20.3332 16.5951 20.3332 12.0001C20.3332 7.40511 16.5948 3.66675 11.9998 3.66675ZM11.9998 4.91675C15.9193 4.91675 19.0832 8.08066 19.0832 12.0001C19.0832 15.9195 15.9193 19.0834 11.9998 19.0834C8.08041 19.0834 4.9165 15.9195 4.9165 12.0001C4.9165 8.08066 8.08041 4.91675 11.9998 4.91675ZM12.6183 8.86857C12.4939 8.8686 12.3724 8.90574 12.2692 8.97524C12.1661 9.04473 12.086 9.14343 12.0393 9.25869C11.9925 9.37396 11.9812 9.50055 12.0068 9.62227C12.0324 9.74399 12.0937 9.85531 12.1829 9.94198L13.616 11.3751H8.87484C8.79202 11.3739 8.70979 11.3892 8.63294 11.4201C8.55608 11.451 8.48613 11.4968 8.42715 11.555C8.36817 11.6131 8.32133 11.6824 8.28937 11.7588C8.2574 11.8353 8.24094 11.9173 8.24094 12.0001C8.24094 12.0829 8.2574 12.1649 8.28937 12.2413C8.32133 12.3177 8.36817 12.387 8.42715 12.4452C8.48613 12.5033 8.55608 12.5492 8.63294 12.5801C8.70979 12.611 8.79202 12.6263 8.87484 12.6251H13.616L12.1829 14.0582C12.123 14.1158 12.0751 14.1848 12.0421 14.2611C12.0091 14.3374 11.9917 14.4196 11.9908 14.5027C11.99 14.5859 12.0057 14.6683 12.0372 14.7453C12.0686 14.8223 12.1151 14.8923 12.1739 14.9511C12.2327 15.0099 12.3026 15.0563 12.3796 15.0878C12.4566 15.1192 12.5391 15.1349 12.6222 15.1341C12.7054 15.1333 12.7875 15.1158 12.8638 15.0828C12.9402 15.0498 13.0091 15.002 13.0667 14.942L15.5667 12.442C15.6839 12.3248 15.7497 12.1658 15.7497 12.0001C15.7497 11.8343 15.6839 11.6754 15.5667 11.5582L13.0667 9.05819C13.0085 8.99819 12.9388 8.9505 12.8617 8.91793C12.7847 8.88536 12.702 8.86857 12.6183 8.86857Z'
          fill={theme.color1}
        />
      </svg>
    </Icon>
  )
}

export default Pointer
