import { throttle } from "lodash"
import { useContext, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import CommonService from "../services/CommonService"
import { expireSession } from "../store/actions/Session"
import { ErrorType } from "../utils/constants"
import { ErrorContext } from "../components/auth/ErrorScreenContext"

const sixSeconds = 6 * 1000

const useIdle = (idleTime, sessionLoaded) => {
  const dispatch = useDispatch()
  const { setErrorState } = useContext(ErrorContext)
  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    let timeout

    const handleUserActivity = async () => {
      try {
        const response = sessionLoaded && (await CommonService.sendHeartBeat())
        if (response?.statusText && response.statusText !== "OK") {
          dispatch(expireSession())
          localStorage.removeItem("sessionToken")
          localStorage.removeItem("deviceToken")
        }
      } catch (error) {
        if (!navigator.onLine) {
          setErrorState(ErrorType.NO_INTERNET_ERROR)
        } else {
          dispatch(expireSession())
          localStorage.removeItem("sessionToken")
          localStorage.removeItem("deviceToken")
        }
      }
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      setIsIdle(true)
      dispatch(expireSession())
      localStorage.removeItem("sessionToken")
      localStorage.removeItem("deviceToken")
    }, idleTime)
    const throttleFun = throttle(handleUserActivity, sixSeconds)

    throttleFun()

    const eventListeners = [
      "mousedown",
      "mousemove",
      "keydown",
      "touchstart",
      "scroll",
      "keypress",
    ]

    eventListeners.forEach(event => {
      document.addEventListener(event, throttleFun)
    })

    return () => {
      eventListeners.forEach(event => {
        document.removeEventListener(event, throttleFun)
      })

      clearTimeout(timeout)
    }
  }, [idleTime, sessionLoaded])

  return isIdle
}

export default useIdle
