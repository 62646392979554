import React, { useState, useEffect } from "react"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Ellipse from "../../../../svg/v3/Ellipse"
import QuestionIcon from "../../../../svg/v3/QuestionIcon"
import ArrowRight from "../../../../svg/arrowRight"
import FreezeInsteadScreen from "./freezeInsteadScreen"
import ProceedToBlock from "./proceedToBlock"
import { Header } from "../../../../core"
import Divider from "../../../../core/Divider/v3/Divider"
import { BottomSheet } from "react-spring-bottom-sheet"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "../../../../../utils/enums"
import { BlockOptions } from "../constants"
import { toast } from "../../../../../core/Toast"
import { useNavigate } from "react-router-dom"

const BlockCardReason_v3 = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const replaceOrBlock = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      if (
        !session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_REPLACE
      } else {
        return BlockOptions.GRAY_OUT
      }
    } else {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REPLACE_CARD_DISABLE,
        )
      ) {
        return BlockOptions.ONLY_BLOCK
      }
    }
  }
  const defaultReasons = [
    {
      reasonDd: "CARDSTOLEN",
      reasonText: t("manageCard.cardstolen"),
      suggestFreeze: false,
    },
    {
      reasonDd: "CARDLOST",
      reasonText: t("manageCard.cardlost"),
      suggestFreeze: false,
    },
    {
      reasonDd: "DAMAGED",
      reasonText: t("manageCard.cardDamaged"),
      suggestFreeze: true,
    },
    {
      reasonDd: "FRAUD",
      reasonText: t("manageCard.cardFraud"),
      suggestFreeze: true,
    },
  ]
  const dispatch = useDispatch()
  const [theme, user, authStore, config, session, screen] = useSelector(
    state => [
      state.theme,
      state.user,
      state.auth,
      state.config,
      state.session,
      state.screen,
    ],
  )

  const [bottomSheetContent, setBottomSheetContent] = useState("")

  const reasons =
    screen?.blockReplaceCard?.reasons?.length > 0
      ? screen?.blockReplaceCard?.reasons
      : defaultReasons

  useEffect(() => {
    // store and check init card state as this screen changes hotlist status
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      replaceOrBlock() === BlockOptions.ONLY_REPLACE ||
      replaceOrBlock() === BlockOptions.GRAY_OUT
    ) {
      toast(t("manageCard.accessBlockedText"))
      navigate("/")
      return false
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
    ) {
      toast(t("manageCard.accessBlockedToast"))
      navigate("/")
      return
    }
  }, [navigate, user.account.status, user.cards])

  const handleReasonClick = (reason, suggestFreeze) => {
    //suggestFreeze will come from config // for now it is static
    if (suggestFreeze)
      setBottomSheetContent(
        <FreezeInsteadScreen
          reason={reason}
          setBottomSheetContent={setBottomSheetContent}
          type='block-card'
        />,
      )
    else
      setBottomSheetContent(
        <ProceedToBlock
          setBottomSheetContent={setBottomSheetContent}
          reason={reason}
        />,
      )
  }
  return (
    <div
      style={{
        minHeight: window.innerHeight,
        backgroundColor: theme.v3.cssVars.leadingWhite,
      }}
    >
      <Header
        text='Block/Replace Card'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div
        style={{
          maxWidth: "450px",
          zIndex: "5",
          position: "relative",
          color: theme?.v3.cssVars.tertiaryNeutral.color1,
        }}
      >
        <div>
          <div className='v3-blockCardReason-ellipse-container'>
            <Ellipse
              className='v3-blockCardReason-ellipse-svg'
              color1={theme.v3.rawColors.tertiaryCaution.color3}
            />
            <QuestionIcon
              className='v3-blockCardReason-questionIcon-svg'
              color1={theme.v3.rawColors.tertiaryCaution.color1}
            />
          </div>

          <div className='v3-blockCardReason-faq-title'>
            {t("manageCard.blockedCardReason")}
          </div>

          <div className='v3-blockCardReason-faq-subtitle'>
            {t("manageCard.blockedCardQuestion")}
          </div>
          <Divider color={theme.v3.cssVars.tertiaryNeutral.color3} />
          <div className='v3-blockCardReason-Reason'>
            {reasons &&
              reasons.map((reason, index) => {
                return (
                  <>
                    <div
                      onClick={() =>
                        handleReasonClick(
                          reason?.reasonDd,
                          reason?.suggestFreeze,
                        )
                      }
                      className='v3-blockCardReason-reason-text'
                    >
                      {reason?.reasonText}

                      <span className='v3-blockCardReason-reason-right-arrow'>
                        <ArrowRight
                          color={theme.v3.cssVars.primary.color1}
                          size={"12px"}
                        />
                      </span>
                    </div>
                    {index < reasons.length - 1 && (
                      <Divider
                        color={theme.v3.cssVars.tertiaryNeutral.color3}
                      />
                    )}
                  </>
                )
              })}
          </div>
        </div>
      </div>

      <BottomSheet
        open={bottomSheetContent}
        onDismiss={() => setBottomSheetContent(null)}
      >
        {bottomSheetContent}
      </BottomSheet>
    </div>
  )
}

export default BlockCardReason_v3
