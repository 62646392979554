import React from "react"
import { useSelector } from "react-redux"

const DividerV3 = ({ color, height, width, marginBottom, ...style }) => {
  const theme = useSelector(state => state.theme)
  const customCss = style.style
  return (
    <div
      style={{
        width: width || "100%",
        borderBottom: "1px solid",
        borderColor: color || theme.v3.rawColors.tertiaryNeutral.color3,
        height: height || "1px",
        marginBottom: marginBottom || "16px",
        ...customCss,
      }}
    />
  )
}

export default DividerV3
