import React from "react"
import RewardPointsBgPattern from "../../../../svg/v3/RewardPointsBgPattern"
import { hexToRGBA } from "../../../../../utils/functions"
import { useSelector } from "react-redux"

const RewardPointsCard = ({ details }) => {
  const theme = useSelector(state => state.theme)
  return (
    <div
      className='v3-aboutCard-RewardPointsCard'
      style={{ background: details?.color }}
    >
      <div className='v3-aboutCard-RewardPointsCard-bgPattern'>
        <RewardPointsBgPattern color={"rgba(128,128,128,0.4)"} />
      </div>
      <img
        src={details?.imageUrl}
        className='v3-aboutCard-RewardPointsCard-image'
        alt=''
      />
      <div className='v3-aboutCard-RewardPointsCard-text'>
        <div>{details?.title}</div>
        <div style={{ color: theme.v3.cssVars.primaryBase.color2 }}>
          {details?.desc}
        </div>
      </div>
    </div>
  )
}

export default RewardPointsCard
