const TokenisationArrowDown = ({ color = "" }) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.14658 10.8538L3.14658 5.85378C3.10013 5.80733 3.06328 5.75218 3.03814 5.69148C3.01299 5.63079 3.00005 5.56573 3.00005 5.50003C3.00005 5.43434 3.01299 5.36928 3.03814 5.30859C3.06328 5.24789 3.10013 5.19274 3.14658 5.14628C3.19304 5.09983 3.24819 5.06298 3.30888 5.03784C3.36958 5.0127 3.43463 4.99976 3.50033 4.99976C3.56603 4.99976 3.63108 5.0127 3.69178 5.03784C3.75248 5.06298 3.80763 5.09983 3.85408 5.14628L8.50033 9.79316L13.1466 5.14628C13.2404 5.05246 13.3677 4.99976 13.5003 4.99976C13.633 4.99976 13.7603 5.05246 13.8541 5.14628C13.9479 5.2401 14.0006 5.36735 14.0006 5.50003C14.0006 5.63272 13.9479 5.75996 13.8541 5.85378L8.85408 10.8538C8.80765 10.9003 8.7525 10.9372 8.6918 10.9623C8.6311 10.9875 8.56604 11.0004 8.50033 11.0004C8.43462 11.0004 8.36956 10.9875 8.30886 10.9623C8.24816 10.9372 8.19302 10.9003 8.14658 10.8538Z'
        fill={color || "#0E0E0E"}
      />
    </svg>
  )
}

export default TokenisationArrowDown
