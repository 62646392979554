import React from "react"
import WebViewHeader from "./WebViewHeader"
import AboutProgramAlertIcon from "../../../../assets/images/v3/aboutProgramAlert.svg"
import { useTranslation } from "react-i18next"

const PayLaterAboutProgram = () => {
  const { t } = useTranslation()
  return (
    <>
      <WebViewHeader headerText={"About Program"} />
      <div className='v3-web-about-program-container'>
        <div className='v3-web-about-program-card'>
          <div className='v3-web-about-program-card-top-text-container'>
            <ul>
              <li className='v3-web-about-program-card-top-text-point'>
                {t("payLaterWebComponents.aboutPrograms.text1")}
              </li>
              <li className='v3-web-about-program-card-top-text-point'>
                {t("payLaterWebComponents.aboutPrograms.text2")}
              </li>
              <li className='v3-web-about-program-card-top-text-point'>
                {t("payLaterWebComponents.aboutPrograms.text3")}
              </li>
            </ul>
          </div>
          <div className='v3-web-about-program-card-bottom-alert-container'>
            <div className='v3-web-about-program-card-bottom-alert-image'>
              <img src={AboutProgramAlertIcon} />
            </div>
            <div className='v3-web-about-program-card-bottom-alert-text'>
              <div className='v3-web-about-program-card-bottom-alert-text-header'>
                {t("payLaterWebComponents.aboutPrograms.pleaseNote")}
              </div>
              <div className='v3-web-about-program-card-bottom-alert-text-points'>
                <ul className='v3-web-about-program-card-bottom-alert-text-point-list'>
                  <li className='v3-web-about-program-card-bottom-alert-text-point'>
                    {t("payLaterWebComponents.aboutPrograms.warningPoint1")}
                  </li>
                  <li className='v3-web-about-program-card-bottom-alert-text-point'>
                    {t("payLaterWebComponents.aboutPrograms.warningPoint2")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PayLaterAboutProgram
