import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import { captureEvents } from "../../../utils/functions"
import paymentFailIcon from "../../../assets/images/redCross.svg"
import warningIcon from "../../../assets/images/warningIcon.svg"
import { useNavigate } from "react-router-dom"
import { EventName, PwaVersions } from "../../../utils/enums"
import Divider from "../../core/Divider/v3/Divider"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const options = ["@ybi", "@paytm", "@apl", "@axl", "@aubank", "@ilb"]

const PaymentExpired = () => {
  const windowDim = useWindowDimensions()
  const { t } = useTranslation()
  const [inputId, setInputId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const paymentGateway = useSelector(state => state.paymentGateway)
  const config = useSelector(state => state.config)

  useEffect(() => {
    captureEvents({
      eventName: EventName.PAY_BILL_FAILURE_CTA,
      metadata: { error: "Session Expired" },
    })
  }, [])

  const handleProceed = async () => {
    captureEvents({
      eventName: EventName.PAY_BILL_FAILURE_CTA,
      metadata: { action: "retry payment" },
    })
    navigate("/paymentGateway/options")
  }

  const retryOtherMethods = async () => {
    captureEvents({
      eventName: EventName.PAY_BILL_FAILURE_CTA,
      metadata: { action: "other payment method" },
    })
    navigate("/paymentGateway/options")
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
        position: "relative",
      }}
    >
      <Header
        text={config?.version == PwaVersions.V3 ? "" : "Bill Payment"}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      {config?.version == PwaVersions.V3 ? (
        <>
          <div className='v3-paymentExpired-container'>
            <div className='v3-paymentExpired-image'>
              <img src={warningIcon} />
            </div>
            <div className='v3-paymentExpired-text'>
              {t("PaymentGateway.paymentExpiredText")}
            </div>
            <Divider />
            <div className='v3-paymentExpired-changePaymentMode'>
              <div>{t("PaymentGateway.changePaymentMode")} </div>
              <div onClick={() => retryOtherMethods()}>
                {t("PaymentGateway.changePaymentModeCTA")}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='mainContainer' style={{ color: theme.appTextColor }}>
            <div
              style={{ marginTop: "200px" }}
              className='paymentSuccessContentContainer'
            >
              <div className='paymentSuccessIcon'>
                <img src={paymentFailIcon} />
              </div>

              <div className='paymentSuccessTitle'>
                {t("PaymentGateway.sessionExpiredText")}
              </div>

              <div className='paymentSuccessDesc'>
                {t("PaymentGateway.sessionTimedOut")}
              </div>
              <div className='paymentSuccessDesc' style={{ marginTop: "1px" }}>
                {t("PaymentGateway.retryPayment")}
              </div>
            </div>
          </div>
          <div className='paymentFailedBottomTextContainer'>
            <div className='paymentFailedBottomTextContainerText'>
              {t("PaymentGateway.paymentFailedBottomTextContainer")}{" "}
              <span
                style={{ cursor: "pointer" }}
                onClick={() => retryOtherMethods()}
                className='paymentReqHighlight'
              >
                {" "}
                {t("PaymentGateway.otherPaymentType")}{" "}
              </span>
            </div>
          </div>
        </>
      )}
      <div className='paymentGatewayBtnContainer'>
        <ColorButton
          text={
            config?.version == PwaVersions.V3 ? "Retry Payment" : "Retry Now"
          }
          onPress={() => handleProceed()}
        />
      </div>
    </div>
  )
}

export default PaymentExpired
