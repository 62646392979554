import React, { lazy, Suspense } from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import CardControlsLoader from "../../../core/SkeletonLoader/CardControlsLoader"
import CardSettings_v3 from "./cardSettings_v3"
// import CardSettings_v2 from "./cardSettings_v2"
const CardSettings_v2 = lazy(() => import("./cardSettings_v2"))

const Index = () => {
  const config = useSelector(state => state.config)
  const getVersioned = version => {
    switch (version) {
      case PwaVersions.V2:
        return (
          <Suspense fallback={<CardControlsLoader />}>
            {" "}
            <CardSettings_v2 />
          </Suspense>
        )
      case PwaVersions.V2_1:
        return (
          <Suspense fallback={<CardControlsLoader />}>
            {" "}
            <CardSettings_v2 />
          </Suspense>
        )
      case PwaVersions.V3:
        return (
          <Suspense fallback={<CardControlsLoader />}>
            {" "}
            <CardSettings_v3 />
          </Suspense>
        )
      default:
        return (
          <Suspense fallback={<CardControlsLoader />}>
            {" "}
            <CardSettings_v2 />
          </Suspense>
        )
    }
  }
  return getVersioned(config?.version)
}

export default Index
