import React, { useEffect, useState } from "react"
import { useLottie } from "lottie-react"
import { useSelector } from "react-redux"
import { Header } from "../core/headers"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"
import cubicalLoader from "../../components/svg/v3/cubeLoader.json"

const CubeAppLoader = () => {
  const screen = useSelector(state => state.screen)
  const headerConfig = screen?.pwaHeader
  const height = useWindowDimensions().height
  const [showText, setShowText] = useState(false)

  const { View } = useLottie(
    {
      animationData: cubicalLoader,
      loop: true,
      autoplay: true,
      speed: 1.0,
    },
    {
      height: 252,
      width: 252,
      maxWidth: useWindowDimensions().width,
    },
  )

  return (
    <>
      {headerConfig?.primaryLogoUrl && <Header />}
      <div className='cube-loader-container' style={{ maxHeight: height }}>
        <div className='cube-loader'>{View}</div>
        {showText && (
          <div className='cube-loader-text'>
            <p className='cube-loader-text-1'>Just hold a little longer..</p>
            <p className='cube-loader-text-2'>You’ll be there in a moment..</p>
          </div>
        )}
      </div>
    </>
  )
}

export default CubeAppLoader
