import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import CustomerCare_v2 from "./CustomerCare_v2"
import CustomerCare_v3 from "./v3/CustomerCare_v3"

const CustomerCare = () => {
  const config = useSelector(state => state.config)
  const versionedCustomerCare = programType => {
    switch (programType) {
      case PwaVersions.V2:
        return <CustomerCare_v2 />
      case PwaVersions.V2_1:
        return <CustomerCare_v2 />
      case PwaVersions.V3:
        return <CustomerCare_v3 />
      default:
        return <></>
    }
  }
  return versionedCustomerCare(config?.version)
}

export default CustomerCare
