import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import couponHeart from "../../../assets/images/v3/couponHeart.svg"
import { Header } from "../../core"
import CvpOfferCard from "./Components/cvpOfferCard"
import hflogo from "../../../assets/images/v3/active/hfLogo.svg"
import CubeAppLoader from "../../core/InAppLoader"
import ZeroStateTransactionsV3 from "../../core/ZeroStateTransactionsV3"
import { useLocation } from "react-router-dom"

const AllCvpOffers = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const benefits = useSelector(state => state.benefits)
  const screen = useSelector(state => state.screen)
  const [loading, setLoading] = useState(false)

  const cvpOffers = benefits?.cvpBenefits?.sort(
    (a, b) => a?.displayOrder - b?.displayOrder,
  )
  const isHfLogoEnabled = screen?.preLoader?.isHfBrandingEnabled
  const location = useLocation()
  const fromRewards = location?.state?.fromRewards

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (cvpOffers && cvpOffers.length > 0) {
        setLoading(false)
        clearTimeout(timer1)
      } else {
        setLoading(true)
        const timer2 = setTimeout(() => {
          if (cvpOffers && cvpOffers.length > 0) {
            setLoading(false)
          }
        }, 1000)
      }
    }, 500)

    return () => {
      clearTimeout(timer1)
    }
  }, [cvpOffers])

  return (
    <>
      {!fromRewards && loading ? (
        <CubeAppLoader />
      ) : (
        <>
          <div
            style={{
              minHeight: window.innerHeight,
              backgroundColor: theme.v3.cssVars.primary.color5,
            }}
            className={cvpOffers?.length <= 0 && "v3-allCvpOffers-container"}
          >
            <Header
              text={t("offers.allCvpOffersHeader")}
              onBack={async () => {
                window.history.go(-1)
              }}
            />
            {cvpOffers?.length > 0 ? (
              <div className='v3-allCvpOffers'>
                <div>
                  <img src={couponHeart} alt='coupon-heart' />
                  <div
                    style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
                  >
                    {t("offers.cvpOfferText")}
                  </div>
                </div>
                <div className='v3-allCvpOffers-offers-container'>
                  {cvpOffers?.map((offer, index) => {
                    return <CvpOfferCard key={index} offer={offer} />
                  })}
                </div>
              </div>
            ) : (
              <ZeroStateTransactionsV3
                text={t("offers.zeroStateOfferText")}
                subText={t("offers.zeroStateOfferSubText")}
              />
            )}
            {isHfLogoEnabled && (
              <div className='v3-allCvpOffers-offers-hf-branding'>
                <div className='v3-home-hf-branding-text'>Powered by</div>
                <img src={hflogo} width={"109px"} height={"19px"} />
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default AllCvpOffers
