const styles = {
  mainView: {
    backgroundColor: "#f8f8f8",
    py: "10",
    px: "5",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },

  loader: { w: "100%", alignItems: "center", justifyContent: "center" },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    background: "#FFFFFF",
    padding: "13px",
    gap: "8px",
  },

  image: {
    width: "80%",
    height: "80%",
    alt: "Alternate Text ",
  },
  manageCard: {
    width: "100%",
    fontSize: 18,
    fontFamily: "Inter",
    fontWeight: 500,

    lineHeight: "24px",
    color: "#303030",
    letterSpacing: "0.04em",
  },
  displayDate: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "16px",
    /* identical to box height, or 125% */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.04em",

    /* Black/Strong */

    color: "#303030",
  },
  displayTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    /* identical to box height, or 125% */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",

    /* Black/Strong */

    color: "#303030",
  },
  choosePlan: {
    display: "flex",
    flexDirection: " column",
    alignItems: "flex-start",
    padding: "0px",
    background: " #FFFFFF",
  },
  choosePlanTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    padding: "16px",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    /* identical to box height, or 125% */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
  },
  plan: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px",
    gap: "20px",
    width: "100%",

    /* White/Mild */
  },
  monthlyPlanTextBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    gap: "2px",

    width: "69px",
    height: "24px",

    /* Notes/Yellow */

    background: "#FFE5A6",
    borderRadius: "2px",
  },
  monthlyPlanText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "16px",
    /* identical to box height, or 125% */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.04em",

    /* Black/Strong */

    color: "#303030",
  },
  amountPerMonth: {
    fontFamily: "Inter",
    fontStyle: "normal",
    paddingTop: "8px",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    /* identical to box height, or 125% */

    letterSpacing: "0.02em",
  },
  monthlyPlanIntrestBox: {
    display: "flex",
    flexDirection: "row",
  },
  monthlyPlanIntrest: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    paddingTop: "5px",
    paddingBottom: "5px",
    /* identical to box height, or 125% */

    color: "#7B7B7B",

    mixBlendMode: "normal",
    letterSpacing: "0.04em",
  },
  monthlyPlanDuration: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "16px",

    letterSpacing: "0.04em",

    color: "#303030",
  },
  displayAmount: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "30px",
    /* identical to box height, or 125% */

    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",

    /* Black/Strong */

    color: "#303030",
  },
  cardInterface: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
  },
  fontText: {
    fontSize: "16",
    fontFamily: "Inter",
    fontWeight: 500,
    ml: "3",
  },
}

export default styles
