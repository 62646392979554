import React from "react"

const PauseSvg = ({ color = "#0E0E0E", backgroundColor = "#F1F1F1" }) => {
  return (
    <svg
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='40' height='40' rx='20' fill={backgroundColor} />
      <path
        d='M20 29.7083C15.06 29.7083 11.0417 25.69 11.0417 20.75C11.0417 15.81 15.06 11.7916 20 11.7916C24.94 11.7916 28.9584 15.81 28.9584 20.75C28.9584 25.69 24.94 29.7083 20 29.7083ZM20 13.0416C15.7492 13.0416 12.2917 16.4991 12.2917 20.75C12.2917 25.0008 15.7492 28.4583 20 28.4583C24.2509 28.4583 27.7084 25.0008 27.7084 20.75C27.7084 16.4991 24.2509 13.0416 20 13.0416ZM18.39 24.7083H17.4425C16.7175 24.7083 16.2492 24.245 16.2492 23.5275V17.9716C16.2492 17.255 16.7175 16.7908 17.4425 16.7908H18.39C19.115 16.7908 19.5834 17.2541 19.5834 17.9716V23.5275C19.5834 24.245 19.115 24.7083 18.39 24.7083ZM17.5 23.4583H18.33L18.3334 18.0457L17.5034 18.0424L17.5 23.4583ZM22.5567 24.7083H21.6092C20.8842 24.7083 20.4159 24.245 20.4159 23.5275V17.9716C20.4159 17.255 20.8842 16.7908 21.6092 16.7908H22.5567C23.2817 16.7908 23.75 17.2541 23.75 17.9716V23.5275C23.75 24.245 23.2817 24.7083 22.5567 24.7083ZM21.6667 23.4583H22.4967L22.5 18.0457L21.67 18.0424L21.6667 23.4583Z'
        fill={color}
      />
    </svg>
  )
}

export default PauseSvg
