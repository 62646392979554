import * as amplitude from "@amplitude/analytics-browser"
import mixpanel from "mixpanel-browser"
import zipy from "zipyai"
import { Envs } from "./constants"

const env = process.env.REACT_APP_ENV
const zipyApiKey = process.env.REACT_APP_ZIPY_API_KEY
const mixpanelApiKey = process.env.REACT_APP_MIXPANEL_API_KEY
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY

const sendZipyData = env === Envs.UAT || env === Envs.SANDBOX
const sendAnalytics =
  env === Envs.UAT || env === Envs.SANDBOX || env === Envs.PROD
const logAnalytics = false

export const initZipy = () => {
  if (sendZipyData) {
    zipy.init(zipyApiKey)
  }
}

export const initMixpanel = () => {
  if (sendAnalytics) {
    mixpanel.init(mixpanelApiKey)
  }
}

export const initAmplitude = () => {
  if (sendAnalytics) {
    amplitude.init(amplitudeApiKey)
  }
}

export const track = (name, props) => {
  if (logAnalytics) {
    name && console.log("Event name ->", name)
    props && console.log("Props ->", props)
  }

  if (sendAnalytics) {
    mixpanel.track(name, props)
    amplitude.track(name, props)
  }
}
