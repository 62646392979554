import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import { DisplayConfig } from "../../../../utils/constants"
import {
  captureEvents,
  consoleError,
  formatAmount,
} from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import { EventName } from "../../../../utils/enums"
import ConvertEmiLoader_v3 from "../../../core/SkeletonLoader/ConvertEMILoader_v3"
import EmiDescriptionCard from "./Components/EmiDescriptionCard"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import EmiElement from "./Components/EmiElement"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import { useToast } from "../../../../nativeBaseReplacements/useToast"

const EMI_CONVERTED_ERROR_STRING = /is previously converted to EMI/

function EMIPlans_v3() {
  const { t } = useTranslation()
  const windowDim = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const theme = useSelector(state => state.theme)
  const common = useSelector(state => state.common)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const { isEmiPlansSet, txnState, emiPlansData, selectedEMIPlan } = common
  const [emiPlans, setEmiPlans] = useState([])
  const [error /*, setError */] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedPlan, setSelectedPlan] = useState(selectedEMIPlan || null)
  const [showProcessingFee, setShowProcessingFee] = useState(true)

  useEffect(() => {
    let data = {}
    captureEvents({ eventName: EventName.CONVERT_TO_EMI, metadata: data })
  }, [])

  useEffect(() => {
    ;(async () => {
      if (
        !isEmiPlansSet ||
        (location.state && location?.state?.txnId != txnState?.txnId)
      ) {
        dispatch(clearCommonState())
        dispatch(setCommonState({ txnState: location?.state }))

        await handleApiAuth({
          apiId: ApiIds.EMI_CONFIG,
          onAuthSuccess: onEmiConfigAuthSuccess(location?.state?.amount),
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: t("EmiElement.emiConfigReason"),
          mpinReason: t("EmiElement.emiConfigReason"),
          toast,
          navigate,
        })
      } else {
        let emi_plans = emiPlansData.emiPlans
        emi_plans.sort(sortByTenure)
        setEmiPlans(emi_plans)
        // setSelectedPlan(emiPlansData.emiPlans[0])
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmiPlansSet])

  useEffect(() => {
    emiPlans?.forEach((emi, index) => {
      if (
        index !== 0 &&
        emi?.processingFees !== emiPlans[index - 1]?.processingFees
      ) {
        setShowProcessingFee(false)
      }
    })
  }, [emiPlans])

  // changing to ascending order
  function sortByTenure(a, b) {
    return a.tenureInMonths - b.tenureInMonths
  }

  const onEmiConfigAuthSuccess = amount => async apiToken => {
    try {
      const response = await AccountService.getEMIConfig(
        apiToken,
        user.account.id,
        amount,
        location.state.txnId,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isEmiPlansSet: true,
            emiPlansData: result.data,
          }),
        )

        navigate("/ConvertToEMI", { replace: true })
      } else {
        consoleError(result?.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(t("EmiElement.emiTxnConvertedErrorToast"))
        } else {
          toast(t("EmiElement.errorToastMesg"))
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast(t("EmiElement.errorToastMesg"))
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const selectEmi = emi => {
    dispatch(setCommonState({ selectedEMIPlan: emi }))
    setSelectedPlan(emi)
  }

  const handleConfirmTenure = () => {
    navigate("/EMIOverview", {
      state: {
        selectedPlan,
      },
    })
  }

  return (
    <div
      className='v3-convertToEMI-container'
      style={{
        minHeight: windowDim.height,
        maxWidth: DisplayConfig.MAX_WIDTH,
      }}
    >
      {error ? (
        <ErrorScreen />
      ) : loading ? (
        <div>
          <ConvertEmiLoader_v3 />
        </div>
      ) : (
        <>
          <div className='v3-convertToEMI-wrapper'>
            <div className='v3-convertToEMI-header-wrapper'>
              <Header
                text={t("EmiElement.convertToEmiStep", {
                  topNo: "1",
                  bottomNo: "2",
                })}
                onBack={async () => {
                  window.history.go(-1)
                }}
              />
              {/* <div className='v3-convertToEMI-header-circularProgress'>
                Step 1 of 2
                <div>
                  <CircularProgressbar
                    value={"50"}
                    strokeWidth={15}
                    styles={buildStyles({
                      pathColor: theme.v3.rawColors.primary.color1,
                      trailColor: theme.v3.rawColors.tertiaryNeutral.color3,
                    })}
                  />
                </div>
              </div> */}
            </div>
            <EmiDescriptionCard />
            <div className='v3-convertToEMI-emi-plans'>
              <div className='v3-convertToEMI-emi-plans-title'>
                {t("EmiElement.selectTenure")}
              </div>
              {showProcessingFee && (
                <div className='v3-convertToEMI-emi-plans-processingFee'>
                  {t("EmiElement.processingFeeOf")}{" "}
                  <span>{formatAmount(emiPlans?.[0]?.processingFees)}</span>{" "}
                  {t("EmiElement.processingFeeAllPlans")}
                </div>
              )}
              <div className='v3-convertToEMI-emi-plans-container'>
                {emiPlans.map((element, index) => (
                  <div onClick={() => selectEmi(element)}>
                    <EmiElement
                      key={index}
                      emi={element}
                      isSelected={element?.emi === selectedPlan?.emi}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='v3-convertToEMI-confirmTenure-button'>
              <ColorButton
                style={{ width: "100%", margin: "auto" }}
                text={t("EmiElement.confirmTenure")}
                onPress={handleConfirmTenure}
                isDisable={!selectedPlan}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EMIPlans_v3
