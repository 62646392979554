import Lottie from "lottie-react"
import { useTranslation } from "react-i18next"
import React from "react"
import pwaLoader from "../svg/v3/pwaLoader.json"
import { useSelector } from "react-redux"
import { BrandingOnHeaders } from "../../utils/enums"
import { Header } from "../core/headers"
import hfLogo from "../../assets/images/v3/active/hfLogo.svg"
import { HeaderContentAlignment } from "../../utils/enums"
import { replaceColor } from "lottie-colorify"
import PwaRedirectionImageLoader from "./SkeletonLoader/PwaRedirectionImageLoader"

const PwaRedirectionLoader = ({ loaderAnimation }) => {
  const { t } = useTranslation()
  const screen = useSelector(state => state.screen)
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const headerConfig = screen?.pwaHeader

  return (
    <div
      className={`${loaderAnimation ? "v3-pwa-redirection-loader-animation" : ""}`}
    >
      {headerConfig?.primaryLogoUrl && <Header />}
      <div className={`v3-pwa-redirection-loader-container`}>
        <div className='v3-pwa-redirection-loader-section'>
          {session.isCssLoaded ? (
            <div
              className='v3-pwa-redirection-loader-image'
              style={{ display: "block" }}
            >
              <Lottie
                loop={true}
                animationData={replaceColor(
                  "#005aff",
                  theme.v3.rawColors.primary.color1,
                  pwaLoader,
                )}
              />
            </div>
          ) : (
            <div style={{ marginBottom: "26px" }}>
              <PwaRedirectionImageLoader />
            </div>
          )}
          <div className='v3-pwa-redirection-loader-text-section'>
            <div className='v3-pwa-redirection-loader-header-text'>
              {t("loader.redirectionLoaderText")}
            </div>
            <div className='v3-pwa-redirection-loader-header-text-description'>
              {t("loader.redirectionLoaderDescription")}
            </div>
          </div>
        </div>
        <div className='v3-pwa-redirection-loader-footer-section'>
          {(headerConfig?.brandingOnHeader === BrandingOnHeaders.SINGLE_LOGO ||
            headerConfig?.brandingOnHeader === BrandingOnHeaders.TWO_LOGO) && (
            <div className='v3-pwa-redirection-logos'>
              <div className={"v3-header-top-logo v3-header-top-logo-center"}>
                <img src={headerConfig?.primaryLogoUrl} alt='primary logo' />
                {headerConfig?.brandingOnHeader ===
                  BrandingOnHeaders.TWO_LOGO && (
                  <>
                    <div className='v3-header-top-logo-seperator'></div>
                    <img
                      src={headerConfig?.secondaryLogoUrl}
                      alt='secondary logo'
                      // width={"70px"}
                      // height={"24px"}
                    />
                  </>
                )}
              </div>
            </div>
            // <div className='v3-pwa-redirection-loader-branding-logo-section'>
            //   <img
            //     src={headerConfig?.primaryLogoUrl}
            //     width={"70px"}
            //     height={"24px"}
            //     alt='primary logo'
            //   />
            //   {headerConfig?.brandingOnHeader ===
            //     BrandingOnHeaders.TWO_LOGO && (
            //     <>
            //       {" "}
            //       |{" "}
            //       <img
            //         src={headerConfig?.secondaryLogoUrl}
            //         alt='secondary logo'
            //         width={"70px"}
            //         height={"24px"}
            //       />
            //     </>
            //   )}
            // </div>
          )}
          {headerConfig?.brandingOnHeader === BrandingOnHeaders.TEXT && (
            <div
              className={"v3-header-top-text v3-loader-header-text-center"}
              style={{ color: theme.v3.cssVars.tertiaryNeutral.color1 }}
            >
              {headerConfig?.textContent}
            </div>
          )}
          <div className='v3-pwa-redirection-loader-hyperface-logo'>
            {screen?.preLoader?.isHfBrandingEnabled &&
              headerConfig?.primaryLogoUrl &&
              headerConfig?.secondaryLogoUrl && (
                <div className='v3-aboutCard-hf-branding'>
                  <div className='v3-home-hf-branding-text'>Powered by</div>
                  <div className='v3-home-hf-branding-logo'>
                    <img src={hfLogo} />
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PwaRedirectionLoader
