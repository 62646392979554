const TokenisationLock = ({ color1 = "#1965CF", color2 = "#ACC8EE" }) => {
  return (
    <svg
      width='13'
      height='14'
      viewBox='0 0 13 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.8183 12.0909H1.90918V4.45455C1.90918 1.99882 3.908 0 6.36373 0C8.81945 0 10.8183 1.99882 10.8183 4.45455V12.0909ZM3.81827 10.1818H8.90918V4.45455C8.90918 3.05073 7.76754 1.90909 6.36373 1.90909C4.95991 1.90909 3.81827 3.05073 3.81827 4.45455V10.1818Z'
        fill={color1}
      />
      <path
        d='M10.8182 14H1.90909C0.854636 14 0 13.1454 0 12.0909V6.36364C0 5.30918 0.854636 4.45455 1.90909 4.45455H10.8182C11.8726 4.45455 12.7273 5.30918 12.7273 6.36364V12.0909C12.7273 13.1454 11.8726 14 10.8182 14Z'
        fill={color2}
      />
      <path
        d='M6.36337 10.1818C7.242 10.1818 7.95428 9.46954 7.95428 8.59091C7.95428 7.71227 7.242 7 6.36337 7C5.48474 7 4.77246 7.71227 4.77246 8.59091C4.77246 9.46954 5.48474 10.1818 6.36337 10.1818Z'
        fill={color1}
      />
      <path
        d='M6.36373 12.0909C5.83618 12.0909 5.40918 11.6639 5.40918 11.1364V8.75H7.31827V11.1364C7.31827 11.6639 6.89127 12.0909 6.36373 12.0909Z'
        fill={color1}
      />
    </svg>
  )
}

export default TokenisationLock
