import React from "react"
import { useSelector } from "react-redux"
import Divider from "../../../../core/Divider/v3/Divider"
import Cross from "../../../../svg/v3/Cross"
import Ellipse from "../../../../svg/v3/Ellipse"
import { BottomSheet } from "react-spring-bottom-sheet"

const CustomerSupportFaqBottomSheet = ({ isOpen, onClose, selectedFaq }) => {
  const [theme] = useSelector(state => [state.theme])

  const {
    tertiaryNeutral: { color1: tertiaryNeutralColor1 },
    primaryBase: { color2: primaryBaseColor2 },
  } = theme.v3.rawColors

  return (
    <BottomSheet open={isOpen} onDismiss={onClose}>
      <div className='bottom-sheet'>
        <div
          className='v3-customerSupport-faq-bottomSheet-closeButton'
          onClick={onClose}
        >
          <Cross />
        </div>
        <div className='v3-customerSupport-bottomSheet-ellipse-container'>
          <Ellipse
            className='v3-blockCardReason-bottomSheet-ellipse-svg'
            color1={selectedFaq?.backgroundColor}
          />
          {selectedFaq?.imageUrl ? (
            <img
              src={selectedFaq?.imageUrl}
              className='v3-customerSupport-bottomSheet-PrivacyIcon-svg'
              alt='lock'
            />
          ) : (
            <div
              className='v3-customerSupport-bottomSheet-PrivacyIcon-svg'
              style={{ transform: "translateX(-30%)" }}
            >
              {selectedFaq?.defaultImage}
            </div>
          )}
        </div>
        <div className='v3-customerSupport-bottomSheet'>
          <div
            className='v3-customerSupport-bottomSheet-header'
            style={{ color: tertiaryNeutralColor1 }}
          >
            <div className='v3-customerSupport-bottomSheet-title'>
              {selectedFaq?.question}
            </div>
          </div>
          <Divider />
          <div
            className='v3-customerSupport-bottomSheet-informationSafety'
            style={{ color: primaryBaseColor2 }}
          >
            {selectedFaq?.answer}
          </div>
        </div>
      </div>
    </BottomSheet>
  )
}

export default CustomerSupportFaqBottomSheet
