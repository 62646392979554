import moment from "moment"
import React, { useEffect, useState } from "react"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Croma from "../../../../../assets/images/v3/croma.svg"
import { formatAmount } from "../../../../../utils/functions"
import Divider from "../../../../core/Divider/v3/Divider"

const TransactionEmiV3 = props => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [paidCounts, setPaidCounts] = useState([])

  useEffect(() => {
    const paid = props.data.map(item => {
      let temp = 0
      for (let schedule of item.schedules) {
        if (schedule.interest === 0 && schedule.principal === 0) {
          temp = temp + 1
        }
      }
      return temp
    })
    setPaidCounts(paid)
  }, [])

  return (
    <div>
      {props?.data.map((element, index) => {
        return (
          <>
            <div className='v3-emi-transactionEmi-card'>
              <div>
                <div className='v3-emi-transactionEmi-card-logo-progressBar'>
                  <CircularProgressbar
                    value={paidCounts}
                    maxValue={element?.schedules?.length}
                    strokeWidth={6}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0,
                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "round",
                      // Text size
                      textSize: "15px",
                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,
                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',
                      // Colors
                      pathColor: `#2680EA`,
                      textColor: "#f88",
                      trailColor: "#DEDEDE",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                  <img
                    src={Croma}
                    alt='logo'
                    className='v3-emi-transactionEmi-logo'
                  />
                </div>
                <div className='v3-emi-TransactionEmi-textContent-left'>
                  <div>{element?.txnDescription}</div>
                  <div>
                    {`${formatAmount(element?.monthlyEmi)}`}
                    <span>/mon.</span>
                  </div>
                </div>
              </div>
              <div>
                <div>{`${paidCounts[index]}/${element?.schedules?.length} paid`}</div>
                <div>{`Ends on: ${moment(element?.paymentDueDate).format(
                  "MMM 'YY",
                )}`}</div>
              </div>
            </div>
            <Divider />
          </>
        )
      })}
    </div>
  )
}

export default TransactionEmiV3
