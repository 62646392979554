import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function LimitTypeWrapper() {
  return (
    <CardSkeleton
      margin='20px 0px 0px 10px'
      width='95%'
      height='100px'
      padding='15px'

    >
      <CardSkeleton
        display='flex'
        width='100%'
        justifyContent='space-between'
        height='30px'
      >
        <TextWrapperSkeleton width='150px' />
        <TextWrapperSkeleton width='35px' br='20px' height='18px' />
      </CardSkeleton>
      <TextWrapperSkeleton width='200px' />
      <TextWrapperSkeleton margin='20px 0px 0px 0px' width='100%' />
      <TextWrapperSkeleton
        margin='-15px 0px 0px 95%'
        br='50%'
        width='20px'
        height='20px'
      />
    </CardSkeleton>
  )
}

function CardControlsLoader() {
  return (
    <>
      <CardSkeleton
        br='0px 0px 0px 0px'
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        height="56px"
      >
        <TextWrapperSkeleton height='10px' width='200px' />
      </CardSkeleton>

      <CardSkeleton
        width='90%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        margin='20px auto'
      >
        <TextWrapperSkeleton margin='auto 20px' height='10px' width='160px' />
        <TextWrapperSkeleton
          margin='auto 20px'
          width='35px'
          br='20px'
          height='18px'
        />
      </CardSkeleton>

      <CardSkeleton
        margin='20px auto'
        width='90%'
        display='flex'
        justifyContent='space-between'
      >
        <CardSkeleton width='97%' br='6px 0px 0px 0px' height='50px'>
          <TextWrapperSkeleton height='10px' margin='20px auto' width='150px' />
        </CardSkeleton>
        <CardSkeleton width='97%' br='0px 6px 0px 0px' height='50px'>
          <TextWrapperSkeleton height='10px' margin='20px auto' width='150px' />
        </CardSkeleton>
      </CardSkeleton>

      <CardSkeleton margin='-12px auto' width='90%' height='510px'>
        <LimitTypeWrapper />
        <LimitTypeWrapper />
        <LimitTypeWrapper />
        <LimitTypeWrapper />
      </CardSkeleton>
    </>
  )
}

export default CardControlsLoader
