import { Fragment } from "react"
import { toast } from "../core/Toast"

const nativeToastReplacement = {
  show: ({ render, duration }) => {
    return toast(
      <Fragment>
        <style
          dangerouslySetInnerHTML={{
            __html: /* css */ `
            .hf-toast {
              border-radius: 32px;
              margin: 12px;
              margin-bottom: 32px;
            }
          `,
          }}
        />
        {render()}
      </Fragment>,
      {
        autoClose: duration,
      },
    )
  },
}

export const useToast = () => {
  return nativeToastReplacement
}
