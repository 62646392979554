import { BottomSheet } from "react-spring-bottom-sheet"
import crossIcon from "../../../../assets/images/v3/closeBtn.svg"
import DividerV3 from "../../Divider/v3/DividerV3"
import { ColorButton } from "../../buttons"
import { nudgeVariants } from "../../../../utils/enums"

const BottomDrawerNudges = ({
  isOpen,
  onClose,
  drawerNudge,
  setShowDrawerNudgesV3,
}) => {
  let obj = JSON.parse(drawerNudge?.displayOptions?.nudgeDetails)

  const handleVisibleNudges = () => {
    if (drawerNudge?.displayOptions?.requireType === nudgeVariants.OPTIONAL) {
      localStorage.setItem("showNudgesAgain", false)
    }
  }

  const handleBtn = () => {
    window.open(obj.redirectionUrl, "_blank")
  }
  return (
    <div style={{ maxWidth: "500px" }}>
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <div
          className='v3-bottomDrawer-nudges-container'
          style={{ backgroundColor: obj.backgroundColor, borderRadius: "16px" }}
        >
          <div className='v3-bottomDrawer-nudges-header'>
            <img
              className='v3-bottomDrawer-nudges-img'
              src={obj.illustrationUrl}
              width={"100%"}
            />
            <span
              className='v3-bottomDrawer-nudges-close-btn'
              // onClick={onClose}
              onClick={() => {
                setShowDrawerNudgesV3(false)
                handleVisibleNudges()
              }}
            >
              <img src={crossIcon} />
            </span>
          </div>
          <div className='v3-bottomDrawer-nudges-title'>{obj.title}</div>
          <div className='v3-bottomDrawer-nudges-description'>
            {obj.description}
          </div>
        </div>
        <DividerV3 />
        <div className='v3-bottomDrawer-nudges-btn'>
          <ColorButton text={"Click me"} onPress={handleBtn} />
        </div>
      </BottomSheet>
    </div>
  )
}

export default BottomDrawerNudges
