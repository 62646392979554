import React, { useContext } from "react"
import moment from "moment"
import AccountService from "../../../services/AccountService"
import { BottomSheetType } from "../../../utils/enums"
import { useTranslation } from "react-i18next"
import { benefitType, ErrorType, offerType } from "../../../utils/constants"
import { useSelector } from "react-redux"
import { useBottomSheet } from "../../auth/BottomSheetContext"
import { ErrorContext } from "../../auth/ErrorScreenContext"
import { setCampaignBenefits } from "../../../store/actions/Benefits"
import { consoleError } from "../../../utils/functions"

export const useSubscribeOffer = () => {
  const { t } = useTranslation()
  const { setErrorState } = useContext(ErrorContext)
  const user = useSelector(state => state.user)
  const benefits = useSelector(state => state.benefits)
  const bottomSheet = useBottomSheet()

  const subscribeOffer = async (benefitId, setIsOptedIn, type) => {
    try {
      const response = await AccountService.subscribeBenefit({
        benefitId: benefitId,
        accountId: user?.account?.id,
        startDate: moment(new Date()).format("YYYY-MM-DD"),
      })
      const result = response.data

      if (result?.success) {
        bottomSheet.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("offers.offerOptInSuccessText"),
          "",
        )
        let updatedBenefits
        switch (type) {
          case offerType.MERCHANT_OFFERS: {
            updatedBenefits = benefits?.merchantBenefits?.map(benefit => {
              if (benefit?.benefitId === benefitId) {
                benefit.isAccountOptedIn = true
              }
            })
          }
          case offerType.CAMPAIGN_OFFERS: {
            updatedBenefits = benefits?.campaignBenefits?.map(benefit => {
              if (benefit?.benefitId === benefitId) {
                benefit.isAccountOptedIn = true
              }
            })
          }
          case offerType.PROGRAM_OFFERS: {
            updatedBenefits = benefits?.cvpBenefits?.map(benefit => {
              if (benefit?.benefitId === benefitId) {
                benefit.isAccountOptedIn = true
              }
            })
          }

          default: {
            updatedBenefits = benefits?.cvpBenefits?.map(benefit => {
              if (benefit?.benefitId === benefitId) {
                benefit.isAccountOptedIn = true
              }
            })
          }
        }
        setCampaignBenefits(updatedBenefits)
        setIsOptedIn(true)
      } else {
        consoleError(result?.errors)
        setErrorState(response?.status)
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR)
      } else {
        consoleError(error)
        setErrorState(ErrorType.INTERNAL_ERROR)
        bottomSheet.openBottomSheet(
          BottomSheetType.FAILURE,
          t("offers.bottomSheetFailureMsg"),
          "",
          null,
          null,
          null,
          false,
        )
      }
    }
  }
  return { subscribeOffer }
}
