import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { ColorButton } from "../../core/buttons"
import { CardOrientation, HEADER_HEIGHT_IN_PX } from "../../../utils/constants"
import { Header } from "../../core"
import { captureEvents, goToRedirectUrl } from "../../../utils/functions"
import { showCancelButton } from "../../../utils/auth"
import { EventName, PwaVersions } from "../../../utils/enums"
import { ScrollView } from "../../../nativeBaseReplacements"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const ForgetMpinIntro = ({ setMpinConfig, onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"

  useEffect(() => {
    captureEvents({ eventName: EventName.WELCOME_PAGE, metadata: {} })
  }, [])

  return (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <ScrollView>
        <Header
          text={`Reset ${mpinWord}`}
          onBack={async () => {
            showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
          }}
        />

        <div
          className='mpinIntro'
          style={{
            minHeight: windowDimensions.height - HEADER_HEIGHT_IN_PX - 20,
          }}
        >
          <div className='mpinIntro-container'>
            {setMpinConfig.introTitle1Subsequent && (
              <div
                className='mpinIntro-title-1'
                style={{ color: theme.appTextColor }}
              >
                {setMpinConfig.introTitle1Subsequent}
              </div>
            )}
            {setMpinConfig.introTitle2Subsequent && (
              <div
                className='mpinIntro-title-2'
                style={{ color: theme.appTextColor }}
              >
                {setMpinConfig.introTitle2Subsequent}
              </div>
            )}
            {setMpinConfig.introImageSubsequentUrl &&
              (theme.cardOrientation === CardOrientation.VERTICAL ? (
                <img
                  className='mpinIntro-img-vertical'
                  src={setMpinConfig.introImageSubsequentUrl}
                  alt='Intro image'
                />
              ) : (
                <img
                  className='mpinIntro-img-horizontal'
                  src={setMpinConfig.introImageSubsequentUrl}
                  alt='Intro image'
                />
              ))}
            {setMpinConfig.introParagraph1Subsequent && (
              <div
                className='mpinIntro-introPara-1'
                style={{ color: theme.appTextColor }}
              >
                {setMpinConfig.introParagraph1Subsequent}
              </div>
            )}
            {setMpinConfig.introParagraph2Subsequent && (
              <div
                className='mpinIntro-introPara-2'
                style={{ color: theme.appTextColor }}
              >
                {setMpinConfig.introParagraph2Subsequent}
              </div>
            )}
            {setMpinConfig.introStepsSubsequent && (
              <div className='mpinIntro-introSteps-container'>
                {setMpinConfig.introStepsSubsequent
                  ?.split("\r\n")
                  .map((point, index) => (
                    <div
                      className='mpinIntro-introStep'
                      style={{ color: theme.appTextColor }}
                    >
                      {index + 1}. {point}
                    </div>
                  ))}
              </div>
            )}
          </div>
          {/* <View display='flex' flex='1' flexDirection='column'></View> */}
          <div className='mpinIntro-cta-container'>
            <div className='mpinIntro-cta'>
              <ColorButton
                mx='auto'
                text={`Reset ${mpinWord}`}
                onPress={async () => onSubmit()}
              />
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  )
}

export default ForgetMpinIntro
