import React from "react"

const ChannelsIcon = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      style={{ width: "-webkit-fill-available" }}
    >
      <path
        d='M17.834 7.25C16.242 7.25 14.8381 8.044 13.9761 9.25H5.83398C5.09198 9.25 4.3999 9.54 3.8999 10.052C3.3739 10.565 3.08398 11.257 3.08398 12C3.08398 13.517 4.31698 14.75 5.83398 14.75H13.9761C14.8381 15.956 16.242 16.75 17.834 16.75C20.453 16.75 22.584 14.619 22.584 12C22.584 9.381 20.453 7.25 17.834 7.25ZM5.83398 13.25C5.14498 13.25 4.58398 12.689 4.58398 12C4.58398 11.665 4.71294 11.355 4.96094 11.113C5.18994 10.879 5.49898 10.75 5.83398 10.75H13.272C13.162 11.151 13.084 11.565 13.084 12C13.084 12.436 13.162 12.849 13.272 13.25H5.83398ZM17.834 15.25C16.042 15.25 14.584 13.792 14.584 12C14.584 10.208 16.042 8.75 17.834 8.75C19.626 8.75 21.084 10.208 21.084 12C21.084 13.792 19.626 15.25 17.834 15.25Z'
        fill={color || "#2680EA"}
      />
    </svg>
  )
}

export default ChannelsIcon
