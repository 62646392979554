import React, { Fragment } from "react"
import { getNativeBaseSpaceToPx } from "../utils/functions"

const customCircleIconReplacement = {
  render: ({ size, color }) => {
    const width = getNativeBaseSpaceToPx(size)
    const height = getNativeBaseSpaceToPx(size)

    return (
      <svg
        viewBox='0 0 24 24'
        stroke=''
        role='img'
        style={{ color, height, width }}
      >
        <g>
          <path
            fill='currentColor'
            d='M 12, 12 m -9, 0 a 9,9 0 1,0 18,0 a 9,9 0 1,0 -18,0'
            stroke=''
          ></path>
        </g>
      </svg>
    )
  },
}

export const CircleIcon = customCircleIconReplacement.render
