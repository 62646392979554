import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import Rewards_v2 from "./v2/rewards_v2"
import Rewards_V3 from "./v3/Rewards_V3"

const Rewards = () => {
  const config = useSelector(state => state.config)
  const getVersioned = version => {
    switch (version) {
      case PwaVersions.V2:
        return <Rewards_v2 />
      case PwaVersions.V2_1:
        return <Rewards_v2 />
      case PwaVersions.V3:
        return <Rewards_V3 />
      default:
        return <></>
    }
  }
  return getVersioned(config?.version)
}

export default Rewards
