import React from "react"
import { useSelector } from "react-redux"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"
import { DisplayConfig } from "../../../utils/constants.js"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb.js"

function PlansWrapper() {
  return (
    <CardSkeleton
      margin='12px auto'
      width='90%'
      height='80px'
      padding='16px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      br='12px'
    >
      <CardSkeleton width='30%' bg='none'>
        <TextWrapperSkeleton width='100%' />
      </CardSkeleton>
      <CardSkeleton
        width='30%'
        bg='none'
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        alignItems='flex-end'
      >
        <TextWrapperSkeleton width='90%' />
        <TextWrapperSkeleton margin='10px 0px' width='70%' />
      </CardSkeleton>
    </CardSkeleton>
  )
}

function ConvertEmiLoader_v3() {
  const theme = useSelector(state => state.theme)
  const windowDim = useWindowDimensions()
  return (
    <div
      style={{
        minHeight: windowDim.height,
        backgroundColor: theme.backgroundColor,
        maxWidth: DisplayConfig.MAX_WIDTH,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        {/* Header */}
        <CardSkeleton
          width='100%'
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          br='0'
          height='50px'
        >
          <TextWrapperSkeleton
            height='10px'
            width='100px'
            margin='auto auto auto 16px'
          />
        </CardSkeleton>
        {/* Header-2 */}
        <CardSkeleton
          width='100%'
          display='flex'
          justifyContent='space-betwenn'
          alignItems='center'
          br='0'
          height='50px'
          margin='1px auto auto auto'
        >
          <TextWrapperSkeleton
            height='10px'
            width='100px'
            margin='auto auto auto 16px'
          />
          <TextWrapperSkeleton
            height='10px'
            width='80px'
            margin='auto 16px auto auto'
          />
        </CardSkeleton>
        {/* Transaction */}
        <CardSkeleton
          width='100%'
          bg={theme.backgroundColor}
          alignItems='center'
          height='80px'
          justifyContent='space-between'
          display='flex'
        >
          <CardSkeleton
            width='200px'
            height='100%'
            bg={theme.backgroundColor}
            display='flex'
            alignItems='center'
          >
            <CardSkeleton width='50px' height='50px' br='50%' margin='16px' />
            <CardSkeleton
              width='100px'
              margin='16px 16px 16px 0px'
              bg={theme.backgroundColor}
            >
              <CardSkeleton
                width='80px'
                height='10px'
                margin='0px 0px 8px 0px'
              />
              <CardSkeleton width='40px' height='10px' />
            </CardSkeleton>
          </CardSkeleton>
          <CardSkeleton width='100px' height='100%' bg={theme.backgroundColor}>
            <CardSkeleton
              height='10px'
              width='80px'
              margin='18px 16px auto auto'
            />
          </CardSkeleton>
        </CardSkeleton>
        {/* Text */}
        <CardSkeleton
          height='10px'
          margin='20px auto auto 16px'
          width='100px'
        />
        <CardSkeleton
          height='10px'
          margin='10px auto 24px 16px'
          width='200px'
        />
        <PlansWrapper />
        <PlansWrapper />
        <PlansWrapper />
      </div>
      <CardSkeleton
        height='40px'
        margin='24px auto'
        width='80%'
        position='absolute'
      />
    </div>
  )
}

export default ConvertEmiLoader_v3
