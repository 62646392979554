import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import rewardSummaryBanner from "../../../assets/images/rewardSummaryBanner.svg"
import rewardsEmptyImage from "../../../assets/images/rewarTxnEmty.svg"
import AccountService from "../../../services/AccountService"
import { DisplayConfig, SsoCategory, SsoType } from "../../../utils/constants"
import { EventName } from "../../../utils/enums"
import {
  captureEvents,
  consoleError,
  formatAmount,
  lrSsoRedirection,
  resetErrorTryAgainCount,
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton } from "../../core/buttons"
import CustomLoader from "../../core/loader"
import ViewAllTransactionsLoader from "../../core/SkeletonLoader/ViewAllTransactionLoader"
import { showToast1 } from "../../core/toast"
import DateSelect from "../../Statement/fetchAll/DateSelect/DateSelect"
import Settings from "../../svg/settings"
import Transactions from "./Transactions"
import FlatList from "../../../nativeBaseReplacements/FlatList"
import View from "../../../nativeBaseReplacements/View"
import Text from "../../../nativeBaseReplacements/Text"
import { useToast } from "../../../nativeBaseReplacements/useToast"
import { Image } from "../../../nativeBaseReplacements"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"
import CommonService from "../../../services/CommonService"
import { setAggregateRewardsSummary } from "../../../store/actions/User"

const initStartDate = moment().subtract(12, "months").format("YYYY-MM-DD")
const initCurrentDate = moment().format("YYYY-MM-DD")
const initTransactions = [
  { type: "TITLE", date: moment().format("YYYY-MM-DD") },
]

const initOffset = 0
const transactionCount = 10

const Rewards_v2 = () => {
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const isTransactionsLoadingRef = useRef(false)
  const [apiError, setApiError] = useState(0)
  const [error, setError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [redirectionLoading, setRedirectionLoading] = useState(false)

  const [transactions, setTransactions] = useState(initTransactions)
  const [ssoRewardsLoaded, setSsoRewardLoaded] = useState(false)
  const offset = useRef(initOffset)
  // date till which txns should be loaded
  const startDate = useRef(initStartDate)
  // pointer to current month being fetched
  const currentMonth = useRef(initCurrentDate)

  const [currYear, setCurrYear] = useState(moment().year())
  const [currMonth, setCurrMonth] = useState(moment().format("MMM"))

  const [selectedYear, setSelecteYear] = useState()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()
  const windowDimensions = useWindowDimensions()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const preferences = useSelector(state => state.preferences)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)

  const rewardPoints = user.aggregate?.currentValue
  const hfCashback = user.hfCashback?.earnedAmount
  const hfCashbackPending = user.hfCashback?.pendingAmount
  const hfRewardPoints = user?.aggregate?.available
  const ssoRewardPoints = user?.rewardSummary?.currentValue || 0
  const pendingStateText = config?.jsonConfig?.rewards?.homePendingStateText

  const account = user.account
  const [isFetchingRewards, setIsFetchingRewards] = useState(
    user?.rewardSummary?.currentValue === undefined ||
      user?.rewardSummary?.currentValue === null,
  )
  const card = user.cards?.[0]
  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }
  const [accountCreatedDate, setAccountCreatedDate] = useState(
    account?.dateCreated,
  )
  const [lrCheck, setLrCheck] = useState(false)

  const [lrDate, setLrDate] = useState("15-" + currMonth + "-" + currYear)

  const numOfYear =
    moment().format("YYYY") - moment(accountCreatedDate).format("YYYY") + 1
  const getAggregateSummary = async () => {
    try {
      const rewardSummaryResponse = await AccountService.getAggregateSummary({
        accountId: user.account?.id,
        tag: "REWARDS",
      })
      if (rewardSummaryResponse.data.success) {
        if (rewardSummaryResponse.data?.data?.count > 0) {
          dispatch(
            setAggregateRewardsSummary({
              rewardSummary: rewardSummaryResponse.data?.data?.aggregates[0],
            }),
          )
        }
      }
      setIsFetchingRewards(false)
    } catch (err) {
      consoleError(err)
      setIsFetchingRewards(false)
    }
  }
  useEffect(() => {
    if (
      user?.rewardSummary?.currentValue === undefined ||
      user?.rewardSummary?.currentValue === null
    ) {
      getAggregateSummary()
    }
  }, [user?.rewardSummary?.currentValue])
  const lr =
    Boolean(screen?.home?.reward?.redirectionType !== "LR_TRANSACTIONS") ||
    Boolean(!user?.rewardSummary?.currentValue)

  useEffect(() => {
    let data = {
      pointBalance:
        Number(user?.rewardSummary?.currentValue) ||
        Number(user?.rewardSummary?.metadata?.balance) ||
        0,
      totalRedeemed: user?.rewardSummary?.metadata?.redeemed
        ? Number(user?.rewardSummary?.metadata?.redeemed)
        : 0,
      totalRewards: user?.rewardSummary?.metadata?.balance
        ? Number(user?.rewardSummary?.metadata?.balance) +
          Number(user?.rewardSummary?.metadata?.redeemed)
        : 0,
    }
    captureEvents({ eventName: EventName.REWARDS, metadata: data })
  }, [])

  useEffect(() => {
    if (apiError >= 3) {
      captureEvents({
        eventName: EventName.REWARDS_ERROR,
        metadata: { msg: "Error fetching Transactions" },
      })
      toast("Error fetching Transactions")
    }

    if (!user.hfCashback && !user.aggregate && lr && !isFetchingRewards) {
      captureEvents({
        eventName: EventName.REWARDS_ERROR,
        metadata: {
          msg: "Uh-oh! Couldn’t fetch your rewards \nhistory Please try again later",
        },
      })
      navigate("/Rewards/error", {
        state: {
          text1: "Unable to Fetch Rewards, Please",
          text2: "try again later",
          headerTitle: "Rewards",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    if (lrDate != "" && lrDate != "FOUND") {
      getTransactions()
    }
  }, [lrDate])

  const payLoadRef = useRef("")
  setTimeout(() => {
    var element = document.querySelector('[aria-label="llllll"]')
    if (element?.childNodes[0]?.childNodes[0]) {
      element.childNodes[0].childNodes[0].style.zIndex = 1
    }
  }, 100)

  useEffect(() => {
    setIsLoading(true)
  }, [currMonth, currYear])

  const getTransactions = async () => {
    try {
      let date = "15-" + currMonth + "-" + currYear

      // get txns for current month with the set offset
      let response
      if (screen?.home?.reward?.redirectionType === "HF_CASHBACK") {
        let payload = {
          count: transactionCount,
          offset: offset.current,
          from: moment(date).startOf("month").format("YYYY-MM-DD"),
          to: moment(date).endOf("month").format("YYYY-MM-DD"),
          sortBy: "TRANSACTION_DATE",
        }
        if (JSON.stringify(payload) === JSON.stringify(payLoadRef.current)) {
          return false
        }
        payLoadRef.current = payload
        response = await AccountService.getCashbacks(user.account.id, payload)
      } else if (screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS") {
        if (lrDate != "" && lrDate != "FOUND") {
          date = lrDate
        }
        let payload = {
          id: user.account.id,
          tag: "REWARDS",
          accountId: user.account.id,
          page: 1,
          startDate: moment(date).startOf("month").format("YYYY-MM-DD"),
          endDate: moment(date).endOf("month").format("YYYY-MM-DD"),
        }
        if (JSON.stringify(payload) === JSON.stringify(payLoadRef.current)) {
          return false
        }
        payLoadRef.current = payload
        response = await AccountService.getSsoRewards(payload)
      } else {
        let payload = {
          count: transactionCount,
          offset: offset.current,
          from: moment(date).startOf("month").format("YYYY-MM-DD"),
          to: moment(date).endOf("month").format("YYYY-MM-DD"),
        }
        if (JSON.stringify(payload) === JSON.stringify(payLoadRef.current)) {
          return false
        }
        payLoadRef.current = payload
        response = await AccountService.getRewards(user.account.id, payload)
      }

      const result = response.data
      setIsLoading(false)

      // if api call successful
      if (result?.success) {
        if (screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS") {
          if (result.data.totalCount !== 0 || result.data.count) {
            setSsoRewardLoaded(true)
            if (result.data.page == 1) {
              if (result?.data?.count == 0 && lrDate != "FOUND") {
                let newDate = moment(lrDate)
                  .subtract(1, "months")
                  .format("DD-MMM-YYYY")
                if (
                  moment(accountCreatedDate).format("YYYY-MM") >=
                    moment(lrDate).format("YYYY-MM") &&
                  lrDate != "FOUND"
                ) {
                  setLrCheck(true)
                  setLrDate("FOUND")
                } else {
                  setLrDate(newDate)
                  setCurrMonth(moment(newDate).format("MMM"))
                  setCurrYear(moment(newDate).format("YYYY"))
                }
              } else if (lrDate != "FOUND") {
                setLrDate("FOUND")
              }
              setTransactions(transactions => [
                ...initTransactions,
                ...result.data.data,
              ])
            } else {
              setTransactions(transactions => [
                ...transactions,
                ...result.data.data,
              ])
            }

            let metadata = {
              month: currMonth,
              year: currYear,
              count: result.data.totalCount || result.data.count,
            }
            captureEvents({
              eventName: EventName.REWARDS_HISTORY,
              metadata: metadata,
            })
            return false
          }
        } else {
          setTransactions(transactions => [...initTransactions, ...result.data])
        }
        let metadata = {
          month: currMonth,
          year: currYear,
          count: result.data.totalCount || result.data.count,
        }
        captureEvents({
          eventName: EventName.REWARDS_HISTORY,
          metadata: metadata,
        })
        // if current month has more txns pending, stay in this month
        if (result.data.hasMore || result.data?.hasNextPage) {
          // increment offset, next call happens for current month with this offset
          offset.current += transactionCount
        } else {
          // else move current month pointer to next month (chronologically previous month)

          // get next month's value
          // const nextMonthToFetch = moment(currentMonth.current)
          //   .subtract(1, "month")
          //   .format("YYYY-MM-DD")

          // move pointer to next month
          // currentMonth.current = nextMonthToFetch
          // reset offset
          offset.current = 0
        }
      } else {
        setApiError(apiError + 1)
      }
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)

    // api is giving all transactions at once irrespective of date, so not running this function.
    if (ssoRewardsLoaded) {
      return false
    }
    if (
      moment(currentMonth.current).format("YYYY-MM") >=
        moment(startDate.current).format("YYYY-MM") &&
      !isTransactionsLoadingRef.current &&
      apiError < 3 &&
      screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS"
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns
      await getTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    } else if (
      moment(currentMonth.current).format("YYYY-MM") <
        moment(startDate.current).format("YYYY-MM") &&
      transactions.length === 1
    ) {
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <View w='100%' alignItems='center' justifyContent='center'>
        <CustomLoader color={theme.color1} width={20} height={20} />
      </View>
    ) : null
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <View zIndex={"39"}>
            <View zIndex={"123"} mb='14px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='700'
                fontSize='18px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                  ? config?.jsonConfig?.rewards?.transactionTitle ||
                    "Cashbacks History"
                  : config?.jsonConfig?.rewards?.transactionTitle ||
                    "Rewards History"}
              </Text>
              <View width={"100%"} zIndex={"93"}>
                <DateSelect
                  key={lrCheck}
                  numOfYear={numOfYear}
                  monthOffset={0}
                  notBtn={true}
                  onViewStatementsClick={getTransactions}
                  setCurrMonth={setCurrMonth}
                  setCurrYear={setCurrYear}
                  fromRewards={true}
                  defaultMonth={currMonth}
                  monthIndex={moment().format("MMMM")}
                  selectYear={currYear}
                />
              </View>
            </View>
          </View>
        )

      case "DATE":
        return <View zIndex={12} marginTop={30} marginLeft={6}></View>

      case "EMPTY":
        return (
          <View>
            <View mt='32px' alignSelf={"center"}>
              <Image
                source={{
                  uri: rewardsEmptyImage,
                }}
                width='192px'
                height='183px'
                alt='Alternate Text '
              />
            </View>
            <View alignSelf={"center"} mt='16px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                Start shopping to earn rewards!
              </Text>
            </View>
          </View>
        )

      default:
        return (
          <View>
            <Transactions
              fromRewards={true}
              transaction={item}
              theme={theme}
              type={screen?.home?.reward?.redirectionType}
              dateFormat={preferences.dateFormat}
              timeFormat={preferences.timeFormat}
              isLast={index === transactions.length - 1}
            />
          </View>
        )
    }
  }

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
    startDate.current = initStartDate
    currentMonth.current = initCurrentDate
  }
  const lrRedirection = () => {
    lrSsoRedirection(
      {
        accountId: user.account.id,
        category: SsoCategory.REWARDS_REDEMPTION,
        sso: SsoType.REQUIRED,
      },
      config?.jsonConfig?.rewards?.redeemUrl,
      setRedirectionLoading,
    )
  }

  return error ? (
    <View display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <View m='0' w={"100%"}>
        <Header
          marginBottom='0px'
          text={config?.jsonConfig?.rewards?.pageHeader || "Rewards"}
          onBack={() => navigate("/")}
          rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
          rightProp={<Settings />}
        />
      </View>
      <View
        borderRadius={"8px"}
        backgroundColor={theme.widgetBackgroundColor}
        borderWidth={"1px"}
        borderColor='#ECECEC'
        shadow={2}
        w='90%'
        padding='24px'
        mt='24px'
      >
        <View mt='32px' zIndex={"-1"} alignSelf={"center"}>
          <Image
            source={{
              uri:
                config?.jsonConfig?.rewards?.transactionNullStateImage ||
                rewardsEmptyImage,
            }}
            width='192px'
            height='183px'
            alt='Alternate Text '
          />
        </View>
        <View alignSelf={"center"} mt='16px' zIndex={-1}>
          <Text
            fontFamily={theme.fontFamily}
            fontWeight='600'
            fontSize='14px'
            lineHeight='18px'
            letterSpacing='0.02em'
            color={theme.appTextColor}
          >
            {config?.jsonConfig?.rewards?.rewardsNullStateText ||
              "There are no rewards here yet, start transacting to earn rewards!"}
          </Text>
        </View>
      </View>
    </View>
  ) : (
    <View>
      <View m='0'>
        <Header
          marginBottom='0px'
          text={config?.jsonConfig?.rewards?.pageHeader || "Rewards"}
          onBack={() => navigate("/")}
          rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
          rightProp={<Settings />}
        />
      </View>
      <View
        alignItems={"center"}
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        minH={windowDimensions.height}
        backgroundColor={theme.backgroundColor}
      >
        <View
          borderRadius={"8px"}
          backgroundColor={theme.widgetBackgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          shadow={2}
          w='90%'
          padding='24px'
          mt='24px'
        >
          <View
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <View justifyContent={"center"}>
              <View>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='14px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={`${theme.appTextColor}:alpha.70`}
                >
                  {config?.jsonConfig?.rewards?.heroTitle
                    ? config?.jsonConfig?.rewards?.heroTitle
                    : screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                      ? "Total Available Cashbacks"
                      : "Total Available Rewards"}
                </Text>
              </View>
              <View mt='12px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='28px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                    ? formatAmount(hfCashback)
                    : screen?.home?.reward?.redirectionType === "HF_REWARDS"
                      ? Number(hfRewardPoints)
                      : screen?.home?.reward?.redirectionType ===
                          "LR_TRANSACTIONS"
                        ? Number(user?.rewardSummary?.currentValue || 0)
                        : rewardPoints}
                </Text>
              </View>
              {
                <div
                  style={{ color: theme.appTextColor }}
                  className='rewards-earned-redeemed-container'
                >
                  {config?.jsonConfig?.rewards?.showTotalEarned && (
                    <div
                      className='rewards-earned-text'
                      style={{
                        color: `${theme.appTextColor}90`,
                        marginRight: "12px",
                      }}
                    >
                      Total Earned
                      <div
                        className='rewards-earned-amount'
                        style={{ color: `${theme.appTextColor}` }}
                      >
                        {screen?.home?.reward?.redirectionType === "HF_REWARDS"
                          ? user?.aggregate?.accrued
                          : user?.rewardSummary?.metadata?.balance
                            ? Number(user?.rewardSummary?.metadata?.balance) +
                              Number(user?.rewardSummary?.metadata?.redeemed)
                            : 0}
                      </div>
                    </div>
                  )}
                  {config?.jsonConfig?.rewards?.showTotalRedeemed && (
                    <div
                      className='rewards-redeem-text'
                      style={{ color: `${theme.appTextColor}90` }}
                    >
                      Total Redeemed
                      <div
                        className='rewards-redeem-amount'
                        style={{ color: `${theme.appTextColor}` }}
                      >
                        {screen?.home?.reward?.redirectionType === "HF_REWARDS"
                          ? user?.aggregate?.redeemed
                          : user?.rewardSummary?.metadata?.redeemed
                            ? Number(user?.rewardSummary?.metadata?.redeemed)
                            : 0}
                      </div>
                    </div>
                  )}
                </div>
              }
            </View>
            <View>
              <Image
                source={{
                  uri:
                    config?.jsonConfig?.rewards?.heroImage ||
                    rewardSummaryBanner,
                }}
                width='70px'
                height='70px'
                alt='Alternate Text '
              />
            </View>
          </View>

          {
            <>
              {config?.jsonConfig?.rewards?.showRedeemCTA && (
                <View mt={"24px"} alignItems='center' width={"100%"}>
                  <ColorButton
                    width={"100%"}
                    text={
                      config?.jsonConfig?.rewards?.redeemCTAText ||
                      "Redeem Rewards"
                    }
                    isDisable={user?.cards[0]?.isHotlisted}
                    onPress={() => {
                      let data = {
                        pointBalance:
                          Number(user?.rewardSummary?.currentValue) ||
                          Number(user?.rewardSummary?.metadata?.balance) ||
                          0,
                        totalRedeemed: user?.rewardSummary?.metadata?.redeemed
                          ? Number(user?.rewardSummary?.metadata?.redeemed)
                          : 0,
                        totalRewards: user?.rewardSummary?.metadata?.balance
                          ? Number(user?.rewardSummary?.metadata?.balance) +
                            Number(user?.rewardSummary?.metadata?.redeemed)
                          : 0,
                      }
                      captureEvents({
                        eventName: EventName.REWARDS_REDEEM_CTA,
                        metadata: data,
                      })
                      lrRedirection()
                    }}
                    isLoading={redirectionLoading}
                  />
                </View>
              )}

              {screen?.home?.reward?.redirectionType === "HF_CASHBACK" && (
                <>
                  <div
                    className='rewards-v2-pending-cashback-text'
                    style={{ color: theme.appTextColor }}
                  >
                    {pendingStateText || "Pending Cashback"}
                  </div>
                  <div className='rewards-v2-pending-cashback-amount'>
                    {formatAmount(hfCashbackPending || 0)}
                  </div>
                </>
              )}
              {config?.jsonConfig?.rewards?.showNote && (
                <div className='rewards-v2_1-note'>
                  {config?.jsonConfig?.rewards?.noteDesc ||
                    "Note - It usually takes up to 5 days to process your reward points"}
                  .
                </div>
              )}
            </>
          }
        </View>
        {/* {screen?.home?.reward?.redirectionType !== "LR_TRANSACTIONS" &&
          screen?.home?.reward?.redirectionType !== "HF_CASHBACK" && (
            <View
              borderRadius={"8px"}
              backgroundColor={theme.widgetBackgroundColor}
              borderWidth={"1px"}
              borderColor='#ECECEC'
              shadow={2}
              w='90%'
              mt='12px'
            >
              <View
                backgroundColor={theme.color1}
                p='2px'
                alignSelf={"flex-end"}
                textAlign='center'
                w='14%'
                mr='10%'
                borderBottomRadius={"4px"}
              >
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.color2}
                >
                  Note
                </Text>
              </View>
              <View w='75%' mx='12px' pb='8px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='500'
                  fontSize='12px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  Reward processing is not instantaneous and the points
                  displayed on the Rewards Portal reflect the current status
                </Text>
              </View>
            </View>
          )} */}
        <View
          borderRadius={"8px"}
          backgroundColor={theme.widgetBackgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          shadow={2}
          w='90%'
          px='16px'
          py='24px'
          mt='24px'
          zIndex={"76"}
        >
          {transactions.length > 1 ? (
            <FlatList
              key={`${currMonth}-${currYear}`}
              ariaLabel='llllll'
              data={transactions}
              renderItem={renderItem}
              keyExtractor={(_transaction, index) => index}
              onEndReached={loadMoreTransaction}
              ListFooterComponent={renderLoader}
              onEndReachedThreshold={0.2}
              refreshing={false}
              onRefresh={handleRefresh}
            />
          ) : (
            <View>
              <View>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='700'
                  fontSize='18px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  {config?.jsonConfig?.rewards?.transactionTitle ||
                    "Rewards History"}
                </Text>
              </View>

              <View width={"100%"}>
                <DateSelect
                  key={lrCheck}
                  numOfYear={numOfYear}
                  monthOffset={0}
                  notBtn={true}
                  onViewStatementsClick={getTransactions}
                  setCurrMonth={setCurrMonth}
                  setCurrYear={setCurrYear}
                  fromRewards={true}
                  defaultMonth={currMonth}
                  monthIndex={moment().format("MMMM")}
                  selectYear={currYear}
                />
              </View>
              {!isLoading ? (
                <>
                  <View mt='32px' zIndex={"-1"} alignSelf={"center"}>
                    <Image
                      source={{
                        uri:
                          config?.jsonConfig?.rewards
                            ?.transactionNullStateImage || rewardsEmptyImage,
                      }}
                      width='192px'
                      height='183px'
                      alt='Alternate Text '
                    />
                  </View>
                  <View alignSelf={"center"} mt='16px' zIndex={-1}>
                    <Text
                      fontFamily={theme.fontFamily}
                      fontWeight='600'
                      fontSize='14px'
                      lineHeight='18px'
                      letterSpacing='0.02em'
                      color={theme.appTextColor}
                    >
                      {config?.jsonConfig?.rewards?.transactionNullStateText ||
                        "Start shopping to earn rewards!"}
                    </Text>
                  </View>
                </>
              ) : (
                <ViewAllTransactionsLoader />
              )}
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default Rewards_v2
