import React, { lazy, Suspense } from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import StatementLoader from "../../../core/SkeletonLoader/statementLoader"
import StatementSummary from "../../Summary"
const StatementNew = lazy(() => import("./MainNew"))

const StatementData = props => {
  const config = useSelector(state => state.config)

  const getVersionedComponent = version => {
    switch (version) {
      case PwaVersions.V2:
        return (
          <Suspense fallback={<StatementLoader />}>
            {" "}
            <StatementNew />
          </Suspense>
        )
      case PwaVersions.V2_1:
        return (
          <Suspense fallback={<StatementLoader />}>
            {" "}
            <StatementNew />
          </Suspense>
        )
      case PwaVersions.V3:
        return (
          <Suspense fallback={<StatementLoader />}>
            {" "}
            <StatementSummary />
          </Suspense>
        )
      default:
        return (
          <Suspense fallback={<StatementLoader />}>
            {" "}
            <StatementNew />
          </Suspense>
        )
    }
  }
  return getVersionedComponent(config?.version)
}

export default StatementData
