import React from "react"

const ArrowLeft = ({ color, size, className="" }) => {
  return (
    <div style={{ width: size }}>
      <svg
        className={className}
        style={{ fill: color }}
        viewBox='0 0 1024 1024'
        class='icon'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
        stroke={color}
        stroke-width='80'
      >
        <path
          d='M768 903.232l-50.432 56.768L256 512l461.568-448 50.432 56.768L364.928 512z'
          style={{ fill: color }}
          stroke={color}
          stroke-width='80'
        />
      </svg>
    </div>
  )
}
export default ArrowLeft
