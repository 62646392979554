import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"

const FavouriteMerchantsCarousel = ({ Merchants, gutter }) => {
  return (
    <Swiper
      loop={Merchants?.length >= 5 ? true : false}
      spaceBetween={2}
      slidesPerView={gutter ? 4.3 : 5}
      centerInsufficientSlides
      style={{
        overflow: "visible",
        width: gutter ? "91%" : "",
      }}
    >
      {Merchants?.map((merchant, index) => {
        return (
          <SwiperSlide style={{ paddingBottom: "40px" }}>
            <div className='v3-aboutCard-favouriteBrands-brandLogo'>
              <img
                src={merchant}
                alt='merchant'
                style={{ pointerEvents: "none" }}
              />
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default FavouriteMerchantsCarousel
