const PrimaryButton = ({
  className = "",
  style,
  children,
  onClick,
  ...props
}) => {
  // defining some props with same name as that of native element for auto-suggestion as typescript is not used
  return (
    <button
      className={`hf-btn hf-btn-primary ${className}`}
      style={{
        ...style,
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

const SecondaryButton = ({
  children,
  style,
  className = "",
  onClick,
  ...props
}) => {
  return (
    <button
      className={`hf-btn hf-btn-secondary ${className}`}
      style={style}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  )
}

export { PrimaryButton, SecondaryButton }
