const styles = {
  mainView: {
    minHeight: "100%",
    backgroundColor: "#fff",
    py: "5",
    px: "0",
  },
  widgetErrorText: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#7B7B7B",
  },
  header: {
    width: "100%",
    display: "block",
    marginBottom: 5,
  },
  closeIcon: {
    cursor: "pointer",
    size: "17px",
    mx: 5,
  },
  title: {
    width: "100%",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
  },
  widget: {
    borderRadius: "8px",
    backgroundColor: "#fff",
  },
  cardContainer: {
    p: "12px",
    borderRadius: "8px",
    backgroundColor: "#fff",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    mb: "16px",
  },
  error: {
    width: "100%",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    mt: 20,
  },
}

export default styles
