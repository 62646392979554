import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "../../nativeBaseReplacements"

const CustomerCarePhone = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 32 32' size='22px'>
      <svg
        version='1.0'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 512.000000 512.000000'
        preserveAspectRatio='xMidYMid meet'
      >
        <g
          className='customerCare-phone-icon-svg-path'
          transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'
          fill={theme.appTextColor}
          stroke='none'
        >
          <path
            d='M1075 4780 c-137 -15 -270 -82 -362 -184 -51 -56 -266 -406 -309
-501 -65 -148 -83 -360 -45 -520 37 -153 154 -441 277 -681 243 -474 552 -892
959 -1300 263 -263 490 -453 770 -646 306 -211 692 -412 1031 -538 150 -56
232 -72 364 -72 210 1 320 43 597 231 218 146 248 172 307 258 122 179 136
394 40 593 -34 69 -60 98 -348 387 -299 300 -314 313 -394 351 -181 88 -383
79 -558 -23 -27 -16 -83 -62 -124 -103 -86 -85 -134 -112 -205 -112 -68 1
-106 20 -233 122 -296 236 -618 562 -839 851 -66 87 -87 142 -73 200 11 51 23
68 103 140 142 127 209 270 208 447 0 151 -42 269 -134 377 -23 26 -175 179
-337 339 -234 230 -308 297 -356 322 -101 52 -223 75 -339 62z m155 -415 c40
-21 588 -577 599 -608 16 -40 13 -123 -3 -156 -8 -15 -47 -56 -86 -91 -138
-122 -199 -246 -208 -419 -10 -218 37 -314 325 -649 245 -286 571 -594 841
-794 124 -92 266 -134 416 -125 160 10 287 69 414 192 45 44 95 85 110 92 41
18 108 15 150 -6 54 -29 548 -527 567 -573 20 -49 19 -94 -4 -143 -24 -52 -36
-62 -223 -189 -179 -122 -221 -142 -309 -155 -91 -12 -168 2 -315 57 -600 227
-1158 602 -1659 1113 -482 492 -817 1005 -1047 1606 -52 136 -53 139 -53 248
0 132 4 144 157 397 103 171 127 197 196 218 32 10 97 3 132 -15z'
          />
          <path
            d='M2950 4778 c-106 -30 -172 -145 -140 -245 30 -92 88 -134 214 -153
654 -99 1221 -647 1342 -1295 9 -49 20 -106 25 -126 43 -175 282 -207 367 -49
22 41 23 53 19 130 -7 102 -61 319 -113 451 -194 493 -547 877 -1019 1105
-247 120 -595 211 -695 182z'
          />
          <path
            d='M2752 4164 c-74 -38 -120 -126 -108 -209 14 -92 79 -151 183 -166
282 -41 465 -125 640 -292 170 -163 286 -392 308 -609 11 -99 23 -136 62 -179
45 -50 119 -74 182 -60 100 22 161 99 161 202 0 186 -98 487 -223 680 -197
307 -497 521 -862 615 -131 34 -295 43 -343 18z'
          />
          <path
            d='M2620 3559 c-142 -44 -190 -219 -89 -326 50 -52 84 -63 194 -63 148
0 250 -42 338 -139 76 -84 108 -186 105 -328 -2 -75 1 -86 28 -129 48 -78 154
-116 237 -85 38 15 99 72 114 109 21 50 27 178 14 270 -47 322 -273 583 -581
672 -87 25 -302 36 -360 19z'
          />
        </g>
      </svg>
    </Icon>
  )
}
export default CustomerCarePhone
