import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function TopWrapper() {
  return (
    <CardSkeleton
      margin='10px 0px 0px 0px'
      width='100%'
      height='80px'
      padding='10px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    
    >
      <TextWrapperSkeleton width='55px' height='50px' br='60%' />

      <CardSkeleton margin="0px 0px 0px -40%">
        <TextWrapperSkeleton width='50px' />
        <TextWrapperSkeleton width='50px' margin='20px auto' />
      </CardSkeleton>
      <TextWrapperSkeleton margin='0px 0px 0px 0px' width='50px' />
    </CardSkeleton>
  )
}



function BilledTransactionLoader() {
  return (
    <>
      <CardSkeleton margin='20px auto' height="400px" width='100%'>
        <TopWrapper />
        <TopWrapper />
        <TopWrapper />
        <TopWrapper />
      </CardSkeleton>
    </>
  )
}

export default BilledTransactionLoader
