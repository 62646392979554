import React from "react"

const UndoTrashSvg = ({ color = "#E72B2B", opacity = 0.4 }) => (
  <svg
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='trash-undo'>
      <path
        id='Vector'
        d='M16.6666 5.125H13.155C13.0775 4.97333 13.0175 4.80167 12.9533 4.61L12.785 4.10416C12.615 3.59416 12.1375 3.25 11.5992 3.25H8.40081C7.86247 3.25 7.38497 3.59416 7.21497 4.10416L7.04664 4.61C6.98247 4.80167 6.92247 4.97333 6.84497 5.125H3.33331C2.98831 5.125 2.70831 5.405 2.70831 5.75C2.70831 6.095 2.98831 6.375 3.33331 6.375H16.6666C17.0116 6.375 17.2916 6.095 17.2916 5.75C17.2916 5.405 17.0116 5.125 16.6666 5.125Z'
        fill={color}
      />
      <path
        id='Vector_2'
        opacity={opacity}
        d='M15.7917 6.375L15.1583 15.9167C15.0667 17.2333 14.375 18.25 12.6583 18.25H7.34165C5.62499 18.25 4.93332 17.2333 4.84165 15.9167L4.20831 6.375H15.7917Z'
        fill={color}
      />
      <path
        id='Vector_3'
        d='M11.6983 11.3142C11.1067 10.7058 10.3317 10.3625 9.52832 10.2933C9.71749 10.0483 9.70998 9.69916 9.48498 9.475C9.24082 9.23083 8.84501 9.23083 8.60084 9.475L7.475 10.6017C7.23083 10.8458 7.23083 11.2417 7.475 11.4858L8.60084 12.6117C8.72251 12.7333 8.8825 12.795 9.04251 12.795C9.2025 12.795 9.3625 12.7342 9.48417 12.6117C9.72834 12.3683 9.72834 11.9717 9.48417 11.7275L9.28082 11.5233C9.84082 11.535 10.3908 11.7625 10.8008 12.1842C11.3608 12.7642 11.5825 13.5742 11.3925 14.3517C11.3108 14.6867 11.5158 15.0258 11.8516 15.1067C11.9016 15.1192 11.9517 15.1242 12 15.1242C12.2817 15.1242 12.5375 14.9333 12.6067 14.6475C12.8992 13.4508 12.5592 12.2058 11.6983 11.3142Z'
        fill={color}
      />
    </g>
  </svg>
)

export default UndoTrashSvg
