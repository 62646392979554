import { useEffect, useState } from "react"

const useWindowDimensions = () => {
  const [dimensions, setDimensions] = useState({
    height: window.visualViewport.height,
    width: window.visualViewport.width,
  })

  useEffect(() => {
    const resizeHandler = () => {
      setDimensions({
        height: window.visualViewport.height,
        width: window.visualViewport.width,
      })
    }
    window.visualViewport.addEventListener("resize", resizeHandler)
    return () => {
      window.visualViewport.removeEventListener("resize", resizeHandler)
    }
  }, [])

  return dimensions
}

export default useWindowDimensions
