const Rewards = ({
  backgroundColor = "#D6E3F6",
  circleColor = "#1965CF",
  powerColor = "#fff",
  customClassname = "",
}) => {
  return (
    <div
      style={{ background: backgroundColor }}
      className={`v3-rewards-icon-container ${customClassname}`}
    >
      <svg
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_i_6020_18928)'>
          <circle cx='13' cy='13' r='13' fill={circleColor} />
        </g>
        <g filter='url(#filter1_d_6020_18928)'>
          <path
            d='M9.48443 13.8908L14.2247 5.48768C14.2584 5.42793 14.3498 5.46053 14.3381 5.52813L13.3884 11.0155C13.369 11.1276 13.4552 11.2301 13.569 11.2301H16.3065C16.4937 11.2301 16.6114 11.4319 16.5192 11.5949L11.7548 20.0201C11.7209 20.08 11.6292 20.0468 11.6415 19.9791L12.6429 14.4714C12.6633 14.3589 12.5769 14.2553 12.4625 14.2553H9.69733C9.51025 14.2553 9.39252 14.0538 9.48443 13.8908Z'
            fill={powerColor}
          />
        </g>
        <defs>
          <filter
            id='filter0_i_6020_18928'
            x='0'
            y='0'
            width='26'
            height='26'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='BackgroundImageFix'
              result='shape'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dx='-2.32304' dy='-2.11391' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
            />
            <feBlend
              mode='normal'
              in2='shape'
              result='effect1_innerShadow_6020_18928'
            />
          </filter>
          <filter
            id='filter1_d_6020_18928'
            x='9.45264'
            y='5.27829'
            width='7.09863'
            height='14.7735'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='-0.177765' />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_6020_18928'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_6020_18928'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export default Rewards
