import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  EmiStatus,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
} from "../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../utils/enums"
import {
  capitalizeFirsLetter,
  formatAmount,
  formatString,
  getTransactionDate,
  truncateString,
} from "../../utils/functions"
import styles from "../AllTransactions/v2/styles"

import { getEmiLabel } from "../AllTransactions/v2/transactions"
import TransactionLogo from "./transactionLogo"
import TransactionLogo_v2_1 from "./TransactionLogo_v2_1"
import Text from "../../nativeBaseReplacements/Text"
import View from "../../nativeBaseReplacements/View"
import ChevronRightIcon from "../../nativeBaseReplacements/ChevronRightIcon"
export const Transaction = ({ item }) => {
  const navigate = useNavigate()
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const config = useSelector(state => state.config)
  const [isDescFull, setIsDescFull] = useState(false)

  const handleConvertToEmiClick = transaction => {
    navigate("/ConvertToEMI", {
      state: {
        txnId: transaction.id,
        amount: transaction.amount,
        description: formatString(
          capitalizeFirsLetter(transaction.description),
        ),
        logo: transaction.identifiedMerchantLogo,
        transactionDate: transaction.transactionDate,
        postingDate: transaction.postingDate,
        txnType: transaction.txnType,
        prevPath: "/",
      },
    })
  }
  return (
    <>
      <View mb='16px'>
        {item.identifiedMerchantLogo ? (
          <img
            {...styles.image}
            // height="52px"
            src={`data:image/jpg;base64,${item.identifiedMerchantLogo}`}
            alt='logo'
            width={TRANSACTION_LOGO_WIDTH + "px"}
            height={TRANSACTION_LOGO_HEIGHT + "px"}
          />
        ) : (
          <View h='51px'>
            {!config?.jsonConfig.showNewTransactionLogos ? (
              <TransactionLogo transactionType={item.txnType} />
            ) : (
              <TransactionLogo_v2_1
                transactionType={item.txnType}
                mcc={item.merchantCategoryCode}
              />
            )}
          </View>
        )}
      </View>
      <View
        mb='18px'
        ml='15px'
        cursor={"pointer"}
        style={{ width: "80%", flex: "auto" }}
        onClick={() => setIsDescFull(!isDescFull)}
      >
        <View style={{ flexDirection: "row" }}>
          <View w={"70%"}>
            <View>
              <Text
                {...styles.transactee}
                fontFamily={theme.fontFamily}
                color={theme.appTextColor}
              >
                {isDescFull
                  ? capitalizeFirsLetter(formatString(item.description))
                  : truncateString(
                      capitalizeFirsLetter(formatString(item.description)) ||
                        "",
                      24,
                    )}
              </Text>
            </View>
          </View>
          <View
            // justifyContent= "right"
            flex='4'
            {...styles.transactionAmountContainer}
            mb={"5px"}
            justifyContent='flex-end'
            mr='5px'
            w='30%'
          >
            <Text
              {...styles.transactionType}
              fontFamily={theme.fontFamily}
              color={
                config?.version === PwaVersions.V2_1 &&
                item.txnNature !== "DEBIT"
                  ? "#0B9B22"
                  : theme.appTextColor
              }
            >
              {item.txnNature === "DEBIT"
                ? config?.version !== PwaVersions.V2_1 && "-"
                : "+"}
            </Text>
            <Text
              {...styles.transactionAmount}
              fontFamily={theme.fontFamily}
              fontSize={"14px"}
              fontWeight={"400"}
              color={
                config?.version === PwaVersions.V2_1 &&
                item.txnNature !== "DEBIT"
                  ? "#0B9B22"
                  : theme.appTextColor
              }
            >
              {formatAmount(item.amount)}
            </Text>
          </View>
        </View>

        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 5 }}>
            <Text
              {...styles.transactionTime}
              fontFamily={theme.fontFamily}
              color={theme.appTextColor}
            >
              {getTransactionDate(
                item.transactionDate,
                item.postingDate,
                config?.version,
              )}
            </Text>
          </View>

          <View
            styles={{
              flexDirection: "column",
              flex: "4",
            }}
            justifySelf='end'
            justifyContent={"end"}
            alignSelf={"center"}
          >
            {(session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.EMI_CONVERSION_DISABLE,
            ) ||
              session.disabledFunctionsByBlockCode.includes(
                Federal_BlockCodes.EMI_CONVERSION_DISABLE,
              )) === false &&
              item.emiAllowed &&
              item.emiStatus === EmiStatus.NOT_CREATED && (
                <View
                  {...styles.transactionConvertToEMI}
                  justifyContent='end'
                  mt='1px'
                  cursor={"pointer"}
                >
                  <Text
                    {...styles.transactionConvertToEMIText}
                    color={theme.color1}
                    textAlign={"end"}
                    fontSize={"13px"}
                    onClick={() =>
                      session.disabledFunctionsByBlockCode.includes(
                        AU_BlockCodes.EMI_CONVERSION_DISABLE,
                      ) ||
                      session.disabledFunctionsByBlockCode.includes(
                        Federal_BlockCodes.EMI_CONVERSION_DISABLE,
                      )
                        ? null
                        : handleConvertToEmiClick(item)
                    }
                  >
                    Convert to EMI
                  </Text>
                  <ChevronRightIcon
                    color={theme.color1}
                    size='20px'
                    width='fit-content'
                  />
                </View>
              )}
            {getEmiLabel(item.emiStatus) && (
              <View
                {...styles.transactionConvertedToEMI}
                alignSelf='end'
                justifySelf={"end"}
                justifyContent='end'
              >
                <Text {...styles.transactionConvertedToEMIText}>
                  {getEmiLabel(item.emiStatus)}
                </Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </>
  )
}

export default Transaction
