const styles = {
  mainView: {
    p: "5",
  },

  cardTextMainView: {
    // backgroundColor: '#000',
    width: "90%",
    position: "absolute",
    ml: 5,
    mt: 5,
    // left: "7",
    // top: "5",
    borderColor: "#FFF",
  },
  buttonContainer: {
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    bottom: "24px",
  },
  cardTextName: {
    color: "#FFF",
    textTransform: "uppercase",
    mb: "1",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "13",
    letterSpacing: "1",
  },
  cardNumber: {
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "13",
    letterSpacing: "2",
  },
  expiryCvvMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: "9",
  },
  expiryCvvText: {
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "12",
    letterSpacing: "1",
  },
  expiryCvvTextNumber: {
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "10",
    letterSpacing: "1",
  },
  circleMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "40%",
  },
  circle: {
    width: "7",
    height: "7",
    opacity: "0.5",
    borderRadius: "25",
    backgroundColor: "#F8F8F8",
  },
  clientText: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "18",
  },
  physicalpayLatertext: {
    mt: "2",
    color: "#7d7d7d",
    fontSize: "14",
    fontFamily: "Inter",
    fontWeight: "400",
  },
  processView: {
    pl: "5",
    py: "4",
    borderRadius: "10",
    backgroundColor: "#F8F8F8",
    my: "5",
    mb: "10",
  },
  processMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  processImage: {
    width: "8",
    height: "8",
    resizeMode: "contain",
    m: "1",
  },
  processIndividualStep: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  processStep: {
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "12px",
    letterSpacing: "0.4px",
    textTransform: "uppercase",
    color: "#7B7B7B",
    mb: "2px",
  },
  processStepName: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "15px",
    lineHeight: "18px",
    color: "#7B7B7B",
  },
  lineSeparator: {
    ml: "4",
    width: "1",
    height: "5",
    resizeMode: "contain",
    m: "1",
  },
}
export default styles
