import React from "react"

const FAQ_Img3 = ({ color = "#1965CF" }) => {
  return (
    <svg
      width='41'
      height='41'
      viewBox='0 0 41 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='41' height='41' rx='20.5' fill='#F5FAFF' />
      <path
        d='M11.501 20.3C11.474 20.3 11.446 20.299 11.418 20.296C11.006 20.251 10.709 19.88 10.754 19.469C11.117 16.152 13.119 13.295 16.109 11.827C16.342 11.713 16.617 11.727 16.836 11.864C17.056 12.001 17.19 12.242 17.19 12.501V15.551C17.19 15.965 16.854 16.301 16.44 16.301C16.026 16.301 15.69 15.965 15.69 15.551V13.8101C13.767 15.1481 12.506 17.249 12.246 19.632C12.204 20.015 11.878 20.3 11.501 20.3ZM24.891 29.173C27.881 27.706 29.883 24.849 30.245 21.531C30.291 21.119 29.993 20.749 29.581 20.704C29.173 20.66 28.8 20.956 28.755 21.368C28.494 23.751 27.234 25.8519 25.31 27.1899V25.449C25.31 25.035 24.974 24.699 24.56 24.699C24.146 24.699 23.81 25.035 23.81 25.449V28.499C23.81 28.758 23.944 28.999 24.164 29.136C24.285 29.211 24.422 29.249 24.56 29.249C24.673 29.25 24.786 29.225 24.891 29.173ZM20.5 15.5C20.5 17.709 22.291 19.5 24.5 19.5C26.709 19.5 28.5 17.709 28.5 15.5C28.5 13.291 26.709 11.5 24.5 11.5C22.291 11.5 20.5 13.291 20.5 15.5ZM12.5 25.5C12.5 27.709 14.291 29.5 16.5 29.5C18.709 29.5 20.5 27.709 20.5 25.5C20.5 23.291 18.709 21.5 16.5 21.5C14.291 21.5 12.5 23.291 12.5 25.5Z'
        fill={color}
      />
    </svg>
  )
}

export default FAQ_Img3
