import { memo } from "react"
import styled, { css } from "styled-components"

export const FormControlErrorMessage = ({ leftIcon, children }) => {
  return (
    <div
      className='nbr-form-control-error-message'
      style={{
        alignItems: "center",
        fontSize: "12px",
        gap: "4px",
        paddingTop: "8px",
      }}
    >
      {leftIcon}
      {children}
    </div>
  )
}

function FormControl({ children, isInvalid }) {
  const classList = ["nbr-form-control"]

  if (isInvalid) {
    classList.push("nbr-form-control-invalid")
  }

  return (
    <FormControlContainer isInvalid={isInvalid} className={classList.join(" ")}>
      {children}
    </FormControlContainer>
  )
}

export default memo(FormControl)

const FormControlContainer = styled.div`
  & .nbr-form-control-error-message {
    display: none;
  }
  ${props =>
    props.isInvalid
      ? css`
          & .nbr-form-control-error-message,
          & .nbr-form-control-error-message svg {
            display: flex;
            color: rgb(220, 38, 38);
          }
          & input {
            border-color: rgb(220, 38, 38) !important;
          }
        `
      : ""}
`
