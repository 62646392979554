import React from "react"

const WarningTriangle = () => {
  return (
    <svg
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.7055 12.8641L11.7503 3.59699C10.7903 1.80149 8.21107 1.80149 7.25032 3.59699L2.29512 12.8641C1.41462 14.5111 2.61015 16.5001 4.4814 16.5001H14.5192C16.3897 16.5001 17.586 14.5104 16.7055 12.8641ZM8.93782 7.50007C8.93782 7.18957 9.18982 6.93757 9.50032 6.93757C9.81082 6.93757 10.0628 7.18957 10.0628 7.50007V10.5001C10.0628 10.8106 9.81082 11.0626 9.50032 11.0626C9.18982 11.0626 8.93782 10.8106 8.93782 10.5001V7.50007ZM9.51533 13.5001C9.10133 13.5001 8.76149 13.1641 8.76149 12.7501C8.76149 12.3361 9.09382 12.0001 9.50782 12.0001H9.51533C9.93008 12.0001 10.2653 12.3361 10.2653 12.7501C10.2653 13.1641 9.92933 13.5001 9.51533 13.5001Z'
        fill='#E5B200'
      />
    </svg>
  )
}

export default WarningTriangle
