import React from "react"

const CS_bell = () => {
  return (
    <svg
      width='100%'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.0002 40.8333C26.7625 40.8333 25.5755 41.3249 24.7003 42.2001C23.8252 43.0753 23.3335 44.2622 23.3335 45.4999C23.3335 46.7376 23.8252 47.9246 24.7003 48.7997C25.5755 49.6749 26.7625 50.1666 28.0002 50.1666C29.2378 50.1666 30.4248 49.6749 31.3 48.7997C32.1752 47.9246 32.6668 46.7376 32.6668 45.4999C32.6668 44.2622 32.1752 43.0753 31.3 42.2001C30.4248 41.3249 29.2378 40.8333 28.0002 40.8333ZM30.3335 12.8333C30.3335 14.1213 29.2882 15.1666 28.0002 15.1666C26.7122 15.1666 25.6668 14.1213 25.6668 12.8333V8.16659C25.6668 6.87859 26.7122 5.83325 28.0002 5.83325C29.2882 5.83325 30.3335 6.87859 30.3335 8.16659V12.8333Z'
        fill='#DB8509'
      />
      <path
        d='M32.535 46.5617C32.6143 46.2199 32.6668 45.8652 32.6668 45.5C32.6668 45.0532 32.5852 44.6297 32.4662 44.2202C31.044 44.2925 29.5553 44.3334 28.0002 44.3334C26.445 44.3334 24.9563 44.2925 23.533 44.2214C23.4163 44.6309 23.3335 45.0544 23.3335 45.5C23.3335 45.8652 23.386 46.2187 23.4653 46.5605C25.1815 46.6422 26.7472 46.6667 28.0002 46.6667C29.2532 46.6667 30.8188 46.6422 32.535 46.5617Z'
        fill='#BC6F0A'
      />
      <path
        d='M47.8332 39.6666C47.8332 42.2449 39.5965 44.3333 27.9998 44.3333C16.4032 44.3333 8.1665 42.2449 8.1665 39.6666C8.1665 39.5033 8.2015 39.3399 8.2715 39.1766C8.45817 38.6516 8.88984 37.9749 9.48484 37.1933C9.90484 36.6216 10.4065 36.0033 10.9432 35.3383C13.6965 32.0366 17.4998 27.9066 17.4998 25.6666V19.8333C17.4998 14.0349 22.2015 9.33325 27.9998 9.33325C33.7982 9.33325 38.4998 14.0349 38.4998 19.8333V25.6666C38.4998 27.9066 42.3032 32.0366 45.0565 35.3383C45.5932 36.0033 46.0948 36.6216 46.5148 37.1933C47.1098 37.9749 47.5415 38.6516 47.7282 39.1766C47.7982 39.3399 47.8332 39.5033 47.8332 39.6666Z'
        fill='#FFC107'
      />
      <path
        d='M46.5149 37.1934C42.3149 39.6434 36.2365 40.8334 27.9999 40.8334C19.7632 40.8334 13.6849 39.6434 9.48486 37.1934C9.90486 36.6217 10.4065 36.0034 10.9432 35.3384C16.6599 38.5 25.1182 38.5 27.9999 38.5C30.8815 38.5 39.3399 38.5 45.0565 35.3384C45.5932 36.0034 46.0949 36.6217 46.5149 37.1934Z'
        fill='#FFE082'
      />
      <path
        d='M44.345 13.5801L42.3967 15.0734C42.8983 16.5667 43.1667 18.1767 43.1667 19.8334C43.1667 21.4901 42.8983 23.1001 42.3967 24.5934L44.345 26.0867C45.0917 24.1501 45.5 22.0384 45.5 19.8334C45.5 17.6284 45.0917 15.5167 44.345 13.5801ZM13.6033 15.0734L11.655 13.5801C10.9083 15.5167 10.5 17.6284 10.5 19.8334C10.5 22.0384 10.9083 24.1501 11.655 26.0867L13.6033 24.5934C13.1017 23.1001 12.8333 21.4901 12.8333 19.8334C12.8333 18.1767 13.1017 16.5667 13.6033 15.0734ZM50.0617 9.20508L48.1717 10.6517C49.455 13.4517 50.1667 16.5551 50.1667 19.8334C50.1667 23.1117 49.455 26.2151 48.1717 29.0151L50.0617 30.4617C51.625 27.2417 52.5 23.6367 52.5 19.8334C52.5 16.0301 51.625 12.4251 50.0617 9.20508ZM7.82833 10.6517L5.93833 9.20508C4.375 12.4251 3.5 16.0301 3.5 19.8334C3.5 23.6367 4.375 27.2417 5.93833 30.4617L7.82833 29.0151C6.545 26.2151 5.83333 23.1117 5.83333 19.8334C5.83333 16.5551 6.545 13.4517 7.82833 10.6517Z'
        fill='#FFAB00'
      />
    </svg>
  )
}

export default CS_bell
