import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import auImage from "../../../assets/images/auImage.svg"
import ixigoImage from "../../../assets/images/ixigoImage.svg"
import upiImage from "../../../assets/images/upiImage.svg"
import AccountService from "../../../services/AccountService"
import { captureEvents, formatAmount } from "../../../utils/functions"
import { Header } from "../../core"

import { useNavigate } from "react-router-dom"
import { EventName, PwaVersions } from "../../../utils/enums"
import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import CancelPaymentBottomSheet from "./v3/cancelPaymentBottomSheet"
import Divider from "../../core/Divider/v3/Divider"
import useWindowDimensions from "../../../hooks/useWindowDimensionsWeb"

const GET_UPI_APP_NAME = {
  okaxis: "Google Pay",
  oksbi: "Google Pay",
  okhdfcbank: "Google Pay",
  okicici: "Google Pay",
  ybl: "PhonePe",
  axl: "PhonePe",
  ibl: "PhonePe",
  paytm: "Paytm",
  upi: "BHIM",
  jio: "JIO Pay",
  freecharge: "Freecharge",
  amazonpay: "Amazon Pay",
  axisbank: "BHIM Axis Pay",
  aubank: "AU Bank",
}

const PaymentRequest = () => {
  const { t } = useTranslation()
  const windowDim = useWindowDimensions()
  const [isOtherAmountSelected, setIsOtherAmountSelected] = useState(false)
  const [remainingMin, setRemainingMin] = useState(0)
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const [upiAppName, setUpiAccountName] = useState()
  const [isCancelPaymentBottomSheetOpen, setIsCancelBottomSheetOpen] =
    useState(false)

  const navigate = useNavigate()

  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const paymentGateway = useSelector(state => state.paymentGateway)
  const config = useSelector(state => state.config)

  const upiFlow = paymentGateway?.pgconfig[0]?.flows[0]
  const timeFromApiInSec = upiFlow?.sessionExpiryInSec || 300
  const INTERVAL_API_CALL = upiFlow?.pollingInSec * 1000 || 3000
  let remainingTime = timeFromApiInSec - 1
  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const totalMinutes = Math.floor(remainingTime / 60)
      const totalSeconds = Math.floor(remainingTime % 60).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
          useGrouping: false,
        },
      )
      setRemainingMin(totalMinutes)
      setRemainingSeconds(totalSeconds)
      remainingTime--
      if (remainingTime < 0) {
        remainingTime = 3000000
        navigate("/PaymentGateway/Expired")
      }
    }, 1000)
  }

  const options = [
    {
      text2: t("PaymentGateway.goUptoApp", {
        appname: GET_UPI_APP_NAME[paymentGateway?.vpaId?.split("@")[1]],
      }),
    },
    {
      text2: t("PaymentGateway.approvePaymentRequest", { bankName: "AU Bank" }),
    },
    {
      text2: t("PaymentGateway.enterUpiPin", { appName: "ixigo" }),
    },
  ]

  useEffect(() => {
    const handleBackNavigation = event => {
      event.preventDefault()
      window.history.pushState(null, document.title, window.location.href)
      console.log("handling back navigation")
      setIsCancelBottomSheetOpen(true)
    }
    // Attach the event listener when the component mounts
    window.addEventListener("popstate", handleBackNavigation)

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handleBackNavigation)
    }
  }, [])

  useEffect(() => {
    captureEvents({
      eventName: EventName.PAY_BILL_PAYMENT_REQ_PAGE,
      metadata: {
        amount: paymentGateway?.amountPayable,
        upiId: paymentGateway?.vpaId,
      },
    })
    if (!paymentGateway?.vpaTxnRefId || !paymentGateway?.vpaId) {
      captureEvents({
        eventName: EventName.PAYMENT_FAILURE,
        metadata: { amount: paymentGateway?.amountPayable },
      })
      navigate("/PaymentGateway/Failed")
    }

    countdownTimer()
  }, [])

  useEffect(() => {
    let data = {
      accountId: user?.account?.id,
      txnRefId: paymentGateway.txnRefId,
    }
    let interval
    ;(async () => {
      interval = setInterval(async () => {
        const response = await AccountService.getPGTransactionStatus(data)
        if (response.status == 200) {
          console.log(response?.data?.data?.status)
          if (response?.data?.data?.status === "SUCCESS") {
            captureEvents({
              eventName: EventName.PAYMENT_SUCCESS,
              metadata: { amount: paymentGateway?.amountPayable },
            })
            navigate("/PaymentGateway/Success")
            return clearInterval(interval)
          } else if (response?.data?.data?.status != "PENDING") {
            captureEvents({
              eventName: EventName.PAYMENT_FAILURE,
              metadata: { amount: paymentGateway?.amountPayable },
            })
            clearInterval(interval)
            navigate("/PaymentGateway/Failed")
          }
        }
      }, INTERVAL_API_CALL)

      return () => clearInterval(interval)
    })()

    return () => clearInterval(interval)
  }, [])

  const getCurrentWidth = () => {
    let totalTime = timeFromApiInSec
    let timeRemains = Number(
      Number(remainingMin * 60) + Number(remainingSeconds),
    )
    let currentWidth = (timeRemains / totalTime) * 100

    return currentWidth
  }

  const getCurrentColor = () => {
    if (remainingMin < 1) {
      return "rgba(224, 0, 0, 1)"
    } else if (remainingMin < 2) {
      return "rgba(234, 140, 0, 1)"
    } else {
      return "rgba(38, 128, 234, 1)"
    }
  }

  const closeCancelPaymentBottomSheet = () => {
    setIsCancelBottomSheetOpen(false)
  }

  const versionedPaymentRequestPage = () => {
    if (config?.version === PwaVersions.V3) {
      return (
        <>
          <Header
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          <div className='v3-paymentRequest-container'>
            <div className='v3-paymentRequest-progressContainer'>
              <div className='v3-paymentRequest-countdownTimer'>
                <div>
                  <span>{remainingMin}</span>:<span>{remainingSeconds}</span>
                </div>
                <div>{t("PaymentGateway.remainingMin")}</div>
              </div>
              <CircularProgressbar
                value={getCurrentWidth()}
                strokeWidth={6}
                styles={{
                  path: {
                    stroke: getCurrentColor(), // Path color
                  },
                  trail: {
                    stroke: "rgba(244, 244, 244, 1)", // Trail color
                  },
                }}
              />
            </div>
            <div className='v3-paymentRequest-container-text'>
              {t("PaymentGateway.openUPIApprove")}{" "}
              <span>{formatAmount(paymentGateway?.amountPayable)}</span>
              {t("PaymentGateway.timerRunsOut")}
            </div>
            <Divider />
            <div className='v3-paymentRequest-container-warning'>
              {t("PaymentGateway.doNotCloseWindow")}{" "}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          {" "}
          <Header
            text='Bill Payment'
            onBack={async () => {
              window.history.go(-1)
            }}
          />
          <div className='mainContainer' style={{ color: theme.appTextColor }}>
            <div className='paymentReqTopContainer'>
              <div className='paymentReqText'>
                {t("PaymentGateway.paymentRequestSent")}
              </div>

              <div className='paymentReqAmount'>
                {formatAmount(paymentGateway?.amountPayable)}
              </div>

              <div className='paymentReqSent'>
                {t("PaymentGateway.sentTo")}{" "}
                <span className='paymentReqHighlight'>
                  {" "}
                  {paymentGateway.vpaId}{" "}
                </span>
              </div>

              <div className='paymentReqOpenAppText'>
                {t("PaymentGateway.openYour")}{" "}
                <span className='paymentReqAppName'>
                  {GET_UPI_APP_NAME[paymentGateway?.vpaId?.split("@")[1]]} app{" "}
                </span>{" "}
                {t("PaymentGateway.openYourUpiApp")}
              </div>

              {/* Change width of span according to time left, width Totol should be 100%  */}
              <div className='paymentReqTimeLine'>
                <span
                  style={{ width: getCurrentWidth() + "%" }}
                  className='paymentReqTimeLineColored'
                ></span>

                <span
                  style={{ width: 100 - getCurrentWidth() + "%" }}
                  className='paymentReqTimeLineGreyed'
                ></span>
              </div>

              <div className='paymentReqRetryPayment'>
                {" "}
                {t("PaymentGateway.paymentRequestExpires")}{" "}
                <span className='paymentReqHighlight'>
                  {" "}
                  {remainingMin && remainingMin} :{" "}
                  {remainingSeconds && remainingSeconds}{" "}
                </span>
              </div>
            </div>

            <div className='optionsContainer  paymentReqStepsContainer'>
              <div className='paymentReqStepsHeading'>
                {t("PaymentGateway.step")}
              </div>
              {options.map((item, index) => (
                <>
                  <div className='optionsContent'>
                    <div className='paymentReqStepsNumContainer'>
                      <div className='paymentReqStepsNum'>{index + 1}</div>
                    </div>
                    <div>
                      <div className='optionsContentText paymentReqStepsText '>
                        {item.text2}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className='paymentReqBottomImageContainer'>
            <img src={auImage}></img>
            <img src={upiImage}></img>
            <img src={ixigoImage}></img>
          </div>
        </>
      )
    }
  }
  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
        position: "relative",
      }}
    >
      {versionedPaymentRequestPage()}
      <CancelPaymentBottomSheet
        isOpen={isCancelPaymentBottomSheetOpen}
        onClose={closeCancelPaymentBottomSheet}
      />
    </div>
  )
}

export default PaymentRequest
