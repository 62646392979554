import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import call from "../../../../assets/images/call.svg"
import callIxigo from "../../../../assets/images/callIxigo.svg"
import mail from "../../../../assets/images/mail.svg"
import mailIxigo from "../../../../assets/images/mailIxigo.svg"
import { DisplayConfig } from "../../../../utils/constants"
import { EventName, PwaVersions } from "../../../../utils/enums"
import { captureEvents } from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import ArrowDown from "../../../svg/arrowDown"
import ArrowUp from "../../../svg/arrowUp"
import CustomerCareEmail from "../../../svg/customerCareEmail"
import CustomerCarePhone from "../../../svg/customerCarePhone"
import FaqAccordian from "../AboutProgram/AboutProgram_v2/FaqAccordian"
import View from "../../../../nativeBaseReplacements/View"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const CustomerCare_v2 = () => {
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const config = useSelector(state => state.config)
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(true)
  const windowDim = useWindowDimensions()
  useEffect(() => {
    captureEvents({ eventName: EventName.CUSTOMER_CARE, metadata: {} })
  }, [])
  return (
    <div
      style={{
        minHeight: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='Customer Care'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div
        className='customerCare'
        style={{ backgroundColor: theme.widgetBackgroundColor }}
      >
        {screen?.customerCare?.descText && (
          <div
            className='customerCare-description'
            style={{ color: theme.appTextColor }}
          >
            {screen?.customerCare?.descText}
          </div>
        )}
        {screen?.customerCare?.moblieNo && (
          <div>
            <div
              className='customerCare-mobileText'
              style={{ color: `${theme.appTextColor}90` }}
            >
              {"You can call us on"}
            </div>
            <div
              className='customerCare-mobile-container'
              onClick={() =>
                (window.location.href = `tel:${screen?.customerCare?.moblieNo}`)
              }
            >
              {config?.version === PwaVersions.V2_1 ? (
                <img
                  className='customerCare-mobile-img'
                  src={config?.version === PwaVersions.V2_1 ? callIxigo : call}
                  alt='Alternate Text '
                />
              ) : (
                <div
                  className='manageCard-widget-icon'
                  style={{ marginTop: "2px" }}
                >
                  <CustomerCarePhone />
                </div>
              )}
              <div
                className='customerCare-mobile'
                style={{ color: theme.appTextColor }}
              >
                {screen?.customerCare?.moblieNo}
              </div>
            </div>
          </div>
        )}
        {screen?.customerCare?.email && (
          <div>
            <div
              className='customerCare-emailText'
              style={{ color: `${theme.appTextColor}90` }}
            >
              {"or email us at"}
            </div>
            <div
              className='customerCare-email-container'
              onClick={() =>
                (window.location.href = `mailto:${screen?.customerCare?.email}`)
              }
            >
              {config?.version === PwaVersions.V2_1 ? (
                <img
                  className='customerCare-email-img'
                  src={config?.version === PwaVersions.V2_1 ? mailIxigo : mail}
                  alt='Alternate Text '
                />
              ) : (
                <div
                  className='manageCard-widget-icon'
                  style={{ marginTop: "4px" }}
                >
                  <CustomerCareEmail />
                </div>
              )}
              <div
                className='customerCare-email'
                style={{ color: theme.appTextColor }}
              >
                {screen?.customerCare?.email}
              </div>
            </div>
          </div>
        )}
      </div>
      {screen?.aboutProgram?.faq?.length > 0 &&
        (config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1) && (
          <div
            className='aboutProgram-faq'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            <div className='aboutProgram-faq-container'>
              <div
                className='aboutProgram-faq-title'
                style={{ color: theme.appTextColor }}
              >
                FAQ
              </div>
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer" }}
              >
                {isOpen ? (
                  <ArrowUp
                    className='aboutProgram-faq-title-arrowUp'
                    size='14px'
                    color={theme.appTextColor}
                  />
                ) : (
                  <ArrowDown
                    className='aboutProgram-faq-title-arrowDown'
                    size='14px'
                    color={theme.appTextColor}
                  />
                )}
              </div>
            </div>
            {isOpen && (
              <div>
                {screen?.aboutProgram?.faq.map((item, index) => (
                  <FaqAccordian item={item} index={index} />
                ))}
              </div>
            )}
          </div>
        )}
      {!(
        config?.version == PwaVersions.V2 || config?.version == PwaVersions.V2_1
      ) ? (
        <View alignItems='center' justifyContent='center'>
          <View
            alignItems='center'
            justifyContent='center'
            position='fixed'
            width='100%'
            bottom='24px'
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          >
            <ColorButton
              text='Go Back'
              onPress={() => navigate("/ManageCard")}
            />
          </View>
        </View>
      ) : null}
    </div>
  )
}

export default CustomerCare_v2
