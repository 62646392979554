import React from "react"

const LineBreaker = () => {
  return (
    <div className='v3-line-breaker'>
      <div className='v3-line-breaker-line'></div>
    </div>
  )
}

export default LineBreaker
