import React from "react"
import { useTranslation } from "react-i18next"

const ZeroSpends = () => {
  const { t } = useTranslation()
  return (
    <div className='v3-spend-analytics-category-split-chart-2-empty-state'>
      {t("spendAnalytics.zeroSpends.spendsOn")} <br />{" "}
      {t("spendAnalytics.zeroSpends.thisPeriod")}
    </div>
  )
}

export default ZeroSpends
