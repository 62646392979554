import { commonStoreKeys } from "../../utils/enums"
import { CLEAR_COMMON_STATE, SET_COMMON_STATE } from "../ActionTypes"

const initialState = {}

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMMON_STATE:
      return {
        ...state,
        ...action.payload,
        // to unset a var it can be set as undefined or null
      }

    case CLEAR_COMMON_STATE: {
      const preservedState = {}

      commonStoreKeys.forEach(key => {
        if (state[key] !== undefined) {
          preservedState[key] = state[key]
        }
      })

      return preservedState
    }
    default:
      return state
  }
}

export default CommonReducer
