const UnfreezeCard = ({ color = "#2680EA" }) => {
  return (
    <svg
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ width: "-webkit-fill-available" }}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.5 1.75C12.9142 1.75 13.25 2.08579 13.25 2.5V4.68934L14.9697 2.96967C15.2626 2.67678 15.7374 2.67678 16.0303 2.96967C16.3232 3.26256 16.3232 3.73744 16.0303 4.03033L13.25 6.81066V18.1893L16.0303 20.9697C16.3232 21.2626 16.3232 21.7374 16.0303 22.0303C15.7374 22.3232 15.2626 22.3232 14.9697 22.0303L13.25 20.3107V22.5C13.25 22.9142 12.9142 23.25 12.5 23.25C12.0858 23.25 11.75 22.9142 11.75 22.5V20.3107L10.0303 22.0303C9.73744 22.3232 9.26256 22.3232 8.96967 22.0303C8.67678 21.7374 8.67678 21.2626 8.96967 20.9697L11.75 18.1893V6.81066L8.96967 4.03033C8.67678 3.73744 8.67678 3.26256 8.96967 2.96967C9.26256 2.67678 9.73744 2.67678 10.0303 2.96967L11.75 4.68934V2.5C11.75 2.08579 12.0858 1.75 12.5 1.75Z'
        fill={color}
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.01135 4.67809C6.41145 4.57088 6.8227 4.80832 6.9299 5.20842L7.94758 9.00642L12.4996 11.6345L17.0516 9.00642L18.0693 5.20842C18.1765 4.80832 18.5877 4.57088 18.9878 4.67809C19.3879 4.78529 19.6254 5.19654 19.5182 5.59664L18.8887 7.94576L20.7847 6.85109C21.1435 6.64398 21.6022 6.76689 21.8093 7.12561C22.0164 7.48433 21.8935 7.94302 21.5347 8.15013L19.6387 9.2448L21.9878 9.87424C22.3879 9.98144 22.6254 10.3927 22.5182 10.7928C22.411 11.1929 21.9997 11.4303 21.5996 11.3231L17.8016 10.3055L13.9996 12.5005L17.8018 14.6958L21.5998 13.6781C21.9999 13.5709 22.4112 13.8083 22.5184 14.2084C22.6256 14.6085 22.3881 15.0198 21.988 15.127L19.6389 15.7564L21.5349 16.8511C21.8937 17.0582 22.0166 17.5169 21.8095 17.8756C21.6024 18.2343 21.1437 18.3572 20.7849 18.1501L18.8889 17.0555L19.5184 19.4046C19.6256 19.8047 19.3881 20.2159 18.988 20.3231C18.5879 20.4303 18.1767 20.1929 18.0695 19.7928L17.0518 15.9948L12.4996 13.3666L7.94738 15.9948L6.92971 19.7928C6.8225 20.1929 6.41125 20.4303 6.01115 20.3231C5.61105 20.2159 5.37361 19.8047 5.48082 19.4046L6.11026 17.0555L4.21424 18.1501C3.85552 18.3572 3.39683 18.2343 3.18972 17.8756C2.98261 17.5169 3.10552 17.0582 3.46424 16.8511L5.36026 15.7564L3.01115 15.127C2.61105 15.0198 2.37361 14.6085 2.48082 14.2084C2.58803 13.8083 2.99928 13.5709 3.39938 13.6781L7.19738 14.6958L10.9996 12.5005L7.19758 10.3055L3.39957 11.3231C2.99948 11.4303 2.58822 11.1929 2.48102 10.7928C2.37381 10.3927 2.61125 9.98144 3.01135 9.87424L5.36046 9.2448L3.46443 8.15013C3.10572 7.94302 2.98281 7.48433 3.18992 7.12561C3.39702 6.76689 3.85572 6.64398 4.21443 6.85109L6.11046 7.94576L5.48102 5.59664C5.37381 5.19654 5.61125 4.78529 6.01135 4.67809Z'
        fill={color}
      />
      <path
        d='M4.03006 4.53669L3.30955 4.8469C2.5721 5.16441 1.94594 5.69441 1.51096 6.36928L1.13401 6.95413C0.711365 7.60988 0.57331 8.40921 0.751523 9.16874C1.17798 10.9862 3.19636 11.9218 4.85889 11.0726L6.66792 10.1486C8.31712 9.30616 8.86565 7.2158 7.84257 5.67217C7.0136 4.42142 5.40826 3.94331 4.03006 4.53669Z'
        fill='white'
      />
      <path
        d='M19.8074 13.7672L19.0869 14.0774C18.3494 14.3949 17.7233 14.9249 17.2883 15.5998L16.9114 16.1846C16.4887 16.8403 16.3507 17.6397 16.5289 18.3992C16.9553 20.2167 18.9737 21.1523 20.6362 20.3031L22.4453 19.379C24.0945 18.5366 24.643 16.4463 23.6199 14.9026C22.7909 13.6519 21.1856 13.1738 19.8074 13.7672Z'
        fill='white'
      />
      <path
        d='M4.24384 5.31852L4.23286 5.3114L4.22151 5.30487C3.50366 4.89138 2.58652 5.13811 2.17303 5.85597C1.76193 6.56967 2.00344 7.48035 2.71171 7.89722L20.8268 19.6349L20.8378 19.6421L20.8491 19.6486C21.567 20.0621 22.4841 19.8153 22.8976 19.0975C23.3087 18.3838 23.0672 17.4731 22.3589 17.0562L4.24384 5.31852Z'
        fill={color}
        stroke='white'
        stroke-linecap='round'
      />
    </svg>
  )
}

export default UnfreezeCard
