import React from "react"
import OfferLock from "../../../svg/v3/offerLock"
import { useSelector } from "react-redux"
import { hexToRGBA } from "../../../../utils/functions"

const CompletedMilestoneOfferCard = ({ details, isCampaignOffer = false }) => {
  const theme = useSelector(state => state.theme)
  return (
    <div
      className='v3-cvpOfferDetails-completedMilestoneCard'
      style={{
        border: isCampaignOffer ? `solid ${theme?.color1} 2px` : "",
        borderRadius: isCampaignOffer ? "18px" : "12px",
        background: isCampaignOffer
          ? theme?.v3.cssVars.leadingWhite
          : theme?.v3.cssVars.primary.color1,
        color: isCampaignOffer
          ? theme?.v3.cssVars.tertiaryNeutral.color1
          : theme?.v3.cssVars.leadingWhite,
      }}
    >
      <div className='v3-cvpOfferDetails-completedMilestoneCard-lock'>
        <div
          style={{
            background: isCampaignOffer
              ? theme.v3.cssVars.primary.color2
              : hexToRGBA("#ffffff", 0.1),
          }}
        >
          <div
            style={{
              background: isCampaignOffer
                ? theme.v3.cssVars.primary.color3
                : hexToRGBA("#ffffff", 0.4),
            }}
          >
            <div
              style={{
                background: isCampaignOffer
                  ? theme.v3.cssVars.primary.color1
                  : hexToRGBA("#ffffff", 0.9),
              }}
            >
              <OfferLock fill={theme?.color1} />
            </div>
          </div>
        </div>
      </div>
      <div className='v3-cvpOfferDetails-completedMilestoneCard-text'>
        {!isCampaignOffer && (
          <div
            className='v3-cvpOfferDetails-completedMilestoneCard-completedText1'
            style={{ fontWeight: "600" }}
          >
            {details?.completedText1}
          </div>
        )}
        <div className='v3-cvpOfferDetails-completedMilestoneCard-completedText2'>
          {details?.completedText2} 🎉
        </div>
        {isCampaignOffer && (
          <div
            className='v3-cvpOfferDetails-completedMilestoneCard-completedText1'
            style={{ fontWeight: "500" }}
          >
            {details?.completedText1}
          </div>
        )}
      </div>
    </div>
  )
}

export default CompletedMilestoneOfferCard
