const Telephone = ({ color = "#1965CF" }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
    >
      <path
        d='M16.9775 14.2475C16.9775 14.5175 16.9175 14.795 16.79 15.065C16.6625 15.335 16.4975 15.59 16.28 15.83C15.9125 16.235 15.5075 16.5275 15.05 16.715C14.6 16.9025 14.1125 17 13.5875 17C12.8225 17 12.005 16.82 11.1425 16.4525C10.28 16.085 9.4175 15.59 8.5625 14.9675C7.7 14.3375 6.8825 13.64 6.1025 12.8675C5.33 12.0875 4.6325 11.27 4.01 10.415C3.395 9.56 2.9 8.705 2.54 7.8575C2.18 7.0025 2 6.185 2 5.405C2 4.895 2.09 4.4075 2.27 3.9575C2.45 3.5 2.735 3.08 3.1325 2.705C3.6125 2.2325 4.1375 2 4.6925 2C4.9025 2 5.1125 2.045 5.3 2.135C5.495 2.225 5.6675 2.36 5.8025 2.555L7.5425 5.0075C7.6775 5.195 7.775 5.3675 7.8425 5.5325C7.91 5.69 7.9475 5.8475 7.9475 5.99C7.9475 6.17 7.895 6.35 7.79 6.5225C7.6925 6.695 7.55 6.875 7.37 7.055L6.8 7.6475C6.7175 7.73 6.68 7.8275 6.68 7.9475C6.68 8.0075 6.6875 8.06 6.7025 8.12C6.725 8.18 6.7475 8.225 6.7625 8.27C6.8975 8.5175 7.13 8.84 7.46 9.23C7.7975 9.62 8.1575 10.0175 8.5475 10.415C8.9525 10.8125 9.3425 11.18 9.74 11.5175C10.13 11.8475 10.4525 12.0725 10.7075 12.2075C10.745 12.2225 10.79 12.245 10.8425 12.2675C10.9025 12.29 10.9625 12.2975 11.03 12.2975C11.1575 12.2975 11.255 12.2525 11.3375 12.17L11.9075 11.6075C12.095 11.42 12.275 11.2775 12.4475 11.1875C12.62 11.0825 12.7925 11.03 12.98 11.03C13.1225 11.03 13.2725 11.06 13.4375 11.1275C13.6025 11.195 13.775 11.2925 13.9625 11.42L16.445 13.1825C16.64 13.3175 16.775 13.475 16.8575 13.6625C16.9325 13.85 16.9775 14.0375 16.9775 14.2475Z'
        stroke={color}
        stroke-miterlimit='10'
      />
      <path
        d='M14.375 7.25C14.375 6.8 14.0225 6.11 13.4975 5.5475C13.0175 5.03 12.38 4.625 11.75 4.625'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M17 7.25C17 4.3475 14.6525 2 11.75 2'
        stroke={color}
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}

export default Telephone
