import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import ErrorImage from "../../../../assets/images/ServerError.svg"
import { captureEvents, goToRedirectUrl } from "../../../../utils/functions"
import { getHeaderText, showCancelButton } from "../../../../utils/auth"
import {
  AuthChallenges,
  HEADER_HEIGHT_IN_PX,
} from "../../../../utils/constants"
import { Header } from "../../../core"
import { EventName, PwaVersions } from "../../../../utils/enums"
import Error from "../../../svg/errorImage"
import { ScrollView } from "../../../../nativeBaseReplacements"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"

const MpinBlocked = ({ onSubmit }) => {
  const { t } = useTranslation()
  const windowDimensions = useWindowDimensions()

  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)

  const mpinWord = screen?.mpin?.setMpin?.mpinCharacterCase || "mPIN"
  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.MPIN)

  useEffect(() => {
    captureEvents({ eventName: EventName.MPIN_BLOCKED, metadata: {} })
  }, [])

  return (
    <div
      className='mpinBlocked'
      style={{
        minHeight:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? windowDimensions.height
            : null,
        backgroundColor:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? theme.backgroundColor
            : null,
      }}
    >
      <ScrollView w='100%'>
        {showHeader ? (
          <Header
            text={config?.version === PwaVersions.V2_1 ? "Login" : headerText}
            onBack={async () => {
              goToRedirectUrl()
            }}
          />
        ) : (
          <div className='header-disabled'></div>
        )}
        <div
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : null,
          }}
        >
          <div className='mpinBlocked-content'>
            <div
              className='mpinBlocked-img-container'
              style={{ backgroundColor: "#f8f8f8" }}
            >
              {config?.version === PwaVersions.V2_1 ? (
                <Error />
              ) : (
                <img className='mpinBlocked-img' src={ErrorImage} />
              )}
            </div>
            <div className='mpinBlocked-text'>
              <div className='mpinBlocked-text-1' fontFamily={theme.fontFamily}>
                {t("mpin.accessBlocked")}
              </div>
              <div className='mpinBlocked-text-2'>
                {`Your access has been restricted due to multiple incorrect attempts. Reset your ${mpinWord} to continue.`}
              </div>
            </div>
          </div>
          <div className='mpinBlocked-cta-container'>
            <div className='mpinBlocked-cta'>
              {/* <View {...styles.messageContainer}>
              <View {...styles.infoSvgContainer}>
                <Info iconProps={{ viewBox: "0 0 30 30" }} />
              </View>
            </View> */}
              <ColorButton
                text={`Reset ${mpinWord}`}
                onPress={() => onSubmit()}
                mb='10px'
              />
              {!(
                config?.version == PwaVersions.V2 ||
                config?.version == PwaVersions.V2_1
              ) ? (
                <WhiteButton
                  text={t("mpin.goBackCTA")}
                  onPress={() => goToRedirectUrl()}
                />
              ) : null}
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  )
}

export default MpinBlocked
