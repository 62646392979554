import React, { forwardRef, useContext, useRef, useState } from "react"
import { BottomSheet } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css" // Import the styles
import { ColorButton, WhiteButton } from "../core/buttons"
import crossIcon from "../../assets/images/v3/closeBtn.svg"
import { getErrorImage } from "../../utils/functions"
import { ErrorContext } from "../auth/ErrorScreenContext"

const FailureBottomSheet = forwardRef(
  (
    {
      isOpen,
      onClose,
      title,
      subtitle,
      onCtaClick,
      onSecondaryCtaClick,
      showRetryButton = true,
      showSecondaryCta = false,
      primaryCtaText = "Retry",
    },
    ref,
  ) => {
    const sheetRef = useRef()
    const { globalError } = useContext(ErrorContext)
    const [submitLoading, setSubmitLoading] = useState(false)

    const handlePrimaryCtaClick = () => {
      onCtaClick()
    }

    return (
      <BottomSheet open={isOpen} onDismiss={onClose}>
        <span
          className='v3-verify-otp-close-btn'
          style={{ float: "right", marginRight: "20px", marginTop: "20px" }}
          onClick={onClose}
        >
          <img src={crossIcon} />
        </span>
        <div>
          <div ref={sheetRef} className='bottom-sheet'>
            <div className='v3-success-bottomSheet-icon-container'>
              {getErrorImage(globalError?.statusCode)}
            </div>

            <div className='v3-failure-bottom-sheet-text'>
              <div className='v3-success-bottomSheet-title'>{title}</div>
              <div className='v3-success-bottomSheet-subtitle'>{subtitle}</div>
            </div>

            <div className='v3-failure-bottom-sheet-cta-container'>
              {showRetryButton && (
                <ColorButton
                  isLoading={submitLoading}
                  onPress={handlePrimaryCtaClick}
                  text={primaryCtaText}
                  width='100%'
                />
              )}
              {showSecondaryCta && (
                <WhiteButton
                  isLoading={submitLoading}
                  onPress={() => onSecondaryCtaClick()}
                  text='Back to Home'
                  width='100%'
                />
              )}
            </div>
          </div>
        </div>
      </BottomSheet>
    )
  },
)

export default FailureBottomSheet
