import React, { useEffect, useState } from "react"
import { ColorButton } from "../../buttons"
import CloseCross from "../../../svg/v3/closeCross"
import LeftArrow from "../../../svg/v3/leftArrow"
import RightArrow from "../../../svg/v3/rightArrow"
import NudgeCloseBtn from "../../../svg/v3/nudgeCloseBtn"

const ModelNudges = ({ setShowModalNudges, modalNudge }) => {
  const [modalNudges, setModalNudges] = useState([
    JSON.parse(modalNudge?.displayOptions?.nudgeDetails),
  ])

  const [currentSlide, setCurrentSlide] = useState(0)

  useEffect(() => {
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  const handleNext = () => {
    if (currentSlide < modalNudges.length - 1) {
      setCurrentSlide(currentSlide + 1)
    }
  }

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1)
    }
  }
  const handleBtn = () => {
    window.open(modalNudges[currentSlide].redirectionUrl, "_blank")
  }

  const handleVisibleNudges = () => {
    if (modalNudge?.displayOptions?.requireType === "OPTIONAL") {
      localStorage.setItem("showNudgesAgain", false)
    }
  }

  return (
    <div className='v3-modelNudges-overlay'>
      <div className='v3-modelNudges'>
        <div
          className='v3-modelNudges-container'
          style={{ backgroundColor: modalNudges[currentSlide].backgroundColor }}
        >
          <div
            className='v3-modelNudges-close-btn'
            onClick={() => {
              setShowModalNudges(false)
              handleVisibleNudges()
            }}
          >
            <CloseCross />
          </div>
          <div className='v3-modelNudges-banner'>
            <img
              className='v3-modelNudges-image'
              src={modalNudges[currentSlide].illustrationUrl}
              alt='Nudge Banner'
            />
          </div>
          <div className='v3-modelNudges-content'>
            <div
              className='v3-modelNudges-headline'
              style={{ color: modalNudges[currentSlide].titleColor }}
            >
              {modalNudges[currentSlide].title}
            </div>
            <div
              className='v3-modelNudges-content-text'
              style={{ color: modalNudges[currentSlide].subtitleColor }}
            >
              {modalNudges[currentSlide].description}
            </div>
          </div>
          <div className='v3-modelNudges-btn'>
            <ColorButton text={"See Offers"} onPress={handleBtn} />
          </div>
        </div>
        <div className='v3-modelNudges-slides-controls'>
          <div
            className={`v3-modelNudges-left-slider ${
              currentSlide === 0 ? "disabled" : ""
            }`}
            onClick={handlePrevious}
          >
            <LeftArrow />
          </div>
          <div className='v3-modelNudges-slide-count'>
            {currentSlide + 1}/{modalNudges.length}
          </div>
          <div className={`v3-modelNudges-right-slider`} onClick={handleNext}>
            {currentSlide === modalNudges.length - 1 ? (
              <div
                onClick={() => {
                  setShowModalNudges(false)
                  handleVisibleNudges()
                }}
              >
                <NudgeCloseBtn />
              </div>
            ) : (
              <RightArrow />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModelNudges
