import { getV3Theme } from "../../../utils/theme"

const CardSettingsDomestic = ({
  color1 = getV3Theme().secondary.color1,
  className,
}) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M18.25 21.75H14V16.5C14 15.535 13.215 14.75 12.25 14.75C11.285 14.75 10.5 15.535 10.5 16.5V21.75H6.25C3.832 21.75 2.5 20.418 2.5 18V11.65C2.5 9.52704 3.08599 8.93401 4.04199 8.14101L10.162 3.01003C11.371 1.99503 13.129 1.99503 14.338 3.01003L20.458 8.14101C21.414 8.93401 22 9.52804 22 11.65V18C22 20.418 20.668 21.75 18.25 21.75ZM15.5 20.25H18.25C19.827 20.25 20.5 19.577 20.5 18V11.65C20.5 10.124 20.248 9.91506 19.501 9.29506L13.375 4.15908C12.723 3.61308 11.777 3.61308 11.125 4.15908L4.99902 9.29506C4.25202 9.91506 4 10.124 4 11.65V18C4 19.577 4.673 20.25 6.25 20.25H9V16.5C9 14.708 10.458 13.25 12.25 13.25C14.042 13.25 15.5 14.708 15.5 16.5V20.25Z'
        fill={color1}
      />
    </svg>
  )
}

export default CardSettingsDomestic
