import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function ContentWrapper() {
  return (
    <CardSkeleton
      justifyContent='space-between'
      height='10px'
      display='flex'
      width='100%'
      margin="40px 0px"
    >
      <TextWrapperSkeleton width='150PX' />
      <TextWrapperSkeleton width='50px' />
    </CardSkeleton>
  )
}

function StatementLoader() {
  return (
    <>
      <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        br='0'
        height="56px"
      >
        <TextWrapperSkeleton height='12px' width='150px' />
      </CardSkeleton>
      <CardSkeleton margin='20px auto' width='90%' height='180px'>
        <CardSkeleton
          display='flex'
          justifyContent='space-between'
          padding='12px'
          width='90%'
          margin='20px auto'
          height='60px'
        >
          <CardSkeleton width='45%'>
            <TextWrapperSkeleton margin='15px auto' width='100px' />
          </CardSkeleton>
          <CardSkeleton width='45%'>
            <TextWrapperSkeleton margin='15px auto' width='100px' />
          </CardSkeleton>
        </CardSkeleton>
        <CardSkeleton padding='15px' margin='auto' width='90%'>
          <TextWrapperSkeleton margin='auto' width='200px' />
        </CardSkeleton>
      </CardSkeleton>

      <CardSkeleton
        padding='12px'
        width='90%'
        margin='20px auto'
        height='500px'
      >
        <TextWrapperSkeleton width='150px' />
        <TextWrapperSkeleton margin='30px 0px 40px 0px' width='100px' />
        <ContentWrapper/>
        <ContentWrapper/>
        <ContentWrapper/>
        <ContentWrapper/>
        <ContentWrapper/>
        <ContentWrapper/>
        <ContentWrapper/>

        <TextWrapperSkeleton margin='20px 0px' width='200px' />
      </CardSkeleton>
    </>
  )
}

export default StatementLoader
