const TokenisationPause = ({ color }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='pause-circle'>
        <path
          id='pause-circle_2'
          d='M7.00118 13.2707C3.54318 13.2707 0.730347 10.4578 0.730347 6.99984C0.730347 3.54184 3.54318 0.729004 7.00118 0.729004C10.4592 0.729004 13.272 3.54184 13.272 6.99984C13.272 10.4578 10.4592 13.2707 7.00118 13.2707ZM7.00118 1.604C4.0256 1.604 1.60535 4.02425 1.60535 6.99984C1.60535 9.97542 4.0256 12.3957 7.00118 12.3957C9.97676 12.3957 12.397 9.97542 12.397 6.99984C12.397 4.02425 9.97676 1.604 7.00118 1.604ZM5.87418 9.77067H5.21095C4.70345 9.77067 4.37561 9.44634 4.37561 8.94409V5.05501C4.37561 4.55335 4.70345 4.22843 5.21095 4.22843H5.87418C6.38168 4.22843 6.70951 4.55276 6.70951 5.05501V8.94409C6.70951 9.44634 6.38168 9.77067 5.87418 9.77067ZM5.25118 8.89567H5.83216L5.83451 5.10685L5.25353 5.10457L5.25118 8.89567ZM8.79084 9.77067H8.12761C7.62011 9.77067 7.29228 9.44634 7.29228 8.94409V5.05501C7.29228 4.55335 7.62011 4.22843 8.12761 4.22843H8.79084C9.29834 4.22843 9.62618 4.55276 9.62618 5.05501V8.94409C9.62618 9.44634 9.29834 9.77067 8.79084 9.77067ZM8.16785 8.89567H8.74883L8.75118 5.10685L8.1702 5.10457L8.16785 8.89567Z'
          fill={color || "#0D2950"}
        />
      </g>
    </svg>
  )
}

export default TokenisationPause
