const TokenisationPower = ({ color }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.4'
        d='M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z'
        fill={color || "#1965CF"}
      />
      <path
        d='M9.00006 8.77057C8.68956 8.77057 8.43756 8.51857 8.43756 8.20807V4.875C8.43756 4.5645 8.68956 4.3125 9.00006 4.3125C9.31056 4.3125 9.56256 4.5645 9.56256 4.875V8.20807C9.56256 8.51857 9.31056 8.77057 9.00006 8.77057ZM12.8956 9.04193C12.8956 8.00393 12.489 7.02604 11.751 6.28729C11.532 6.06829 11.1758 6.06679 10.9553 6.28729C10.7355 6.50629 10.7355 6.86332 10.9553 7.08307C11.481 7.60882 11.7706 8.30468 11.7706 9.04193C11.7706 10.5697 10.5278 11.8125 9.00006 11.8125C7.47231 11.8125 6.22949 10.5697 6.22949 9.04193C6.22949 8.30468 6.51911 7.60882 7.04486 7.08307C7.26461 6.86332 7.26461 6.50704 7.04486 6.28729C6.82436 6.06679 6.46808 6.06829 6.24908 6.28729C5.51108 7.02604 5.10449 8.00393 5.10449 9.04193C5.10449 11.1899 6.85206 12.9375 9.00006 12.9375C11.1481 12.9375 12.8956 11.1899 12.8956 9.04193Z'
        fill={color || "#1965CF"}
      />
    </svg>
  )
}

export default TokenisationPower
