import React from "react"
import { useSelector } from "react-redux"
import { DisplayConfig } from "../../utils/constants"
const getNewObj = (arr, type) => {
  const tempObj =
    type === "link"
      ? {
          type: "link",
          linkText: "",
          linkUrl: "",
        }
      : {
          type: "text",
          text: "",
        }

  arr.push(tempObj)

  return tempObj
}
const checkLink = (char, obj, flagObject) => {
  if (char === "(") {
    flagObject.isLinkText = true
    flagObject.isLinkUrl = false
  } else if (char === ")" || char === "[") {
    flagObject.isLinkText = false
    flagObject.isLinkUrl = true
  } else if (char === "]") {
    flagObject.isLinkText = false
    flagObject.isLinkUrl = false
  } else {
    if (flagObject.isLinkText) {
      obj.linkText += char
    } else if (flagObject.isLinkUrl) {
      obj.linkUrl += char
    } else {
      obj.text += char
    }
  }
  return flagObject
}
const getEmbeddedLinkObjects = formattedText => {
  const arr = []
  let obj = null

  let flagObject = {}
  flagObject.isLinkText = false
  flagObject.isLinkUrl = false
  let prevType = ""
  for (let i = 0; i < formattedText.length; i++) {
    const char = formattedText.charAt(i)
    const prevChar = i > 0 ? formattedText.charAt(i - 1) : null

    if (prevChar === "]" || (i === 0 && char !== "(")) {
      obj = getNewObj(arr, "text")
    } else if (prevChar === "(") {
      obj = getNewObj(arr, "link")
    }

    if (char === " " && prevType === "text") {
      obj = getNewObj(arr, prevType)
      continue
    }
    prevType = obj.type
    flagObject = checkLink(char, obj, flagObject)
  }
  return arr
}

const EmbeddedLink = ({
  text,
  linkStyle = {},
  openInNewTab = true,
  ...otherProps
}) => {
  const theme = useSelector(state => state.theme)
  return (
    <div>
      {getEmbeddedLinkObjects(text).map((s, ind) =>
        s.type === "text" ? (
          <a style={ind == 0 ? { marginLeft: "1px" } : { marginLeft: "0px" }}>
            {s.text?.replace(/\s/g, "\u00A0")}{" "}
          </a>
        ) : (
          <a
            href={s.linkUrl}
            target={openInNewTab ? "_blank" : undefined}
            style={{
              display: "inline-flex",
              color: theme.color1,
              textDecoration: "none",
            }}
            {...linkStyle}
          >
            {s.linkText?.replace(/\s/g, "\u00A0")}
          </a>
        ),
      )}
    </div>
  )
}

export { EmbeddedLink, getEmbeddedLinkObjects }
