import store from "../store/Store"
import { expireSession } from "../store/actions/Session"
import { DEFAULT_TENANT } from "../utils/enums"
import { getApiConfig } from "../utils/functions"
import Service from "./Service"

const CommonService = {
  getSessionInfo: () => Service.get(`/get-session-info`),

  sendHeartBeat: async () => {
    const session = store.getState().session
    try {
      const response = await fetch(
        process.env.REACT_APP_API_BASE_URL + "/pwa/send-heartbeat",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${session.deviceToken}`,
            tenantId: localStorage.getItem("tenantId") || DEFAULT_TENANT,
          },
        },
      )

      if (response.status === 401) {
        store.dispatch(expireSession())
        return response
      }
      return response.json()
    } catch (error) {
      throw new Error("Network error")
    }
  },

  getLrSsoUrl: () => Service.get(`/get-lrSsoUrl`),

  generateLrSsoUrl: data => Service.post(`/generate-lrSsoUrl`, data),

  getDesignConfig: () =>
    Service.get(
      `/get-design-config?accountId=${localStorage.getItem("accountId")}`,
    ),

  generateDeviceId: data => Service.post(`/generate-device-id`, data),

  verifyDeviceId: data => Service.post(`/verify-device-id`, data),

  getAfaDetails: data => Service.post(`/get-afa-details`, data),

  generateOTP: data => Service.post(`/generate-otp`, data),

  verifyOTP: data => Service.post(`/verify-otp`, data),

  generateChallengeOTP: data => Service.post(`/generate-challenge-otp`, data),

  verifyChallengeOTP: data => Service.post(`/verify-challenge-otp`, data),

  generateChallengeEmailOTP: data =>
    Service.post(`/generate-challenge-email-otp`, data),

  verifyChallengeOTPEmail: data =>
    Service.post(`/verify-challenge-email-otp`, data),

  setMpin: (apiToken, data) =>
    Service.post(`/set-mpin`, data, getApiConfig(apiToken)),

  verifyChallengeMpin: data => Service.post(`/verify-challenge-mpin`, data),

  verifyChallengeCustomer: data =>
    Service.post(`/verify-challenge-customer`, data),

  multiwalletPwaRedirection: () => {
    return Service.post("/redirection/multiwallet")
  },
}

export default CommonService
