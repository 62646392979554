import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { Header } from "../../core"
import { useParams } from "react-router-dom"
import OfferDetailsAccordian from "./Components/offerDetailsAccordian"
import Divider from "../../core/Divider/v3/Divider"
import { ColorButton } from "../../core/buttons"
import PendingMilestoneCard from "./Components/pendingMilestoneCard"
import CompletedMilestoneOfferCard from "./Components/completedMilestoneOfferCard"
import arrowBullet from "../../../assets/images/v3/arrowBullet.svg"
import { hexToRGBA } from "../../../utils/functions"
import defaultBanner from "../../../assets/images/v3/BrandBannerCVP.svg"
import defaultLogo from "../../../assets/images/v3/Vector.svg"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { useBottomSheet } from "../../auth/BottomSheetContext"
import { BottomSheetType } from "../../../utils/enums"
import AccountService from "../../../services/AccountService"
import { consoleError } from "../../../utils/functions"
import { ErrorContext } from "../../auth/ErrorScreenContext"
import { ErrorType, offerType, offerVisibility } from "../../../utils/constants"
import SubscribeRedirectIcon from "../../../assets/images/v3/subscribeRedirect.svg"
import { setCvpBenefits } from "../../../store/actions/Benefits"
import { useSubscribeOffer } from "./subscribeOffer"

const CvpOfferDetails = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const bottomSheet = useBottomSheet()
  const benefits = useSelector(state => state.benefits)
  const params = useParams()
  const { setErrorState } = useContext(ErrorContext)
  const { subscribeOffer } = useSubscribeOffer()

  const { BenefitId } = params
  const offer = benefits?.cvpBenefits?.filter(offer => {
    return offer?.benefitId === BenefitId
  })?.[0]

  let offerDetails
  if (offer) {
    offerDetails = JSON.parse(offer?.offerDetails)
  }
  const [isOptedIn, setIsOptedIn] = useState(offer?.isAccountOptedIn)

  const handleCtaClick = () => {
    if (offerDetails?.ctaLink) {
      window.location.href = offerDetails?.ctaLink
    }
  }

  const isOptInOffer = offer?.visibility === offerVisibility.OPT_IN

  return (
    <>
      <div
        style={{
          height: window.innerHeight,
          backgroundColor: theme?.backgroundColor,
          overflowY: "auto",
          color: theme?.v3.cssVars.tertiaryNeutral.color1,
        }}
        className='v3-cvpOfferDetails'
      >
        <Header
          text={`${t("offers.cvpOfferDetailsHeaderPrefix")} - ${offer?.displayTitle}`}
          onBack={async () => {
            window.history.go(-1)
          }}
        />
        <div className='v3-cvpOfferDetails-banner-container'>
          {offer?.illustrationUrl ? (
            <img
              src={offer?.illustrationUrl}
              className='v3-cvpOfferDetails-banner'
              alt='banner'
            />
          ) : (
            <img src={defaultBanner} className='v3-cvpOfferDetails-banner' />
          )}
          <div className='v3-cvpOfferDetails-logo'>
            {offer?.logoUrl ? (
              <img src={offer?.logoUrl} alt='logo' />
            ) : (
              <img src={defaultLogo} style={{ background: "white" }} />
            )}
          </div>
        </div>
        <div className='v3-cvpOfferDetails-text-container'>
          <div className='v3-cvpOfferDetails-text-description'>
            {offer?.displayDescription || offer?.description}
          </div>
          {offer?.threshold !== 0 && (
            <>
              {offer?.currentValue >= offer?.threshold ? (
                <>
                  <CompletedMilestoneOfferCard details={offerDetails} />
                  {offerDetails?.completedText3 && (
                    <div
                      className='v3-cvpOfferDetails-completedOffer-infoCard'
                      style={{
                        background: theme?.v3.cssVars.leadingWhite,
                      }}
                    >
                      <div
                        style={{
                          background: theme.v3.cssVars.tertiaryNeutral.color3,
                        }}
                      >
                        <img src={arrowBullet} alt='bullet' />
                        <div>{offerDetails?.completedText3}</div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <PendingMilestoneCard details={offerDetails} offer={offer} />
              )}
            </>
          )}
          {offerDetails?.content && (
            <div className='v3-cvpOfferDetails-text-accordian-container'>
              {offerDetails?.content?.map((element, index) => {
                return (
                  <>
                    {index !== 0 && (
                      <Divider
                        height='1px'
                        color={theme.v3.rawColors.tertiaryNeutral.color3}
                      />
                    )}
                    <OfferDetailsAccordian
                      header={element?.title}
                      points={element?.points}
                      open={index === 0}
                    />
                  </>
                )
              })}
            </div>
          )}
        </div>
      </div>
      <div
        className='v3-cvpOfferDetails-cta'
        style={{ background: theme?.v3.cssVars.leadingWhite }}
      >
        <ColorButton
          text={
            isOptInOffer && !isOptedIn
              ? t("offers.optInNowText")
              : offerDetails?.ctaText
          }
          width={"100%"}
          onPress={
            isOptInOffer && !isOptedIn
              ? () =>
                  subscribeOffer(
                    offer?.benefitId,
                    setIsOptedIn,
                    offerType.PROGRAM_OFFERS,
                  )
              : handleCtaClick
          }
          isDisable={
            offer?.threshold !== 0 && offer?.currentValue < offer?.threshold
          }
          redirectIcon={
            isOptInOffer && !isOptedIn ? SubscribeRedirectIcon : null
          }
        />
      </div>
    </>
  )
}

export default CvpOfferDetails
