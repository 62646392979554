import React from "react"
import DefaultCompanyLogo from "../../../../assets/images/DefaultCompanyLogo.svg"
import Star from "../../../../assets/images/Star.svg"
import arrowRight from "../../../../assets/images/arrowRight.svg"
import CommonService from "../../../../services/CommonService"
import { useTranslation } from "react-i18next"

const NewUserMultiwalletWidget = ({ session, user }) => {
  const date = new Date()
  const { t } = useTranslation()
  const month = date.toLocaleString("en-US", { month: "long" })
  const year = date.getFullYear().toString().substring(2)
  const handleRedirectionClick = async () => {
    try {
      const response = await CommonService.multiwalletPwaRedirection()
      if (response.data?.success) {
        document.cookie =
          "sessionData=" +
          JSON.stringify({
            deviceToken: session.deviceToken,
            accountId: user.account?.id,
            firstName: user?.customer?.firstName,
            nativeAppUrl: session?.redirectUrl,
            tenantId: localStorage.getItem("tenantId"),
          }) +
          ";expires=1d;domain=.hyperface.co;path=/"
        const redirectionUrl = response.data.data.redirectionUrl
        if (redirectionUrl) {
          window.location.href = redirectionUrl
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='home-benefits-widget'>
      <div className='benifits-header'>
        <div className='benifits-left-content'>
          <div className='benifits-header-image'>
            <img src={DefaultCompanyLogo} alt='Benifits image' />
          </div>
          <div className='benifits-header-text'>
            <div className='benifits-header-title'>
              {t("NewUserMultiwalletWidgetV3.benefitsHeaderTitle")}
            </div>
            <div className='benifits-header-org-name'>
              {t("NewUserMultiwalletWidgetV3.benefitsHeaderOrgName")}
            </div>
          </div>
        </div>
        <div
          className='benefits-right-chevron'
          onClick={handleRedirectionClick}
        >
          <img src={arrowRight} />
        </div>
      </div>
      <div className='benifits-body' style={{ marginTop: 0 }}>
        <div className='benefits-body-header'>
          <div className='benefits-body-header-icon'>
            <img src={Star} alt='star' />
          </div>
          <div className='benefits-body-header-text'>
            {t("NewUserMultiwalletWidgetV3.benefitsBodyHeader")}{" "}
            {user?.customer?.firstName}!
          </div>
        </div>
        <div className='benefits-body-text'>
          {t("NewUserMultiwalletWidgetV3.benefitsBodyTextProcessedDate", {
            month: month,
            year: year,
          })}
        </div>
      </div>
    </div>
  )
}

export default NewUserMultiwalletWidget
