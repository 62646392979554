const styles = {
  mainView: {
    p: "5",
    mt: 5,
  },
  cardImage: {
    width: "100%",
    height: "150",
    borderRadius: "15",
    resizeMode: "stretch",
  },
  cardTextMainView: {
    width: "90%",
    position: "absolute",
    ml: 5,
    mt: 5,
    borderColor: "#FFF",
  },
  cardTextName: {
    color: "#FFF",
    textTransform: "uppercase",
    mb: "1",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "13",
    letterSpacing: "1",
  },
  cardNumber: {
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "13",
    letterSpacing: "2",
  },
  expiryCvvMainView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: "9",
  },
  expiryCvvText: {
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "12",
    letterSpacing: "1",
  },
  expiryCvvTextNumber: {
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "10",
    letterSpacing: "1",
  },
  circleMainView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "40%",
  },
  circle: {
    width: "7",
    height: "7",
    opacity: "0.5",
    borderRadius: "25",
    backgroundColor: "#F8F8F8",
  },
  clientText: {
    my: "24px",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "26px",
    letterSpacing: "0.02em",
    color: "#303030",
  },
  physicalpayLatertext: {
    mt: "24px",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.02em",
    color: "#7B7B7B",
  },
}

export default styles
