import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import MerchantOffersCarousel from "../../../../../OffersList/v3/Components/merchantOfferCarousel"
import { useTranslation } from "react-i18next"

const MerchantOffer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const screen = useSelector(state => state.screen)
  const benefits = useSelector(state => state.benefits)

  const allMerchantOffers = benefits?.merchantBenefits?.filter(offer => {
    return !offer?.tags.includes("Hero Offer")
  })

  const handleViewAllClick = path => {
    navigate(path)
  }
  return (
    allMerchantOffers &&
    allMerchantOffers.length > 0 && (
      <div className='v3-offers-section-2'>
        <div className='v3-offers-section-header'>
          <div className='v3-offers-section-header-title'>
            {" "}
            {t("offers.dealsOnBrands")}
          </div>
          <div
            className='v3-offers-section-header-cta'
            onClick={() => handleViewAllClick("/MerchantOffers")}
          >
            {t("offers.ViewAll")}
          </div>
        </div>
        <MerchantOffersCarousel
          maxNumberOfCards={
            screen?.home?.homeMiscellaneous?.merchantOffersListLength || 3
          }
        />
      </div>
    )
  )
}

export default MerchantOffer
