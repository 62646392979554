import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import Mpin_v3 from "./Mpin_v3"
import Mpin from "./Mpin"

const MpinComp = () => {
  const config = useSelector(state => state.config)
  const getVersionedMpin = version => {
    if (version === PwaVersions.V3) return <Mpin_v3 />
    return <Mpin />
  }
  return getVersionedMpin(config?.version)
}

export default MpinComp
