import React, { forwardRef } from "react"
import View from "./View"

/** for now only replaces vertical scrollview */
const ScrollView = forwardRef((props, ref) => {
  const { style, ...restProps } = props
  return (
    <View
      nbr={"ScrollView"}
      ref={ref}
      {...restProps}
      style={{
        flexGrow: 1,
        flexShrink: 1,
        // Enable hardware compositing in modern browsers.
        // Creates a new layer with its own backing surface that can significantly
        // improve scroll performance.
        transform: "translateZ(0)",
        // iOS native scrolling
        WebkitOverflowScrolling: "touch",
        flexDirection: "column",
        overflowX: "hidden",
        overflowY: "auto",
        ...style,
      }}
    />
  )
})

export default ScrollView
