const CopyToClipboard = ({ color = "#1965CF", width = "8", height = "8" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.03877 2.30059H3.27839C2.59161 2.30059 2.21367 2.67852 2.21367 3.3653V6.12569C2.21367 6.81247 2.59161 7.1904 3.27839 7.1904H6.03877C6.72555 7.1904 7.10349 6.81247 7.10349 6.12569V3.3653C7.10349 2.67852 6.72555 2.30059 6.03877 2.30059ZM6.63028 6.12569C6.63028 6.54589 6.45898 6.7172 6.03877 6.7172H3.27839C2.85818 6.7172 2.68688 6.54589 2.68688 6.12569V3.3653C2.68688 2.94509 2.85818 2.77379 3.27839 2.77379H6.03877C6.45898 2.77379 6.63028 2.94509 6.63028 3.3653V6.12569ZM1.42499 2.10184V4.86538C1.42499 5.24331 1.57739 5.33638 1.62755 5.36729C1.73923 5.43543 1.77387 5.58118 1.70573 5.69255C1.66093 5.76542 1.58336 5.80548 1.50355 5.80548C1.46159 5.80548 1.41865 5.79412 1.38017 5.77078C1.09592 5.59664 0.951782 5.29221 0.951782 4.86538V2.10184C0.951782 1.42609 1.33953 1.0387 2.01496 1.0387H4.77842C5.30999 1.0387 5.56399 1.27182 5.68387 1.4671C5.75201 1.57846 5.71697 1.72421 5.60561 1.79235C5.49394 1.86081 5.34882 1.82548 5.28036 1.71412C5.24976 1.66396 5.15636 1.5116 4.77842 1.5116H2.01496C1.59601 1.51191 1.42499 1.68289 1.42499 2.10184Z'
        fill={color}
      />
    </svg>
  )
}

export default CopyToClipboard
