import React, { useContext, useState } from "react"
import InfiniteScroll from "../../../core/InfiniteScrollV3"
import RecentTransactions from "./RecentTransactionTable"
import AccountService from "../../../../services/AccountService"
import { ErrorType } from "../../../../utils/constants"
import { ErrorContext } from "../../../auth/ErrorScreenContext"
import { useSelector } from "react-redux"
import { resetErrorTryAgainCount } from "../../../../utils/functions"
import ZeroTransactions from "./ZeroTransactions"
import CubeAppLoader from "../../../core/InAppLoader"

const WebTransactionList = ({
  state,
  dropDownSelected,
  selectTransationType,
  filterList,
  setSelectedTransaction,
}) => {
  const [transactions, setTransactions] = useState([])
  const { setErrorState } = useContext(ErrorContext)
  const user = useSelector(state => state.user)

  const fetchTransactions = async (count, offset) => {
    const accountId = user?.account?.id
    const from = dropDownSelected?.from || state?.from
    const to = dropDownSelected?.to || state?.to
    try {
      const response = await AccountService.getTransactions(user?.account?.id, {
        count,
        offset,
        from: from,
        to: to,
        txnType: selectTransationType.value,
        // merchantCategoryCode: billedTransactionFilters?.merchantCategoryCode,
        // txnNature: billedTransactionFilters?.txnNature,
      })
      const status = response.status

      if (!response.data.success) {
        setErrorState(response?.status, () =>
          fetchTransactions(accountId, count, offset, from, to),
        )
        throw new Error("Api error, couldn't fetch transactions")
      }

      resetErrorTryAgainCount()
      if (status === 200) {
        setTransactions(prev => [...prev, ...response.data.data.transactions])
      }
      const hasMoreFlag = response?.data?.data?.hasMore
      return { status, hasMoreFlag }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          fetchTransactions(accountId, count, offset, from, to),
        )
      } else {
        console.error(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          fetchTransactions(accountId, count, offset, from, to),
        )
      }
    }
  }
  return (
    <InfiniteScroll
      dataLength={10}
      next={fetchTransactions}
      list={transactions}
      setList={setTransactions}
      loadingDelay={100}
      displayElement={data => (
        <RecentTransactions
          transactions={data}
          currentPage={"ALL_TRANSACTIONS"}
          dropDownSelected={dropDownSelected}
          setSelectedTransaction={setSelectedTransaction}
        />
      )}
      selectedFilter={filterList}
      hasPullDownToRefresh={false}
      hasScrollTopTopOption={false}
      fromDate={dropDownSelected?.from || state?.from}
      toDate={dropDownSelected?.to || state?.to}
      zeroStateComponent={() => (
        <ZeroTransactions
          currentPage={"ALL_TRANSACTIONS"}
          dateRange={dropDownSelected}
        />
      )}
    />
  )
}

export default WebTransactionList
