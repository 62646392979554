import React from "react"
import { useTranslation } from "react-i18next"
import txnLogo from "../../assets/images/v3/makeMyTrip.svg"
import Warning from "../svg/v3/warning"
import Refresh from "../svg/v3/refresh"
import { formatAmount } from "../../utils/functions"
import { useNavigate } from "react-router-dom"
import Transaction_v3 from "./Transaction_v3"
import ZeroStateTransactionsV3 from "./ZeroStateTransactionsV3"
import TransactionListLoader from "./SkeletonLoader/TransactionListLoader"
import ZeroStateTransactions from "../Home/Components/TransactionSection/v3/ZeroStateTransactions"

const StatementTransaction_v3 = ({
  transactionHeader,
  transactionsData,
  isViewAllTransactionEnabled,
  styles,
  isHighlightSectionOpen,
  isTransactionsAvailable,
  selectedCycle,
  from,
  to,
  isTransactionsLoading,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleViewAllTransactionClick = () => {
    navigate("/Transactions", {
      state: {
        cycle: selectedCycle,
        from: from,
        to: to,
      },
    })
  }
  return (
    <div
      className='v3-transactions-section'
      style={{ ...styles, top: isHighlightSectionOpen ? "500px" : "380px" }}
    >
      <div className='v3-transactions-section-header'>{transactionHeader}</div>
      {isTransactionsAvailable && !isTransactionsLoading ? (
        <div className='v3-transactions-container'>
          {transactionsData?.map((transaction, index) => {
            return <Transaction_v3 transaction={transaction} />
          })}

          {/* these transaction contains some other states that will be used when we get flags api data  */}

          {/* <div className='v3-transaction-content'>
                        <div className='v3-transaction-section'>
                            <div className='v3-transaction-left-section'>
                                <div className='v3-transaction-logo'>
                                    <img src={txnLogo} alt='transaction logo' />
                                </div>
                                <div className='v3-transaction-description'>
                                    <div className='v3-transaction-title'>
                                        MakeMyTrip
                                    </div>
                                    <div className='v3-transaction-created-date'>
                                        29 Jul
                                    </div>
                                </div>
                            </div>
                            <div className='v3-transaction-right-section'>
                                <div className='v3-transaction-amount'>
                                    ₹6501.00
                                </div>
                                <div className='v3-transaction-reward-point'>
                                    + 200 pts
                                </div>
                            </div>
                        </div>
                    </div> */}
          {/* <div className='v3-transaction-content'>
                        <div className='v3-transaction-section'>
                            <div className='v3-transaction-left-section'>
                                <div className='v3-transaction-logo'>
                                    <img src={txnLogo} alt='transaction logo' />
                                </div>
                                <div className='v3-transaction-description'>
                                    <div className='v3-transaction-title'>
                                        MakeMyTrip
                                    </div>
                                    <div className='v3-transaction-created-date'>
                                        29 Jul
                                    </div>
                                </div>
                            </div>
                            <div className='v3-transaction-right-section'>
                                <div className='v3-transaction-amount'>
                                    ₹6501.00
                                </div>
                                <div className='v3-transaction-cashback-amount'>
                                    ₹200 cashback
                                </div>
                            </div>
                        </div> */}
          {/* <div className='v3-transaction-emi-failed-nudge'>
                            <div className='v3-transaction-emi-failed-nudge-left-section'>
                                <div className='v3-transaction-emi-failed-logo'>
                                    <Warning size={'14px'} />
                                </div>
                                <div className='v3-transaction-emi-failed-text'>
                                    Conversion to EMI Failed. <span className='v3-transaction-emi-failed-logo-retry-text'>Tap to Retry</span>
                                </div>
                            </div>
                            <div className='v3-transaction-emi-failed-retry-logo'>
                                <Refresh size={'16px'} />
                            </div>
                        </div> */}
          {/* </div> */}
          {isViewAllTransactionEnabled && (
            <div
              className='v3-view-all-transaction-button'
              onClick={handleViewAllTransactionClick}
            >
              {t("transactions.viewTransactionsText")}
            </div>
          )}
        </div>
      ) : !isTransactionsLoading ? (
        //zero state for transactions
        <div className='v3-statement-transaction-zero-state-wrapper'>
          <ZeroStateTransactions
            billingCycle={{
              from: from,
              to: to,
            }}
          />
        </div>
      ) : (
        <TransactionListLoader />
      )}
    </div>
  )
}

export default StatementTransaction_v3
