
const initialState = {
  locales: "en-IN",
  localeOptions: { style: "currency", currency: "INR" },
  dateFormat: "DD MMM YYYY",
  timeFormat: "hh:mm a",
}

const PreferencesReducer = (state = initialState, action) => {
  return state
}

export default PreferencesReducer
