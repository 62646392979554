import React from "react"

const HeaderArrow = ({ color }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
    >
      <path
        d='M8.80781 3.93246L3.18281 9.55746C3.1247 9.61551 3.0786 9.68444 3.04715 9.76031C3.0157 9.83619 2.99951 9.91752 2.99951 9.99965C2.99951 10.0818 3.0157 10.1631 3.04715 10.239C3.0786 10.3149 3.1247 10.3838 3.18281 10.4418L8.80781 16.0668C8.92509 16.1841 9.08415 16.25 9.25 16.25C9.41585 16.25 9.57491 16.1841 9.69219 16.0668C9.80946 15.9496 9.87535 15.7905 9.87535 15.6247C9.87535 15.4588 9.80946 15.2997 9.69219 15.1825L5.13359 10.6247H17.375C17.5408 10.6247 17.6997 10.5588 17.8169 10.4416C17.9342 10.3244 18 10.1654 18 9.99965C18 9.83389 17.9342 9.67492 17.8169 9.55771C17.6997 9.4405 17.5408 9.37465 17.375 9.37465H5.13359L9.69219 4.81684C9.80946 4.69956 9.87535 4.5405 9.87535 4.37465C9.87535 4.2088 9.80946 4.04974 9.69219 3.93246C9.57491 3.81519 9.41585 3.7493 9.25 3.7493C9.08415 3.7493 8.92509 3.81519 8.80781 3.93246Z'
        fill={color || "#343434"}
      />
    </svg>
  )
}

export default HeaderArrow
