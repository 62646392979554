import React, { forwardRef, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { BottomSheet } from "react-spring-bottom-sheet"
import Cross from "../../../svg/v3/Cross"
import ArrowRight from "../../../svg/arrowRight"
import { useDispatch, useSelector } from "react-redux"
import {
  captureEvents,
  formatAmount,
  isWebKitBrowser,
  resetErrorTryAgainCount,
} from "../../../../utils/functions"
import { useNavigate } from "react-router-dom"
import { AmountTypes, EventName } from "../../../../utils/enums"
import {
  setPgAmount,
  setPgConfig,
} from "../../../../store/actions/PaymentGateway"
import AccountService from "../../../../services/AccountService"
import Divider from "../../../core/Divider/v3/Divider"
import CurrencyFormat from "react-currency-format"
import { ColorButton } from "../../../core/buttons"
import Info from "../../../svg/info"
import crossIcon from "../../../../assets/images/v3/closeBtn.svg"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import { ErrorType } from "../../../../utils/constants"
import ArrowRightLarge from "../../../svg/v3/arrowRightSmall"
import { ErrorContext } from "../../../auth/ErrorScreenContext"

const PaymentGatewayV3 = forwardRef(({ isOpen, onClose, ref }) => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setErrorState } = useContext(ErrorContext)

  const [showCustomAmountPage, setShowCustomAmountPage] = useState(false)
  const [inputValue, setInputValue] = useState()
  const [isError, setIsError] = useState(false)

  const totalAmountDue = user?.summary?.lastStatement?.totalAmountDue || 0
  const minimumAmountDue = user?.summary?.lastStatement?.minimumAmountDue || 0
  const balanceAmountDue = user?.summary?.lastStatement?.balanceAmountDue || 0
  const dueDate = user?.summary?.lastStatement?.dueDate

  const amountTypes = screen.home.repayments.amountType

  useEffect(() => {
    if (isOpen) {
      captureEvents({
        eventName: EventName.PAY_BILL_AMOUNT_SELECTION_PAGE,
        metadata: {},
      })
    }
  }, [isOpen])

  const handleArrowClick = async selectedValue => {
    let amountPayable
    if (selectedValue === "TOTAL_DUES") amountPayable = balanceAmountDue
    else if (selectedValue === "MINIMUM_DUES") amountPayable = minimumAmountDue
    else if (selectedValue === "CUSTOM") {
      amountPayable = inputValue || 0
    }

    if (!amountPayable || amountPayable <= 0) {
      setIsError(true)
      return false
    }
    dispatch(
      setPgAmount({
        amountPayable: amountPayable,
        paymentType: selectedValue,
      }),
    )

    try {
      const response = await AccountService.getPGConfig()
      let redirectUrl
      if (response.status == 200) {
        redirectUrl = response?.data?.data[0]?.flows[1]?.metadata?.redirectUrl
        dispatch(
          setPgConfig({
            pgconfig: response?.data?.data,
          }),
        )
        let modeSelected
        if (selectedValue === "TOTAL_DUES") modeSelected = "TAD"
        else if (selectedValue === "MINIMUM_DUES") modeSelected = "MAD"
        else modeSelected = "CUSTOM"
        captureEvents({
          eventName: EventName.PROCEED_TO_PAY,
          metadata: { amount: modeSelected },
        })
        resetErrorTryAgainCount()
      } else {
        setErrorState(response?.status, () => handleArrowClick(selectedValue))
      }

      if (amountPayable > 100000) {
        window.open(redirectUrl)
      } else {
        navigate("/paymentGateway/options")
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () =>
          handleArrowClick(selectedValue),
        )
      } else {
        setErrorState(ErrorType.INTERNAL_ERROR, () =>
          handleArrowClick(selectedValue),
        )
      }
    }
  }

  const showCustomPage = () => {
    setShowCustomAmountPage(true)
  }

  const handleBottomSheetClose = () => {
    setInputValue()
    setShowCustomAmountPage(false)
    onClose()
  }
  const amountTypeFromDashboard = {
    TAD: {
      text: t("PaymentGateway.payTotalDue"),
      amount: balanceAmountDue,
      arrowClick: () => handleArrowClick("TOTAL_DUES"),
    },
    MAD: {
      text: t("PaymentGateway.payMinDue"),
      amount: balanceAmountDue > 0 ? minimumAmountDue : 0,
      arrowClick: () => handleArrowClick("MINIMUM_DUES"),
    },
    CUSTOM_AMOUNT: {
      text: t("PaymentGateway.payCustomAmt"),
      arrowClick: () => showCustomPage(),
    },
  }

  const orderedAmountTypes = Object.keys(amountTypeFromDashboard)
  const renderAmountTypes = (option, paymentOption) => {
    if (paymentOption) {
      if (
        option === AmountTypes.CUSTOM_AMOUNT ||
        (option !== AmountTypes.CUSTOM_AMOUNT && paymentOption.amount > 0)
      ) {
        return (
          <div
            className='v3-paymentGateway-bottomSheet-dues-container'
            onClick={paymentOption?.arrowClick}
            style={{ cursor: "pointer" }}
          >
            <div className='v3-paymentGateway-bottomSheet-dues-container-emiText'>
              <div>{paymentOption?.text}</div>
              <div>{paymentOption?.text2}</div>
            </div>
            <div className='v3-paymentGateway-bottomSheet-dues-container-emi'>
              <div>
                {option !== AmountTypes.CUSTOM_AMOUNT && (
                  <div>{formatAmount(paymentOption?.amount)}</div>
                )}
                <div>
                  {isWebKitBrowser() ? (
                    <ArrowRightLarge
                      color={theme.v3.rawColors.primary.color1}
                    />
                  ) : (
                    <ArrowRight
                      size='14px'
                      color={theme.v3.rawColors.primary.color1}
                    />
                  )}
                </div>
              </div>
              <div>{paymentOption?.text3}</div>
            </div>
          </div>
        )
      }
    }
  }

  const bottomSheetContent = () => {
    if (showCustomAmountPage) {
      return (
        <>
          <div className='v3-paymentGateway-bottomSheet-header'>
            <div>{t("PaymentGateway.customAmountText")}</div>
            <div
              onClick={() => handleBottomSheetClose()}
              style={{ cursor: "pointer" }}
            >
              <img src={crossIcon} />
            </div>
          </div>
          <Divider />
          <div className='v3-paymentGateway-bottomSheet-customAmount-text'>
            {t("PaymentGateway.enterAmountText")}
          </div>
          <div
            className={`v3-paymentGateway-bottomSheet-customAmount-amountInput ${isError ? "v3-paymentGateway-bottomSheet-customAmount-amountInput-error" : ""}`}
          >
            <span className='currencyInput'>₹</span>
            <CurrencyFormat
              onKeyDown={evt =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
              }
              placeholder='0.00'
              thousandSpacing='2s'
              thousandSeparator={true}
              decimalScale={2}
              value={inputValue}
              onValueChange={values => {
                if (isError) {
                  setIsError(false)
                }
                let amount = values?.value
                //Restricting entering negative amount
                if ((amount + "").charAt(0) === "-") {
                  amount = amount * -1
                }
                setInputValue(amount)
              }}
              className='v3-paymentGateway-amount-input'
            />
          </div>
          {isError && (
            <div className='v3-paymentUpiVerify-invalid-upiId v3-paymentGateway-error'>
              <div>
                <Info iconProps={{ fill: "red", size: "16px" }} />
              </div>
              {t("PaymentGateway.amountErrorText", {
                currency: "₹",
                amt: "0.00",
              })}
            </div>
          )}
          <DividerV3
            style={{ position: "absolute", left: "0", bottom: "59px" }}
          />
          <ColorButton
            text='Proceed to Pay'
            width='100%'
            onPress={() => handleArrowClick("CUSTOM")}
          />
        </>
      )
    } else {
      return (
        <>
          <div className='v3-paymentGateway-bottomSheet-header'>
            <div>{t("PaymentGateway.payYourBillText")}</div>
            <div onClick={() => onClose()} style={{ cursor: "pointer" }}>
              <img src={crossIcon} />
            </div>
          </div>
          {orderedAmountTypes
            .map(option => {
              if (amountTypes?.includes(option)) {
                const paymentOption = amountTypeFromDashboard[option]
                return renderAmountTypes(option, paymentOption)
              }
            })
            .filter(el => el !== undefined)
            .map((element, index) => (
              <>
                {index !== 0 && <Divider />}
                {element}
              </>
            ))}
        </>
      )
    }
  }

  return (
    <BottomSheet open={isOpen} onDismiss={handleBottomSheetClose}>
      <div className='v3-paymentGateway-bottomSheet'>
        {bottomSheetContent()}
      </div>
    </BottomSheet>
  )
})

export default PaymentGatewayV3
