const TokenisationMenuDots = ({ color = "" }) => {
  return (
    <svg
      width='4'
      height='20'
      viewBox='0 0 4 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='2' cy='2' r='2' fill={color || "#0D2950"} />
      <circle cx='2' cy='10' r='2' fill={color || "#0D2950"} />
      <circle cx='2' cy='18' r='2' fill={color || "#0D2950"} />
    </svg>
  )
}

export default TokenisationMenuDots
