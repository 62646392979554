import React from "react"
import { useSelector } from "react-redux"
import WebExceptionIcon from "../../../../../assets/images/v3/webExceptionIcon.svg"
import { Header } from "../../../../core"
import { useTranslation } from "react-i18next"

const ExceptionWebScreen = () => {
  const { t } = useTranslation()
  const screen = useSelector(state => state.screen)
  const headerConfig = screen?.pwaHeader

  return (
    <>
      {headerConfig?.primaryLogoUrl && <Header />}
      <div className='v3-web-exception-screen-container'>
        <div className='v3-web-exception-screen-top-section'>
          <div className='v3-web-exception-screen-icon'>
            <img src={WebExceptionIcon} alt='exception icon' />
          </div>
          <div className='v3-web-exception-screen-text-container'>
            <div className='v3-web-exception-screen-header-text'>
              {t("messages.oopsMessage")} <br />{" "}
              {t("messages.somethingWentWrong")}
            </div>
            <div className='v3-web-exception-screen-description-text'>
              {t("messages.requestCouldNotBeProcessedMessage")}
            </div>
          </div>
        </div>
        <div className='v3-web-exception-screen-cta-section'>
          <div className='v3-web-exception-screen-primary-cta'>
            {t("messages.tryAgainText")}
          </div>
          <div className='v3-web-exception-screen-secondary-cta'>
            {t("messages.backToHome")}
          </div>
        </div>
      </div>
    </>
  )
}

export default ExceptionWebScreen
